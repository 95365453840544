const Library_mobile_fi = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Laitteessa on [cpuDisplay] -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Laitteessa on [cpuDisplay] -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Suoritin ohjaa tietokoneen toimintaa kuin aivot. Katsomasi videot, pelaamasi pelit, selaamasi sivustot kaikki käynnistyvät suorittimen avulla.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Suoritin on tietokoneen aivot"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Suorituskykyä edullisesti.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Silver -suoritin",
                    "tr_subtitle": "Voit hallita digitaalista maailmaasi missä oletkin, sillä pääset käsiksi tiedostoihin myös verkosta. Vaivattoman moniajon ansiosta voit vaihtaa helposti ohjelmien välillä ja tehdä enemmän nopeammin.",
                    "tr_gen_core_title": "Intel® Pentium® Silver -suoritin"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Gold -suoritin",
                    "tr_subtitle": "Parannettu tuottavuus – Parannettu suorituskyky antaa tehoa vaativimpiin päiviin.  Virtaviivaistettua verkkoselailua – Lataa nopeasti paljon mediaa sisältävät verkkosivut, jotta voit selata enemmän ja odotella vähemmän.",
                    "tr_gen_core_title": "Intel® Pentium® Gold -suoritin"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Celeron® -suoritin",
                    "tr_subtitle": "Suorituskykyä työhön ja viihteeseen. Katsele elokuvia ja verkkovideoita huipputarkkuudella. Tee enemmän, odota vähemmän.",
                    "tr_gen_core_title": "Intel® Celeron® -suoritin"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® -suoritin",
                    "tr_subtitle": "Suunniteltu viihdettä, videoiden suoratoistoa ja tuottavuutta varten. Katsele elokuvia ja verkkovideoita huipputarkkuudella. Tee enemmän, odota vähemmän.",
                    "tr_gen_core_title": "Intel® Pentium® -suoritin"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Tarvittava suorituskyky riittävän edulliseen hintaan",
                "tr_title": "Intel® -Suoritin",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Arvo aivan omaa luokkaansa",
                    "tr_subtitle": "Intel® -Suoritin pystyy käsittelemään melkeinpä minkä tahansa tehtävän kohtuuhintaan. Siirry helposti yhdestä ohjelmistosta toiseen. Voit myös nauttia laadukkaasta grafiikasta tasaisella suorituskyvyllä.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Luo vahvempia yhteyksiä",
                    "tr_subtitle": "Yksityiskohtainen videon laatu, monivivahteinen äänen selkeys ja lähes kolme kertaa nopeampi internet-yhteys mahdollistavat toimivan yhteistyön missä tahansa.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Ero on kuin yöllä ja päivällä",
                    "tr_subtitle": "Vapaapäivän leffamaratonista viime hetken paranteluihin Intel® -Suoritin pidentää laitteen akunkestoa ja tarjoaa mukaansatempaavia, keskeytyksettömiä kokemuksia.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Jokainen ympäristö on oppimista varten",
                    "tr_subtitle": "Käytä useita oppimisen verkkotyökaluja helposti kodin jokaisesta nurkasta käsin."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Opi missä tahansa",
                    "tr_subtitle": "Suorita oppitunteja missä vain käyttäessäsi samalla useita sähköisen oppimisen työkaluja."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hanki viihteen ja tuottavuuden voima, joka sopii budjettiisi ja elämäntapaasi.",
                "tr_title": "Intel® -suorituskykyä millä tahansa budjetilla",
                "tr_gen_core_title": "Intel® Celeron® -suoritin",
                "processorCompare": {
                  "tr_title": "LÖYDÄ OIKEA SUORITUSKYKY JA HINTATASO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Intel® Celeron® -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Selaa nopeasti paljon mediatiedostojakin sisältäviä sivuja",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Siirry ohjelmasta toiseen nopeasti",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pelaa ajan kuluksi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoista 4K-videoita",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kevyeeseen valokuva- ja videomuokkaukseen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Työskentele nopeasti älykkään suorituskyvyn avulla",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Laitteessa on 7.&nbsp;sukupolven Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;&#8209;suoritin"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Laitteessa on 6.&nbsp;sukupolven Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;&#8209;suoritin"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Laitteessa on 5.&nbsp;sukupolven Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;&#8209;suoritin"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Laitteessa on 4.&nbsp;sukupolven Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;&#8209;suoritin"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Laitteessa on 3&nbsp;sukupolven Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;&#8209;suoritin"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Laitteessa on 2.&nbsp;sukupolven Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;&#8209;suoritin"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Laitteessa on 1.&nbsp;sukupolven Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;&#8209;suoritin"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Suositellaan:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Laitteessa on 8. sukupolven Intel® Core™ i3 -suoritin",
                "tr_gen_core_title": "8. sukupolven Intel® Core™ i3 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. sukupolven Intel® Core™ -suorittimet suunniteltiin pysymään digitaalisen maailmasi vauhdissa. Yhdistämällä niiden nopeuden ja suorituskyvyn voit tehdä tietokoneella enemmän turhautumatta.",
                "tr_title": "Laitteessa on 9. sukupolven Intel® Core™ i3 -suoritin",
                "tr_gen_core_title": "9. sukupolven Intel® Core™ i3 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Monipuolinen suorituskyky ja pitkä akunkesto takaavat, että voit käyttää tietokonetta missä tahansa.",
                "tr_title": "Tehoa läpi päivän",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i3 -suoritin",
                "processorCompare": {
                  "tr_title": "ETSI SOPIVA 10. SUKUPOLVEN TEHOTASO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Elokuvien suoratoisto 4K-tarkkuudella",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nopeat, luotettavat yhteydet",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Valokuvien maskaus ja videosuodatus",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pelit 1080p-tarkkuudella ja tasaisella kuvataajuudella",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Sopii parhaiten suurten tiedostojen ja muokkausohjelmien käyttämiseen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Voit helposti siirtyä elokuvien suoratoistosta esitysten luomiseen: suorituskyky riittää kyllä.",
                "tr_title": "Tehoa läpi päivän",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i3 -suoritin"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tämä kannettava on suunniteltu aina liikkeellä oleville.",
                "tr_title": "Tässä on kannettavien uusi luokka",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i3 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Erityisesti kannettaville tietokoneille suunniteltu 11. sukupolven Intel® Core™ i3 -suoritin mahdollistaa entistä tehokkaamman käytön kaikkialla.",
                "tr_title": "Hyödynnä uuden kannettavasi koko teho",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i3 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i3 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Kun etsit pöytäkonetta koko perheesi tarpeisiin, tarvitset 11. sukupolven Intel® Core™ i3 -suorittimen tehon ja monipuolisuuden.",
                "tr_title": "Ole valmis mihin tahansa mullistavan tehon ansiosta",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i3 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i3 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Laitteessa on 8. sukupolven Intel® Core™ i5 -suoritin",
                "tr_gen_core_title": "8. sukupolven Intel® Core™ i5 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. sukupolven Intel® Core™ -suorittimet suunniteltiin pysymään digitaalisen maailmasi vauhdissa. Yhdistämällä niiden nopeuden ja suorituskyvyn voit tehdä tietokoneella enemmän turhautumatta.",
                "tr_title": "Laitteessa on 9. sukupolven Intel® Core™ i5 -suoritin",
                "tr_gen_core_title": "9. sukupolven Intel® Core™ i5 -suoritin"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "HAASTAVIMMATKIN PELIT JA VIRTUAALITODELLISUUS SUJUVASTI",
                "tr_title": "Huippupeli alkaa tästä",
                "tr_gen_core_title": "9. sukupolven Intel® Core™ i5 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "LÖYDÄ INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-luokan peliä",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pelaa, suoratoista ja tallenna laadusta tinkimättä",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Paras 9. sukupolven Intel® Core™ -suoritin pelaamiseen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 4<br/>Säikeet: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vaihtele ohjelmia helposti ja olet valmis mihin tahansa.",
                "tr_title": "Moniajoa kaikkialla",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i5 -suoritin",
                "processorCompare": {
                  "tr_title": "ETSI SOPIVA 10. SUKUPOLVEN TEHOTASO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Elokuvien suoratoisto 4K-tarkkuudella",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nopeat, luotettavat yhteydet",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Valokuvien maskaus ja videosuodatus",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pelit 1080p-tarkkuudella ja tasaisella kuvataajuudella",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Sopii parhaiten suurten tiedostojen ja muokkausohjelmien käyttämiseen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Helpota viihteestä nauttimista, hyötyohjelmien käyttöä, sisällön luomista ja kaikkea muuta, mitä haluat tehdä.",
                "tr_title": "Päivitä tehokkaampaan tietokoneeseen",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i5 -suoritin"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tämä kannettava on suunniteltu aina liikkeellä oleville.",
                "tr_title": "Tässä on kannettavien uusi luokka",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i5 -suoritin"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Suunniteltu saumatonta suoratoistoa ja AAA-luokan pelejä varten",
                "tr_title": "Pelaa niin kuin haluat",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i5 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ETSI 10. SUKUPOLVEN INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Saumaton AAA-luokan pelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto ja tallennus",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kehittynyt sisällön luonti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Huipputason pelit",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Lukitsematon ja ylikellotettava<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Suunniteltu saumatonta suoratoistoa ja AAA-luokan pelejä varten",
                "tr_title": "Pelaa niin kuin haluat",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i5 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ETSI 10. SUKUPOLVEN INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Saumaton AAA-luokan pelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto ja tallennus",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kehittynyt sisällön luonti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Huipputason pelit",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Lukitsematon ja ylikellotettava<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 10<br/>Säikeet: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Vaihtele ohjelmia helposti ja olet valmis mihin tahansa.",
                "tr_title": "Moniajoa kaikkialla",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i5 -suoritin",
                "tr_gen_core_title_uppercase": "10. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. sukupolven Intel® Core™ i5 -suoritin tuottaa upean kuvanlaadun ja mahdollistaa monipuolisen tuottavuuden erittäin ohuilla ja kevyillä tietokoneilla.",
                "tr_title": "Vaikuttavaa tehoa kannettavassa tietokoneessa",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i5 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Kun etsit pöytäkonetta koko perheesi tarpeisiin, tarvitset 11. sukupolven Intel® Core™ i5 -suorittimen tehon ja monipuolisuuden.",
                "tr_title": "Ole valmis mihin tahansa mullistavan tehon ansiosta",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i5 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. sukupolven Intel® Core™ i5 -suoritin tuottaa upean kuvanlaadun ja mahdollistaa monipuolisen tuottavuuden erittäin ohuilla ja kevyillä kannettavilla.",
                "tr_title": "Vaikuttavaa tehoa kannettavassa tietokoneessa",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i5 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Laitteessa on 8. sukupolven Intel® Core™ i7 -suoritin",
                "tr_gen_core_title": "8. sukupolven Intel® Core™ i7 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "9. sukupolven Intel® Core™ -suorittimet suunniteltiin pysymään digitaalisen maailmasi vauhdissa. Yhdistämällä niiden nopeuden ja suorituskyvyn voit tehdä tietokoneella enemmän turhautumatta.",
                "tr_title": "Laitteessa on 9. sukupolven Intel® Core™ i7 -suoritin",
                "tr_gen_core_title": "9. sukupolven Intel® Core™ i7 -suoritin"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "JOPA 32&nbsp;% PAREMPI KUVATAAJUUS pelattaessa, suoratoistettaessa ja tallennettaessa verrattuna 3 VUOTTA vanhaan tietokoneeseen<sup>3</sup>",
                "tr_title": "Jaa parhaat pelihetket tehokkaasti",
                "tr_gen_core_title": "9. sukupolven Intel® Core™ i7 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "LÖYDÄ INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-luokan peliä",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pelaa, suoratoista ja tallenna laadusta tinkimättä",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Paras 9. sukupolven Intel® Core™ -suoritin pelaamiseen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 4<br/>Säikeet: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Tämä tehokas ja tyylikäs laite voi vaikuttaa merkittävästi tapaan, jolla luot, pidät yhteyttä ja suoratoistat.",
                "tr_title": "Huipputehoa kaikkialla",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i7 -suoritin",
                "processorCompare": {
                  "tr_title": "ETSI SOPIVA 10. SUKUPOLVEN TEHOTASO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Elokuvien suoratoisto 4K-tarkkuudella",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nopeat, luotettavat yhteydet",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Valokuvien maskaus ja videosuodatus",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pelit 1080p-tarkkuudella ja tasaisella kuvataajuudella",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Sopii parhaiten suurten tiedostojen ja muokkausohjelmien käyttämiseen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Kehittynyt suorituskyky vie kaiken toimintasi uudelle tasolle.",
                "tr_title": "Tarpeeksi tehoa mihin tahansa",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i7 -suoritin"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tämä kannettava on suunniteltu aina liikkeellä oleville.",
                "tr_title": "Tässä on kannettavien uusi luokka",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i7 -suoritin"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Pelaa, suoratoista ja tallenna kilpailijoita tehokkaammin",
                "tr_title": "Lisää tehoa pelaamiseen",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i7 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ETSI 10. SUKUPOLVEN INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Saumaton AAA-luokan pelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto ja tallennus",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kehittynyt sisällön luonti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Huipputason pelit",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Lukitsematon ja ylikellotettava<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Pelaa, suoratoista ja tallenna kilpailijoita tehokkaammin",
                "tr_title": "Lisää tehoa pelaamiseen",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i7 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ETSI 10. SUKUPOLVEN INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Saumaton AAA-luokan pelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto ja tallennus",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kehittynyt sisällön luonti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Huipputason pelit",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Lukitsematon ja ylikellotettava<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 10<br/>Säikeet: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tämä tehokas ja virtaviivainen laite voi vaikuttaa valtavasti siihen, miten luot, pidät yhteyttä ja suoratoistat.",
                "tr_title": "Huipputason suorituskyky missä tahansa",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i7 -suoritin",
                "tr_gen_core_title_uppercase": "10. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. sukupolven Intel® Core™ i7 -suoritin tuo kehittyneen sisällön luonnin, mukavan pelikokemuksen ja uuden sukupolven viihteen ultrakannettaviin tietokoneisiin.",
                "tr_title": "Mullistavaa tehoa kannettavassa tietokoneessa",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Tee pöytäkoneestasi koko kodin tarpeita vastaava apuväline 11. sukupolven Intel® Core™ i7 -suorittimen mullistavan tehon avulla.",
                "tr_title": "Työskentele, opiskele ja pelaa ilman rajoja",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. sukupolven Intel® Core™ i7 -suoritin tuo edistyneen sisällön luomisen, mukavan pelikokemuksen ja uuden sukupolven viihteen ohuisiin ja kevyisiin kannettaviin.",
                "tr_title": "Mullistavaa tehoa kannettavassa tietokoneessa",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tee pöytäkoneestasi koko kodin tarpeita vastaava apuväline 11. sukupolven Intel® Core™ i7 -suorittimen mullistavan tehon avulla.",
                "tr_title": "Luo ilman rajoja",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Edistynyt sisällön luominen, sujuva pelaaminen ja uuden tason viihde ohuissa ja kevyissä kannettavissa, tämä 11. sukupolven Intel® Core™ i7 -suoritin kehittyi entisestään.",
                "tr_title": "Mullistavaa tehoa kannettavassa tietokoneessa",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Laitteessa on 8. sukupolven Intel® Core™ i9 -suoritin",
                "tr_gen_core_title": "8. sukupolven Intel® Core™ i9 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. sukupolven Intel® Core™ -suorittimet suunniteltiin pysymään digitaalisen maailmasi vauhdissa. Yhdistämällä niiden nopeuden ja suorituskyvyn voit tehdä tietokoneella enemmän turhautumatta.",
                "tr_title": "Laitteessa on 9. sukupolven Intel® Core™ i9 -suoritin",
                "tr_gen_core_title": "9. sukupolven Intel® Core™ i9 -suoritin"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "JOPA 41&nbsp;% PAREMPI KUVATAAJUUS pelattaessa, suoratoistettaessa ja tallennettaessa verrattuna 3 VUOTTA vanhaan tietokoneeseen<sup>4</sup>",
                "tr_title": "Pelaajat vaativat – Intel® Core™ i9 täyttää odotukset",
                "tr_gen_core_title": "9. sukupolven Intel® Core™ i9 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "LÖYDÄ INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-luokan peliä",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pelaa, suoratoista ja tallenna laadusta tinkimättä",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Paras 9. sukupolven Intel® Core™ -suoritin pelaamiseen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 4<br/>Säikeet: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hyödynnä potentiaalisi huippuluokan peliominaisuuksien avulla",
                "tr_title": "Kilpaile korkeimmalla tasolla",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i9 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ETSI 10. SUKUPOLVEN INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Saumaton AAA-luokan pelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto ja tallennus",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kehittynyt sisällön luonti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Huipputason pelit",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Lukitsematon ja ylikellotettava<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hyödynnä potentiaalisi huippuluokan peliominaisuuksien avulla",
                "tr_title": "Kilpaile korkeimmalla tasolla",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i9 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ETSI 10. SUKUPOLVEN INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Saumaton AAA-luokan pelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto ja tallennus",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kehittynyt sisällön luonti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Huipputason pelit",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Lukitsematon ja ylikellotettava<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 10<br/>Säikeet: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hyödynnä potentiaalisi huippuluokan sisällönluontiominaisuuksien avulla",
                "tr_title": "Luo korkeatasoisempaa sisältöä",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i9 -suoritin",
                "tr_gen_core_title_uppercase": "10. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ -kannettavat käyttävät 11. sukupolven Intel® Core™ i5 -suoritinta, ja Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -näytönohjain antaa huimasti lisää nopeutta ja tehoa ohuelle ja kevyelle mallille.",
                "tr_title": "Lisää tehoa kehittyneelle kannettavalle",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i5 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ -kannettavat käyttävät 11. sukupolven Intel® Core™ i7 -suoritinta, ja Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -näytönohjain antaa lisää nopeutta ja tehoa huippuluokan mobiilikäyttöön.",
                "tr_title": "Tuhdisti tehoa kehittyneelle kannettavalle",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Nauti suorituskyvystä, mediasta ja yhteydenpidon kokemuksista uskomattomaan hintaan.",
                "tr_title": "Suorituskyky ja yhteydet hämmästyttävällä hinnalla",
                "tr_gen_core_title": "Intel® Pentium® Silver -suoritin",
                "processorCompare": {
                  "tr_title": "LÖYDÄ OIKEA SUORITUSKYKY JA HINTATASO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Selaa nopeasti paljon mediatiedostojakin sisältäviä sivuja",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Siirry ohjelmasta toiseen nopeasti",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pelaa ajan kuluksi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoista 4K-videoita",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kevyeeseen valokuva- ja videomuokkaukseen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Työskentele nopeasti älykkään suorituskyvyn avulla",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Nauti saumattomasta moniajosta, yksinkertaisesta kuvien ja videoiden muokkauksesta, 4K-videoista ja elävistä grafiikoista uskomattomaan hintaan.",
                "tr_title": "Vaikuttava suorituskyky työhön ja pelaamiseen",
                "tr_gen_core_title": "Intel® Pentium® Gold -suoritin",
                "processorCompare": {
                  "tr_title": "LÖYDÄ OIKEA SUORITUSKYKY JA HINTATASO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Selaa nopeasti paljon mediatiedostojakin sisältäviä sivuja",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Siirry ohjelmasta toiseen nopeasti",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pelaa ajan kuluksi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoista 4K-videoita",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kevyeeseen valokuva- ja videomuokkaukseen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Työskentele nopeasti älykkään suorituskyvyn avulla",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA-luokan peli ja pitkä akunkesto ultrakannettavassa tietokoneessa. Ei tarvetta tinkiä pelitavasta.",
                "tr_title": "Ei tarvetta kompromisseihin",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i5 -suoritin",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -suoritin",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "LÖYDÄ SINULLE SOPIVA ULTRAKANNETTAVA INTEL® CORE™ -PELISUORITIN.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. sukupolven Intel® Core™ i5-11300H &#8209;suoritin"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. sukupolven Intel® Core™ i7-11370H &#8209;suoritin"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. sukupolven Intel® Core™ i7-11375H Special Edition &#8209;suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Jopa <span class='style_badge'>4,4 GHz:n</span> enimm.kello&shy;taajuus",
                      "tr_td2_title": "Jopa <span class='style_badge'>4,8 GHz:n</span> enimm.kello&shy;taajuus",
                      "tr_td3_title": "Jopa <span class='style_badge'>5,0 GHz:n</span> enimm.kello&shy;taajuus"
                    },
                    {
                      "tr_td1_title": "4 ydintä/ 8&nbsp;säiettä",
                      "tr_td2_title": "4 ydintä/ 8&nbsp;säiettä",
                      "tr_td3_title": "4 ydintä/ 8&nbsp;säiettä"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost &#8209;teknologia",
                      "tr_td2_title": "Intel® Turbo Boost &#8209;teknologia",
                      "tr_td3_title": "Intel® Turbo Boost Max &#8209;teknologia 3.0"
                    },
                    {
                      "tr_td1_title": "8 Mt:n Intel® Smart Cache &#8209;välimuisti",
                      "tr_td2_title": "12 Mt:n Intel® Smart Cache &#8209;välimuisti",
                      "tr_td3_title": "12 Mt:n Intel® Smart Cache &#8209;välimuisti"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;näytö&shy;nohjain",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;näytö&shy;nohjain",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;näytö&shy;nohjain"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA-luokan pelit 1080p-tarkkuudella, korkealla kuvataajuudella ja huippusuorituskyvyllä ultrakannettavan tietokoneen avulla.",
                "tr_title": "Anna mennä. Ota kaikki irti.",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 -suoritin",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -suoritin",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "LÖYDÄ SINULLE SOPIVA ULTRAKANNETTAVA INTEL® CORE™ -PELISUORITIN.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. sukupolven Intel® Core™ i5-11300H &#8209;suoritin"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. sukupolven Intel® Core™ i7-11370H &#8209;suoritin"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. sukupolven Intel® Core™ i7-11375H Special Edition &#8209;suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Jopa <span class='style_badge'>4,4 GHz:n</span> enimm.kello&shy;taajuus",
                      "tr_td2_title": "Jopa <span class='style_badge'>4,8 GHz:n</span> enimm.kello&shy;taajuus",
                      "tr_td3_title": "Jopa <span class='style_badge'>5,0 GHz:n</span> enimm.kello&shy;taajuus"
                    },
                    {
                      "tr_td1_title": "4 ydintä/ 8&nbsp;säiettä",
                      "tr_td2_title": "4 ydintä/ 8&nbsp;säiettä",
                      "tr_td3_title": "4 ydintä/ 8&nbsp;säiettä"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost &#8209;teknologia",
                      "tr_td2_title": "Intel® Turbo Boost &#8209;teknologia",
                      "tr_td3_title": "Intel® Turbo Boost Max &#8209;teknologia 3.0"
                    },
                    {
                      "tr_td1_title": "8 Mt:n Intel® Smart Cache &#8209;välimuisti",
                      "tr_td2_title": "12 Mt:n Intel® Smart Cache &#8209;välimuisti",
                      "tr_td3_title": "12 Mt:n Intel® Smart Cache &#8209;välimuisti"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;näytö&shy;nohjain",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;näytö&shy;nohjain",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;näytö&shy;nohjain"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Jopa 5,0 GHz:n maksimi kellotaajuuden avulla viet pelisi uudelle tasolle, ja ultrakannettavan tietokoneen avulla voit pelata missä tahansa.",
                "tr_title": "Lisää tehoa kevyessä paketissa",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 Special Edition -suoritin",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition -suoritin",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "LÖYDÄ SINULLE SOPIVA ULTRAKANNETTAVA INTEL® CORE™ -PELISUORITIN.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. sukupolven Intel® Core™ i5-11300H &#8209;suoritin"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. sukupolven Intel® Core™ i7-11370H &#8209;suoritin"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. sukupolven Intel® Core™ i7-11375H Special Edition &#8209;suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Jopa <span class='style_badge'>4,4 GHz:n</span> enimm.kello&shy;taajuus",
                      "tr_td2_title": "Jopa <span class='style_badge'>4,8 GHz:n</span> enimm.kello&shy;taajuus",
                      "tr_td3_title": "Jopa <span class='style_badge'>5,0 GHz:n</span> enimm.kello&shy;taajuus"
                    },
                    {
                      "tr_td1_title": "4 ydintä/ 8&nbsp;säiettä",
                      "tr_td2_title": "4 ydintä/ 8&nbsp;säiettä",
                      "tr_td3_title": "4 ydintä/ 8&nbsp;säiettä"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost &#8209;teknologia",
                      "tr_td2_title": "Intel® Turbo Boost &#8209;teknologia",
                      "tr_td3_title": "Intel® Turbo Boost Max &#8209;teknologia 3.0"
                    },
                    {
                      "tr_td1_title": "8 Mt:n Intel® Smart Cache &#8209;välimuisti",
                      "tr_td2_title": "12 Mt:n Intel® Smart Cache &#8209;välimuisti",
                      "tr_td3_title": "12 Mt:n Intel® Smart Cache &#8209;välimuisti"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;näytö&shy;nohjain",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;näytö&shy;nohjain",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;näytö&shy;nohjain"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. sukupolven Intel® Core™ i7 Special Edition -suoritin tuo edistyneen sisällönluomisen, sujuvan pelaamisen ja uuden tason viihteen ultrakannettaviin tietokoneisiin.",
                "tr_title": "Mullistavaa tehoa kannettavassa tietokoneessa",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 Special Edition -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i7 SPECIAL EDITION -SUORITIN"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. sukupolven Intel® Core™ i7 Special Edition -suoritin tuo edistyneen sisällönluomisen, sujuvan pelaamisen ja uuden tason viihteen ultrakannettaviin tietokoneisiin.",
                "tr_title": "Mullistavaa tehoa kannettavassa tietokoneessa",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 Special Edition -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i7 SPECIAL EDITION -SUORITIN",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Nopeat kellotaajuudet ja useat ytimet toimivat saumattomasti yhdessä, jotta saat enemmän irti kilpapelaamisesta ja vaativasta sisällön luomisesta.",
                "tr_title": "Massiivinen teho. Täydellinen tasapaino",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i9 -suoritin",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -suoritin",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Oikealla kellotaajuuden ja ytimen suorituskyvyn tasapainolla saat enemmän irti peleistä olipa kyseessä korkean kuvataajuuden kilpapelaaminen tai mukaansatempaava AAA-tason pelikokemus.",
                "tr_title": "Määrätietoista tehoa",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i5 -suoritin",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Pelaa, tallenna ja suoratoista vaativia suuren kuvataajuuden pelejä ja vaihda vaivatta raskaisiin moniajoa vaativiin tehtäviin.",
                "tr_title": "Äärimmäistä tehokkuutta",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 -suoritin",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Nopeat kellotaajuudet ja useat ytimet toimivat saumattomasti yhdessä, jotta saat enemmän irti kilpapelaamisesta ja vaativasta sisällön luomisesta.",
                "tr_title": "Massiivinen teho. Täydellinen tasapaino",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i9 -suoritin",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Voit helposti siirtyä elokuvien suoratoistosta esitysten luomiseen: suorituskyky riittää kyllä.",
                "tr_title": "Tehoa läpi päivän",
                "tr_gen_core_title": "Intel® Core™ i3 &#8209;suoritin ja Intel®&nbsp;Hybrid &#8209;teknologia",
                "tr_subTitle2": "Intel® Core™ i3 &#8209;suoritin ja Intel®&nbsp;Hybrid &#8209;teknologia"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Monipuolinen suorituskyky ja pitkä akunkesto takaavat, että voit käyttää tietokonetta missä tahansa.",
                "tr_title": "Tehoa läpi päivän",
                "tr_gen_core_title": "Intel® Core™ i3 &#8209;suoritin ja Intel®&nbsp;Hybrid &#8209;teknologia",
                "tr_subTitle2": "Intel® Core™ i3 &#8209;suoritin ja Intel®&nbsp;Hybrid &#8209;teknologia"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vaihtele ohjelmia helposti ja olet valmis mihin tahansa.",
                "tr_title": "Moniajoa kaikkialla",
                "tr_gen_core_title": "Intel® Core™ i5 &#8209;suoritin ja Intel®&nbsp;Hybrid &#8209;teknologia",
                "tr_subTitle2": "Intel® Core™ i5 &#8209;suoritin ja Intel®&nbsp;Hybrid &#8209;teknologia"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Helpota viihteestä nauttimista, hyötyohjelmien käyttöä, sisällön luomista ja kaikkea muuta, mitä haluat tehdä.",
                "tr_title": "Päivitä tehokkaampaan tietokoneeseen",
                "tr_gen_core_title": "Intel® Core™ i5 &#8209;suoritin ja Intel®&nbsp;Hybrid &#8209;teknologia",
                "tr_subTitle2": "Intel® Core™ i5 &#8209;suoritin ja Intel®&nbsp;Hybrid &#8209;teknologia"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Pöytäkoneen suorituskyky peleissä yhdistettynä kannettavuuteen – soveltuu sekä kilpapelaamiseen korkealla kuvataajuudella että mukaansatempaaviin AAA-luokan peleihin.",
                "tr_title": "Määrätietoista tehoa",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i5 -suoritin",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Pöytäkoneen suorituskyky peleissä yhdistettynä kannettavuuteen – soveltuu sekä kilpapelaamiseen korkealla kuvataajuudella että mukaansatempaaviin AAA-luokan peleihin.",
                "tr_title": "Määrätietoista tehoa",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i5 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Kannettava, jolla voit samanaikaisesti pelata, tallentaa ja suoratoistaa korkealla kuvataajuudella sekä vaihtaa vaivattomasti moniajoa vaativiin tehtäviin.",
                "tr_title": "Äärimmäistä tehokkuutta",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 -suoritin",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Kannettava, jolla voit samanaikaisesti pelata, tallentaa ja suoratoistaa korkealla kuvataajuudella sekä vaihtaa vaivattomasti moniajoa vaativiin tehtäviin.",
                "tr_title": "Äärimmäistä tehokkuutta",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Nopeat kellotaajuudet ja useat ytimet toimivat saumattomasti yhdessä, jotta saat enemmän irti kilpapelaamisesta ja vaativasta sisällön luomisesta missä tahansa oletkin.",
                "tr_title": "Massiivinen teho. Täydellinen tasapaino",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i9 -suoritin",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Nopeat kellotaajuudet ja useat ytimet toimivat saumattomasti yhdessä, jotta saat enemmän irti kilpapelaamisesta ja vaativasta sisällön luomisesta missä tahansa oletkin.",
                "tr_title": "Massiivinen teho. Täydellinen tasapaino",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i9 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Nopeat kellotaajuudet, useat ytimet ja rajaton suorituskyky toimivat saumattomasti yhdessä, jotta saat enemmän irti kilpapelaamisesta ja vaativasta sisällön luomisesta missä tahansa oletkin. ",
                "tr_title": "Rajaton teho ja täydellinen tasapaino",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i9 -suoritin",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Nopeat kellotaajuudet, useat ytimet ja rajaton suorituskyky toimivat saumattomasti yhdessä, jotta saat enemmän irti kilpapelaamisesta ja vaativasta sisällön luomisesta missä tahansa oletkin. ",
                "tr_title": "Rajaton teho ja täydellinen tasapaino",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i9 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Nopeat kellotaajuudet ja useat ytimet toimivat saumattomasti yhdessä, jotta saat enemmän irti kilpapelaamisesta ja vaativasta sisällön luomisesta.",
                "tr_title": "Massiivinen teho. Täydellinen tasapaino",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i9 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Nopeat kellotaajuudet ja useat ytimet toimivat saumattomasti yhdessä, jotta saat enemmän irti vaativasta sisällön luonnista – otitpa kannettavasi mukaan mihin tahansa.",
                "tr_title": "Massiivinen teho. Täydellinen tasapaino",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i9 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Nopeat kellotaajuudet ja useat ytimet toimivat saumattomasti yhdessä, jotta saat enemmän irti vaativasta sisällön luonnista.",
                "tr_title": "Massiivinen teho. Täydellinen tasapaino",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i9 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hyödynnä potentiaalisi huippuluokan peliominaisuuksien avulla",
                "tr_title": "Kilpaile korkeimmalla tasolla",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i9 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ETSI 10. SUKUPOLVEN INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Saumaton AAA-luokan pelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto ja tallennus",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kehittynyt sisällön luonti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Huipputason pelit",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Lukitsematon ja ylikellotettava<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hyödynnä potentiaalisi huippuluokan peliominaisuuksien avulla",
                "tr_title": "Kilpaile korkeimmalla tasolla",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i9 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ETSI 10. SUKUPOLVEN INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Saumaton AAA-luokan pelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Suoratoisto ja tallennus",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kehittynyt sisällön luonti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Huipputason pelit",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Lukitsematon ja ylikellotettava<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 10<br/>Säikeet: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tämä kannettava on suunniteltu aina liikkeellä oleville.",
                "tr_title": "Tässä on kannettavien uusi luokka",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i9 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Nopeat kellotaajuudet ja useat ytimet toimivat saumattomasti yhdessä, jotta saat enemmän irti kilpapelaamisesta ja vaativasta sisällön luomisesta.",
                "tr_title": "Rajaton teho ja täydellinen tasapaino",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i9 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Nopeat kellotaajuudet, useat ytimet ja rajaton suorituskyky toimivat saumattomasti yhdessä, jotta saat enemmän irti vaativasta sisällön luonnista – otitpa kannettavasi mukaan mihin tahansa.",
                "tr_title": "Rajaton teho ja täydellinen tasapaino",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i9 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Nopeat kellotaajuudet ja useat ytimet toimivat saumattomasti yhdessä, jotta saat enemmän irti vaativasta sisällön luonnista.",
                "tr_title": "Rajaton teho ja täydellinen tasapaino",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i9 -suoritin",
                "tr_gen_core_title_uppercase": "11. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt <br/></span><span class='subtitle_font'>Intel® Smart Cache</span> -välimuistia",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Haastavammatkin pelit ja virtuaalitodellisuus sujuvasti",
                "tr_title": "Huippupeli alkaa tästä",
                "tr_gen_core_title": "9. sukupolven Intel® Core™ i5 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "LÖYDÄ INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-luokan peliä",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pelaa, suoratoista ja tallenna laadusta tinkimättä",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Paras 9. sukupolven Intel® Core™ -suoritin pelaamiseen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 4<br/>Säikeet: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "JOPA 32 % PAREMPI KUVATAAJUUS <br/><span class='tr_subtitle_font'>pelattaessa, suoratoistettaessa ja tallennettaessa verrattuna 3 vuotta vanhaan PC-tietokoneeseen<sup>3</sup></span>",
                "tr_title": "Jaa parhaat pelihetket tehokkaasti",
                "tr_gen_core_title": "9. sukupolven Intel® Core™ i7 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "LÖYDÄ INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-luokan peliä",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pelaa, suoratoista ja tallenna laadusta tinkimättä",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Paras 9. sukupolven Intel® Core™ -suoritin pelaamiseen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 4<br/>Säikeet: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "JOPA 41 % PAREMPI KUVATAAJUUS <br/><span class='tr_subtitle_font'>pelattaessa, suoratoistettaessa ja tallennettaessa verrattuna 3 vuotta vanhaan PC-tietokoneeseen<sup>4</sup></span>",
                "tr_title": "Pelaajat vaativat – Intel® Core™ i9 täyttää odotukset",
                "tr_gen_core_title": "9. sukupolven Intel® Core™ i9 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>säiettä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ydintä</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jopa <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] Mt<br/></span> <span class='subtitle_font'>välimuistia</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Lukitsematon",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "LÖYDÄ INTEL® CORE™ -SUORITIN, JOKA SOPII PELITYYLIISI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -suoritin"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -suoritin"
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -suoritin"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-luokan peliä",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kilpapelaaminen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pelaa, suoratoista ja tallenna laadusta tinkimättä",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Paras 9. sukupolven Intel® Core™ -suoritin pelaamiseen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 4<br/>Säikeet: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 6<br/>Säikeet: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kellotaajuus<sup>2</sup>: jopa 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Ytimet: 8<br/>Säikeet: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Suunniteltu seuraavan sukupolven pelaamiseen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i5 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i9 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ydintä</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores-ydintä",
                    "tr_badgeSubTitle": "[e_core] E-cores-ydintä"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>säiettä</span></span>",
                    "tr_badgePreTitle": "[p_thread] P-cores-ytimissä",
                    "tr_badgeSubTitle": "[e_thread] E-cores-ytimissä"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Taisteluasema ja työasema samassa laitteessa",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i9 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ydintä</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores-ydintä",
                    "tr_badgeSubTitle": "[e_core] E-cores-ydintä"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>säiettä</span></span>",
                    "tr_badgePreTitle": "[p_thread] P-cores-ytimissä",
                    "tr_badgeSubTitle": "[e_thread] E-cores-ytimissä"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Luotettavaa suorituskykyä, kun tarvitset sitä eniten",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i3 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i3 -SUORITIN",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Tehostaa suorituskykyä siellä, missä sitä tarvitaan eniten",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i5 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Tehostaa suorituskykyä siellä, missä sitä tarvitaan eniten",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i5 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Huipputason suorituskykyä siellä, missä tarvitset sitä eniten",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Huipputason suorituskykyä siellä, missä tarvitset sitä eniten",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ -kannettavat tarjoavat suorituskykyä, sisäänrakennettua älyä, jonka avulla viestit luottavaisesti videochateissa, nopeamman internetin, pitkäkestoisen akun ja paljon muuta.",
                "tr_title": "Tehostettua suorituskykyä siellä, missä sitä eniten tarvitset",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i5 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ -kannettavat tarjoavat suorituskykyä, sisäänrakennettua älyä, jonka avulla viestit luottavaisesti videochateissa, nopeamman internetin, pitkäkestoisen akun ja paljon muuta.",
                "tr_title": "Huipputason suorituskykyä siellä, missä tarvitset sitä eniten",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ -kannettavat tarjoavat suorituskykyä, sisäänrakennettua älyä, jonka avulla viestit luottavaisesti videochateissa, nopeamman internetin, pitkäkestoisen akun ja paljon muuta.",
                "tr_title": "Äärimmäinen suorituskyky huipputason kannettavassa",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i9 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Intel® Turbo Boost -teknologia 2.0:n avulla jopa [max_turbo_frequency] GHz",
                "tr_title": "Built for business",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i5 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Vähentää melua ja sumentaa taustasi",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Suorituskyvyn automaattinen räätälöinti priorisoi sitä, mitä teet eniten",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Saat sujuvat, laadukkaat verkkopuhelut",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Heti valmiit, laitteistopohjaiset suojaustoiminnot",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Tarjoaa laitehallinnan etätoiminnot",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimoi automaattisesti Wi-Fi-suorituskyvyn<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hyödyntää datapohjaisia sovelluksia",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Tarjoaa eniten tuottavuutta tehostetulla suorituskyvyllä",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Intel® Turbo Boost -teknologia 2.0:n avulla jopa [max_turbo_frequency] GHz",
                "tr_title": "Tekee kovasti töitä, jotta sinä voit keskittyä liiketoimintaasi",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Vähentää melua ja sumentaa taustasi",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Suorituskyvyn automaattinen räätälöinti priorisoi sitä, mitä teet eniten",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Saat sujuvat, laadukkaat verkkopuhelut",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Heti valmiit, laitteistopohjaiset suojaustoiminnot",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Tarjoaa laitehallinnan etätoiminnot",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimoi automaattisesti Wi-Fi-suorituskyvyn<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hyödyntää datapohjaisia sovelluksia",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Tarjoaa eniten tuottavuutta tehostetulla suorituskyvyllä",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Intel® Turbo Boost -teknologia 2.0:n avulla jopa [max_turbo_frequency] GHz",
                "tr_title": "Vauhdita liiketoimintaasi",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i9 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "12. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Vähentää melua ja sumentaa taustasi",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Suorituskyvyn automaattinen räätälöinti priorisoi sitä, mitä teet eniten",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Saat sujuvat, laadukkaat verkkopuhelut",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Heti valmiit, laitteistopohjaiset suojaustoiminnot",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Tarjoaa laitehallinnan etätoiminnot",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimoi automaattisesti Wi-Fi-suorituskyvyn<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hyödyntää datapohjaisia sovelluksia",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Tarjoaa eniten tuottavuutta tehostetulla suorituskyvyllä",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Luotettava suorituskyky, jolla saat aikaan enemmän",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i3 &#8209;suoritin",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Tosielämän suorituskykyä, jolla saat aikaan enemmän",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i5 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Tosielämän suorituskykyä, jolla saat aikaan enemmän",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i5 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Tehokasta suorituskykyä, jolla saat aikaan enemmän",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Tehokasta suorituskykyä, jolla saat aikaan enemmän",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Ylivertaista suorituskykyä",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i9 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ydintä</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores-ydintä",
                    "tr_badgeSubTitle": "[e_core] E-cores-ydintä"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>säiettä</span></span>",
                    "tr_badgePreTitle": "[p_thread] P-cores-ytimissä",
                    "tr_badgeSubTitle": "[e_thread] E-cores-ytimissä"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Yhdistä nykyiseen puhelimeesi saumattomasti<sup>24</sup>, valitse paras Wi-Fi-yhteys älykkäästi ja lataa nopeasti akulla, joka kestää.",
                "tr_title": "Verrattoman suorituskyvyn ansiosta saat aikaan enemmän missä tahansa",
                "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i5 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Yhdistä nykyiseen puhelimeesi saumattomasti<sup>24</sup>, valitse paras Wi-Fi-yhteys älykkäästi ja lataa nopeasti akulla, joka kestää.",
                "tr_title": "Verrattoman suorituskyvyn ansiosta tietokoneesi tekee yhtä aikaa vielä enemmän",
                "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Yhdistä nykyiseen puhelimeesi saumattomasti<sup>24</sup>, valitse paras Wi-Fi-yhteys älykkäästi ja lataa nopeasti akulla, joka kestää.",
                "tr_title": "Verrattoman suorituskyvyn ansiosta vaativimmatkin tehtävät hoituvat nopeasti",
                "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i9 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Ylivertaista suorituskykyä",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i5 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i9 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ydintä</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores-ydintä",
                    "tr_badgeSubTitle": "[e_core] E-cores-ydintä"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>säiettä</span></span>",
                    "tr_badgePreTitle": "[p_thread] P-cores-ytimissä",
                    "tr_badgeSubTitle": "[e_thread] E-cores-ytimissä"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Ylivertaista suorituskykyä",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 -suoritin",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 -SUORITIN",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 -suoritin",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 -SUORITIN",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 -suoritin",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 -SUORITIN",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ydintä</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores-ydintä",
                    "tr_badgeSubTitle": "[e_core] E-cores-ydintä"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>säiettä</span></span>",
                    "tr_badgePreTitle": "[p_thread] P-cores-ytimissä",
                    "tr_badgeSubTitle": "[e_thread] E-cores-ytimissä"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Luotettava suorituskyky, jolla saat aikaan enemmän",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Intel® Core™ i3 -suoritin",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Tosielämän suorituskykyä, jolla saat aikaan enemmän",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel® Core™ i5 -suoritin",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 -SUORITIN",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Tehokasta suorituskykyä, jolla saat aikaan enemmän",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel® Core™ i7 -suoritin",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 -SUORITIN",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Ylivertaista suorituskykyä",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 -SUORITIN",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 -SUORITIN",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 -SUORITIN",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ydintä</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores-ydintä",
                    "tr_badgeSubTitle": "[e_core] E-cores-ydintä"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>säiettä</span></span>",
                    "tr_badgePreTitle": "[p_thread] P-cores-ytimissä",
                    "tr_badgeSubTitle": "[e_thread] E-cores-ytimissä"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Luotettava suorituskyky, jolla saat aikaan enemmän",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 3 &#8209;suoritin",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 &#8209;suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 &#8209;suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 &#8209;suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Tosielämän suorituskykyä, jolla saat aikaan enemmän",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 5 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 5 -SUORITIN",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 &#8209;suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 &#8209;suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 &#8209;suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Tehokasta suorituskykyä, jolla saat aikaan enemmän",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 7 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 7 -SUORITIN",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 &#8209;suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 &#8209;suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 &#8209;suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "tr_title": "Älä tingi pelikokemuksestasi",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 5 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 5 -SUORITIN",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 7 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 7 -SUORITIN",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 9 &#8209;suoritin",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 9 -SUORITIN",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ydintä</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores-ydintä",
                    "tr_badgeSubTitle": "[e_core] E-cores-ydintä",
                    "tr_badgeSubTitle1": "[l_core] LPE-ydintä"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>säiettä</span></span>",
                    "tr_badgePreTitle": "[p_thread] P-cores-ytimissä",
                    "tr_badgeSubTitle": "[e_thread] E-cores-ytimissä",
                    "tr_badgeSubTitle1": "[l_thread] LPE-ytimissä"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 5 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 7 -suoritin",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 9 -suoritin",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Älä tingi pelikokemuksestasi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Älä tingi pelikokemuksestasi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Älä tingi pelikokemuksestasi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Älä tingi pelikokemuksestasi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Älä tingi pelikokemuksestasi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Älä tingi pelikokemuksestasi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Pienyrityksetkin ansaitsevat&nbsp;kattavia liiketoimintaratkaisuja",
                "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i5 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i5 -SUORITIN",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Vähentää melua ja sumentaa taustasi",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Suorituskyvyn automaattinen räätälöinti priorisoi sitä, mitä teet eniten",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Saat sujuvat, laadukkaat verkkopuhelut",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Heti valmiit, laitteistopohjaiset suojaustoiminnot",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Auttaa suojaamaan laitteita ja tietoja fyysisiltä peukalointihyökkäyksiltä",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Pienyrityksetkin ansaitsevat&nbsp;kattavia liiketoimintaratkaisuja",
                "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i7 -SUORITIN",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Vähentää melua ja sumentaa taustasi",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Suorituskyvyn automaattinen räätälöinti priorisoi sitä, mitä teet eniten",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Saat sujuvat, laadukkaat verkkopuhelut",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Heti valmiit, laitteistopohjaiset suojaustoiminnot",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Auttaa suojaamaan laitteita ja tietoja fyysisiltä peukalointihyökkäyksiltä",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Pienyrityksetkin ansaitsevat&nbsp;kattavia liiketoimintaratkaisuja",
                "tr_gen_core_title": "13. sukupolven Intel®&nbsp;Core™&nbsp;i9 &#8209;suoritin",
                "tr_gen_core_title_uppercase": "13. SUKUPOLVEN INTEL® CORE™ i9 -SUORITIN",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "MITEN TÄMÄN SUORITTIMEN TEHO PÄRJÄÄ VERTAILUSSA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Nykyinen laite",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Vähentää melua ja sumentaa taustasi",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Suorituskyvyn automaattinen räätälöinti priorisoi sitä, mitä teet eniten",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Saat sujuvat, laadukkaat verkkopuhelut",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Heti valmiit, laitteistopohjaiset suojaustoiminnot",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Auttaa suojaamaan laitteita ja tietoja fyysisiltä peukalointihyökkäyksiltä",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Vapauta uudet AI-elämykset liiketoiminnalle",
                "tr_title": "Ole valmiina tulevaan Intel® Core™ Ultra&nbsp;[XX] &#8209;suorittimien ja Intel vPro® &#8209;alustan avulla",
                "tr_gen_core_title": "Intel® Core™ Ultra [XX] -suoritin",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "TEHOSTA LIIKETOIMINTAA INTELIN AI-TIETOKONEIDEN AVULLA",
                    "tr_badgeSubTitle": "Mahtavan käyttökokemuksen tarjoavat tehokkaat tietokoneet ovat yrityksen tuottavuuden ytimessä. Intel vPro® -alusta parantaa työntekijöiden tehokkuutta ja suorituskykyä eri kuormituksissa ja sovelluksissa uuden arkkitehtuurin ansiosta. Se tarjoaa erinomaisen ja tehokkaan suorituskyvyn liiketoiminnan useille monimutkaisille kuormituksille lähettämällä oikean tehtävän oikeaan moduuliin oikeaan aikaan.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "MONIKERROKSISET SUOJAUSOMINAISUUDET HETI KÄYTÖSSÄ",
                    "tr_badgeSubTitle": "Uudet ja nousevat uhat edellyttävät kokonaisvaltaista lähestymistapaa suojaukseen. Intel vPro® -alusta tarjoaa suojauksen laitteistotasolla sekä koko pinossa, jotta yrityksesi pysyy paremmin suojattuna. Nämä ainutlaatuiset valmiit suojaustoimet, mukaan lukien tekoälypohjaiset uhkien havaitsemisominaisuudet, auttavat suojaamaan käyttäjiä, tietoja ja liiketoimintaa.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "VALMIIT TYÖKALUT KÄYTTÄJIEN TUOTTAVUUDEN SÄILYTTÄMISEKSI KAIKKIALLA",
                    "tr_badgeSubTitle": "Toimistot sijaitsevat eri paikoissa, ja niin myös tietokoneet. Intel vPro® -alusta tarjoaa moderneja laitteistopohjaisia hallintaominaisuuksia, joiden avulla yritykset voivat helpottaa PC-joukon hallintaa, yksinkertaistaa tukea ja parantaa käyttökokemusta mahdollistaen samalla kestävät tietojenkäsittelykäytännöt.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Enemmän kuin upea tietokone: AI-tietokone",
                "tr_subtitle1": "TEHON TAKAAVAT INTEL® CORE™ ULTRA -SUORITTIMET (SARJA 2)",
                "tr_subtitle2": "Intel AI -tietokoneella se on mahdollista",
                "tr_subtitle": "Intelin tehokkain, turvallisin ja suorituskykyisin mobiilisuoritin<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 &#8209;suoritin (Sarja&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Lisää luovuutta</span> nopeaan luomiseen tekoälytyökalujen avulla"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Lisää tuottavuutta</span> aikaa säästävän tekoälyavustajan tuella"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Lisää suojausta</span> sisäänrakennetun tietosuojan avulla"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Enemmän kuin upea tietokone: AI-tietokone",
                "tr_subtitle1": "TEHON TAKAAVAT INTEL® CORE™ ULTRA -SUORITTIMET (SARJA 2)",
                "tr_subtitle2": "Intel AI -tietokoneella se on mahdollista",
                "tr_subtitle": "Intelin tehokkain, turvallisin ja suorituskykyisin mobiilisuoritin<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 &#8209;suoritin (Sarja&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Lisää luovuutta</span> nopeaan luomiseen tekoälytyökalujen avulla"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Lisää tuottavuutta</span> aikaa säästävän tekoälyavustajan tuella"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Lisää suojausta</span> sisäänrakennetun tietosuojan avulla"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Enemmän kuin upea tietokone: AI-tietokone",
                "tr_subtitle1": "TEHON TAKAAVAT INTEL® CORE™ ULTRA -SUORITTIMET (SARJA 2)",
                "tr_subtitle2": "Intel AI -tietokoneella se on mahdollista",
                "tr_subtitle": "Intelin tehokkain, turvallisin ja suorituskykyisin mobiilisuoritin<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 &#8209;suoritin (Sarja&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Enemmän kuin upea tietokone: AI-tietokone",
                "tr_subtitle1": "TEHON TAKAAVAT INTEL® CORE™ ULTRA -SUORITTIMET (SARJA 2)",
                "tr_subtitle2": "Intel AI -tietokoneella se on mahdollista",
                "tr_subtitle": "Intelin tehokkain, turvallisin ja suorituskykyisin mobiilisuoritin<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 &#8209;suoritin (Sarja&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_tileTitle1": "Erillinen näytönohjain",
                "tr_title": "MIKÄ ON GRAFIIKKASUORITIN?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Grafiikkasuoritin eli GPU muodostaa näytöllä näkemäsi kuvat.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integroitu grafiikkasuoritin",
                    "tr_badgeSubTitle": "Integroitu grafiikkasuoritin käyttää samaa RAM-muistia kuin muu tietokone, mikä säästää sen kuluttamaa energiaa.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Erillinen grafiikkasuoritin",
                    "tr_badgeSubTitle": "Erillinen grafiikkasuoritintyyppi on kaikista tehokkain, sillä se käyttää omaa RAM-muistia ja muita resursseja, joita käytetään ainoastaan huippuluokan grafiikan tuottamiseen.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Hybridigrafiikkasuoritin",
                    "tr_badgeSubTitle": "Erillinen grafiikkasuoritintyyppi on kaikista tehokkain, sillä se käyttää omaa RAM-muistia ja muita resursseja, joita käytetään ainoastaan huippuluokan grafiikan tuottamiseen.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Kevyeeseen valokuva- ja videomuokkaukseen",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Katso 4K-videoita",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Pelaa suosittuja pelejä huikealla tarkkuudella",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Kaksoisgrafiikkasuoritin",
                    "tr_badgeSubTitle": "Esimerkiksi järjestelmät, joissa on Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -näytönohjain, voivat hyödyntää sekä suorittimeen integroidun että erillisen grafiikkasuorittimen tehoa grafiikan käsittelyssä.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integroitu grafiikkasuoritin",
                    "tr_badgeSubTitle": "Samassa sirussa keskussuorittimen kanssa on integroitu grafiikkasuoritin. Se käyttää muun tietokoneen kanssa samaa RAM-muistia ja muita resursseja lisäten näin energiatehokkuutta ja mahdollistaen ohuemman ja kevyemmän laitteen.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Erillinen grafiikkasuoritin",
                    "tr_badgeSubTitle": "Erillinen grafiikkasuoritintyyppi on kaikista tehokkain, sillä se käyttää omaa RAM-muistia ja muita resursseja, joita käytetään ainoastaan huippuluokan grafiikan tuottamiseen.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Tavallinen",
                    "tr_badgeSubTitle": "Jopa 4 Gt VRAM-muistia",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Kilpailu&shy;kykyinen",
                    "tr_badgeSubTitle": "Jopa 8 Gt VRAM-muistia",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Yli 8 Gt VRAM-muistia",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "Intensiivinen grafiikkateho",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Huipputehokas grafiikankäsittely vaatii erillisen näytönohjaimen, joka voi keskittyä vain kauniiden, terävien kuvien ja sulavan animaation luomiseen.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "Runsas ja elävä grafiikka",
                "tr_pretitle": "Intel® Iris® Plus -näytönohjain",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Iris® Plus -näytönohjaimella uppoudut 4K HDR -videoihin ja Full HD -peleihin ilman erillistä näytönohjainta.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "Upeat visualisoinnit, vaivaton suorituskyky",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® -Grafiikka tarjoaa näytönohjaimen mullistavan, integroidun suorituskyvyn, joka tukee sujuvaa suunnittelua sisällöntuottajille sekä monipuolisia pelikokemuksia.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "Intel® Iris® Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "Intel® UHD Graphics hyödyntää X<sup class='small_text_sup_xe'>e</sup>-arkkitehtuuria",
                "tr_pretitle": "Mukaansatempaavat visuaaliset kokemukset",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Koe virtaviivaistettu valokuvien ja videoiden muokkaus, nauti ensiluokkaisesta 4K-videosisällöstä tai pelaa suosittuja pelejä huikealla tarkkuudella.",
                "tr_photo_text": "Kevyeeseen valokuva- ja videomuokkaukseen",
                "tr_video_text": "Katso 4K-videoita",
                "tr_game_text": "Pelaa suosittuja pelejä huikealla tarkkuudella",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "EI PELKÄSTÄÄN HYVÄN NÄKÖINEN",
                "tr_subtitle": "Näytönohjain toimii tiiviisti suorittimen kanssa ja määrää pelien sujuvuuden ja ulkoasun. Video-RAM (VRAM), joka on varattu pelkästään grafiikan tuottamiseen, kertoo heti näytönohjaimen tehokkuudesta.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] Gt<br/></span><span class='subtitle-value'>VRAM-muistia</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PALJON GRAFIIKKAA SISÄLTÄVILLE PELEILLE",
                "tr_subtitle": "Huipputehokas grafiikankäsittely vaatii erillisen näytönohjaimen, joka pystyy keskittymään kauniiden, terävien kuvien ja sulavien animaatioiden luomiseen.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] Gt<br/></span><span class='subtitle-value'>VRAM-muistia</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PALJON GRAFIIKKAA SISÄLTÄVILLE PELEILLE",
                "tr_subtitle": "Huipputehokas grafiikankäsittely vaatii erillisen näytönohjaimen, joka pystyy keskittymään kauniiden, terävien kuvien ja sulavien animaatioiden luomiseen.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] Gt<br/></span><span class='subtitle-value'>VRAM-muistia</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "EI PELKÄSTÄÄN HYVÄN NÄKÖINEN",
                "tr_subtitle": "Näytönohjain toimii tiiviisti suorittimen kanssa ja määrää pelien sujuvuuden ja ulkoasun. Video-RAM (VRAM), joka on varattu pelkästään grafiikan tuottamiseen, kertoo heti näytönohjaimen tehokkuudesta.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] Gt<br/></span><span class='subtitle-value'>VRAM-muistia</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "Intensiivinen grafiikkateho",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Huipputehokas grafiikankäsittely vaatii erillisen näytönohjaimen, joka voi keskittyä vain kauniiden, terävien kuvien ja sulavan animaation luomiseen.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "PALJON GRAFIIKKAA SISÄLTÄVILLE PELEILLE",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Huipputehokas grafiikankäsittely vaatii erillisen näytönohjaimen, joka voi keskittyä vain kauniiden, terävien kuvien ja sulavan animaation luomiseen.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] Gt<br/></span><span class='subtitle-value'>VRAM-muistia</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Huipputehokas grafiikankäsittely vaatii erillisen näytönohjaimen, joka pystyy keskittymään kauniiden, terävien kuvien ja sulavien animaatioiden luomiseen.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Runsaasti grafiikkatehoa peliin vähimmäisvaatimusten täyttämiseksi",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Vaativille pelaajille, jotka tarvitsevat huipputehoa pelaamiseen",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Kokeneille pelaajille, jotka käyttävät tietokonettaan myös raskaaseen sisällön luontiin",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Valloittavia videoita ja mukaansatempaavaa pelaamista",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Ota kaikki irti tarkasta näytöstä ja nauti Intel® HD Graphics tarjoamasta monipuolisesta multimediakokemuksesta ja sisäänrakennetusta grafiikankäsittelystä.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Tehoa eloisiin videoihin ja kuviin",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Enemmän yksityiskohtia ja kirkkaampia värejä suoratoistovideoihin ja kuviin.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "Uskomattoman upeaa luomista ja pelaamista",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;näytö&shy;nohjain",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -näytönohjain tuo kevyisiin ja ohuisiin tietokoneisiin uraauurtavan grafiikkatehon, joka takaa suorituskyvyn niin mukaansatempaavassa viihteessä, edistyneessä sisällön luomisessa kuin suosikkipeleissä.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_pretitle": "PALJON GRAFIIKKAA SISÄLTÄVILLE PELEILLE",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;näytö&shy;nohjain",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Huipputehokas grafiikankäsittely vaatii erillisen näytönohjaimen, joka pystyy keskittymään kauniiden, terävien kuvien ja sulavien animaatioiden luomiseen.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] Gt<br/></span><span class='subtitle-value'>VRAM-muistia</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "Vapauta luovuutesi",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX -näytönohjain",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Hoida luovat projektit lennokkaasti ja uppoudu erilaisiin peleihin suorittimeen integroidun ja ensimmäisen erillisen Intel® -grafiikkasuorittimen yhdistetyn tehon avulla.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX -näytönohjain",
                "tr_pretitle": "PALJON GRAFIIKKAA SISÄLTÄVILLE PELEILLE",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Huipputehokas grafiikankäsittely vaatii erillisen näytönohjaimen, joka pystyy keskittymään kauniiden, terävien kuvien ja sulavien animaatioiden luomiseen.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] Gt<br/></span><span class='subtitle-value'>VRAM-muistia</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Näytönohjain",
                "tr_title": "Upea grafiikka sisäänrakennettuna",
                "tr_subtitle": "Pelit, elokuvat ja verkkovideot näkyvät äärimmäisen selkeinä ja yksityiskohtaisina.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näytönohjain",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Näytönohjain",
                    "tr_specText": "Tehosta työnkulkua"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Näytönohjain",
                    "tr_specText": "Viritä pelaamisesi huippuunsa"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Näytönohjain",
                    "tr_specText": "Viritä pelaamisesi huippuunsa"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Näytönohjain",
                    "tr_specText": "Pelaa täysin uudella tasolla"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "VAPAUTA LUOVUUTESI",
                    "tr_specText": "Nopeuta tuottavuuttasi kaikkialla kattavilla mediaominaisuuksilla, kuten <strong>alan ensimmäisellä AV1-laitteistokoodauksella</strong> ja tekoälyn nopeuttamalla sisällön luonnilla.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "VAPAUTA LUOVUUTESI",
                    "tr_specText": "Nopeuta tuottavuuttasi kattavilla mediaominaisuuksilla, kuten <strong>alan ensimmäisellä AV1-laitteistokoodauksella</strong> ja tekoälyn nopeuttamalla sisällön luonnilla.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "VAPAUTA LUOVUUTESI",
                    "tr_specText": "Koe huippuluokkainen, tekoälyn nopeuttama sisällön luonti <strong>alan ensimmäisellä AV1-laitteistokoodauksella</strong> ja kehittyneellä <strong>X<sup>e</sup>-mediamoottorilla.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "VAPAUTA LUOVUUTESI",
                    "tr_specText": "Koe huippuluokkainen, tekoälyn nopeuttama sisällön luonti <strong>alan ensimmäisellä AV1-laitteistokoodauksella</strong> ja kehittyneellä <strong>X<sup>e</sup>-mediamoottorilla.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "VAPAUTA LUOVUUTESI",
                    "tr_specText": "Koe huippuluokkainen, tekoälyn nopeuttama sisällön luonti <strong>alan ensimmäisellä AV1-laitteistokoodauksella</strong> ja kehittyneellä <strong>X<sup>e</sup>-mediamoottorilla.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "KANNETTAVUUS JA SUORITUSKYKY KOHDALLAAN",
                    "tr_specText": "Pelaa uusimpia pelejä missä tahansa ja nauti huippuunsa viritetystä suorituskyvystä ja tarkkuudesta moderneilla peliominaisuuksilla. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI -skaalausteknologia parempaa suorituskykyä ja sujuvaa pelaamista varten. <br/><br/><strong>DirectX* 12 Ultimate -tuki:  </strong>Vie pelaaminen uudelle realismin tasolle grafiikkateknologioilla, kuten säteenseurannalla ja mukautuvalla varjostuksella."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "OTA PELIT HALLINTAAN ",
                    "tr_specText": "<strong>Intel® Arc™ Controlin</strong> avulla voit hienosäätää kokemustasi intuitiivisella ja modernilla asettelulla. Se lataa ohjainpäivitykset saumattomasti ja optimoi pelit puolestasi."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "OTA PELIT HALLINTAAN ",
                    "tr_specText": "<strong>Intel® Arc™ Controlin</strong> avulla voit hienosäätää kokemustasi intuitiivisella ja modernilla asettelulla. Se lataa ohjainpäivitykset saumattomasti ja optimoi pelit puolestasi."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "OTA PELIT HALLINTAAN ",
                    "tr_specText": "Lataa ohjainpäivitykset saumattomasti ja optimoi suosikkipelit <strong>Intel® Arc™ Control</strong> -ohjelmiston avulla."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAKSIMOI INTEL® -ALUSTASI",
                    "tr_specText": "Parita yhteensopivien Intel® Core™ -suorittimien kanssa ja vapauta <strong>Intel® Deep Link -teknologia</strong> luomisen, pelaamisen ja suoratoiston käyttökokemuksen parantamiseksi."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MAKSIMOI INTEL® -JÄRJESTELMÄSI",
                    "tr_specText": "Parita yhteensopivien Intel® Core™ -suorittimien kanssa ja vapauta <strong>Intel® Deep Link -teknologia</strong> luomisen, pelaamisen ja suoratoiston käyttökokemuksen parantamiseksi."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAKSIMOI INTEL® -ALUSTASI",
                    "tr_specText": "Parita yhteensopivien Intel® Core™ -suorittimien kanssa ja vapauta <strong>Intel® Deep Link -teknologia</strong> luomisen, pelaamisen ja suoratoiston käyttökokemuksen parantamiseksi."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAKSIMOI INTEL® -ALUSTASI",
                    "tr_specText": "Parita yhteensopivien Intel® Core™ -suorittimien kanssa ja vapauta <strong>Intel® Deep Link -teknologia</strong> luomisen, pelaamisen ja suoratoiston käyttökokemuksen parantamiseksi."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAKSIMOI INTEL® -JÄRJESTELMÄSI",
                    "tr_specText": "Parita yhteensopivien Intel® Core™ -suorittimien kanssa ja vapauta <strong>Intel® Deep Link -teknologia</strong> luomisen, pelaamisen ja suoratoiston käyttökokemuksen parantamiseksi."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "OLET PÄÄOSASSA",
                    "tr_specText": "Lähetä saumattomasti <strong>Intel® Arc™ Control</strong>  -ohjelmiston kehittyneiden suoratoistoteknologioiden avulla."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "OLET PÄÄOSASSA",
                    "tr_specText": "Lähetä saumattomasti <strong>Intel® Arc™ Control</strong>  -ohjelmiston kehittyneiden suoratoistoteknologioiden avulla."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "NAUTI MUKAANSATEMPAAVASTA, SUORITUSKYKYISESTÄ PELAAMISESTA",
                    "tr_specText": "Aseta suorituskykysi etusijalle uusimmissa AAA-luokan peleissä suurilla tarkkuuksilla ja virkistystaajuuksilla. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI -skaalausteknologia parempaa suorituskykyä ja sujuvaa pelaamista varten. <br/><br/><strong>DirectX* 12 Ultimate -tuki:  </strong>Vie pelaaminen uudelle realismin tasolle grafiikkateknologioilla, kuten säteenseurannalla ja mukautuvalla varjostuksella."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "NAUTI MUKAANSATEMPAAVASTA, SUORITUSKYKYISESTÄ PELAAMISESTA",
                    "tr_specText": "Aseta suorituskykysi etusijalle uusimmissa AAA-luokan peleissä suurilla tarkkuuksilla ja virkistystaajuuksilla. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI -skaalausteknologia parempaa suorituskykyä ja sujuvaa pelaamista varten. <br/><br/><strong>DirectX* 12 Ultimate -tuki:  </strong>Vie pelaaminen uudelle realismin tasolle grafiikkateknologioilla, kuten säteenseurannalla ja mukautuvalla varjostuksella."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "ANNA PELEILLE POTKUA",
                    "tr_specText": "Vie pelikokemuksesi uudelle tasolle: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI -skaalausteknologia parempaa suorituskykyä ja sujuvaa pelaamista varten. <br/><br/><strong>DirectX* 12 Ultimate -tuki:  </strong>Vie pelaaminen uudelle realismin tasolle grafiikkateknologioilla, kuten säteenseurannalla ja mukautuvalla varjostuksella."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "NAUTI NOPEATEMPOISESTA JA MONIPUOLISESTA PELAAMISESTA",
                    "tr_specText": "Nauti sulavasta pelaamisesta suosituissa AAA-luokan peleissä sekä suuren päivitysnopeuden pelaamisesta niin intensiivisissä e-urheilun ammuntapeleissä kuin nopeatempoisissa RTS-suosikeissa. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI -skaalausteknologia parempaa suorituskykyä ja sujuvaa pelaamista varten. <br/><br/><strong>DirectX* 12 Ultimate -tuki:  </strong>Vie pelaaminen uudelle realismin tasolle grafiikkateknologioilla, kuten säteenseurannalla ja mukautuvalla varjostuksella."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "NAUTI MUKAANSATEMPAAVASTA, SUORITUSKYKYISESTÄ PELAAMISESTA",
                    "tr_specText": "Aseta suorituskykysi etusijalle uusimmissa AAA-luokan peleissä suurilla tarkkuuksilla ja virkistystaajuuksilla. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI -skaalausteknologia parempaa suorituskykyä ja sujuvaa pelaamista varten. <br/><br/><strong>DirectX* 12 Ultimate -tuki:  </strong>Vie pelaaminen uudelle realismin tasolle grafiikkateknologioilla, kuten säteenseurannalla ja mukautuvalla varjostuksella."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Näytönohjain",
                    "tr_specText": "Näytönohjainten universumi, jossa on tilaa kaikille"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "NOPEUTA KUVATAAJUUTTA, NOPEUTA PELAAMISTA",
                    "tr_specText": "Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling -teknologia (X<sup class='small_text_sup_xe'>e</sup>SS) vie pelaamisen seuraavalle tasolle tekoälypohjaisen skaalauksen avulla – saat parhaan suorituskyvyn ja suuren kuvatarkkuuden. X<sup class='small_text_sup_xe'>e</sup>SS on optimoitu Intel® Arc™ -näytönohjaintuotteita varten, joten voit hyödyntää täysin Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX) -laitteistokiihdytystä."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "PARHAAT VISUALISOINNIT OVAT TÄÄLLÄ",
                    "tr_specText": "Kaikissa Intel® Arc™ -näytönohjaimissa on täysi DirectX* 12 Ultimate -tuki, ja voit nauttia upeasta ja mukaansatempaavasta pelaamisesta uusimpien näytönohjainteknologioiden ansiosta. Mukana ovat seuraavan sukupolven pelaamisen perusominaisuudet: sädeseuranta, muuttuvatehoinen sävytys, verkkosävytys ja mallintajan palaute."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "SAUMATON KUVIEN, VIDEOIDEN JA MEDIASISÄLLÖN LUOMINEN",
                    "tr_specText": "Vapauta mielikuvituksesi ja vangitse yleisöt monipuolisen digitaalisen sisällön luonnin avulla käyttäen erittäin edistynyttä mediakonetta, jota tehostavat tekoäly ja Intel® -teknologia. Luo näyttävää sisältöä, jonka mahdollistaa kaikkia johtavia mediasisällön muotoja tukeva näytönohjain, ja pysy ajan tasalla edistyneistä AV1-videokoodauksen ominaisuuksista."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "KOE HUIPPULUOKAN TEKOÄLYPOHJAINEN LUOMINEN JA PELAAMINEN",
                    "tr_specText": "Intel® Arc™ -näytönohjain avaa tehokkaita tekoälyominaisuuksia aina edistyneestä luomisesta mukaansatempaavaan pelaamiseen. Saat kaiken irti tämän päivän sisällönluontiohjelmistosta, mukaan lukien teksti kuvaksi -generoinnin ja tekoälyn tehostaman videoiden muokkauksen. Lisäksi voit hyödyntää suuren kuvatarkkuuden pelaamista tekoälypohjaisen X<sup class='small_text_sup_xe'>e</sup>SS-skaalauksen avulla."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Näytönohjain",
                    "tr_specText": "Ammattimainen grafiikka alkaa täältä"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "MAHTAVA LIIKKUVILLE LUOVAN TYÖN TEKIJÖILLE, SUUNNITTELIJOILLE JA INSINÖÖREILLE",
                    "tr_specText": "Saatavilla tietyissä H-sarjan Intel® Core™ Ultra -suorittimissa, joissa valmis Intel® Arc™ Pro -näytönohjain. Avaa kaksinkertainen suorituskyvyn parannus ja riippumattoman ohjelmistotoimittajan (ISV) sertifioinnit, jotta luotettavuutta voidaan lisätä ja tarjota tehokasta tekoälyn ja sädeseurannan laitteistokiihdytystä upeaa sisällön luontia varten liikkeellä ollessa.<br/><br/><span class='expandable_heading'>Ohjelmistosertifioinnit<br/></span><br/>Intel on työskennellyt satojen ohjelmistoyritysten kanssa vuosien varrella, ja tämä ainutlaatuinen kokemus toteutuu A-sarjan Intel® Arc™ Pro -näytönohjaimissa. Sertifioinnit ovat meille yhtä tärkeitä kuin sinulle."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "SAAVUTETTAVA TEKOÄLY",
                    "tr_specText": "Tämä grafiikkasuorittimien sarja sisältää laajan valikoiman upeaa teknologiaa, mukaan lukien yhteensopivien tekoälytyökalujen tuen sekä valmiin erillisen tekoälykiihdytyksen tarvittaessa."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "SÄDESEURANTA ILMAN LISÄHINTAA",
                    "tr_specText": "Näytönohjainten on kyettävä tekemään enemmän kuin vain siirtämään pikseleitä näytölle. Niiden on myös nopeutettava kauniiden kuvien luomista. A-sarjan Intel® Arc™ Pro -näytönohjaimeen rakennettu sädeseurannan laitteistotekniikka antaa yhteensopivan ohjelmiston aloittaa näiden uusien ammattimaisten työnkulkujen tutkimisen. Sädeseurantaa pidetään yleensä osana kalliita näytönohjaimia, mutta Intelin uusin ammattimaisten näytönohjainten valikoima haastaa tämän odotuksen."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "MUKAANSATEMPAAVAT NÄYTÖT SISÄLLÖNTUOTTAJIEN AVUKSI",
                    "tr_specText": "Kaikissa Intel® Arc™ -näytönohjaimissa on täysi DirectX* 12 Ultimate -tuki, ja voit nauttia upeasta ja mukaansatempaavasta pelaamisesta uusimpien näytönohjainteknologioiden ansiosta. Mukana ovat seuraavan sukupolven pelaamisen perusominaisuudet: sädeseuranta, muuttuvatehoinen sävytys, verkkosävytys ja mallintajan palaute."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "DYNAAMISESTI OPTIMOIDUT KUVAT",
                    "tr_specText": "Pro-tason näytönohjainten erillisen laitteiston dekoodaamisen avulla Dolby Vision* lisää syvyyttä, terävää kontrastia ja enemmän värejä yhteensopivaan näyttöösi. Dolby Vision* avaa HDR-teknologian täyden potentiaalin optimoimalla dynaamisesti kuvan laatua palvelun, näyttölaitteen ja alustan perusteella mahdollistaakseen upeat visualisoinnit joka kerta."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "AMMATTILAISTEN VAHVISTAMA OHJELMISTO",
                    "tr_specText": "Intel on työskennellyt satojen ohjelmistoyritysten kanssa vuosien varrella, ja tämä ainutlaatuinen kokemus toteutuu ammattimaisten A-sarjan Intel® Arc™ Pro -näytönohjainten valikoimassa. Sertifioinnit ja ohjelmiston työnkulun pätevyys ovat meille yhtä tärkeitä kuin sinulle."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "SÄDESEURANTA ILMAN LISÄHINTAA",
                    "tr_specText": "Näytönohjainten on kyettävä tekemään enemmän kuin vain siirtämään pikseleitä näytölle. Niiden on myös nopeutettava kauniiden kuvien luomista. A-sarjan Intel® Arc™ Pro -näytönohjaimeen rakennettu sädeseurannan laitteistoteknologia antaa yhteensopivan ohjelmiston aloittaa näiden uusien ammattimaisten työnkulkujen tutkimisen. Sädeseurantaa pidetään yleensä osana kalliita näytönohjaimia, mutta Intelin uusin ammattimaisten näytönohjainten valikoima haastaa tämän odotuksen."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "USEITA SUURIA NÄYTTÖÄ JA YKSI PIENI MOBIILIKÄYTTÖINEN GRAFIIKKASUORITIN",
                    "tr_specText": "Kannettavien Intel® Arc™ Pro -näytönohjain voi tukea jopa neljää erittäin suurta näyttöä ja tehostaa työnkulkuasi. Lisätään vielä dynaamisen alueen (HDR) tuki, niin käytössäsi on kaikki tarpeellinen sosiaalisessa mediassa esittelyn arvoista, usean näytön koti- tai toimistokokoonpanoa varten."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "TÄYSI MUISTIN KAISTANLEVEYDEN POTENTIAALI",
                    "tr_specText": "Näytönohjaimen kaistanleveys on tärkeä osa työasemaa. Väärä valinta voi hidastaa ammattimaisen työnkulun, minkä johdosta varmistimme, että A-sarjan Intel® Arc™ Pro -grafiikkasuoritin tukee nykyaikaista PCIe* 4.0:aa ilman yhteensopivuusongelmia aiempien versioiden kanssa. Grafiikkamuistia on paranneltu suuren kaistanleveyden nopeudella, mikä mahdollistaa projektitietojen käytön ohjelmiston toimesta entistä nopeammin."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "HERÄTÄ JOKAINEN KUVA ELOON",
                    "tr_specText": "<span class='expandable_heading'>Tekoälyn tehostama. Intelin vahvistama.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS on tekoälyn tehostama skaalausteknologia, joka parantaa suorituskykyä säilyttäen jokaisen kuvan kauneuden – voit kokea pelaamisen niin kuin pitää."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "REAALIAIKAINEN.<br/> MUKAANSATEMPAAVA.<br/> TODENMUKAISUUTTA PARHAIMMILLAAN.",
                    "tr_specText": "Todenmukainen maailma odottaa. Valmiin sädeseurannan avulla voit kokea aivan kaiken, aina aidontuntuisista varjoista ja heijastuksista mestarilliseen valaistukseen. Kyse on toisin sanoen visuaalisesta pelikokemuksesta, jota et halua jättää väliin."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "PARAS PELAAMISESI – JAETTUNA",
                    "tr_specText": "Anna yleisön nähdä saumatonta pelaamista. Intel®&nbsp;Arc™ &#8209;näytönohjaimen laitteistokiihdytetyt AV1&#8209;koodausominaisuudet saavat heidät kiinnostumaan joka liikkeestä. Korkea visuaalinen laatu samalla bittinopeudella varmistaa parhaan suoratoiston säästäen samalla kaistanleveyttä."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Tervetuloa sisällöntuottajat",
                    "tr_specText": "Uudet ja parannellut tekoälykokemukset ovat sormien ulottuvilla. A-sarjan Intel®&nbsp;Arc™ &#8209;näytönohjaimiin upotettujen edistyneiden tekoälykiihdyttimien avulla vietät enemmän aikaa luomisen parissa ja vähemmän aikaa odottaen useissa sovelluksissa.<br/><br/>Vie videoiden luominen seuraavalle tasolle ja näytä tietä laadukkaan, tallennustilaltaan tehokkaan AV1&#8209;videon avulla, jonka mahdollistavat Intel®&nbsp;Arc™ &#8209;näytönohjaimeen rakennetut edistyneet AV1-laitteisto-ominaisuudet."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Käyttöjärjestelmä",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Kaikki mitä tarvitset asioiden hoitamiseen",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Tehokas suorituskyky pakattuna ohueksi, kevyeksi ja kestäväksi kokonaisuudeksi - voit saada kaiken. Mahtava akunkesto, kauniit kosketusnäytöt* ja uusimmat puolijohdeasemat (SSD:t) takaavat, että löydät etsimäsi Windows 10 -tietokoneen.",
                "tr_noteSection": "* Laitteiston mukaan",
                "titleArray": [
                  {
                    "tr_title": "Vähemmän on enemmän",
                    "tr_subtitle": "Nykypäivän tietokoneet käyttävät yleensä vähemmän virtaa, jotta akulla olisi enemmän käyttöikää. Tämä tarkoittaa keskimäärin kahdeksan tunnin videotoistoa.**",
                    "tr_noteSection": "** Akunkesto vaihtelee merkittävästi asetusten, käyttötapojen ja muiden tekijöiden mukaan."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Käyttöjärjestelmä",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "KAIKKI MITÄ TARVITSET ASIOIDEN HOITAMISEEN",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Tehokas suorituskyky pakattuna ohueksi, kevyeksi ja kestäväksi kokonaisuudeksi - voit saada kaiken. Mahtava akunkesto, kauniit kosketusnäytöt* ja uusimmat puolijohdeasemat (SSD:t) takaavat, että löydät etsimäsi Windows 10 -tietokoneen.",
                "tr_noteSection": "* Laitteiston mukaan",
                "titleArray": [
                  {
                    "tr_title": "VÄHEMMÄN ON ENEMMÄN",
                    "tr_subtitle": "Nykypäivän tietokoneet käyttävät yleensä vähemmän virtaa, jotta akulla olisi enemmän käyttöikää. Tämä tarkoittaa keskimäärin kahdeksan tunnin videotoistoa.**",
                    "tr_noteSection": "** Akunkesto vaihtelee merkittävästi asetusten, käyttötapojen ja muiden tekijöiden mukaan."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Käyttöjärjestelmä",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Virtaviivaistettu turvallisuutta ja suorituskykyä varten",
                "tr_pretitle": "Windows* 10 in S Mode",
                "tr_subtitle": "Windows* 10 in S mode -tilassa saat ylimääräisen suojauskerroksen, jonka ansiosta voit työskennellä, pelata ja selata turvallisesti, koska tiedät, että laitteesi on suojattu."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Käyttöjärjestelmä",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "VIRTAVIIVAISTETTU TURVALLISUUTTA JA SUORITUSKYKYÄ VARTEN",
                "tr_title": "Windows* 10 in S Mode",
                "tr_subtitle": "Windows* 10 in S mode -tilassa saat ylimääräisen suojauskerroksen, jonka ansiosta voit työskennellä, pelata ja selata turvallisesti, koska tiedät, että laitteesi on suojattu."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Käyttöjärjestelmä",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Tee mahtavia juttuja",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Suorituskyky, hinta ja paljon valinnanvaraa. Innovatiivisista hybridilaitteista ohuisiin ja kevyisiin kannettaviin, kosketusnäyttöihin ja irrotettaviin näppäimistöihin – löydät varmasti tyyliisi ja budjettiisi sopivan Windows*-tietokoneen."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Käyttöjärjestelmä",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "TEE MAHTAVIA JUTTUJA",
                "tr_title": "Windows*",
                "tr_subtitle": "Suorituskyky, hinta ja paljon valinnanvaraa. Innovatiivisista hybridilaitteista ohuisiin ja kevyisiin kannettaviin, kosketusnäyttöihin ja irrotettaviin näppäimistöihin – löydät varmasti tyyliisi ja budjettiisi sopivan Windows*-tietokoneen."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Käyttöjärjestelmä",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Käyttöjärjestelmä",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Muisti",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "KUINKA PALJON RAM-MUISTIA TARVITSET?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Arkikäyttö – 2–8 GT RAM-MUISTIA",
                    "tr_badgeSubTitle": "Ihanteellinen niille, jotka käyttävät tietokonettaan lähinnä tekstinkäsittelyyn ja verkkosurffailuun ja jotka keskittyvät tavallisesti vain yhteen ohjelmaan kerrallaan."
                  },
                  {
                    "tr_badgeTitle": "Tehokas – 8–16 GT RAM-MUISTIA",
                    "tr_badgeSubTitle": "Kun RAM-muistia on enemmän, sovellukset toimivat nopeammin ja voit myös käyttää useampia sovelluksia samanaikaisesti."
                  },
                  {
                    "tr_badgeTitle": "Huipputehokas – YLI 16 GT RAM-MUISTIA",
                    "tr_badgeSubTitle": "Jos sinua kiinnostaa pelaaminen, kuvien tai videoiden käsittely tai 3D-mallinnus, tarvitset lisää RAM-muistia näiden vaativien ohjelmien suorittamiseen."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MUISTI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] Gt RAM-muistia arkipäiväiseen moniajoon",
                "tr_subtitle": "Muisti sallii perussovellusten, kuten tekstinkäsittely- ja selainsovellusten, sulavan lataamisen ja suorittamisen.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] Gt<br/></span><span class='subtitle-value'>RAM-muistia</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MUISTI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] Gt RAM-muistia tehokkaaseen moniajoon",
                "tr_subtitle": "Jos käytät useita ohjelmia samanaikaisesti, [memory_total] Gt RAM-muistia tekee ohjelmien välillä vaihtelusta helppoa.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] Gt<br/></span><span class='subtitle-value'>RAM-muistia</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MUISTI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] Gt RAM-muistia edistyneeseen moniajoon",
                "tr_subtitle": "Muisti riittää useiden tehokkaiden ohjelmien suorittamiseen samanaikaisesti tietokoneen hidastumatta.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] Gt<br/></span><span class='subtitle-value'>RAM-muistia</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MUISTI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1] Gt muistia = [Value2] Gt Intel® Optane™ -muistia + [Value3] Gt RAM-muistia",
                "tr_subtitle": "Tällä muistiratkaisujen yhdistelmällä saat entistä responsiivisemman RAM-muistin sekä entistä nopeamman ja tehokkaamman kiintolevyn Intel® Optane -muistin ansiosta.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] Gt<br/></span> <span class='subtitle-value'>MUISTIA</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ -muisti",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Vähemmän odottelua. Enemmän kaikkea mistä pidät.",
                "tr_subtitle": "Yhdessä järjestelmämuistin kanssa Intel® Optane™ -muisti auttaa usein käyttämiäsi ohjelmia käynnistymään ja latautumaan välittömästi.",
                "tr_pretitle": "[XX] Gt Intel® Optane™ -muistia",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "MITÄ HYÖTYÄ MUISTISTA ON TIETOKONEELLENI?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Nopeuttaa sisällön luontia",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Parantaa pelien responsiivisuutta",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Helpottaa moniajoa",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Avaa ohjelmat nopeammin",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ -muisti",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Vauhdita pelaamista</span>",
                "tr_pretitle": "[XX] Gt Intel® Optane™ -muistia",
                "tr_pretitle1": "[XX] Gt:n Intel® Optane™ -muisti nopeuttaa pelaamista",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Pelit käynnistyvät jopa <br/><span class=\"optane-game\">98&nbsp;% nopeammin</span><br/>data-asemalta [optane_legal_processor_name] -suorittimen ja Intel® Optane™ -muistin avulla<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Tasot latautuvat jopa <br/><span class=\"optane-game\">4,1 kertaa nopeammin</span><br/>data-asemalta [optane_legal_processor_name] -suorittimen ja Intel® Optane™ -muistin avulla<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Intel® Optane™ -muisti",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Vauhdita pelaamista</span>",
                "tr_pretitle": "[XX] Gt Intel® Optane™ -muistia",
                "tr_pretitle1": "[XX] Gt:n Intel® Optane™ -muisti nopeuttaa pelaamista",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Pelit käynnistyvät jopa <br/><span class=\"optane-game\">98&nbsp;% nopeammin</span><br/>data-asemalta [optane_legal_processor_name] -suorittimen ja Intel® Optane™ -muistin avulla<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Tasot latautuvat jopa <br/><span class=\"optane-game\">4,1 kertaa nopeammin</span><br/>data-asemalta [optane_legal_processor_name] -suorittimen ja Intel® Optane™ -muistin avulla<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MUISTI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1] Gt muistia = [Value2] Gt Intel® Optane™ -muistia + [Value3] Gt RAM-muistia",
                "tr_subtitle": "Tällä muistiratkaisujen yhdistelmällä saat entistä responsiivisemman RAM-muistin sekä entistä nopeamman ja tehokkaamman kiintolevyn Intel® Optane -muistin ansiosta.",
                "titleArray": [
                  {
                    "tr_title": "[XX] Gt RAM-muistia moniajoon",
                    "tr_subtitle": "Jos käytät useita ohjelmia samanaikaisesti, [memory_total] Gt RAM-muistia tekee ohjelmien välillä vaihtelusta helppoa."
                  },
                  {
                    "tr_title": "Jokapäiväisiin tehtäviin",
                    "tr_subtitle": "Muisti sallii perussovellusten, kuten tekstinkäsittely- ja selainsovellusten, sulavan lataamisen ja suorittamisen."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MUISTI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] Gt RAM-muistia",
                "tr_pretitle": "ENEMMÄN MONIAJOA",
                "tr_subtitle": "RAM-muisti auttaa suoritinta käsittelemään ohjelmatiedostoja nopeammin ja tekemään useita tehtäviä samanaikaisesti.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Vakiomuisti",
                    "tr_badgeSubTitle": "Jopa 8 Gt RAM-muistia"
                  },
                  {
                    "tr_badgeTitle": "Lisämuisti",
                    "tr_badgeSubTitle": "Yli 8 Gt ja enintään 16 Gt RAM-muistia"
                  },
                  {
                    "tr_badgeTitle": "Huippumuisti",
                    "tr_badgeSubTitle": "Yli 16 Gt RAM-muistia"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MUISTI",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] Gt moniajoon",
                "tr_pretitle": "ENEMMÄN MONIAJOA",
                "tr_subtitle": "RAM-muisti auttaa suoritinta käsittelemään ohjelmatiedostoja nopeammin ja tekemään useita tehtäviä samanaikaisesti.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Vakiomuisti",
                    "tr_badgeSubTitle": "Jopa 8 Gt RAM-muistia"
                  },
                  {
                    "tr_badgeTitle": "Lisämuisti",
                    "tr_badgeSubTitle": "Yli 8 Gt ja enintään 16 Gt RAM-muistia"
                  },
                  {
                    "tr_badgeTitle": "Huippumuisti",
                    "tr_badgeSubTitle": "Yli 16 Gt RAM-muistia"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "MITEN MUISTI PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Tavallinen",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 Gt:n <span class='ram_value'>RAM-muisti</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Edistynyt",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 Gt:n <span class='ram_value'>RAM-muisti</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Paras",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 Gt:n <span class='ram_value'>RAM-muisti</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Muisti",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "MITEN MUISTI PÄRJÄÄ VERTAILUSSA?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "MITEN MUISTI PÄRJÄÄ VERTAILUSSA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Nykyinen laite",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Arkikäyttö",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 Gt:n <span class='ram_value'>RAM-muisti</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Tehokäyttö",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 Gt:n <span class='ram_value'>RAM-muisti</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Edistynyt",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 Gt:n <span class='ram_value'>RAM-muisti</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tallennustila",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "PALJONKO TILAA TIETOKONEESSA ON?",
                "tr_subtitle": "Sinun ei tarvitse arvailla. Näet täsmälleen, mitä voit tallentaa tähän tietokoneeseen.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Luo musiikkikirjasto</span> <br/><span class=\"storage-standard\">[num_songs] kappaleelle<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup> Keskimäärin 5 Mt musiikkikappaletta kohti",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Luo pelikirjasto</span> <br/><span class=\"storage-standard\">[num_games] pelille<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup> Keskimäärin 40 Gt peliä kohti",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Tallennustilaa</span><br/><span class=\"storage-standard\"> [num_videos] videolle<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup> Keskimäärin 5&nbsp;Gt videota kohti",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Ota ja tallenna</span> <br/><span class=\"storage-standard\">[num_photos] valokuvaa<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup> Keskimäärin 4&nbsp;Mt valokuvaa kohti",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity] Gt:n kiintolevy"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity] Gt:n SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory] Gt:n Intel® Optane™ &#8209;muisti + [convertedSSD] Gt:n SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "TALLENNUSTILA",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity] Gt:n SSD-asema",
                "tr_preTitle1": "[capacity] Tt:n SSD-asema",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Tässä tietokoneessa on SSD-asema ja [capacity] Gt tallennustilaa. Voit siis tallentaa melkoisen määrän kuvia, videoita ja ohjelmia sekä käyttää niitä huomattavasti nopeammin kuin kiintolevyltä.",
                    "tr_title": "Tietokoneessasi kaikelle on paikkansa"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Tässä tietokoneessa on SSD-asema ja [capacity] Tt tallennustilaa. Voit siis tallentaa melkoisen määrän kuvia, videoita ja ohjelmia sekä käyttää niitä huomattavasti nopeammin kuin kiintolevyltä.",
                    "tr_title": "Tässä laitteessa on runsaasti tallennustilaa"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] Gt:n<br/></span><span class='subtitle-value'>TALLENNUSTILA</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] Tt:n<br/></span><span class='subtitle-value'>TALLENNUSTILA</span> ",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "TALLENNUSTILA",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity] Gt:n kiintolevy",
                "tr_preTitle1": "[capacity] Tt:n kiintolevy",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Kiintolevyasemat ovat hyvä valinta niille, jotka haluavat paljon tallennustilaa suoraan tietokoneeseen.",
                    "tr_title": "Tietokoneessasi kaikelle on paikkansa"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Kiintolevyasemat ovat hyvä valinta niille, jotka haluavat paljon tallennustilaa suoraan tietokoneeseen.",
                    "tr_title": "Tässä laitteessa on runsaasti tallennustilaa"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>TALLENNUS&shy;TILAA</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "TALLENNUSTILA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "PALJONKO TILAA TIETOKONEESSA ON?",
                "tr_subtitle": "Sinun ei tarvitse arvailla. Näet täsmälleen, mitä voit tallentaa tähän tietokoneeseen.",
                "titleArray": [
                  {
                    "tr_title": "[XX] Gt:n kiintolevy"
                  },
                  {
                    "tr_title": "[XX] Gt:n SSD-asema"
                  },
                  {
                    "tr_title": "[total_capacity] Gt:n Intel® SSD -asema, jonka toimintaa nopeuttaa [optane_memory_size] Gt:n Intel® Optane™ -muisti"
                  },
                  {
                    "tr_title": "[total_capacity] Tt:n Intel® SSD -aseman toimintaa nopeuttaa [optane_memory_size] Gt:n Intel® Optane™ -muisti"
                  },
                  {
                    "tr_title": "[convertedSSD] Gt:n SSD-asema + [convertedHDD] Gt:n kiintolevy"
                  },
                  {
                    "tr_title": "[convertedSSD] Tt:n SSD-asema + [convertedHDD] Gt:n kiintolevy"
                  },
                  {
                    "tr_title": "[convertedSSD] Gt:n SSD-asema + [convertedHDD] Tt:n kiintolevy"
                  },
                  {
                    "tr_title": "[convertedSSD] Tt:n SSD-asema + [convertedHDD] Tt:n kiintolevy"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] Gt:n<br/></span><span class='subtitle-value'>TALLENNUSTILA</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] Gt:n<br/></span><span class='subtitle-value'>TALLENNUSTILA</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] Gt:n<br/></span><span class='subtitle-value'>TALLENNUSTILA</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] Tt:n<br/></span><span class='subtitle-value'>TALLENNUSTILA</span> ",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] Tt:n<br/></span><span class='subtitle-value'>TALLENNUSTILA</span> ",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] Tt:n<br/></span><span class='subtitle-value'>TALLENNUSTILA</span> ",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "TALLENNUSTILA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "[convertedSSD] Gt:n SSD-asema + [convertedHDD] Gt:n kiintolevy",
                "tr_preTitle1": "[convertedSSD] Tt:n SSD-asema + [convertedHDD] Gt:n kiintolevy ",
                "tr_preTitle2": "[convertedSSD] Gt:n SSD-asema + [convertedHDD] Tt:n kiintolevy ",
                "tr_preTitle3": "[convertedSSD] Tt:n SSD-asema + [convertedHDD] Tt:n kiintolevy ",
                "titleArray": [
                  {
                    "tr_title": "Tietokoneessasi kaikelle on paikkansa",
                    "tr_title1": "Tässä laitteessa on runsaasti tallennustilaa",
                    "tr_subtitle": "Tässä tietokoneessa on SSD-asema, jossa on [convertedSSD] Gt tallennustilaa, ja kiintolevyasema, jonka tallennustila on [convertedHDD] Gt. Tämä takaa sekä valtavan kapasiteetin että nopean tallennustehon.",
                    "tr_subtitle1": "Tässä tietokoneessa on SSD-asema, jossa on [convertedSSD] Tt tallennustilaa, ja kiintolevyasema, jonka tallennustila on [convertedHDD] Gt. Tämä takaa sekä valtavan kapasiteetin että nopean tallennustehon.",
                    "tr_subtitle2": "Tässä tietokoneessa on SSD-asema, jossa on [convertedSSD] Gt tallennustilaa, ja kiintolevyasema, jonka tallennustila on [convertedHDD] Tt. Tämä takaa sekä valtavan kapasiteetin että nopean tallennustehon.",
                    "tr_subtitle3": "Tässä tietokoneessa on SSD-asema, jossa on [convertedSSD] Tt tallennustilaa, ja kiintolevyasema, jonka tallennustila on [convertedHDD] Tt. Tämä takaa sekä valtavan kapasiteetin että nopean tallennustehon."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] Gt:n<br/></span><span class='subtitle-value'>TALLENNUSTILA</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] Tt:n<br/></span><span class='subtitle-value'>TALLENNUSTILA</span> ",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "TALLENNUSTILA",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Korkean kapasiteetin tallennustila kiihtyvään käyttöön Intel® Optane™ -muistin ja SSD:n voimalla.",
                "tr_title": "Tervetuloa, valtavat tiedostot",
                "tr_pretitle": "[total_capacity] Gt:n Intel® SSD -asema, jonka toimintaa nopeuttaa [optane_memory_size] Gt:n Intel® Optane™ -muisti",
                "tr_pretitle1": "[total_capacity] Tt:n Intel® SSD -aseman toimintaa nopeuttaa [optane_memory_size] Gt:n Intel® Optane™ -muisti",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Tässä vallankumouksellisessa tallennusratkaisussa yhdistyvät ensimmäistä kertaa Intel® Optane™ -muisti ja SSD samassa sirussa. Se tarjoaa seuraavat edut:",
                    "tr_title": "MIKÄ ON INTEL® OPTANE™ -MUISTI JA SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Avaaminen vie vain hetken",
                    "tr_badgeSubTitle": "Esilataamalla usein käytetyt tiedostot ja ohjelmat sinulle jää enemmän aikaa tekemiseen odottelun sijaan.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Tallennustilaa ja nopeutta",
                    "tr_badgeSubTitle": "Tallennustila riittää mediatiedostoille SSD:n nopean suorituskyvyn ansiosta.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Responsiivisempi pitkällä aikavälillä",
                    "tr_badgeSubTitle": "Intel® Optane™ -muisti SSD:n kanssa oppii toimintatapasi ja tekee näin tietokoneestasi tehokkaamman.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "TALLENNUSTILA",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Mediatiedostosi ovat kätesi ulottuvilla luotettavassa tallennusvälineessä.",
                "tr_title": "Tilaa kaikille kuville, videoille ja musiikkikappaleillesi"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näyttö",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] miljoonaa pikseliä – pyyhkäise, nipistä ja hämmästy",
                "tr_subtitle": "Käytä tietokonetta kuin älypuhelinta intuitiivisilla ohjaimilla ja nauti terävämmistä ja kirkkaammista yksityiskohdista.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Tarkkuus: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pikselit: [screenResolution] miljoonaa"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]\" näyttö",
                    "tr_badgeSubTitle": "Näytön korkeus: [screenHeight] tuumaa",
                    "tr_badgePreTitle": "Näytön leveys: [YY]\""
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Kosketusnäyttö",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näyttö",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Nauti [screenResolution] miljoonasta pikselistä",
                "tr_subtitle": "Pikselit ovat kaikki kaikessa. Mitä enemmän niitä on, sitä terävämpiä ja eloisampia kuvasi ovat.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Tarkkuus: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pikselit: [screenResolution] miljoonaa"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]\" näyttö",
                    "tr_badgeSubTitle": "Näytön korkeus: [screenHeight] tuumaa",
                    "tr_badgePreTitle": "Näytön leveys: [YY]\""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näyttö",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal]\" <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "IKKUNA PELIMAAILMOIHIN",
                "tr_subtitle": "Kuten television kanssa, pelinäytössäkin tärkeimmät ominaisuudet ovat koko ja tarkkuus.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Tarkkuus: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pikselit: [screenResolution] miljoonaa"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]\" näyttö",
                    "tr_badgeSubTitle": "Näytön korkeus: [screenHeight] tuumaa",
                    "tr_badgePreTitle": "Näytön leveys: [screenWidth] tuumaa"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Kosketusnäyttö"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näyttö",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Tässä laitteessa on vaikuttava näyttö",
                "tr_subtitle": "Yli kahdeksankertainen määrä pikseleitä teräväpiirtoon verrattuna. Videoiden ja pelien kuvan terävyys ja väri nousee uudelle tasolle.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Tarkkuus: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pikselit: [screenResolution] miljoonaa"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]\" näyttö",
                    "tr_badgeSubTitle": "Näytön korkeus: [screenHeight] tuumaa",
                    "tr_badgePreTitle": "Näytön leveys: [YY]\""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Näyttö",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] miljoonaa pikseliä – napauta, pyyhkäise ja kosketa",
                    "tr_subTitle": "Näet kaiken nyt aiempaakin terävämpänä ja yksityiskohtaisempana."
                  },
                  {
                    "tr_title": "[screenResolution] miljoonaa pikseliä parantaa digitaalista maailmaasi",
                    "tr_subTitle": "Pikselit ovat kaikki kaikessa. Mitä enemmän niitä on, sitä terävämpiä ja eloisampia kuvasi ovat."
                  },
                  {
                    "tr_title": "Tässä laitteessa on vaikuttava näyttö",
                    "tr_subTitle": "Kun pikseleitä on lähes neljä kertaa enemmän verrattuna teräväpiirtoon, videoiden ja pelien terävyys ja väri nousevat aivan uudelle tasolle."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Yhteydet",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3 -teknologia tarjoaa nopeimman ja monipuolisimman liitännän telakoihin, näyttöihin tai tietolaitteisiin.",
                "tr_title": "Yksi portti kaikkiin tarkoituksiin",
                "tr_title1": "YKSI PORTTI KAIKKIIN TARKOITUKSIIN",
                "titleArray": [
                  {
                    "tr_title": "MITEN VOIN KÄYTTÄÄ THUNDERBOLT™ 3 -TEKNOLOGIAA?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 3 -teknologia"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K-näytöt",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Liitä näyttöjä ja hyödynnä niiden hämmästyttävää tarkkuutta, kontrastia ja värejä."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Ulkoinen näytönohjain",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Anna tietokoneellesi vaikuttava grafiikkapäivitys."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Nopea verkko",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Nauti nopeista vertaisverkkoyhteyksistä."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Yhteydet",
                "detailBGUrl": "",
                "tr_subtitle": "Thunderbolt™ 3 -teknologia siirtää tietoa nopeammin kuin perinteiset USB-väylät, joten voit siirtää tiedostoja nopeasti ja lisätä helposti oheislaitteita, kuten useita näyttöjä tai ulkoisia näytönohjaimia.",
                "tr_title": "Thunderbolt™ 3 -teknologia",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "SALAMANNOPEA TIEDONSIIRTO",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mb/s",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gb/s",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Thunderbolt™ 3 -teknologia",
                    "tr_badgeSubTitle": "40 Gb/s",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Yhteydet",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Yksi yleisportti ja -kaapeli tukevat kaikkia tarvitsemiasi oheislaitteita</li> <li>Luotettava kannettavan tietokoneen lataus</li> <li>Kattava sertifiointi takaa toimivuuden</li></ul>",
                "tr_title": "Aidosti universaali liitettävyys",
                "tr_title1": "AIDOSTI UNIVERSAALI LIITETTÄVYYS",
                "tr_noteSection": "<sup>†</sup> Perustuu vähimmäisvaatimuksiin",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "MITEN VOIN KÄYTTÄÄ THUNDERBOLT™ 4 -TEKNOLOGIAA?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 4 -teknologia"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Luo",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Liitä ulkoinen näyttö ja nopea tallennuslaite kuvien ja videoiden tarkastelua ja muokkausta varten."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Yhdistä telakkaan",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tee työpöydästäsi siisti yhdistämällä kannettava kaikkiin oheislaitteisiin yhdellä kaapelilla."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Peli",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Lisää SSD- ja kiintolevykapasiteettia – nopeutena jopa 3 000 Mt/s – pelikirjastojen ja mediatiedostojen tallennusta varten."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Huipputeho<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4 kertaa nopeampi kuin USB* 3.2 <br/>2 kertaa nopeampi kuin HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Yhteydet",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Nauti Thunderbolt™ 3 -teknologian samasta hämmästyttävästä suorituskyvystä ja vielä useammista ominaisuuksista, joiden avulla voit helposti yhdistää ja käyttää uusimpia Thunderbolt™ -teknologiaa hyödyntäviä telakoita, näyttöjä, nopeita tallennuslaitteita tai USB*-lisävarusteita.",
                "tr_title": "Thunderbolt™ 4 -teknologia",
                "tr_pretitle": "AIDOSTI UNIVERSAALI LIITETTÄVYYS",
                "tr_noteSection": "<sup>†</sup> Perustuu vähimmäisvaatimuksiin",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Voit korvata kaikki USB-C*-kaapelit yhdellä Thunderbolt™ 4 -yleiskaapelilla ja näin helpottaa ja nopeuttaa kaapelin valintaa."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Thunderbolt™ 4 -telakoissa voi olla jopa neljä Thunderbolt™ -porttia, joilla saat lisää joustavuutta oheislaitteiden liittämiseen."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Huipputeho<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4 kertaa nopeampi kuin USB* 3.2 <br/>2 kertaa nopeampi kuin HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Yhteydet",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Yksi yleisportti ja -kaapeli tukevat kaikkia tarvitsemiasi oheislaitteita</li> <li>Luotettava kannettavan tietokoneen lataus</li> <li>Kattava sertifiointi takaa toimivuuden</li></ul>",
                "tr_title": "Maksimoi kokemuksesi Thunderbolt™ -teknologiaa hyödyntävillä lisälaitteilla",
                "tr_noteSection": "<sup>†</sup> Perustuu vähimmäisvaatimuksiin",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "MITEN VOIN KÄYTTÄÄ THUNDERBOLT™ 4 -TEKNOLOGIAA?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Luo",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Liitä ulkoinen näyttö ja nopea tallennuslaite kuvien ja videoiden tarkastelua ja muokkausta varten."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Yhdistä telakkaan",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tee työpöydästäsi siisti yhdistämällä kannettava kaikkiin oheislaitteisiin yhdellä kaapelilla."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Peli",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Lisää SSD- ja kiintolevykapasiteettia – nopeutena jopa 3 000 Mt/s – pelikirjastojen ja mediatiedostojen tallennusta varten."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Huipputeho<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4 kertaa nopeampi kuin USB* 3.2 <br/>2 kertaa nopeampi kuin HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Nämä ovat yleisimmät Wi‑Fi-tyypit:",
                "tr_title": "TIETOJA WI‑FI-STANDARDEISTA",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 mahdollistaa suuremman nopeuden silloinkin, kun useampi käyttäjä käyttää samaa signaalia.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Intelin uusimman Wi-Fi-innovaation ansiosta saat gigabitin Wi-Fiä paremman nopeuden silloinkin, kun samaa signaalia käyttää useampi käyttäjä.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Wi‑Fi 6E on suurin Wi‑Fi-uudistus 20 vuoteen – se avaa uusia nopeita kanavia suorituskyvyn ja vakauden parantamiseksi sekä häiriöiden vähentämiseksi."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 mahdollistaa suuremman nopeuden silloinkin, kun useampi käyttäjä käyttää samaa signaalia.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Kaikki Wi-Fi 6E:n edut ja Intel® Killer™ Prioritization Engine, joka siirtää kaistanleveyttä verkkosivustoille ja sovelluksille, jotka tarvitsevat sitä eniten."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Nopeilla kanavilla on vähemmän liikennettä, mikä parantaa yhteytesi laatua ja vakautta."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 mahdollistaa suuremman nopeuden silloinkin, kun useampi käyttäjä käyttää samaa signaalia.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Wi-Fi 6:n ja Intel® Killer™ Prioritization Enginen älykkään suorituskyvyn ansiosta häiriöitä on vähemmän ja verkon käyttö on nopeampaa ja sujuvampaa.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Nopeilla kanavilla on vähemmän liikennettä, mikä parantaa yhteytesi laatua ja vakautta."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Yli 5 Gbps:n Wi-Fi-nopeus PC:ssä sekä pysyvästi vähäinen viive – jaa tiedostoja sekunneissa minuuttien sijaan."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) on viimeisintä teknologiaa, joka hyödyntää \"äärimmäistä\" luotettavuutta, lyhyitä vasteaikoja ja suuria nopeuksia tekoälypohjaisen verkon optimoinnin avulla."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 mahdollistaa suuremman nopeuden silloinkin, kun useampi käyttäjä käyttää samaa signaalia.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) saavuttaa jopa yli 1 Gbps nopeudet ja tarjoaa luotettavan yhteyden ja lyhyet vasteajat 6 GHz -kanavien avulla."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Lisää tehokkuutta, kapasiteettia ja nopeutta ylikuormittuneissa verkoissa Wi-Fi 5:een verrattuna."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Wi‑Fi [XX]:n ansiosta Internet-yhteys toimii nopeammin<sup>†</sup> ja vakaammin.",
                "tr_title": "Parempi kantama ja nopeus",
                "tr_noteSection": "<sup>†</sup>Todellinen langaton nopeus ja/tai toiminta-alueen laajuus vaihtelevat.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Monin- ja verkkopeleissä yhteyden vakaus on tärkeämpää kuin koskaan aiemmin. Nopeuden lisäksi tarvitset langattoman signaalin, joka pystyy ylläpitämään tarvittavan nopeuden eri etäisyyksillä.",
                "tr_title": "[XX]",
                "tr_pretitle": "YHTEYS ON TÄRKEIN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Yhdistä Intel® Wi‑Fi 6 (Gig+) -teknologian avulla Intel® Wi‑Fi 6 -reitittimiin, joiden käyttämä 160 MHz:n kanavatuki takaa nopeamman ja luotettavamman yhteyden useammille aktiivisille käyttäjille.",
                "tr_title": "Lähes kolminkertaiset nopeudet Intel® <span class='no-warp'>Wi-Fi 6 </span> (Gig+) -yhteydellä<sup>†</sup> ",
                "tr_noteSection": "<sup>†</sup> Verrattuna tavalliseen AC Wi‑Fiin. Osa ominaisuuksista on käytettävissä vain tietyissä tuotteissa. Saat lisätietoja valmistajalta. Lisää Wi‑Fi-tietoja löydät osoitteesta intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Perustason kaksikaistainen Intel® Wi-Fi 6 tarjoaa paremman suorituskyvyn perustarpeille, kuten sähköpostille ja verkkoselaamiselle, ruuhkaisissa verkoissa.<sup>9</sup>",
                "tr_title": "Välttämätön Wi‑Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Monin- ja verkkopeleissä yhteyden vakaus on tärkeämpää kuin koskaan aiemmin. Nopeuden lisäksi tarvitset langattoman signaalin, joka pystyy ylläpitämään tarvittavan nopeuden eri etäisyyksillä.",
                "tr_title": "[XX]",
                "tr_pretitle": "YHTEYS ON TÄRKEIN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Näiden uusien Wi‑Fi-kanavien ansiosta sinun ei tarvitse jakaa kaistanleveyttä naapurien vanhojen laitteiden kanssa. Saat sujuvaa suoratoistoa ja nopeita selailuja, jotta etätyö ja opiskelu sujuvat saumattomasti.",
                "tr_title": "Yhdistä ilman kilpailua",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "[XX] saa Internet-yhteyden toimimaan nopeammin<sup>†</sup> ja vakaammin.",
                "tr_title": "Parempi kantama ja nopeus",
                "tr_noteSection": "<sup>†</sup>Todellinen langaton nopeus ja/tai toiminta-alueen laajuus vaihtelevat.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Monin- ja verkkopeleissä yhteyden vakaus on tärkeämpää kuin koskaan aiemmin. Nopeuden lisäksi tarvitset langattoman signaalin, joka pystyy ylläpitämään tarvittavan nopeuden eri etäisyyksillä.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "YHTEYS ON TÄRKEIN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Sisäänrakennettu ultranopea Wi‑Fi",
                "tr_subtitle": "Pysy yhteydessä verkkomaailmaan ilman johtoa."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Omilla erityistaajuuksilla on vähemmän häiriöitä, ja Intel® Killer™ Prioritization Engine asettaa pelaamisen etusijalle, joten online-pelaaminen nousee uudelle tasolle.",
                "tr_title": "Jätä kilpailijat taakse",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Omilla erityistaajuuksilla on vähemmän häiriöitä, ja Intel® Killer™ Prioritization Engine asettaa pelaamisen etusijalle, joten online-pelaaminen nousee uudelle tasolle.",
                "tr_title": "[XX]",
                "tr_pretitle": "JÄTÄ KILPAILIJAT TAAKSE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Omilla huippunopeilla kaistoilla on vähemmän häiriöitä, joten voit keskittyä pelaamiseen ja verkkopelit sujuvat saumattomasti.",
                "tr_title": "[XX]",
                "tr_pretitle": "JÄTÄ KILPAILIJAT TAAKSE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi nopeuttaa automaattisesti niiden sovellusten verkkoliikennettä, jotka tarvitsevat sitä eniten, jolloin videoiden suoratoistossa yksityiskohdat ovat teräviä ja puskurointia on vähemmän.",
                "tr_title": "Nopeampi ja fiksumpi tapa muodostaa yhteys⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Monin- ja verkkopeleissä yhteyden vakaus on tärkeämpää kuin koskaan aiemmin. Nopeuden lisäksi tarvitset langattoman signaalin, joka pystyy ylläpitämään tarvittavan nopeuden eri etäisyyksillä.",
                "tr_title": "[XX]",
                "tr_pretitle": "YHTEYS ON TÄRKEIN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Wi-Fi 5:n ansiosta Internet-yhteys toimii nopeammin<sup>†</sup> ja luotettavammin.",
                "tr_title": "Parempi kantama ja nopeus",
                "tr_noteSection": "<sup>†</sup>Todellinen langaton nopeus ja/tai toiminta-alueen laajuus vaihtelevat.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Monin- ja verkkopeleissä yhteyden vakaus on tärkeämpää kuin koskaan aiemmin. Nopeuden lisäksi tarvitset langattoman signaalin, joka pystyy ylläpitämään tarvittavan nopeuden eri etäisyyksillä.",
                "tr_title": "[XX]",
                "tr_pretitle": "JÄTÄ KILPAILIJAT TAAKSE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Wi-Fi 7 -ominaisuudet perustuvat aikaisempiin Wi-Fi-sukupolviin ja parantavat niitä. Tämä merkitsee huippunopeuksien lisäksi huomattavasti entistä parempaa reagointikykyä ja luotettavuutta mukaansatempaavia kuluttajakokemuksia ja kehittyneitä tulevaisuuden teknologioita varten.",
                "tr_title": "Langallisen kaltainen PC-verkko",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Wi-Fi 7 -ominaisuudet perustuvat aikaisempiin Wi-Fi-sukupolviin ja parantavat niitä. Tämä merkitsee huippunopeuksien lisäksi huomattavasti entistä parempaa reagointikykyä ja luotettavuutta mukaansatempaavia kuluttajakokemuksia ja kehittyneitä tulevaisuuden teknologioita varten.",
                "tr_title": "[XX]",
                "tr_pretitle": "UUDISTAEN KÄYTTÄJÄKOKEMUKSET",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Lisää nopeutta, lyhyempiä vasteaikoja ja parempaa luotettavuutta tekoälypohjaisen verkon optimoinnin avulla",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Lisää nopeutta, lyhyempiä vasteaikoja ja parempaa luotettavuutta tekoälypohjaisen verkon optimoinnin avulla.",
                "tr_title": "[XX]",
                "tr_pretitle": "LANGALLISEN KALTAINEN REAGOINTIKYKY",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akku",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Miten sitä mitataan?",
                    "tr_badgeSubTitle": "Useimmat valmistajat ilmoittavat akunkeston tunteina. Vaikka laskelma ei ole täysin tarkka, sen avulla voi verrata tietokoneiden akunkestoa toisiinsa."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akku",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PELAA MISSÄ TAHANSA",
                "tr_subtitle": "Nykyisten pelikannettavien tehon ansiosta voit irtautua pöydän äärestä. Nyt voit pelata missä tahansa.",
                "tr_title": "[Dynamic.battery.values.hours] tunnin akunkesto"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akku",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Irrota johdot ja käytä sovelluksiasi, pelejäsi ja musiikkiasi tuntien ajan.",
                "tr_title": "[XX] tuntia suoratoistoa yhdellä latauksella<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup> Perustuu tietokoneen valmistajan ilmoittamaan akunkestoon.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] tuntia",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akku",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Irrota johdot ja käytä sovelluksiasi, pelejäsi ja musiikkiasi tuntien ajan.",
                "tr_title": "[Dynamic.battery.values.hours] tuntia virtaa missä tahansa<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup> Perustuu tietokoneen valmistajan ilmoittamaan akunkestoon.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] tuntia",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akku",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Irrota johdot ja käytä sovelluksiasi, pelejäsi ja musiikkiasi tuntien ajan.",
                "tr_title": "[Dynamic.battery.values.hours] tuntia virtaa koko päiväksi yhdellä latauksella<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup> Perustuu tietokoneen valmistajan ilmoittamaan akunkestoon.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] tuntia",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akku",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Tietokone optimoi akunkeston, ja 30 minuutin latauksella saa vähintään 4 tuntia käyttöaikaa.<sup>4</sup>",
                "tr_title": "Tee töitä johdotta pidempään [Dynamic.battery.values.hours] tunnin akunkestolla<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup> Perustuu tietokoneen valmistajan ilmoittamaan akunkestoon.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] tuntia",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Koti",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Miksi valita tämä tietokone",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Näytä kaikki tekniset tiedot",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Etä",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobiililaitteet",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Laillisuustiedot",
        "tr_paragraph_array": [
          "Suorituskykytesteissä käytettyjen ohjelmistojen ja kuormitusten suorituskyky on voitu optimoida Intel® -suorittimille. Suorituskykytestit, kuten SYSmark* ja MobileMark*, tehdään määrättyjä järjestelmiä, komponentteja ja toimintoja käyttäen. Mikä tahansa muutos näissä tekijöissä voi vaikuttaa tuloksiin. Tutustu muihin tietoihin ja suorituskykytesteihin, jotta voit kunnolla vertailla hankintasuunnitelmaasi kuuluvien tuotteiden suorituskykyä muiden tuotteiden kanssa käytettynä. Lisätietoja suorituskyvystä ja testituloksista on osoitteessa www.intel.com/benchmarks.",
          "¹ Intel® Wi-Fi 6 (Gig+) -tuotteet tukevat valinnaisia 160 MHz:n kanavia, mikä takaa parhaat mahdolliset teoreettiset enimmäisnopeudet (2 402 Mb/s) tavallisille 2x2 802.11ax PC -Wi-Fi-tuotteille. Ensiluokkaiset Intel® Wi-Fi 6 (Gig+) -tuotteet takaavat 2–4 kertaa nopeammat teoreettiset enimmäisnopeudet verrattuna tavallisiin 2x2 (1 201 Mb/s)- tai 1x1 (600 Mb/s) 802.11ax PC -Wi-Fi-tuotteisiin, jotka tukevat vain 80 MHz:n kanavan pakollista vaatimusta.",
          "² Suositus on tarkoitettu ainoastaan tiedoksi. Älä tee ostopäätöstä vain tämän perusteella.",
          "³Intel® -teknologioiden ominaisuudet ja edut vaihtelevat järjestelmäkokoonpanon mukaan, ja ne saattavat edellyttää laitteiden, ohjelmien ja palvelujen aktivointia. Suorituskyky vaihtelee järjestelmäkokoonpanon mukaan. Mikään tietokonejärjestelmä ei ole täysin turvallinen. Lisätietoja saat järjestelmän valmistajalta tai myyjältä tai osoitteesta intel.com.",
          "⁴ Järjestelmiin, joissa on FHD-näyttö, selattaessa verkkoa langattomasti. Virran ollessa katkaistu, OEM:n oletussammutustasosta.",
          "⁵ Verrattuna muihin tietokoneiden I/O-liitäntätekniikoihin, kuten eSATA, USB ja IEEE 1394* Firewire. Suorituskyky määräytyy käytettävän laitteiston ja ohjelmistojen mukaan. Edellyttää Thunderbolt™ -teknologiaa hyödyntävää laitetta.",
          "⁶ Lähes 3 kertaa nopeampi: 802.11ax 2x2 160 MHz mahdollistaa enimmillään 2402 Mb/s:n teoreettisen datansiirtonopeuden. Noin 3 kertaa nopeampi kuin 802.11ac 2x2 80 MHz (867 Mb/s) Wi‑Fi -standardi, kuten käy ilmi IEEE 802.11* Wi‑Fi -standardin vaatimuksista, ja edellyttää samoin määritetyn 802.11ax Wi‑Fi -reitittimen käyttöä.",
          "⁷ 75 % pienempi viive perustuu Intelin simulaatiodataan (79 %). Simulaatiot on tehty 9 asiakkaalla käyttäen 802.11ax-reititintä OFDMA:n kanssa ja ilman. Keskimääräinen viive ilman OFDMA:ta on 36 ms, OFDMA:n kanssa se pienenee 7,6 ms:n tasolle. Viiveen pieneneminen edellyttää sitä, että 802.11ax (Wi-Fi 6) -reititin ja kaikki asiakkaat tukevat OFDMA:ta.",
          "⁸ 802.11-määritysten teoreettisen enimmäisdatansiirtonopeuden vertailun perusteella 2x2 160 MHz Wi‑Fi 6/6E (802.11ax) pystyy tarjoamaan 2402 Mb/s, kun taas 2x2 80 MHz Wi‑Fi 5 (802.11ac) pystyy tarjoamaan 867 Mb/s, eli ensimmäinen on 2,8 kertaa nopeampi.",
          "⁹ Luokkansa paras Wi‑Fi 6: Intel® Wi‑Fi 6 (Gig+) -tuotteet tukevat valinnaisia 160 MHz:n kanavia, mikä takaa parhaat mahdolliset teoreettiset enimmäisnopeudet (2 402 Mb/s) tyypillisille 2x2 802.11ax PC Wi‑Fi -tuotteille. Premium Intel® Wi‑Fi 6 (Gig+) -tuotteet mahdollistavat 2–4 kertaa nopeamman teoreettisen enimmäisnopeuden verrattuna standardeihin 2x2 (1201 Mb/s)- tai 1x1 (600 Mb/s) 802.11ax PC Wi‑Fi -tuotteisiin, jotka tukevat vain 80 MHz:n kanavan pakollisia vaatimuksia.",
          "Vain valituissa tuotteissa, ja toiminnallisuus vaihtelee OEM-mallin mukaan. OEM tai vähittäismyyjä voivat antaa tarkempia tietoja. Lisää Wi‑Fi-tietoja löydät osoitteesta intel.com/wifi6disclaimers.",
          "¹⁰ Thunderbolt™ 4 -teknologia 40 Gb/s tarjoaa nopeimman, yksinkertaisimman ja luotettavimman kaapeliratkaisun mihin tahansa telakkaan, näyttöön tai tietolaitteeseen verrattuna muihin PC I/O -yhteysteknologioihin, kuten eSATA*, USB* ja IEEE 1394* FireWire.",
          "¹¹ Vaatii 802.11ax-pohjaisen reitittimen, joka tukee OFDMA-modulointia ja useita asiakkaita verkossa, jolla on AX-tuki. Tiheästi liikennöidyissä ympäristöissä toimintaa parantavat OFDMA-modulointiominaisuudet, joita tukevat 802.11ax -asiakkaat ja -tukiasemat. 2 Gb/s perustuu oletukseen noin 70 prosentin IEEE 802.11* -standardin mukaisesta teoreettisesta enimmäistiedonsiirtonopeudesta 802.11ax 160 MHz 2402 Mb/s.",
          "¹² Testivertailu käyttäen asiakirjan käynnistystä taustatoimintokuormituksen kanssa, vertailussa 8. sukupolven Intel® Core™ i7-8565U -suoritin (512 Gt:n TLC SSD) ja 8. sukupolven Intel® Core™ i7-8565U -suoritin (32 Gt + 512 Gt:n Intel® Optane™ -muisti H10 ja SSD).",
          "¹³ Intel® Thread Director on suunniteltu 12. sukupolven Intel® Core™ -suorittimiin ja se auttaa käyttöjärjestelmiä ohjaamaan työkuormituksia älykkäämmin oikeisiin ytimiin. Käyttäjän ei tarvitse tehdä mitään. Katso lisätietoja osoitteesta intel.com.",
          "¹⁴ Ei käytettävissä tietyissä 12. sukupolven Intel® Core™ -suorittimissa. Suorituskykyinen hybridiarkkitehtuuri yhdistää kaksi uutta ydinmikroarkkitehtuuria, Performance-cores-ytimet (P-cores) ja Efficient-cores-ytimet (E-cores), yhteen suoritinytimeen. Jotkin 12. sukupolven Intel® Core™ -suorittimet (tietyt 12. sukupolven Intel® Core™ i5 -suorittimet ja pienemmät) eivät tue suorituskykyistä hybridiarkkitehtuuria, vain P-cores-ytimiä.",
          "¹⁵ Mitattu pelin käynnistyksen kuormituksen mukaan, vertailussa 8. sukupolven Intel® Core™ i7-8750H -suoritin (32 Gt:n Intel® Optane™ -muistimoduuli + Intel® SSD 256 Gt:n PCIe* + 1 Tt:n kiintolevy) ja 8. sukupolven Intel® Core™ i7-8750H -suoritin (Intel® SSD 256 Gt:n PCIe + 1 Tt:n kiintolevy). Pelin käynnistyksen kuormitus – Kuormituksen kehittänyt Intel mittaa aikaa, joka kuluu pelin Total War*: WARHAMMER* II, koontiversio: 5577.0 käynnistämiseen ja päävalikon avaamiseen niin, että esittelyvideot eivät ole käytössä (käynnistys). Intel® Optane™ -muisti – data-aseman kiihdytyskokoonpanot: Intel® Core™ i7-8750H -suoritin, PL1 = suurin lämpöteho 45 W, 6 ydintä ja 12 säiettä, turbo jopa 4 GHz ennakkotuotannossa olevalla OEM-järjestelmällä, näytönohjain: NVIDIA* GeForce* GTX 1070, muisti: 2 x 4 Gt:n DDR4, tallennustila: Intel® SSD Series 760p 256 Gt (PCIe*) + 1 Tt:n kiintolevy + 32 Gt:n Intel® Optane™ -muisti, käyttöjärjestelmä: Windows* 10 RS3 (koontiversio 1709), MCU 0x84; Intel® Core™ i7-8750H -suoritin, PL1 = suurin lämpöteho 45 W, 6 ydintä ja 12 säiettä, turbo jopa 4 GHz ennakkotuotannossa olevalla OEM-järjestelmällä, näytönohjain: NVIDIA* GeForce* GTX 1070, muisti: 2 x 4 Gt:n DDR4, tallennustila: Intel® SSD Series 760p 256 Gt (PCIe*) + 1 Tt:n kiintolevy, käyttöjärjestelmä: Windows* 10 RS3 (koontiversio 1709), MCU 0x84.",
          "¹⁶ Mitattu pelin tason latauksen kuormituksen mukaan, vertailussa 8. sukupolven Intel® Core™ i7-8750H -suoritin (32 Gt:n Intel® Optane™ -muistimoduuli) ja 8. sukupolven Intel® Core™ i7-8750H -suoritin (vain kiintolevy). Pelin tason latauksen kuormitus - Kuormituksen kehittänyt Intel mittaa aikaa, joka kuluu päävalikosta tason latauksen päättymiseen (tason lataus) pelissä Total War*: WARHAMMER* II, koontiversio: 5577.0. Intel® Optane™ -muisti – data-aseman kiihdytyskokoonpanot: Intel® Core™ i7-8750H -suoritin, PL1 = suurin lämpöteho 45 W, 6 ydintä ja 12 säiettä, turbo jopa 4 GHz ennakkotuotannossa olevalla OEM-järjestelmällä, näytönohjain: NVIDIA* GeForce* GTX 1070, muisti: 2 x 4 Gt:n DDR4, tallennustila: Intel® SSD Series 760p 256 Gt (PCIe*) + 1 Tt:n kiintolevy + 32 Gt:n Intel® Optane™ -muisti, käyttöjärjestelmä: Windows* 10 RS3 (koontiversio 1709), MCU 0x84; Intel® Core™ i7-8750H -suoritin, PL1 = suurin lämpöteho 45 W, 6 ydintä ja 12 säiettä, turbo jopa 4 GHz ennakkotuotannossa olevalla OEM-järjestelmällä, näytönohjain: NVIDIA* GeForce* GTX 1070, muisti: 2 x 4 Gt:n DDR4, tallennustila: Intel® SSD Series 760p 256 Gt (PCIe*) + 1 Tt:n kiintolevy, käyttöjärjestelmä: Windows* 10 RS3 (koontiversio 1709), MCU 0x84.",
          "¹⁷ Performance-cores-ytimissä. Suorituskyky vaihtelee käytön, kokoonpanon ja muiden tekijöiden mukaan. Lue lisää osoitteesta www.intel.com/PerformanceIndex.",
          "¹⁸ Kellotaajuuden tai jännitteen muuttaminen saattaa lyhentää suorittimen ja muiden järjestelmäkomponenttien käyttöikää ja heikentää järjestelmän vakautta ja suorituskykyä. Tuotetakuut eivät ehkä ole voimassa, jos suoritinta käytetään sen tekniset ominaisuudet ylittävällä tavalla. Tarkista tiedot järjestelmän ja komponenttien valmistajilta.",
          "¹⁹ Mitattuna Intel vPro® -alustan verrattomalla yhdistelmällä käyttöjärjestelmän suojausominaisuuksia, sovellus- ja tietoturvaa sekä kehittynyttä uhkien torjuntaa kaikenkokoisille yrityksille ja lisäksi Intelin turvallisuuden etusijalle asettava lähestymistapa tuotesuunnitteluun, valmistukseen ja tukeen. Kaikki Intel vPro® -alustalla rakennetut yritystietokoneet on vahvistettu tiukkojen teknisten tietojen mukaisesti, mukaan lukien yksilölliset laitteistopohjaiset suojausominaisuudet. Saat lisätietoja osoitteesta www.intel.com/PerformanceIndex (alustat). Mitään tuotetta tai komponenttia ei voi suojata täydellisesti.",
          "²⁰ Intel® Connectivity Performance Suite -ohjelmistopaketti on käytettävissä vain Windowsiin* pohjautuvissa järjestelmissä. Intel Over The Air (OTA) -Wi-Fi-testaus ruuhkaisessa verkossa verrattuna ilman ICPS-ohjelmistojen langattoman liikenteen priorisointia ja älykästä tukiaseman vaihtamista. Saat lisätietoja osoitteesta www.intel.com/PerformanceIndex (yhteydet). Tulokset voivat vaihdella.",
          "²¹ Verrattuna edelliseen suoritinsukupolveen.",
          "²² Intel® Arc™ -näytönohjain on markkinoiden ensimmäinen, jossa on AV1-koodaustuki (vuoden 2022 ensimmäisellä vuosineljänneksellä). Lisätietoa on osoitteessa intel.com/performanceindex.",
          "²³ Mitattuna Intel® Evo™ -mallien ainutlaatuisilla ominaisuuksilla, joiden on myös vahvistettu täyttävän keskeiset mobiilikäyttökokemuksen kynnysarvot. Testattu helmikuussa 2022.",
          "²⁴ Intel® Unison™ -ratkaisu on tällä hetkellä käytettävissä vain tietyissä Intel® Evo™ -malleissa Windows*-pohjaisilla tietokoneilla. Se on yhdistettävissä vain Android- ja iOS-puhelimiin. Kaikissa laitteissa on oltava tuettu käyttöjärjestelmäversio.",
          "²⁵ Muistin enimmäisnopeudet koskevat kokoonpanoja, joissa on 1 DIMM kanavaa kohden (1DPC). Lisä-DIMM millä tahansa kanavalla voi vaikuttaa muistin enimmäisnopeuteen. Enintään DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 ja DDR5-5200 1Rx8, 1Rx16, 2Rx8 valituissa SKU:issa. Muistin enimmäiskapasiteetti on saavutettavissa 2DPC-kokoonpanoissa.",
          "²⁶ Vakiotason Wi-Fiin verrattuna. Saat lisätietoja osoitteesta intel.com/performance-wireless. Tulokset voivat vaihdella.",
          "²⁷Suorituskykyinen hybridiarkkitehtuuri yhdistää kaksi ydinten mikroarkkitehtuuria, suorituskyvyn Performance-cores-ytimet (P-cores) ja tehokkuuden Efficient-cores-ytimet (E-cores), yhdessä suorittimessa, joka esiteltiin ensimmäisen kerran 12. sukupolven Intel® Core™ -suorittimissa. Tietyissä 12. sukupolven ja sitä uudemmissa Intel® Core™ -suorittimissa ei ole suorituskykyistä hybridiarkkitehtuuria, ainoastaan P-cores-ytimiä, ja niiden välimuistin koko on sama kuin edellisen sukupolven. Saat SKU-tietoja osoitteesta ark.intel.com.",
          "²⁸Laitteeseen integroitu Intel® Thread Director on käytettävissä vain 12. sukupolven ja sitä uudemmissa Intel® Core™ -suorittimien suorituskykyisissä hybridiarkkitehtuurikokoonpanoissa. Ominaisuuden käyttöönotto käyttöjärjestelmässä vaaditaan. Käytettävissä olevat ominaisuudet ja toiminnot vaihtelevat käyttöjärjestelmän mukaan.",
          "²⁹Laitteeseen integroitu Intel® Thread Director on käytettävissä vain 12. ja 13. sukupolven Intel® Core™ -suorittimien suorituskykyisissä hybridiarkkitehtuurikokoonpanoissa. Ominaisuuden käyttöönotto käyttöjärjestelmässä vaaditaan. Käytettävissä olevat ominaisuudet ja toiminnot vaihtelevat käyttöjärjestelmän mukaan.",
          "³⁰Mitattu käyttäen Intel® Core™ Ultra &#8209;mobiilisuorittimen (Sarja 2 — koodinimi: Lunar Lake) suorituskykytietoja RVP&#8209;testausalustalla verrattuna Intel® Core™ Ultra &#8209;mobiilisuorittimeen (Sarja 1 — koodinimi: Meteor Lake) 3DMark Time Spy &#8209;testiohjelmassa. Saat lisätietoja osoitteesta intel.com/performanceindex. Tulokset voivat vaihdella.",
          "³¹Taustan sumennus ja kasvojen seuranta ovat käytössä vain Windowsiin* pohjautuvissa järjestelmissä.",
          "³²Wi-Fi 7 -tuotteet voivat käyttää 320 MHz-kanavaa 6 GHz taajuudella ja uutta 160 MHz-kanavayhdistelmää 5 GHz taajudella uusilla Multi-Resource Unit Puncturing -kyvykkyyksillä.",
          "³³Perustuu IEEE:n langattomien yhteyksien vakiomäärityksiin ja kahden tietovirran laitteiden teoreettisiin maksimitietomääriin.",
          "³⁴Intel-tekniikkasimulaatiot ylikuormittuneissa verkkoympäristöissä osoittivat suuren vasteajan vähennyksen olevan mahdollista uusilla Wi-Fi 7 Multi-Link Operation -kyvykkyyksillä.",
          "³⁵Saavutettu minimivaraustaso OEM:n oletussammutustasosta. Yksittäisten järjestelmien tulokset voivat vaihdella. Lisätietoa on osoitteessa intel.com/performance-evo.",
          "³⁶6 GHz:n Wi-Fi-kaista ei ole käytettävissä kaikissa maissa tai kaikilla alueilla.",
          "³⁷Vain Windows*",
          "³⁸Saavutettu varaustaso OEM:n oletussammutustasosta. Lisätietoja suorituskyvystä ja testituloksista on osoitteessa intel.com/Evo.",
          "³⁹Viittaa Intel® Core™ Ultra -mobiilisuorittimiin (Sarja 2), perustuen ainutlaatuisiin laitteistopohjaisiin turvaominaisuuksiin, vertaansa vailla olevaan käyttöjärjestelmän ylä- ja alapuolella toimivien suojausominaisuuksien yhdistelmään sekä vaikuttavaan suorituskykyyn edellistä sukupolvea alemmalla virrankulutuksella (syyskuusta 2024 alkaen). Saat lisätietoja osoitteesta intel.com/performanceindex. Tulokset voivat vaihdella.",
          "⁴⁰Ohjelmistosovellukset esitetään vain kuvailutarkoituksessa. Tekoälyominaisuudet saattavat vaatia lisäohjelmiston ostamista, tilausta tai käyttöönottoa ohjelmiston tai alustan tarjoajan toimesta tai niillä saattaa olla erityisiä määritys- ja yhteensopivuusvaatimuksia. Lisätietoja on osoitteessa www.intel.com/PerformanceIndex. Tulokset voivat vaihdella. © Intel Corporation.",
          "⁴¹Tässä nähtäviä kuvia on voitu muokata tai ne voivat olla simuloituja. Tekoälyominaisuudet saattavat vaatia lisäohjelmiston ostamista, tilausta tai käyttöönottoa ohjelmiston tai alustan tarjoajan toimesta tai niillä saattaa olla erityisiä määritys- ja yhteensopivuusvaatimuksia. Lisätietoja: www.intel.com/AIPC.",
          "⁴²Julkaistaan vaiheittain ennakkokäyttöön Windows* 11:n uusimmassa päivityksessä valikoiduilla markkina-alueilla koko maailmassa. Saatavuusaika vaihtelee laitteen ja markkina-alueen mukaan. Lue lisää täältä: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Intel® Unison™ -ratkaisu on tällä hetkellä saatavilla soveltuvissa malleissa. Saat lisätietoja osoitteesta www.intel.com/PerformanceIndex.",
          "⁴⁴Intel® X<sup>e</sup>SS -skaalausteknologialla tehostettu kuvataajuus verrattuna natiiviin kuvataajuuteen mitattuna Dying Light 2 Stay Human -pelin ollessa käynnissä Intel® Core™ Ultra 7 165H -suorittimella varustetussa tietokoneessa. Saat lisätietoja osoitteesta www.intel.com/PerformanceIndex. Tulokset voivat vaihdella.",
          "⁴⁵Mitattu käyttäen Intel® Core™ Ultra -mobiilisuorittimen (sarja 2 – koodinimi: Lunar Lake) alempaa järjestelmäpiiritehoa RVP-testausalustalla verrattuna Intel® Core™ Ultra -mobiilisuorittimeen (sarja 1 – koodinimi: Meteor Lake) RVP-testausalustalla YouTubessa* (4K 30 FPS AV1). Saat lisätietoja osoitteesta intel.com/performanceindex. Tulokset voivat vaihdella.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel® ei ole vastuussa virheellisestä hinnoittelusta.",
          "* Muut nimet ja merkit voivat olla muiden omaisuutta.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES JA NIIHIN LIITTYVÄT SUUNNITTELU- JA YHDISTELMÄMERKIT OVAT TAVARAMERKKEJÄ, JOTKA OMISTAA INFLEXION STUDIOS INC. KAIKKI OIKEUDET PIDÄTETÄÄN.",
          "Intel -teknologiat saattavat edellyttää erityisiä laitteita tai ohjelmistoja tai palvelujen aktivointia. ",
          "Kaikki NERO* -sovelluksen tulokset ja data on tarkoitettu vain käyttäjän hyödyksi ja tiedoksi. Intel Corporation ei esimerkiksi ota vastuuta NERO* -sovelluksen tai suorituskykytulosten ja -pisteiden tarkkuudesta tai www.nero.com -sivuston tai muun kolmannen osapuolen sivuston kautta ladatun sovellusversion tuottamasta mahdollisesta vaihtelusta. NERO* PC -vertailutestin pisteet mitataan käyttämällä tiettyjä tietokonejärjestelmiä, komponentteja, ohjelmistoja, toimintoja ja lisätoimintoja. Mikä tahansa muutos näissä tekijöissä voi muuttaa tuloksia. Tutustu muihin tietoihin ja suorituskykytesteihin, jotta voit kunnolla vertailla hankintasuunnitelmaasi kuuluvien tuotteiden suorituskykyä muiden tuotteiden kanssa käytettynä.",
          "Kaikki Intel vPro® -alustan versiot edellyttävät sopivaa Intel® Core™ -suoritinta, tuettua käyttöjärjestelmää, Intel® LAN- ja/tai WLAN-silikonia, laitteiston parannuksia sekä muita laitteistoja ja ohjelmistoja, jotka ovat välttämättömiä alustaa määrittävien hallittavuuden käyttötapausten, suojausominaisuuksien, järjestelmän suorituskyvyn ja vakauden toimittamiseksi.",
          "Suorituskyky vaihtelee käytön, kokoonpanon ja muiden tekijöiden mukaan. Lisätietoja saat osoitteesta www.intel.com/PerformanceIndex.",
          "Intel ei vastaa kolmansien osapuolten datasta eikä tarkasta sitä. Tutustu muihin lähteisiin arvioidaksesi tietojen tarkkuutta.",
          "© Intel Corporation. Intel, Intel-logo ja muut Intel-merkit ovat Intel Corporationin tai sen tytäryhtiöiden tavaramerkkejä. * Muut nimet ja merkit voivat olla muiden omaisuutta.",
          "Mitään tuotetta tai komponenttia ei voi suojata täydellisesti.",
          "Kustannukset ja tulokset saattavat vaihdella.",
          "Intel, Intel-logo, Intel Core, Intel Optane, Intel Iris, Thunderbolt ja Thunderbolt-logo ovat Intel Corporationin tai sen tytäryhtiöiden tavaramerkkejä. Muut nimet ja merkit voivat olla muiden omaisuutta.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Koti",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Tarkista ja vertaa",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Trendit",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/evo/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/video_link.mp4",
      "iconURL": "",
      "videoName": "video_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ Performance to Power Their World (16_9).mp4",
      "iconURL": "",
      "videoName": "Video_ Performance to Power Their World (16_9)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "TERVETULOA",
    "tr_welcomePagetrSubtitle": "Etäohjaa tätä tietokonetta älypuhelimella",
    "tr_welcomePagembSubtitle": "Tutustu tämän tietokoneen teknisiin ominaisuuksiin älypuhelimesi avulla",
    "tr_welcomePageBtnText": "Aloita",
    "tr_welcomePageText": "Tutustu ja vertaile tämän tietokoneen tietoja älypuhelimesi avulla",
    "tr_scrollText": "Tutki edessäsi olevaa laitetta",
    "tr_toolTipTextc1": "Käytä näitä painikkeita siirtyäksesi edessäsi olevan laitteen eri sivuille.",
    "tr_toolTipTextc2": "Alla olevien korttien avulla voit siirtyä kunkin laitteen teknisten tietojen sivulle.",
    "tr_toolTipTextc3": "Käytä näitä painikkeita siirtyäksesi ylös ja alas edessäsi olevalla näytöllä.",
    "tr_toolTipTextc4": "Sulje etätila ja katso laitteen teknisiä tietoja älypuhelimellasi.",
    "tr_toolTipTextmb1": "Napauta kortteja nähdäksesi yksityiskohtaiset sisältösivut.",
    "tr_toolTipTextmb2": "Napauta nähdäksesi yhteenvedon kaikista laitteen teknisistä tiedoista",
    "tr_toolTipTextmb3": "Napauta painiketta skannataksesi QR-koodin ja vertaile jopa neljää laitetta",
    "tr_nextButton": "Seuraava",
    "tr_prevButton": "Edellinen",
    "tr_gotItButton": "OK",
    "tr_skipButton": "Ohita",
    "tr_placeholderText": "*paikkamerkki",
    "tr_processorHeading": "Suoritin",
    "tr_processorSubheading": "10. sukupolven Intel® Core™ i7 -suoritin",
    "tr_memorySubheading": "[XX] Gt",
    "tr_storageSubheading": "[XX] Gt:n Intel® Optane™ -muisti + [YY] Gt:n SSD-asema",
    "tr_coachmarkProcessor": "XX -suoritin",
    "tr_coachmarkGraphics": "[XX] -näytönohjain",
    "tr_coachmarkMemory": "Määritetty arvo",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999,95&nbsp;$",
    "tr_compare": "Vertaa",
    "tr_viewallspecs": "NÄYTÄ KAIKKI TEKNISET TIEDOT",
    "tr_operatingSystem": "KÄYTTÖ&shy;JÄRJESTELMÄ",
    "tr_AmdProcessorNonIntel": "AMD*-suoritin",
    "tr_QualcommProcessorNonIntel": "Qualcomm* &#8209;suoritin",
    "tr_GBText": "Gt",
    "tr_remoteText": "Tiedot näkyvät kannettavan näytöllä",
    "tr_scanErrorText": "Voit skannata enintään 3 laitetta kerrallaan. Poista yksi tai useampi laite.",
    "tr_scanErrorText2": "Olet jo skannannut tämän laitteen. Skannaa toinen laite.",
    "tr_scanMsg": "Skannaa ja lisää laite",
    "tr_backToScan": "Palaa takaisin vertailuun",
    "tr_scanToCompare": "Skannaa ja vertaa",
    "tr_compareDevice": "Vertaa laitteita",
    "tr_processorTitle": "SUORITIN",
    "tr_graphicTitle": "NÄYTÖN&shy;OHJAIN",
    "tr_storageTitle": "TALLENNUSTILA",
    "tr_displayTitle": "NÄYTTÖ",
    "tr_batteryTitle": "AKKU",
    "tr_memoryTitle": "MUISTI",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "YHTEYDET",
    "tr_priceTitle": "HINTA",
    "tr_operatingSystemTitle": "KÄYTTÖ&shy;JÄRJESTELMÄ",
    "tr_batteryHoursText": "[XX] tuntia",
    "tr_hrsText": "t",
    "tr_touchscreeenText": "[screenDiagonal]” kosketusnäyttö",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, kosketusnäyttö",
    "tr_FHDTouchScreenText": "FHD, kosketusnäyttö",
    "tr_4KTouchscreenText": "4K, kosketusnäyttö",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Tallenna",
    "tr_device_comparison": "Laitteiden vertailuasiakirja [dd]/[mm]/[yyyy]",
    "tr_Save_As_JPEG": "Tallenna JPEG-tiedostona",
    "tr_Save_As_PDF": "Tallenna PDF-tiedostona",
    "tr_Downloading_PDF": "Ladataan PDF-tiedosto",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Avaa",
    "tr_logo": "logo",
    "tr_laptop_name": "Kannettavan nimi",
    "tr_TBText": "Tt",
    "tr_optaneMemoryTitle": "Intel® Optane™ -muisti",
    "tr_inchUnit": "tuumaa",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "INTEL® OPTANE™ -MUISTI",
    "tr_Thunderbolt3": "Thunderbolt™ 3 -teknologia",
    "tr_Thunderbolt4": "Thunderbolt™ 4 -teknologia",
    "tr_processorGraphicsCaps": "SUORITTIMEN NÄYTÖNOHJAIN",
    "tr_processorGraphicsSmall": "Suorittimen näytönohjain",
    "tr_graphicsCardCaps": "ERILLINEN NÄYTÖNOHJAIN",
    "tr_graphicsCardSmall": "Erillinen näytönohjain",
    "tr_processorTitleCamel": "Suoritin",
    "tr_graphicTitleCamel": "Näytönohjain",
    "tr_storageTitleCamel": "Tallennustila",
    "tr_displayTitleCamel": "Näyttö",
    "tr_batteryTitleCamel": "Akku",
    "tr_memoryTitleCamel": "Muisti",
    "tr_connectivityTitleCamel": "Yhteydet",
    "tr_priceTitleCamel": "hinta",
    "tr_operatingSystemTitleCamel": "Käyttöjärjestelmä",
    "tr_viewallspecsCamel": "Näytä kaikki tekniset tiedot",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal]” [screenResolutionType]-kosketusnäyttö",
    "tr_OS": "KÄYTTÖ&shy;JÄRJESTELMÄ",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Tietokoneen tekniset tiedot",
    "tr_explorePC_text": "Tutustu kaikkiin tämän tietokoneen ominaisuuksiin",
    "tr_mtlPC_text": "Suunniteltu tekemään kaikkea tarkoittaa tätä",
    "tr_optaneMemory": "Intel® Optane™ -muisti",
    "tr_displayText_Display": "[screenDiagonal]” NÄYTTÖ",
    "tr_displayTextResolution_Display": "[screenDiagonal]” [screenResolutionType] -NÄYTTÖ",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal]” [screenResolutionType] -kosketusnäyttö",
    "tr_displayTextTouchscreeenText": "[screenDiagonal]” kosketusnäyttö",
    "tr_learnMoreLabel": "Lue lisää",
    "tr_batteryHoursTextUpperCase": "[XX] TUNTIA",
    "tr_processorGraphicsIrisXe": "11. SUKUPOLVEN INTEL® CORE™ [cpu_fam] -SUORITIN JA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> -NÄYTÖNOHJAIN",
    "tr_processorGraphicsIrisXeMax": "11. SUKUPOLVEN INTEL® CORE™ [cpu_fam] -SUORITIN JA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX -NÄYTÖNOHJAIN",
    "tr_Thunderbolt3UpperCase": "THUNDERBOLT™ 3 -TEKNOLOGIA",
    "tr_Thunderbolt4UpperCase": "THUNDERBOLT™ 4 -TEKNOLOGIA",
    "tr_processorWithArcGraphics": "12. SUKUPOLVEN INTEL® CORE™ [cpu_fam] -SUORITIN JA INTEL® ARC™ -NÄYTÖNOHJAIN",
    "tr_processorGraphicsIrisXe12Gen": "12. SUKUPOLVEN INTEL® CORE™ [cpu_fam] -SUORITIN JA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> -NÄYTÖNOHJAIN",
    "tr_processorGraphicsIrisXeMax12Gen": "12. SUKUPOLVEN INTEL® CORE™ [cpu_fam] -SUORITIN JA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX -NÄYTÖNOHJAIN",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "13. SUKUPOLVEN INTEL® CORE™ [cpu_fam] -SUORITIN JA INTEL® ARC™ -NÄYTÖNOHJAIN",
    "tr_processorGraphicsIrisXe13Gen": "13. SUKUPOLVEN INTEL® CORE™ [cpu_fam] -SUORITIN JA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> -NÄYTÖNOHJAIN",
    "tr_processorGraphicsIrisXeMax13Gen": "13. SUKUPOLVEN INTEL® CORE™ [cpu_fam] -SUORITIN JA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX -NÄYTÖNOHJAIN",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "INTEL® ARC™ A350M -NÄYTÖNOHJAIN",
      "tr_A370M": "INTEL® ARC™ A370M -NÄYTÖNOHJAIN",
      "tr_A730M": "INTEL® ARC™ A730M -NÄYTÖNOHJAIN",
      "tr_A770M": "INTEL® ARC™ A770M -NÄYTÖNOHJAIN",
      "tr_A310": "INTEL® ARC™ A310 -NÄYTÖNOHJAIN",
      "tr_A380": "INTEL® ARC™ A380 -NÄYTÖNOHJAIN",
      "tr_A580": "INTEL® ARC™ A580 -NÄYTÖNOHJAIN",
      "tr_A750": "INTEL® ARC™ A750 -NÄYTÖNOHJAIN",
      "tr_A770": "INTEL® ARC™ A770 -NÄYTÖNOHJAIN"
    },
    "tr_memoryValue": "[XX] Gt RAM-muistia",
    "tr_memoryWithOptaneDesc": "[Value1] Gt muistia = [Value2] Gt Intel® Optane™ -muistia + [Value3] Gt RAM-muistia",
    "tr_textGBGB": "[convertedSSD] Gt:n SSD-asema + [convertedHDD] Gt:n kiintolevy",
    "tr_textGBTB": "[convertedSSD] Gt:n SSD-asema + [convertedHDD] Tt:n kiintolevy",
    "tr_textTBGB": "[convertedSSD] Tt:n SSD-asema + [convertedHDD] Gt:n kiintolevy",
    "tr_textTBTB": "[convertedSSD] Tt:n SSD-asema + [convertedHDD] Tt:n kiintolevy",
    "tr_textGBSSD": "[convertedSSD] Gt:n SSD",
    "tr_textTBSSD": "[convertedSSD] Tt:n SSD",
    "tr_textGBHDD": "[convertedHDD] Gt:n kiintolevy",
    "tr_textTBHDD": "[convertedHDD] Tt:n kiintolevy",
    "tr_textStorageWithOptaneGB": "[XX] Gt:n Intel® Optane™ -muisti + [convertedSSD] Gt:n SSD-asema",
    "tr_textStorageWithOptaneTB": "[XX] Gt:n Intel® Optane™ -muisti + [convertedSSD] Tt:n SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8,1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8,1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home in S mode",
      "tr_OS21": "Windows* 10 Pro in S mode",
      "tr_OS22": "Windows* 10 Enterprise in S mode",
      "tr_OS23": "Windows* 11 Home in S mode",
      "tr_OS24": "Windows* 11 Pro in S mode",
      "tr_OS25": "Windows* 11 Enterprise in S mode"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Intel® Arc™ A310 Graphics",
      "tr_GR387": "Intel® Arc™ A350M Graphics",
      "tr_GR388": "Intel® Arc™ A370M Graphics",
      "tr_GR389": "Intel® Arc™ A380 Graphics",
      "tr_GR390": "Intel® Arc™ A530M Graphics",
      "tr_GR391": "Intel® Arc™ A550M Graphics",
      "tr_GR392": "Intel® Arc™ A570M Graphics",
      "tr_GR393": "Intel® Arc™ A580 Graphics",
      "tr_GR394": "Intel® Arc™ A730M Graphics",
      "tr_GR395": "Intel® Arc™ A750 Graphics",
      "tr_GR396": "Intel® Arc™ A770 Graphics",
      "tr_GR397": "Intel® Arc™ A770M Graphics",
      "tr_GR398": "Intel® Arc™ Graphics",
      "tr_GR399": "Intel® Arc™ Pro A30M Graphics",
      "tr_GR400": "Intel® Arc™ Pro A40/A50 Graphics",
      "tr_GR401": "Intel® Arc™ Pro A60 Graphics",
      "tr_GR402": "Intel® Arc™ Pro A60M Graphics",
      "tr_GR403": "Intel® -Grafiikka ",
      "tr_GR404": "Intel® HD Graphics 2000",
      "tr_GR405": "Intel® HD Graphics 2500",
      "tr_GR406": "Intel® HD Graphics 3000",
      "tr_GR407": "Intel® HD Graphics 400",
      "tr_GR408": "Intel® HD Graphics 4000",
      "tr_GR409": "Intel® HD Graphics 405",
      "tr_GR410": "Intel® HD Graphics 4200",
      "tr_GR411": "Intel® HD Graphics 4400",
      "tr_GR412": "Intel® HD Graphics 4600",
      "tr_GR413": "Intel® HD Graphics 500",
      "tr_GR414": "Intel® HD Graphics 5000",
      "tr_GR415": "Intel® HD Graphics 505",
      "tr_GR416": "Intel® HD Graphics 510",
      "tr_GR417": "Intel® HD Graphics 515",
      "tr_GR418": "Intel® HD Graphics 520",
      "tr_GR419": "Intel® HD Graphics 530",
      "tr_GR420": "Intel® HD Graphics 5300",
      "tr_GR421": "Intel® HD Graphics 5500",
      "tr_GR422": "Intel® HD Graphics 5600",
      "tr_GR423": "Intel® HD Graphics 6000",
      "tr_GR424": "Intel® HD Graphics 610",
      "tr_GR425": "Intel® HD Graphics 615",
      "tr_GR426": "Intel® HD Graphics 620",
      "tr_GR427": "Intel® HD Graphics 630",
      "tr_GR428": "Intel® HD Graphics",
      "tr_GR429": "Intel® Iris® Graphics 5100",
      "tr_GR430": "Intel® Iris® Graphics 540",
      "tr_GR431": "Intel® Iris® Graphics 550",
      "tr_GR432": "Intel® Iris® Graphics 6100",
      "tr_GR433": "Intel® Iris® Plus Graphics 640",
      "tr_GR434": "Intel® Iris® Plus Graphics 645",
      "tr_GR435": "Intel® Iris® Plus Graphics 650",
      "tr_GR436": "Intel® Iris® Plus Graphics 655",
      "tr_GR437": "Intel® Iris® Plus Graphics",
      "tr_GR438": "Intel® Iris® Pro Graphics 5200",
      "tr_GR439": "Intel® Iris® Pro Graphics 580",
      "tr_GR440": "Intel® Iris® Pro Graphics 6200",
      "tr_GR441": "Intel® Iris® X<sup>e</sup> -näytönohjain",
      "tr_GR442": "Intel® Iris® X<sup>e</sup> MAX -näytönohjain A220M",
      "tr_GR443": "Intel® Iris® X<sup>e</sup> MAX -näytönohjain",
      "tr_GR444": "Intel® Integrated Graphics",
      "tr_GR445": "Intel® UHD Graphics 600",
      "tr_GR446": "Intel® UHD Graphics 605",
      "tr_GR447": "Intel® UHD Graphics 610",
      "tr_GR448": "Intel® UHD Graphics 615",
      "tr_GR449": "Intel® UHD Graphics 617",
      "tr_GR450": "Intel® UHD Graphics 620",
      "tr_GR451": "Intel® UHD Graphics 630",
      "tr_GR452": "Intel® UHD Graphics 710",
      "tr_GR453": "Intel® UHD Graphics 730",
      "tr_GR454": "Intel® UHD Graphics 770",
      "tr_GR455": "Intel® UHD Graphics",
      "tr_GR456": "Intel® UHD Graphics",
      "tr_GR457": "Intel® UHD Graphics",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 ja Max-Q Design",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti ja Max-Q Design",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 ja Max-Q Design",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 ja Max-Q Design",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 ja Max-Q Design",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 ja Max-Q Design",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti ja Max-Q Design",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti ja Max-Q Design",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 ja Max-Q Design",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 ja Max-Q Design",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super ja Max-Q Design",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 ja Max-Q Design",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super ja Max-Q Design",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 ja Max-Q Design",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 ja Max-Q Design",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 ja Max-Q Design",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 ja Max-Q Design",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 ja Max-Q Design",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 ja Max-Q Design",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 ja Max-Q Design",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 ja Max-Q Design",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 ja Max-Q Design",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 ja Max-Q Design",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada-sukupolvi",
      "tr_GR773": "NVIDIA* RTX 3000 Ada-sukupolvi",
      "tr_GR774": "NVIDIA* RTX 3500 Ada-sukupolvi",
      "tr_GR775": "NVIDIA* RTX 4000 Ada-sukupolvi",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada-sukupolvi",
      "tr_GR777": "NVIDIA* RTX 4500 Ada-sukupolvi",
      "tr_GR778": "NVIDIA* RTX 5000 Ada-sukupolvi",
      "tr_GR779": "NVIDIA* RTX 5880 Ada-sukupolvi",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada-sukupolvi",
      "tr_GR811": "NVIDIA* RTX 1000 Ada-sukupolvi",
      "tr_GR812": "Intel® Arc™ -näytönohjain",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E, Ada-sukupolvi",
      "tr_GR815": "AMD Radeon* Pro W7900, kaksoispaikka"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Osta heti",
    "tr_textTryagain": "Yritä uudelleen.",
    "tr_textBuynowErr": "Osta heti -vaihtoehto ei ole käytettävissä yhteyden katkeamisen vuoksi",
    "tr_chatbotPlaceholder_text": "Kysy minulta mitä tahansa laitteesta",
    "tr_deviceSpecs_text": "Laitetiedot"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Intel® Core™ i3 &#8209;suoritin ja Intel®&nbsp;Hybrid &#8209;teknologia"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Intel® Core™ i5 &#8209;suoritin ja Intel®&nbsp;Hybrid &#8209;teknologia"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Intel® tekee pelikokemuksesta mahtavan",
                "tr_subtitle": "Herätä Marvel’s Avengers* -sankarit eloon pelinsisäisillä optimoinneilla, joiden tehosta vastaa Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Koe Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Täällä kilpailevat maailman parhaat pelaajat",
                "tr_subtitle": "Intel® Extreme Masters on videopelihistorian kauimmin jatkunut pelisarja, joka asettaa e-urheilulle standardit.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Pelaa kuin ammattilaiset",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Intel tekee mestareita",
                "tr_subtitle": "Voitettuaan Rainbow Six Siegen* vuoden 2021 Pohjois-Amerikan mestaruussarjan Soniqs-joukkue pohtii pelihistoriaansa, e-urheilun tulevaisuutta ja kumppanuuttaan Intelin kanssa.",
                "tr_pretitle": "Esittelyssä Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "VR-valmius",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Missä VR herää eloon",
                "tr_subtitle": "Kaikkein uskomattomimmat, elävimmän tuntuiset ja mukaansatempaavimmat virtuaalitodellisuuskokemukset tietokoneella. Uusimpien Intel® Core™ &#8209;suorittimien ansiosta voit olla kuka tahansa, mennä minne tahansa ja kokea pelaamisen uudessa ulottuvuudessa.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Kokeile yli 100:a laadukasta konsolipeliä, kuten Sea of Thieves* ja Psychonauts* 2, Windows*-tietokoneellasi, matkapuhelimella ja tabletilla Xbox* Game Pass Ultimaten ja yhteensopivan ohjaimen avulla.",
                "titleArray": [
                  {
                    "tr_title": "Pelaa yhdessä yhdistämällä laitteita",
                    "tr_subtitle": "Hyppää Xboxin* ytimeen, jossa miljoonien pelaajien yhteisö odottaa valmiina pelaamaan yhdessä. Liity ja pelaa muiden kanssa käyttäen jaettua pelikirjastoa, olivatpa he sitten toisella puolella maailmaa tai aivan vieressä."
                  },
                  {
                    "tr_title": "Pelaa Windows* PC:llä",
                    "tr_subtitle": "Pelaa konsolipelejä pilvipalvelimen pelivalikoimasta käyttämällä Windowsin* Xbox*-sovellusta ja yhteensopivaa ohjainta."
                  },
                  {
                    "tr_title": "Valitse ja pelaa",
                    "tr_subtitle": "Nauti loistavista Xbox*-peleistä aiempaa useammissa paikoissa. Pelaa yli 100:a peliä tietokoneella, matkapuhelimella ja tabletilla, olitpa sitten kotona, kun televisiosi on käytössä, kahvilassa tai missä tahansa muualla, missä on nopea internetyhteys."
                  },
                  {
                    "tr_title": "Pidä hauskaa ilman ohjainta",
                    "tr_subtitle": "Xboxin* kosketusnäyttöinen pelaaminen antaa uusia tapoja liittyä mukaan ja pelata. Se tarjoaa totutun kokemuksen ja tukee pelitasoa, johon olet tottunut käyttäessäsi fyysistä ohjainta."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Valokeilassa",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Yli 100 tekoälykokemusta",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Ohjaa enemmän, kosketa vähemmän",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Pysy kuvassa kaikissa ympäristöissä",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Luovuus",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Korjaa valaistusta kuin ammattilainen",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Lisää helposti vaikuttavia tehosteita",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Remiksaa kappaleita hyödyntämällä tekoälyn tehoa",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tuottavuus",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Tehosta tuottavuutta ja yksityisyyttä",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Vietä enemmän aikaa erossa pistorasiasta",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Sinun maailmasi. Sinun laitteillasi.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Viihde",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Sulavaa suoratoistoa myös ilman verkkovirtaa",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Nopeaa, mukaansatempaavaa pelaamista",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Valokeilassa",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Yli 100 tekoälykokemusta",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Tee suosikkikappaleistasi omiasi",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Näe visiosi reaaliajassa",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Luovuus",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Nopein tie viimeiseen versioon",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Tee jokaisesta kuvasta täydellinen",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tuottavuus",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Tehosta tuottavuutta ja yksityisyyttä",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Enemmän tehokkuutta. Enemmän vapautta.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Sinun maailmasi. Sinun laitteillasi.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Viihde",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Sujuvaa HD-suoratoistoa myös ilman verkkovirtaa",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Mukaansatempaavaa pelaamista missä tahansa",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Luovempi",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Kuvanmuokkausta helpolla tavalla",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Luo kehystettäväksi kelpaavia valokuvia sekunneissa",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Kirjoita mitä haluat – tekoäly luo sen",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Parempi suorituskyky",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Huijauskoodi sujuvampaan pelaamiseen",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Vietä enemmän aikaa erossa pistorasiasta",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-immersiivinen",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tuottavampi",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Jokapäiväinen tekoälykumppanisi",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Täydelliset videopuhelut",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Sinun maailmasi. Sinun laitteillasi.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Turvallisempi",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Sisäänrakennettu suojaus pitää tietosi turvassa ja yksityisinä",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Luovempi",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Kuvanmuokkausta helpolla tavalla",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Tekoäly – oikotie valmiiseen versioon",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Muunna suosikkikappaleesi sanoitus nopeasti tekstiksi",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Vältä tekoälyn avulla tuntikausien muokkaus",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Parempi suorituskyky",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Huijauskoodi sujuvampaan pelaamiseen",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Vietä enemmän aikaa erossa pistorasiasta",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-immersiivinen",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tuottavampi",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Jokapäiväinen tekoälykumppanisi",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Täydelliset videopuhelut",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Sinun maailmasi. Sinun laitteillasi.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Turvallisempi",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Sisäänrakennettu suojaus pitää tietosi turvassa ja yksityisinä",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Kaikki viihde tietokoneellasi",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Jos tietokone toimii myös viihdekeskuksena, tarvitset riittävästi tallennustilaa lempikappaleillesi, videoillesi ja peleille sekä suorittimen ja näytön oikean yhdistelmän, jotta voit luoda mukaansatempaavaa grafiikkaa. Tässä tietokoneessa on kaikki kolme.",
                "titleArray": [
                  {
                    "tr_title": "Valmis seuraavan sukupolven grafiikkaa varten",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Luo oma digitaalinen kirjasto",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Viihteen ikkuna",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Saumatonta pelaamista ja suoratoistoa",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Luo oma digitaalinen kirjasto",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Viihteen ikkuna",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Ole tuottavampi, missä tahansa",
              "tr_tileTitle1": "Saat aikaan enemmän. Nopeasti.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tässä tietokoneessa tehokkuus ja kannettavuus ovat täydellisessä tasapainossa. Ohuessa ja kevyessä tietokoneessa on myös Wi&#8209;Fi, joka varmistaa yhteytesi, ja akku, jonka ansiosta et tarvitse pistorasioita.",
                "tr_subtitle1": "Tässä tietokoneessa teho ja suorituskyky ovat täydellisessä tasapainossa, joten voit tehdä sillä enemmän lyhyemmässä ajassa. Tehokas suoritin, johon on yhdistetty runsaasti muistia ja salamannopea Wi-Fi-yhteys, tekee päivittäisistä tehtävistä vaivattomia.",
                "tr_subtitle2": "Tässä tietokoneessa tehokkuus ja kannettavuus ovat täydellisessä tasapainossa. Siinä on ohuille ja kevyille tietokoneille suunniteltu suoritin, Wi‑Fi, joka varmistaa yhteytesi, ja muisti, jolla moniajo sujuu.",
                "titleArray": [
                  {
                    "tr_title": "Luotu tekemään enemmän",
                    "tr_title1": "Älykästä suorituskykyä, jolla on vaikutusta",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Luokkansa parhaat yhteydet",
                    "tr_title1": "Yhdistä siihen, mikä on tärkeintä",
                    "tr_title2": "Wi‑Fi-teknologian edistysaskel",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Pidempään ilman johtoa",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Vaihtele ohjelmia kuin ammattilainen",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Vapaus luoda",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Sisällön luominen on yksi tietokoneen vaativimmista tehtävistä. Onneksi nämä osat tehostavat luovuuttasi.",
                "titleArray": [
                  {
                    "tr_title": "Älykkäämpi tapa luoda",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Herätä kuvasi eloon",
                    "tr_title1": "Päästä luovuutesi valloilleen",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Muistia moniajoon",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Katso tämän tietokoneen sisään",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Pidä mielessäsi nämä kolme pääasiaa valitessasi uutta tietokonetta. Kun päätät, miten aiot käyttää uutta tietokonettasi, tiedät jo suurin piirtein, mitä tarvitset. Seuraavassa on kolme tärkeintä osaa:",
                "titleArray": [
                  {
                    "tr_title": "Suorituskykyä pelikäyttöön ja suoratoistoon",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Tallennustila",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Muisti",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Kaikki viihde tietokoneellasi",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Jos tietokone toimii myös viihdekeskuksena, tarvitset riittävästi tallennustilaa lempikappaleillesi, videoillesi ja peleille sekä suorittimen ja näytön oikean yhdistelmän, jotta voit luoda mukaansatempaavaa grafiikkaa. Tässä tietokoneessa on kaikki kolme.",
                "titleArray": [
                  {
                    "tr_title": "Valmis seuraavan sukupolven grafiikkaa varten",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Luo oma digitaalinen kirjasto",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Viihteen ikkuna",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Saumatonta pelaamista ja suoratoistoa",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Luo oma digitaalinen kirjasto",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Viihteen ikkuna",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Muisti",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Näyttö",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Nopea moniajo",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NOPEA MONIAJO",
                "tr_subtitle": "Tee enemmän hidastamatta",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat hyviä moniajon kannalta:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Useita ohjelmia samanaikaisesti käsittelevä suoritin"
                  },
                  {
                    "tr_title": "Nopea, vahva ja luotettava Wi‑Fi-yhteys"
                  },
                  {
                    "tr_title": "Saumattomasti sovellusten välillä vaihtava muisti"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videokeskustelu",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOKESKUSTELU",
                "tr_subtitle": "Kristallinkirkkaat yhteydet",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat videokeskustelun kannalta loistavia:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Kuvia samanaikaisesti tallentava ja jakava suoritin"
                  },
                  {
                    "tr_title": "Nopea, vahva ja luotettava Wi‑Fi-yhteys"
                  },
                  {
                    "tr_title": "Kristallinkirkasta kuvaa lähettävä verkkokamera"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-valmius",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Missä VR herää eloon",
                "tr_subtitle": "Miten luodaan täysin virtuaalinen maailma? Tietokoneessa tarvitaan suorittimen, näytönohjaimen ja muistin välistä koordinaatiota.",
                "titleArray": [
                  {
                    "tr_title": "Tehoa virtuaalimaailmaan",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Todellisuus on yksityiskohdissa",
                    "tr_title1": "Uppoudu katselukokemukseen",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Videoeditointi",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOEDITOINTI",
                "tr_subtitle": "Tee elokuvataikoja",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat videoeditoinnin kannalta loistavia:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Suuria tiedostoja käsittelevä suoritin"
                  },
                  {
                    "tr_title": "Editointiohjelmistosta nopean ja responsiivisen tekevä muisti"
                  },
                  {
                    "tr_title": "Renderointiaikoja lyhentävä näytönohjain"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Nopea moniajo",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NOPEA MONIAJO",
                "tr_subtitle": "Tee enemmän hidastamatta",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat hyviä moniajon kannalta:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Useita ohjelmia samanaikaisesti käsittelevä suoritin"
                  },
                  {
                    "tr_title": "Nopea, vahva ja luotettava Wi‑Fi-yhteys"
                  },
                  {
                    "tr_title": "Saumattomasti sovellusten välillä vaihtava muisti"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-valmius",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Missä VR herää eloon",
                "tr_subtitle": "Miten luodaan täysin virtuaalinen maailma? Tietokoneessa tarvitaan suorittimen, näytönohjaimen ja muistin välistä koordinaatiota.",
                "titleArray": [
                  {
                    "tr_title": "Tehoa virtuaalimaailmaan",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Todellisuus on yksityiskohdissa",
                    "tr_title1": "Uppoudu katselukokemukseen",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D-mallinnus",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D-MALLINNUS",
                "tr_subtitle": "Seuraavan tason luomistyötä",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat 3D-mallinnuksen kannalta loistavia:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Kehittyneitä luovia ohjelmistoja käsittelevä suoritin"
                  },
                  {
                    "tr_title": "Suuret tiedostot nopeasti valmisteleva muisti"
                  },
                  {
                    "tr_title": "Tehokkaaseen kuvien luomiseen ja muokkaamiseen sopiva näytönohjain"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Reaaliaikainen yhteistyö",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "REAALIAIKAINEN YHTEISTYÖ",
                "tr_subtitle": "Työskentele mistä käsin tahansa",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat verkkoyhteistyön kannalta loistavia:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Toimisto-ohjelmistoja tukeva suoritin"
                  },
                  {
                    "tr_title": "Luotettava Wi‑Fi-yhteys"
                  },
                  {
                    "tr_title": "Upeana teräväpiirtona tallentava verkkokamera"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-valmius",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Missä VR herää eloon",
                "tr_subtitle": "Miten luodaan täysin virtuaalinen maailma? Tietokoneessa tarvitaan suorittimen, näytönohjaimen ja muistin välistä koordinaatiota.",
                "titleArray": [
                  {
                    "tr_title": "Tehoa virtuaalimaailmaan",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Todellisuus on yksityiskohdissa",
                    "tr_title1": "Uppoudu katselukokemukseen",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ -kannettavat",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Näin ohuet ja kevyet kannettavat ovat harvoin yhtä tehokkaita",
                "tr_subtitle": "Intel® Evo™ -kannettavat on suunniteltu mobiilikäyttöön, ja ne tarjoavat tarvitsemasi nopeuden, grafiikan ja akunkeston upean ohuessa ja kevyessä muodossa.",
                "titleArray": [
                  {
                    "tr_title": "Kannettava tietokone on tärkeämpi väline kuin koskaan, joten varmistimme, että se on entistä tehokkaampi.",
                    "tr_subtitle": "Selaa, pyöritä ja zoomaa kosketusnäytön avulla."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Käyttövalmis hetkessä",
                    "tr_subtitle": "Tietokoneen voi palauttaa lepotilasta käyttöön alle sekunnissa."
                  },
                  {
                    "tr_title": "Uppoudu katselukokemukseen",
                    "tr_subtitle": "Upea näyttö herättää kuvan eloon suurella tarkkuudella ja eloisin värein."
                  },
                  {
                    "tr_title": "Tee valmista jälkeä nopeasti",
                    "tr_subtitle": "Nauti huippuluokan suorituskyvystä 11.&nbsp;sukupolven Intel®&nbsp;Core™ -suorittimilla."
                  },
                  {
                    "tr_title": "Entistäkin parempi akunkesto",
                    "tr_subtitle": "Työskentele keskeytyksettä tietokoneella, joka optimoi akunkeston ja latautuu hetkessä."
                  },
                  {
                    "tr_title": "Universaali liitettävyys",
                    "tr_subtitle": "Thunderbolt™ 4 -teknologia tekee muiden laitteiden liittämisestä helppoa, nopeaa ja turvallista.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Nopeat ja luotettavat yhteydet",
                    "tr_subtitle": "Puhu videopuheluita tai jaa tiedostoja luotettavalla yhteydellä, joka käyttää Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+)&nbsp;&#8209;teknologiaa.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ -kannettavat",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Kaikki mitä tarvitset ja enemmänkin ohuessa ja kevyessä kannettavassa",
                "tr_subtitle": "Intel® Evo™ -kannettavat on suunniteltu mobiilikäyttöön ja tarjoamaan paras kokemus<sup>2</sup>, mitä ikinä teetkin.",
                "titleArray": [
                  {
                    "tr_title": "Kannettava tietokone on tärkeämpi väline kuin koskaan, joten varmistimme, että se on entistä tehokkaampi.",
                    "tr_subtitle": "Selaa, pyöritä ja zoomaa kosketusnäytön avulla."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Käyttövalmis hetkessä",
                    "tr_subtitle": "Tietokoneen voi palauttaa lepotilasta käyttöön alle sekunnissa."
                  },
                  {
                    "tr_title": "Uppoudu katselukokemukseen",
                    "tr_subtitle": "Upea näyttö herättää kuvan eloon suurella tarkkuudella ja eloisin värein."
                  },
                  {
                    "tr_title": "Mullistava suorituskyky",
                    "tr_subtitle": "12. sukupolven Intel® Core™ -suorittimien älykkään suorituskyvyn ansiosta saat lisätehoa siellä, missä sitä eniten tarvitset."
                  },
                  {
                    "tr_title": "Entistäkin parempi akunkesto",
                    "tr_subtitle": "Työskentele keskeytyksettä tietokoneella, joka optimoi akunkeston ja latautuu hetkessä."
                  },
                  {
                    "tr_title": "Universaali liitettävyys",
                    "tr_subtitle": "Thunderbolt™ 4 -teknologia tekee muiden laitteiden liittämisestä helppoa, nopeaa ja turvallista.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Nopeat ja luotettavat yhteydet",
                    "tr_subtitle": "Puhu videopuheluita tai jaa tiedostoja luotettavalla yhteydellä, joka käyttää Intel® Wi-Fi 6E (Gig+) -teknologiaa.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ -kannettavat",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Näin ohuet ja kevyet kannettavat ovat harvoin yhtä tehokkaita",
                "tr_subtitle": "Intel® Evo™ -kannettavat on suunniteltu mobiilikäyttöön, ja ne tarjoavat tarvitsemasi nopeuden, grafiikan ja akunkeston upean ohuessa ja kevyessä muodossa.",
                "titleArray": [
                  {
                    "tr_title": "Kannettava tietokone on tärkeämpi väline kuin koskaan, joten varmistimme, että se on entistä tehokkaampi.",
                    "tr_subtitle": "Selaa, pyöritä ja zoomaa kosketusnäytön avulla."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Käyttövalmis hetkessä",
                    "tr_subtitle": "Tietokoneen voi palauttaa lepotilasta käyttöön alle sekunnissa."
                  },
                  {
                    "tr_title": "Uppoudu katselukokemukseen",
                    "tr_subtitle": "Upea näyttö herättää kuvan eloon suurella tarkkuudella ja eloisin värein."
                  },
                  {
                    "tr_title": "Tee valmista jälkeä nopeasti",
                    "tr_subtitle": "Nauti huippuluokan suorituskyvystä 11.&nbsp;sukupolven Intel®&nbsp;Core™ -suorittimilla."
                  },
                  {
                    "tr_title": "Entistäkin parempi akunkesto",
                    "tr_subtitle": "Työskentele keskeytyksettä tietokoneella, joka optimoi akunkeston ja latautuu hetkessä."
                  },
                  {
                    "tr_title": "Universaali liitettävyys",
                    "tr_subtitle": "Thunderbolt™ 4 -teknologia tekee muiden laitteiden liittämisestä helppoa, nopeaa ja turvallista.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Nopeat ja luotettavat yhteydet",
                    "tr_subtitle": "Puhu videopuheluita tai jaa tiedostoja luotettavalla yhteydellä, joka käyttää Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+)&nbsp;&#8209;teknologiaa.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ -kannettavat",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Kaikki mitä tarvitset ja enemmänkin ohuessa ja kevyessä kannettavassa",
                "tr_subtitle": "Intel® Evo™ -kannettavat on suunniteltu mobiilikäyttöön ja tarjoamaan paras kokemus<sup>2</sup>, mitä ikinä teetkin.",
                "titleArray": [
                  {
                    "tr_title": "Kannettava tietokone on tärkeämpi väline kuin koskaan, joten varmistimme, että se on entistä tehokkaampi.",
                    "tr_subtitle": "Selaa, pyöritä ja zoomaa kosketusnäytön avulla."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Käyttövalmis hetkessä",
                    "tr_subtitle": "Tietokoneen voi palauttaa lepotilasta käyttöön alle sekunnissa."
                  },
                  {
                    "tr_title": "Uppoudu katselukokemukseen",
                    "tr_subtitle": "Upea näyttö herättää kuvan eloon suurella tarkkuudella ja eloisin värein."
                  },
                  {
                    "tr_title": "Mullistava suorituskyky",
                    "tr_subtitle": "12. sukupolven Intel® Core™ -suorittimien älykkään suorituskyvyn ansiosta saat lisätehoa siellä, missä sitä eniten tarvitset."
                  },
                  {
                    "tr_title": "Entistäkin parempi akunkesto",
                    "tr_subtitle": "Työskentele keskeytyksettä tietokoneella, joka optimoi akunkeston ja latautuu hetkessä."
                  },
                  {
                    "tr_title": "Universaali liitettävyys",
                    "tr_subtitle": "Thunderbolt™ 4 -teknologia tekee muiden laitteiden liittämisestä helppoa, nopeaa ja turvallista.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Nopeat ja luotettavat yhteydet",
                    "tr_subtitle": "Puhu videopuheluita tai jaa tiedostoja luotettavalla yhteydellä, joka käyttää Intel® Wi-Fi 6E (Gig+) -teknologiaa.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ -kannettavat",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Kaikki mitä tarvitset ja enemmänkin ohuessa ja kevyessä kannettavassa",
                "tr_subtitle": "Intel® Evo™ -kannettavat on suunniteltu mobiilikäyttöön ja tarjoamaan paras kokemus<sup>2</sup>, mitä ikinä teetkin.",
                "titleArray": [
                  {
                    "tr_title": "Kannettava tietokone on tärkeämpi väline kuin koskaan, joten varmistimme, että se on entistä tehokkaampi.",
                    "tr_subtitle": "Selaa, pyöritä ja zoomaa kosketusnäytön avulla."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Käyttövalmis hetkessä",
                    "tr_subtitle": "Tietokoneen voi palauttaa lepotilasta käyttöön alle sekunnissa."
                  },
                  {
                    "tr_title": "Uppoudu katselukokemukseen",
                    "tr_subtitle": "Upea näyttö herättää kuvan eloon suurella tarkkuudella ja eloisin värein."
                  },
                  {
                    "tr_title": "Mullistava suorituskyky",
                    "tr_subtitle": "13. sukupolven Intel® Core™ -suorittimien älykkään suorituskyvyn ansiosta saat lisätehoa siellä, missä sitä eniten tarvitset."
                  },
                  {
                    "tr_title": "Entistäkin parempi akunkesto",
                    "tr_subtitle": "Työskentele keskeytyksettä tietokoneella, joka optimoi akunkeston ja latautuu hetkessä."
                  },
                  {
                    "tr_title": "Universaali liitettävyys",
                    "tr_subtitle": "Thunderbolt™ 4 -teknologia tekee muiden laitteiden liittämisestä helppoa, nopeaa ja turvallista.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Nopeat ja luotettavat yhteydet",
                    "tr_subtitle": "Puhu videopuheluita tai jaa tiedostoja luotettavalla yhteydellä, joka käyttää Intel® Wi-Fi 6E (Gig+) -teknologiaa.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ -kannettavat",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Kaikki mitä tarvitset ja enemmänkin ohuessa ja kevyessä kannettavassa",
                "tr_subtitle": "Intel® Evo™ -kannettavat on suunniteltu mobiilikäyttöön ja tarjoamaan paras kokemus<sup>2</sup>, mitä ikinä teetkin.",
                "titleArray": [
                  {
                    "tr_title": "Kannettava tietokone on tärkeämpi väline kuin koskaan, joten varmistimme, että se on entistä tehokkaampi.",
                    "tr_subtitle": "Selaa, pyöritä ja zoomaa kosketusnäytön avulla."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Käyttövalmis hetkessä",
                    "tr_subtitle": "Tietokoneen voi palauttaa lepotilasta käyttöön alle sekunnissa."
                  },
                  {
                    "tr_title": "Uppoudu katselukokemukseen",
                    "tr_subtitle": "Upea näyttö herättää kuvan eloon suurella tarkkuudella ja eloisin värein."
                  },
                  {
                    "tr_title": "Mullistava suorituskyky",
                    "tr_subtitle": "13. sukupolven Intel® Core™ -suorittimien älykkään suorituskyvyn ansiosta saat lisätehoa siellä, missä sitä eniten tarvitset."
                  },
                  {
                    "tr_title": "Entistäkin parempi akunkesto",
                    "tr_subtitle": "Työskentele keskeytyksettä tietokoneella, joka optimoi akunkeston ja latautuu hetkessä."
                  },
                  {
                    "tr_title": "Universaali liitettävyys",
                    "tr_subtitle": "Thunderbolt™ 4 -teknologia tekee muiden laitteiden liittämisestä helppoa, nopeaa ja turvallista.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Nopeat ja luotettavat yhteydet",
                    "tr_subtitle": "Puhu videopuheluita tai jaa tiedostoja luotettavalla yhteydellä, joka käyttää Intel® Wi-Fi 6E (Gig+) -teknologiaa.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Valokeilassa",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Mikä on Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Miksi Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Luovuus",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ole luova entistä useammissa paikoissa",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Huippunopea kuvankäsittely",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Luo alkuperäistä taidetta hetkessä",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tuottavuus",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maksimoi tuottavuus Rewind AI:n avulla",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Havaitse tekoälyn avulla deepfake-videot ennen jakamista",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Sinun maailmasi. Sinun laitteillasi.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kannettavuus",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Vilkuta eleohjaukselle",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Akunkesto, joka vastaa tarpeitasi",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Pitää sinut yhteydessä automaattisesti",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Valokeilassa",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Mikä on Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Miksi Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Luovuus",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Nopea ja sulava videoiden toisto",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Tuota hittejä hyödyntämällä tekoälyn tehoa",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Nopein tie lopulliseen versioon",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tuottavuus",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maksimoi tuottavuus Rewind AI:n avulla",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Havaitse tekoälyn avulla deepfake-videot ennen jakamista",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Sinun maailmasi. Sinun laitteillasi.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kannettavuus",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Tee yhteistyötä lähes mistä tahansa",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Akunkesto, joka vastaa tarpeitasi",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Pitää sinut yhteydessä automaattisesti",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Kaikki viihde tietokoneellasi",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Jos tietokone toimii myös viihdekeskuksena, tarvitset riittävästi tallennustilaa lempikappaleillesi, videoillesi ja peleille sekä suorittimen ja näytön oikean yhdistelmän, jotta voit luoda mukaansatempaavaa grafiikkaa. Tässä tietokoneessa on kaikki kolme.",
                "titleArray": [
                  {
                    "tr_title": "Vaikuttavaa pelaamista ja suoratoistoa",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Luo oma digitaalinen kirjasto",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Viihteen ikkuna",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Saumatonta pelaamista ja suoratoistoa",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Luo oma digitaalinen kirjasto",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Viihteen ikkuna",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Ole tuottavampi, missä tahansa",
              "tr_tileTitle1": "Saat aikaan enemmän. Nopeasti.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tässä tietokoneessa tehokkuus ja kannettavuus ovat täydellisessä tasapainossa. Ohuessa ja kevyessä tietokoneessa on myös Wi&#8209;Fi, joka varmistaa yhteytesi, ja akku, jonka ansiosta et tarvitse pistorasioita.",
                "tr_subtitle1": "Tässä tietokoneessa teho ja suorituskyky ovat täydellisessä tasapainossa, joten voit tehdä sillä enemmän lyhyemmässä ajassa. Tehokas suoritin, johon on yhdistetty runsaasti muistia ja salamannopea Wi-Fi-yhteys, tekee päivittäisistä tehtävistä vaivattomia.",
                "tr_subtitle2": "Tässä tietokoneessa tehokkuus ja kannettavuus ovat täydellisessä tasapainossa. Siinä on ohuille ja kevyille tietokoneille suunniteltu suoritin, Wi‑Fi, joka varmistaa yhteytesi, ja muisti, jolla moniajo sujuu.",
                "titleArray": [
                  {
                    "tr_title": "Älykästä suorituskykyä, jolla on vaikutusta",
                    "tr_title1": "Nopeutta älykkäällä suorituskyvyllä",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Luokkansa parhaat yhteydet",
                    "tr_title1": "Yhdistä siihen, mikä on tärkeintä",
                    "tr_title2": "Wi‑Fi-teknologian edistysaskel",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Pidempään ilman johtoa",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Vaihtele ohjelmia kuin ammattilainen",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Vapaus luoda",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Sisällön luominen on yksi tietokoneen vaativimmista tehtävistä. Onneksi nämä osat tehostavat luovuuttasi.",
                "titleArray": [
                  {
                    "tr_title": "Tehokasta luovuutta",
                    "tr_title1": "Hoida luovat projektit sujuvasti",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Korkeatasoista luomista",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Muistia moniajoon",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Katso tämän tietokoneen sisään",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Pidä mielessäsi nämä kolme pääasiaa valitessasi uutta tietokonetta. Kun päätät, miten aiot käyttää uutta tietokonettasi, tiedät jo suurin piirtein, mitä tarvitset. Seuraavassa on kolme tärkeintä osaa:",
                "titleArray": [
                  {
                    "tr_title": "Suoritin",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Tallennustila",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Muisti",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Vapaus luoda",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Sisällön luominen on yksi tietokoneen vaativimmista tehtävistä. Onneksi nämä osat tehostavat luovuuttasi.",
                "titleArray": [
                  {
                    "tr_title": "Älykkäämpi tapa luoda",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Herätä kuvasi eloon",
                    "tr_title1": "Päästä luovuutesi valloilleen",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Muistia moniajoon",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Ole tuottavampi, missä tahansa",
              "tr_tileTitle1": "Saat aikaan enemmän. Nopeasti.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tässä tietokoneessa tehokkuus ja kannettavuus ovat täydellisessä tasapainossa. Ohuessa ja kevyessä tietokoneessa on myös Wi&#8209;Fi, joka varmistaa yhteytesi, ja akku, jonka ansiosta et tarvitse pistorasioita.",
                "tr_subtitle1": "Tässä tietokoneessa teho ja suorituskyky ovat täydellisessä tasapainossa, joten voit tehdä sillä enemmän lyhyemmässä ajassa. Tehokas suoritin, johon on yhdistetty runsaasti muistia ja salamannopea Wi-Fi-yhteys, tekee päivittäisistä tehtävistä vaivattomia.",
                "tr_subtitle2": "Tässä tietokoneessa tehokkuus ja kannettavuus ovat täydellisessä tasapainossa. Siinä on ohuille ja kevyille tietokoneille suunniteltu suoritin, Wi‑Fi, joka varmistaa yhteytesi, ja muisti, jolla moniajo sujuu.",
                "titleArray": [
                  {
                    "tr_title": "Luotu tekemään enemmän",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Luokkansa parhaat yhteydet",
                    "tr_title1": "Yhdistä siihen, mikä on tärkeintä",
                    "tr_title2": "Wi‑Fi-teknologian edistysaskel",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Pidempään ilman johtoa",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Vaihtele ohjelmia kuin ammattilainen",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-valmius",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Missä VR herää eloon",
                "tr_subtitle": "Miten luodaan täysin virtuaalinen maailma? Tietokoneessa tarvitaan suorittimen, näytönohjaimen ja muistin välistä koordinaatiota.",
                "titleArray": [
                  {
                    "tr_title": "Tehoa virtuaalimaailmaan",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Todellisuus on yksityiskohdissa",
                    "tr_title1": "Uppoudu katselukokemukseen",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>Mikä ihana näky",
      "tr_sub_header": "Sininen valo voi aiheuttaa visuaalista epämukavuutta ja häiritä unirytmiä. Samsungin SGS Eye Care Display -testattu OLED auttaa värien muuttamisen sijaan vähentämällä mahdollisesti haitallisen sinisen valon aallonpituutta, mikä helpottaa katselua ja vähentää silmien väsymistä.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Vähemmän haitallista sinistä valoa"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Kaikin tavoin ultra",
      "tr_sub_header": "OLEDin ultrakevyt ja ultraohut muotoilu maksimoi kuvan laadun ja minimoi samalla painon."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Aito musta",
      "tr_sub_header": "0,0005 nitiä"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74 %<br/><span class='conventional_text'>Tavallinen</span>",
      "tr_sub_header": "120 %<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Suoratoisto",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SUORATOISTO",
                "tr_subtitle": "Loputtomasti viihdettä",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat suoratoiston kannalta loistavia:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "HD-suoratoistoon kykenevä suoritin"
                  },
                  {
                    "tr_title": "Kirkkaita ja kauniita kuvia varten tehty näyttö"
                  },
                  {
                    "tr_title": "Vähemmän puskurointia ja lyhyempiä latausaikoja tarjoava Wi‑Fi-yhteys"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Verkkoselaus",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VERKKOSELAUS",
                "tr_subtitle": "Internet helpolla tavalla",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat verkkoselauksen kannalta loistavia:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Nopeasti mediapitoisia sivuja lataava suoritin"
                  },
                  {
                    "tr_title": "Nopea ja luotettava Wi‑Fi-yhteys"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videokeskustelu",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOKESKUSTELU",
                "tr_subtitle": "Kristallinkirkkaat yhteydet",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat videokeskustelun kannalta loistavia:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Kuvia samanaikaisesti tallentava ja jakava suoritin"
                  },
                  {
                    "tr_title": "Nopea, vahva ja luotettava Wi‑Fi-yhteys"
                  },
                  {
                    "tr_title": "Kristallinkirkasta kuvaa lähettävä verkkokamera"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Nopea moniajo",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NOPEA MONIAJO",
                "tr_subtitle": "Tee enemmän hidastamatta",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat hyviä moniajon kannalta:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Useita ohjelmia samanaikaisesti käsittelevä suoritin"
                  },
                  {
                    "tr_title": "Nopea, vahva ja luotettava Wi‑Fi-yhteys"
                  },
                  {
                    "tr_title": "Saumattomasti sovellusten välillä vaihtava muisti"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videokeskustelu",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOKESKUSTELU",
                "tr_subtitle": "Kristallinkirkkaat yhteydet",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat videokeskustelun kannalta loistavia:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Kuvia samanaikaisesti tallentava ja jakava suoritin"
                  },
                  {
                    "tr_title": "Nopea, vahva ja luotettava Wi‑Fi-yhteys"
                  },
                  {
                    "tr_title": "Kristallinkirkasta kuvaa lähettävä verkkokamera"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pelaa ajan kuluksi",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PELAA AJAN KULUKSI",
                "tr_subtitle": "On aika pelata",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat kevyen pelaamisen kannalta loistavia:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Sujuvan pelaamisen mahdollistava suoritin"
                  },
                  {
                    "tr_title": "Äänikeskusteluun ja suoratoistoon samanaikaisesti kykenevä muisti"
                  },
                  {
                    "tr_title": "Mahtavia kuvia tarjoava näytönohjain"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Valokuvien muokkaaminen",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VALOKUVIEN MUOKKAAMINEN",
                "tr_subtitle": "Pikselintarkkoja valokuvia",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat valokuvien muokkaamisen kannalta loistavia:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Suurten kuvajoukkojen muokkaamiseen kykenevä suoritin"
                  },
                  {
                    "tr_title": "Muokkaamiseen käytettävää ohjelmistoa nopeuttava muisti"
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 -teknologian avulla voit siirtää tiedostoja nopeasti"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Nopea moniajo",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NOPEA MONIAJO",
                "tr_subtitle": "Tee enemmän hidastamatta",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat hyviä moniajon kannalta:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Useita ohjelmia samanaikaisesti käsittelevä suoritin"
                  },
                  {
                    "tr_title": "Nopea, vahva ja luotettava Wi‑Fi-yhteys"
                  },
                  {
                    "tr_title": "Saumattomasti sovellusten välillä vaihtava muisti"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Reaaliaikainen yhteistyö",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "REAALIAIKAINEN YHTEISTYÖ",
                "tr_subtitle": "Työskentele mistä käsin tahansa",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat verkkoyhteistyön kannalta loistavia:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Toimisto-ohjelmistoja tukeva suoritin"
                  },
                  {
                    "tr_title": "Luotettava Wi‑Fi-yhteys"
                  },
                  {
                    "tr_title": "Upeana teräväpiirtona tallentava verkkokamera"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videoeditointi",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOEDITOINTI",
                "tr_subtitle": "Tee elokuvataikoja",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat videoeditoinnin kannalta loistavia:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Suuria tiedostoja käsittelevä suoritin"
                  },
                  {
                    "tr_title": "Editointiohjelmistosta nopean ja responsiivisen tekevä muisti"
                  },
                  {
                    "tr_title": "Renderointiaikoja lyhentävä näytönohjain"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Valokuvien muokkaaminen",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VALOKUVIEN MUOKKAAMINEN",
                "tr_subtitle": "Pikselintarkkoja valokuvia",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat valokuvien muokkaamisen kannalta loistavia:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Suurten kuvajoukkojen muokkaamiseen kykenevä suoritin"
                  },
                  {
                    "tr_title": "Muokkaamiseen käytettävää ohjelmistoa nopeuttava muisti"
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 -teknologian avulla voit siirtää tiedostoja nopeasti"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Videoeditointi",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOEDITOINTI",
                "tr_subtitle": "Tee elokuvataikoja",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat videoeditoinnin kannalta loistavia:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Suuria tiedostoja käsittelevä suoritin"
                  },
                  {
                    "tr_title": "Editointiohjelmistosta nopean ja responsiivisen tekevä muisti"
                  },
                  {
                    "tr_title": "Renderointiaikoja lyhentävä näytönohjain"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D-mallinnus",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D-MALLINNUS",
                "tr_subtitle": "Seuraavan tason luomistyötä",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat 3D-mallinnuksen kannalta loistavia:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Kehittyneitä luovia ohjelmistoja käsittelevä suoritin"
                  },
                  {
                    "tr_title": "Suuret tiedostot nopeasti valmisteleva muisti"
                  },
                  {
                    "tr_title": "Tehokkaaseen kuvien luomiseen ja muokkaamiseen sopiva näytönohjain"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Reaaliaikainen yhteistyö",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "REAALIAIKAINEN YHTEISTYÖ",
                "tr_subtitle": "Työskentele mistä käsin tahansa",
                "tr_subtitle1": "Nämä asiat tässä tietokoneessa ovat verkkoyhteistyön kannalta loistavia:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Toimisto-ohjelmistoja tukeva suoritin"
                  },
                  {
                    "tr_title": "Luotettava Wi‑Fi-yhteys"
                  },
                  {
                    "tr_title": "Upeana teräväpiirtona tallentava verkkokamera"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Intel® Unison™ -sovellus",
    "tr_title": "Avaa yhdistetty maailmasi",
    "tr_subtitle": "Siirrä tiedostoja ja kuvia saumattomasti samalla kun hallitset puhelimen ilmoituksia, puheluita ja tekstiviestejä tietokoneeltasi.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "SIIRRÄ TIEDOSTOJA JA KUVIA",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "SOITA JA VASTAA PUHELUIHIN",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "LÄHETÄ JA VASTAANOTA TEKSTIVIESTEJÄ",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "HALLITSE PUHELIMEN MUISTUTUKSIA",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "AVAA YHDISTETTY MAAILMASI",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}