const Library_mobile_cs = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Používá procesor [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Používá procesor [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Procesor podobně jako mozek řídí vše, co počítač dělá. Videa, která sledujete, hry, které hrajete, webové stránky, které navštěvujete – to vše začíná procesorem.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Procesor je mozkem počítače"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Skvělý výkon a užitná hodnota.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Pentium® Silver",
                    "tr_subtitle": "Díky výkonu při přístupu k&nbsp;souborům na webu vás ve vašem digitálním světě nebude nic omezovat. Můžete snadno zpracovávat více úloh najednou díky jednoduchému přepínání mezi programy a zvládnete toho více za kratší dobu.",
                    "tr_gen_core_title": "Procesor Intel® Pentium® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Pentium® Gold",
                    "tr_subtitle": "Vysoká produktivita – zvýšený výkon pro nejnáročnější dny. Plynulejší prohlížení webových stránek – rychlé načítání webových stránek bohatých na multimediální obsah znamená více objevování a méně čekání.",
                    "tr_gen_core_title": "Procesor Intel® Pentium® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Celeron®",
                    "tr_subtitle": "Výkon pro více práce a zábavy. Filmy a webová videa v úchvatných detailech. Více práce a zábavy a méně čekání.",
                    "tr_gen_core_title": "Procesor Intel® Celeron®"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Pentium®",
                    "tr_subtitle": "Určeno pro skvělou zábavu, streamování videí a produktivní práci. Filmy a webová videa v&nbsp;úchvatných detailech. Více práce a zábavy a méně čekání.",
                    "tr_gen_core_title": "Procesor Intel® Pentium®"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Výkon, který potřebujete. Cena, kterou chcete.",
                "tr_title": "Procesor Intel®",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Hodnota ve své vlastní kategorii",
                    "tr_subtitle": "Procesor Intel® zvládne prakticky každý úkol za cenu, kterou si můžete dovolit. Snadno přecházejte z jednoho softwaru do druhého. Nebo si vychutnejte vysoce kvalitní grafiku se stabilním výkonem.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Vytvářejte silnější vazby",
                    "tr_subtitle": "Živější kvalita videa, bohatší čistota zvuku a téměř třikrát rychlejší připojení k internetu vám umožní spolupracovat zcela bez obav, ať jste kdekoli.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Rozdíl je jako den a noc",
                    "tr_subtitle": "Od ranních filmových maratonů až po úpravy na poslední chvíli – procesor Intel® prodlouží výdrž na baterii vašeho zařízení, abyste si mohli vychutnat skutečně pohlcující zážitky bez přerušení.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Každé prostředí je vhodné pro učení",
                    "tr_subtitle": "Používejte několik vzdělávacích nástrojů on-line z kterékoli místnosti vašeho domova."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Učte se, kde chcete",
                    "tr_subtitle": "Dokončete výuku, kdekoli potřebujete, a zároveň používejte různé e-learningové nástroje."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Získejte výkon pro zábavu a produktivitu, který bude vyhovovat vašemu rozpočtu a životnímu stylu.",
                "tr_title": "Výkon Intel pro každý rozpočet.",
                "tr_gen_core_title": "Procesor Intel® Celeron®",
                "processorCompare": {
                  "tr_title": "NAJDĚTE SPRÁVNOU ÚROVEŇ VÝKONU A&nbsp;UŽITNÉ&nbsp;HODNOTY",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Procesor Intel® Celeron®"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rychlé prohlížení webových stránek bohatých na multimediální obsah",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snadné přepínání mezi programy",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraní běžných her",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování videa v&nbsp;rozlišení 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nenáročné úpravy fotografií a videa",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rychlá produktivita díky inteligentnímu výkonu",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Používá procesor 7.&nbsp;generace Intel® Core™ [cpu_fam]"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Používá procesor 6.&nbsp;generace Intel® Core™ [cpu_fam]"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Používá procesor 5.&nbsp;generace Intel® Core™ [cpu_fam]"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Používá procesor 4.&nbsp;generace Intel® Core™ [cpu_fam]"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Používá procesor 3.&nbsp;generace Intel® Core™ [cpu_fam]"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Používá procesor 2.&nbsp;generace Intel® Core™ [cpu_fam]"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Používá procesor 1.&nbsp;generace Intel® Core™ [cpu_fam]"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Doporučeno pro:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Používá procesor 8.&nbsp;generace Intel® Core™ i3",
                "tr_gen_core_title": "Procesor 8. generace Intel® Core™ i3"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesory 9. generace Intel® Core™ byly vytvořeny proto, aby vám pomohly držet krok s&nbsp;digitálním světem. Díky kombinaci jejich rychlosti a výkonu budete moci dělat více věcí, které vás na počítači baví, a to bez jakékoli frustrace.",
                "tr_title": "Používá procesor 9.&nbsp;generace Intel®&nbsp;Core™ i3",
                "tr_gen_core_title": "Procesor 9. generace Intel® Core™ i3"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Všestranný výkon je spojen s&nbsp;dlouhou výdrží na baterii, díky níž můžete být stále v&nbsp;pohybu. Kdekoli.",
                "tr_title": "Energie na celý den",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i3",
                "processorCompare": {
                  "tr_title": "NAJDĚTE IDEÁLNÍ ÚROVEŇ VÝKONU&nbsp;10.&nbsp;GENERACE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streamování filmů s&nbsp;rozlišením 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rychlé a&nbsp;spolehlivé připojení",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Úpravy fotografií a editace videa",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraní v&nbsp;rozlišení 1080p s&nbsp;plynulou frekvencí snímků",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ideální pro práci s&nbsp;velkými soubory a programy pro tvůrce kreativních děl",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Díky výkonové rezervě snadno přejdete od streamování filmů k&nbsp;vytváření&nbsp;prezentací.",
                "tr_title": "Energie na celý den",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i3"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tento notebook je určen zvláště těm, kteří jsou stále na cestách.",
                "tr_title": "Představujeme novou třídu notebooků",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i3"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesor 11.&nbsp;generace Intel® Core™ i3 je navržený speciálně pro přenosné počítače a umožní vám zvládnout víc než dosud, ať jste kdekoli.",
                "tr_title": "Využijte svůj nový notebook naplno",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i3",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i3",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Když celá vaše rodina používá ke všem úkolům jeden stolní počítač, potřebujete výkon a všestrannost procesoru 11.&nbsp;generace Intel® Core™ i3.",
                "tr_title": "Revoluční výkon vás připraví na&nbsp;všechno.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i3",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i3",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Používá procesor 8.&nbsp;generace Intel® Core™ i5",
                "tr_gen_core_title": "Procesor 8. generace Intel® Core™ i5"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesory 9. generace Intel® Core™ byly vytvořeny proto, aby vám pomohly držet krok s&nbsp;digitálním světem. Díky kombinaci jejich rychlosti a výkonu budete moci dělat více věcí, které vás na počítači baví, a to bez jakékoli frustrace.",
                "tr_title": "Používá procesor 9.&nbsp;generace Intel® Core™ i5",
                "tr_gen_core_title": "Procesor 9. generace Intel® Core™ i5"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "PLYNULÉ HRANÍ A VIRTUÁLNÍ REALITA I&nbsp;U&nbsp;NEJNÁROČNĚJŠÍCH TITULŮ",
                "tr_title": "Opravdové hraní začíná zde",
                "tr_gen_core_title": "Procesor 9. generace Intel® Core™ i5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Špičkové herní tituly",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraní, streamování a nahrávání bez kompromisů",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nejlepší procesor 9. generace Intel® Core™ pro hraní her",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 4<br/>Počet vláken: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Můžete snadno přepínat mezi několika programy a&nbsp;být tak připraveni na vše, co vám den přinese.",
                "tr_title": "Zpracování více úloh najednou na cestách",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i5",
                "processorCompare": {
                  "tr_title": "NAJDĚTE IDEÁLNÍ ÚROVEŇ VÝKONU&nbsp;10.&nbsp;GENERACE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streamování filmů s&nbsp;rozlišením 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rychlé a&nbsp;spolehlivé připojení",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Úpravy fotografií a editace videa",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraní v&nbsp;rozlišení 1080p s&nbsp;plynulou frekvencí snímků",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ideální pro práci s&nbsp;velkými soubory a programy pro tvůrce kreativních děl",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Zlepšit se dá všechno, čemu se věnujete&nbsp;– ať už je to zábava nebo vytváření obsahu.",
                "tr_title": "Upgradujte na výkonnější počítač",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i5"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tento notebook je určen zvláště těm, kteří jsou stále na cestách.",
                "tr_title": "Představujeme novou třídu notebooků",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i5"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vytvořeno pro plynulé streamování a&nbsp;hraní špičkových her",
                "tr_title": "Hraj podle sebe",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY 10.&nbsp;GENERACE INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Plynulé hraní nejnáročnějších her",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování s&nbsp;nahráváním",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tvorba náročného obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herní výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odemčené a připravené k&nbsp;přetaktování<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vytvořeno pro plynulé streamování a&nbsp;hraní špičkových her",
                "tr_title": "Hraj podle sebe",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY 10.&nbsp;GENERACE INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Plynulé hraní nejnáročnějších her",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování s&nbsp;nahráváním",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tvorba náročného obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herní výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odemčené a připravené k&nbsp;přetaktování<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 10<br/>Počet vláken: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Můžete snadno přepínat mezi několika programy a&nbsp;být tak připraveni na vše, co vám den přinese.",
                "tr_title": "Zpracování více úloh najednou na cestách",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i5",
                "tr_gen_core_title_uppercase": "PROCESOR 10. GEN. INTEL® CORE™ i5"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesor 11.&nbsp;generace Intel® Core™ i5 poskytuje nejtenčím a nejlehčím počítačům úchvatné vizuální možnosti a funkce zvyšující produktivitu.",
                "tr_title": "Působivý výkon notebooku",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i5",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i5",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Když celá vaše rodina používá ke všem úkolům jeden stolní počítač, potřebujete výkon a všestrannost procesoru 11.&nbsp;generace Intel® Core™ i5.",
                "tr_title": "Revoluční výkon vás připraví na&nbsp;všechno.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i5",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i5",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesor 11.&nbsp;generace Intel® Core™ i5 poskytuje tenkým a lehkým notebookům úchvatné vizuální možnosti a funkce zvyšující produktivitu.",
                "tr_title": "Působivý výkon notebooku",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i5",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i5"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Používá procesor 8.&nbsp;generace Intel® Core™ i7",
                "tr_gen_core_title": "Procesor 8. generace Intel® Core™ i7"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Procesory 9. generace Intel® Core™ byly vytvořeny proto, aby vám pomohly držet krok s&nbsp;digitálním světem. Díky kombinaci jejich rychlosti a výkonu budete moci dělat více věcí, které vás na počítači baví, a to bez jakékoli frustrace.",
                "tr_title": "Používá procesor 9.&nbsp;generace Intel® Core™ i7",
                "tr_gen_core_title": "Procesor 9. generace Intel® Core™ i7"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "AŽ O 32&nbsp;% VÍCE SNÍMKŮ ZA SEKUNDU (FPS) při hraní, streamování a nahrávání v&nbsp;porovnání s&nbsp;3&nbsp;ROKY STARÝM POČÍTAČEM<sup>3</sup>",
                "tr_title": "Výkon ke sdílení nejlepších herních okamžiků",
                "tr_gen_core_title": "Procesor 9. generace Intel® Core™ i7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Špičkové herní tituly",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraní, streamování a nahrávání bez kompromisů",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nejlepší procesor 9. generace Intel® Core™ pro hraní her",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 4<br/>Počet vláken: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Toto elegantní zařízení bylo navrženo tak, aby bylo co nejefektivnější. Proto může značnou měrou ovlivnit způsob, jakým tvoříte, připojujete se a&nbsp;streamujete.",
                "tr_title": "Vyšší výkon kdekoli",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i7",
                "processorCompare": {
                  "tr_title": "NAJDĚTE IDEÁLNÍ ÚROVEŇ VÝKONU&nbsp;10.&nbsp;GENERACE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streamování filmů s&nbsp;rozlišením 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rychlé a&nbsp;spolehlivé připojení",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Úpravy fotografií a editace videa",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraní v&nbsp;rozlišení 1080p s&nbsp;plynulou frekvencí snímků",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ideální pro práci s&nbsp;velkými soubory a programy pro tvůrce kreativních děl",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Špičkový výkon posune všechno, co děláte, na vyšší úroveň.",
                "tr_title": "Dost výkonu na všechno",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i7"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tento notebook je určen zvláště těm, kteří jsou stále na cestách.",
                "tr_title": "Představujeme novou třídu notebooků",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i7"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hrajte, streamujte a&nbsp;nahrávejte s&nbsp;náskokem před ostatními",
                "tr_title": "Posuňte svůj herní svět na další úroveň",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY 10.&nbsp;GENERACE INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Plynulé hraní nejnáročnějších her",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování s&nbsp;nahráváním",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tvorba náročného obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herní výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odemčené a připravené k&nbsp;přetaktování<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hrajte, streamujte a&nbsp;nahrávejte s&nbsp;náskokem před ostatními",
                "tr_title": "Posuňte svůj herní svět na další úroveň",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY 10.&nbsp;GENERACE INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Plynulé hraní nejnáročnějších her",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování s&nbsp;nahráváním",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tvorba náročného obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herní výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odemčené a připravené k&nbsp;přetaktování<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 10<br/>Počet vláken: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Toto elegantní zařízení bylo navrženo tak, aby bylo co nejefektivnější. Proto může značnou měrou ovlivnit způsob, jakým tvoříte, připojujete se a streamujete.",
                "tr_title": "Prémiový výkon kdekoliv",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "PROCESOR 10. GEN. INTEL® CORE™ i7"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesor 11.&nbsp;generace Intel® Core™ i7 poskytuje ultrapřenosným počítačům funkce na vytváření náročného obsahu, plynulé hraní her a špičkovou zábavu.",
                "tr_title": "Výkon notebooků, který boří hranice",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i7",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Proměňte svůj stolní počítač v&nbsp;nástroj sloužící všem potřebám vaší domácnosti díky revolučnímu výkonu procesoru 11.&nbsp;generace Intel® Core™ i7.",
                "tr_title": "Pracujte. Studujte. Hrajte. Bez hranic.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i7",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesor 11.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7 poskytuje tenkým a lehkým notebookům výkon na tvorbu náročného obsahu, plynulé hraní her a špičkovou zábavu.",
                "tr_title": "Výkon notebooků, který boří hranice",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i7"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Proměňte svůj stolní počítač v&nbsp;nástroj sloužící všem potřebám vaší domácnosti díky revolučnímu výkonu procesoru 11.&nbsp;generace Intel® Core™ i7.",
                "tr_title": "Tvorba bez hranic",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i7"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tento procesor 11.&nbsp;generace Intel® Core™ i7, který poskytuje tenkým a lehkým notebookům výkon na tvorbu náročného obsahu, plynulé hraní her a špičkovou zábavu, je nyní ještě lepší.",
                "tr_title": "Výkon notebooků, který boří hranice",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i7"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Používá procesor 8.&nbsp;generace Intel® Core™ i9",
                "tr_gen_core_title": "Procesor 8. generace Intel® Core™ i9"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesory 9. generace Intel® Core™ byly vytvořeny proto, aby vám pomohly držet krok s&nbsp;digitálním světem. Díky kombinaci jejich rychlosti a výkonu budete moci dělat více věcí, které vás na počítači baví, a to bez jakékoli frustrace.",
                "tr_title": "Používá procesor 9.&nbsp;generace Intel® Core™ i9",
                "tr_gen_core_title": "Procesor 9. generace Intel® Core™ i9"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "AŽ O 41&nbsp;% VÍCE SNÍMKŮ ZA SEKUNDU (FPS) při hraní, streamování a nahrávání v&nbsp;porovnání s&nbsp;3&nbsp;ROKY STARÝM POČÍTAČEM<sup>4</sup>",
                "tr_title": "Hráči žádají, procesory Intel® Core™ i9 umožňují.",
                "tr_gen_core_title": "Procesor 9. generace Intel® Core™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Špičkové herní tituly",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraní, streamování a nahrávání bez kompromisů",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nejlepší procesor 9. generace Intel® Core™ pro hraní her",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 4<br/>Počet vláken: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Využijte svůj potenciál a&nbsp;hrajte opravdu naplno",
                "tr_title": "Soupeřte na nejvyšších úrovních",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY 10.&nbsp;GENERACE INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Plynulé hraní nejnáročnějších her",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování s&nbsp;nahráváním",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tvorba náročného obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herní výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odemčené a připravené k&nbsp;přetaktování<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Využijte svůj potenciál a&nbsp;hrajte opravdu naplno",
                "tr_title": "Soupeřte na nejvyšších úrovních",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY 10.&nbsp;GENERACE INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Plynulé hraní nejnáročnějších her",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování s&nbsp;nahráváním",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tvorba náročného obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herní výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odemčené a připravené k&nbsp;přetaktování<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 10<br/>Počet vláken: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Využijte svůj potenciál a&nbsp;vytvářejte obsah opravdu naplno",
                "tr_title": "Tvořte na nejvyšší úrovni",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i9",
                "tr_gen_core_title_uppercase": "PROCESOR 10. GEN. INTEL® CORE™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Notebooky Intel® Evo™ používající procesor 11.&nbsp;generace Intel® Core™ i5 a grafikou Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> vám poskytnou neuvěřitelnou rychlost a výkon, a to vše v&nbsp;tenkém a lehkém provedení.",
                "tr_title": "Zvýšený výkon pro pokročilý notebook",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i5",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i5",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Notebooky Intel® Evo™ používající procesor 11.&nbsp;generace Intel® Core™ i7 a grafikou Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> vám poskytují pokročilý výkon a rychlost pro špičkové mobilní možnosti.",
                "tr_title": "Pokročilý výkon pro pokročilý notebook",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i7",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vychutnejte si skvěle vyvážené možnosti výkonu, multimédií a připojení za úžasnou cenu.",
                "tr_title": "Výkon a připojení za úžasnou cenu.",
                "tr_gen_core_title": "Procesor Intel® Pentium® Silver",
                "processorCompare": {
                  "tr_title": "NAJDĚTE SPRÁVNOU ÚROVEŇ VÝKONU A&nbsp;UŽITNÉ&nbsp;HODNOTY",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rychlé prohlížení webových stránek bohatých na multimediální obsah",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snadné přepínání mezi programy",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraní běžných her",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování videa v&nbsp;rozlišení 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nenáročné úpravy fotografií a videa",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rychlá produktivita díky inteligentnímu výkonu",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Užívejte si plynulé zpracování více úloh najednou, jednoduché úpravy fotografií a videa, videa v&nbsp;rozlišení 4K a živou grafiku za neuvěřitelnou cenu.",
                "tr_title": "Působivý výkon pro práci i&nbsp;zábavu.",
                "tr_gen_core_title": "Procesor Intel® Pentium® Gold",
                "processorCompare": {
                  "tr_title": "NAJDĚTE SPRÁVNOU ÚROVEŇ VÝKONU A&nbsp;UŽITNÉ&nbsp;HODNOTY",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rychlé prohlížení webových stránek bohatých na multimediální obsah",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snadné přepínání mezi programy",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraní běžných her",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování videa v&nbsp;rozlišení 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nenáročné úpravy fotografií a videa",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rychlá produktivita díky inteligentnímu výkonu",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Ultrapřenosný počítač s&nbsp;dlouhou výdrží na baterii, umožňující hraní špičkových her. Není nutné dělat kompromisy ve způsobu hraní her.",
                "tr_title": "Není nutné dělat kompromisy.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i5",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "NAJDĚTE SVŮJ ULTRAPŘENOSNÝ HERNÍ PROCESOR INTEL® CORE™.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesor 11.&nbsp;generace Intel® Core™ i5-11300H"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesor 11.&nbsp;generace Intel® Core™ i7-11370H"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesor 11.&nbsp;generace Intel® Core™ i7-11375H Special Edition"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Maximální pracovní frekvence až <span class='style_badge'>4,4 GHz</span>",
                      "tr_td2_title": "Maximální pracovní frekvence až <span class='style_badge'>4,8 GHz</span>",
                      "tr_td3_title": "Maximální pracovní frekvence až <span class='style_badge'>5,0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 jádra/ 8&nbsp;vláken",
                      "tr_td2_title": "4 jádra/ 8&nbsp;vláken",
                      "tr_td3_title": "4 jádra/ 8&nbsp;vláken"
                    },
                    {
                      "tr_td1_title": "Technologie Intel® Turbo Boost",
                      "tr_td2_title": "Technologie Intel® Turbo Boost",
                      "tr_td3_title": "Technologie Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Vyrovnávací paměť 8 MB Intel® Smart Cache",
                      "tr_td2_title": "Vyrovnávací paměť 12 MB Intel® Smart Cache",
                      "tr_td3_title": "Vyrovnávací paměť 12 MB Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Ultrapřenosný počítač s&nbsp;vynikajícím výkonem bez připojení k&nbsp;napájení, umožňující hraní špičkových herních titulů v&nbsp;rozlišení 1080p s&nbsp;vysokým počtem snímků za sekundu při nastavení na vysokou kvalitu.",
                "tr_title": "Jen do toho. Získejte všechno.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i7",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "NAJDĚTE SVŮJ ULTRAPŘENOSNÝ HERNÍ PROCESOR INTEL® CORE™.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesor 11.&nbsp;generace Intel® Core™ i5-11300H"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesor 11.&nbsp;generace Intel® Core™ i7-11370H"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesor 11.&nbsp;generace Intel® Core™ i7-11375H Special Edition"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Maximální pracovní frekvence až <span class='style_badge'>4,4 GHz</span>",
                      "tr_td2_title": "Maximální pracovní frekvence až <span class='style_badge'>4,8 GHz</span>",
                      "tr_td3_title": "Maximální pracovní frekvence až <span class='style_badge'>5,0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 jádra/ 8&nbsp;vláken",
                      "tr_td2_title": "4 jádra/ 8&nbsp;vláken",
                      "tr_td3_title": "4 jádra/ 8&nbsp;vláken"
                    },
                    {
                      "tr_td1_title": "Technologie Intel® Turbo Boost",
                      "tr_td2_title": "Technologie Intel® Turbo Boost",
                      "tr_td3_title": "Technologie Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Vyrovnávací paměť 8 MB Intel® Smart Cache",
                      "tr_td2_title": "Vyrovnávací paměť 12 MB Intel® Smart Cache",
                      "tr_td3_title": "Vyrovnávací paměť 12 MB Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Maximální pracovní frekvence až 5,0 GHz, která posune vaše hry ještě dál, a ultrapřenosný počítač, abyste si je mohli vzít kamkoli.",
                "tr_title": "Vyšší výkon pod kapotou.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "NAJDĚTE SVŮJ ULTRAPŘENOSNÝ HERNÍ PROCESOR INTEL® CORE™.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesor 11.&nbsp;generace Intel® Core™ i5-11300H"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesor 11.&nbsp;generace Intel® Core™ i7-11370H"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesor 11.&nbsp;generace Intel® Core™ i7-11375H Special Edition"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Maximální pracovní frekvence až <span class='style_badge'>4,4 GHz</span>",
                      "tr_td2_title": "Maximální pracovní frekvence až <span class='style_badge'>4,8 GHz</span>",
                      "tr_td3_title": "Maximální pracovní frekvence až <span class='style_badge'>5,0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 jádra/ 8&nbsp;vláken",
                      "tr_td2_title": "4 jádra/ 8&nbsp;vláken",
                      "tr_td3_title": "4 jádra/ 8&nbsp;vláken"
                    },
                    {
                      "tr_td1_title": "Technologie Intel® Turbo Boost",
                      "tr_td2_title": "Technologie Intel® Turbo Boost",
                      "tr_td3_title": "Technologie Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Vyrovnávací paměť 8 MB Intel® Smart Cache",
                      "tr_td2_title": "Vyrovnávací paměť 12 MB Intel® Smart Cache",
                      "tr_td3_title": "Vyrovnávací paměť 12 MB Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesor 11. generace Intel® Core™ i7 Special Edition poskytuje ultrapřenosným počítačům funkce na vytváření náročného obsahu, plynulé hraní her a špičkovou zábavu.",
                "tr_title": "Výkon notebooků, který boří hranice",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i7 SPECIAL EDITION"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesor 11. generace Intel® Core™ i7 Special Edition poskytuje ultrapřenosným počítačům funkce na vytváření náročného obsahu, plynulé hraní her a špičkovou zábavu.",
                "tr_title": "Výkon notebooků, který boří hranice",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i7 SPECIAL EDITION",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Vysoké pracovní frekvence a vysoké počty jader hladce spolupracují, abyste vytěžili víc ze soutěžního hraní a vytváření náročného obsahu.",
                "tr_title": "Obrovský výkon. V&nbsp;dokonalé rovnováze.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i9",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Díky správné rovnováze mezi pracovní frekvencí a výkonem jádra získáte ze svých her víc, ať už pro soutěžní hraní s&nbsp;vysokým počtem snímků za sekundu nebo pro působivé zážitky ze špičkových her.",
                "tr_title": "Výkon, který má svůj účel.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i5",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hrajte, nahrávejte a streamujte špičkové herní tituly s&nbsp;vysokým počtem snímků za sekundu a bez problémů přepínejte na současné zpracování náročných úloh.",
                "tr_title": "Výkon, který posouvá hranice.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i7",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké pracovní frekvence a vysoké počty jader hladce spolupracují, abyste vytěžili víc ze soutěžního hraní a vytváření náročného obsahu.",
                "tr_title": "Obrovský výkon. V&nbsp;dokonalé rovnováze.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i9",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Díky výkonové rezervě snadno přejdete od streamování filmů k&nbsp;vytváření&nbsp;prezentací.",
                "tr_title": "Energie na celý den",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 s&nbsp;technologií Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Procesor Intel®&nbsp;Core™&nbsp;i3 s&nbsp;technologií Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Všestranný výkon je spojen s&nbsp;dlouhou výdrží na baterii, díky níž můžete být stále v&nbsp;pohybu. Kdekoli.",
                "tr_title": "Energie na celý den",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 s&nbsp;technologií Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Procesor Intel®&nbsp;Core™&nbsp;i3 s&nbsp;technologií Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Můžete snadno přepínat mezi několika programy a&nbsp;být tak připraveni na vše, co vám den přinese.",
                "tr_title": "Zpracování více úloh najednou na cestách",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 s&nbsp;technologií Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Procesor Intel®&nbsp;Core™&nbsp;i5 s&nbsp;technologií Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Zlepšit se dá všechno, čemu se věnujete&nbsp;– ať už je to zábava nebo vytváření obsahu.",
                "tr_title": "Upgradujte na výkonnější počítač",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 s&nbsp;technologií Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Procesor Intel®&nbsp;Core™&nbsp;i5 s&nbsp;technologií Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Herní výkon srovnatelný se stolním počítačem a mobilita, ať už pro soutěžní hraní s&nbsp;vysokými FPS nebo působivé zážitky ze špičkových her.",
                "tr_title": "Výkon, který má svůj účel.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i5",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Herní výkon srovnatelný se stolním počítačem a mobilita, ať už pro soutěžní hraní s&nbsp;vysokými FPS nebo působivé zážitky ze špičkových her.",
                "tr_title": "Výkon, který má svůj účel.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i5",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i5",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Notebook, který umožňuje hrát, nahrávat a streamovat současně s&nbsp;vysokými FPS a také snadno zpracovávat více náročných úloh najednou.",
                "tr_title": "Výkon, který posouvá hranice.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i7",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Notebook, který umožňuje hrát, nahrávat a streamovat současně s&nbsp;vysokými FPS a také snadno zpracovávat více náročných úloh najednou.",
                "tr_title": "Výkon, který posouvá hranice.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i7",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké pracovní frekvence a vysoké počty jader bezproblémově spolupracují tak, abyste vytěžili víc ze soutěžního hraní a vytváření náročného obsahu, ať už si svůj počítač vezmete kamkoliv.",
                "tr_title": "Obrovský výkon. V&nbsp;dokonalé rovnováze.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i9",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké pracovní frekvence a vysoké počty jader bezproblémově spolupracují tak, abyste vytěžili víc ze soutěžního hraní a vytváření náročného obsahu, ať už si svůj počítač vezmete kamkoliv.",
                "tr_title": "Obrovský výkon. V&nbsp;dokonalé rovnováze.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i9",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i9",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké pracovní frekvence, vysoké počty jader a výkon odemčeného procesoru bezproblémově spolupracují tak, abyste vytěžili víc ze soutěžního hraní a vytváření náročného obsahu, ať už si svůj počítač vezmete kamkoliv.",
                "tr_title": "Odemčený výkon. V&nbsp;dokonalé rovnováze.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i9",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké pracovní frekvence, vysoké počty jader a výkon odemčeného procesoru bezproblémově spolupracují tak, abyste vytěžili víc ze soutěžního hraní a vytváření náročného obsahu, ať už si svůj počítač vezmete kamkoliv.",
                "tr_title": "Odemčený výkon. V&nbsp;dokonalé rovnováze.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i9",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i9",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké pracovní frekvence a vysoké počty jader hladce spolupracují, abyste vytěžili víc ze soutěžního hraní a vytváření náročného obsahu.",
                "tr_title": "Obrovský výkon. V&nbsp;dokonalé rovnováze.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i9",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i9",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké pracovní frekvence a vysoké počty jader hladce spolupracují, abyste vytěžili víc z&nbsp;vytváření náročného obsahu, ať si svůj počítač vezmete kamkoliv.",
                "tr_title": "Obrovský výkon. V&nbsp;dokonalé rovnováze.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i9",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké pracovní frekvence a vysoké počty jader hladce spolupracují, abyste vytěžili víc z&nbsp;vytváření náročného obsahu.",
                "tr_title": "Obrovský výkon. V&nbsp;dokonalé rovnováze.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i9",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Využijte svůj potenciál a&nbsp;hrajte opravdu naplno",
                "tr_title": "Soupeřte na nejvyšších úrovních",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY 10.&nbsp;GENERACE INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Plynulé hraní nejnáročnějších her",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování s&nbsp;nahráváním",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tvorba náročného obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herní výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odemčené a připravené k&nbsp;přetaktování<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Využijte svůj potenciál a&nbsp;hrajte opravdu naplno",
                "tr_title": "Soupeřte na nejvyšších úrovních",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY 10.&nbsp;GENERACE INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Plynulé hraní nejnáročnějších her",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamování s&nbsp;nahráváním",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tvorba náročného obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herní výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odemčené a připravené k&nbsp;přetaktování<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 10<br/>Počet vláken: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tento notebook je určen zvláště těm, kteří jsou stále na cestách.",
                "tr_title": "Představujeme novou třídu notebooků",
                "tr_gen_core_title": "Procesor 10.&nbsp;generace Intel® Core™ i9"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké pracovní frekvence a vysoké počty jader hladce spolupracují, abyste vytěžili víc ze soutěžního hraní a vytváření náročného obsahu.",
                "tr_title": "Odemčený výkon. V&nbsp;dokonalé rovnováze.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i9",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i9",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké pracovní frekvence, vysoké počty jader a výkon odemčeného procesoru hladce spolupracují, abyste vytěžili víc z&nbsp;vytváření náročného obsahu, ať si svůj počítač vezmete kamkoliv.",
                "tr_title": "Odemčený výkon. V&nbsp;dokonalé rovnováze.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i9",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké pracovní frekvence a vysoké počty jader hladce spolupracují, abyste vytěžili víc z&nbsp;vytváření náročného obsahu.",
                "tr_title": "Odemčený výkon. V&nbsp;dokonalé rovnováze.",
                "tr_gen_core_title": "Procesor 11.&nbsp;generace Intel® Core™ i9",
                "tr_gen_core_title_uppercase": "PROCESOR 11. GEN. INTEL® CORE™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Plynulé hraní a virtuální realita i&nbsp;u&nbsp;nejnáročnějších titulů",
                "tr_title": "Opravdové hraní začíná zde",
                "tr_gen_core_title": "Procesor 9. generace Intel® Core™ i5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Špičkové herní tituly",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraní, streamování a nahrávání bez kompromisů",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nejlepší procesor 9. generace Intel® Core™ pro hraní her",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 4<br/>Počet vláken: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "AŽ O 32&nbsp;% VÍCE SNÍMKŮ ZA SEKUNDU <br/><span class='tr_subtitle_font'>při hraní, streamování a&nbsp;nahrávání v&nbsp;porovnání s&nbsp;3&nbsp;roky starým počítačem<sup>3</sup></span>",
                "tr_title": "Výkon ke sdílení nejlepších herních okamžiků",
                "tr_gen_core_title": "Procesor 9. generace Intel® Core™ i7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Špičkové herní tituly",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraní, streamování a nahrávání bez kompromisů",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nejlepší procesor 9. generace Intel® Core™ pro hraní her",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 4<br/>Počet vláken: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "AŽ O 41&nbsp;% VÍCE SNÍMKŮ ZA SEKUNDU <br/><span class='tr_subtitle_font'>při hraní, streamování a&nbsp;nahrávání v&nbsp;porovnání s&nbsp;3&nbsp;roky starým počítačem<sup>4</sup></span>",
                "tr_title": "Hráči žádají, procesory Intel® Core™ i9 umožňují.",
                "tr_gen_core_title": "Procesor 9. generace Intel® Core™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet vláken:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Počet jader:</span><span class='badgetitle_font'><br/>[XX]</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>paměti cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Odemčený",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "HRAJTE PODLE VLASTNÍCH PRAVIDEL S&nbsp;PROCESORY INTEL® CORE™",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Špičkové herní tituly",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Soutěžní hraní",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hraní, streamování a nahrávání bez kompromisů",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nejlepší procesor 9. generace Intel® Core™ pro hraní her",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 4<br/>Počet vláken: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 6<br/>Počet vláken: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Pracovní frekvence<sup>2</sup>: až 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Počet jader: 8<br/>Počet vláken: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Stvořeno pro gaming další generace",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i5"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i7"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i9"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet jader: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet vláken: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Herní sestava i pracovní stanice",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i7"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i9"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet jader: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet vláken: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Spolehlivý výkon, když ho potřebujete nejvíc",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel® Core™ i3",
                "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i3",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Zvýšení výkonu tam, kde je potřeba nejvíc",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Zvýšení výkonu tam, kde je potřeba nejvíc",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Špičkový výkon tam, kde ho potřebujete nejvíc",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Špičkový výkon tam, kde ho potřebujete nejvíc",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "S&nbsp;notebooky Intel® Evo™ získáte výkon, vestavěnou inteligenci pro bezproblémové videohovory, rychlejší internet, dlouhou výdrž na baterii a další výhody.",
                "tr_title": "Zvýšení výkonu tam, kde ho potřebujete nejvíc.",
                "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i5",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "S&nbsp;notebooky Intel® Evo™ získáte výkon, vestavěnou inteligenci pro bezproblémové videohovory, rychlejší internet, dlouhou výdrž na baterii a další výhody.",
                "tr_title": "Špičkový výkon tam, kde ho potřebujete nejvíc",
                "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i7",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "S&nbsp;notebooky Intel® Evo™ získáte výkon, vestavěnou inteligenci pro bezproblémové videohovory, rychlejší internet, dlouhou výdrž na baterii a další výhody.",
                "tr_title": "Extrémní výkon v&nbsp;prémiovém notebooku",
                "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i9",
                "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i9",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "S&nbsp;technologií Intel® Turbo Boost 2.0 a&nbsp;frekvencí až [max_turbo_frequency] GHz",
                "tr_title": "Navržen pro podnikání",
                "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i5",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Potlačení hluku a rozmazání pozadí",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatické přizpůsobení výkonu tak, aby upřednostňoval nejčastěji prováděné úlohy",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Zážitek z&nbsp;plynulých, vysoce kvalitních webových hovorů",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Získání vestavěného hardwarového zabezpečení",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Poskytování možností vzdálené správy zařízení",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatická optimalizace výkonu Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Poskytování výkonu aplikacím, které zpracovávají data",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Největší produktivita se zvýšeným výkonem",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "S&nbsp;technologií Intel® Turbo Boost 2.0 a&nbsp;frekvencí až [max_turbo_frequency] GHz",
                "tr_title": "Neúnavně pracuje, abyste se mohli soustředit na své podnikání.",
                "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i7",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Potlačení hluku a rozmazání pozadí",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatické přizpůsobení výkonu tak, aby upřednostňoval nejčastěji prováděné úlohy",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Zážitek z&nbsp;plynulých, vysoce kvalitních webových hovorů",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Získání vestavěného hardwarového zabezpečení",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Poskytování možností vzdálené správy zařízení",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatická optimalizace výkonu Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Poskytování výkonu aplikacím, které zpracovávají data",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Největší produktivita se zvýšeným výkonem",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "S&nbsp;technologií Intel® Turbo Boost 2.0 a&nbsp;frekvencí až [max_turbo_frequency] GHz",
                "tr_title": "Zrychlete své podnikání",
                "tr_gen_core_title": "Procesor 12.&nbsp;generace Intel®&nbsp;Core™&nbsp;i9",
                "tr_gen_core_title_uppercase": "PROCESOR 12. GENERACE INTEL® CORE™ i9",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Potlačení hluku a rozmazání pozadí",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatické přizpůsobení výkonu tak, aby upřednostňoval nejčastěji prováděné úlohy",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Zážitek z&nbsp;plynulých, vysoce kvalitních webových hovorů",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Získání vestavěného hardwarového zabezpečení",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Poskytování možností vzdálené správy zařízení",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatická optimalizace výkonu Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Poskytování výkonu aplikacím, které zpracovávají data",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Největší produktivita se zvýšeným výkonem",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Spolehlivý výkon určený k tomu zvládnout více",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel® Core™ i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Reálný výkon určený k tomu zvládnout více",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Reálný výkon určený k tomu zvládnout více",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Vysoký výkon určený k tomu zvládnout více",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Vysoký výkon určený k tomu zvládnout více",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Výkon bez hranic",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i9",
                "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet jader: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet vláken: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Plynule se připojí k vašemu stávajícímu telefonu<sup>24</sup>, inteligentně zvolí nejlepší Wi-Fi, rychle se nabije a baterie vydrží déle.",
                "tr_title": "Výkon bez hranic, se kterým toho stihnete víc a odkudkoli",
                "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i5",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Plynule se připojí k vašemu stávajícímu telefonu<sup>24</sup>, inteligentně zvolí nejlepší Wi-Fi, rychle se nabije a baterie vydrží déle.",
                "tr_title": "Výkon bez hranic umožní vašemu počítači zvládnout ještě více věcí najednou",
                "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i7",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Plynule se připojí k vašemu stávajícímu telefonu<sup>24</sup>, inteligentně zvolí nejlepší Wi-Fi, rychle se nabije a baterie vydrží déle.",
                "tr_title": "Výkon bez hranic, se kterým rychle stihnete i ty nejnáročnější úlohy",
                "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i9",
                "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i9",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Výkon bez hranic",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet jader: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet vláken: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Výkon bez hranic",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet jader: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet vláken: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Spolehlivý výkon určený k tomu zvládnout více",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesor Intel® Core™ i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Reálný výkon určený k tomu zvládnout více",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Vysoký výkon určený k tomu zvládnout více",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Výkon bez hranic",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet jader: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet vláken: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Spolehlivý výkon určený k tomu zvládnout více",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Reálný výkon určený k tomu zvládnout více",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;5",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Vysoký výkon určený k tomu zvládnout více",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;7",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL®&nbsp;CORE™&nbsp;7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Hrajte bez kompromisů",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;5",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;7",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;9",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet jader: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'><span class='core-text'>Počet vláken: </span>[XX]</span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] na LPE-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Hrajte bez kompromisů",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Hrajte bez kompromisů",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Hrajte bez kompromisů",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Hrajte bez kompromisů",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Hrajte bez kompromisů",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Hrajte bez kompromisů",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Malé firmy si zaslouží velká obchodní řešení.",
                "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i5",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Potlačení hluku a rozmazání pozadí",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatické přizpůsobení výkonu tak, aby upřednostňoval nejčastěji prováděné úlohy",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Zážitek z&nbsp;plynulých, vysoce kvalitních webových hovorů",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Hardwarové zabezpečení bez nutnosti dalšího vybavení a konfigurací",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Pomáhá chránit zařízení a data před fyzickými pokusy o manipulaci",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Malé firmy si zaslouží velká obchodní řešení.",
                "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i7",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Potlačení hluku a rozmazání pozadí",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatické přizpůsobení výkonu tak, aby upřednostňoval nejčastěji prováděné úlohy",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Zážitek z&nbsp;plynulých, vysoce kvalitních webových hovorů",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Hardwarové zabezpečení bez nutnosti dalšího vybavení a konfigurací",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Pomáhá chránit zařízení a data před fyzickými pokusy o manipulaci",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Malé firmy si zaslouží velká obchodní řešení.",
                "tr_gen_core_title": "Procesor 13.&nbsp;generace Intel®&nbsp;Core™&nbsp;i9",
                "tr_gen_core_title_uppercase": "PROCESOR 13. GENERACE INTEL® CORE™ i9",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "JAKÝ JE TENTO PROCESOR VE SROVNÁNÍ S OSTATNÍMI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Stávající zařízení",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Potlačení hluku a rozmazání pozadí",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatické přizpůsobení výkonu tak, aby upřednostňoval nejčastěji prováděné úlohy",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Zážitek z&nbsp;plynulých, vysoce kvalitních webových hovorů",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Hardwarové zabezpečení bez nutnosti dalšího vybavení a konfigurací",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Pomáhá chránit zařízení a data před fyzickými pokusy o manipulaci",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Odemkněte nové zážitky s AI pro podnikání",
                "tr_title": "Díky procesorům Intel® Core™ Ultra&nbsp;[XX] a platformě Intel vPro® budete připraveni na to, co přijde",
                "tr_gen_core_title": "Procesor Intel® Core™ Ultra [XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "POSUŇTE SVÉ PODNIKÁNÍ NA NOVOU ÚROVEŇ S POČÍTAČI S UMĚLOU INTELIGENCÍ OD INTELU",
                    "tr_badgeSubTitle": "Výkonné počítače, na kterých všechno běží rychle a hladce, tvoří základ podnikatelské produktivity. Platforma Intel vPro® zvyšuje výkonnost zaměstnanců napříč různými úlohami a aplikacemi. Využívá totiž novou architekturu, která zajišťuje vyšší a energeticky efektivnější výkon v široké škále komplexních pracovních úloh tím, že posílá úlohy ke zpracování ve správný čas na správnou jednotku.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "VÍCEVRSTVÉ BEZPEČNOSTNÍ FUNKCE AKTIVNÍ UŽ V ZÁKLADNÍM NASTAVENÍ",
                    "tr_badgeSubTitle": "Nově se objevující hrozby vyžadují komplexní přístup k zabezpečení. Platforma Intel vPro® poskytuje ochranu na všech úrovních počínaje hardwarovou. Vaše podnikání je tak lépe chráněno. Tato jedinečná bezpečnostní opatření dostupná bez nutnosti další konfigurace a vybavení, včetně detekce hrozeb pomocí umělé inteligence, chrání vaše uživatele, vaše data i vaše podnikání.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "INTEGROVANÉ NÁSTROJE UMOŽŇUJÍ UŽIVATELŮM PRACOVAT PRAKTICKY ODKUDKOLIV",
                    "tr_badgeSubTitle": "Počítače – a tedy i kanceláře – jsou dnes přece všude. Platforma Intel vPro® nabízí moderní hardwarové možnosti správy a šetří tak podnikům práci s obhospodařováním firemních počítačů, zjednodušuje podporu a zvyšuje komfort koncového uživatele. A to všechno s důrazem na udržitelné používání výpočetní techniky.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Víc než skvělý počítač. Počítač&nbsp;s&nbsp;AI.",
                "tr_subtitle1": "POUŽÍVÁ PROCESORY INTEL® CORE™ ULTRA (SERIES 2)",
                "tr_subtitle2": "Je to možné – na počítači s AI od Intelu",
                "tr_subtitle": "Nejefektivnější, nejbezpečnější a nejvýkonnější procesor pro mobilní počítače od Intelu<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;5 (Series&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Kreativnější</span> díky nástrojům využívajícími umělou inteligenci k rychlejší tvorbě obsahu"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Produktivnější</span> díky pomoci od umělé inteligence šetřící čas"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Bezpečnější</span> díky vestavěné ochraně dat"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Víc než skvělý počítač. Počítač&nbsp;s&nbsp;AI.",
                "tr_subtitle1": "POUŽÍVÁ PROCESORY INTEL® CORE™ ULTRA (SERIES 2)",
                "tr_subtitle2": "Je to možné – na počítači s AI od Intelu",
                "tr_subtitle": "Nejefektivnější, nejbezpečnější a nejvýkonnější procesor pro mobilní počítače od Intelu<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;7 (Series&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Kreativnější</span> díky nástrojům využívajícími umělou inteligenci k rychlejší tvorbě obsahu"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Produktivnější</span> díky pomoci od umělé inteligence šetřící čas"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Bezpečnější</span> díky vestavěné ochraně dat"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Víc než skvělý počítač. Počítač&nbsp;s&nbsp;AI.",
                "tr_subtitle1": "POUŽÍVÁ PROCESORY INTEL® CORE™ ULTRA (SERIES 2)",
                "tr_subtitle2": "Je to možné – na počítači s AI od Intelu",
                "tr_subtitle": "Nejefektivnější, nejbezpečnější a nejvýkonnější procesor pro mobilní počítače od Intelu<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;5 (Series&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Víc než skvělý počítač. Počítač&nbsp;s&nbsp;AI.",
                "tr_subtitle1": "POUŽÍVÁ PROCESORY INTEL® CORE™ ULTRA (SERIES 2)",
                "tr_subtitle2": "Je to možné – na počítači s AI od Intelu",
                "tr_subtitle": "Nejefektivnější, nejbezpečnější a nejvýkonnější procesor pro mobilní počítače od Intelu<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;7 (Series&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_tileTitle1": "Dedikovaná grafika",
                "tr_title": "CO JE TO GRAFICKÝ PROCESOR?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Grafický procesor nebo také jednotka GPU vytváří obraz, který vidíte na obrazovce.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integrovaný grafický procesor",
                    "tr_badgeSubTitle": "Integrovaná grafika používá stejnou paměť RAM jako ostatní části počítače, díky čemuž je energeticky úspornější.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Samostatná grafika",
                    "tr_badgeSubTitle": "Toto je nejvýkonnější typ grafického procesoru, protože má vlastní paměť RAM a&nbsp;další prostředky, jejichž cílem je zajistit výjimečnou grafiku.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Hybridní grafický procesor",
                    "tr_badgeSubTitle": "Toto je nejvýkonnější typ grafického procesoru, protože má vlastní paměť RAM a&nbsp;další prostředky, jejichž cílem je zajistit výjimečnou grafiku.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Efektivnější úpravy fotografií a videa",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Sledování pořadů v rozlišení 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Hraní oblíbených her s úchvatnými detaily",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Dvě grafiky",
                    "tr_badgeSubTitle": "Některé systémy, jako například systémy s&nbsp;grafikou Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, mohou zpracovávat grafické úlohy pomocí procesorové i samostatné grafiky zároveň.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integrovaný grafický procesor",
                    "tr_badgeSubTitle": "Integrovaný grafický procesor (GPU) je součástí stejného čipu jako hlavní procesor, neboli CPU. Tento typ grafického procesoru používá stejnou paměť RAM i&nbsp;další prostředky jako ostatní části počítače, což zajišťuje větší energetickou úspornost a&nbsp;umožňuje vytvořit tenčí a&nbsp;lehčí zařízení.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Samostatný grafický procesor",
                    "tr_badgeSubTitle": "Toto je nejvýkonnější typ grafického procesoru, protože má vlastní paměť RAM a&nbsp;další prostředky, jejichž cílem je zajistit výjimečnou grafiku.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Standardní",
                    "tr_badgeSubTitle": "Až 4&nbsp;GB paměti VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Soutěžní",
                    "tr_badgeSubTitle": "Až 8&nbsp;GB paměti VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Více než 8&nbsp;GB paměti VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Intenzivní grafický výkon",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "K dosažení nejvyššího grafického výkonu je vyžadována samostatná grafická karta, která má jediný úkol, a to zajistit perfektně ostrý obraz a plynulé animace.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Integrovaná bohatá a&nbsp;živá grafika",
                "tr_pretitle": "Grafika Intel® Iris® Plus",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "S&nbsp;grafikou Intel® Iris® Plus vás naprosto pohltí videa 4K HDR a&nbsp;hraní v&nbsp;rozlišení Full HD, bez potřeby další grafické karty.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Ohromující obraz, plynulý chod",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Grafiky Intel® posouvají možnosti integrovaných grafik na novou úroveň a zajišťují hladký tvůrčí proces a bohaté zážitky při hraní her.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Grafika Intel® Iris® Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "UHD grafika Intel® s architekturou X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "Ohromující vizuální funkce",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Poznejte efektivnější úpravy fotografií a videa, sledujte prémiové pořady v rozlišení 4K nebo hrajte oblíbené hry s úchvatnými detaily.",
                "tr_photo_text": "Efektivnější úpravy fotografií a videa",
                "tr_video_text": "Sledování pořadů v rozlišení 4K",
                "tr_game_text": "Hraní oblíbených her s úchvatnými detaily",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MNOHEM VÍCE NEŽ JEN PĚKNÉ OBRÁZKY",
                "tr_subtitle": "Grafická karta (GPU) díky úzké spolupráci s&nbsp;procesorem (CPU) pomáhá určit, jak plynule poběží vaše hry a jak budou vypadat. Paměť VRAM, nebo také video RAM, vyhrazená pouze pro grafiku, je rychlý způsob, jak zjistit, jak si vaše grafická karta (GPU) stojí.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>PAMĚTI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "HERNÍ GRAFIKA PRO INTENZIVNÍ HRANÍ",
                "tr_subtitle": "Nejnáročnější grafické procesy vyžadují samostatnou grafickou kartu, jejímž jediným úkolem je vytvořit perfektně ostrý obraz a&nbsp;plynulé animace.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>PAMĚTI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "HERNÍ GRAFIKA PRO INTENZIVNÍ HRANÍ",
                "tr_subtitle": "Nejnáročnější grafické procesy vyžadují samostatnou grafickou kartu, jejímž jediným úkolem je vytvořit perfektně ostrý obraz a&nbsp;plynulé animace.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>PAMĚTI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MNOHEM VÍCE NEŽ JEN PĚKNÉ OBRÁZKY",
                "tr_subtitle": "Grafická karta (GPU) díky úzké spolupráci s&nbsp;procesorem (CPU) pomáhá určit, jak plynule poběží vaše hry a jak budou vypadat. Paměť VRAM, nebo také video RAM, vyhrazená pouze pro grafiku, je rychlý způsob, jak zjistit, jak si vaše grafická karta (GPU) stojí.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>PAMĚTI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Intenzivní grafický výkon",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "K dosažení nejvyššího grafického výkonu je vyžadována samostatná grafická karta, která má jediný úkol, a to zajistit perfektně ostrý obraz a plynulé animace.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "HERNÍ GRAFIKA PRO INTENZIVNÍ HRANÍ",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "K dosažení nejvyššího grafického výkonu je vyžadována samostatná grafická karta, která má jediný úkol, a to zajistit perfektně ostrý obraz a plynulé animace.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>PAMĚTI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Nejnáročnější grafické procesy vyžadují samostatnou grafickou kartu, jejímž jediným úkolem je vytvořit perfektně ostrý obraz a&nbsp;plynulé animace.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Zajišťuje dostatečný výkon grafiky, který splní vaše minimální požadavky na hraní her.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Pro náročnější hráče, kteří hrají na profesionální úrovni",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Pro profesionální hráče, kteří zároveň používají počítač k&nbsp;vytváření velkého objemu kreativních děl",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Živé video a realistické herní zážitky",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Využijte svou obrazovku na maximum s grafikou Intel® HD a bavte se s multimédii a vestavěnou grafikou.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Výkon pro živé video a&nbsp;obrázky",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Získejte detailnější streamované video a fotografie s&nbsp;bohatými barvami.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Úžasná tvorba obsahu i&nbsp;hraní her",
                "tr_pretitle": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> propůjčuje tenkým a&nbsp;lehkým počítačům revoluční grafický výkon pro pohlcující zábavu, vytváření náročného obsahu a hraní běžných her.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_pretitle": "HERNÍ GRAFIKA PRO INTENZIVNÍ HRANÍ",
                "tr_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Nejnáročnější grafické procesy vyžadují samostatnou grafickou kartu, jejímž jediným úkolem je vytvořit perfektně ostrý obraz a&nbsp;plynulé animace.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>PAMĚTI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Probuďte svoji kreativitu",
                "tr_pretitle": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Zvládejte hladce kreativní projekty a ponořte se do nepřeberné nabídky her díky kombinovanému výkonu procesorové grafiky a&nbsp;první samostatné grafiky společnosti Intel®.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "tr_pretitle": "HERNÍ GRAFIKA PRO INTENZIVNÍ HRANÍ",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Nejnáročnější grafické procesy vyžadují samostatnou grafickou kartu, jejímž jediným úkolem je vytvořit perfektně ostrý obraz a&nbsp;plynulé animace.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>PAMĚTI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Grafika",
                "tr_title": "Nádherná integrovaná grafika",
                "tr_subtitle": "Vychutnejte si své hry, filmy a webová videa v úžasných detailech a s čistým obrazem.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Zvyšte svůj pracovní výkon"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Zvyšte svůj herní výkon"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Zvyšte svůj herní výkon"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Zvyšte úroveň svého hraní"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "PROBUĎTE SVOJI KREATIVITU",
                    "tr_specText": "Zrychlete svou produktivitu, ať jste kdekoli, díky nejrozsáhlejším multimediálním funkcím, jako je <strong>vůbec první hardwarové kódování AV1</strong> a tvorba obsahu akcelerovaná umělou inteligencí.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "PROBUĎTE SVOJI KREATIVITU",
                    "tr_specText": "Zrychlete svou produktivitu díky nejrozsáhlejším multimediálním funkcím, jako je <strong>vůbec první hardwarové kódování AV1</strong> a tvorba obsahu akcelerovaná umělou inteligencí.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "PROBUĎTE SVOJI KREATIVITU",
                    "tr_specText": "Dopřejte si špičkovou tvorbu obsahu akcelerovanou umělou inteligencí díky <strong>prvnímu hardwarovému kódování AV1</strong> a pokročilému <strong>mediálnímu modulu X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "PROBUĎTE SVOJI KREATIVITU",
                    "tr_specText": "Dopřejte si špičkovou tvorbu obsahu akcelerovanou umělou inteligencí díky <strong>prvnímu hardwarovému kódování AV1</strong> a pokročilému <strong>mediálnímu modulu X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "PROBUĎTE SVOJI KREATIVITU",
                    "tr_specText": "Dopřejte si špičkovou tvorbu obsahu akcelerovanou umělou inteligencí díky <strong>prvnímu hardwarovému kódování AV1</strong> a pokročilému <strong>mediálnímu modulu X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "PŘENOSNOST VE SPOJENÍ S VÝKONEM",
                    "tr_specText": "Hrajte nejnovější hry na cestách a získejte mimořádný výkon a věrnost díky moderním možnostem hraní. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Technologie Intel® vylepšená umělou inteligencí přináší lepší výkon a plynulé hraní. <br/><br/>Podpora <strong>DirectX* 12 Ultimate:  </strong>Vyneste své hraní na vyšší úroveň realismu díky grafickým technologiím, jako je sledování paprsků (ray tracing) a technologie VRS (variable rate shading)."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "PŘEVEZMĚTE KONTROLU NAD SVÝM HRANÍM",
                    "tr_specText": "<strong>Řídicí centrum Intel® Arc™</strong> vám umožní vylepšit svůj zážitek díky intuitivnímu a modernímu rozložení.  Získejte přístup k aktualizacím ovladače, optimalizujte své hry a mnohem více."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "PŘEVEZMĚTE KONTROLU NAD SVÝM HRANÍM",
                    "tr_specText": "<strong>Řídicí centrum Intel® Arc™</strong> vám umožní vylepšit svůj zážitek díky intuitivnímu a modernímu rozložení.  Získejte přístup k aktualizacím ovladače, optimalizujte své hry a mnohem více."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "PŘEVEZMĚTE KONTROLU NAD SVÝM HRANÍM",
                    "tr_specText": "Získejte bezproblémové aktualizace ovladače a optimalizujte mnoho svých oblíbených her díky softwaru <strong>řídicího centra Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAXIMALIZUJTE SVOJI PLATFORMU INTEL®",
                    "tr_specText": "Díky spojení s kompatibilními procesory Intel® Core™ můžete využít <strong>technologii Intel® Deep Link</strong> a vylepšit své zážitky z tvorby obsahu, hraní her a streamování."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MAXIMALIZUJTE SVŮJ SYSTÉM INTEL®",
                    "tr_specText": "Díky spojení s kompatibilními procesory Intel® Core™ můžete využít <strong>technologii Intel® Deep Link</strong> a vylepšit své zážitky z tvorby obsahu, hraní her a streamování."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAXIMALIZUJTE SVOJI PLATFORMU INTEL®",
                    "tr_specText": "Díky spojení s kompatibilními procesory Intel® Core™ můžete využít <strong>technologii Intel® Deep Link</strong> a vylepšit své zážitky z tvorby obsahu, hraní her a streamování."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMALIZUJTE SVOJI PLATFORMU INTEL®",
                    "tr_specText": "Díky spojení s kompatibilními procesory Intel® Core™ můžete využít <strong>technologii Intel® Deep Link</strong> a vylepšit své zážitky z tvorby obsahu, hraní her a streamování."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMALIZUJTE SVŮJ SYSTÉM INTEL®",
                    "tr_specText": "Díky spojení s kompatibilními procesory Intel® Core™ můžete využít <strong>technologii Intel® Deep Link</strong> a vylepšit své zážitky z tvorby obsahu, hraní her a streamování."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "PŘESUŇTE SE DO CENTRA DĚNÍ",
                    "tr_specText": "Vysílejte bez problémů s pokročilou technologií streamování, kterou přináší software <strong>řídicího centra Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "PŘESUŇTE SE DO CENTRA DĚNÍ",
                    "tr_specText": "Vysílejte bez problémů s pokročilou technologií streamování, kterou přináší software <strong>řídicího centra Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "DOPŘEJTE SI REALISTICKÉ HRANÍ HER S VYSOKÝM VÝKONEM",
                    "tr_specText": "Nejlepší výkon je na prvním místě s nejnovějšími špičkovými herními tituly s vysokým rozlišením a vysokými obnovovacími frekvencemi. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Technologie Intel® vylepšená umělou inteligencí přináší lepší výkon a plynulé hraní. <br/><br/>Podpora <strong>DirectX* 12 Ultimate:  </strong>Vyneste své hraní na vyšší úroveň realismu díky grafickým technologiím, jako je sledování paprsků (ray tracing) a technologie VRS (variable rate shading)."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "DOPŘEJTE SI REALISTICKÉ HRANÍ HER S VYSOKÝM VÝKONEM",
                    "tr_specText": "Nejlepší výkon je na prvním místě s nejnovějšími špičkovými herními tituly s vysokým rozlišením a vysokými obnovovacími frekvencemi. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Technologie Intel® vylepšená umělou inteligencí přináší lepší výkon a plynulé hraní. <br/><br/>Podpora <strong>DirectX* 12 Ultimate:  </strong>Vyneste své hraní na vyšší úroveň realismu díky grafickým technologiím, jako je sledování paprsků (ray tracing) a technologie VRS (variable rate shading)."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "DEJTE SVÝM HRÁM NOVÝ IMPULS",
                    "tr_specText": "Vyneste svůj herní zážitek na vyšší úroveň s: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Technologie Intel® vylepšená umělou inteligencí přináší lepší výkon a plynulé hraní. <br/><br/>Podpora <strong>DirectX* 12 Ultimate:  </strong>Vyneste své hraní na vyšší úroveň realismu díky grafickým technologiím, jako je sledování paprsků (ray tracing) a technologie VRS (variable rate shading)."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "UŽIJTE SI POKROČILÉ A RYCHLÉ HRANÍ",
                    "tr_specText": "Vychutnejte si plynulé hraní mnoha špičkových herních titulů a vysokou obnovovací frekvenci od vysokooktanových esportových stříleček až po rychlou RTS. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Technologie Intel® vylepšená umělou inteligencí přináší lepší výkon a plynulé hraní. <br/><br/>Podpora <strong>DirectX* 12 Ultimate:  </strong>Vyneste své hraní na vyšší úroveň realismu díky grafickým technologiím, jako je sledování paprsků (ray tracing) a technologie VRS (variable rate shading)."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "DOPŘEJTE SI REALISTICKÉ HRANÍ HER S VYSOKÝM VÝKONEM",
                    "tr_specText": "Nejlepší výkon je na prvním místě s nejnovějšími špičkovými herními tituly s vysokým rozlišením a vysokými obnovovacími frekvencemi. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Technologie Intel® vylepšená umělou inteligencí přináší lepší výkon a plynulé hraní. <br/><br/>Podpora <strong>DirectX* 12 Ultimate:  </strong>Vyneste své hraní na vyšší úroveň realismu díky grafickým technologiím, jako je sledování paprsků (ray tracing) a technologie VRS (variable rate shading)."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Grafický vesmír, kde je místo pro každého"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "VÍCE SNÍMKŮ, RYCHLEJŠÍ HRA",
                    "tr_specText": "Technologie Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) zvyšuje rozlišení a využívá při tom umělou inteligenci. Vaše hraní se tak díky špičkovému výkonu a věrnému obrazu posune na úplně novou úroveň. Technologie X<sup class='small_text_sup_xe'>e</sup>SS je optimalizována pro grafiky Intel® Arc™, takže můžete naplno využít hardwarovou akceleraci Intel®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;Matrix&nbsp;Extension (Intel® XMX)."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "DOKONALÝ OBRAZ SE STÁVÁ REALITOU",
                    "tr_specText": "S plnou podporou DirectX* 12 Ultimate na všech grafikách Intel® Arc™ a také všech nejnovější grafických technologií, jako je ray tracing, VRS, mesh shading a sampler feedback - což jsou základní kameny moderního hraní, si užijete ohromující a pohlcující hraní her."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "SNADNÉ VYTVÁŘENÍ FOTEK, VIDEÍ A MULTIMEDIÁLNÍHO OBSAHU",
                    "tr_specText": "Dejte průchod svojí fantazii a zaujměte publikum bohatou digitální tvorbou pomocí hypermoderního mediálního enginu, vylepšenému umělou inteligencí a zrychlenému technologií Intel®. Vytvářejte působivý obsah s grafikou, která podporuje všechny současné rozšířené multimediální formáty a zároveň nabízí nejpokročilejší možnosti kódování videa ve formátu AV1."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "VYZKOUŠEJTE SI ŠPIČKOVOU TVORBU OBSAHU A HRANÍ HER S PODPOROU AI",
                    "tr_specText": "Grafiky Intel® Arc™ nabízejí obrovské možnosti využití umělé inteligence, od pokročilé tvorby obsahu až k hraní, které vás úplně pohltí. Využijte možnosti dnešního pokročilého softwaru pro tvorbu, včetně generování obrazu na základě textu a úprav videa urychlených pomocí AI, opravdu na maximum. A k tomu se ještě můžete těšit na hraní s vysokým rozlišením díky AI upscalingu technologie X<sup class='small_text_sup_xe'>e</sup>SS."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Vstupenka do světa profesionální grafiky"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "SKVĚLÁ PRO KREATIVCE, DESIGNÉRY A INŽENÝRY NA CESTÁCH",
                    "tr_specText": "Integrovaná grafika Intel® Arc™ Pro je k dispozici u vybraných procesorů Intel® Core™ Ultra H-série. Přichází s dvojnásobným navýšením grafického výkonu a certifikacemi od nezávislých dodavatelů softwaru (ISV), což zvyšuje spolehlivost a umožňuje efektivní hardwarovou akceleraci umělé inteligence a ray tracingu, takže i na cestách můžete tvořit dechberoucí obsah.<br/><br/><span class='expandable_heading'>Softwarové certifikace<br/></span><br/>Intel v průběhu let pracoval ruku v ruce se stovkami softwarových společností. A tato bezkonkurenční zkušenost se na našich grafických kartách Intel®&nbsp;Arc™&nbsp;Pro&nbsp;A&#8209;série naplno projevuje. Certifikace jsou pro nás stejně důležité jako pro vás."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "UMĚLÁ INTELIGENCE, KTEROU SI MŮŽETE DOVOLIT",
                    "tr_specText": "Tato řada grafik nabízí širokou škálu skvělých technologií včetně integrované AI akcelerace, která podporuje vaše kompatibilní nástroje umělé inteligence přesně ve chvílích, kdy to potřebujete."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING, ZA KTERÝ SI NEMUSÍTE PŘIPLÁCET",
                    "tr_specText": "Od grafické karty se očekává víc než jenom vykreslování pixelů na obrazovce. Musí také umět urychlovat tvorbu hezkých obrázků. Hardwarová technologie ray tracingu, která je součástí grafik Intel® Arc™ Pro A-série, vám u kompatibilního softwaru umožní objevovat nové profesionální pracovní postupy. U ray tracingu panuje přesvědčení, že je dostupný pouze na drahých grafikách, ale nejnovější řada profesionálních grafik od Intelu toto přesvědčení vyvrací."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "POHLCUJÍCÍ DISPLEJE, VĚTŠÍ MOŽNOSTI PRO TVŮRCE",
                    "tr_specText": "S plnou podporou DirectX* 12 Ultimate na všech grafikách Intel® Arc™ a také všech nejnovější grafických technologií, jako je ray tracing, VRS, mesh shading a sampler feedback - což jsou základní kameny moderního hraní, si užijete ohromující a pohlcující hraní her."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "DYNAMICKY OPTIMALIZOVANÉ OBRÁZKY",
                    "tr_specText": "Dedikované hardwarové dekódování na profesionálních grafických procesorech umožňuje Dolby Vision* dodat kompatibilnímu displeji větší hloubku, ostřejší kontrast a víc barev. Dolby Vision* naplno využívá potenciál technologie HDR. Dynamicky totiž optimalizuje kvalitu obrazu podle toho, jakou používáte službu, zobrazovací zařízení a platformu. Obraz je tak díky tomu vždy ohromující."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "ODBORNĚ OVĚŘENÝ SOFTWARE",
                    "tr_specText": "Intel v průběhu let pracoval ruku v ruce se stovkami softwarových společností. A tato bezkonkurenční zkušenost se na profesionálních grafikách řady Intel®&nbsp;Arc™&nbsp;Pro&nbsp;A&#8209;série naplno projevuje. Certifikace a kvalifikace pracovních postupů u softwaru jsou pro nás stejně důležité jako pro vás."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING, ZA KTERÝ SI NEMUSÍTE PŘIPLÁCET",
                    "tr_specText": "Od grafické karty se očekává víc než jenom vykreslování pixelů na obrazovce. Musí také umět urychlovat tvorbu hezkých obrázků. Hardwarová technologie ray tracingu, která je součástí grafik Intel® Arc™ Pro A-série, vám u kompatibilního softwaru umožní objevovat nové profesionální pracovní postupy. U ray tracingu panuje přesvědčení, že je dostupný pouze na drahých grafikách, ale nejnovější řada profesionálních grafik od Intelu toto přesvědčení vyvrací."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "JEDNA MALÁ GRAFICKÁ KARTA PRO NOTEBOOKY, NĚKOLIK VELKÝCH DISPLEJŮ",
                    "tr_specText": "Grafiky Intel® Arc™ Pro podporují až čtyři extra velké displeje a vy tak můžete pracovat ještě efektivněji. Když k tomu ještě přidáte podporu vysokého dynamického rozsahu (HDR), máte všechno, co potřebujete, abyste si doma nebo v kanceláři vytvořili pracoviště s několika displeji, kterým uděláte dojem i na sociálních sítích."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "ŠÍŘKA PÁSMA PRO PLNÉ VYUŽITÍ PAMĚTI",
                    "tr_specText": "U grafiky pro mobilní pracovní stanici je důležitým faktorem šířka pásma. Když ji netrefíte, zpomalí profesionální práci, a právě proto jsme se postarali, aby grafiky Intel® Arc™ Pro A-série podporovaly moderní standard PCIe* 4.0 a zároveň neopomíjely zpětnou kompatibilitu. Vysoká šířka pásma znamená vyšší přenosovou rychlost a umožňuje tak účelnější využití paměti grafické karty, protože software se rychleji dostane k datům projektu."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "VDECHNĚTE ŽIVOT KAŽDÉMU SNÍMKU",
                    "tr_specText": "<span class='expandable_heading'>Vylepšeno pomocí AI. Zaštítěno Intelem.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS je technologie zvyšující rozlišení pomocí umělé inteligence. Výsledkem je rychlejší snímková frekvence, která neubírá z krásy každého snímku, takže si hru užijete tak, jak to má být."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "REÁLNÝ ČAS.<br/> REÁLNÝ ZÁŽITEK.<br/> DOKONALÝ REALISMUS.",
                    "tr_specText": "Ještě realističtější světy na obzoru. Díky integrovanému ray tracingu vám bude všechno připadat jako živé, od stínů a odrazů až po dokonalé nasvícení. Jinak řečeno, takhle strhující obrazový zážitek z hraní si určitě nebudete chtít nechat ujít."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "DOKONALÁ HRA, DOKONALÉ SDÍLENÍ",
                    "tr_specText": "Dopřejte svému publiku herní video bez prodlev a sekání, protože tak to má být. Hardwarová akcelerace kódování AV1 u grafik Intel® Arc™ zajistí, že ostatní budou napjatě sledovat každý váš krok ve hře. Vyšší kvalita obrazu při stejné přenosové rychlosti znamená, že streamujete s nejvyšší možnou kvalitou a zároveň šetříte kapacitu připojení."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Vítáme všechny tvůrce",
                    "tr_specText": "Nové a zdokonalené možnosti umělé inteligence teď máte na dosah ruky. Díky pokročilým AI akcelerátorům zabudovaných v grafikách Intel® Arc™ A-série můžete strávit víc času tvorbou a méně čekáním na aplikace, než zpracují svoje úlohy.<br/><br/>Pozvedněte vytváření videí na novou úroveň a zařaďte se na špičku díky formátu AV1, který nabízí vysokou kvalitu videa v malém objemu dat a schopnost využít zabudované hardwarové podpory grafiky Intel® Arc™."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operační systém",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Vše, co potřebujete k tomu, abyste práci dokončili",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Vysoký výkon v tenkém, lehkém a trvanlivém těle – můžete mít vše. S velkou výdrží na baterii, krásnými dotykovými obrazovkami* a nejnovějšími disky SSD (Solid-State Drive), to vše najdete v počítači se systémem Windows 10.",
                "tr_noteSection": "*V závislosti na hardwaru.",
                "titleArray": [
                  {
                    "tr_title": "Méně je více",
                    "tr_subtitle": "Dnešní počítače obvykle spotřebovávají méně energie, takže vám baterie vydrží déle – v průměru osm a více hodin přehrávání videa.**",
                    "tr_noteSection": "**Výdrž baterie se výrazně proměňuje v závislosti na zařízení, nastavení, využití a dalších faktorech."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operační systém",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "VŠE, CO POTŘEBUJETE K TOMU, ABYSTE PRÁCI DOKONČILI",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Vysoký výkon v tenkém, lehkém a trvanlivém těle – můžete mít vše. S velkou výdrží na baterii, krásnými dotykovými obrazovkami* a nejnovějšími disky SSD (Solid-State Drive), to vše najdete v počítači se systémem Windows 10.",
                "tr_noteSection": "*V závislosti na hardwaru.",
                "titleArray": [
                  {
                    "tr_title": "MÉNĚ JE VÍCE",
                    "tr_subtitle": "Dnešní počítače obvykle spotřebovávají méně energie, takže vám baterie vydrží déle – v průměru osm a více hodin přehrávání videa.**",
                    "tr_noteSection": "**Výdrž baterie se výrazně proměňuje v závislosti na zařízení, nastavení, využití a dalších faktorech."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operační systém",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Optimalizováno pro bezpečnost a&nbsp;výkon",
                "tr_pretitle": "Windows* 10 v režimu S",
                "tr_subtitle": "Používáním systému Windows* 10 v režimu S získáte další vrstvu ochrany, která vám umožní pracovat, hrát a procházet web s jistotou, že je vaše zařízení bezpečné."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operační systém",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "OPTIMALIZOVÁNO PRO BEZPEČNOST A VÝKON",
                "tr_title": "Windows* 10 v režimu S",
                "tr_subtitle": "Používáním systému Windows* 10 v režimu S získáte další vrstvu ochrany, která vám umožní pracovat, hrát a procházet web s jistotou, že je vaše zařízení bezpečné."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operační systém",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Dělejte skvělé věci",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Výkon, cena a&nbsp;spousta možností na výběr. Od inovativních modelů 2v1 až po tenké a lehké notebooky, dotykové displeje a odpojitelné klávesnice – vhodný počítač se systémem Windows* si vyberete bez ohledu na váš rozpočet a oblíbený styl."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operační systém",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "DĚLEJTE SKVĚLÉ VĚCI",
                "tr_title": "Windows*",
                "tr_subtitle": "Výkon, cena a&nbsp;spousta možností na výběr. Od inovativních modelů 2v1 až po tenké a lehké notebooky, dotykové displeje a odpojitelné klávesnice – vhodný počítač se systémem Windows* si vyberete bez ohledu na váš rozpočet a oblíbený styl."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operační systém",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operační systém",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Paměť",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "KOLIK PAMĚTI RAM POTŘEBUJETE?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Každodenní potřeby – 2 GB AŽ 8 GB PAMĚTI RAM",
                    "tr_badgeSubTitle": "Ideální pro uživatele, kteří používají počítač zejména pro zpracování textu a&nbsp;surfování po webu a&nbsp;obvykle využívají v&nbsp;jednu chvíli pouze jeden program."
                  },
                  {
                    "tr_badgeTitle": "Výkonný počítač – 8 GB AŽ 16 GB PAMĚTI RAM",
                    "tr_badgeSubTitle": "S&nbsp;větší pamětí RAM běží aplikace rychleji, a&nbsp;navíc můžete současně spouštět více aplikací."
                  },
                  {
                    "tr_badgeTitle": "Pokročilé potřeby – VÍCE NEŽ 16&nbsp;GB PAMĚTI RAM",
                    "tr_badgeSubTitle": "Chcete-li hrát hry, upravovat fotografie a&nbsp;videa nebo modelovat třírozměrné scény, budete ke zvládnutí náročných programů potřebovat více paměti RAM."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMĚŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX]&nbsp;GB paměti RAM pro každodenní práci na více úlohách najednou",
                "tr_subtitle": "Paměť v&nbsp;tomto zařízení umožňuje plynulé načítání a&nbsp;spouštění základních aplikací, jako jsou aplikace pro zpracování textu a&nbsp;prohlížení webových stránek.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>PAMĚTI RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMĚŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX]&nbsp;GB paměti RAM pro práci na více náročných úlohách najednou",
                "tr_subtitle": "Pokud používáte víc programů současně, [memory_total]&nbsp;GB paměti vám umožní mezi nimi snadno přepínat.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>PAMĚTI RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMĚŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX]&nbsp;GB paměti RAM pro pokročilou práci na více úlohách najednou",
                "tr_subtitle": "Budete mít spoustu paměti ke spouštění několika náročných programů současně, aniž byste svůj počítač zpomalili.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>PAMĚTI RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMĚŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1]&nbsp;GB paměti = [Value2]&nbsp;GB paměti Intel®&nbsp;Optane™ + [Value3]&nbsp;GB paměti RAM",
                "tr_subtitle": "Tato kombinace vám díky paměti Intel® Optane™ zrychlí pevný disk, zatímco rychlost reakcí bude ještě posílena pamětí RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>PAMĚTI</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Paměť Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Méně čekání. Víc toho, co máte rádi.",
                "tr_subtitle": "Když paměť Intel® Optane™ spolupracuje se systémovou pamětí, dává vám rychlejší přístup k&nbsp;často používaným programům, abyste je mohli spustit a načíst, jen na ně kliknete.",
                "tr_pretitle": "[XX] GB paměti Intel® Optane™",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "K&nbsp;ČEMU JE V&nbsp;MÉM POČÍTAČI PAMĚŤ?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Rychlejší tvorbu obsahu",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Hraní s&nbsp;rychlejšími reakcemi",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Snadnější zpracování více úloh najednou",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Rychlejší spouštění programů",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Paměť Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Zrychlete své hraní</span>",
                "tr_pretitle": "[XX] GB paměti Intel® Optane™",
                "tr_pretitle1": "[XX]&nbsp;GB paměti Intel®&nbsp;Optane™ zrychluje vaše&nbsp;hry",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Až o<br/><span class=\"optane-game\">98&nbsp;% rychlejší</span><br/>spouštění her z&nbsp;datového disku s&nbsp;procesorem [optane_legal_processor_name]<br/> a pamětí Intel® Optane™.<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Až<br/><span class=\"optane-game\">4,1× rychlejší</span><br/>načítání herních úrovní z&nbsp;datového disku s&nbsp;procesorem [optane_legal_processor_name]<br/> a&nbsp;pamětí Intel® Optane™.<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Paměť Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Zrychlete své hraní</span>",
                "tr_pretitle": "[XX] GB paměti Intel® Optane™",
                "tr_pretitle1": "[XX] GB paměti Intel® Optane™ zrychluje vaše hry",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Až o<br/><span class=\"optane-game\">98&nbsp;% rychlejší</span><br/>spouštění her z&nbsp;datového disku s&nbsp;procesorem [optane_legal_processor_name]<br/> a pamětí Intel® Optane™.<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Až<br/><span class=\"optane-game\">4,1× rychlejší</span><br/>načítání herních úrovní z&nbsp;datového disku s&nbsp;procesorem [optane_legal_processor_name]<br/> a&nbsp;pamětí Intel® Optane™.<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMĚŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1]&nbsp;GB paměti = [Value2]&nbsp;GB paměti Intel®&nbsp;Optane™ + [Value3]&nbsp;GB paměti RAM",
                "tr_subtitle": "Tato kombinace vám díky paměti Intel® Optane™ zrychlí pevný disk, zatímco rychlost reakcí bude ještě posílena pamětí RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB paměti RAM pro zpracování více úloh najednou",
                    "tr_subtitle": "Pokud používáte víc programů současně, [memory_total]&nbsp;GB paměti vám umožní mezi nimi snadno přepínat."
                  },
                  {
                    "tr_title": "Pro běžnou práci",
                    "tr_subtitle": "Paměť v&nbsp;tomto zařízení umožňuje plynulé načítání a&nbsp;spouštění základních aplikací, jako jsou aplikace pro zpracování textu a&nbsp;prohlížení webových stránek."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMĚŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX]&nbsp;GB paměti RAM",
                "tr_pretitle": "JEŠTĚ LEPŠÍ ZPRACOVÁNÍ VÍCE ÚLOH NAJEDNOU",
                "tr_subtitle": "Paměť RAM zajišťuje procesoru snazší přístup k&nbsp;programovým souborům, urychluje jeho přístup k&nbsp;těmto datům a&nbsp;umožňuje tak práci na více úlohách najednou.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standardní paměť",
                    "tr_badgeSubTitle": "Až 8&nbsp;GB paměti RAM"
                  },
                  {
                    "tr_badgeTitle": "Rozšířená paměť",
                    "tr_badgeSubTitle": "Více než 8 GB, může být až 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Elitní paměť",
                    "tr_badgeSubTitle": "Více než 16 GB paměti RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMĚŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB pro lepší zpracování více úloh najednou",
                "tr_pretitle": "JEŠTĚ LEPŠÍ ZPRACOVÁNÍ VÍCE ÚLOH NAJEDNOU",
                "tr_subtitle": "Paměť RAM zajišťuje procesoru snazší přístup k&nbsp;programovým souborům, urychluje jeho přístup k&nbsp;těmto datům a&nbsp;umožňuje tak práci na více úlohách najednou.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standardní paměť",
                    "tr_badgeSubTitle": "Až 8&nbsp;GB paměti RAM"
                  },
                  {
                    "tr_badgeTitle": "Rozšířená paměť",
                    "tr_badgeSubTitle": "Více než 8 GB, může být až 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Elitní paměť",
                    "tr_badgeSubTitle": "Více než 16 GB paměti RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "JAKÁ JE TATO PAMĚŤ VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Standardní",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB paměti <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Pokročilá",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB paměti <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Elitní",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB paměti <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Paměť",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "JAKÁ JE TATO PAMĚŤ VE SROVNÁNÍ S OSTATNÍMI?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "JAKÁ JE TATO PAMĚŤ VE SROVNÁNÍ S OSTATNÍMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Stávající zařízení",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Každodenní",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB paměti <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Výkonná",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB paměti <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Pokročilá",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB paměti <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Úložiště",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "KOLIK TOHO VÁŠ POČÍTAČ DOKÁŽE ULOŽIT?",
                "tr_subtitle": "Není třeba hádat. Ukážeme vám, co přesně je možné na tento počítač uložit.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Vytvořte knihovnu čítající</span> <br/><span class=\"storage-standard\">[num_songs] skladeb<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>při průměrné velikosti skladby 5&nbsp;MB",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Vytvořte knihovnu čítající</span><br/><span class=\"storage-standard\"> [num_games] her<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>Při průměrné velikosti hry 40 GB.",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Úložiště pro</span><br/><span class=\"storage-standard\">[num_videos] videí<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>při průměrné velikosti videa 5&nbsp;GB",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Pořiďte a uložte</span><br/><span class=\"storage-standard\">[num_photos] fotografií<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>při průměrné velikosti fotografie 4 MB",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity]&nbsp;GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity]&nbsp;GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory] GB paměť Intel® Optane™ + [convertedSSD] GB disk SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ÚLOŽIŠTĚ",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "Úložiště [capacity] GB SSD",
                "tr_preTitle1": "Úložiště [capacity] TB SSD",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Tento počítač je vybaven diskem SSD o velikosti [capacity] GB. To znamená, že pojme spoustu fotografií, videí a&nbsp;programů, ke kterým budete mít mnohem rychlejší přístup, než by tomu bylo u&nbsp;pevného disku.",
                    "tr_title": "Místo pro všechno ve vašem počítači"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Tento počítač je vybaven diskem SSD o velikosti [capacity] TB. To znamená, že pojme spoustu fotografií, videí a&nbsp;programů, ke kterým budete mít mnohem rychlejší přístup, než by tomu bylo u&nbsp;pevného disku.",
                    "tr_title": "Toto zařízení má působivé úložiště"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>ÚLOŽIŠTĚ<br/></span><span class='value'>[capacity] GB</span>",
                    "tr_badgeTitle1": "<span class='subtitle-value'>ÚLOŽIŠTĚ<br/></span><span class='value'>[capacity] TB</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ÚLOŽIŠTĚ",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "Úložiště [capacity] GB HDD",
                "tr_preTitle1": "Úložiště [capacity] TB HDD",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Pevné disky jsou skvělou volbou pro uživatele, kteří chtějí do svého počítače ukládat velké množství dat.",
                    "tr_title": "Místo pro všechno ve vašem počítači"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Pevné disky jsou skvělou volbou pro uživatele, kteří chtějí do svého počítače ukládat velké množství dat.",
                    "tr_title": "Toto zařízení má působivé úložiště"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>ÚLOŽIŠTĚ</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ÚLOŽIŠTĚ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "KOLIK TOHO VÁŠ POČÍTAČ DOKÁŽE ULOŽIT?",
                "tr_subtitle": "Není třeba hádat. Ukážeme vám, co přesně je možné na tento počítač uložit.",
                "titleArray": [
                  {
                    "tr_title": "[XX]&nbsp;GB HDD"
                  },
                  {
                    "tr_title": "[XX]&nbsp;GB SSD"
                  },
                  {
                    "tr_title": "[total_capacity] GB disk SSD od společnosti Intel® akcelerovaný [optane_memory_size]&nbsp;GB paměti Intel® Optane™"
                  },
                  {
                    "tr_title": "[total_capacity] TB disk SSD od společnosti Intel® akcelerovaný [optane_memory_size]&nbsp;GB paměti Intel® Optane™"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>ÚLOŽIŠTĚ<br/></span><span class='value'>[capacity] GB</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>ÚLOŽIŠTĚ<br/></span><span class='value'>[capacity] GB</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>ÚLOŽIŠTĚ<br/></span><span class='value'>[capacity] GB</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>ÚLOŽIŠTĚ<br/></span><span class='value'>[capacity] TB</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>ÚLOŽIŠTĚ<br/></span><span class='value'>[capacity] TB</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>ÚLOŽIŠTĚ<br/></span><span class='value'>[capacity] TB</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ÚLOŽIŠTĚ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "Úložiště [convertedSSD] GB SSD + [convertedHDD] GB HDD",
                "tr_preTitle1": "Úložiště [convertedSSD] TB SSD + [convertedHDD] GB HDD",
                "tr_preTitle2": "Úložiště [convertedSSD] GB SSD + [convertedHDD] TB HDD",
                "tr_preTitle3": "Úložiště [convertedSSD] TB SSD + [convertedHDD] TB HDD",
                "titleArray": [
                  {
                    "tr_title": "Místo pro všechno ve vašem počítači",
                    "tr_title1": "Toto zařízení má působivé úložiště",
                    "tr_subtitle": "Tento počítač je vybaven diskem SSD o&nbsp;kapacitě [convertedSSD] GB a&nbsp;pevným diskem o&nbsp;kapacitě [convertedHDD] GB. Vaše úložiště tak bude mít mimořádnou kapacitu a&nbsp;rychlost.",
                    "tr_subtitle1": "Tento počítač je vybaven diskem SSD o&nbsp;kapacitě [convertedSSD] TB a&nbsp;pevným diskem o&nbsp;kapacitě [convertedHDD] GB. Vaše úložiště tak bude mít mimořádnou kapacitu a&nbsp;rychlost.",
                    "tr_subtitle2": "Tento počítač je vybaven diskem SSD o&nbsp;kapacitě [convertedSSD] GB a&nbsp;pevným diskem o&nbsp;kapacitě [convertedHDD] TB. Vaše úložiště tak bude mít mimořádnou kapacitu a&nbsp;rychlost.",
                    "tr_subtitle3": "Tento počítač je vybaven diskem SSD o&nbsp;kapacitě [convertedSSD] TB a&nbsp;pevným diskem o&nbsp;kapacitě [convertedHDD] TB. Vaše úložiště tak bude mít mimořádnou kapacitu a&nbsp;rychlost."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>ÚLOŽIŠTĚ<br/></span><span class='value'>[capacity] GB</span>",
                    "tr_badgeTitle1": "<span class='subtitle-value'>ÚLOŽIŠTĚ<br/></span><span class='value'>[capacity] TB</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ÚLOŽIŠTĚ",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Získejte rychlejší přístup k&nbsp;velkokapacitnímu úložišti prostřednictvím paměti Intel® Optane™ s&nbsp;diskem SSD.",
                "tr_title": "Obří soubory jsou vítané",
                "tr_pretitle": "[total_capacity] GB disk SSD od společnosti Intel® akcelerovaný [optane_memory_size]&nbsp;GB paměti Intel® Optane™",
                "tr_pretitle1": "[total_capacity] TB disk SSD od společnosti Intel® akcelerovaný [optane_memory_size]&nbsp;GB paměti Intel® Optane™",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Toto revoluční&nbsp;řešení úložiště je první, které kombinuje paměť Intel® Optane™ a disk SSD na jednom čipu. Výhody:",
                    "tr_title": "CO JE TO PAMĚŤ INTEL® OPTANE™ S&nbsp;DISKEM SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Rychlé otevírání souborů a spouštění aplikací",
                    "tr_badgeSubTitle": "Díky tomu, že se často používané soubory a&nbsp;programy načtou předem, můžete věnovat víc času tvorbě obsahu a&nbsp;méně času trávit čekáním.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Kapacita a rychlost",
                    "tr_badgeSubTitle": "Získáte prostor, který potřebujete pro multimediální soubory, a&nbsp;současně rychlost disku SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Rychlejší reakce v&nbsp;průběhu času",
                    "tr_badgeSubTitle": "Paměť Intel® Optane™ s&nbsp;diskem SSD se učí, jakým způsobem pracujete, a&nbsp;tím vaši práci na počítači zefektivňuje.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ÚLOŽIŠTĚ",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Mějte všechna svá multimédia po ruce ve velkém a spolehlivém úložišti.",
                "tr_title": "Místo pro všechny vaše fotografie, videa a&nbsp;skladby"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Displej",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] mil. pixelů, na které si můžete sáhnout",
                "tr_subtitle": "Komunikujte s&nbsp;počítačem stejně jako s&nbsp;telefonem pomocí intuitivního ovládání a ostřejších, živějších detailů.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rozlišení: [screenResolutionX] × [screenResolutionY]",
                    "tr_badgePreTitle": "Pixely: [screenResolution] milionů"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]palcový displej",
                    "tr_badgeSubTitle": "Výška obrazovky: [screenHeight]&nbsp;palců",
                    "tr_badgePreTitle": "Šířka obrazovky: [YY] palců"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Dotykový displej",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Displej",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Ponořte se do [screenResolution]&nbsp;mil. pixelů",
                "tr_subtitle": "Nejdůležitější jsou pixely. Čím je jich více, tím ostřejší a věrnější budou vaše obrázky.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rozlišení: [screenResolutionX] × [screenResolutionY]",
                    "tr_badgePreTitle": "Pixely: [screenResolution] milionů"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]palcový displej",
                    "tr_badgeSubTitle": "Výška obrazovky: [screenHeight]&nbsp;palců",
                    "tr_badgePreTitle": "Šířka obrazovky: [YY] palců"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Displej",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] palců <br/>[screenResolutionX] × [screenResolutionY]",
                "tr_pretitle": "VAŠE OKNO DO HERNÍCH SVĚTŮ",
                "tr_subtitle": "Stejně jako při výběru televizoru vás také při výběru herní obrazovky bude nejvíce zajímat rozměr a rozlišení.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rozlišení: [screenResolutionX] × [screenResolutionY]",
                    "tr_badgePreTitle": "Pixely: [screenResolution] milionů"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]palcový displej",
                    "tr_badgeSubTitle": "Výška obrazovky: [screenHeight]&nbsp;palců",
                    "tr_badgePreTitle": "Šířka obrazovky: [screenWidth]&nbsp;palců"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Dotykový displej"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Displej",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Toto zařízení má působivou obrazovku",
                "tr_subtitle": "Více než osminásobek pixelů oproti rozlišení HD posune vaše videa a hry na zcela novou úroveň, která bude ostřejší a barevnější.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rozlišení: [screenResolutionX] × [screenResolutionY]",
                    "tr_badgePreTitle": "Pixely: [screenResolution] milionů"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]palcový displej",
                    "tr_badgeSubTitle": "Výška obrazovky: [screenHeight]&nbsp;palců",
                    "tr_badgePreTitle": "Šířka obrazovky: [YY] palců"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Displej",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] mil. pixelů, na které si můžete sáhnout",
                    "tr_subTitle": "Zobrazte svůj oblíbený obsah ostře a v živějších detailech, abyste jej mohli snáze ovládat."
                  },
                  {
                    "tr_title": "[screenResolution] mil. pixelů pro vylepšení vašeho digitálního světa",
                    "tr_subTitle": "Nejdůležitější jsou pixely. Čím je jich více, tím ostřejší a věrnější budou vaše obrázky."
                  },
                  {
                    "tr_title": "Toto zařízení má působivou obrazovku",
                    "tr_subTitle": "Téměř čtyřnásobek pixelů proti rozlišení HD posune vaše videa a&nbsp;hry <br/>na zcela novou úroveň, která bude ostřejší a&nbsp;barevnější."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Možnosti připojení",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Technologie Thunderbolt™&nbsp;3 představuje nejrychlejší a&nbsp;nejvšestrannější připojení k&nbsp;libovolnému dokovacímu, zobrazovacímu nebo datovému zařízení.",
                "tr_title": "Jeden port pro všechno",
                "tr_title1": "JEDEN PORT PRO VŠECHNO",
                "titleArray": [
                  {
                    "tr_title": "JAKÁ VYUŽITÍ MÁ TECHNOLOGIE THUNDERBOLT™&nbsp;3?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Technologie Thunderbolt™ 3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "Monitory s&nbsp;rozlišením 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Připojení obrazovek s&nbsp;báječným rozlišením, kontrastem a&nbsp;počtem barev"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Samostatná grafická karta",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "V&nbsp;okamžiku výrazně vylepší grafiku počítače."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Rychlé připojení k&nbsp;síti",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Poskytuje rychlé připojení rovnocenných (peer-to-peer) zařízení"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Možnosti připojení",
                "detailBGUrl": "",
                "tr_subtitle": "Technologie Thunderbolt™&nbsp;3 přesouvá data rychleji než konvenční rozhraní USB. Díky tomu můžete přesouvat soubory rychleji a&nbsp;snadněji připojit periferní zařízení, jako jsou další monitory nebo externí grafické karty.",
                "tr_title": "Technologie Thunderbolt™ 3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "BLESKOVÁ RYCHLOST DAT",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mb/s",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gb/s",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Technologie Thunderbolt™ 3",
                    "tr_badgeSubTitle": "40 Gb/s",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Možnosti připojení",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Jeden univerzální port a kabel podporují všechno vaše oblíbené příslušenství</li> <li>Spolehlivé nabíjení notebooků</li> <li>Robustní certifikace dodává pocit jistoty</li></ul>",
                "tr_title": "Opravdu univerzální kabelové připojení",
                "tr_title1": "OPRAVDU UNIVERZÁLNÍ KABELOVÉ PŘIPOJENÍ",
                "tr_noteSection": "<sup>†</sup>Na základě minimálních požadavků specifikace",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "JAK MOHU TECHNOLOGII THUNDERBOLT™ 4 POUŽÍVAT?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Technologie Thunderbolt™ 4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Tvořit",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Připojte externí monitor a rychlé úložiště, abyste si mohli prohlížet a upravovat fotky a videa."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dokovací stanice",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Připojte všechno příslušenství k&nbsp;notebooku pomocí jediného kabelu, abyste měli na stole pořádek."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Hra",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Přidejte další kapacitu disků SSD a&nbsp;HDD s rychlostí až 3&nbsp;000 MB/s, abyste měli kam ukládat hry a&nbsp;multimediální soubory."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximální výkon<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Čtyřikrát rychlejší než USB* 3.2 <br/>Dvakrát rychlejší než HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Možnosti připojení",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Užívejte si stejně úžasný výkon technologie Thunderbolt™ 3 s&nbsp;ještě více možnostmi, díky kterým budete moct snadno připojit a&nbsp;využívat nejnovější dokovací zařízení, monitory a&nbsp;rychlá úložiště s&nbsp;rozhraním Thunderbolt™ nebo jakékoli příslušenství USB*.",
                "tr_title": "Technologie Thunderbolt™ 4",
                "tr_pretitle": "OPRAVDU UNIVERZÁLNÍ KABELOVÉ PŘIPOJENÍ",
                "tr_noteSection": "<sup>†</sup>Na základě minimálních požadavků specifikace",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Jeden univerzální kabel Thunderbolt™ 4 dokáže nahradit všechny vaše kabely USB-C*, čímž vám usnadní a urychlí výběr kabelu."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Doky technologie Thunderbolt™ 4 nabízejí až čtyři porty Thunderbolt™, se kterými získáte větší flexibilitu při připojování příslušenství."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximální výkon<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Čtyřikrát rychlejší než USB* 3.2 <br/>Dvakrát rychlejší než HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Možnosti připojení",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Jeden univerzální port a kabel podporují všechno vaše oblíbené příslušenství</li> <li>Spolehlivé nabíjení notebooků</li> <li>Robustní certifikace dodává pocit jistoty</li></ul>",
                "tr_title": "Maximalizujte své zážitky s&nbsp;příslušenstvím Thunderbolt™",
                "tr_noteSection": "<sup>†</sup>Na základě minimálních požadavků specifikace",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "JAK MOHU TECHNOLOGII THUNDERBOLT™ 4 POUŽÍVAT?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Tvořit",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Připojte externí monitor a rychlé úložiště, abyste si mohli prohlížet a upravovat fotky a videa."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dokovací stanice",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Připojte všechno příslušenství k&nbsp;notebooku pomocí jediného kabelu, abyste měli na stole pořádek."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Hra",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Přidejte další kapacitu disků SSD a&nbsp;HDD s rychlostí až 3&nbsp;000 MB/s, abyste měli kam ukládat hry a&nbsp;multimediální soubory."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximální výkon<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Čtyřikrát rychlejší než USB* 3.2 <br/>Dvakrát rychlejší než HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Nejběžnější typy připojení Wi‑Fi jsou:",
                "tr_title": "VYSVĚTLENÍ STANDARDŮ Wi‑Fi",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Díky Wi-Fi 6 můžete mít rychlejší připojení i ve chvíli, kdy stejný signál využívá hodně uživatelů najednou.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "S&nbsp;nejnovější inovací technologie Wi-Fi od společnosti Intel můžete dosáhnout vyšších rychlostí než u&nbsp;gigabitové Wi-Fi i&nbsp;v&nbsp;případě, že stejný signál využívá několik uživatelů.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Připojení Wi‑Fi 6E, představující největší pokrok v technologii Wi‑Fi za 20 let, otevírá nové vysokorychlostní kanály pro lepší výkon a spolehlivost a menší rušení."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Díky Wi-Fi 6 můžete mít rychlejší připojení i ve chvíli, kdy stejný signál využívá hodně uživatelů najednou.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Všechny výhody technologie Wi&#8209;Fi 6E v&nbsp;kombinaci s&nbsp;mechanizmem stanovení priority Intel® Killer™, který přiděluje kapacitu připojení webovým stránkám a aplikacím, které ji potřebují nejvíc."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Díky menší konkurenci na vysokorychlostních kanálech si budete moci vychutnávat vyšší výkon a spolehlivost."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6",
                    "tr_badgeSubTitle": "Díky Wi-Fi 6 můžete mít rychlejší připojení i ve chvíli, kdy stejný signál využívá hodně uživatelů najednou.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Díky menšímu rušení s&nbsp;připojením Wi-Fi 6 a inteligentnímu výkonu mechanizmu stanovení priority Intel® Killer™ bude vaše práce s&nbsp;internetem rychlejší.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Díky menší konkurenci na vysokorychlostních kanálech si budete moci vychutnávat vyšší výkon a spolehlivost."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Rychlosti Wi-Fi připojení přesahující 5 Gb/s a stabilně nízká latence – sdílení souborů vám místo minut zabere vteřiny."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) je nejnovější technologie umožňující \"extrémní\" spolehlivost, nízkou latenci a rychlost díky optimalizaci sítě založené na umělé inteligenci."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Díky Wi-Fi 6 můžete mít rychlejší připojení i ve chvíli, kdy stejný signál využívá hodně uživatelů najednou.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) s rychlostí vyšší než 1 Gb/s, spolehlivostí a nízkou latencí s využitím kanálů 6 GHz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Lepší efektivita, kapacita a rychlost ve srovnání s Wi-Fi 5 v přetížených sítích."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Připojte se k internetu lépe prostřednictvím standardu Wi‑Fi [XX] a&nbsp;získejte rychlejší<sup>†</sup> a spolehlivější připojení.",
                "tr_title": "Získejte větší dosah a&nbsp;rychlost",
                "tr_noteSection": "<sup>†</sup>Skutečná datová propustnost a&nbsp;dosah bezdrátové komunikace se může měnit.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "V&nbsp;éře her pro více hráčů a online hraní je možnost zůstat neustále ve spojení důležitější než kdy dřív. Kromě rychlosti ale také potřebujete bezdrátový signál, který potřebné rychlosti dokáže zajistit i&nbsp;na dálku.",
                "tr_title": "[XX]",
                "tr_pretitle": "KLÍČEM JE PŘIPOJENÍ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Připojte se pomocí technologie Intel® Wi‑Fi 6 (Gig+) ke směrovači Wi‑Fi 6 s podporou kanálu 160 MHz a získejte rychlejší připojení a větší spolehlivost pro aktivnější uživatele.",
                "tr_title": "Téměř třikrát vyšší rychlosti s&nbsp;připojením Intel® <span class='no-warp'>Wi-Fi 6  </span>(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Než u standardu Wi‑Fi ac. Vybrané funkce jsou k dispozici pouze u některých modelů. Podrobnosti vám sdělí výrobce. Další informace o připojení Wi‑Fi najdete na adrese intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Základní dvoupásmové připojení Intel® Wi-Fi 6 podporuje vyšší rychlost u nejběžnějších způsobů využití internetu, jako je e-mail nebo prohlížení webových stránek na přetížených sítích.<sup>9</sup>",
                "tr_title": "Nezbytná Wi-Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "V&nbsp;éře her pro více hráčů a online hraní je možnost zůstat neustále ve spojení důležitější než kdy dřív. Kromě rychlosti ale také potřebujete bezdrátový signál, který potřebné rychlosti dokáže zajistit i&nbsp;na dálku.",
                "tr_title": "[XX]",
                "tr_pretitle": "KLÍČEM JE PŘIPOJENÍ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Dostupnost těchto nových kanálů Wi‑Fi znamená, že se nemusíte dělit o šířku pásma se staršími zařízeními v okolí, takže streamování bude plynulejší, prohlížení webu rychlejší a připojení při práci a výuce na dálku bezproblémové.",
                "tr_title": "Připojte se bez konkurence",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Připojte se k&nbsp;internetu lépe prostřednictvím standardu [XX] a&nbsp;získejte rychlejší<sup>†</sup> a spolehlivější připojení.",
                "tr_title": "Získejte větší dosah a&nbsp;rychlost",
                "tr_noteSection": "<sup>†</sup>Skutečná datová propustnost a&nbsp;dosah bezdrátové komunikace se může měnit.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "V&nbsp;éře her pro více hráčů a online hraní je možnost zůstat neustále ve spojení důležitější než kdy dřív. Kromě rychlosti ale také potřebujete bezdrátový signál, který potřebné rychlosti dokáže zajistit i&nbsp;na dálku.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "KLÍČEM JE PŘIPOJENÍ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Integrované ultrarychlé připojení Wi‑Fi",
                "tr_subtitle": "Zůstaňte v kontaktu s online světem i bez drátů."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Díky vyhrazeným vysokorychlostním kanálům s nižším rušením a modulu Intel® Killer™ Prioritization Engine, který dává hry na první místo, se hraní po internetu právě dočkalo zásadního vylepšení.",
                "tr_title": "Nechte konkurenci za sebou",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Díky vyhrazeným vysokorychlostním kanálům s nižším rušením a modulu Intel® Killer™ Prioritization Engine, který dává hry na první místo, se hraní po internetu právě dočkalo zásadního vylepšení.",
                "tr_title": "[XX]",
                "tr_pretitle": "NECHTE KONKURENCI ZA SEBOU",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Díky vyhrazeným vysokorychlostním kanálům s&nbsp;nižším rušením, které dávají hry na první místo, se hraní po internetu právě dočkalo zásadního vylepšení.",
                "tr_title": "[XX]",
                "tr_pretitle": "NECHTE KONKURENCI ZA SEBOU",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Technologie Intel® Killer™ Wi-Fi automaticky poskytuje rychlejší připojení aplikacím, které to nejvíce potřebují. Takže streamovaná videa získají ostré detaily a sníží se prodleva při jejich načítání.",
                "tr_title": "Rychlejší a chytřejší způsob připojení.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "V&nbsp;éře her pro více hráčů a online hraní je možnost zůstat neustále ve spojení důležitější než kdy dřív. Kromě rychlosti ale také potřebujete bezdrátový signál, který potřebné rychlosti dokáže zajistit i&nbsp;na dálku.",
                "tr_title": "[XX]",
                "tr_pretitle": "KLÍČEM JE PŘIPOJENÍ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Připojte se k&nbsp;internetu lépe prostřednictvím standardu Wi-Fi 5 a&nbsp;získejte rychlejší<sup>†</sup> a spolehlivější připojení.",
                "tr_title": "Získejte větší dosah a&nbsp;rychlost",
                "tr_noteSection": "<sup>†</sup>Skutečná datová propustnost a&nbsp;dosah bezdrátové komunikace se může měnit.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "V&nbsp;éře her pro více hráčů a online hraní je možnost zůstat neustále ve spojení důležitější než kdy dřív. Kromě rychlosti ale také potřebujete bezdrátový signál, který potřebné rychlosti dokáže zajistit i&nbsp;na dálku.",
                "tr_title": "[XX]",
                "tr_pretitle": "NECHTE KONKURENCI ZA SEBOU",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Wi-Fi 7 vychází z dřívějších generací Wi-Fi a posouvá jejich vlastnosti na novou úroveň. Vedle vyšší rychlosti tak můžeme čekat i výrazně zlepšenou odezvu a spolehlivost pro důmyslné technologie budoucnosti a intenzivnější zážitky.",
                "tr_title": "Bezdrátové připojení, které se vyrovná kabelu",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Wi-Fi 7 vychází z dřívějších generací Wi-Fi a posouvá jejich vlastnosti na novou úroveň. Vedle vyšší rychlosti tak můžeme čekat i výrazně zlepšenou odezvu a spolehlivost pro důmyslné technologie budoucnosti a intenzivnější zážitky.",
                "tr_title": "[XX]",
                "tr_pretitle": "NOVÁ DIMENZE UŽIVATELSKÉHO ZÁŽITKU",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Zlepšená rychlost, latence a spolehlivost díky optimalizaci sítí na bázi umělé inteligence",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Zlepšená rychlost, latence a spolehlivost díky optimalizaci sítí na bázi umělé inteligence.",
                "tr_title": "[XX]",
                "tr_pretitle": "ODEZVA JAKO NA KABELU",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterie",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Jak lze tuto vlastnost měřit?",
                    "tr_badgeSubTitle": "Většina výrobců vyjadřuje výdrž na baterii v&nbsp;hodinách. Tento odhad sice není stoprocentně přesný, ale pomůže vám, když v&nbsp;tomto ohledu porovnáváte dva počítače."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterie",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "HRANÍ NA CESTÁCH",
                "tr_subtitle": "S&nbsp;výkonem dnešních herních notebooků není důvod sedět za stolem. Nyní si můžete vzít hry kamkoliv.",
                "tr_title": "[Dynamic.battery.values.hours]&nbsp;hod. provozu na baterii"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterie",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Odpojte nabíječku a zůstaňte celé hodiny se svými aplikacemi, hrami a hudbou.",
                "tr_title": "[XX] hodin streamování na jedno nabití<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Na základě výdrže na baterii udávané výrobcem počítače.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]&nbsp;h",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterie",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Odpojte nabíječku a zůstaňte celé hodiny se svými aplikacemi, hrami a hudbou.",
                "tr_title": "[Dynamic.battery.values.hours] hodin provozu na cestách<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Na základě výdrže na baterii udávané výrobcem počítače.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]&nbsp;h",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterie",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Odpojte nabíječku a zůstaňte celé hodiny se svými aplikacemi, hrami a hudbou.",
                "tr_title": "[Dynamic.battery.values.hours] hodin pro celodenní provoz na jedno nabití<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Na základě výdrže na baterii udávané výrobcem počítače.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]&nbsp;h",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Baterie",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Tento počítač optimalizuje spotřebu energie, aby baterie vydržela déle, a&nbsp;poskytuje výdrž nejméně 4 hodiny po 30minutovém nabíjení.<sup>4</sup>",
                "tr_title": "S&nbsp;[Dynamic.battery.values.hours]&nbsp;hodinami výdrže na baterii se můžete odpojit na delší dobu<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Na základě výdrže na baterii udávané výrobcem počítače.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]&nbsp;h",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Domů",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Proč právě tento počítač",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Zobrazit všechny specifikace",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "vzdálená",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobilní zařízení",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Právní informace",
        "tr_paragraph_array": [
          "Software a zátěže použité ve výkonnostních testech mohly být optimalizovány jen pro procesory Intel®. Měření výkonnostních testů, jako jsou například SYSmark* nebo MobileMark*, se provádí pomocí konkrétních počítačových systémů, komponent a&nbsp;funkcí. Jakákoli změna kteréhokoli z&nbsp;těchto parametrů může způsobit odlišné výsledky. Při konečném výběru byste měli vzít v&nbsp;úvahu také další informace a výkonnostní testy, včetně výkonu posuzovaného produktu v&nbsp;kombinaci s&nbsp;dalšími produkty. Úplné informace o&nbsp;výkonu a výsledcích srovnávacích testů naleznete na adrese www.intel.com/benchmarks.",
          "¹Produkty Intel® Wi-Fi 6 (GIG+) podporují volitelné 160MHz kanály, což u&nbsp;typických produktů s&nbsp;Wi-Fi 802.11ax 2x2 umožňuje využití nejvyšších teoretických maximálních rychlostí (2402 Mb/s). Špičkové produkty Intel® Wi-Fi&nbsp;6 (GIG+) umožňují využití 2× až 4× vyšších teoretických maximálních rychlostí ve srovnání se zařízeními vybavenými standardem 802.11ax 2x2 (1201&nbsp;Mb/s) nebo 1x1 (600&nbsp;Mb/s), které zvládnou jen základní 80MHz pásmo.",
          "²Pouze informativní doporučení. Při rozhodování o koupi se seznamte i s&nbsp;dalšími zdroji informací.",
          "³Funkce a výhody technologií Intel mohou záviset na konfiguraci systému a mohou vyžadovat podporovaný hardware, software nebo aktivaci služby. Výkon se může lišit v závislosti na konfiguraci systému. Žádný počítačový systém nedokáže zajistit absolutní zabezpečení. Další informace získáte od výrobce nebo prodejce systému nebo na webu intel.com.",
          "⁴Pro systémy s&nbsp;FHD obrazovkou při prohlížení webových stránek prostřednictvím bezdrátového připojení. Platí pro vypnutý počítač, základní úroveň nastavení OEM pro vypnutí.",
          "⁵Ve srovnání s&nbsp;jinými technologiemi vstupně-výstupního připojení počítačů, například eSATA, USB nebo IEEE&nbsp;1394* FireWire. Výkon se bude lišit v závislosti na konkrétním použitém hardwaru a softwaru. Musí být použito zařízení podporující technologii Thunderbolt™.",
          "⁶Téměř třikrát rychlejší: standard 802.11ax 2×2 160 MHz umožňuje dosáhnout maximální teoretické rychlosti přenosu dat 2402 Mb/s. Téměř třikrát rychlejší než standard 802.11ac 2×2 80 MHz (867 Mb/s), jak je uvedeno ve specifikacích bezdrátového standardu IEEE 802.11*, a vyžaduje obdobně konfigurované bezdrátové síťové směrovače 802.11ax.",
          "⁷O 75 % nižší latence vychází ze simulace dat společnosti Intel (79 %) pro standard 802.11ax s využitím a bez využití OFDMA s použitím devíti klientů. Průměrná latence bez využití OFDM je 36&nbsp;ms, s&nbsp;využitím OFDMA se průměr sníží na 7,6&nbsp;ms. Ke snížení latence je potřeba, aby směrovač 802.11ax (Wi-Fi 6) i&nbsp;všichni klienti podporovali OFDMA.",
          "⁸Na základě porovnání teoretických maximálních rychlostí přenosu dat ve specifikaci 802.11 může připojení 2×2 160 MHz Wi‑Fi 6/6E (802.11ax) poskytovat rychlost 2402 Mb/s a připojení 2×2 80 MHz Wi‑Fi 5 (802.11ac) rychlost 867 Mb/s, což znamená 2,8× vyšší rychlost.",
          "⁹Nejlepší Wi‑Fi 6 ve své třídě: produkty Intel® Wi‑Fi 6 (Gig+) podporují volitelné kanály se šířkou pásma 160 MHz, což umožňuje využití nejvyšších teoretických maximálních rychlostí (2402 Mb/s) u typických produktů s Wi‑Fi 802.11ax 2x2. Špičkové produkty Intel® Wi‑Fi 6 (Gig+) umožňují využití 2× až 4× vyšších teoretických maximálních rychlostí ve srovnání se zařízeními vybavenými standardem 802.11ax 2x2 (1201 Mb/s) nebo 1x1 (600 Mb/s), které zvládnou jen základní 80MHz pásmo.",
          "Pouze vybrané modely, funkce se liší podle provedení OEM. Podrobnosti získáte od výrobce OEM nebo prodejce. Další informace o připojení Wi‑Fi najdete na adrese intel.com/wifi6disclaimers.",
          "¹⁰Technologie Thunderbolt™ 4 s&nbsp;rychlostí 40 Gb/s poskytuje nejrychlejší, nejjednodušší a nejspolehlivější řešení kabelového připojení libovolné dokovací stanice, monitoru nebo datového zařízení ve srovnání s&nbsp;jinými technologiemi vstupně-výstupního připojení počítačů včetně eSATA*, USB* a IEEE 1394* FireWire.",
          "¹¹Vyžaduje, aby směrovač 802.11ax podporoval OFDMA a&nbsp;více klientů v&nbsp;síti s&nbsp;podporou AX. Lepší výkon v&nbsp;hustých prostředích lze získat prostřednictvím funkcí OFDMA podporovaných klienty a&nbsp;přístupovými body 802.11ax. 2 Gb/s za předpokladu, že alespoň 70&nbsp;% specifikace standardu IEEE 802.11* dosahuje teoretické maximální rychlosti přenosu dat 802.11ax 160&nbsp;MHz 2402&nbsp;Mb/s.",
          "¹²Na základě měření při otevírání dokumentu s&nbsp;aktivitou na pozadí při porovnávání procesoru 8.&nbsp;generace Intel® Core™ i7-8565U (TLC SSD 512&nbsp;GB) s&nbsp;procesorem 8.&nbsp;generace Intel® Core™ i7-8565U (32GB paměť Intel® Optane™ H10 a&nbsp;512GB jednotka SSD).",
          "¹³Technologie Intel® Thread Director je zabudována do procesorů 12. generace Intel® Core™ a pomáhá podporovaným operačním systémům inteligentněji směrovat úlohy do správných jader. Není nutná žádná akce uživatele. Podrobnosti najdete na webu intel.com.",
          "¹⁴Není k dispozici u některých procesorů 12. generace Intel® Core™. Architektura s hybridním výkonem kombinuje na jednom procesorovém čipu dvě nové mikroarchitektury jader, Performance-cores (P-cores) a Efficient-cores (E-cores). Vybrané procesory 12. generace Intel® Core™ (některé procesory 12. generace Intel® Core™ i5 a nižší) nepodporují architekturu s hybridním výkonem, jen jádra P-cores.",
          "¹⁵Měřeno testem Game Launch Workload na zařízení s&nbsp;procesorem 8.&nbsp;generace Intel® Core™ i7-8750H (s&nbsp;paměťovým modulem Intel® Optane™ o&nbsp;velikosti 32&nbsp;GB, diskem PCIe* Intel® SSD o&nbsp;velikosti 256&nbsp;GB a&nbsp;pevným diskem o&nbsp;velikosti 1&nbsp;TB) ve srovnání s&nbsp;procesorem 8.&nbsp;generace Intel® Core™ i7-8750H (s&nbsp;diskem PCIe* Intel® SSD o&nbsp;velikosti 256&nbsp;GB a&nbsp;pevným diskem o&nbsp;velikosti 1&nbsp;TB). Měřeno testem Game Launch Workload – zatížení vyvinuté společností Intel k&nbsp;měření doby spuštění hry Total War*: WARHAMMER* II verze 5577.0 a&nbsp;dosažení hlavní nabídky s&nbsp;vypnutým úvodním videem (spuštění). Konfigurace pro zrychlení datového disku s&nbsp;pamětí Intel® Optane™: procesor Intel® Core™ i7-8750H, TDP&nbsp;PL1&nbsp;=&nbsp;45&nbsp;W, 6&nbsp;jader&nbsp;/&nbsp;12&nbsp;vláken, s&nbsp;frekvencí až 4&nbsp;GHz na předprodukčním systému OEM, grafika: NVIDIA* GeForce* GTX 1070, paměť: 2× 4&nbsp;GB DDR4, úložiště: disk řady Intel® SSD (PCIe*) 760p o&nbsp;velikosti 256&nbsp;GB + pevný disk o&nbsp;velikosti 1&nbsp;TB + paměť Intel® Optane™ o&nbsp;velikosti 32&nbsp;GB, operační systém: Windows* 10 RS3 sestavení 1709 s&nbsp;mikrořadičem (MCU) 0x84; procesor Intel® Core™ i7-8750H, TDP PL1 = 45&nbsp;W, 6&nbsp;jader&nbsp;/&nbsp;12&nbsp;vláken, s&nbsp;frekvencí až 4&nbsp;GHz na předprodukčním systému OEM, grafika: NVIDIA* GeForce* GTX 1070, paměť: 2× 4&nbsp;GB DDR4, úložiště: disk řady Intel® SSD (PCIe*) 760p o&nbsp;velikosti 256&nbsp;GB a&nbsp;pevný disk o&nbsp;velikosti 1&nbsp;TB, operační systém: Windows* 10 RS3 sestavení 1709, s&nbsp;mikrořadičem (MCU) 0x84.",
          "¹⁶Měřeno při zatížení vyvolaném spuštěním hry a načtením herní úrovně na počítači s&nbsp;procesorem 8. generace Intel® Core™ i7-8750H (vybaveným paměťovým modulem Intel® Optane™ o&nbsp;velikosti 32&nbsp;GB) ve srovnání s&nbsp;procesorem 8. generace Intel® Core™ i7 8750H (pouze s&nbsp;pevným diskem). Zatížení vyvolané spuštěním hry – zatížení vyvinuté společností Intel k&nbsp;měření doby od otevření hlavní nabídky až po úplné načtení úrovně ve hře Total War*: WARHAMMER* II verze 5577.0. Konfigurace pro zrychlení datového disku s&nbsp;pamětí Intel® Optane™: procesor Intel® Core™ i7-8750H, TDP&nbsp;PL1&nbsp;=&nbsp;45&nbsp;W, 6&nbsp;jader&nbsp;/&nbsp;12&nbsp;vláken, s&nbsp;frekvencí až 4&nbsp;GHz na předprodukčním systému OEM, grafika: NVIDIA* GeForce* GTX 1070, paměť: 2× 4&nbsp;GB DDR4, úložiště: disk řady Intel® SSD (PCIe*) 760p o&nbsp;velikosti 256&nbsp;GB + pevný disk o&nbsp;velikosti 1&nbsp;TB + paměť Intel® Optane™ o&nbsp;velikosti 32&nbsp;GB, operační systém: Windows* 10 RS3 sestavení 1709 s&nbsp;mikrořadičem (MCU) 0x84; procesor Intel® Core™ i7-8750H, TDP PL1 = 45&nbsp;W, 6&nbsp;jader&nbsp;/&nbsp;12&nbsp;vláken, s&nbsp;frekvencí až 4&nbsp;GHz na předprodukčním systému OEM, grafika: NVIDIA* GeForce* GTX 1070, paměť: 2× 4&nbsp;GB DDR4, úložiště: disk řady Intel® SSD (PCIe*) 760p o&nbsp;velikosti 256&nbsp;GB a pevný disk o&nbsp;velikosti 1&nbsp;TB, operační systém: Windows* 10 RS3 sestavení 1709, s&nbsp;mikrořadičem (MCU) 0x84.",
          "¹⁷Na jádrech P-core. Výkonnost se liší podle způsobu použití, konfigurace a dalších faktorů. Další informace se dozvíte na stránce www.intel.com/PerformanceIndex.",
          "¹⁸Změna taktovací frekvence nebo napětí může poškodit procesor a další systémové komponenty nebo snížit jejich praktickou životnost a také snížit stabilitu a výkon systému. Produktové záruky nemusejí být platné, pokud je procesor provozován nad rámec specifikací. Další podrobnosti získáte od výrobce systému a&nbsp;komponentů.",
          "¹⁹Na základě měření pomocí výše a níže uvedené bezkonkurenční kombinace možností zabezpečení operačního systému, ochrany aplikací a dat a pokročilé ochrany před hrozbami, které poskytuje platforma Intel vPro® firmám všech velikostí, a také přístupu společnosti Intel, který při návrhu, výrobě a podpoře produktů klade zabezpečení na první místo. Všechny firemní počítače založené na platformě Intel vPro® byly ověřeny podle přísných specifikací, včetně jedinečných hardwarových funkcí zabezpečení. Podrobnosti najdete na adrese www.intel.com/PerformanceIndex (část Platforms). Žádný produkt ani komponenta nemohou být naprosto bezpečné.",
          "²⁰Aplikace Intel® Connectivity Performance Suite je k&nbsp;dispozici jen na počítačích se systémem Windows*. Testování technologie Intel Over The Air (OTA) Wi-Fi v&nbsp;přetížené síti ve srovnání s&nbsp;provozem bez stanovení priority bezdrátového provozu pomocí softwarové aplikace ICPS a inteligentního přepínání přístupových bodů. Podrobnosti najdete na adrese www.intel.com/PerformanceIndex (část Connectivity). Výsledky se mohou lišit.",
          "²¹V&nbsp;porovnání s&nbsp;předchozí generací procesorů.",
          "²²Grafika Intel® Arc™ je první grafika na trhu s podporou kódování AV1 (k 1.&nbsp;čtvrtletí 2022). Podrobnosti na intel.com/performanceindex.",
          "²³Na základě jedinečných funkcí návrhu Intel® Evo™, které splňují také prahové hodnoty pro klíčové zážitky z mobilního počítače. Testování z února 2022.",
          "²⁴Řešení Intel® Unison™ je v současné době k dispozici pouze na oprávněných návrzích Intel® Evo™ na počítačích s operačním systémem Windows* a dá se spárovat pouze s telefony s operačním systémem Android nebo iOS. Na všech zařízeních musí běžet podporovaná verze operační systému.",
          "²⁵Maximální rychlosti paměti jsou spojeny s konfiguracemi 1 DIMM na kanál (1DPC). Dodatečné zatížení DIMM na jakémkoli kanálu může ovlivnit maximální rychlost paměti. Až DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 a DDR5-5200 1Rx8, 1Rx16, 2Rx8 na vybraných SKU. Maximální kapacity paměti je možné dosáhnout s konfiguracemi 2DPC.",
          "²⁶Versus standardní Wi-Fi. Podrobnosti najdete na stránkách intel.com/performance-wireless. Výsledky se mohou lišit.",
          "²⁷Hybridní architektura kombinuje na jednom čipu dvě různé mikroarchitektury jader – Performace-cores (P-cores) a Efficient-cores (E-cores). Poprvé byla představena u procesorů 12. generace Intel® Core™. Vybrané procesory 12. nebo novější generace Intel® Core™ nepoužívají hybridní architekturu, ale mají pouze jádra P-cores a stejnou velikost mezipaměti jako předchozí generace. Podrobnosti o jednotlivých modelech najdete na webu ark.intel.com.",
          "²⁸Technologie Intel® Thread Director je zabudovaná do hardwaru a je k dispozici jen u procesorů 12. nebo novější generace Intel® Core™ využívajících hybridní architekturu; vyžaduje aktivaci v operačním systému. Dostupné vlastnosti a funkce se liší podle operačního systému.",
          "²⁹Technologie Intel® Thread Director je zabudovaná do hardwaru a je k dispozici jen u procesorů 12. a 13. generace Intel® Core™ využívajících hybridní architekturu; vyžaduje aktivaci v operačním systému. Dostupné vlastnosti a funkce se liší podle operačního systému.",
          "³⁰Měřeno na základě výkonu referenční validační platformy procesoru pro mobilní počítače Intel® Core™ Ultra (Série 2 – kódový název: Lunar Lake) ve srovnání s procesorem pro mobilní počítače Intel® Core™ Ultra (Série 1 – kódový název: Meteor Lake) ve srovnávacím testu 3DMark Time Spy. Podrobnosti najdete na intel.com/performanceindex. Výsledky se mohou lišit.",
          "³¹Funkce rozostření pozadí a sledování obličeje jsou k dispozici jen na zařízeních se systémem Windows*.",
          "³²Produkty Wi-Fi 7 mohou přistupovat ke 320 MHz kanálům v pásmu 6 GHz a k novým kombinacím 160 MHz kanálů v pásmu 5 GHz s novými funkcemi Multi-Resource Unit Puncturing.",
          "³³Na základě specifikací bezdrátového standardu IEEE a maximální teoretické rychlosti přenosu dat pro zařízení se dvěma datovými proudy.",
          "³⁴Inženýrské simulace přetížených síťových prostředí společnosti Intel ukazují, že s novými funkcemi Wi-Fi 7 Multi-Link Operation je možné výrazně snížit latenci.",
          "³⁵Minimální nabití získané z výchozího stavu baterie pro vypnutí počítače podle výrobce OEM. Výsledky jednotlivých systémů se mohou lišit. Podrobnosti najdete na stránkách intel.com/performance-evo.",
          "³⁶6 GHz Wi-Fi pásmo není k dispozici ve všech zemích/regionech.",
          "³⁷Pouze pro Windows*",
          "³⁸Nabití získané z výchozího stavu baterie pro vypnutí počítače podle výrobce OEM. Úplné informace o výsledcích výkonnostních a srovnávacích testů naleznete na adrese intel.com/Evo.",
          "³⁹Týká se procesorů pro mobilní počítače Intel® Core™ Ultra (Series&nbsp;2), na základě jedinečných hardwarových bezpečnostních funkcí, bezkonkurenční kombinace těchto funkcí a zabezpečení systému a působivého výkonu i při nižším napájení ve srovnání s předchozí generací (k září 2024). Podrobnosti najdete na intel.com/performanceindex. Výsledky se mohou lišit.",
          "⁴⁰Softwarové aplikace jsou zobrazeny pouze pro ilustrační účely. Funkce umělé inteligence mohou vyžadovat zakoupení dalšího softwaru/předplatného, aktivaci ze strany poskytovatele softwaru / platformy nebo mohou mít specifické požadavky na konfiguraci či kompatibilitu. Podrobnosti se dozvíte na adrese www.intel.com/PerformanceIndex. Výsledky se mohou lišit. © Intel Corporation.",
          "⁴¹Zobrazené obrázky mohou být pozměněny nebo simulovány. Funkce umělé inteligence mohou vyžadovat zakoupení dalšího softwaru/předplatného, aktivaci ze strany poskytovatele softwaru / platformy nebo mohou mít specifické požadavky na konfiguraci či kompatibilitu. Podrobnosti na stránkách www.intel.com/AIPC.",
          "⁴²Uváděno postupně v ukázkové verzi v rámci nejnovější aktualizace systému Windows* 11 na vybraných světových trzích. Datum dostupnosti se liší v závislosti na zařízení a trhu. Zjistit více na aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Řešení Intel® Unison™ je aktuálně k dispozici u zařízení, která splňují požadavky. Podrobnosti najdete na adrese www.intel.com/PerformanceIndex.",
          "⁴⁴FPS zvýšené pomocí funkce Intel® X<sup>e</sup>SS vs. nativní FPS, měřeno ve hře Dying Light 2 Stay Human běžící na procesoru Intel® Core™ Ultra 7 165H. Podrobnosti najdete na adrese www.intel.com/PerformanceIndex. Výsledky se mohou lišit.",
          "⁴⁵Měřeno na základě nižší spotřeby SoC referenční validační platformy procesoru pro mobilní počítače Intel® Core™ Ultra (Series 2 – kódový název: Lunar Lake) ve srovnání s referenční validační platformou procesoru pro mobilní počítače Intel® Core™ Ultra (série 1 – kódový název: Meteor Lake) při přehrávaní videa na YouTube* ve 4K, 30FPS a v AV1. Podrobnosti najdete na intel.com/performanceindex. Výsledky se mohou lišit.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Společnost Intel nenese zodpovědnost za nesprávně uvedené ceny.",
          "*Ostatní názvy a značky mohou být majetkem jiných osob.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES A SOUVISEJÍCÍ DESIGNOVÉ A SLOŽENÉ ZNAČKY JSOU OCHRANNÉ ZNÁMKY SPOLEČNOSTI INFLEXION STUDIOS INC. VŠECHNA PRÁVA VYHRAZENA.",
          "Technologie Intel můžou vyžadovat podporovaný hardware, software nebo aktivaci služeb.",
          "Všechny výsledky a data společnosti NERO* jsou poskytovány jen k&nbsp;praktickým a informačním účelům. Společnost Intel Corporation nenese žádnou odpovědnost například za přesnost aplikace NERO*, výsledky a skóre výkonu nebo potenciální odchylky od jiných verzí aplikace ke stažení dostupných na webu www.nero.com nebo jiných webových stránkách třetích stran. Výsledky srovnávacích testů počítačů NERO* se měří pomocí konkrétních počítačových systémů, komponent, softwaru, operací a funkcí. Jakákoli změna kteréhokoli z&nbsp;těchto parametrů může způsobit odlišné výsledky. Při konečném výběru byste měli vzít v&nbsp;úvahu také další informace a výkonnostní testy, včetně výkonu posuzovaného produktu v&nbsp;kombinaci s&nbsp;dalšími produkty.",
          "Všechny verze platformy Intel vPro® vyžadují vhodný procesor Intel® Core™, podporovaný operační systém, čip Intel® LAN nebo WLAN, vylepšení firmwaru a další hardware a software, které jsou nezbytné k&nbsp;zajištění možností správy, funkcí zabezpečení, výkonu a stability systému, které tuto platformu definují.",
          "Výkonnost se liší podle způsobu použití, konfigurace a dalších faktorů. Další informace se dozvíte na stránce www.intel.com/PerformanceIndex.",
          "Společnost Intel nekontroluje ani nereviduje data třetích stran. Přesnost informací je třeba ověřit i z&nbsp;jiných zdrojů.",
          "© Intel Corporation. Intel, logo Intel a&nbsp;další značky Intel jsou ochranné známky společnosti Intel Corporation nebo jejích dceřiných společností. *Ostatní názvy a značky mohou být majetkem jiných osob.",
          "Žádný produkt ani komponenta nemohou být naprosto bezpečné.",
          "Vaše náklady a výsledky se můžou lišit.",
          "Intel, logo Intel, Intel Core, Intel Optane, Intel Iris, Thunderbolt a&nbsp;logo Thunderbolt jsou ochrannými známkami společnosti Intel Corporation nebo jejích dceřiných společností. Ostatní názvy a značky mohou být nárokovány jako vlastnictví jiných subjektů.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Domů",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Porovnejte naskenováním",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Trendy",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/evo/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/video_link.mp4",
      "iconURL": "",
      "videoName": "video_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ Performance to Power Their World (16_9).mp4",
      "iconURL": "",
      "videoName": "Video_ Performance to Power Their World (16_9)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "VÍTEJTE",
    "tr_welcomePagetrSubtitle": "Ovládejte tento počítač na dálku svým chytrým telefonem",
    "tr_welcomePagembSubtitle": "Vítejte, podívejte se na specifikace tohoto počítače na svém chytrém telefonu",
    "tr_welcomePageBtnText": "Začít",
    "tr_welcomePageText": "Prohlédněte si a porovnejte podrobnosti tohoto počítače na svém chytrém telefonu",
    "tr_scrollText": "Navigujte na zařízení před vámi",
    "tr_toolTipTextc1": "Pomocí těchto tlačítek můžete přecházet na různé stránky na zařízení před vámi.",
    "tr_toolTipTextc2": "Klepnutím na níže uvedené karty přejdete na podrobnou stránku specifikace každého zařízení.",
    "tr_toolTipTextc3": "Pomocí těchto tlačítek se můžete pohybovat na obrazovce před vámi nahoru a dolů.",
    "tr_toolTipTextc4": "Vypnutím režimu dálkového ovládání zobrazíte specifikace zařízení na svém chytrém telefonu.",
    "tr_toolTipTextmb1": "Klepnutím na karty zobrazíte podrobné stránky obsahu.",
    "tr_toolTipTextmb2": "Klepnutím zobrazíte shrnutí všech specifikací zařízení.",
    "tr_toolTipTextmb3": "Klepněte na tlačítko, naskenujte QR kód a porovnejte až čtyři zařízení.",
    "tr_nextButton": "Další",
    "tr_prevButton": "Předchozí",
    "tr_gotItButton": "ROZUMÍM",
    "tr_skipButton": "Přeskočit",
    "tr_placeholderText": "*zástupný symbol",
    "tr_processorHeading": "Procesor",
    "tr_processorSubheading": "Procesor 10.&nbsp;generace Intel® Core™ i7",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "[XX] GB paměti Intel® Optane™ + [YY] GB SSD",
    "tr_coachmarkProcessor": "Procesor XX",
    "tr_coachmarkGraphics": "Grafika [XX]",
    "tr_coachmarkMemory": "Hodnota specifikace",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999,95 USD",
    "tr_compare": "Porovnat",
    "tr_viewallspecs": "ZOBRAZIT VŠECHNY SPECIFIKACE",
    "tr_operatingSystem": "OPERAČNÍ SYSTÉM",
    "tr_AmdProcessorNonIntel": "Procesor AMD*",
    "tr_QualcommProcessorNonIntel": "Procesor Qualcomm*",
    "tr_GBText": "GB",
    "tr_remoteText": "Specifikace zobrazené na obrazovce notebooku",
    "tr_scanErrorText": "Najednou lze naskenovat maximálně tři zařízení. Odstraňte alespoň jedno zařízení.",
    "tr_scanErrorText2": "Toto zařízení jste již naskenovali, naskenujte jiné.",
    "tr_scanMsg": "Naskenovat a přidat zařízení",
    "tr_backToScan": "Zpět na porovnání",
    "tr_scanToCompare": "Naskenovat a&nbsp;porovnat",
    "tr_compareDevice": "Porovnat zařízení",
    "tr_processorTitle": "PROCESOR",
    "tr_graphicTitle": "GRAFIKA",
    "tr_storageTitle": "ÚLOŽIŠTĚ",
    "tr_displayTitle": "DISPLEJ",
    "tr_batteryTitle": "BATERIE",
    "tr_memoryTitle": "PAMĚŤ",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "MOŽNOSTI PŘIPOJENÍ",
    "tr_priceTitle": "CENA",
    "tr_operatingSystemTitle": "OPERAČNÍ SYSTÉM",
    "tr_batteryHoursText": "[XX] hodin",
    "tr_hrsText": "h",
    "tr_touchscreeenText": "[screenDiagonal]” dotyková obrazovka",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, dotykový displej",
    "tr_FHDTouchScreenText": "FHD, dotykový displej",
    "tr_4KTouchscreenText": "4K, dotykový displej",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Uložit",
    "tr_device_comparison": "Dokument s&nbsp;porovnáním zařízení [dd]. [mm]. [yyyy]",
    "tr_Save_As_JPEG": "Uložit jako JPEG",
    "tr_Save_As_PDF": "Uložit jako PDF",
    "tr_Downloading_PDF": "Stahování souboru PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Otevřít",
    "tr_logo": "logo",
    "tr_laptop_name": "Název notebooku",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Paměť Intel® Optane™",
    "tr_inchUnit": "palce",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "PAMĚŤ INTEL® OPTANE™",
    "tr_Thunderbolt3": "Technologie Thunderbolt™ 3",
    "tr_Thunderbolt4": "Technologie Thunderbolt™ 4",
    "tr_processorGraphicsCaps": "PROCESOROVÁ GRAFIKA",
    "tr_processorGraphicsSmall": "Procesorová grafika",
    "tr_graphicsCardCaps": "DEDIKOVANÁ GRAFIKA",
    "tr_graphicsCardSmall": "Dedikovaná grafika",
    "tr_processorTitleCamel": "Procesor",
    "tr_graphicTitleCamel": "Grafika",
    "tr_storageTitleCamel": "Úložiště",
    "tr_displayTitleCamel": "Displej",
    "tr_batteryTitleCamel": "Baterie",
    "tr_memoryTitleCamel": "Paměť",
    "tr_connectivityTitleCamel": "Možnosti připojení",
    "tr_priceTitleCamel": "Cena",
    "tr_operatingSystemTitleCamel": "Operační systém",
    "tr_viewallspecsCamel": "Zobrazit všechny specifikace",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "Dotyková obrazovka [screenDiagonal]” [screenResolutionType]",
    "tr_OS": "OPERAČNÍ SYSTÉM",
    "tr_Chrome_OS": "Operační systém Chrome*",
    "tr_PCSpecs_text": "Specifikace počítače",
    "tr_explorePC_text": "Prozkoumejte všechno o tomto počítači",
    "tr_mtlPC_text": "Podívejte se, co „navrženy tak, aby zvládly všechno“ opravdu znamená",
    "tr_optaneMemory": "Paměť Intel®&nbsp;Optane™",
    "tr_displayText_Display": "DISPLEJ [screenDiagonal]\"",
    "tr_displayTextResolution_Display": "DISPLEJ [screenDiagonal]\" [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen_Display": "DOTYKOVÝ DISPLEJ [screenDiagonal]\" [screenResolutionType]",
    "tr_displayTextTouchscreeenText": "DOTYKOVÝ DISPLEJ [screenDiagonal]\"",
    "tr_learnMoreLabel": "Další informace",
    "tr_batteryHoursTextUpperCase": "[XX]&nbsp;HOD.",
    "tr_processorGraphicsIrisXe": "PROCESOR 11. GEN. INTEL® CORE™ [cpu_fam] A GRAFIKA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "PROCESOR 11. GEN. INTEL® CORE™ [cpu_fam] A GRAFIKA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_Thunderbolt3UpperCase": "TECHNOLOGIE THUNDERBOLT™ 3",
    "tr_Thunderbolt4UpperCase": "TECHNOLOGIE THUNDERBOLT™ 4",
    "tr_processorWithArcGraphics": "PROCESOR 12.&nbsp;GENERACE INTEL® CORE™ [cpu_fam] A GRAFIKA INTEL® ARC™",
    "tr_processorGraphicsIrisXe12Gen": "PROCESOR 12. GENERACE INTEL® CORE™ [cpu_fam] A GRAFIKA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "PROCESOR 12. GENERACE INTEL® CORE™ [cpu_fam] A GRAFIKA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "PROCESOR 13.&nbsp;GENERACE INTEL® CORE™ [cpu_fam] A GRAFIKA INTEL® ARC™",
    "tr_processorGraphicsIrisXe13Gen": "PROCESOR 13. GENERACE INTEL® CORE™ [cpu_fam] A GRAFIKA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "PROCESOR 13. GENERACE INTEL® CORE™ [cpu_fam] A GRAFIKA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "GRAFIKA INTEL® ARC™ A350M",
      "tr_A370M": "GRAFIKA INTEL® ARC™ A370M",
      "tr_A730M": "GRAFIKA INTEL® ARC™ A730M",
      "tr_A770M": "GRAFIKA INTEL® ARC™ A770M",
      "tr_A310": "GRAFIKA INTEL® ARC™ A310",
      "tr_A380": "GRAFIKA INTEL® ARC™ A380",
      "tr_A580": "GRAFIKA INTEL® ARC™ A580",
      "tr_A750": "GRAFIKA INTEL® ARC™ A750",
      "tr_A770": "GRAFIKA INTEL® ARC™ A770"
    },
    "tr_memoryValue": "[XX]&nbsp;GB paměti RAM",
    "tr_memoryWithOptaneDesc": "[Value1]&nbsp;GB paměti = [Value2]&nbsp;GB paměti Intel®&nbsp;Optane™ + [Value3]&nbsp;GB paměti RAM",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textGBSSD": "[convertedSSD]&nbsp;GB SSD",
    "tr_textTBSSD": "[convertedSSD]&nbsp;TB SSD",
    "tr_textGBHDD": "[convertedHDD]&nbsp;GB HDD",
    "tr_textTBHDD": "[convertedHDD]&nbsp;TB HDD",
    "tr_textStorageWithOptaneGB": "[XX] GB paměti Intel®&nbsp;Optane™&nbsp;+ [convertedSSD]&nbsp;GB&nbsp;SSD",
    "tr_textStorageWithOptaneTB": "[XX] GB paměti Intel®&nbsp;Optane™&nbsp;+ [convertedSSD]&nbsp;TB&nbsp;SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home v režimu S",
      "tr_OS21": "Windows* 10 Pro v režimu S",
      "tr_OS22": "Windows* 10 Enterprise v režimu S",
      "tr_OS23": "Windows* 11 Home v režimu S",
      "tr_OS24": "Windows* 11 Pro v režimu S",
      "tr_OS25": "Windows* 11 Enterprise v režimu S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Grafika Intel®&nbsp;Arc™&nbsp;A310",
      "tr_GR387": "Grafika Intel®&nbsp;Arc™&nbsp;A350M",
      "tr_GR388": "Grafika Intel®&nbsp;Arc™&nbsp;A370M",
      "tr_GR389": "Grafika Intel®&nbsp;Arc™&nbsp;A380",
      "tr_GR390": "Grafika Intel®&nbsp;Arc™&nbsp;A530M",
      "tr_GR391": "Grafika Intel®&nbsp;Arc™&nbsp;A550M",
      "tr_GR392": "Grafika Intel®&nbsp;Arc™&nbsp;A570M",
      "tr_GR393": "Grafika Intel®&nbsp;Arc™&nbsp;A580",
      "tr_GR394": "Grafika Intel®&nbsp;Arc™&nbsp;A730M",
      "tr_GR395": "Grafika Intel®&nbsp;Arc™&nbsp;A750",
      "tr_GR396": "Grafika Intel®&nbsp;Arc™&nbsp;A770",
      "tr_GR397": "Grafika Intel®&nbsp;Arc™&nbsp;A770M",
      "tr_GR398": "Grafika Intel® Arc™",
      "tr_GR399": "Grafika Intel®&nbsp;Arc™&nbsp;Pro A30M",
      "tr_GR400": "Grafika Intel®&nbsp;Arc™&nbsp;Pro A40/A50",
      "tr_GR401": "Grafika Intel®&nbsp;Arc™&nbsp;Pro A60",
      "tr_GR402": "Grafika Intel®&nbsp;Arc™&nbsp;Pro A60M",
      "tr_GR403": "Grafika Intel®",
      "tr_GR404": "HD grafika Intel®&nbsp;2000",
      "tr_GR405": "HD grafika Intel®&nbsp;2500",
      "tr_GR406": "HD grafika Intel®&nbsp;3000",
      "tr_GR407": "HD grafika Intel®&nbsp;400",
      "tr_GR408": "HD grafika Intel®&nbsp;4000",
      "tr_GR409": "HD grafika Intel®&nbsp;405",
      "tr_GR410": "HD grafika Intel®&nbsp;4200",
      "tr_GR411": "HD grafika Intel®&nbsp;4400",
      "tr_GR412": "HD grafika Intel®&nbsp;4600",
      "tr_GR413": "HD grafika Intel®&nbsp;500",
      "tr_GR414": "HD grafika Intel®&nbsp;5000",
      "tr_GR415": "HD grafika Intel®&nbsp;505",
      "tr_GR416": "HD grafika Intel®&nbsp;510",
      "tr_GR417": "HD grafika Intel®&nbsp;515",
      "tr_GR418": "HD grafika Intel®&nbsp;520",
      "tr_GR419": "HD grafika Intel®&nbsp;530",
      "tr_GR420": "HD grafika Intel®&nbsp;5300",
      "tr_GR421": "HD grafika Intel®&nbsp;5500",
      "tr_GR422": "HD grafika Intel®&nbsp;5600",
      "tr_GR423": "HD grafika Intel®&nbsp;6000",
      "tr_GR424": "HD grafika Intel®&nbsp;610",
      "tr_GR425": "HD grafika Intel®&nbsp;615",
      "tr_GR426": "HD grafika Intel®&nbsp;620",
      "tr_GR427": "HD grafika Intel®&nbsp;630",
      "tr_GR428": "HD grafika Intel®",
      "tr_GR429": "Grafika Intel® Iris® 5100",
      "tr_GR430": "Grafika Intel® Iris® 540",
      "tr_GR431": "Grafika Intel® Iris® 550",
      "tr_GR432": "Grafika Intel® Iris® 6100",
      "tr_GR433": "Grafika Intel® Iris® Plus 640",
      "tr_GR434": "Grafika Intel® Iris® Plus 645",
      "tr_GR435": "Grafika Intel® Iris® Plus 650",
      "tr_GR436": "Grafika Intel® Iris® Plus 655",
      "tr_GR437": "Grafika Intel® Iris® Plus",
      "tr_GR438": "Grafika Intel® Iris® Pro 5200",
      "tr_GR439": "Grafika Intel® Iris® Pro 580",
      "tr_GR440": "Grafika Intel® Iris® Pro 6200",
      "tr_GR441": "Grafika Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "Grafika Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "Grafika Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "Integrovaná grafika Intel®",
      "tr_GR445": "UHD grafika Intel® 600",
      "tr_GR446": "UHD grafika Intel® 605",
      "tr_GR447": "UHD grafika Intel® 610",
      "tr_GR448": "UHD grafika Intel® 615",
      "tr_GR449": "UHD grafika Intel® 617",
      "tr_GR450": "UHD grafika Intel® 620",
      "tr_GR451": "UHD grafika Intel® 630",
      "tr_GR452": "UHD grafika Intel® 710",
      "tr_GR453": "UHD grafika Intel® 730",
      "tr_GR454": "UHD grafika Intel® 770",
      "tr_GR455": "UHD grafika Intel®",
      "tr_GR456": "UHD grafika Intel®",
      "tr_GR457": "UHD grafika Intel®",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 v provedení Max-Q",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti v provedení Max-Q",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 v provedení Max-Q",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 v provedení Max-Q",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 v provedení Max-Q",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 v provedení Max-Q",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti v provedení Max-Q",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti v provedení Max-Q",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 v provedení Max-Q",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 v provedení Max-Q",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super v provedení Max-Q",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 v provedení Max-Q",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super v provedení Max-Q",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 v provedení Max-Q",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 v provedení Max-Q",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 v provedení Max-Q",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 v provedení Max-Q",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 v provedení Max-Q",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 v provedení Max-Q",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 v provedení Max-Q",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 v provedení Max-Q",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 v provedení Max-Q",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 v provedení Max-Q",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "Grafika Intel® Arc™",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Kupte nyní",
    "tr_textTryagain": "Zkuste to znovu",
    "tr_textBuynowErr": "Z důvodu přerušení připojení není možnost „Kupte nyní“ k dispozici",
    "tr_chatbotPlaceholder_text": "Zeptejte se mě na cokoli o tomto zařízení",
    "tr_deviceSpecs_text": "Specifikace zařízení"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 s&nbsp;technologií Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 s&nbsp;technologií Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Vybavte se technologií Intel® pro mocnější herní zážitky",
                "tr_subtitle": "Avengers* od Marvelu ožívají díky optimalizacím počítače používající Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Zažijte Intel® Extreme Masters (IEM)",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Místo, kde se utkávají nejlepší hráči na světě",
                "tr_subtitle": "Intel® Extreme Masters, nejdéle trvající turnaj v historii videoher, udává standardy pro elektronické sporty.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Hrajte jako profesionál",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Šampioni používají technologie Intel.",
                "tr_subtitle": "Tým Soniqs se po vítězství v&nbsp;šampionátu 2021 North American League ve hře Rainbow Six Siege* zamýšlí nad svou herní historií, budoucností e-sportu a partnerstvím se společností Intel.",
                "tr_pretitle": "Představujeme tým Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Připraveno pro virtuální realitu",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Když virtuální realita ožije",
                "tr_subtitle": "Nejúžasnější, nejvíce realistické a&nbsp;pohlcující zážitky z&nbsp;virtuální reality zažijete na počítači. S&nbsp;nejnovějšími procesory Intel® Core™ se můžete stát kýmkoli, jít kamkoli a&nbsp;zažít hru v&nbsp;jiné dimenzi.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Hrajte více než 100 vysoce kvalitních konzolových her, jako je Sea of Thieves* a Psychonauts* 2, na počítači se systémem Windows*, mobilním telefonu nebo tabletu s&nbsp;předplatným Xbox* Game Pass Ultimate a kompatibilním ovladačem.",
                "titleArray": [
                  {
                    "tr_title": "Hrajte společně na různých zařízeních",
                    "tr_subtitle": "Vstupte do světa Xbox* s&nbsp;komunitou milionů hráčů, kteří jsou připravení a čekají, až si společně zahrají. Připojte se a hrajte s&nbsp;ostatními hry ze sdílené knihovny, ať jsou na druhém konci světa nebo sedí hned vedle vás."
                  },
                  {
                    "tr_title": "Hraní na počítači se systémem Windows*",
                    "tr_subtitle": "Hrajte konzolové hry z&nbsp;katalogu v&nbsp;cloudu pomocí aplikace Xbox* pro Windows* a kompatibilního ovladače."
                  },
                  {
                    "tr_title": "Vyberte si a hrajte",
                    "tr_subtitle": "Vychutnávejte si skvělé hry pro Xbox* na více místech než předtím. Hrajte více než 100 her na počítači, mobilním telefonu nebo tabletu, ať už jste doma a na televizi se dívá někdo jiný, v&nbsp;kavárně nebo kdekoli jinde s&nbsp;vysokorychlostním připojením k&nbsp;internetu."
                  },
                  {
                    "tr_title": "Zábava bez ovladače",
                    "tr_subtitle": "Dotykové ovládání her Xbox* zpřístupňuje nové způsoby hraní, poskytuje dobře známý zážitek a umožňuje hrát tak, jak jste zvyklí s&nbsp;fyzickým ovladačem."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Zajímavosti",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Víc než 100 zážitků s AI",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Míň dotyků, víc ovládání",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Buďte v záběru, ať jste kdekoli",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativita",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Úprava osvětlení snímků na profesionální úrovni",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Snadno a rychle přidávejte velkolepé efekty",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Využijte umělou inteligenci k remixování písniček",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivita",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Lepší zabezpečení, vyšší produktivita",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Jde to i bez zásuvky",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Váš svět. Na vašich zařízeních.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Zábava",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Plynulé streamování i při provozu na baterii",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Plynulý a pohlcující zážitek ze hry",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Zajímavosti",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Víc než 100 zážitků s AI",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Vytvořte si vlastní verzi svojí oblíbené písničky",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Dejte svým představám okamžitý tvar",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativita",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Nejrychlejší cesta k poslednímu střihu",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Ať je každá fotka dokonalá",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivita",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Lepší zabezpečení, vyšší produktivita",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Lepší energetická efektivita. Větší svoboda.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Váš svět. Na vašich zařízeních.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Zábava",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Plynulé streamování v HD rozlišení i při provozu na baterii",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Pohlcující hraní na více místech",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Kreativnější",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Zjednodušení úpravy fotografií",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Fotky ve výstavní kvalitě už za pár vteřin",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Vy napíšete, co chcete. AI to vytvoří.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Vyšší výkon",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ultimátní cheat pro plynulejší hraní",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Jde to i bez zásuvky",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Dokonale pohlcující",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivnější",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Váš každodenní společník s AI",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Videohovory bez chybičky",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Váš svět. Na vašich zařízeních.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lepší zabezpečení",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Integrované ochranné prvky udržují vaše data soukromá a v bezpečí",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Kreativnější",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Zjednodušení úpravy fotografií",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI. Vaše zkratka k finálnímu střihu.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Rychlé přepisy textů vašich oblíbených písniček",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Díky AI si ušetříte dlouhé hodiny úprav",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Vyšší výkon",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ultimátní cheat pro plynulejší hraní",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Jde to i bez zásuvky",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Dokonale pohlcující",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivnější",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Váš každodenní společník s AI",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Videohovory bez chybičky",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Váš svět. Na vašich zařízeních.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lepší zabezpečení",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Integrované ochranné prvky udržují vaše data soukromá a v bezpečí",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Veškerá zábava. Jediný počítač.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "U&nbsp;počítače, který slouží i&nbsp;jako centrum zábavy, potřebujete dostatečně velké úložiště, které pojme všechny vaše oblíbené skladby, videa a&nbsp;hry. Doplnit ho musí správná kombinace procesoru a&nbsp;obrazovky, abyste ze všeho měli působivý vizuální zážitek. Tento počítač splňuje všechny tři tyto požadavky.",
                "titleArray": [
                  {
                    "tr_title": "Připraveno na grafiku nové generace",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vytvořte si vlastní digitální knihovnu",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Obrazovka plná zábavy",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Plynulé hraní her a streamování",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vytvořte si vlastní digitální knihovnu",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Obrazovka plná zábavy",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Dosáhněte vyšší produktivity. Kdekoli.",
              "tr_tileTitle1": "Stihnete toho víc. Rychle.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tento počítač je skvěle vyvážen, co se týče výkonu a&nbsp;přenosnosti. Kromě procesoru určeného pro tenká a&nbsp;lehká provedení obsahuje tento počítač i&nbsp;připojení Wi&#8209;Fi, které vám pomůže zůstat stále online, a&nbsp;baterii, díky níž si nemusíte dělat starosti, zda najdete zásuvku.",
                "tr_subtitle1": "Výkon tohoto počítače je dokonale vyvážený, takže stihnete udělat víc za kratší dobu. Kombinace robustního procesoru, velké paměti a&nbsp;bleskové Wi-Fi vám zjednoduší každodenní práci.",
                "tr_subtitle2": "Tento počítač je skvěle vyvážen, co se týče výkonu a&nbsp;přenosnosti. Kromě procesoru určeného pro tenká a&nbsp;lehká provedení obsahuje tento počítač i&nbsp;připojení Wi‑Fi, které vám pomůže zůstat stále online, a&nbsp;dostatek paměti, abyste mohli provádět více úloh najednou.",
                "titleArray": [
                  {
                    "tr_title": "Abyste zvládli víc",
                    "tr_title1": "Inteligentní výkon, na kterém záleží",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Nejlepší připojení ve&nbsp;své&nbsp;třídě",
                    "tr_title1": "Připojení, na kterém záleží",
                    "tr_title2": "Velký pokrok v&nbsp;technologii Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Delší výdrž bez napájení",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Žonglujte s&nbsp;programy jako profesionál",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tvůrčí svoboda",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Vytváření obsahu je pro váš počítač jednou z&nbsp;nejnáročnějších úloh. Tyto&nbsp;komponenty vám naštěstí tvůrčí proces značně ulehčí.",
                "titleArray": [
                  {
                    "tr_title": "Chytřejší způsob vytváření obsahu",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vdechněte obrazům život",
                    "tr_title1": "Pojďme tvořit",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Paměť pro zpracování více úloh najednou",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Nahlédněte do tohoto počítače",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Toto jsou tři hlavní komponenty, na něž byste se měli při výběru nového počítače zaměřit. Když víte, jakým způsobem budete nový počítač používat, jste na dobré cestě i&nbsp;co se týče rozhodování, co všechno od těchto tří hlavních komponent budete potřebovat. Jsou to:",
                "titleArray": [
                  {
                    "tr_title": "Výkon pro hraní her a streamování",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Úložiště",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Paměť",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Veškerá zábava. Jediný počítač.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "U&nbsp;počítače, který slouží i&nbsp;jako centrum zábavy, potřebujete dostatečně velké úložiště, které pojme všechny vaše oblíbené skladby, videa a&nbsp;hry. Doplnit ho musí správná kombinace procesoru a&nbsp;obrazovky, abyste ze všeho měli působivý vizuální zážitek. Tento počítač splňuje všechny tři tyto požadavky.",
                "titleArray": [
                  {
                    "tr_title": "Připraveno na grafiku nové generace",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vytvořte si vlastní digitální knihovnu",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Obrazovka plná zábavy",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Plynulé hraní her a streamování",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vytvořte si vlastní digitální knihovnu",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Obrazovka plná zábavy",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Paměť",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Displej",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Rychlé zpracování více úloh",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "RYCHLÉ ZPRACOVÁNÍ VÍCE ÚLOH",
                "tr_subtitle": "Zvládněte více bez zpomalení",
                "tr_subtitle1": "Zde je to, co jsme našli v tomto počítači, co je skvělé pro multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který dokáže zpracovávat několik programů najednou."
                  },
                  {
                    "tr_title": "Wi‑Fi, které je rychlé, silné a spolehlivé."
                  },
                  {
                    "tr_title": "Paměť, která plynule přepíná mezi aplikacemi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videokonverzace",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOKONVERZACE",
                "tr_subtitle": "Křišťálově čisté připojení",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělé videochaty:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který dokáže nahrávat a zároveň sdílet obraz."
                  },
                  {
                    "tr_title": "Wi‑Fi, které je rychlé, silné a spolehlivé."
                  },
                  {
                    "tr_title": "Webová kamera, která má křišťálově čistý obraz."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Připraveno pro virtuální realitu",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Když virtuální realita ožije",
                "tr_subtitle": "Co obnáší vytvoření světa, který je zcela virtuální? Na počítači k&nbsp;tomu potřebujete koordinaci mezi procesorem, grafickou kartou a&nbsp;pamětí.",
                "titleArray": [
                  {
                    "tr_title": "Pohánění virtuálních světů",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Realita spočívá v&nbsp;detailech",
                    "tr_title1": "Nechte se pohltit",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Úprava videa",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ÚPRAVA VIDEA",
                "tr_subtitle": "Vytvářejte filmová kouzla",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělé úpravy videa:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který si poradí s&nbsp;velkými soubory."
                  },
                  {
                    "tr_title": "Paměť, díky které je střihový software rychlý a pohotový."
                  },
                  {
                    "tr_title": "Grafika, která zkrátí dobu vykreslování."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Rychlé zpracování více úloh",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "RYCHLÉ ZPRACOVÁNÍ VÍCE ÚLOH",
                "tr_subtitle": "Zvládněte více bez zpomalení",
                "tr_subtitle1": "Zde je to, co jsme našli v tomto počítači, co je skvělé pro multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který dokáže zpracovávat několik programů najednou."
                  },
                  {
                    "tr_title": "Wi‑Fi, které je rychlé, silné a spolehlivé."
                  },
                  {
                    "tr_title": "Paměť, která plynule přepíná mezi aplikacemi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Připraveno pro virtuální realitu",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Když virtuální realita ožije",
                "tr_subtitle": "Co obnáší vytvoření světa, který je zcela virtuální? Na počítači k&nbsp;tomu potřebujete koordinaci mezi procesorem, grafickou kartou a&nbsp;pamětí.",
                "titleArray": [
                  {
                    "tr_title": "Pohánění virtuálních světů",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Realita spočívá v&nbsp;detailech",
                    "tr_title1": "Nechte se pohltit",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D modelování",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D MODELOVÁNÍ",
                "tr_subtitle": "Výtvory nové úrovně",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělé 3D modelování:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který si poradí s&nbsp;pokročilým kreativním softwarem."
                  },
                  {
                    "tr_title": "Paměť, která dokáže rychle vyvolat velké soubory."
                  },
                  {
                    "tr_title": "Grafika pro výkonné vytváření a úpravy obrázků."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Spolupráce v&nbsp;reálném čase",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SPOLUPRÁCE V&nbsp;REÁLNÉM ČASE",
                "tr_subtitle": "Pracujte odkudkoli",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělou spolupráci na internetu:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Procesor umožňující používat kancelářský software."
                  },
                  {
                    "tr_title": "Wi‑Fi, na které se můžete spolehnout."
                  },
                  {
                    "tr_title": "Webová kamera, která nahrává ve skvělém rozlišení HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Připraveno pro virtuální realitu",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Když virtuální realita ožije",
                "tr_subtitle": "Co obnáší vytvoření světa, který je zcela virtuální? Na počítači k&nbsp;tomu potřebujete koordinaci mezi procesorem, grafickou kartou a&nbsp;pamětí.",
                "titleArray": [
                  {
                    "tr_title": "Pohánění virtuálních světů",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Realita spočívá v&nbsp;detailech",
                    "tr_title1": "Nechte se pohltit",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Notebooky Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tak tenké a lehké notebooky by neměly být tak výkonné",
                "tr_subtitle": "Notebooky Intel® Evo™ jsou navrženy pro mobilní použití, poskytují rychlost, grafiku a výdrž na baterii, jakou potřebujete. To&nbsp;vše&nbsp;v&nbsp;těch nejúžasnějších tenkých a lehkých provedeních.",
                "titleArray": [
                  {
                    "tr_title": "Notebook je důležitější než kdy jindy, takže jsme se ujistili, že toho dokáže udělat více než kdy jindy.",
                    "tr_subtitle": "Pomocí dotykové obrazovky můžete prozkoumávat, otáčet a přibližovat."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Bleskurychlé probuzení",
                    "tr_subtitle": "Díky okamžitému probuzení se počítač připraví&nbsp;z&nbsp;režimu spánku za méně než sekundu."
                  },
                  {
                    "tr_title": "Působivý obraz",
                    "tr_subtitle": "Na nádherném displeji obraz ožije v&nbsp;ostrém&nbsp;rozlišení a živých barvách."
                  },
                  {
                    "tr_title": "Rychlé dokončení veškeré práce",
                    "tr_subtitle": "Užívejte si mimořádně rychlou odezvu&nbsp;procesorů 11.&nbsp;generace Intel®&nbsp;Core™."
                  },
                  {
                    "tr_title": "Delší výdrž na baterii",
                    "tr_subtitle": "Nenechte se vytrhnout ze soustředění s&nbsp;počítačem, který optimalizuje výdrž na baterii a&nbsp;rychle se nabíjí."
                  },
                  {
                    "tr_title": "Univerzální připojovací kabel",
                    "tr_subtitle": "S technologií Thunderbolt™ 4 je připojení dalších zařízení k&nbsp;počítači jednoduché, rychlé a&nbsp;bezpečné.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Rychlé, spolehlivé připojení",
                    "tr_subtitle": "Uskutečňujte videohovory nebo sdílejte soubory pomocí připojení Intel®&nbsp;Wi‑Fi&nbsp;6&nbsp;(Gig+), na které se můžete spolehnout.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Notebooky Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Vše, co potřebujete, a ještě víc v&nbsp;tenkém a lehkém notebooku.",
                "tr_subtitle": "Notebooky Intel® Evo™ jsou navrženy pro mobilní výkon a poskytnou vám ty nejlepší možnosti<sup>2</sup>, ať děláte cokoli.",
                "titleArray": [
                  {
                    "tr_title": "Notebook je důležitější než kdy jindy, takže jsme se ujistili, že toho dokáže udělat více než kdy jindy.",
                    "tr_subtitle": "Pomocí dotykové obrazovky můžete prozkoumávat, otáčet a přibližovat."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Bleskurychlé probuzení",
                    "tr_subtitle": "Díky okamžitému probuzení se počítač připraví&nbsp;z&nbsp;režimu spánku za méně než sekundu."
                  },
                  {
                    "tr_title": "Působivý obraz",
                    "tr_subtitle": "Na nádherném displeji obraz ožije v&nbsp;ostrém&nbsp;rozlišení a živých barvách."
                  },
                  {
                    "tr_title": "Revoluční výkon",
                    "tr_subtitle": "Získejte vyšší výkon tam, kde ho potřebujete nejvíc, díky inteligentnímu výkonu procesorů 12.&nbsp;generace Intel®&nbsp;Core™."
                  },
                  {
                    "tr_title": "Delší výdrž na baterii",
                    "tr_subtitle": "Nenechte se vytrhnout ze soustředění s&nbsp;počítačem, který optimalizuje výdrž na baterii a&nbsp;rychle se nabíjí."
                  },
                  {
                    "tr_title": "Univerzální připojovací kabel",
                    "tr_subtitle": "S technologií Thunderbolt™ 4 je připojení dalších zařízení k&nbsp;počítači jednoduché, rychlé a&nbsp;bezpečné.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Rychlé, spolehlivé připojení",
                    "tr_subtitle": "Uskutečňujte videohovory nebo sdílejte soubory pomocí připojení Intel® Wi-Fi 6E (Gig+), na které se můžete spolehnout.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Notebooky Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tak tenké a lehké notebooky by neměly být tak výkonné",
                "tr_subtitle": "Notebooky Intel® Evo™ jsou navrženy pro mobilní použití, poskytují rychlost, grafiku a výdrž na baterii, jakou potřebujete. To&nbsp;vše&nbsp;v&nbsp;těch nejúžasnějších tenkých a lehkých provedeních.",
                "titleArray": [
                  {
                    "tr_title": "Notebook je důležitější než kdy jindy, takže jsme se ujistili, že toho dokáže udělat více než kdy jindy.",
                    "tr_subtitle": "Pomocí dotykové obrazovky můžete prozkoumávat, otáčet a přibližovat."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Bleskurychlé probuzení",
                    "tr_subtitle": "Díky okamžitému probuzení se počítač připraví&nbsp;z&nbsp;režimu spánku za méně než sekundu."
                  },
                  {
                    "tr_title": "Působivý obraz",
                    "tr_subtitle": "Na nádherném displeji obraz ožije v&nbsp;ostrém&nbsp;rozlišení a živých barvách."
                  },
                  {
                    "tr_title": "Rychlé dokončení veškeré práce",
                    "tr_subtitle": "Užívejte si mimořádně rychlou odezvu&nbsp;procesorů 11.&nbsp;generace Intel®&nbsp;Core™."
                  },
                  {
                    "tr_title": "Delší výdrž na baterii",
                    "tr_subtitle": "Nenechte se vytrhnout ze soustředění s&nbsp;počítačem, který optimalizuje výdrž na baterii a&nbsp;rychle se nabíjí."
                  },
                  {
                    "tr_title": "Univerzální připojovací kabel",
                    "tr_subtitle": "S technologií Thunderbolt™ 4 je připojení dalších zařízení k&nbsp;počítači jednoduché, rychlé a&nbsp;bezpečné.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Rychlé, spolehlivé připojení",
                    "tr_subtitle": "Uskutečňujte videohovory nebo sdílejte soubory pomocí připojení Intel®&nbsp;Wi‑Fi&nbsp;6&nbsp;(Gig+), na které se můžete spolehnout.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Notebooky Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Vše, co potřebujete, a ještě víc v&nbsp;tenkém a lehkém notebooku.",
                "tr_subtitle": "Notebooky Intel® Evo™ jsou navrženy pro mobilní výkon a poskytnou vám ty nejlepší možnosti<sup>2</sup>, ať děláte cokoli.",
                "titleArray": [
                  {
                    "tr_title": "Notebook je důležitější než kdy jindy, takže jsme se ujistili, že toho dokáže udělat více než kdy jindy.",
                    "tr_subtitle": "Pomocí dotykové obrazovky můžete prozkoumávat, otáčet a přibližovat."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Bleskurychlé probuzení",
                    "tr_subtitle": "Díky okamžitému probuzení se počítač připraví&nbsp;z&nbsp;režimu spánku za méně než sekundu."
                  },
                  {
                    "tr_title": "Působivý obraz",
                    "tr_subtitle": "Na nádherném displeji obraz ožije v&nbsp;ostrém&nbsp;rozlišení a živých barvách."
                  },
                  {
                    "tr_title": "Revoluční výkon",
                    "tr_subtitle": "Získejte vyšší výkon tam, kde ho potřebujete nejvíc, díky inteligentnímu výkonu procesorů 12.&nbsp;generace Intel®&nbsp;Core™."
                  },
                  {
                    "tr_title": "Delší výdrž na baterii",
                    "tr_subtitle": "Nenechte se vytrhnout ze soustředění s&nbsp;počítačem, který optimalizuje výdrž na baterii a&nbsp;rychle se nabíjí."
                  },
                  {
                    "tr_title": "Univerzální připojovací kabel",
                    "tr_subtitle": "S technologií Thunderbolt™ 4 je připojení dalších zařízení k&nbsp;počítači jednoduché, rychlé a&nbsp;bezpečné.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Rychlé, spolehlivé připojení",
                    "tr_subtitle": "Uskutečňujte videohovory nebo sdílejte soubory pomocí připojení Intel® Wi-Fi 6E (Gig+), na které se můžete spolehnout.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Notebooky Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Vše, co potřebujete, a ještě víc v&nbsp;tenkém a lehkém notebooku.",
                "tr_subtitle": "Notebooky Intel® Evo™ jsou navrženy pro mobilní výkon a poskytnou vám ty nejlepší možnosti<sup>2</sup>, ať děláte cokoli.",
                "titleArray": [
                  {
                    "tr_title": "Notebook je důležitější než kdy jindy, takže jsme se ujistili, že toho dokáže udělat více než kdy jindy.",
                    "tr_subtitle": "Pomocí dotykové obrazovky můžete prozkoumávat, otáčet a přibližovat."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Bleskurychlé probuzení",
                    "tr_subtitle": "Díky okamžitému probuzení se počítač připraví&nbsp;z&nbsp;režimu spánku za méně než sekundu."
                  },
                  {
                    "tr_title": "Působivý obraz",
                    "tr_subtitle": "Na nádherném displeji obraz ožije v&nbsp;ostrém&nbsp;rozlišení a živých barvách."
                  },
                  {
                    "tr_title": "Revoluční výkon",
                    "tr_subtitle": "Získejte vyšší výkon tam, kde ho potřebujete nejvíc, díky inteligentnímu výkonu procesorů 13.&nbsp;generace Intel®&nbsp;Core™."
                  },
                  {
                    "tr_title": "Delší výdrž na baterii",
                    "tr_subtitle": "Nenechte se vytrhnout ze soustředění s&nbsp;počítačem, který optimalizuje výdrž na baterii a&nbsp;rychle se nabíjí."
                  },
                  {
                    "tr_title": "Univerzální připojovací kabel",
                    "tr_subtitle": "S technologií Thunderbolt™ 4 je připojení dalších zařízení k&nbsp;počítači jednoduché, rychlé a&nbsp;bezpečné.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Rychlé, spolehlivé připojení",
                    "tr_subtitle": "Uskutečňujte videohovory nebo sdílejte soubory pomocí připojení Intel® Wi-Fi 6E (Gig+), na které se můžete spolehnout.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Notebooky Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Vše, co potřebujete, a ještě víc v&nbsp;tenkém a lehkém notebooku.",
                "tr_subtitle": "Notebooky Intel® Evo™ jsou navrženy pro mobilní výkon a poskytnou vám ty nejlepší možnosti<sup>2</sup>, ať děláte cokoli.",
                "titleArray": [
                  {
                    "tr_title": "Notebook je důležitější než kdy jindy, takže jsme se ujistili, že toho dokáže udělat více než kdy jindy.",
                    "tr_subtitle": "Pomocí dotykové obrazovky můžete prozkoumávat, otáčet a přibližovat."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Bleskurychlé probuzení",
                    "tr_subtitle": "Díky okamžitému probuzení se počítač připraví&nbsp;z&nbsp;režimu spánku za méně než sekundu."
                  },
                  {
                    "tr_title": "Působivý obraz",
                    "tr_subtitle": "Na nádherném displeji obraz ožije v&nbsp;ostrém&nbsp;rozlišení a živých barvách."
                  },
                  {
                    "tr_title": "Revoluční výkon",
                    "tr_subtitle": "Získejte vyšší výkon tam, kde ho potřebujete nejvíc, díky inteligentnímu výkonu procesorů 13.&nbsp;generace Intel®&nbsp;Core™."
                  },
                  {
                    "tr_title": "Delší výdrž na baterii",
                    "tr_subtitle": "Nenechte se vytrhnout ze soustředění s&nbsp;počítačem, který optimalizuje výdrž na baterii a&nbsp;rychle se nabíjí."
                  },
                  {
                    "tr_title": "Univerzální připojovací kabel",
                    "tr_subtitle": "S technologií Thunderbolt™ 4 je připojení dalších zařízení k&nbsp;počítači jednoduché, rychlé a&nbsp;bezpečné.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Rychlé, spolehlivé připojení",
                    "tr_subtitle": "Uskutečňujte videohovory nebo sdílejte soubory pomocí připojení Intel® Wi-Fi 6E (Gig+), na které se můžete spolehnout.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Zajímavosti",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Co se skrývá pod Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Proč Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativita",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Buďte kreativnější na více místech",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Úprava fotografií v plné rychlosti",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Vytvářejte originální umění sami a bez čekání",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivita",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximalizujte produktivitu pomocí aplikace Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Odhalte deepfake videa pomocí umělé inteligence dříve, než je začnete sdílet",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Váš svět. Na vašich zařízeních.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilita",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ovládání gesty je tady",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Výdrž na baterii, která vás nelimituje",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Automaticky vás udržuje ve spojení",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Zajímavosti",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Co se skrývá pod Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Proč Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativita",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rychlé a plynulé přehrávání videa",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Zapřáhněte umělou inteligenci a vytvářejte vlastní hity",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Nejrychlejší cesta k poslednímu střihu",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivita",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximalizujte produktivitu pomocí aplikace Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Odhalte deepfake videa pomocí umělé inteligence dříve, než je začnete sdílet",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Váš svět. Na vašich zařízeních.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilita",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Zapojte se do týmu prakticky odkudkoli",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Výdrž na baterii, která vás nelimituje",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Automaticky vás udržuje ve spojení",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Veškerá zábava. Jediný počítač.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "U&nbsp;počítače, který slouží i&nbsp;jako centrum zábavy, potřebujete dostatečně velké úložiště, které pojme všechny vaše oblíbené skladby, videa a&nbsp;hry. Doplnit ho musí správná kombinace procesoru a&nbsp;obrazovky, abyste ze všeho měli působivý vizuální zážitek. Tento počítač splňuje všechny tři tyto požadavky.",
                "titleArray": [
                  {
                    "tr_title": "Hraní her a streamování s&nbsp;působivým obrazem",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vytvořte si vlastní digitální knihovnu",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Obrazovka plná zábavy",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Plynulé hraní her a streamování",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vytvořte si vlastní digitální knihovnu",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Obrazovka plná zábavy",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Dosáhněte vyšší produktivity. Kdekoli.",
              "tr_tileTitle1": "Stihnete toho víc. Rychle.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tento počítač je skvěle vyvážen, co se týče výkonu a&nbsp;přenosnosti. Kromě procesoru určeného pro tenká a&nbsp;lehká provedení obsahuje tento počítač i&nbsp;připojení Wi&#8209;Fi, které vám pomůže zůstat stále online, a&nbsp;baterii, díky níž si nemusíte dělat starosti, zda najdete zásuvku.",
                "tr_subtitle1": "Výkon tohoto počítače je dokonale vyvážený, takže stihnete udělat víc za kratší dobu. Kombinace robustního procesoru, velké paměti a&nbsp;bleskové Wi-Fi vám zjednoduší každodenní práci.",
                "tr_subtitle2": "Tento počítač je skvěle vyvážen, co se týče výkonu a&nbsp;přenosnosti. Kromě procesoru určeného pro tenká a&nbsp;lehká provedení obsahuje tento počítač i&nbsp;připojení Wi‑Fi, které vám pomůže zůstat stále online, a&nbsp;dostatek paměti, abyste mohli provádět více úloh najednou.",
                "titleArray": [
                  {
                    "tr_title": "Inteligentní výkon, na kterém záleží",
                    "tr_title1": "Zrychlete díky inteligentnímu výkonu",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Nejlepší připojení ve&nbsp;své&nbsp;třídě",
                    "tr_title1": "Připojení, na kterém záleží",
                    "tr_title2": "Velký pokrok v&nbsp;technologii Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Delší výdrž bez napájení",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Žonglujte s&nbsp;programy jako profesionál",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tvůrčí svoboda",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Vytváření obsahu je pro váš počítač jednou z&nbsp;nejnáročnějších úloh. Tyto&nbsp;komponenty vám naštěstí tvůrčí proces značně ulehčí.",
                "titleArray": [
                  {
                    "tr_title": "Rychlejší kreativita",
                    "tr_title1": "Zvládejte hladce kreativní projekty",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vylepšené vytváření obsahu",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Paměť pro zpracování více úloh najednou",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Nahlédněte do tohoto počítače",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Toto jsou tři hlavní komponenty, na něž byste se měli při výběru nového počítače zaměřit. Když víte, jakým způsobem budete nový počítač používat, jste na dobré cestě i&nbsp;co se týče rozhodování, co všechno od těchto tří hlavních komponent budete potřebovat. Jsou to:",
                "titleArray": [
                  {
                    "tr_title": "Procesor",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Úložiště",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Paměť",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Tvůrčí svoboda",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Vytváření obsahu je pro váš počítač jednou z&nbsp;nejnáročnějších úloh. Tyto&nbsp;komponenty vám naštěstí tvůrčí proces značně ulehčí.",
                "titleArray": [
                  {
                    "tr_title": "Chytřejší způsob vytváření obsahu",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vdechněte obrazům život",
                    "tr_title1": "Pojďme tvořit",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Paměť pro zpracování více úloh najednou",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Dosáhněte vyšší produktivity. Kdekoli.",
              "tr_tileTitle1": "Stihnete toho víc. Rychle.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tento počítač je skvěle vyvážen, co se týče výkonu a&nbsp;přenosnosti. Kromě procesoru určeného pro tenká a&nbsp;lehká provedení obsahuje tento počítač i&nbsp;připojení Wi&#8209;Fi, které vám pomůže zůstat stále online, a&nbsp;baterii, díky níž si nemusíte dělat starosti, zda najdete zásuvku.",
                "tr_subtitle1": "Výkon tohoto počítače je dokonale vyvážený, takže stihnete udělat víc za kratší dobu. Kombinace robustního procesoru, velké paměti a&nbsp;bleskové Wi-Fi vám zjednoduší každodenní práci.",
                "tr_subtitle2": "Tento počítač je skvěle vyvážen, co se týče výkonu a&nbsp;přenosnosti. Kromě procesoru určeného pro tenká a&nbsp;lehká provedení obsahuje tento počítač i&nbsp;připojení Wi‑Fi, které vám pomůže zůstat stále online, a&nbsp;dostatek paměti, abyste mohli provádět více úloh najednou.",
                "titleArray": [
                  {
                    "tr_title": "Abyste zvládli víc",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Nejlepší připojení ve&nbsp;své&nbsp;třídě",
                    "tr_title1": "Připojení, na kterém záleží",
                    "tr_title2": "Velký pokrok v&nbsp;technologii Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Delší výdrž bez napájení",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Žonglujte s&nbsp;programy jako profesionál",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Připraveno pro virtuální realitu",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Když virtuální realita ožije",
                "tr_subtitle": "Co obnáší vytvoření světa, který je zcela virtuální? Na počítači k&nbsp;tomu potřebujete koordinaci mezi procesorem, grafickou kartou a&nbsp;pamětí.",
                "titleArray": [
                  {
                    "tr_title": "Pohánění virtuálních světů",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Realita spočívá v&nbsp;detailech",
                    "tr_title1": "Nechte se pohltit",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>Pohled pro bolavé oči",
      "tr_sub_header": "Modré světlo může způsobovat zrakové nepohodlí a narušovat váš spánkový cyklus. Displej OLED společnosti Samsung, který prošel testem SGS Eye Care, nepomáhá změnou barev, ale snížením vlnové délky potenciálně škodlivého modrého světla, čímž poskytuje očím pohodlí a snižuje jejich únavu.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Méně škodlivé modré světlo"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra ve všech ohledech",
      "tr_sub_header": "Ultralehká a ultratenká konstrukce displeje OLED maximalizuje kvalitu obrazu a zároveň minimalizuje hmotnost."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Skutečná černá",
      "tr_sub_header": "0,0005 nitu"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74&nbsp;%<br/><span class='conventional_text'>Běžný displej</span>",
      "tr_sub_header": "120&nbsp;%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streamování",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMOVÁNÍ",
                "tr_subtitle": "Nekonečná zábava",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělé streamování:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který dokáže streamovat v&nbsp;rozlišení HD."
                  },
                  {
                    "tr_title": "Displej pro krásný a jasný obraz."
                  },
                  {
                    "tr_title": "Wi‑Fi, které umožňuje méně čekání a rychlejší načítání."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Prohlížení webu",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PROHLÍŽENÍ WEBU",
                "tr_subtitle": "Snadný internet",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělé prohlížení webu:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který dokáže rychle načíst multimediální stránky."
                  },
                  {
                    "tr_title": "Rychlé a spolehlivé Wi‑Fi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videokonverzace",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOKONVERZACE",
                "tr_subtitle": "Křišťálově čisté připojení",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělé videochaty:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který dokáže nahrávat a zároveň sdílet obraz."
                  },
                  {
                    "tr_title": "Wi‑Fi, které je rychlé, silné a spolehlivé."
                  },
                  {
                    "tr_title": "Webová kamera, která má křišťálově čistý obraz."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Rychlé zpracování více úloh",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "RYCHLÉ ZPRACOVÁNÍ VÍCE ÚLOH",
                "tr_subtitle": "Zvládněte více bez zpomalení",
                "tr_subtitle1": "Zde je to, co jsme našli v tomto počítači, co je skvělé pro multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který dokáže zpracovávat několik programů najednou."
                  },
                  {
                    "tr_title": "Wi‑Fi, které je rychlé, silné a spolehlivé."
                  },
                  {
                    "tr_title": "Paměť, která plynule přepíná mezi aplikacemi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videokonverzace",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOKONVERZACE",
                "tr_subtitle": "Křišťálově čisté připojení",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělé videochaty:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který dokáže nahrávat a zároveň sdílet obraz."
                  },
                  {
                    "tr_title": "Wi‑Fi, které je rychlé, silné a spolehlivé."
                  },
                  {
                    "tr_title": "Webová kamera, která má křišťálově čistý obraz."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hraní běžných her",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "HRANÍ BĚŽNÝCH HER",
                "tr_subtitle": "Je čas na hraní",
                "tr_subtitle1": "Co tento počítač obsahuje pro hraní nenáročných her:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Procesor umožňující plynulé hraní."
                  },
                  {
                    "tr_title": "Paměť, která zvládne hlasový chat a streamování zároveň."
                  },
                  {
                    "tr_title": "Grafika, která nabízí úžasný obraz."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Úprava fotografií",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ÚPRAVA FOTOGRAFIÍ",
                "tr_subtitle": "Dokonalé fotografie",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělé úpravy fotografií:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Procesor s&nbsp;výkonem pro úpravy velkých skupin snímků."
                  },
                  {
                    "tr_title": "Paměť, která urychluje práci s&nbsp;grafickým softwarem."
                  },
                  {
                    "tr_title": "Technologie Thunderbolt™ 4 umožňuje rychlé přenášení souborů."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Rychlé zpracování více úloh",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "RYCHLÉ ZPRACOVÁNÍ VÍCE ÚLOH",
                "tr_subtitle": "Zvládněte více bez zpomalení",
                "tr_subtitle1": "Zde je to, co jsme našli v tomto počítači, co je skvělé pro multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který dokáže zpracovávat několik programů najednou."
                  },
                  {
                    "tr_title": "Wi‑Fi, které je rychlé, silné a spolehlivé."
                  },
                  {
                    "tr_title": "Paměť, která plynule přepíná mezi aplikacemi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Spolupráce v&nbsp;reálném čase",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SPOLUPRÁCE V&nbsp;REÁLNÉM ČASE",
                "tr_subtitle": "Pracujte odkudkoli",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělou spolupráci na internetu:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Procesor umožňující používat kancelářský software."
                  },
                  {
                    "tr_title": "Wi‑Fi, na které se můžete spolehnout."
                  },
                  {
                    "tr_title": "Webová kamera, která nahrává ve skvělém rozlišení HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Úprava videa",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ÚPRAVA VIDEA",
                "tr_subtitle": "Vytvářejte filmová kouzla",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělé úpravy videa:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který si poradí s&nbsp;velkými soubory."
                  },
                  {
                    "tr_title": "Paměť, díky které je střihový software rychlý a pohotový."
                  },
                  {
                    "tr_title": "Grafika, která zkrátí dobu vykreslování."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Úprava fotografií",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ÚPRAVA FOTOGRAFIÍ",
                "tr_subtitle": "Dokonalé fotografie",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělé úpravy fotografií:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Procesor s&nbsp;výkonem pro úpravy velkých skupin snímků."
                  },
                  {
                    "tr_title": "Paměť, která urychluje práci s&nbsp;grafickým softwarem."
                  },
                  {
                    "tr_title": "Technologie Thunderbolt™ 4 umožňuje rychlé přenášení souborů."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Úprava videa",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ÚPRAVA VIDEA",
                "tr_subtitle": "Vytvářejte filmová kouzla",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělé úpravy videa:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který si poradí s&nbsp;velkými soubory."
                  },
                  {
                    "tr_title": "Paměť, díky které je střihový software rychlý a pohotový."
                  },
                  {
                    "tr_title": "Grafika, která zkrátí dobu vykreslování."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D modelování",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D MODELOVÁNÍ",
                "tr_subtitle": "Výtvory nové úrovně",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělé 3D modelování:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Procesor, který si poradí s&nbsp;pokročilým kreativním softwarem."
                  },
                  {
                    "tr_title": "Paměť, která dokáže rychle vyvolat velké soubory."
                  },
                  {
                    "tr_title": "Grafika pro výkonné vytváření a úpravy obrázků."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Spolupráce v&nbsp;reálném čase",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SPOLUPRÁCE V&nbsp;REÁLNÉM ČASE",
                "tr_subtitle": "Pracujte odkudkoli",
                "tr_subtitle1": "Co tento počítač obsahuje pro skvělou spolupráci na internetu:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Procesor umožňující používat kancelářský software."
                  },
                  {
                    "tr_title": "Wi‑Fi, na které se můžete spolehnout."
                  },
                  {
                    "tr_title": "Webová kamera, která nahrává ve skvělém rozlišení HD."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Aplikace Intel® Unison™",
    "tr_title": "Odemkněte svůj propojený svět",
    "tr_subtitle": "Jednoduše přenášejte soubory a fotografie a zároveň spravujte oznámení, hovory a texty ze svého PC.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "PŘENÁŠEJTE SOUBORY A FOTOGRAFIE",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "VOLEJTE A PŘIJÍMEJTE HOVORY",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "ODESÍLEJTE A PŘIJÍMEJTE SMS",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "SPRAVUJTE OZNÁMENÍ TELEFONU",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "ODEMKNĚTE SVŮJ PROPOJENÝ SVĚT",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}