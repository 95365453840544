const Library_mobile_es = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado con un procesador [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado con un procesador [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Al igual que el cerebro, el procesador controla lo que hace tu ordenador. Esto significa que, desde los vídeos que ves y los videojuegos a los que juegas hasta las webs que visitas, todo se ejecuta gracias al procesador.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "El procesador es el cerebro del ordenador"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Rendimiento y valor.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesador Intel® Pentium® Silver",
                    "tr_subtitle": "Mantente al día de tu mundo digital con el rendimiento necesario para acceder a archivos desde Internet. Haz multitarea con sencillez cambiando fácilmente entre programas y consigue hacer más en menos tiempo.",
                    "tr_gen_core_title": "Procesador Intel® Pentium® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesador Intel® Pentium® Gold",
                    "tr_subtitle": "Mayor productividad: Un rendimiento mejorado con la potencia que necesitas para los días más exigentes. Navegación por Internet simplificada: Carga rápidamente páginas web con contenido multimedia para explorar más y esperar menos.",
                    "tr_gen_core_title": "Procesador Intel® Pentium® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesador Intel® Celeron®",
                    "tr_subtitle": "Rendimiento para mejorar el trabajo y disfrutar más del entretenimiento. Disfruta de películas y vídeos en Internet con detalles asombrosos. Pasa más tiempo haciendo cosas y menos esperando.",
                    "tr_gen_core_title": "Procesador Intel® Celeron®"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesador Intel® Pentium®",
                    "tr_subtitle": "Para disfrutar de entretenimiento, transmisión de vídeos y productividad excelentes. Disfruta de películas y vídeos en Internet con detalles asombrosos. Pasa más tiempo haciendo cosas y menos esperando.",
                    "tr_gen_core_title": "Procesador Intel® Pentium®"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "El rendimiento que necesitas. La asequibilidad que buscas.",
                "tr_title": "Procesador Intel®",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Un valor incomparable",
                    "tr_subtitle": "Un procesador Intel® puede manejar casi cualquier tarea que necesites por un precio que te puedes permitir. Pasa fácilmente de un software a otro o disfruta de gráficos de alta calidad con un rendimiento constante.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Crea conexiones más fuertes",
                    "tr_subtitle": "Con una calidad de vídeo más nítida, mayor claridad de audio y conectividad a Internet casi 3 veces más rápida, podrás colaborar con confianza donde lo necesites.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Como de la noche al día",
                    "tr_subtitle": "Desde maratones de películas matutinos hasta retoques de última hora, el procesador Intel® amplía la autonomía de tu dispositivo para ofrecerte experiencias verdaderamente inmersivas e ininterrumpidas.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Cualquier sitio es bueno para aprender",
                    "tr_subtitle": "Ejecuta varias herramientas de aprendizaje en línea con facilidad desde cualquier rincón de tu casa."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Aprende donde quieras",
                    "tr_subtitle": "Termina las clases donde lo necesites ejecutando varias herramientas de aprendizaje electrónico al mismo tiempo."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Obtén la potencia para entretenimiento y productividad que se ajusta a tu presupuesto y estilo de vida.",
                "tr_title": "Rendimiento Intel para cualquier presupuesto.",
                "tr_gen_core_title": "Procesador Intel® Celeron®",
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL NIVEL ADECUADO DE RENDIMIENTO Y VALOR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Procesador Intel® Celeron®"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesador Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rápida navegación por Internet y multimedia",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fácil intercambio entre programas",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos ocasionales",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión de vídeos 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Edición ligera de fotos y vídeos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rápida productividad con rendimiento inteligente",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Equipado con un procesador Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;de&nbsp;7<sup>a</sup>&nbsp;generación"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Equipado con un procesador Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;de&nbsp;6<sup>a</sup>&nbsp;generación"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Equipado con un procesador Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;de&nbsp;5<sup>a</sup>&nbsp;generación"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Equipado con un procesador Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;de&nbsp;4<sup>a</sup>&nbsp;generación"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Equipado con un procesador Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;de&nbsp;3<sup>a</sup>&nbsp;generación"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Equipado con un procesador Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;de&nbsp;2<sup>a</sup>&nbsp;generación"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Equipado con un procesador Intel®&nbsp;Core™&nbsp;[cpu_fam]&nbsp;de&nbsp;1<sup>a</sup>&nbsp;generación"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Recomendado para:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado con un procesador Intel® Core™ i3 de 8<sup>a</sup> generación",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de 8<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Los procesadores Intel® Core™ de 9<sup>a</sup> generación se han diseñado para seguir el ritmo del mundo digital. Gracias a la combinación de velocidad y rendimiento, podrás aprovechar al máximo tu ordenador sin frustraciones.",
                "tr_title": "Equipado con un procesador Intel®&nbsp;Core™ i3 de 9<sup>a</sup> generación",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de 9<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "El rendimiento versátil se une a la amplia autonomía para que sigas tu ritmo en cualquier lugar.",
                "tr_title": "Potencia durante todo&nbsp;el&nbsp;día",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;10<sup>a</sup> generación",
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL NIVEL ADECUADO DE RENDIMIENTO DE LA 10<sup>A</sup> GENERACIÓN",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Transmisión de películas en 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Conectividad rápida y fiable",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Máscara de fotos y filtros de vídeo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos en 1080p con velocidades de&nbsp;fotogramas&nbsp;fluidas",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ideal para trabajar con archivos de gran tamaño y programas de creación",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Pasa fácilmente de transmitir películas a crear presentaciones con un máximo rendimiento.",
                "tr_title": "Potencia durante todo&nbsp;el&nbsp;día",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;10<sup>a</sup> generación"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Un portátil diseñado especialmente para los que siempre se están desplazando.",
                "tr_title": "Presentamos una nueva clase de portátiles",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;10<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Diseñado específicamente para ordenadores portátiles, el procesador Intel® Core™ i3 de 11<sup>a</sup> generación te permite hacer más que nunca, dondequiera que estés.",
                "tr_title": "Sácale más partido a tu nuevo portátil",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i3 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Cuando toda tu familia depende de un equipo de sobremesa para todo lo que hace, tienes que buscar la potencia y versatilidad de un procesador Intel® Core™ i3 de 11<sup>a</sup> generación.",
                "tr_title": "Prepárate para todo con un rendimiento revolucionario.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i3 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado con un procesador Intel®&nbsp;Core™ i5 de 8<sup>a</sup> generación",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de 8<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Los procesadores Intel® Core™ de 9<sup>a</sup> generación se han diseñado para seguir el ritmo del mundo digital. Gracias a la combinación de velocidad y rendimiento, podrás aprovechar al máximo tu ordenador sin frustraciones.",
                "tr_title": "Equipado con un procesador Intel®&nbsp;Core™ i5 de 9<sup>a</sup> generación",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de 9<sup>a</sup> generación"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "JUGABILIDAD Y VR FLUIDAS EN LOS VIDEOJUEGOS MÁS EXIGENTES",
                "tr_title": "Empieza a jugar en serio",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de 9<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Videojuegos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Juega, transmite y graba sin límites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Los mejores procesadores Intel® Core™ de 9<sup>a</sup> generación para videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Hilos: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gestiona fácilmente varios programas para que estés listo para todo, vayas donde vayas.",
                "tr_title": "Multitarea en los desplazamientos",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;10<sup>a</sup> generación",
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL NIVEL ADECUADO DE RENDIMIENTO DE LA 10<sup>A</sup> GENERACIÓN",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Transmisión de películas en 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Conectividad rápida y fiable",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Máscara de fotos y filtros de vídeo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos en 1080p con velocidades de&nbsp;fotogramas&nbsp;fluidas",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ideal para trabajar con archivos de gran tamaño y programas de creación",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Potencia todo lo que haces, desde el entretenimiento hasta la productividad y la creación de contenido.",
                "tr_title": "Actualiza a un ordenador más potente",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;10<sup>a</sup> generación"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Un portátil diseñado especialmente para los que siempre se están desplazando.",
                "tr_title": "Presentamos una nueva clase de portátiles",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;10<sup>a</sup> generación"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Diseñado para fluidez en transmisión y videojuegos AAA",
                "tr_title": "Juega según tus condiciones",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;10<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ DE 10<sup>A</sup> GENERACIÓN RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Fluidez con videojuegos triple A",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión y grabación",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creación avanzada de contenido",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rendimiento máximo en videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado y apto para overclocking<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Diseñado para fluidez en transmisión y videojuegos AAA",
                "tr_title": "Juega según tus condiciones",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;10<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ DE 10<sup>A</sup> GENERACIÓN RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Fluidez con videojuegos triple A",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión y grabación",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creación avanzada de contenido",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rendimiento máximo en videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado y apto para overclocking<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 10<br/>Hilos: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Gestiona fácilmente varios programas para que estés listo para todo, vayas donde vayas.",
                "tr_title": "Multitarea en los desplazamientos",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;10<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;10<sup>A</sup>&nbsp;GENERACIÓN"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "El procesador Intel® Core™ i5 de 11<sup>a</sup> generación aporta experiencias visuales vibrantes y una productividad de amplio alcance a los ordenadores más finos y ligeros.",
                "tr_title": "Potencia del portátil impresionante",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Cuando toda tu familia depende de un equipo de sobremesa para todo lo que hace, tienes que buscar la potencia y versatilidad de un procesador Intel® Core™ i5 de 11<sup>a</sup> generación.",
                "tr_title": "Prepárate para todo con un rendimiento revolucionario.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "El procesador Intel® Core™ i5 de 11<sup>a</sup> generación ofrece experiencias visuales vibrantes y una productividad de amplia gama a los portátiles finos y ligeros.",
                "tr_title": "Potencia del portátil impresionante",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Equipado con un procesador Intel®&nbsp;Core™ i7 de 8<sup>a</sup> generación",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de 8<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Los procesadores Intel® Core™ de 9<sup>a</sup> generación se han diseñado para seguir el ritmo del mundo digital. Gracias a la combinación de velocidad y rendimiento, podrás aprovechar al máximo tu ordenador sin frustraciones.",
                "tr_title": "Equipado con un procesador Intel®&nbsp;Core™ i7 de 9<sup>a</sup> generación",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de 9<sup>a</sup> generación"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "HASTA UN 32&nbsp;% MÁS DE FPS mientras juegas, transmites y grabas, comparado con un ORDENADOR DE HACE 3 AÑOS<sup>3</sup>",
                "tr_title": "La potencia para compartir tus mejores momentos de videojuegos",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de 9<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Videojuegos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Juega, transmite y graba sin límites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Los mejores procesadores Intel® Core™ de 9<sup>a</sup> generación para videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Hilos: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Diseñado para la eficiencia, este elegante dispositivo puede tener un gran impacto en tu forma de crear, conectar y transmitir.",
                "tr_title": "Rendimiento avanzado en&nbsp;cualquier&nbsp;lugar",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;10<sup>a</sup> generación",
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL NIVEL ADECUADO DE RENDIMIENTO DE LA 10<sup>A</sup> GENERACIÓN",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Transmisión de películas en 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Conectividad rápida y fiable",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Máscara de fotos y filtros de vídeo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos en 1080p con velocidades de&nbsp;fotogramas&nbsp;fluidas",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ideal para trabajar con archivos de gran tamaño y programas de creación",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Rendimiento avanzado para llevar todo lo que haces a un nivel superior",
                "tr_title": "Potencia para hacerlo&nbsp;todo",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;10<sup>a</sup> generación"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Un portátil diseñado especialmente para los que siempre se están desplazando.",
                "tr_title": "Presentamos una nueva clase de portátiles",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;10<sup>a</sup> generación"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Juega, transmite y graba con una ventaja competitiva",
                "tr_title": "Potencia tu mundo gaming",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;10<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ DE 10<sup>A</sup> GENERACIÓN RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Fluidez con videojuegos triple A",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión y grabación",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creación avanzada de contenido",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rendimiento máximo en videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado y apto para overclocking<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Juega, transmite y graba con una ventaja competitiva",
                "tr_title": "Potencia tu mundo gaming",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;10<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ DE 10<sup>A</sup> GENERACIÓN RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Fluidez con videojuegos triple A",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión y grabación",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creación avanzada de contenido",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rendimiento máximo en videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado y apto para overclocking<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 10<br/>Hilos: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Diseñado para ser eficiente, este elegante dispositivo puede tener un enorme impacto en la forma de crear, conectar y transmitir.",
                "tr_title": "Un rendimiento premium en cualquier lugar",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;10<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;10<sup>A</sup>&nbsp;GENERACIÓN"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "El procesador Intel® Core™ i7 de 11<sup>a</sup> generación aporta creación avanzada de contenido, una experiencia de juego fluida y entretenimiento de nivel superior a los ordenadores ultraportátiles.",
                "tr_title": "Rendimiento revolucionario del portátil",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Haz que tu equipo de sobremesa sea el lugar de destino de cualquier necesidad de tu hogar con el rendimiento sin límites del procesador Intel® Core™ i7 de 11<sup>a</sup> generación.",
                "tr_title": "Trabaja. Aprende. Juega. Sin límites.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "El procesador Intel® Core™ i7 de 11<sup>a</sup> generación ofrece una creación de contenido avanzada, videojuegos fluidos y entretenimiento de nivel superior a portátiles finos y ligeros.",
                "tr_title": "Rendimiento revolucionario del portátil",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Haz que tu equipo de sobremesa sea el lugar de destino de cualquier necesidad de tu hogar con el rendimiento sin límites del procesador Intel® Core™ i7 de 11<sup>a</sup> generación.",
                "tr_title": "Crea sin límites",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Con la creación de contenido avanzada, videojuegos fluidos y entretenimiento de nivel superior en portátiles finos y ligeros, este procesador Intel® Core™ i7 de 11<sup>a</sup> generación ahora es mejor.",
                "tr_title": "Rendimiento revolucionario del portátil",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado con un procesador Intel®&nbsp;Core™ i9 de 8<sup>a</sup> generación",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de 8<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Los procesadores Intel® Core™ de 9<sup>a</sup> generación se han diseñado para seguir el ritmo del mundo digital. Gracias a la combinación de velocidad y rendimiento, podrás aprovechar al máximo tu ordenador sin frustraciones.",
                "tr_title": "Equipado con un procesador Intel®&nbsp;Core™ i9 de 9<sup>a</sup> generación",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de 9<sup>a</sup> generación"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "HASTA UN 41&nbsp;% MÁS DE FPS mientras juegas, transmites y grabas, comparado con un ORDENADOR DE HACE 3 AÑOS<sup>4</sup>",
                "tr_title": "Los jugadores piden. El Intel® Core™ i9 responde.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de 9<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Videojuegos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Juega, transmite y graba sin límites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Los mejores procesadores Intel® Core™ de 9<sup>a</sup> generación para videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Hilos: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Aumenta tu potencial con la experiencia en videojuegos definitiva",
                "tr_title": "Compite al máximo&nbsp;nivel",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;10<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ DE 10<sup>A</sup> GENERACIÓN RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Fluidez con videojuegos triple A",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión y grabación",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creación avanzada de contenido",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rendimiento máximo en videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado y apto para overclocking<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Aumenta tu potencial con la experiencia en videojuegos definitiva",
                "tr_title": "Compite al máximo&nbsp;nivel",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;10<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ DE 10<sup>A</sup> GENERACIÓN RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Fluidez con videojuegos triple A",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión y grabación",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creación avanzada de contenido",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rendimiento máximo en videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado y apto para overclocking<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 10<br/>Hilos: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Aumenta tu potencial con la experiencia de creación definitiva",
                "tr_title": "Creación a los niveles más avanzados",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;10<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;10<sup>A</sup>&nbsp;GENERACIÓN",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Los portátiles Intel® Evo™ con procesador Intel® Core™ i5 de 11<sup>a</sup> generación y gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> ofrecen una velocidad y un rendimiento increíbles, todo ello en un diseño fino y ligero.",
                "tr_title": "Potencia mejorada para un portátil evolucionado",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Los portátiles Intel® Evo™ con procesador Intel® Core™ i7 de 11<sup>a</sup> generación y gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> ofrecen velocidad y rendimiento avanzados para una experiencia móvil de gama alta.",
                "tr_title": "Potencia avanzada para un portátil evolucionado",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Disfruta de un gran equilibrio entre rendimiento, multimedia y conectividad a un precio increíble.",
                "tr_title": "Rendimiento y conectividad a un precio increíble.",
                "tr_gen_core_title": "Procesador Intel® Pentium® Silver",
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL NIVEL ADECUADO DE RENDIMIENTO Y VALOR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Procesador Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesador Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rápida navegación por Internet y multimedia",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fácil intercambio entre programas",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos ocasionales",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión de vídeos 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Edición ligera de fotos y vídeos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rápida productividad con rendimiento inteligente",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Disfruta de una multitarea fluida, edición sencilla de fotos y vídeos, vídeos en 4K y gráficos nítidos a un precio increíble.",
                "tr_title": "Rendimiento impresionante para trabajar y jugar.",
                "tr_gen_core_title": "Procesador Intel® Pentium® Gold",
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL NIVEL ADECUADO DE RENDIMIENTO Y VALOR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Procesador Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesador Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rápida navegación por Internet y multimedia",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fácil intercambio entre programas",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos ocasionales",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión de vídeos 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Edición ligera de fotos y vídeos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rápida productividad con rendimiento inteligente",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Videojuegos triple A y gran autonomía en un ordenador ultraportátil. No tienes por qué limitar tu forma de jugar.",
                "tr_title": "No tienes por qué limitarte.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;11<sup>a</sup> generación",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ENCUENTRA TU PROCESADOR INTEL® CORE™ ULTRAPORTÁTIL PARA VIDEOJUEGOS.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5&#8209;11300H de&nbsp;11<sup>a</sup>&nbsp;genera&shy;ción"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7&#8209;11370H de&nbsp;11<sup>a</sup>&nbsp;genera&shy;ción"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7-11375H Special Edition de&nbsp;11<sup>a</sup>&nbsp;genera&shy;ción"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Hasta <span class='style_badge'>4,4&nbsp;GHz</span> de velocidad de reloj máxima",
                      "tr_td2_title": "Hasta <span class='style_badge'>4,8&nbsp;GHz</span> de velocidad de reloj máxima",
                      "tr_td3_title": "Hasta <span class='style_badge'>5,0&nbsp;GHz</span> de velocidad de reloj máxima"
                    },
                    {
                      "tr_td1_title": "4 núcleos/ 8&nbsp;hilos.",
                      "tr_td2_title": "4 núcleos/ 8&nbsp;hilos.",
                      "tr_td3_title": "4 núcleos/ 8&nbsp;hilos."
                    },
                    {
                      "tr_td1_title": "Tecnología Intel® Turbo Boost",
                      "tr_td2_title": "Tecnología Intel® Turbo Boost",
                      "tr_td3_title": "Tecnología Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB de caché inteligente Intel®",
                      "tr_td2_title": "12 MB de&nbsp;caché inteligente Intel®",
                      "tr_td3_title": "12 MB de&nbsp;caché inteligente Intel®"
                    },
                    {
                      "tr_td1_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Videojuegos triple A a 1080p con un alto índice de FPS en ajustes altos y un rendimiento excelente desenchufado en un ordenador ultraportátil.",
                "tr_title": "Atrévete a tenerlo todo.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;11<sup>a</sup> generación",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ENCUENTRA TU PROCESADOR INTEL® CORE™ ULTRAPORTÁTIL PARA VIDEOJUEGOS.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5&#8209;11300H de&nbsp;11<sup>a</sup>&nbsp;genera&shy;ción"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7&#8209;11370H de&nbsp;11<sup>a</sup>&nbsp;genera&shy;ción"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7-11375H Special Edition de&nbsp;11<sup>a</sup>&nbsp;genera&shy;ción"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Hasta <span class='style_badge'>4,4&nbsp;GHz</span> de velocidad de reloj máxima",
                      "tr_td2_title": "Hasta <span class='style_badge'>4,8&nbsp;GHz</span> de velocidad de reloj máxima",
                      "tr_td3_title": "Hasta <span class='style_badge'>5,0&nbsp;GHz</span> de velocidad de reloj máxima"
                    },
                    {
                      "tr_td1_title": "4 núcleos/ 8&nbsp;hilos.",
                      "tr_td2_title": "4 núcleos/ 8&nbsp;hilos.",
                      "tr_td3_title": "4 núcleos/ 8&nbsp;hilos."
                    },
                    {
                      "tr_td1_title": "Tecnología Intel® Turbo Boost",
                      "tr_td2_title": "Tecnología Intel® Turbo Boost",
                      "tr_td3_title": "Tecnología Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB de caché inteligente Intel®",
                      "tr_td2_title": "12 MB de&nbsp;caché inteligente Intel®",
                      "tr_td3_title": "12 MB de&nbsp;caché inteligente Intel®"
                    },
                    {
                      "tr_td1_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Hasta 5,0&nbsp;GHz de velocidad de reloj máxima para sacar el máximo provecho a tus videojuegos y un ordenador ultraportátil para llevarlos donde vayas.",
                "tr_title": "Más potencia en&nbsp;menos&nbsp;peso.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 Special Edition de&nbsp;11<sup>a</sup> generación",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ENCUENTRA TU PROCESADOR INTEL® CORE™ ULTRAPORTÁTIL PARA VIDEOJUEGOS.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5&#8209;11300H de&nbsp;11<sup>a</sup>&nbsp;genera&shy;ción"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7&#8209;11370H de&nbsp;11<sup>a</sup>&nbsp;genera&shy;ción"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7-11375H Special Edition de&nbsp;11<sup>a</sup>&nbsp;genera&shy;ción"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Hasta <span class='style_badge'>4,4&nbsp;GHz</span> de velocidad de reloj máxima",
                      "tr_td2_title": "Hasta <span class='style_badge'>4,8&nbsp;GHz</span> de velocidad de reloj máxima",
                      "tr_td3_title": "Hasta <span class='style_badge'>5,0&nbsp;GHz</span> de velocidad de reloj máxima"
                    },
                    {
                      "tr_td1_title": "4 núcleos/ 8&nbsp;hilos.",
                      "tr_td2_title": "4 núcleos/ 8&nbsp;hilos.",
                      "tr_td3_title": "4 núcleos/ 8&nbsp;hilos."
                    },
                    {
                      "tr_td1_title": "Tecnología Intel® Turbo Boost",
                      "tr_td2_title": "Tecnología Intel® Turbo Boost",
                      "tr_td3_title": "Tecnología Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB de caché inteligente Intel®",
                      "tr_td2_title": "12 MB de&nbsp;caché inteligente Intel®",
                      "tr_td3_title": "12 MB de&nbsp;caché inteligente Intel®"
                    },
                    {
                      "tr_td1_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "El procesador Intel® Core™ i7 Special Edition de 11<sup>a</sup> generación ofrece creación avanzada de contenido, videojuegos fluidos y entretenimiento de siguiente nivel en ordenadores ultraportátiles.",
                "tr_title": "Rendimiento revolucionario del portátil",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 Special Edition de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 SPECIAL&nbsp;EDITION DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "El procesador Intel® Core™ i7 Special Edition de 11<sup>a</sup> generación ofrece creación avanzada de contenido, videojuegos fluidos y entretenimiento de siguiente nivel en ordenadores ultraportátiles.",
                "tr_title": "Rendimiento revolucionario del portátil",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 Special Edition de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 SPECIAL&nbsp;EDITION DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Las rápidas velocidades de reloj y el alto número de núcleos se combinan a la perfección para sacar más provecho de los videojuegos de competición y la creación de contenido complejo.",
                "tr_title": "Increíble potencia con el equilibrio perfecto",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;11<sup>a</sup> generación",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "El equilibrio perfecto entre velocidad de reloj y rendimiento de los núcleos para sacar más provecho a tus videojuegos, ya sea en competición con un alto índice de FPS o en experiencias triple A envolventes.",
                "tr_title": "Potencia para videojuegos.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;11<sup>a</sup> generación",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Juega, graba y transmite títulos exigentes con un alto índice de FPS y cambia sin esfuerzo a cargas de trabajo de multitarea exigentes.",
                "tr_title": "Potencia para superar los&nbsp;límites.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;11<sup>a</sup> generación",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Las rápidas velocidades de reloj y el alto número de núcleos se combinan a la perfección para sacar más provecho de los videojuegos de competición y la creación de contenido complejo.",
                "tr_title": "Increíble potencia con el equilibrio perfecto",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;11<sup>a</sup> generación",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Pasa fácilmente de transmitir películas a crear presentaciones con un máximo rendimiento.",
                "tr_title": "Potencia durante todo&nbsp;el&nbsp;día",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 con Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Procesador Intel®&nbsp;Core™&nbsp;i3 con Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "El rendimiento versátil se une a la amplia autonomía para que sigas tu ritmo en cualquier lugar.",
                "tr_title": "Potencia durante todo&nbsp;el&nbsp;día",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 con Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Procesador Intel®&nbsp;Core™&nbsp;i3 con Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gestiona fácilmente varios programas para que estés listo para todo, vayas donde vayas.",
                "tr_title": "Multitarea en los desplazamientos",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 con Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Procesador Intel®&nbsp;Core™&nbsp;i5 con Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Potencia todo lo que haces, desde el entretenimiento hasta la productividad y la creación de contenido.",
                "tr_title": "Actualiza a un ordenador más potente",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 con Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Procesador Intel®&nbsp;Core™&nbsp;i5 con Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "El rendimiento en videojuegos de un equipo de sobremesa se combina con la movilidad, ya sea para partidas competitivas con FPS altos o experiencias triple A envolventes.",
                "tr_title": "Potencia para videojuegos.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;11<sup>a</sup> generación",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "El rendimiento en videojuegos de un equipo de sobremesa se combina con la movilidad, ya sea para partidas competitivas con FPS altos o experiencias triple A envolventes.",
                "tr_title": "Potencia para videojuegos.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Un portátil que puede jugar, grabar y transmitir contenido al mismo tiempo con FPS altos y cambiar sin esfuerzo a grandes cargas de trabajo multitarea.",
                "tr_title": "Potencia para superar los&nbsp;límites.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;11<sup>a</sup> generación",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Un portátil que puede jugar, grabar y transmitir contenido al mismo tiempo con FPS altos y cambiar sin esfuerzo a grandes cargas de trabajo multitarea.",
                "tr_title": "Potencia para superar los&nbsp;límites.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Las velocidades de reloj rápidas y el alto número de núcleos se combinan a la perfección para sacar más provecho de los videojuegos de competición y la creación de contenido complejo, dondequiera que te lleves el ordenador.",
                "tr_title": "Increíble potencia con el equilibrio perfecto",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;11<sup>a</sup> generación",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Las velocidades de reloj rápidas y el alto número de núcleos se combinan a la perfección para sacar más provecho de los videojuegos de competición y la creación de contenido complejo, dondequiera que te lleves el ordenador.",
                "tr_title": "Increíble potencia con el equilibrio perfecto",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Las velocidades de reloj rápidas, el alto número de núcleos y el rendimiento desbloqueado se combinan a la perfección para sacar más provecho de los videojuegos de competición y la creación de contenido complejo, dondequiera que te lleves el ordenador.",
                "tr_title": "Potencia sin límites. Equilibrio perfecto.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;11<sup>a</sup> generación",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Las velocidades de reloj rápidas, el alto número de núcleos y el rendimiento desbloqueado se combinan a la perfección para sacar más provecho de los videojuegos de competición y la creación de contenido complejo, dondequiera que te lleves el ordenador.",
                "tr_title": "Potencia sin límites. Equilibrio perfecto.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Las rápidas velocidades de reloj y el alto número de núcleos se combinan a la perfección para sacar más provecho de los videojuegos de competición y la creación de contenido complejo.",
                "tr_title": "Increíble potencia con el equilibrio perfecto",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Las velocidades de reloj rápidas y el alto número de núcleos se combinan a la perfección para sacar más provecho de la creación de contenido complejo, dondequiera que te lleves el ordenador.",
                "tr_title": "Increíble potencia con el equilibrio perfecto",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Las velocidades de reloj rápidas y el alto número de núcleos se combinan a la perfección para sacar más provecho de la creación de contenido complejo.",
                "tr_title": "Increíble potencia con el equilibrio perfecto",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Aumenta tu potencial con la experiencia en videojuegos definitiva",
                "tr_title": "Compite al máximo&nbsp;nivel",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;10<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ DE 10<sup>A</sup> GENERACIÓN RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Fluidez con videojuegos triple A",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión y grabación",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creación avanzada de contenido",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rendimiento máximo en videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado y apto para overclocking<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Aumenta tu potencial con la experiencia en videojuegos definitiva",
                "tr_title": "Compite al máximo&nbsp;nivel",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;10<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ DE 10<sup>A</sup> GENERACIÓN RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Fluidez con videojuegos triple A",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmisión y grabación",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creación avanzada de contenido",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rendimiento máximo en videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado y apto para overclocking<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 10<br/>Hilos: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Un portátil diseñado especialmente para los que siempre se están desplazando.",
                "tr_title": "Presentamos una nueva clase de portátiles",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;10<sup>a</sup> generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Las rápidas velocidades de reloj y el alto número de núcleos se combinan a la perfección para sacar más provecho de los videojuegos de competición y la creación de contenido complejo.",
                "tr_title": "Potencia sin límites. Equilibrio perfecto.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Las velocidades de reloj rápidas, el alto número de núcleos y el rendimiento desbloqueado se combinan a la perfección para sacar más provecho de la creación de contenido complejo, dondequiera que te lleves el ordenador.",
                "tr_title": "Potencia sin límites. Equilibrio perfecto.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Las velocidades de reloj rápidas y el alto número de núcleos se combinan a la perfección para sacar más provecho de la creación de contenido complejo.",
                "tr_title": "Potencia sin límites. Equilibrio perfecto.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;11<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de Caché inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Jugabilidad y VR fluidas en los títulos más exigentes",
                "tr_title": "Empieza a jugar en serio",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de 9<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Videojuegos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Juega, transmite y graba sin límites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Los mejores procesadores Intel® Core™ de 9<sup>a</sup> generación para videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Hilos: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "HASTA UN 32&nbsp;% MÁS DE FPS <br/><span class='tr_subtitle_font'>mientras juegas, transmites y grabas, comparado con un ordenador de hace 3 años<sup>3</sup></span>",
                "tr_title": "La potencia para compartir tus mejores momentos de videojuegos",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de 9<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Videojuegos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Juega, transmite y graba sin límites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Los mejores procesadores Intel® Core™ de 9<sup>a</sup> generación para videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Hilos: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "HASTA UN 41&nbsp;% MÁS DE FPS <br/><span class='tr_subtitle_font'>mientras juegas, transmites y grabas, comparado con un ordenador de hace 3 años<sup>4</sup></span>",
                "tr_title": "Los jugadores piden. El Intel® Core™ i9 responde.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de 9<sup>a</sup> generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>hilos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Hasta <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>de caché</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCUENTRA EL PROCESADOR INTEL® CORE™ RECOMENDADO PARA TU FORMA DE JUGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Videojuegos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Videojuegos de competición",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Juega, transmite y graba sin límites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Los mejores procesadores Intel® Core™ de 9<sup>a</sup> generación para videojuegos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Hilos: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Hilos: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidad de reloj<sup>2</sup>: hasta 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Hilos: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Diseñado para la próxima generación de gaming",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>a</sup> generación",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>a</sup> generación",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;12<sup>a</sup> generación",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores",
                    "tr_badgeSubTitle": "[e_core] E-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>hilos</span></span>",
                    "tr_badgePreTitle": "[p_thread] en P-cores",
                    "tr_badgeSubTitle": "[e_thread] en E-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Trabaja y juega en un solo ordenador",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>a</sup> generación",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;12<sup>a</sup> generación",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores",
                    "tr_badgeSubTitle": "[e_core] E-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>hilos</span></span>",
                    "tr_badgePreTitle": "[p_thread] en P-cores",
                    "tr_badgeSubTitle": "[e_thread] en E-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento fiable cuando más se necesita",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;12<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i3 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Un impulso de rendimiento donde más lo necesitas",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Un impulso de rendimiento donde más lo necesitas",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento superior donde más lo necesitas",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento superior donde más lo necesitas",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Con los portátiles Intel® Evo™, obtendrás rendimiento, inteligencia integrada para videoconferencias seguras, Internet más rápido, una batería de larga duración y otras muchas cosas más.",
                "tr_title": "Un impulso de rendimiento donde más lo necesitas.",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Con los portátiles Intel® Evo™, obtendrás rendimiento, inteligencia integrada para videoconferencias seguras, Internet más rápido, una batería de larga duración y otras muchas cosas más.",
                "tr_title": "Rendimiento superior donde más lo necesitas",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Con los portátiles Intel® Evo™, obtendrás rendimiento, inteligencia integrada para videoconferencias seguras, Internet más rápido, una batería de larga duración y otras muchas cosas más.",
                "tr_title": "Rendimiento extremo en un portátil de gama alta",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;12<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Con tecnología Intel® Turbo Boost 2.0 hasta [max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Creado para la empresa",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir el ruido y difuminar el fondo",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Personalizar automáticamente el rendimiento para priorizar las actividades más frecuentes",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hacer llamadas web sin interrupciones y de alta calidad",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Contar con seguridad por hardware lista para utilizarse",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Ofrecer funcionalidades de gestión de dispositivos a distancia",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimizar automáticamente el rendimiento Wi&#8209;Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Potenciar las aplicaciones basadas en datos",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "La mayor productividad con rendimiento mejorado",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Con tecnología Intel® Turbo Boost 2.0 hasta [max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Trabaja duro para que puedas centrarte en tu empresa",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir el ruido y difuminar el fondo",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Personalizar automáticamente el rendimiento para priorizar las actividades más frecuentes",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hacer llamadas web sin interrupciones y de alta calidad",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Contar con seguridad por hardware lista para utilizarse",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Ofrecer funcionalidades de gestión de dispositivos a distancia",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimizar automáticamente el rendimiento Wi&#8209;Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Potenciar las aplicaciones basadas en datos",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "La mayor productividad con rendimiento mejorado",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Con tecnología Intel® Turbo Boost 2.0 hasta [max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Acelera tu empresa",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;12<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;12<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir el ruido y difuminar el fondo",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Personalizar automáticamente el rendimiento para priorizar las actividades más frecuentes",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hacer llamadas web sin interrupciones y de alta calidad",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Contar con seguridad por hardware lista para utilizarse",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Ofrecer funcionalidades de gestión de dispositivos a distancia",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimizar automáticamente el rendimiento Wi&#8209;Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Potenciar las aplicaciones basadas en datos",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "La mayor productividad con rendimiento mejorado",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento fiable dedicado a hacer más",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;13<sup>a</sup> generación",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento real dedicado a hacer más",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;13<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento real dedicado a hacer más",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;13<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento potente dedicado a hacer más",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;13<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento potente dedicado a hacer más",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;13<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Más allá del rendimiento",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;13<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores",
                    "tr_badgeSubTitle": "[e_core] E-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>hilos</span></span>",
                    "tr_badgePreTitle": "[p_thread] en P-cores",
                    "tr_badgeSubTitle": "[e_thread] en E-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Conéctate sin problemas a tu teléfono<sup>24</sup>, selecciona de forma inteligente el mejor Wi-Fi y carga rápidamente y con una autonomía duradera.",
                "tr_title": "Ve más allá del rendimiento para conseguir hacer más en cualquier lugar",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;13<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Conéctate sin problemas a tu teléfono<sup>24</sup>, selecciona de forma inteligente el mejor Wi-Fi y carga rápidamente y con una autonomía duradera.",
                "tr_title": "Ve más allá del rendimiento para permitir que tu ordenador haga aún más al mismo tiempo",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;13<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Conéctate sin problemas a tu teléfono<sup>24</sup>, selecciona de forma inteligente el mejor Wi-Fi y carga rápidamente y con una autonomía duradera.",
                "tr_title": "Ve más allá del rendimiento para acelerar las tareas más exigentes",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;13<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Más allá del rendimiento",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;13<sup>a</sup> generación",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;13<sup>a</sup> generación",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;13<sup>a</sup> generación",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores",
                    "tr_badgeSubTitle": "[e_core] E-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>hilos</span></span>",
                    "tr_badgePreTitle": "[p_thread] en P-cores",
                    "tr_badgeSubTitle": "[e_thread] en E-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Más allá del rendimiento",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores",
                    "tr_badgeSubTitle": "[e_core] E-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>hilos</span></span>",
                    "tr_badgePreTitle": "[p_thread] en P-cores",
                    "tr_badgeSubTitle": "[e_thread] en E-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento fiable dedicado a hacer más",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento real dedicado a hacer más",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento potente dedicado a hacer más",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Más allá del rendimiento",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores",
                    "tr_badgeSubTitle": "[e_core] E-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>hilos</span></span>",
                    "tr_badgePreTitle": "[p_thread] en P-cores",
                    "tr_badgeSubTitle": "[e_thread] en E-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento fiable dedicado a hacer más",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesador Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesador Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesador Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento real dedicado a hacer más",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;5",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL®&nbsp;CORE™&nbsp;5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesador Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesador Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesador Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Rendimiento potente dedicado a hacer más",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;7",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL®&nbsp;CORE™&nbsp;7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesador Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesador Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesador Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesador",
                "tr_title": "Juega sin límites",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;5",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;7",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;9",
                    "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] P-cores",
                    "tr_badgeSubTitle": "[e_core] E-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>hilos</span></span>",
                    "tr_badgePreTitle": "[p_thread] en P-cores",
                    "tr_badgeSubTitle": "[e_thread] en E-cores",
                    "tr_badgeSubTitle1": "[l_thread] en LPE-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Procesador Intel® Core™ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Juega sin límites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Juega sin límites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Juega sin límites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Juega sin límites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Juega sin límites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Juega sin límites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Las pequeñas empresas merecen grandes soluciones comerciales",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;13<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i5 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir el ruido y difuminar el fondo",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Personalizar automáticamente el rendimiento para priorizar las actividades más frecuentes",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Hacer llamadas web sin interrupciones y de alta calidad",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Contar con protecciones de seguridad por hardware listas para su uso",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Proteger dispositivos y datos contra ataques de manipulación física",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Las pequeñas empresas merecen grandes soluciones comerciales",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;13<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i7 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir el ruido y difuminar el fondo",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Personalizar automáticamente el rendimiento para priorizar las actividades más frecuentes",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Hacer llamadas web sin interrupciones y de alta calidad",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Contar con protecciones de seguridad por hardware listas para su uso",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Proteger dispositivos y datos contra ataques de manipulación física",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Las pequeñas empresas merecen grandes soluciones comerciales",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;13<sup>a</sup> generación",
                "tr_gen_core_title_uppercase": "PROCESADOR INTEL® CORE™ i9 DE&nbsp;13<sup>A</sup>&nbsp;GENERACIÓN",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTE PROCESADOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo<br/>actual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir el ruido y difuminar el fondo",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Personalizar automáticamente el rendimiento para priorizar las actividades más frecuentes",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Hacer llamadas web sin interrupciones y de alta calidad",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Contar con protecciones de seguridad por hardware listas para su uso",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Proteger dispositivos y datos contra ataques de manipulación física",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Accede a nuevas experiencias de IA para empresas",
                "tr_title": "Prepárate para el futuro con los procesadores&nbsp;Intel®&nbsp;Core™&nbsp;Ultra&nbsp;[XX] e Intel&nbsp;vPro®",
                "tr_gen_core_title": "Procesador Intel® Core™ Ultra [XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "DALE UN IMPULSO A TU EMPRESA CON LOS ORDENADORES CON IA DE INTEL",
                    "tr_badgeSubTitle": "Los ordenadores potentes que ofrecen una gran experiencia son el núcleo de la productividad empresarial. Intel vPro® aumenta la eficacia y el rendimiento de los empleados en todas las cargas de trabajo y aplicaciones gracias a una nueva arquitectura que les ofrece una eficiencia energética superior para las diversas tareas empresariales complejas, ya que envía la tarea adecuada al motor correcto y en el momento exacto.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "CARACTERÍSTICAS DE SEGURIDAD EN VARIAS CAPAS, SIN NECESIDAD DE CONFIGURACIÓN PREVIA",
                    "tr_badgeSubTitle": "Las amenazas nuevas y emergentes requieren un enfoque integral de la seguridad. Intel vPro® cuenta con protecciones a nivel de hardware y en toda la pila para que tu empresa esté más a salvo. Estas medidas de seguridad singulares y listas para usar (entre ellas, las funcionalidades de detección de amenazas impulsadas por IA) ayudan a proteger a tus usuarios, tus datos y tu empresa.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "HERRAMIENTAS INTEGRADAS PARA MANTENER LA PRODUCTIVIDAD DE LOS USUARIOS PRÁCTICAMENTE EN CUALQUIER LUGAR",
                    "tr_badgeSubTitle": "Las oficinas están por todas partes, y los ordenadores también. Intel vPro® dispone de capacidades de administración modernas por hardware para que las empresas alivien la carga que conlleva administrar las flotas informáticas, simplificar la asistencia y mejorar las experiencias de los usuarios finales, al tiempo que permite seguir prácticas informáticas sostenibles.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Más que un gran PC, un&nbsp;PC&nbsp;con&nbsp;IA",
                "tr_subtitle1": "CON LOS PROCESADORES INTEL® CORE™ ULTRA (SERIE 2)",
                "tr_subtitle2": "Es posible con tu PC Intel con IA",
                "tr_subtitle": "El procesador Intel® para portátiles más eficiente, seguro y de mayor rendimiento<sup>39</sup>",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;5 (Serie&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Más creatividad</span> con herramientas de IA para crear más rápido"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Más productividad</span> con asistencia de IA para ahorrar tiempo"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Más seguridad</span> con protección de datos integrada"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Más que un gran PC, un&nbsp;PC&nbsp;con&nbsp;IA",
                "tr_subtitle1": "CON LOS PROCESADORES INTEL® CORE™ ULTRA (SERIE 2)",
                "tr_subtitle2": "Es posible con tu PC Intel con IA",
                "tr_subtitle": "El procesador Intel® para portátiles más eficiente, seguro y de mayor rendimiento<sup>39</sup>",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;7 (Serie&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Más creatividad</span> con herramientas de IA para crear más rápido"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Más productividad</span> con asistencia de IA para ahorrar tiempo"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Más seguridad</span> con protección de datos integrada"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Más que un gran PC, un&nbsp;PC&nbsp;con&nbsp;IA",
                "tr_subtitle1": "CON LOS PROCESADORES INTEL® CORE™ ULTRA (SERIE 2)",
                "tr_subtitle2": "Es posible con tu PC Intel con IA",
                "tr_subtitle": "El procesador Intel® para portátiles más eficiente, seguro y de mayor rendimiento<sup>39</sup>",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;5 (Serie&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Más que un gran PC, un&nbsp;PC&nbsp;con&nbsp;IA",
                "tr_subtitle1": "CON LOS PROCESADORES INTEL® CORE™ ULTRA (SERIE 2)",
                "tr_subtitle2": "Es posible con tu PC Intel con IA",
                "tr_subtitle": "El procesador Intel® para portátiles más eficiente, seguro y de mayor rendimiento<sup>39</sup>",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;7 (Serie&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_tileTitle1": "Gráficos dedicados",
                "tr_title": "¿QUÉ ES UNA UNIDAD DE PROCESAMIENTO GRÁFICO?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "La unidad de procesamiento gráfico o GPU crea las imágenes que ves en la pantalla.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU integrada",
                    "tr_badgeSubTitle": "Una GPU integrada utiliza la misma RAM que el resto del ordenador, lo que aumenta su eficiencia energética.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU independiente",
                    "tr_badgeSubTitle": "Este es el tipo de GPU más potente porque cuenta con su propia RAM y otros recursos, que se dedican a crear unos gráficos excepcionales.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "GPU híbrida",
                    "tr_badgeSubTitle": "Este es el tipo de GPU más potente porque cuenta con su propia RAM y otros recursos, que se dedican a crear unos gráficos excepcionales.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Edición simplificada de fotos y vídeos",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Haz maratones de contenidos de vídeo en 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Juega a videojuegos populares con detalles impresionantes",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "GPU dobles",
                    "tr_badgeSubTitle": "Algunos sistemas, como los que tienen los gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, pueden aprovechar tanto la GPU en el procesador como la independiente para distribuir la carga de trabajo gráfica.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU integrada",
                    "tr_badgeSubTitle": "Una GPU integrada en el mismo chip que el procesador principal o CPU. Este tipo de GPU utiliza la misma RAM y otros recursos que el resto del ordenador, lo que permite que sea más eficiente energéticamente y ofrezca así dispositivos más finos y ligeros.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU dedicada",
                    "tr_badgeSubTitle": "Este es el tipo de GPU más potente porque cuenta con su propia RAM y otros recursos, que se dedican a crear unos gráficos excepcionales.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Estándar",
                    "tr_badgeSubTitle": "Hasta 4&nbsp;GB de VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Competición",
                    "tr_badgeSubTitle": "Hasta 8&nbsp;GB de VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Más de 8&nbsp;GB de VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Potencia gráfica intensa",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "El procesamiento gráfico más potente requiere de una tarjeta gráfica que se centre exclusivamente en crear imágenes atractivas y nítidas y animaciones fluidas.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Gráficos integrados nítidos y vibrantes",
                "tr_pretitle": "Gráficos Intel® Iris® Plus",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Los gráficos Intel® Iris® Plus ofrecen una experiencia inmersiva de vídeo 4K HDR y videojuegos Full HD sin necesidad de tarjeta gráfica adicional.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Imágenes impresionantes y rendimiento sin esfuerzo",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Los Gráficos Intel® revolucionan el rendimiento de los gráficos integrados para ofrecer diseño fluido a los creadores y experiencias de videojuegos enriquecidas.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Gráficos Intel® Iris® Pro.",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Gráficos UHD Intel® con arquitectura X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "Experiencias visuales envolventes",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Experimenta una edición simplificada de fotos y vídeos, haz maratones de contenidos de vídeo de alta calidad en 4K o juega a videojuegos populares con detalles impresionantes.",
                "tr_photo_text": "Edición simplificada de fotos y vídeos",
                "tr_video_text": "Haz maratones de contenidos de vídeo en 4K",
                "tr_game_text": "Juega a videojuegos populares con detalles impresionantes",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MUCHO MÁS QUE IMÁGENES BONITAS",
                "tr_subtitle": "Al trabajar estrechamente con el procesador (o CPU), la tarjeta gráfica (o GPU) ayuda a determinar cómo funcionan y qué aspecto tendrán tus videojuegos. La VRAM, o RAM de vídeo, dedicada solo para los gráficos, es una forma rápida de saber cómo va tu GPU.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PARA GRÁFICOS DE VIDEOJUEGOS POTENTES",
                "tr_subtitle": "El procesamiento gráfico más potente requiere una tarjeta gráfica independiente que pueda centrarse exclusivamente en la creación de imágenes hermosas y nítidas y animaciones fluidas",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PARA GRÁFICOS DE VIDEOJUEGOS POTENTES",
                "tr_subtitle": "El procesamiento gráfico más potente requiere una tarjeta gráfica independiente que pueda centrarse exclusivamente en la creación de imágenes hermosas y nítidas y animaciones fluidas",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MUCHO MÁS QUE IMÁGENES BONITAS",
                "tr_subtitle": "Al trabajar estrechamente con el procesador (o CPU), la tarjeta gráfica (o GPU) ayuda a determinar cómo funcionan y qué aspecto tendrán tus videojuegos. La VRAM, o RAM de vídeo, dedicada solo para los gráficos, es una forma rápida de saber cómo va tu GPU.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Potencia gráfica intensa",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "El procesamiento gráfico más potente requiere de una tarjeta gráfica que se centre exclusivamente en crear imágenes atractivas y nítidas y animaciones fluidas.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "PARA GRÁFICOS DE VIDEOJUEGOS POTENTES",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "El procesamiento gráfico más potente requiere de una tarjeta gráfica que se centre exclusivamente en crear imágenes atractivas y nítidas y animaciones fluidas.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "El procesamiento gráfico más potente requiere una tarjeta gráfica independiente que pueda centrarse exclusivamente en la creación de imágenes hermosas y nítidas y animaciones fluidas",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Proporciona potencia de gráficos suficiente para satisfacer los requisitos mínimos de tu videojuego.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Para jugadores exigentes que requieren jugar a nivel de competición",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Para jugadores avanzados que también utilizan sus ordenadores para creación de contenido exigente",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Vídeo dinámico y videojuegos inmersivos",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Saca el máximo partido a tu pantalla de alta resolución con los Gráficos HD&nbsp;Intel® y disfruta de una completa experiencia multimedia y procesamiento de gráficos integrados.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Vídeo e imágenes vibrantes",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Obtén más detalles y colores brillantes en tus fotos y transmisión de vídeo.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Creación y videojuegos increíbles",
                "tr_pretitle": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Desde el entretenimiento envolvente y la creación avanzada de contenido hasta videojuegos populares, los gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> aportan un rendimiento gráfico revolucionario a los ordenadores finos y ligeros.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_pretitle": "PARA GRÁFICOS DE VIDEOJUEGOS POTENTES",
                "tr_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "El procesamiento gráfico más potente requiere una tarjeta gráfica independiente que pueda centrarse exclusivamente en la creación de imágenes hermosas y nítidas y animaciones fluidas",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Libera tu creatividad",
                "tr_pretitle": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Desarrolla tus proyectos creativos y sumérgete en una amplia variedad de videojuegos con la potencia combinada de los gráficos en el procesador y el primer motor gráfico independiente Intel®.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "tr_pretitle": "PARA GRÁFICOS DE VIDEOJUEGOS POTENTES",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "El procesamiento gráfico más potente requiere una tarjeta gráfica independiente que pueda centrarse exclusivamente en la creación de imágenes hermosas y nítidas y animaciones fluidas",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Gráficos",
                "tr_title": "Espléndidos gráficos integrados",
                "tr_subtitle": "Disfruta de tus juegos, películas y vídeos en Internet con una claridad y unos detalles asombrosos.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Aumenta tu flujo de trabajo"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Potencia tu juego"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Potencia tu juego"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Lleva tu juego al siguiente nivel"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "LIBERA TU CREATIVIDAD",
                    "tr_specText": "Acelera tu productividad dondequiera que vayas con las funciones multimedia más completas, como <strong>la primera codificación AV1 por hardware del sector</strong> y creación de contenido acelerada por IA.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "LIBERA TU CREATIVIDAD",
                    "tr_specText": "Acelera tu productividad con las funciones multimedia más completas, como <strong>la primera codificación AV1 por hardware del sector</strong> y creación de contenido acelerada por IA.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "LIBERA TU CREATIVIDAD",
                    "tr_specText": "Experimenta creación de contenido de vanguardia acelerada por IA con <strong>la primera codificación AV1 por hardware del sector</strong> y el avanzado <strong>motor de medios X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "LIBERA TU CREATIVIDAD",
                    "tr_specText": "Experimenta creación de contenido de vanguardia acelerada por IA con <strong>la primera codificación AV1 por hardware del sector</strong> y el avanzado <strong>motor de medios X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "LIBERA TU CREATIVIDAD",
                    "tr_specText": "Experimenta creación de contenido de vanguardia acelerada por IA con <strong>la primera codificación AV1 por hardware del sector</strong> y el avanzado <strong>motor de medios X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "LA PORTABILIDAD Y EL RENDIMIENTO SE DAN LA MANO",
                    "tr_specText": "Juega a los videojuegos más recientes desde donde quieras y obtén rendimiento potenciado y fidelidad con las últimas funcionalidades de juego. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Tecnología Intel® de escalado mejorado por IA para videojuegos más fluidos y de mayor rendimiento. <br/><br/>Compatibilidad con <strong>DirectX* 12 Ultimate:  </strong>Lleva tus juegos a un nuevo nivel de realismo con tecnologías gráficas como el trazado de rayos y el sombreado de velocidad variable."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "TOMA EL CONTROL DE TUS VIDEOJUEGOS",
                    "tr_specText": "El <strong>panel de control Intel® Arc™</strong> te permite refinar tu experiencia con un diseño intuitivo y moderno. Obtén actualizaciones de controladores sin problemas, optimiza tus videojuegos y mucho más."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "TOMA EL CONTROL DE TUS VIDEOJUEGOS",
                    "tr_specText": "El <strong>panel de control Intel® Arc™</strong> te permite refinar tu experiencia con un diseño intuitivo y moderno. Obtén actualizaciones de controladores sin problemas, optimiza tus videojuegos y mucho más."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "TOMA EL CONTROL DE TUS VIDEOJUEGOS",
                    "tr_specText": "Obtén actualizaciones de controladores sin problemas y optimiza muchos de tus videojuegos favoritos con el software del <strong>panel de control Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAXIMIZA TU PLATAFORMA INTEL®",
                    "tr_specText": "Combina los gráficos con procesadores Intel® Core™ compatibles para mejorar tu experiencia de creación, juego y streaming gracias a la <strong>tecnología Intel®&nbsp;Deep&nbsp;Link</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MAXIMIZA TU SISTEMA INTEL®",
                    "tr_specText": "Combina los gráficos con procesadores Intel® Core™ compatibles para mejorar tu experiencia de creación, juego y streaming gracias a la <strong>tecnología Intel®&nbsp;Deep&nbsp;Link</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAXIMIZA TU PLATAFORMA INTEL®",
                    "tr_specText": "Combina los gráficos con procesadores Intel® Core™ compatibles para mejorar tu experiencia de creación, juego y streaming gracias a la <strong>tecnología Intel®&nbsp;Deep&nbsp;Link</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMIZA TU PLATAFORMA INTEL®",
                    "tr_specText": "Combina los gráficos con procesadores Intel® Core™ compatibles para mejorar tu experiencia de creación, juego y streaming gracias a la <strong>tecnología Intel®&nbsp;Deep&nbsp;Link</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMIZA TU SISTEMA INTEL®",
                    "tr_specText": "Combina los gráficos con procesadores Intel® Core™ compatibles para mejorar tu experiencia de creación, juego y streaming gracias a la <strong>tecnología Intel®&nbsp;Deep&nbsp;Link</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "SÉ EL CENTRO DE ATENCIÓN",
                    "tr_specText": "Transmite sin problemas con tecnologías de streaming avanzadas habilitadas por el <strong>panel de control Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "SÉ EL CENTRO DE ATENCIÓN",
                    "tr_specText": "Transmite sin problemas con tecnologías de streaming avanzadas habilitadas por el <strong>panel de control Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "EXPERIMENTA VIDEOJUEGOS INMERSIVOS DE ALTO&nbsp;RENDIMIENTO",
                    "tr_specText": "Prioriza el rendimiento con los videojuegos AAA más recientes a resoluciones y tasas de refresco altas. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Tecnología Intel® de escalado mejorado por IA para videojuegos más fluidos y de mayor rendimiento. <br/><br/>Compatibilidad con <strong>DirectX* 12 Ultimate:  </strong>Lleva tus juegos a un nuevo nivel de realismo con tecnologías gráficas como el trazado de rayos y el sombreado de velocidad variable."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "EXPERIMENTA VIDEOJUEGOS INMERSIVOS DE ALTO&nbsp;RENDIMIENTO",
                    "tr_specText": "Prioriza el rendimiento con los videojuegos AAA más recientes a resoluciones y tasas de refresco altas. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Tecnología Intel® de escalado mejorado por IA para videojuegos más fluidos y de mayor rendimiento. <br/><br/>Compatibilidad con <strong>DirectX* 12 Ultimate:  </strong>Lleva tus juegos a un nuevo nivel de realismo con tecnologías gráficas como el trazado de rayos y el sombreado de velocidad variable."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "DALES UN IMPULSO A TUS JUEGOS",
                    "tr_specText": "Lleva tu experiencia de juego al siguiente nivel con: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Tecnología Intel® de escalado mejorado por IA para videojuegos más fluidos y de mayor rendimiento. <br/><br/>Compatibilidad con <strong>DirectX* 12 Ultimate:  </strong>Lleva tus juegos a un nuevo nivel de realismo con tecnologías gráficas como el trazado de rayos y el sombreado de velocidad variable."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "DISFRUTA DE UNA EXPERIENCIA RÁPIDA Y AVANZADA EN JUEGOS",
                    "tr_specText": "Disfruta de un juego fluido en muchos de los juegos AAA más populares, y de una alta tasa de refresco, desde competiciones de juegos de disparos en primera persona de alto octanaje hasta éxitos de estrategia en tiempo real frenéticos. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Tecnología Intel® de escalado mejorado por IA para videojuegos más fluidos y de mayor rendimiento. <br/><br/>Compatibilidad con <strong>DirectX* 12 Ultimate:  </strong>Lleva tus juegos a un nuevo nivel de realismo con tecnologías gráficas como el trazado de rayos y el sombreado de velocidad variable."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "EXPERIMENTA VIDEOJUEGOS INMERSIVOS DE ALTO&nbsp;RENDIMIENTO",
                    "tr_specText": "Prioriza el rendimiento con los videojuegos AAA más recientes a resoluciones y tasas de refresco altas. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Tecnología Intel® de escalado mejorado por IA para videojuegos más fluidos y de mayor rendimiento. <br/><br/>Compatibilidad con <strong>DirectX* 12 Ultimate:  </strong>Lleva tus juegos a un nuevo nivel de realismo con tecnologías gráficas como el trazado de rayos y el sombreado de velocidad variable."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Un universo gráfico con espacio para todo el mundo"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "ACELERA LOS FOTOGRAMAS Y ACELERA LOS VIDEOJUEGOS",
                    "tr_specText": "La tecnología Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) lleva tu experiencia de juego a un nivel superior gracias al escalado mejorado por IA, para un rendimiento máximo con alta fidelidad de imagen. X<sup class='small_text_sup_xe'>e</sup>SS está optimizada para los productos gráficos Intel® Arc™, por lo que puedes aprovechar al máximo la aceleración por hardware de Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX)."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "YA ESTÁ AQUÍ LA EXPERIENCIA VISUAL DEFINITIVA",
                    "tr_specText": "La compatibilidad total con DirectX* 12 Ultimate de todos los gráficos Intel® Arc™ te permitirá disfrutar de impresionantes videojuegos inmersivos gracias a las tecnologías de gráficos más recientes, como el trazado de rayos, el sombreado de tasa variable, el sombreado de malla y la retroalimentación de muestreo, que son los elementos fundamentales de los videojuegos de próxima generación."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "CREACIÓN DE FOTOS, VÍDEOS Y MEDIOS SIN PROBLEMAS",
                    "tr_specText": "Libera tu imaginación y cautiva a tu público creando contenido digital de calidad gracias a un motor multimedia hiperavanzado, aumentado por la IA y acelerado con tecnología Intel®. Crea contenido espectacular con gráficos compatibles con los principales formatos de medios actuales al tiempo que te mantienes al día con las capacidades de codificación de vídeo AV1 más avanzadas."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "CREACIÓN Y VIDEOJUEGOS DE VANGUARDIA CON IA",
                    "tr_specText": "Los gráficos Intel® Arc™ permiten acceder a potentes capacidades de IA, desde la creación avanzada hasta los videojuegos inmersivos. Aprovecha al máximo el software de creación avanzada de hoy en día, que incluye la generación de texto a imagen y la edición de vídeo acelerada por IA. Además, disfruta de videojuegos en alta resolución con el escalado X<sup class='small_text_sup_xe'>e</sup>SS acelerado por IA."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Los gráficos profesionales empiezan aquí"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "IDEALES PARA REALIZAR TAREAS DE CREATIVIDAD, DISEÑO E INGENIERÍA DESDE CUALQUIER LUGAR",
                    "tr_specText": "Disponibles en determinados procesadores Intel®&nbsp;Core™&nbsp;Ultra serie H, los gráficos Intel®&nbsp;Arc™&nbsp;Pro integrados permiten doblar el rendimiento gráfico con certificaciones de proveedores de software independientes para mayor fiabilidad, y ofrecen aceleración por hardware y trazado de rayos con la eficiencia de la IA para una creación de contenido impresionante desde cualquier lugar.<br/><br/><span class='expandable_heading'>Certificaciones de software<br/></span><br/>Intel lleva años trabajando codo con codo con cientos de empresas de software y esta experiencia inigualable se aplica a nuestras tarjetas gráficas Intel®&nbsp;Arc™&nbsp;Pro serie A. Las certificaciones son tan importantes para nosotros como para ti."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "UNA IA MÁS ACCESIBLE",
                    "tr_specText": "Esta gama de GPU incluye una amplia selección de excelentes tecnologías que admiten tus herramientas de IA compatibles, con aceleración de IA dedicada integrada donde la necesites."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "TRAZADO DE RAYOS A UN PRECIO ASEQUIBLE",
                    "tr_specText": "Las tarjetas gráficas deben hacer algo más que limitarse a enviar píxeles a las pantallas: también deben acelerar la creación de imágenes hermosas. La tecnología de hardware de trazado de rayos integrada en los gráficos Intel®&nbsp;Arc™&nbsp;Pro serie A permite que tu software compatible tenga acceso a estos nuevos flujos de trabajo profesionales. Suele considerarse que el trazado de rayos solo está disponible en gráficos caros, pero la gama de gráficos profesionales más reciente de Intel rompe con esa percepción."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "PANTALLAS INMERSIVAS PARA EMPODERAR A LOS CREADORES",
                    "tr_specText": "La compatibilidad total con DirectX* 12 Ultimate de todos los gráficos Intel® Arc™ te permitirá disfrutar de impresionantes videojuegos inmersivos gracias a las tecnologías de gráficos más recientes, como el trazado de rayos, el sombreado de tasa variable, el sombreado de malla y la retroalimentación de muestreo, que son los elementos fundamentales de los videojuegos de próxima generación."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "IMÁGENES OPTIMIZADAS DINÁMICAMENTE",
                    "tr_specText": "Con la decodificación por hardware dedicada en las GPU Pro, Dolby Vision* ayuda a añadir mayor profundidad, un contraste más nítido y más colores a tu pantalla compatible. Dolby Vision* libera todo el potencial de la tecnología HDR al optimizar dinámicamente la calidad de imagen en función de tu servicio, dispositivo de pantalla y plataforma para ofrecer imágenes impresionantes en todo momento."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "SOFTWARE VERIFICADO POR ESPECIALISTAS",
                    "tr_specText": "Intel lleva años trabajando codo con codo con cientos de empresas de software y esta experiencia inigualable se aplica a nuestra gama de gráficos profesionales Intel®&nbsp;Arc™&nbsp;Pro serie A. Las certificaciones y las cualificaciones de flujos de trabajo de software son tan importantes para nosotros como para ti."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "TRAZADO DE RAYOS A UN PRECIO ASEQUIBLE",
                    "tr_specText": "Las tarjetas gráficas deben hacer algo más que limitarse a enviar píxeles a las pantallas: también deben acelerar la creación de imágenes hermosas. La tecnología de hardware de trazado de rayos integrada en los gráficos Intel®&nbsp;Arc™&nbsp;Pro serie A permite que tu software compatible tenga acceso a estos nuevos flujos de trabajo profesionales. Suele considerarse que el trazado de rayos solo está disponible en gráficos caros, pero la gama de gráficos profesionales más reciente de Intel rompe con esa percepción."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "VARIAS PANTALLAS GRANDES CON UNA PEQUEÑA GPU PARA PORTÁTILES",
                    "tr_specText": "Los gráficos Intel® Arc™ Pro para portátiles admiten hasta cuatro pantallas ultragrandes para mejorar la eficiencia de tu flujo de trabajo. Añade también compatibilidad con alto rango dinámico (HDR) y tendrás todo lo que necesitas para montar un sistema multipantalla en casa o en la oficina del que querrás presumir en redes sociales."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "POTENCIAL DE ANCHO DE BANDA DE MEMORIA COMPLETO",
                    "tr_specText": "El ancho de banda de la tarjeta gráfica es un factor importante en toda estación de trabajo. Si te equivocas puedes ralentizar los flujos de trabajo profesionales; por eso nos aseguramos de que la GPU Intel® Arc™ Pro serie A tenga compatibilidad con PCIe* 4.0 sin perder retrocompatibilidad. La memoria de gráficos se complementa con una alta velocidad de ancho de banda, lo que permite que tu software acceda aún más rápido a los datos del proyecto."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "HAZ QUE CADA FOTOGRAMA COBRE VIDA",
                    "tr_specText": "<span class='expandable_heading'>Potenciado por IA. Respaldado por Intel.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS es una tecnología de escalado mejorada por IA que aumenta tu rendimiento sin sacrificar la belleza de cada fotograma, para que puedas experimentar tu juego como debe ser."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "INMERSIÓN TOTAL<br/> EN TIEMPO REAL.<br/> REALISMO EN SU MÁXIMA EXPRESIÓN.",
                    "tr_specText": "Te esperan mundos más realistas. Con el trazado de rayos integrado, lo experimentarás todo, desde sombras y reflejos realistas hasta una iluminación magistral. En otras palabras, será una experiencia visual de juego que no querrás perderte."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "COMPARTE FÁCILMENTE TUS MEJORES PARTIDAS",
                    "tr_specText": "Dale a tu público el juego perfecto que se merece ver. Con las capacidades de codificación AV1 aceleradas por hardware de los gráficos Intel® Arc™, quedará cautivado por cada uno de tus movimientos. Una mayor calidad visual a la misma tasa de bits te permite dar lo mejor de ti al tiempo que ahorras ancho de banda."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Un espacio para la creatividad",
                    "tr_specText": "Nuevas y mejoradas experiencias de IA al alcance de tu mano. Con los aceleradores de IA avanzados integrados en los gráficos Intel® Arc™ de la serie A, pasa más tiempo creando y menos tiempo esperando en muchas aplicaciones.<br/><br/>Lleva tu creación de vídeo al siguiente nivel y lidera el camino con vídeo AV1 de alta calidad y almacenamiento eficiente, impulsado por las capacidades avanzadas de hardware AV1 integradas en los gráficos Intel® Arc™."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Todo lo que necesitas para hacer tu trabajo",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Rendimiento potente que es fino, ligero y diseñado para durar... puedes tenerlo todo. Con una gran duración de la batería, hermosas pantallas táctiles* y las unidades de estado sólido (SSD) más recientes, encontrarás lo que buscas en un PC con Windows 10.",
                "tr_noteSection": "*Depende del hardware",
                "titleArray": [
                  {
                    "tr_title": "Menos es más",
                    "tr_subtitle": "Los PC de hoy en día suelen usar menos energía, por lo que obtienes mayor autonomía; en promedio, más de ocho horas de reproducción de vídeo.**",
                    "tr_noteSection": "**La duración de la batería varía significativamente según el dispositivo, la configuración, el uso y otros factores."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "TODO LO QUE NECESITAS PARA HACER TU TRABAJO",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Rendimiento potente que es fino, ligero y diseñado para durar... puedes tenerlo todo. Con una gran duración de la batería, hermosas pantallas táctiles* y las unidades de estado sólido (SSD) más recientes, encontrarás lo que buscas en un PC con Windows 10.",
                "tr_noteSection": "*Depende del hardware",
                "titleArray": [
                  {
                    "tr_title": "MENOS ES MÁS",
                    "tr_subtitle": "Los PC de hoy en día suelen usar menos energía, por lo que obtienes mayor autonomía; en promedio, más de ocho horas de reproducción de vídeo.**",
                    "tr_noteSection": "**La duración de la batería varía significativamente según el dispositivo, la configuración, el uso y otros factores."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Optimizado para la&nbsp;seguridad&nbsp;y el&nbsp;rendimiento",
                "tr_pretitle": "Windows* 10 en modo S",
                "tr_subtitle": "Cuando se ejecuta en Windows* 10 en modo S, obtendrás una capa adicional de protección para trabajar, jugar y navegar con la confianza de que tu dispositivo es seguro."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "OPTIMIZADO PARA LA SEGURIDAD Y EL RENDIMIENTO",
                "tr_title": "Windows* 10 en modo S",
                "tr_subtitle": "Cuando se ejecuta en Windows* 10 en modo S, obtendrás una capa adicional de protección para trabajar, jugar y navegar con la confianza de que tu dispositivo es seguro."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Haz grandes cosas",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Rendimiento, precio y mucho donde elegir. Desde innovadores 2 en 1 hasta portátiles finos y ligeros, pantallas táctiles y teclados separables: no importa cuál sea tu presupuesto o tu estilo, podrás encontrar un ordenador con Windows* a tu medida."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "HAZ GRANDES COSAS",
                "tr_title": "Windows*",
                "tr_subtitle": "Rendimiento, precio y mucho donde elegir. Desde innovadores 2 en 1 hasta portátiles finos y ligeros, pantallas táctiles y teclados separables: no importa cuál sea tu presupuesto o tu estilo, podrás encontrar un ordenador con Windows* a tu medida."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memoria",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "¿CUÁNTA RAM NECESITAS?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diaria: DESDE 2 GB HASTA 8 GB DE&nbsp;RAM",
                    "tr_badgeSubTitle": "Perfecta para aquellos usuarios que utilizan sus ordenadores principalmente para procesar textos y navegar en Internet y que no tienden a centrarse en más de un programa a la vez."
                  },
                  {
                    "tr_badgeTitle": "Potente: DESDE 8 GB HASTA 16 GB DE&nbsp;RAM",
                    "tr_badgeSubTitle": "Con más RAM, tus aplicaciones no solo funcionarán más rápido, sino que también podrás ejecutar más al mismo&nbsp;tiempo."
                  },
                  {
                    "tr_badgeTitle": "Avanzada: MÁS DE 16&nbsp;GB DE RAM",
                    "tr_badgeSubTitle": "Si te interesan los videojuegos, la edición de fotos y vídeos o el modelado en 3D, necesitarás más memoria RAM para poder utilizar estos programas tan exigentes."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX]&nbsp;GB de RAM para la multitarea cotidiana",
                "tr_subtitle": "Con tu memoria podrás cargar y ejecutar con fluidez las aplicaciones básicas, como el procesamiento de textos y la navegación en Internet.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>de RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX]&nbsp;GB de RAM para una multitarea potente",
                "tr_subtitle": "Si usas varios programas al mismo tiempo, [memory_total]&nbsp;GB de memoria te permitirán cambiar entre ellos con facilidad.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>de RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX]&nbsp;GB de RAM para una multitarea avanzada",
                "tr_subtitle": "Disfrutarás de suficiente memoria para ejecutar varios programas exigentes al mismo tiempo sin ralentizar tu ordenador.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>de RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "Memoria de [Value1]&nbsp;GB = Memoria Intel®&nbsp;Optane™ de [Value2]&nbsp;GB + [Value3]&nbsp;GB de RAM",
                "tr_subtitle": "Esta combinación de soluciones de memoria te proporcionará rendimiento acelerado de disco duro de la memoria Intel®&nbsp;Optane™ además de más capacidad de respuesta de tu RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>de MEMORIA</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memoria Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Menos esperas. Más de lo que te gusta.",
                "tr_subtitle": "Cuando la memoria Intel® Optane™ funciona con la memoria del sistema, te ofrece un acceso más rápido a los programas que usas con más frecuencia, para que se inicien y carguen en cuanto los&nbsp;necesitas.",
                "tr_pretitle": "Memoria Intel® Optane™ de [XX] GB",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "¿CÓMO MEJORA ESTA MEMORIA MI ORDENADOR?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Creación de contenido más rápida",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Más capacidad de respuesta en los videojuegos",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Multitarea más sencilla",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Mayor velocidad de apertura de programas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memoria Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Acelera tu juego</span>",
                "tr_pretitle": "Memoria Intel® Optane™ de [XX] GB",
                "tr_pretitle1": "[XX] GB de memoria Intel® Optane™ aceleran tu experiencia de juego",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Inicia videojuegos hasta un <br/><span class=\"optane-game\">98&nbsp;% más rápido</span><br/>desde la unidad de datos con un procesador [optane_legal_processor_name] con memoria Intel®&nbsp;Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Carga niveles hasta <br/><span class=\"optane-game\">4,1&nbsp;veces más rápido</span><br/>desde la unidad de datos con un procesador [optane_legal_processor_name] con memoria Intel®&nbsp;Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Memoria Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Acelera tu juego</span>",
                "tr_pretitle": "Memoria Intel® Optane™ de [XX] GB",
                "tr_pretitle1": "[XX] GB de memoria Intel® Optane™ aceleran tu experiencia de juego",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Inicia videojuegos hasta un <br/><span class=\"optane-game\">98&nbsp;% más rápido</span><br/>desde la unidad de datos con un procesador [optane_legal_processor_name] con memoria Intel®&nbsp;Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Carga niveles hasta <br/><span class=\"optane-game\">4,1&nbsp;veces más rápido</span><br/>desde la unidad de datos con un procesador [optane_legal_processor_name] con memoria Intel®&nbsp;Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Memoria de [Value1]&nbsp;GB = Memoria Intel®&nbsp;Optane™ de [Value2]&nbsp;GB + [Value3]&nbsp;GB de RAM",
                "tr_subtitle": "Esta combinación de soluciones de memoria te proporcionará rendimiento acelerado de disco duro de la memoria Intel®&nbsp;Optane™ además de más capacidad de respuesta de tu RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX]&nbsp;GB de RAM para multitarea",
                    "tr_subtitle": "Si usas varios programas al mismo tiempo, [memory_total]&nbsp;GB de memoria te permitirán cambiar entre ellos con facilidad."
                  },
                  {
                    "tr_title": "Para tareas cotidianas",
                    "tr_subtitle": "Con tu memoria podrás cargar y ejecutar con fluidez las aplicaciones básicas, como el procesamiento de textos y la navegación en Internet."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX]&nbsp;GB de RAM",
                "tr_pretitle": "PARA HACER MÁS MULTITAREA",
                "tr_subtitle": "La RAM da a tu procesador acceso a archivos de programa, lo que permite que la CPU pueda acceder a ellos más rápido y trabajar en varias tareas a la vez.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Memoria estándar",
                    "tr_badgeSubTitle": "Hasta 8 GB de RAM"
                  },
                  {
                    "tr_badgeTitle": "Memoria avanzada",
                    "tr_badgeSubTitle": "Más de 8 GB y hasta 16 GB de RAM"
                  },
                  {
                    "tr_badgeTitle": "Memoria de élite",
                    "tr_badgeSubTitle": "Más de 16 GB de RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB para más multitarea",
                "tr_pretitle": "PARA HACER MÁS MULTITAREA",
                "tr_subtitle": "La RAM da a tu procesador acceso a archivos de programa, lo que permite que la CPU pueda acceder a ellos más rápido y trabajar en varias tareas a la vez.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Memoria estándar",
                    "tr_badgeSubTitle": "Hasta 8 GB de RAM"
                  },
                  {
                    "tr_badgeTitle": "Memoria avanzada",
                    "tr_badgeSubTitle": "Más de 8 GB y hasta 16 GB de RAM"
                  },
                  {
                    "tr_badgeTitle": "Memoria de élite",
                    "tr_badgeSubTitle": "Más de 16 GB de RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTA MEMORIA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Estándar",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>DE RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avanzada",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>DE RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "De élite",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>DE RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memoria",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "¿CÓMO SE COMPARA ESTA MEMORIA?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "¿CÓMO SE COMPARA ESTA MEMORIA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo<br/>actual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Diaria",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>DE RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Potente",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>DE RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avanzada",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>DE RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Almacenamiento",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "¿CON CUÁNTO PUEDE TU ORDENADOR?",
                "tr_subtitle": "No hay necesidad de adivinar. Te mostraremos exactamente lo que puedes almacenar en este ordenador.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Crea una biblioteca de</span> <br/><span class=\"storage-standard\">[num_songs] canciones<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>según un promedio de 5&nbsp;MB por canción",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Crea una biblioteca de </span><br/><span class=\"storage-standard\">[num_games] videojuegos<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>según un promedio de 40&nbsp;GB por videojuego",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Almacenamiento para </span><br/><span class=\"storage-standard\">[num_videos] vídeos<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>según un promedio de 5&nbsp;GB por vídeo",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Haz y almacena </span><br/><span class=\"storage-standard\">[num_photos] fotos<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>según un promedio de 4&nbsp;MB por foto",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "HDD de [capacity] GB"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "SSD de [capacity] GB"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "Memoria Intel® Optane™ de [total_optane_memory]&nbsp;GB + SSD de [convertedSSD]&nbsp;GB"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ALMACENA&shy;MIENTO",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity] GB de almacenamiento SSD",
                "tr_preTitle1": "[capacity] TB de almacenamiento SSD",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Este ordenador cuenta con una SSD con [capacity]&nbsp;GB de almacenamiento. Esto significa que podrás guardar un número impresionante de fotos, vídeos y programas y acceder a ellos mucho más rápido de lo que lo harías en una unidad de disco duro.",
                    "tr_title": "Un lugar para todo en tu ordenador"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Este ordenador cuenta con una SSD con [capacity]&nbsp;TB de almacenamiento. Esto significa que podrás guardar un número impresionante de fotos, vídeos y programas y acceder a ellos mucho más rápido de lo que lo harías en una unidad de disco duro.",
                    "tr_title": "Este dispositivo tiene un almacenamiento impresionante"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ALMACENA&shy;MIENTO</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ALMACENA&shy;MIENTO</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ALMACENA&shy;MIENTO",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity] GB de almacenamiento HDD",
                "tr_preTitle1": "[capacity] TB de almacenamiento HDD",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Las unidades de disco duro son una buena elección para aquellos que buscan una gran capacidad de almacenamiento directamente en sus ordenadores.",
                    "tr_title": "Un lugar para todo en tu ordenador"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Las unidades de disco duro son una buena elección para aquellos que buscan una gran capacidad de almacenamiento directamente en sus ordenadores.",
                    "tr_title": "Este dispositivo tiene un almacenamiento impresionante"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>DE ALMACENA&shy;MIENTO</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ALMACENA&shy;MIENTO",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "¿CON CUÁNTO PUEDE TU ORDENADOR?",
                "tr_subtitle": "No hay necesidad de adivinar. Te mostraremos exactamente lo que puedes almacenar en este ordenador.",
                "titleArray": [
                  {
                    "tr_title": "HDD DE [XX]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD DE [XX]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD Intel® de [total_capacity]&nbsp;GB acelerada por memoria Intel® Optane™ de [optane_memory_size]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD Intel® de [total_capacity] TB acelerada por la memoria Intel® Optane™ de [optane_memory_size]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD]&nbsp;GB + HDD de [convertedHDD]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD]&nbsp;TB + HDD de [convertedHDD]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD]&nbsp;GB + HDD de [convertedHDD]&nbsp;TB"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD]&nbsp;TB + HDD de [convertedHDD]&nbsp;TB"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ALMACENA&shy;MIENTO</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ALMACENA&shy;MIENTO</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ALMACENA&shy;MIENTO</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ALMACENA&shy;MIENTO</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ALMACENA&shy;MIENTO</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ALMACENA&shy;MIENTO</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ALMACENA&shy;MIENTO",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "Almacenamiento SSD de [convertedSSD]&nbsp;GB + HDD de [convertedHDD]&nbsp;GB",
                "tr_preTitle1": "Almacenamiento SSD de [convertedSSD]&nbsp;TB + HDD de [convertedHDD]&nbsp;GB",
                "tr_preTitle2": "Almacenamiento SSD de [convertedSSD]&nbsp;GB + HDD de [convertedHDD]&nbsp;TB",
                "tr_preTitle3": "Almacenamiento SSD de [convertedSSD]&nbsp;TB + HDD&nbsp;de&nbsp;[convertedHDD]&nbsp;TB",
                "titleArray": [
                  {
                    "tr_title": "Un lugar para todo en tu ordenador",
                    "tr_title1": "Este dispositivo tiene un almacenamiento impresionante",
                    "tr_subtitle": "Este ordenador cuenta con una SSD con [convertedSSD]&nbsp;GB de almacenamiento y una unidad de disco duro de [convertedHDD]&nbsp;GB. Esto te dará una enorme capacidad junto con un rendimiento de almacenamiento rápido.",
                    "tr_subtitle1": "Este ordenador cuenta con una SSD con [convertedSSD]&nbsp;TB de almacenamiento y una unidad de disco duro de [convertedHDD]&nbsp;GB. Esto te dará una enorme capacidad junto con un rendimiento de almacenamiento rápido.",
                    "tr_subtitle2": "Este ordenador cuenta con una SSD con [convertedSSD]&nbsp;GB de almacenamiento y una unidad de disco duro de [convertedHDD]&nbsp;TB. Esto te dará una enorme capacidad junto con un rendimiento de almacenamiento rápido.",
                    "tr_subtitle3": "Este ordenador cuenta con una SSD con [convertedSSD]&nbsp;TB de almacenamiento y una unidad de disco duro de [convertedHDD]&nbsp;TB. Esto te dará una enorme capacidad junto con un rendimiento de almacenamiento rápido."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ALMACENA&shy;MIENTO</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ALMACENA&shy;MIENTO</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ALMACENA&shy;MIENTO",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Accede rápidamente a almacenamiento de alta capacidad gracias a la memoria Intel® Optane™ con&nbsp;SSD.",
                "tr_title": "Ideal para archivos de gran tamaño",
                "tr_pretitle": "SSD Intel® de [total_capacity]&nbsp;GB acelerada por memoria Intel® Optane™ de [optane_memory_size]&nbsp;GB",
                "tr_pretitle1": "SSD Intel® de [total_capacity] TB acelerada por la memoria Intel® Optane™ de [optane_memory_size]&nbsp;GB",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Esta revolucionaria solución de almacenamiento es la primera en combinar la memoria Intel® Optane™ y una SSD en el mismo chip. Esta memoria te permite:",
                    "tr_title": "¿QUÉ ES LA MEMORIA INTEL® OPTANE™ CON SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Abrir lo que necesites con rapidez",
                    "tr_badgeSubTitle": "Al precargar archivos y programas utilizados con frecuencia, puedes pasar más tiempo creando y menos esperando.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Disfrutar de almacenamiento y velocidad",
                    "tr_badgeSubTitle": "Contarás con el espacio que necesitas para archivos multimedia y el rápido rendimiento de una SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Tener más capacidad de respuesta con el tiempo",
                    "tr_badgeSubTitle": "La memoria Intel® Optane™ con SSD aprende cómo trabajas, lo que hace que tu ordenador sea más eficiente.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ALMACENA&shy;MIENTO",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Ten tu contenido multimedia siempre a mano con una gran cantidad de espacio de almacenamiento fiable.",
                "tr_title": "Espacio para todas tus&nbsp;fotos,&nbsp;vídeos y&nbsp;canciones"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pantalla",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] millones de píxeles para deslizar, pellizcar y&nbsp;asombrar",
                "tr_subtitle": "Interactúa con tu ordenador como si fuera un smartphone con controles intuitivos y detalles más vivos y nítidos.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]''<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolución: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Píxeles: [screenResolution] millones"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Pantalla de [screenDiagonal] pulgadas",
                    "tr_badgeSubTitle": "Altura de pantalla: [screenHeight] pulgadas",
                    "tr_badgePreTitle": "Ancho de pantalla: [YY] pulgadas"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Pantalla táctil",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pantalla",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Sumérgete en [screenResolution] millones de píxeles",
                "tr_subtitle": "La clave está en los píxeles. Cuantos más tengas, más nítidas y más vivas serán tus imágenes.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]''<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolución: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Píxeles: [screenResolution] millones"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Pantalla de [screenDiagonal] pulgadas",
                    "tr_badgeSubTitle": "Altura de pantalla: [screenHeight] pulgadas",
                    "tr_badgePreTitle": "Ancho de pantalla: [YY] pulgadas"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pantalla",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] pulgadas <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "LA VENTANA A TU MUNDO DE VIDEOJUEGOS",
                "tr_subtitle": "Al igual que en un televisor, lo más importante en una pantalla de videojuegos es su tamaño y resolución.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolución: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Píxeles: [screenResolution] millones"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Pantalla de [screenDiagonal] pulgadas",
                    "tr_badgeSubTitle": "Altura de pantalla: [screenHeight] pulgadas",
                    "tr_badgePreTitle": "Ancho de pantalla: [screenWidth] pulgadas"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Pantalla táctil"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pantalla",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Este dispositivo tiene una pantalla impresionante",
                "tr_subtitle": "Con una cantidad de píxeles más de ocho veces superior a la HD, tus vídeos y videojuegos alcanzarán un nuevo nivel de nitidez y color.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolución: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Píxeles: [screenResolution] millones"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Pantalla de [screenDiagonal] pulgadas",
                    "tr_badgeSubTitle": "Altura de pantalla: [screenHeight] pulgadas",
                    "tr_badgePreTitle": "Ancho de pantalla: [YY] pulgadas"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pantalla",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] millones de píxeles para deslizar y tocar",
                    "tr_subTitle": "Ve e interactúa con las cosas que te gustan con detalles más nítidos e intensos."
                  },
                  {
                    "tr_title": "[screenResolution] millones de píxeles para mejorar tu mundo digital",
                    "tr_subTitle": "La clave está en los píxeles. Cuantos más tengas, más nítidas y más vivas serán tus imágenes."
                  },
                  {
                    "tr_title": "Este dispositivo tiene una pantalla impresionante",
                    "tr_subTitle": "Con casi cuatro veces más píxeles que la HD, tus vídeos y videojuegos te llevarán a todo un nuevo nivel de nitidez y color."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividad",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "La tecnología Thunderbolt™ 3 ofrece la conexión más rápida y versátil con cualquier base, pantalla o dispositivo de datos.",
                "tr_title": "Un puerto que lo hace todo",
                "tr_title1": "UN PUERTO QUE LO HACE TODO",
                "titleArray": [
                  {
                    "tr_title": "¿CÓMO PUEDO UTILIZAR LA TECNOLOGÍA THUNDERBOLT™ 3?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tecnología Thunderbolt™ 3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "Monitores 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Conecta pantallas de una resolución, contraste y color sensacionales"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Tarjeta gráfica externa.",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Proporciona a cualquier ordenador una gran mejora de gráficos al instante"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Red rápida",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Proporciona conexiones peer-to-peer rápidas"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividad",
                "detailBGUrl": "",
                "tr_subtitle": "La tecnología Thunderbolt™ 3 transfiere datos a una velocidad más alta que los USB convencionales, lo que te permite mover archivos de forma rápida e integrar fácilmente periféricos como múltiples monitores o tarjetas gráficas externas.",
                "tr_title": "Tecnología Thunderbolt™ 3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "DATOS A LA VELOCIDAD DEL RAYO",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480&nbsp;Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5&nbsp;Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Tecnología Thunderbolt™ 3",
                    "tr_badgeSubTitle": "40&nbsp;Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividad",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Un puerto y un cable universales que admiten todos tus accesorios favoritos</li> <li>Carga de portátiles fiable</li> <li>Certificación sólida que ofrece tranquilidad</li></ul>",
                "tr_title": "Conectividad por cable verdaderamente universal",
                "tr_title1": "CONECTIVIDAD POR CABLE VERDADERAMENTE UNIVERSAL",
                "tr_noteSection": "<sup>†</sup> Basado en requisitos de especificación mínimos",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "¿CÓMO PUEDO UTILIZAR LA TECNOLOGÍA THUNDERBOLT™ 4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tecnología Thunderbolt™ 4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Crear",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Conecta un monitor externo y almacenamiento rápido para ver y editar fotos y vídeos."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Conecta",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Crea una configuración de escritorio sencilla y despejada conectando tu portátil a todos tus accesorios con&nbsp;un&nbsp;solo&nbsp;cable."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Juega",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Añade más capacidad de SSD y HDD con velocidades de hasta 3000&nbsp;MB/s para almacenar bibliotecas de videojuegos y archivos multimedia."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Rendimiento máximo<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4 veces más rápido que el USB* 3.2 <br/>2 veces más rápido que el HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividad",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Disfruta del mismo rendimiento asombroso de la tecnología Thunderbolt™ 3 con aún más capacidades que te permiten conectar y utilizar fácilmente las últimas bases Thunderbolt™, monitores, almacenamiento rápido o cualquier accesorio USB*.",
                "tr_title": "Tecnología Thunderbolt™ 4",
                "tr_pretitle": "CONECTIVIDAD POR CABLE VERDADERAMENTE UNIVERSAL",
                "tr_noteSection": "<sup>†</sup> Basado en requisitos de especificación mínimos",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Un cable universal Thunderbolt™ 4 puede reemplazar todos tus cables USB-C* para que la selección de cables sea rápida y fácil."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Las bases con tecnología Thunderbolt™ 4 ofrecen hasta 4 puertos Thunderbolt™ para conectar con más flexibilidad cualquiera de tus accesorios."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Rendimiento máximo<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4 veces más rápido que el USB* 3.2 <br/>2 veces más rápido que el HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividad",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Un puerto y un cable universales que admiten todos tus accesorios favoritos</li> <li>Carga de portátiles fiable</li> <li>Certificación sólida que ofrece tranquilidad</li></ul>",
                "tr_title": "Maximiza tu experiencia con los accesorios Thunderbolt™",
                "tr_noteSection": "<sup>†</sup> Basado en requisitos de especificación mínimos",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "¿CÓMO PUEDO UTILIZAR LA TECNOLOGÍA THUNDERBOLT™ 4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Crear",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Conecta un monitor externo y almacenamiento rápido para ver y editar fotos y vídeos."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Conecta",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Crea una configuración de escritorio sencilla y despejada conectando tu portátil a todos tus accesorios con&nbsp;un&nbsp;solo&nbsp;cable."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Juega",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Añade más capacidad de SSD y HDD con velocidades de hasta 3000&nbsp;MB/s para almacenar bibliotecas de videojuegos y archivos multimedia."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Rendimiento máximo<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4 veces más rápido que el USB* 3.2 <br/>2 veces más rápido que el HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Los tipos de tecnología Wi‑Fi más habituales son:",
                "tr_title": "ACERCA DE LOS ESTÁNDARES WI‑FI",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "El Wi-Fi 6 ofrece mayores velocidades incluso cuando muchos usuarios utilizan la misma señal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Con la innovación en Wi-Fi más reciente de Intel, puedes lograr velocidades más rápidas que el Wi-Fi gigabit incluso con varios usuarios conectados a la misma señal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "El mayor avance de Wi‑Fi en 20 años, Wi‑Fi 6E abre nuevos canales de alta velocidad para un rendimiento y una fiabilidad mejorados, y menos interferencias."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "El Wi-Fi 6 ofrece mayores velocidades incluso cuando muchos usuarios utilizan la misma señal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Todas las ventajas de la Wi-Fi 6E junto con el motor de priorización Intel® Killer™, que cambia el ancho de banda a los sitios web y las aplicaciones que más lo necesitan."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Con menos competencia en tus canales de alta velocidad, disfrutarás de rendimiento y fiabilidad mejorados."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6",
                    "tr_badgeSubTitle": "El Wi-Fi 6 ofrece mayores velocidades incluso cuando muchos usuarios utilizan la misma señal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Con menos interferencia de Wi-Fi 6 y el rendimiento inteligente del motor de priorización Intel® Killer™, tu experiencia en línea será más rápida y con mayor capacidad de respuesta.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Con menos competencia en tus canales de alta velocidad, disfrutarás de rendimiento y fiabilidad mejorados."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Velocidades Wi-Fi para ordenadores superiores a 5 Gbps con latencia ultrabaja: comparte archivos en segundos en lugar de en minutos."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) es la nueva tecnología que ofrece fiabilidad, latencia y velocidades extremas gracias a la optimización de redes mediante IA."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "El Wi-Fi 6 ofrece mayores velocidades incluso cuando muchos usuarios utilizan la misma señal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) permite alcanzar velocidades de más de 1 Gbps con fiabilidad y baja latencia a través del canal de 6 GHz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Mayor eficiencia, capacidad y velocidad que el Wi-Fi 5 en redes congestionadas."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Mantén una mejor conexión con Wi&#8209;Fi&nbsp;[XX] y disfruta de Internet con&nbsp;velocidades más rápidas<sup>†</sup> y&nbsp;mayor&nbsp;fiabilidad.",
                "tr_title": "Consigue un mejor alcance y velocidad",
                "tr_noteSection": "<sup>†</sup>El rendimiento inalámbrico y/o la distancia reales variarán.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "En la era de los videojuegos en línea y multijugador, permanecer conectado es mucho más importante que antes. Además de la velocidad, también necesitas una señal inalámbrica que pueda mantener esas velocidades a distancia.",
                "tr_title": "[XX]",
                "tr_pretitle": "LA CONEXIÓN ES LA CLAVE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Conéctate con Intel® Wi‑Fi 6 (Gig+) a los rúteres Wi‑Fi 6 que admiten el canal de 160 MHz para conexiones más rápidas, mayor fiabilidad y más usuarios activos.",
                "tr_title": "Velocidades casi 3 veces más rápidas con Intel® <span class='no-warp'>Wi-Fi 6  </span>(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Que la Wi‑Fi AC estándar. Algunas funciones solo están disponibles con ciertas SKU. Consulta con el fabricante para obtener más información. Para obtener más información sobre Wi‑Fi, visita intel.com/wifi6disclaimers."
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Wi-Fi 6 de doble banda ofrece una conectividad de nivel básico para tareas esenciales, como correo electrónico o navegación por Internet, con un mejor rendimiento en redes congestionadas.<sup>9</sup>",
                "tr_title": "Wi-Fi esencial",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "En la era de los videojuegos en línea y multijugador, permanecer conectado es mucho más importante que antes. Además de la velocidad, también necesitas una señal inalámbrica que pueda mantener esas velocidades a distancia.",
                "tr_title": "[XX]",
                "tr_pretitle": "LA CONEXIÓN ES LA CLAVE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Con estos nuevos canales Wi‑Fi no tendrás que compartir el ancho de banda con los dispositivos más antiguos de tu vecindad, por lo que conseguirás una transmisión fluida, navegación por Internet rápida y trabajo y aprendizaje a distancia sin problemas.",
                "tr_title": "Conéctate sin competencia",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Mantén una mejor conexión con [XX] y disfruta de Internet con&nbsp;velocidades más rápidas<sup>†</sup> y&nbsp;mayor&nbsp;fiabilidad.",
                "tr_title": "Consigue un mejor alcance y velocidad",
                "tr_noteSection": "<sup>†</sup>El rendimiento inalámbrico y/o la distancia reales variarán.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "En la era de los videojuegos en línea y multijugador, permanecer conectado es mucho más importante que antes. Además de la velocidad, también necesitas una señal inalámbrica que pueda mantener esas velocidades a distancia.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "LA CONEXIÓN ES LA CLAVE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Wi‑Fi ultrarrápido integrado",
                "tr_subtitle": "No pierdas la conexión con tu mundo en línea."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Con carriles de alta velocidad dedicados para menos interferencias y el motor de priorización Intel® Killer™ que da prioridad al juego, los videojuegos en línea acaban de conseguir una mejora considerable.",
                "tr_title": "Deja atrás a la competencia",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Con carriles de alta velocidad dedicados para menos interferencias y el motor de priorización Intel® Killer™ que da prioridad al juego, los videojuegos en línea acaban de conseguir una mejora considerable.",
                "tr_title": "[XX]",
                "tr_pretitle": "DEJA ATRÁS A LA COMPETENCIA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Como ahora hay carriles de alta velocidad dedicados, que disminuyen las interferencias y priorizan los videojuegos, los juegos en línea acaban de dar el salto al siguiente nivel.",
                "tr_title": "[XX]",
                "tr_pretitle": "DEJA ATRÁS A LA COMPETENCIA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi acelera automáticamente el tráfico de red para las aplicaciones que más lo necesitan para que tus vídeos de transmisión tengan detalles nítidos y menos búfer.",
                "tr_title": "Una forma más rápida e inteligente de conectarse.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "En la era de los videojuegos en línea y multijugador, permanecer conectado es mucho más importante que antes. Además de la velocidad, también necesitas una señal inalámbrica que pueda mantener esas velocidades a distancia.",
                "tr_title": "[XX]",
                "tr_pretitle": "LA CONEXIÓN ES LA CLAVE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Mantén una mejor conexión con Wi-Fi 5 y disfruta de Internet con velocidades más rápidas<sup>†</sup> y más fiabilidad.",
                "tr_title": "Consigue un mejor alcance y velocidad",
                "tr_noteSection": "<sup>†</sup>El rendimiento inalámbrico y/o la distancia reales variarán.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "En la era de los videojuegos en línea y multijugador, permanecer conectado es mucho más importante que antes. Además de la velocidad, también necesitas una señal inalámbrica que pueda mantener esas velocidades a distancia.",
                "tr_title": "[XX]",
                "tr_pretitle": "DEJA ATRÁS A LA COMPETENCIA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Las características de Wi-Fi 7 ampliarán y mejorarán las de las generaciones anteriores de Wi-Fi. Esto no solo significará velocidades más rápidas, sino también una mejora considerable de la capacidad de respuesta y la fiabilidad para ofrecer experiencias envolventes al consumidor y futuras tecnologías sofisticadas.",
                "tr_title": "Redes informáticas similares a las cableadas",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Las características de Wi-Fi 7 ampliarán y mejorarán las de las generaciones anteriores de Wi-Fi. Esto no solo significará velocidades más rápidas, sino también una mejora considerable de la capacidad de respuesta y la fiabilidad para ofrecer experiencias envolventes al consumidor y futuras tecnologías sofisticadas.",
                "tr_title": "[XX]",
                "tr_pretitle": "TRANSFORMACIÓN DE LA EXPERIENCIA DEL USUARIO",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Mejor velocidad, latencia y fiabilidad con la optimización de redes mediante IA",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Mejor velocidad, latencia y fiabilidad con la optimización de redes mediante IA.",
                "tr_title": "[XX]",
                "tr_pretitle": "CAPACIDAD DE RESPUESTA SIMILAR A LA DE UNA RED CABLEADA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batería",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "¿Cómo se mide?",
                    "tr_badgeSubTitle": "La mayoría de los fabricantes expresan la autonomía en horas. Aunque este cálculo no es preciso al 100 %, debería ayudarte a comparar la autonomía de un ordenador frente a otro."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batería",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PARA JUGAR EN LOS DESPLAZAMIENTOS",
                "tr_subtitle": "Con la potencia de los actuales portátiles para videojuegos, no hay razón para estar atado a un equipo de sobremesa. Ahora puedes ir con tus videojuegos a cualquier lugar.",
                "tr_title": "[Dynamic.battery.values.hours] horas de autonomía"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batería",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Desenchufa y sigue conectado con tus apps, juegos y música durante&nbsp;horas.",
                "tr_title": "[XX] horas de transmisión con una sola carga<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Basado en la autonomía indicada por el fabricante del ordenador.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] horas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batería",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Desenchufa y sigue conectado con tus apps, juegos y música durante&nbsp;horas.",
                "tr_title": "[Dynamic.battery.values.hours] horas de autonomía en desplazamientos<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Basado en la autonomía indicada por el fabricante del ordenador.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] horas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batería",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Desenchufa y sigue conectado con tus apps, juegos y música durante&nbsp;horas.",
                "tr_title": "[Dynamic.battery.values.hours] horas para tener autonomía durante todo el día con una sola carga<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Basado en la autonomía indicada por el fabricante del ordenador.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] horas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batería",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Este ordenador optimiza la autonomía para que la batería dure más tiempo y te da al menos 4 horas de autonomía con una carga de 30 minutos.<sup>4</sup>",
                "tr_title": "Desconecta por más tiempo con [Dynamic.battery.values.hours] horas de autonomía<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Basado en la autonomía indicada por el fabricante del ordenador.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] horas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Inicio",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Por qué este ordenador",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Ver todas las especificaciones",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "A distancia",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Móvil",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Información legal",
        "tr_paragraph_array": [
          "El software y las cargas de trabajo utilizados para las pruebas de rendimiento pueden haber sido optimizados para el uso con microprocesadores Intel® exclusivamente. Las pruebas de rendimiento, como SYSmark* y MobileMark*, se han medido utilizando componentes, funciones y sistemas informáticos específicos. Cualquier cambio realizado en cualquiera de estos factores puede hacer que los resultados varíen. Es conveniente consultar otras fuentes de información y pruebas de rendimiento que le ayudarán a evaluar a fondo sus posibles compras, incluido el rendimiento de un producto concreto en combinación con otros. Para obtener información más completa sobre los resultados del análisis de rendimiento, visita www.intel.com/benchmarks.",
          "¹Los productos Intel® Wi-Fi 6 (Gig+) admiten canales de 160 MHz opcionales, lo que permite alcanzar las velocidades teóricas máximas más rápidas posibles (2402&nbsp;Mbps) en productos Wi-Fi para ordenador 2&nbsp;x&nbsp;2 802.11ax estándar. Los productos Intel® Wi-Fi 6 (Gig+) de gama alta permiten velocidades teóricas máximas de 2 a 4 veces más rápidas en comparación con los productos Wi-Fi para ordenador 802.11ax estándar de 2&nbsp;x&nbsp;2 (1201&nbsp;Mbps) o 1&nbsp;x&nbsp;1 (600&nbsp;Mbps), que solo cumplen el requisito obligatorio de canales de 80&nbsp;MHz.",
          "²Recomendación solo con fines informativos. Consulte otras fuentes a la hora de tomar una decisión de compra.",
          "³Las características y ventajas de las tecnologías Intel dependen de la configuración del sistema, y podrían requerir la activación de hardware, software o servicios. El rendimiento variará en función de la configuración del sistema. Ningún sistema informático es absolutamente seguro. Consulte al fabricante del sistema o al distribuidor minorista, o bien puede encontrar más información en intel.com.",
          "⁴Para sistemas con pantallas FHD, cuando se utilicen para navegación en Internet inalámbrica. Cuando se apaga, desde el nivel de apagado predeterminado del OEM.",
          "⁵En comparación con otras tecnologías para conexión de E/S en ordenadores, incluyendo eSATA, USB e IEEE 1394* FireWire. El rendimiento variará dependiendo del hardware y software específicos que utilice. Debe utilizar un dispositivo con tecnología Thunderbolt™.",
          "⁶Casi 3 veces más rápidas: 802.11ax 2 x 2 de 160 MHz permite velocidades de datos teóricas máximas de 2402 Mbps. Aproximadamente 3 veces más rápido que la Wi‑Fi 802.11ac estándar 2 x 2 de 80 MHz (867 Mbps), tal y como se documenta en las especificaciones del estándar inalámbrico IEEE 802.11* y requiere el uso de rúteres de red inalámbrica 802.11ax configurados de forma similar.",
          "⁷Reducción de latencia del 75&nbsp;%: Se basa en datos de simulación de Intel (79&nbsp;%) de 802.11ax con y sin OFDMA utilizando 9 clientes. La latencia media sin OFDM es de 36&nbsp;ms; con OFDMA la latencia media se reduce a 7,6&nbsp;ms. La mejora en latencia requiere que el rúter 802.11ax (Wi-Fi 6) y todos los clientes admitan OFDMA.",
          "⁸Basado en la comparación de la velocidad de datos teórica máxima en la especificación 802.11, Wi‑Fi 6/6E (802.11ax) 2 x 2 de 160 MHz puede ofrecer 2402 Mbps comparada con Wi‑Fi 5 (802.11ac) 2 x 2 de 80 MHz, que puede ofrecer 867 Mbps, lo que equivale a velocidades 2,8 veces más rápidas.",
          "⁹La mejor Wi‑Fi 6 de su clase: Los productos Intel® Wi‑Fi 6 (Gig+) admiten canales de 160 MHz opcionales, lo que permite alcanzar las velocidades teóricas máximas más rápidas posibles (2402 Mbps) en productos Wi‑Fi para ordenador 2 x 2 802.11ax estándar. Los productos Intel® Wi‑Fi 6 (Gig+) de gama alta permiten velocidades teóricas máximas de 2 a 4 veces más rápidas en comparación con los productos Wi‑Fi para ordenador 802.11ax estándar de 2 x 2 (1201 Mbps) o 1 x 1 (600 Mbps), que solo cumplen el requisito obligatorio de canales de 80 MHz.",
          "Solo SKU seleccionadas, la funcionalidad varía en función del diseño OEM. Consulte con el OEM o el minorista para obtener más información. Para obtener más información sobre Wi‑Fi, visite intel.com/wifi6disclaimers.",
          "¹⁰La tecnología Thunderbolt™ 4, a 40&nbsp;Gbps, ofrece la solución de cable más rápida, simple y fiable de cualquier estación de acoplamiento, pantalla o dispositivo de datos en comparación con otras tecnologías de conexión de E/S para ordenadores, como eSATA*, USB* y IEEE 1394* FireWire.",
          "¹¹Requiere un rúter basado en 802.11ax que admita OFDMA y múltiples clientes en la red con compatibilidad con AX. Es posible alcanzar mejor rendimiento en entornos densos con la funcionalidad OFDMA compatible con clientes y puntos de acceso 802.11ax. 2&nbsp;Gbps basados en supuestos de aproximadamente el 70&nbsp;% de las velocidades de datos teóricas máximas que indica la especificación IEEE 802.11* para 802.11ax de 160&nbsp;MHz y 2402&nbsp;Mbps.",
          "¹²Según medición realizada con una carga de trabajo de apertura de documentos con actividad en segundo plano en la que se compara el procesador Intel® Core™ i7-8565U de 8<sup>a</sup> generación (SSD TLC de 512 GB) con el procesador Intel® Core™ i7-8565U de 8<sup>a</sup> generación (memoria Intel® Optane™ H10 de 32 GB + 512 GB con almacenamiento de estado sólido).",
          "¹³Intel® Thread Director está integrado en procesadores Intel® Core™ de 12<sup>a</sup> generación y ayuda a que los sistemas operativos canalicen de forma más inteligente las cargas de trabajo al núcleo adecuado. No se requiere ninguna acción por parte del usuario. Consulte intel.com para obtener más información.",
          "¹⁴No disponible en ciertos procesadores Intel® Core™ de 12<sup>a</sup> generación. En la arquitectura híbrida de rendimiento se combinan dos nuevas microarquitecturas de núcleos, P-cores y E-cores, en un solo circuito integrado del procesador. Algunos procesadores Intel® Core™ de 12<sup>a</sup> generación (ciertos procesadores Intel® Core™ i5 de 12<sup>a</sup> generación y versiones anteriores) no son compatibles con la arquitectura híbrida de rendimiento, solo los P-cores.",
          "¹⁵Según la medición de carga de trabajo de inicio de videojuegos comparando el procesador Intel® Core™ i7-8750H de 8<sup>a</sup> generación (módulo de memoria Intel® Optane™ de 32 GB + Intel® SSD PCIe* de 256 GB + HDD de 1 TB) con el procesador Intel® Core™ i7-8750H de 8<sup>a</sup> generación (Intel® SSD PCIe* de 256 GB + HDD de 1 TB). Carga de trabajo de inicio de videojuegos: carga de trabajo desarrollada por Intel midiendo el tiempo empleado en iniciar Total War*: WARHAMMER* II compilación: 5577.0 y alcanzar el menú principal con los vídeos introductorios deshabilitados (Iniciar). Configuraciones de aceleración de disco de datos de la memoria Intel® Optane™: procesador Intel® Core™ i7-8750H, PL1 = 45 W de TDP, 6N/12H, turbo hasta 4 GHz en sistema OEM de preproducción, gráficos: NVIDIA* GeForce* GTX 1070, memoria: 2 x 4 GB de DDR4, almacenamiento: Intel® SSD serie 760p de 256 GB (PCIe*) + HDD de 1 TB + memoria Intel® Optane™ de 32 GB, SO: Windows* 10 RS3 compilación 1709, MCU 0x84; procesador Intel® Core™ i7-8750H, PL1 = 45 W de TDP, 6N/12H, turbo hasta 4 GHz en sistema OEM de preproducción, gráficos: NVIDIA* GeForce* GTX 1070, memoria: 2 x 4 GB de DDR4, almacenamiento: Intel® SSD serie 760p de 256 GB (PCIe*) + HDD de 1 TB, SO: Windows* 10 RS3 compilación 1709, MCU 0x84.",
          "¹⁶Según mediciones de carga de trabajo de carga de niveles de videojuegos comparando el procesador Intel® Core™ i7-8750H de 8<sup>a</sup> generación (módulo de memoria Intel® Optane™ de 32 GB) con el procesador Intel® Core™ i7-8750H de 8<sup>a</sup> generación (solo con HDD). Carga de trabajo de carga de niveles de videojuegos: carga de trabajo desarrollada por Intel midiendo el tiempo empleado desde el menú principal hasta la finalización de la carga del nivel en Total War*: WARHAMMER* II compilación: 5577.0. Configuraciones de aceleración de disco de datos de la memoria Intel® Optane™: procesador Intel® Core™ i7-8750H, PL1 = 45 W de TDP, 6N/12H, turbo hasta 4 GHz en sistema OEM de preproducción, gráficos: NVIDIA* GeForce* GTX 1070, memoria: 2 x 4 GB de DDR4, almacenamiento: Intel® SSD serie 760p de 256 GB (PCIe*) + HDD de 1 TB + memoria Intel® Optane™ de 32 GB, SO: Windows* 10 RS3 compilación 1709, MCU 0x84; procesador Intel® Core™ i7-8750H, PL1 = 45 W de TDP, 6N/12H, turbo hasta 4 GHz en sistema OEM de preproducción, gráficos: NVIDIA* GeForce* GTX 1070, memoria: 2 x 4 GB de DDR4, almacenamiento: Intel® SSD serie 760p de 256 GB (PCIe*) + HDD de 1 TB, SO: Windows* 10 RS3 compilación 1709, MCU 0x84.",
          "¹⁷En Performance-cores. El rendimiento varía según el uso, la configuración y otros factores. Más información en www.intel.com/PerformanceIndex.",
          "¹⁸Alterar la frecuencia del reloj o el voltaje puede dañar o reducir la vida útil del procesador y otros componentes del sistema, y puede reducir la estabilidad del sistema y su rendimiento. Es posible que no se puedan aplicar las garantías de los productos si el procesador es manipulado por encima de sus especificaciones. Consulte con los fabricantes del sistema y de los componentes para obtener más detalles.",
          "¹⁹Según mediciones de la inigualable combinación de capacidades de seguridad por encima y por debajo del SO, protecciones de aplicaciones y datos y protecciones avanzadas frente a amenazas ofrecidas para empresas de cualquier tamaño por la plataforma Intel vPro®, además de la postura de Intel de que la seguridad es lo primero en cualquier diseño, fabricación y asistencia de producto. Todos los ordenadores empresariales construidos en la plataforma Intel vPro® han sido validados según rigurosas especificaciones, incluidas las exclusivas funciones de seguridad basadas en hardware. Consulte www.intel.com/PerformanceIndex (plataformas) para obtener más información. Ningún producto o componente es completamente seguro.",
          "²⁰Intel® Connectivity Performance Suite está disponible solo en sistemas basados en Windows*. Las pruebas de Over The Air (OTA) Wi-Fi de Intel se realizaron en una red congestionada con y sin las funcionalidades de priorización del tráfico inalámbrico y conmutación inteligente del punto de acceso que ofrece la aplicación de software ICPS. Consulte www.intel.com/PerformanceIndex (conectividad) para obtener más información. Los resultados pueden variar.",
          "²¹En comparación con la generación anterior de procesadores.",
          "²²Los gráficos Intel® Arc™ son los primeros del mercado en admitir codificación AV1 (a partir del primer trimestre de 2022). Más información en https://edc.intel.com/content/www/es/es/<wbr>products/performance/benchmarks/overview/.",
          "²³Según las características únicas de los diseños Intel® Evo™, que también se verifican para cumplir con los umbrales de las experiencias clave de usuarios móviles. Pruebas realizadas en febrero de 2022.",
          "²⁴La solución Intel® Unison™ solo está disponible actualmente en diseños Intel® Evo™ seleccionados en ordenadores con tecnología Windows* y solo se empareja con teléfonos basados en Android o iOS; todos los dispositivos deben ejecutar una versión del sistema operativo compatible.",
          "²⁵Las velocidades máximas de memoria se asocian con configuraciones de 1 DIMM por canal (1DPC). Cargar DIMM adicionales en cualquier canal puede afectar a la velocidad máxima de memoria. Hasta DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 y DDR5-5200 1Rx8, 1Rx16, 2Rx8 en determinadas SKU. La capacidad máxima de memoria se puede lograr con configuraciones 2DPC.",
          "²⁶En comparación con el Wi-Fi estándar. Consulte intel.com/performance-wireless para obtener información. Los resultados pueden variar.",
          "²⁷La arquitectura híbrida de rendimiento combina dos microarquitecturas de núcleos, los P-cores y los E-cores, en un solo chip de procesador, que se incorporó por primera vez en los procesadores Intel®&nbsp;Core™ de 12<sup>a</sup> generación. Algunos procesadores Intel®&nbsp;Core™ de 12<sup>a</sup> generación y más recientes no poseen arquitectura híbrida de rendimiento, solo cuentan con P-cores, y tienen el mismo tamaño de caché que los de la generación anterior. Consulte ark.intel.com para obtener más información sobre las SKU.",
          "²⁸Intel® Thread Director se integra en el hardware y solo se incluye en configuraciones de arquitectura híbrida de rendimiento de los procesadores Intel® Core™ de 12<sup>a</sup> generación o más recientes; se requiere la habilitación del SO. Las características y la funcionalidad disponibles varían según el SO.",
          "²⁹Intel® Thread Director se integra en el hardware y solo se incluye en configuraciones de arquitectura híbrida de rendimiento de los procesadores Intel® Core™ de 12<sup>a</sup> y 13<sup>a</sup> generación; se requiere la habilitación del SO. Las características y la funcionalidad disponibles varían según el SO.",
          "³⁰Según el rendimiento de la plataforma de validación de referencia medido en el procesador Intel® Core™ Ultra para portátiles (Serie 2, nombre en código: Lunar Lake) frente al procesador Intel® Core™ Ultra para portátiles (Serie 1, nombre en código: Meteor Lake) en 3DMark Time Spy. Consulte intel.com/performanceindex para obtener más información. Los resultados pueden variar.",
          "³¹El desenfoque del fondo y el seguimiento facial solo están disponibles en sistemas con Windows*.",
          "³²Los productos Wi-Fi 7 pueden acceder a canales de 320 MHz en la banda de 6 GHz y a nuevas combinaciones de canales de 160 MHz en la de 5 GHz gracias a la innovadora función Multi-Resource Unit Puncturing.",
          "³³De acuerdo con las especificaciones del estándar inalámbrico IEEE y las velocidades de datos teóricas máximas para dispositivos de doble flujo.",
          "³⁴Las simulaciones de entornos de red congestionados realizadas por Intel en la fase de ingeniería muestran una reducción considerable de la latencia al utilizar la nueva función Multi-Link Operation del Wi-Fi 7.",
          "³⁵Carga mínima obtenida desde el nivel de batería predeterminado por el OEM para apagar el portátil. Los resultados de cada sistema individual pueden variar. Consulte intel.com/performance-evo para obtener más información.",
          "³⁶La banda Wi-Fi de 6 GHz no está disponible en todos los países/regiones.",
          "³⁷Solo Windows*.",
          "³⁸Carga obtenida desde el nivel de batería predeterminado por el OEM para apagar el portátil. Para obtener información más completa sobre los resultados del análisis de rendimiento, visite intel.com/Evo.",
          "³⁹Se refiere a los procesadores Intel® Core™ Ultra para portátiles (Serie 2), según las características de seguridad únicas basadas en hardware, la combinación sin igual de capacidades de seguridad por encima y por debajo del sistema operativo, y el rendimiento impresionante incluso con menor consumo que la generación anterior (a septiembre de 2024). Consulte intel.com/performanceindex para obtener más información. Los resultados pueden variar.",
          "⁴⁰Las aplicaciones de software solo se muestran con fines ilustrativos. Las características de IA pueden requerir la compra de software adicional, suscripción o habilitación de un proveedor de software o plataforma. Además, pueden tener requisitos de configuración o compatibilidad específicos. Más información en www.intel.com/PerformanceIndex. Los resultados pueden variar. © Intel Corporation.",
          "⁴¹Las imágenes que se muestran pueden haber sido alteradas o simuladas. Las características de IA pueden requerir la compra de software adicional, suscripción o habilitación de un proveedor de software o plataforma. Además, pueden tener requisitos de configuración o compatibilidad específicos. Más información en www.intel.com/AIPC.",
          "⁴²Se lanzará gradualmente en una versión preliminar con la última actualización de Windows* 11 en determinados mercados mundiales. El momento de disponibilidad varía según el dispositivo y el mercado. Más información aquí: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³La solución Intel® Unison™ está disponible actualmente para diseños que cumplen los requisitos. Para más información, visite www.intel.com/PerformanceIndex.",
          "⁴⁴FPS ampliados por Intel® X<sup>e</sup>SS frente a FPS nativos, según la medición de Dying Light 2 Stay Human con el procesador Intel® Core™ Ultra 7 165H. Para más información, visite www.intel.com/PerformanceIndex. Los resultados pueden variar.",
          "⁴⁵Según el menor consumo de energía del sistema integrado en chip medido en la plataforma de validación de referencia del procesador Intel® Core™ Ultra para portátiles (Serie 2, nombre en código: Lunar Lake) frente a la plataforma de validación de referencia del procesador Intel® Core™ Ultra para portátiles (Serie 1, nombre en código: Meteor Lake) en YouTube* 4K a 30 FPS AV1. Consulte intel.com/performanceindex para obtener más información. Los resultados pueden variar.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel no se responsabiliza de los errores en los precios.",
          "*Otros nombres comerciales y marcas pueden ser reclamados como propiedad de terceros.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES Y LAS MARCAS COMPUESTAS Y DISEÑOS RELACIONADOS SON MARCAS COMERCIALES DE INFLEXION STUDIOS INC. TODOS LOS DERECHOS RESERVADOS.",
          "Es posible que las tecnologías Intel requieran hardware habilitado, software o activación de servicios.",
          "Los resultados y datos de NERO* se proporcionan para su comodidad y solo con fines informativos. Intel Corporation no se responsabiliza de, por ejemplo, la precisión de la aplicación NERO*, los resultados y las puntuaciones de rendimiento, o las posibles variaciones de otras versiones descargables de la aplicación disponibles en www.nero.com u otros sitios web de terceros. Los resultados del análisis de rendimiento del ordenador NERO* se miden mediante sistemas informáticos, componentes, software, operaciones y funciones específicos. Cualquier cambio realizado en alguno de esos factores podría generar una variación en los resultados. Es conveniente consultar otras fuentes de información y pruebas de rendimiento que le ayudarán a evaluar a fondo sus posibles compras, incluido el rendimiento de un producto concreto en combinación con otros.",
          "Todas las versiones de la plataforma Intel&nbsp;vPro® requieren un procesador Intel®&nbsp;Core™ que reúna las condiciones necesarias, un sistema operativo compatible, un chip&nbsp;Intel® LAN o WLAN, mejoras de firmware y el hardware y el software necesarios para ofrecer los casos prácticos de capacidad de administración, las características de seguridad, el rendimiento del sistema y la estabilidad que definen la plataforma.",
          "El rendimiento varía según el uso, la configuración y otros factores. Más información en www.intel.com/PerformanceIndex.",
          "Intel no ejerce control ni inspección algunos sobre los datos de terceros. Para evaluar la exactitud, debería consultar otras fuentes.",
          "© Intel Corporation. Intel, el logotipo Intel y otras marcas Intel son marcas comerciales de Intel Corporation o de sus filiales. *Otros nombres comerciales y marcas pueden ser reclamados como propiedad de terceros.",
          "Ningún producto o componente es completamente seguro.",
          "Sus costes y resultados pueden variar.",
          "Intel, el logotipo Intel, Intel Core, Intel Optane, Intel Iris, Thunderbolt y el logotipo Thunderbolt son marcas comerciales de Intel Corporation o de sus filiales. Otros nombres comerciales y marcas pueden ser reclamados como propiedad de terceros.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Inicio",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Escanea para comparar",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Tendencia",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/evo/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/video_link.mp4",
      "iconURL": "",
      "videoName": "video_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ Performance to Power Their World (16_9).mp4",
      "iconURL": "",
      "videoName": "Video_ Performance to Power Their World (16_9)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "BIENVENIDO/A",
    "tr_welcomePagetrSubtitle": "Controla este ordenador a distancia con tu smartphone",
    "tr_welcomePagembSubtitle": "Bienvenido/a, explora las especificaciones de este ordenador en tu smartphone",
    "tr_welcomePageBtnText": "Comenzar",
    "tr_welcomePageText": "Explora y compara los detalles de este ordenador en tu smartphone",
    "tr_scrollText": "Navega por el dispositivo que tienes delante",
    "tr_toolTipTextc1": "Usa estos botones para navegar por distintas páginas en el dispositivo que tienes delante.",
    "tr_toolTipTextc2": "Al tocar las tarjetas inferiores navegarás a una página con detalles de cada especificación del dispositivo.",
    "tr_toolTipTextc3": "Usa estos botones para subir y bajar por la pantalla que tienes delante.",
    "tr_toolTipTextc4": "Desconecta el modo a distancia para ver las especificaciones del dispositivo en tu smartphone.",
    "tr_toolTipTextmb1": "Toca las tarjetas para ver páginas de contenido detalladas.",
    "tr_toolTipTextmb2": "Toca para ver un resumen de todas las especificaciones del dispositivo",
    "tr_toolTipTextmb3": "Toca el botón para escanear el código QR y comparar hasta 4 dispositivos",
    "tr_nextButton": "Siguiente",
    "tr_prevButton": "Anterior",
    "tr_gotItButton": "HECHO",
    "tr_skipButton": "Omitir",
    "tr_placeholderText": "*marcador",
    "tr_processorHeading": "Procesador",
    "tr_processorSubheading": "Procesador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;10<sup>a</sup> generación",
    "tr_memorySubheading": "[XX]&nbsp;GB",
    "tr_storageSubheading": "Memoria Intel® Optane™ de [XX]&nbsp;GB + SSD de [YY]&nbsp;GB",
    "tr_coachmarkProcessor": "Procesador XX",
    "tr_coachmarkGraphics": "Gráficos [XX]",
    "tr_coachmarkMemory": "Valor de especificaciones",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999,95&nbsp;USD",
    "tr_compare": "Comparar",
    "tr_viewallspecs": "VER TODAS LAS ESPECIFICACIONES",
    "tr_operatingSystem": "SO",
    "tr_AmdProcessorNonIntel": "Procesador AMD*",
    "tr_QualcommProcessorNonIntel": "Procesador Qualcomm*",
    "tr_GBText": "GB",
    "tr_remoteText": "Especificaciones mostradas en la pantalla del portátil",
    "tr_scanErrorText": "Solo puedes escanear hasta 3 dispositivos a la vez. Borra uno o más dispositivos.",
    "tr_scanErrorText2": "Ya has escaneado este dispositivo. Escanea otro.",
    "tr_scanMsg": "Escanea para añadir el dispositivo",
    "tr_backToScan": "Volver a Comparar",
    "tr_scanToCompare": "Escanea para comparar",
    "tr_compareDevice": "Comparar dispositivos",
    "tr_processorTitle": "PROCESADOR",
    "tr_graphicTitle": "GRÁFICOS",
    "tr_storageTitle": "ALMACENA&shy;MIENTO",
    "tr_displayTitle": "PANTALLA",
    "tr_batteryTitle": "BATERÍA",
    "tr_memoryTitle": "MEMORIA",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "CONECTIVIDAD",
    "tr_priceTitle": "PRECIO",
    "tr_operatingSystemTitle": "SISTEMA OPERATIVO",
    "tr_batteryHoursText": "[XX] horas",
    "tr_hrsText": "horas",
    "tr_touchscreeenText": "Pantalla táctil de [screenDiagonal]”",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, pantalla táctil",
    "tr_FHDTouchScreenText": "FHD, pantalla táctil",
    "tr_4KTouchscreenText": "4K, pantalla táctil",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Guardar",
    "tr_device_comparison": "Documento de comparación de dispositivos [dd]-[mm]-[yyyy]",
    "tr_Save_As_JPEG": "Guardar como JPEG",
    "tr_Save_As_PDF": "Guardar como PDF",
    "tr_Downloading_PDF": "Descargando PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Abrir",
    "tr_logo": "logotipo",
    "tr_laptop_name": "Nombre del portátil",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Memoria Intel® Optane™",
    "tr_inchUnit": "pulgadas",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "MEMORIA INTEL®&nbsp;OPTANE™",
    "tr_Thunderbolt3": "Tecnología Thunderbolt™ 3",
    "tr_Thunderbolt4": "Tecnología Thunderbolt™ 4",
    "tr_processorGraphicsCaps": "GRÁFICOS DEL PROCESADOR",
    "tr_processorGraphicsSmall": "Gráficos del procesador",
    "tr_graphicsCardCaps": "GRÁFICOS DEDICADOS",
    "tr_graphicsCardSmall": "Gráficos dedicados",
    "tr_processorTitleCamel": "Procesador",
    "tr_graphicTitleCamel": "Gráficos",
    "tr_storageTitleCamel": "Almacena&shy;miento",
    "tr_displayTitleCamel": "Pantalla",
    "tr_batteryTitleCamel": "Batería",
    "tr_memoryTitleCamel": "Memoria",
    "tr_connectivityTitleCamel": "Conectividad",
    "tr_priceTitleCamel": "Precio",
    "tr_operatingSystemTitleCamel": "Sistema operativo",
    "tr_viewallspecsCamel": "Ver todas las especificaciones",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenResolutionType] de [screenDiagonal]”",
    "tr_displayTextResolutionTouchscreen": "Pantalla táctil [screenResolutionType] de [screenDiagonal]”",
    "tr_OS": "SO",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Especificaciones del ordenador",
    "tr_explorePC_text": "Explora todo lo que ofrece este ordenador",
    "tr_mtlPC_text": "Comprueba lo que significa realmente “diseñado para hacerlo todo”",
    "tr_optaneMemory": "Memoria Intel®&nbsp;Optane™",
    "tr_displayText_Display": "PANTALLA DE [screenDiagonal]”",
    "tr_displayTextResolution_Display": "PANTALLA DE [screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen_Display": "PANTALLA TÁCTIL DE [screenDiagonal]” [screenResolutionType]",
    "tr_displayTextTouchscreeenText": "PANTALLA TÁCTIL DE [screenDiagonal]”",
    "tr_learnMoreLabel": "Más información",
    "tr_batteryHoursTextUpperCase": "[XX] HORAS",
    "tr_processorGraphicsIrisXe": "PROCESADOR INTEL® CORE™ [cpu_fam] DE 11<sup>A</sup> GENERACIÓN Y GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "PROCESADOR INTEL® CORE™ [cpu_fam] DE&nbsp;11<sup>A</sup>&nbsp;GENERACIÓN Y&nbsp;GRÁFICOS&nbsp;INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX",
    "tr_Thunderbolt3UpperCase": "TECNOLOGÍA THUNDERBOLT™ 3",
    "tr_Thunderbolt4UpperCase": "TECNOLOGÍA THUNDERBOLT™ 4",
    "tr_processorWithArcGraphics": "PROCESADOR INTEL® CORE™ [cpu_fam] DE 12<sup>A</sup> GENERACIÓN Y GRÁFICOS INTEL® ARC™",
    "tr_processorGraphicsIrisXe12Gen": "PROCESADOR INTEL® CORE™ [cpu_fam] DE 12<sup>A</sup> GENERACIÓN Y GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "PROCESADOR INTEL® CORE™ [cpu_fam] DE 12<sup>A</sup> GENERACIÓN Y GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_samsung_oled": "OLED de Samsung",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "PROCESADOR INTEL® CORE™ [cpu_fam] DE 13<sup>A</sup> GENERACIÓN Y GRÁFICOS INTEL® ARC™",
    "tr_processorGraphicsIrisXe13Gen": "PROCESADOR INTEL® CORE™ [cpu_fam] DE 13<sup>A</sup> GENERACIÓN Y GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "PROCESADOR INTEL® CORE™ [cpu_fam] DE 13<sup>A</sup> GENERACIÓN Y GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "GRÁFICOS INTEL® ARC™ A350M",
      "tr_A370M": "GRÁFICOS INTEL® ARC™ A370M",
      "tr_A730M": "GRÁFICOS INTEL® ARC™ A730M",
      "tr_A770M": "GRÁFICOS INTEL® ARC™ A770M",
      "tr_A310": "GRÁFICOS INTEL® ARC™ A310",
      "tr_A380": "GRÁFICOS INTEL® ARC™ A380",
      "tr_A580": "GRÁFICOS INTEL® ARC™ A580",
      "tr_A750": "GRÁFICOS INTEL® ARC™ A750",
      "tr_A770": "GRÁFICOS INTEL® ARC™ A770"
    },
    "tr_memoryValue": "[XX]&nbsp;GB de RAM",
    "tr_memoryWithOptaneDesc": "Memoria de [Value1]&nbsp;GB = Memoria Intel®&nbsp;Optane™ de [Value2]&nbsp;GB + [Value3]&nbsp;GB de RAM",
    "tr_textGBGB": "SSD de [convertedSSD]&nbsp;GB + HDD de [convertedHDD]&nbsp;GB",
    "tr_textGBTB": "SSD de [convertedSSD]&nbsp;GB + HDD de [convertedHDD]&nbsp;TB",
    "tr_textTBGB": "SSD de [convertedSSD]&nbsp;TB + HDD de [convertedHDD]&nbsp;GB",
    "tr_textTBTB": "SSD de [convertedSSD]&nbsp;TB + HDD de [convertedHDD]&nbsp;TB",
    "tr_textGBSSD": "SSD de [convertedSSD] GB",
    "tr_textTBSSD": "SSD de [convertedSSD] TB",
    "tr_textGBHDD": "HDD de [convertedHDD] GB",
    "tr_textTBHDD": "HDD de [convertedHDD] TB",
    "tr_textStorageWithOptaneGB": "Memoria Intel®&nbsp;Optane™ de&nbsp;[XX]&nbsp;GB +&nbsp;SSD&nbsp;de [convertedSSD]&nbsp;GB",
    "tr_textStorageWithOptaneTB": "Memoria Intel®&nbsp;Optane™ de&nbsp;[XX]&nbsp;GB +&nbsp;SSD&nbsp;de [convertedSSD]&nbsp;TB",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home en modo S",
      "tr_OS21": "Windows* 10 Pro en modo S",
      "tr_OS22": "Windows* 10 Enterprise en modo S",
      "tr_OS23": "Windows* 11 Home en modo S",
      "tr_OS24": "Windows* 11 Pro en modo S",
      "tr_OS25": "Windows* 11 Enterprise en modo S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Gráficos Intel®&nbsp;Arc™ A310",
      "tr_GR387": "Gráficos Intel®&nbsp;Arc™ A350M",
      "tr_GR388": "Gráficos Intel®&nbsp;Arc™ A370M",
      "tr_GR389": "Gráficos Intel®&nbsp;Arc™ A380",
      "tr_GR390": "Gráficos Intel®&nbsp;Arc™ A530M",
      "tr_GR391": "Gráficos Intel®&nbsp;Arc™ A550M",
      "tr_GR392": "Gráficos Intel®&nbsp;Arc™ A570M",
      "tr_GR393": "Gráficos Intel®&nbsp;Arc™ A580",
      "tr_GR394": "Gráficos Intel®&nbsp;Arc™ A730M",
      "tr_GR395": "Gráficos Intel®&nbsp;Arc™ A750",
      "tr_GR396": "Gráficos Intel®&nbsp;Arc™ A770",
      "tr_GR397": "Gráficos Intel®&nbsp;Arc™ A770M",
      "tr_GR398": "Gráficos Intel® Arc™",
      "tr_GR399": "Gráficos Intel®&nbsp;Arc™ Pro A30M",
      "tr_GR400": "Gráficos Intel®&nbsp;Arc™ Pro A40/A50",
      "tr_GR401": "Gráficos Intel®&nbsp;Arc™ Pro A60",
      "tr_GR402": "Gráficos Intel®&nbsp;Arc™ Pro A60M",
      "tr_GR403": "Gráficos Intel®",
      "tr_GR404": "Gráficos HD&nbsp;Intel®&nbsp;2000",
      "tr_GR405": "Gráficos HD&nbsp;Intel®&nbsp;2500",
      "tr_GR406": "Gráficos HD&nbsp;Intel®&nbsp;3000",
      "tr_GR407": "Gráficos HD&nbsp;Intel®&nbsp;400",
      "tr_GR408": "Gráficos HD&nbsp;Intel®&nbsp;4000",
      "tr_GR409": "Gráficos HD&nbsp;Intel®&nbsp;405",
      "tr_GR410": "Gráficos HD&nbsp;Intel®&nbsp;4200",
      "tr_GR411": "Gráficos HD&nbsp;Intel®&nbsp;4400",
      "tr_GR412": "Gráficos HD&nbsp;Intel®&nbsp;4600",
      "tr_GR413": "Gráficos HD&nbsp;Intel®&nbsp;500",
      "tr_GR414": "Gráficos HD&nbsp;Intel®&nbsp;5000",
      "tr_GR415": "Gráficos HD&nbsp;Intel®&nbsp;505",
      "tr_GR416": "Gráficos HD&nbsp;Intel®&nbsp;510",
      "tr_GR417": "Gráficos HD&nbsp;Intel®&nbsp;515",
      "tr_GR418": "Gráficos HD&nbsp;Intel®&nbsp;520",
      "tr_GR419": "Gráficos HD&nbsp;Intel®&nbsp;530",
      "tr_GR420": "Gráficos HD&nbsp;Intel®&nbsp;5300",
      "tr_GR421": "Gráficos HD&nbsp;Intel®&nbsp;5500",
      "tr_GR422": "Gráficos HD&nbsp;Intel®&nbsp;5600",
      "tr_GR423": "Gráficos HD&nbsp;Intel®&nbsp;6000",
      "tr_GR424": "Gráficos HD&nbsp;Intel®&nbsp;610",
      "tr_GR425": "Gráficos HD&nbsp;Intel®&nbsp;615",
      "tr_GR426": "Gráficos HD&nbsp;Intel®&nbsp;620",
      "tr_GR427": "Gráficos HD&nbsp;Intel®&nbsp;630",
      "tr_GR428": "Gráficos HD Intel®",
      "tr_GR429": "Gráficos Intel® Iris® 5100",
      "tr_GR430": "Gráficos Intel® Iris® 540",
      "tr_GR431": "Gráficos Intel® Iris® 550",
      "tr_GR432": "Gráficos Intel® Iris® 6100",
      "tr_GR433": "Gráficos Intel® Iris® Plus 640",
      "tr_GR434": "Gráficos Intel® Iris® Plus 645",
      "tr_GR435": "Gráficos Intel® Iris® Plus 650",
      "tr_GR436": "Gráficos Intel® Iris® Plus 655",
      "tr_GR437": "Gráficos Intel® Iris® Plus",
      "tr_GR438": "Gráficos Intel® Iris® Pro 5200",
      "tr_GR439": "Gráficos Intel® Iris® Pro 580",
      "tr_GR440": "Gráficos Intel® Iris® Pro 6200",
      "tr_GR441": "Gráficos Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "Gráficos Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "Gráficos Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "Gráficos integrados Intel®",
      "tr_GR445": "Gráficos UHD Intel® 600",
      "tr_GR446": "Gráficos UHD Intel® 605",
      "tr_GR447": "Gráficos UHD Intel® 610",
      "tr_GR448": "Gráficos UHD Intel® 615",
      "tr_GR449": "Gráficos UHD Intel® 617",
      "tr_GR450": "Gráficos UHD Intel® 620",
      "tr_GR451": "Gráficos UHD Intel® 630",
      "tr_GR452": "Gráficos UHD Intel® 710",
      "tr_GR453": "Gráficos UHD Intel® 730",
      "tr_GR454": "Gráficos UHD Intel® 770",
      "tr_GR455": "Gráficos UHD Intel®",
      "tr_GR456": "Gráficos UHD Intel®",
      "tr_GR457": "Gráficos UHD Intel®",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 con diseño Max-Q",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti con diseño Max-Q",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 con diseño Max-Q",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 con diseño Max-Q",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 con diseño Max-Q",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 con diseño Max-Q",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti con diseño Max-Q",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti con diseño Max-Q",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 con diseño Max-Q",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 con diseño Max-Q",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super con diseño Max-Q",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 con diseño Max-Q",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super con diseño Max-Q",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 con diseño Max-Q",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 con diseño Max-Q",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 con diseño Max-Q",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 con diseño Max-Q",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 con diseño Max-Q",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 con diseño Max-Q",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 con diseño Max-Q",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 con diseño Max-Q",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 con diseño Max-Q",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 con diseño Max-Q",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 generación Ada",
      "tr_GR773": "NVIDIA* RTX 3000 generación Ada",
      "tr_GR774": "NVIDIA* RTX 3500 generación Ada",
      "tr_GR775": "NVIDIA* RTX 4000 generación Ada",
      "tr_GR776": "NVIDIA* RTX 4000 SFF generación Ada",
      "tr_GR777": "NVIDIA* RTX 4500 generación Ada",
      "tr_GR778": "NVIDIA* RTX 5000 generación Ada",
      "tr_GR779": "NVIDIA* RTX 5880 generación Ada",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 generación Ada",
      "tr_GR811": "NVIDIA* RTX 1000 generación Ada",
      "tr_GR812": "Gráficos Intel® Arc™",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 de doble ranura"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Comprar ahora",
    "tr_textTryagain": "Inténtalo de nuevo",
    "tr_textBuynowErr": "Debido a una interrupción de la conexión, la opción Comprar ahora no está disponible",
    "tr_chatbotPlaceholder_text": "Pregúntame lo que quieras sobre el dispositivo",
    "tr_deviceSpecs_text": "Especificaciones del dispositivo"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 con&nbsp;Intel®&nbsp;Hybrid&nbsp;Technology"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 con&nbsp;Intel®&nbsp;Hybrid&nbsp;Technology"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Prepárate con Intel para una experiencia de juego más poderosa",
                "tr_subtitle": "Dales vida a los Marvel’s Avengers* con optimizaciones para ordenador integradas en el juego con la tecnología Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Experimenta Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Donde compiten los mejores jugadores del mundo",
                "tr_subtitle": "Intel® Extreme Masters, la serie de eventos que más tiempo lleva activa en la historia de los videojuegos, establece los estándares para los deportes electrónicos.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Juega como los profesionales",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Los campeones se hacen realidad con Intel.",
                "tr_subtitle": "Después de ganar el campeonato de la Liga Norteamericana de Rainbow Six Siege* de 2021, los Soniqs reflexionan sobre su historia con los videojuegos, el futuro de los deportes electrónicos y su asociación con Intel.",
                "tr_pretitle": "Con los Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Listo para la VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Donde la VR cobra vida",
                "tr_subtitle": "Las experiencias de realidad virtual más increíbles, realistas y envolventes se ejecutan en un ordenador. Con los nuevos procesadores Intel® Core™ podrás ser cualquier personaje, ir a cualquier sitio y experimentar los videojuegos en la siguiente dimensión.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Juega a más de 100 videojuegos de consola de alta calidad como Sea of Thieves* y Psychonauts* 2 en tu ordenador Windows*, tu teléfono móvil y tu tableta con Xbox* Game Pass Ultimate y un controlador compatible.",
                "titleArray": [
                  {
                    "tr_title": "Jugar juntos en distintos dispositivos",
                    "tr_subtitle": "Accede a lo mejor de Xbox* con una comunidad de millones de jugadores listos para jugar contigo. Conéctate y juega con otras personas desde una biblioteca de videojuegos compartidos, tanto si están al otro lado del mundo como sentadas a tu lado."
                  },
                  {
                    "tr_title": "Videojuegos para ordenador Windows*",
                    "tr_subtitle": "Disfruta de un catálogo de videojuegos de consola en la nube usando la aplicación Xbox* para Windows* y un controlador compatible."
                  },
                  {
                    "tr_title": "Juega donde quieras",
                    "tr_subtitle": "Disfruta de estupendos videojuegos de Xbox* en más lugares que nunca. Juega a más de 100 videojuegos en tu ordenador, tu teléfono móvil y tu tableta tanto si estás en casa, mientras otros usan la TV, o si estás en una cafetería o cualquier otro lugar con una conexión a Internet de alta velocidad."
                  },
                  {
                    "tr_title": "Diversión sin controlador",
                    "tr_subtitle": "Los videojuegos con control táctil de Xbox* ofrecen nuevas formas de lanzarse a jugar con una experiencia familiar y el nivel de juego al que estás acostumbrado con un controlador físico."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Destacados",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Más de 100 experiencias de IA",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Más control a distancia",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "No te quedes al margen en ningún entorno",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creatividad",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Corrige la iluminación como un profesional",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Añade efectos exuberantes con total facilidad",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Haz un remix de canciones gracias al poder de la IA",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productividad",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Impulsa tu productividad y privacidad",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Pasa más tiempo lejos del enchufe",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Tu mundo, en tus dispositivos",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Entretenimiento",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Streaming fluido, incluso sin estar enchufado",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Videojuegos rápidos e inmersivos",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Destacados",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Más de 100 experiencias de IA",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Haz tuyas las canciones que te gusten",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Tu visión en tiempo real",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creatividad",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "El camino más rápido hacia tu versión definitiva",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Haz que cada foto sea perfecta",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productividad",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Impulsa tu productividad y privacidad",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Más eficiente. Más libertad.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Tu mundo, en tus dispositivos",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Entretenimiento",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Streaming fluido en HD, incluso sin estar enchufado",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Videojuegos inmersivos, en más lugares",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Más creatividad",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Edición de fotos fácil",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Crea fotos dignas de enmarcarse en segundos",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Tú lo escribes. La IA lo crea.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Más rendimiento",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "El truco para jugar con fluidez",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Pasa más tiempo lejos del enchufe",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultrainmersivos",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Más productividad",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Haz tu vida más fácil con IA",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Videollamadas perfectas",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Tu mundo, en tus dispositivos",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Más seguridad",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Protecciones integradas para aumentar la seguridad y privacidad de tus datos",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Más creatividad",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Edición de fotos fácil",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "IA. La vía rápida a tu montaje final.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Transcribe rápidamente las letras de tus canciones favoritas",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Ahórrate horas de edición con la IA",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Más rendimiento",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "El truco para jugar con fluidez",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Pasa más tiempo lejos del enchufe",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultrainmersivos",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Más productividad",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Haz tu vida más fácil con IA",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Videollamadas perfectas",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Tu mundo, en tus dispositivos",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Más seguridad",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Protecciones integradas para aumentar la seguridad y privacidad de tus datos",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Todo tu entretenimiento. Un&nbsp;ordenador.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Para un ordenador que actúa también como un centro de entretenimiento, necesitas almacenamiento suficiente para todas tus canciones, vídeos y videojuegos favoritos, y la combinación adecuada de procesador y pantalla para crear elementos visuales envolventes. Este ordenador tiene los tres.",
                "titleArray": [
                  {
                    "tr_title": "Listo para imágenes de próxima generación",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crea tu biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Una ventana al entretenimiento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Videojuegos y streaming fluidos",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crea tu biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Una ventana al entretenimiento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sé más productivo. En&nbsp;cualquier&nbsp;lugar.",
              "tr_tileTitle1": "Consigue hacer más, rápidamente.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Este ordenador es el equilibrio perfecto entre potencia y portabilidad. Comenzando por un procesador creado para diseños finos y ligeros, este ordenador también incluye el tipo de tecnología Wi&#8209;Fi necesaria para mantenerte conectado y la batería adecuada para permitirte utilizarlo sin&nbsp;cables.",
                "tr_subtitle1": "Este ordenador tiene el equilibrio perfecto entre potencia y rendimiento para ayudarte a conseguir más en menos tiempo. La combinación de un procesador robusto acelerado por una amplia memoria y una conexión Wi-Fi rápida como el rayo optimizará tus tareas cotidianas.",
                "tr_subtitle2": "Este ordenador es el equilibrio perfecto entre potencia y portabilidad. Comenzando por un procesador creado para diseños finos y ligeros, este ordenador también incluye el tipo de tecnología Wi‑Fi necesaria para mantenerte conectado y la memoria adecuada para la multitarea.",
                "titleArray": [
                  {
                    "tr_title": "Diseñado para hacer más",
                    "tr_title1": "Rendimiento inteligente que marca la diferencia",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La mejor conectividad de su clase",
                    "tr_title1": "Conéctate a lo importante",
                    "tr_title2": "Un avance importante en Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Sin cables durante más tiempo",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Cambia entre programas como un pro",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Libertad para crear",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "La creación de contenido es una de las tareas más exigentes para tu ordenador. Por suerte, estos componentes cuentan con la potencia para simplificar tu proceso creativo.",
                "titleArray": [
                  {
                    "tr_title": "Una forma más inteligente de crear",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Da vida a tus imágenes",
                    "tr_title1": "Vamos a crear",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memoria para la multitarea",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mira en el interior de&nbsp;este&nbsp;ordenador",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Estos son los tres componentes principales a los que debes prestar atención cuando elijas un ordenador nuevo. Decidir cómo vas a utilizar tu nuevo ordenador te será de gran ayuda para determinar en qué medida necesitarás estos tres componentes clave. Son:",
                "titleArray": [
                  {
                    "tr_title": "Rendimiento para videojuegos y transmisión",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Almacenamiento",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memoria",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Todo tu entretenimiento. Un&nbsp;ordenador.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Para un ordenador que actúa también como un centro de entretenimiento, necesitas almacenamiento suficiente para todas tus canciones, vídeos y videojuegos favoritos, y la combinación adecuada de procesador y pantalla para crear elementos visuales envolventes. Este ordenador tiene los tres.",
                "titleArray": [
                  {
                    "tr_title": "Listo para imágenes de próxima generación",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crea tu biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Una ventana al entretenimiento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Videojuegos y streaming fluidos",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crea tu biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Una ventana al entretenimiento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Memoria",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Pantalla",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Multitarea de alta velocidad",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITAREA DE ALTA VELOCIDAD",
                "tr_subtitle": "Haz más sin ralentizaciones",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para la multitarea:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede hacer funcionar varios programas a la vez."
                  },
                  {
                    "tr_title": "Un Wi‑Fi rápido, potente y fiable."
                  },
                  {
                    "tr_title": "Memoria que cambia sin problemas entre aplicaciones."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videoconferencia",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCONFERENCIA",
                "tr_subtitle": "Conexiones fluidas",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para videoconferencias:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede grabar y compartir imágenes al mismo tiempo."
                  },
                  {
                    "tr_title": "Un Wi‑Fi rápido, potente y fiable."
                  },
                  {
                    "tr_title": "Una cámara web que te ayudará a que te vean con claridad."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Listo para la VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Donde la VR cobra vida",
                "tr_subtitle": "¿Qué hace falta para crear un mundo completamente virtual? En un ordenador necesitarás de la coordinación entre el procesador, la tarjeta gráfica y la memoria.",
                "titleArray": [
                  {
                    "tr_title": "Creación de mundos virtuales",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La realidad está en los detalles",
                    "tr_title1": "Sumérgete",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Edición de vídeo",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDICIÓN DE VÍDEO",
                "tr_subtitle": "La magia del cine",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para edición de vídeo:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede manejar archivos grandes."
                  },
                  {
                    "tr_title": "Memoria que hace que el software de edición sea rápido y responda."
                  },
                  {
                    "tr_title": "Gráficos que reducirán los tiempos de renderización."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Multitarea de alta velocidad",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITAREA DE ALTA VELOCIDAD",
                "tr_subtitle": "Haz más sin ralentizaciones",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para la multitarea:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede hacer funcionar varios programas a la vez."
                  },
                  {
                    "tr_title": "Un Wi‑Fi rápido, potente y fiable."
                  },
                  {
                    "tr_title": "Memoria que cambia sin problemas entre aplicaciones."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Listo para la VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Donde la VR cobra vida",
                "tr_subtitle": "¿Qué hace falta para crear un mundo completamente virtual? En un ordenador necesitarás de la coordinación entre el procesador, la tarjeta gráfica y la memoria.",
                "titleArray": [
                  {
                    "tr_title": "Creación de mundos virtuales",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La realidad está en los detalles",
                    "tr_title1": "Sumérgete",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Modelado en 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODELADO EN 3D",
                "tr_subtitle": "Creaciones de nivel superior",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para el modelado en 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede manejar software creativo avanzado."
                  },
                  {
                    "tr_title": "Memoria que puede cargar rápidamente archivos grandes."
                  },
                  {
                    "tr_title": "Gráficos para potente creación y edición de imágenes."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Colaboración en tiempo real",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLABORACIÓN EN TIEMPO REAL",
                "tr_subtitle": "Trabaja desde cualquier lugar",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para colaborar en línea:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que hace funcionar software de ofimática."
                  },
                  {
                    "tr_title": "Un Wi‑Fi en el que se puede confiar."
                  },
                  {
                    "tr_title": "Una cámara web que graba en HD brillante."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Listo para la VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Donde la VR cobra vida",
                "tr_subtitle": "¿Qué hace falta para crear un mundo completamente virtual? En un ordenador necesitarás de la coordinación entre el procesador, la tarjeta gráfica y la memoria.",
                "titleArray": [
                  {
                    "tr_title": "Creación de mundos virtuales",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La realidad está en los detalles",
                    "tr_title1": "Sumérgete",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Portátiles Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Los portátiles finos y ligeros no deberían ser tan potentes",
                "tr_subtitle": "Diseñados para el rendimiento móvil, los portátiles Intel® Evo™ ofrecen los estándares de velocidad, gráficos y autonomía que necesitas, todo ello en impresionantes diseños más finos y&nbsp;ligeros.",
                "titleArray": [
                  {
                    "tr_title": "Los portátiles son más importantes que nunca, así que nos hemos asegurado de que puedan hacer aún más.",
                    "tr_subtitle": "Usa la pantalla táctil para explorar, girar y ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reactivación instantánea",
                    "tr_subtitle": "Con la reactivación instantánea, tu ordenador puede salir de la&nbsp;suspensión y estar preparado en menos de un segundo."
                  },
                  {
                    "tr_title": "Visualización envolvente",
                    "tr_subtitle": "Una magnífica pantalla hace que&nbsp;las imágenes cobren vida con&nbsp;nitidez y&nbsp;colores&nbsp;vivos."
                  },
                  {
                    "tr_title": "Haz lo que necesites con&nbsp;rapidez",
                    "tr_subtitle": "Disfruta del rendimiento más ágil con&nbsp;los procesadores Intel® Core™ de&nbsp;11<sup>a</sup>&nbsp;generación."
                  },
                  {
                    "tr_title": "Sin cables durante más&nbsp;tiempo",
                    "tr_subtitle": "No pierdas el ritmo&nbsp;con&nbsp;un&nbsp;ordenador que&nbsp;optimiza&nbsp;la&nbsp;autonomía y&nbsp;se&nbsp;recarga en&nbsp;un&nbsp;instante."
                  },
                  {
                    "tr_title": "Conectividad por cable universal",
                    "tr_subtitle": "Hace que conectar otros dispositivos a tu ordenador sea fácil, rápido y seguro con la tecnología Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexiones rápidas y&nbsp;fiables",
                    "tr_subtitle": "Haz videoconferencia o comparte archivos con una conexión totalmente fiable gracias a Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Portátiles Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Todo lo que necesitas y más en un portátil fino y ligero.",
                "tr_subtitle": "Los portátiles Intel® Evo™ están diseñados para el rendimiento móvil y para ofrecerte la mejor experiencia<sup>2</sup>, independientemente de lo que&nbsp;hagas.",
                "titleArray": [
                  {
                    "tr_title": "Los portátiles son más importantes que nunca, así que nos hemos asegurado de que puedan hacer aún más.",
                    "tr_subtitle": "Usa la pantalla táctil para explorar, girar y ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reactivación instantánea",
                    "tr_subtitle": "Con la reactivación instantánea, tu ordenador puede salir de la&nbsp;suspensión y estar preparado en menos de un segundo."
                  },
                  {
                    "tr_title": "Visualización envolvente",
                    "tr_subtitle": "Una magnífica pantalla hace que&nbsp;las imágenes cobren vida con&nbsp;nitidez y&nbsp;colores&nbsp;vivos."
                  },
                  {
                    "tr_title": "Un rendimiento revolucionario",
                    "tr_subtitle": "Consigue una potencia adicional donde más la necesitas con el rendimiento inteligente de los procesadores Intel® Core™ de 12<sup>a</sup> generación."
                  },
                  {
                    "tr_title": "Sin cables durante más&nbsp;tiempo",
                    "tr_subtitle": "No pierdas el ritmo&nbsp;con&nbsp;un&nbsp;ordenador que&nbsp;optimiza&nbsp;la&nbsp;autonomía y&nbsp;se&nbsp;recarga en&nbsp;un&nbsp;instante."
                  },
                  {
                    "tr_title": "Conectividad por cable universal",
                    "tr_subtitle": "Hace que conectar otros dispositivos a tu ordenador sea fácil, rápido y seguro con la tecnología Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexiones rápidas y&nbsp;fiables",
                    "tr_subtitle": "Charla por vídeo o comparte archivos con una conexión con la que puedes contar usando Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Portátiles Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Los portátiles finos y ligeros no deberían ser tan potentes",
                "tr_subtitle": "Diseñados para el rendimiento móvil, los portátiles Intel® Evo™ ofrecen los estándares de velocidad, gráficos y autonomía que necesitas, todo ello en impresionantes diseños más finos y&nbsp;ligeros.",
                "titleArray": [
                  {
                    "tr_title": "Los portátiles son más importantes que nunca, así que nos hemos asegurado de que puedan hacer aún más.",
                    "tr_subtitle": "Usa la pantalla táctil para explorar, girar y ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reactivación instantánea",
                    "tr_subtitle": "Con la reactivación instantánea, tu ordenador puede salir de la&nbsp;suspensión y estar preparado en menos de un segundo."
                  },
                  {
                    "tr_title": "Visualización envolvente",
                    "tr_subtitle": "Una magnífica pantalla hace que&nbsp;las imágenes cobren vida con&nbsp;nitidez y&nbsp;colores&nbsp;vivos."
                  },
                  {
                    "tr_title": "Haz lo que necesites con&nbsp;rapidez",
                    "tr_subtitle": "Disfruta del rendimiento más ágil con&nbsp;los procesadores Intel® Core™ de&nbsp;11<sup>a</sup>&nbsp;generación."
                  },
                  {
                    "tr_title": "Sin cables durante más&nbsp;tiempo",
                    "tr_subtitle": "No pierdas el ritmo&nbsp;con&nbsp;un&nbsp;ordenador que&nbsp;optimiza&nbsp;la&nbsp;autonomía y&nbsp;se&nbsp;recarga en&nbsp;un&nbsp;instante."
                  },
                  {
                    "tr_title": "Conectividad por cable universal",
                    "tr_subtitle": "Hace que conectar otros dispositivos a tu ordenador sea fácil, rápido y seguro con la tecnología Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexiones rápidas y&nbsp;fiables",
                    "tr_subtitle": "Haz videoconferencia o comparte archivos con una conexión totalmente fiable gracias a Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Portátiles Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Todo lo que necesitas y más en un portátil fino y ligero.",
                "tr_subtitle": "Los portátiles Intel® Evo™ están diseñados para el rendimiento móvil y para ofrecerte la mejor experiencia<sup>2</sup>, independientemente de lo que&nbsp;hagas.",
                "titleArray": [
                  {
                    "tr_title": "Los portátiles son más importantes que nunca, así que nos hemos asegurado de que puedan hacer aún más.",
                    "tr_subtitle": "Usa la pantalla táctil para explorar, girar y ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reactivación instantánea",
                    "tr_subtitle": "Con la reactivación instantánea, tu ordenador puede salir de la&nbsp;suspensión y estar preparado en menos de un segundo."
                  },
                  {
                    "tr_title": "Visualización envolvente",
                    "tr_subtitle": "Una magnífica pantalla hace que&nbsp;las imágenes cobren vida con&nbsp;nitidez y&nbsp;colores&nbsp;vivos."
                  },
                  {
                    "tr_title": "Un rendimiento revolucionario",
                    "tr_subtitle": "Consigue una potencia adicional donde más la necesitas con el rendimiento inteligente de los procesadores Intel® Core™ de 12<sup>a</sup> generación."
                  },
                  {
                    "tr_title": "Sin cables durante más&nbsp;tiempo",
                    "tr_subtitle": "No pierdas el ritmo&nbsp;con&nbsp;un&nbsp;ordenador que&nbsp;optimiza&nbsp;la&nbsp;autonomía y&nbsp;se&nbsp;recarga en&nbsp;un&nbsp;instante."
                  },
                  {
                    "tr_title": "Conectividad por cable universal",
                    "tr_subtitle": "Hace que conectar otros dispositivos a tu ordenador sea fácil, rápido y seguro con la tecnología Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexiones rápidas y&nbsp;fiables",
                    "tr_subtitle": "Charla por vídeo o comparte archivos con una conexión con la que puedes contar usando Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Portátiles Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Todo lo que necesitas y más en un portátil fino y ligero.",
                "tr_subtitle": "Los portátiles Intel® Evo™ están diseñados para el rendimiento móvil y para ofrecerte la mejor experiencia<sup>2</sup>, independientemente de lo que&nbsp;hagas.",
                "titleArray": [
                  {
                    "tr_title": "Los portátiles son más importantes que nunca, así que nos hemos asegurado de que puedan hacer aún más.",
                    "tr_subtitle": "Usa la pantalla táctil para explorar, girar y ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reactivación instantánea",
                    "tr_subtitle": "Con la reactivación instantánea, tu ordenador puede salir de la&nbsp;suspensión y estar preparado en menos de un segundo."
                  },
                  {
                    "tr_title": "Visualización envolvente",
                    "tr_subtitle": "Una magnífica pantalla hace que&nbsp;las imágenes cobren vida con&nbsp;nitidez y&nbsp;colores&nbsp;vivos."
                  },
                  {
                    "tr_title": "Un rendimiento revolucionario",
                    "tr_subtitle": "Consigue una potencia adicional donde más la necesitas con el rendimiento inteligente de los procesadores Intel® Core™ de 13<sup>a</sup> generación."
                  },
                  {
                    "tr_title": "Sin cables durante más&nbsp;tiempo",
                    "tr_subtitle": "No pierdas el ritmo&nbsp;con&nbsp;un&nbsp;ordenador que&nbsp;optimiza&nbsp;la&nbsp;autonomía y&nbsp;se&nbsp;recarga en&nbsp;un&nbsp;instante."
                  },
                  {
                    "tr_title": "Conectividad por cable universal",
                    "tr_subtitle": "Hace que conectar otros dispositivos a tu ordenador sea fácil, rápido y seguro con la tecnología Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexiones rápidas y&nbsp;fiables",
                    "tr_subtitle": "Charla por vídeo o comparte archivos con una conexión con la que puedes contar usando Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Portátiles Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Todo lo que necesitas y más en un portátil fino y ligero.",
                "tr_subtitle": "Los portátiles Intel® Evo™ están diseñados para el rendimiento móvil y para ofrecerte la mejor experiencia<sup>2</sup>, independientemente de lo que&nbsp;hagas.",
                "titleArray": [
                  {
                    "tr_title": "Los portátiles son más importantes que nunca, así que nos hemos asegurado de que puedan hacer aún más.",
                    "tr_subtitle": "Usa la pantalla táctil para explorar, girar y ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reactivación instantánea",
                    "tr_subtitle": "Con la reactivación instantánea, tu ordenador puede salir de la&nbsp;suspensión y estar preparado en menos de un segundo."
                  },
                  {
                    "tr_title": "Visualización envolvente",
                    "tr_subtitle": "Una magnífica pantalla hace que&nbsp;las imágenes cobren vida con&nbsp;nitidez y&nbsp;colores&nbsp;vivos."
                  },
                  {
                    "tr_title": "Un rendimiento revolucionario",
                    "tr_subtitle": "Consigue una potencia adicional donde más la necesitas con el rendimiento inteligente de los procesadores Intel® Core™ de 13<sup>a</sup> generación."
                  },
                  {
                    "tr_title": "Sin cables durante más&nbsp;tiempo",
                    "tr_subtitle": "No pierdas el ritmo&nbsp;con&nbsp;un&nbsp;ordenador que&nbsp;optimiza&nbsp;la&nbsp;autonomía y&nbsp;se&nbsp;recarga en&nbsp;un&nbsp;instante."
                  },
                  {
                    "tr_title": "Conectividad por cable universal",
                    "tr_subtitle": "Hace que conectar otros dispositivos a tu ordenador sea fácil, rápido y seguro con la tecnología Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexiones rápidas y&nbsp;fiables",
                    "tr_subtitle": "Charla por vídeo o comparte archivos con una conexión con la que puedes contar usando Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Destacados",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "¿Qué es Intel®&nbsp;Evo™&nbsp;Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "¿Por qué elegir Intel®&nbsp;Evo™&nbsp;Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creatividad",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Sé más creativo en más lugares",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Edición de fotos a toda velocidad",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Crea arte original al instante",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productividad",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximiza la productividad con Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Detecta ultrafalsos antes de compartirlos con IA",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Tu mundo, en tus dispositivos",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Movilidad",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Saluda al control por gestos",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Autonomía que no te defraudará",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Mantén automáticamente la conexión",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Destacados",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "¿Qué es Intel®&nbsp;Evo™&nbsp;Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "¿Por qué elegir Intel®&nbsp;Evo™&nbsp;Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creatividad",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Reproducción de vídeo rápida y fluida",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Produce éxitos con el poder de la IA",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "El camino más rápido hacia tu versión definitiva",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productividad",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximiza la productividad con Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Detecta ultrafalsos antes de compartirlos con IA",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Tu mundo, en tus dispositivos",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Movilidad",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Colabora desde prácticamente cualquier lugar",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Autonomía que no te defraudará",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Mantén automáticamente la conexión",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Todo tu entretenimiento. Un&nbsp;ordenador.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Para un ordenador que actúa también como un centro de entretenimiento, necesitas almacenamiento suficiente para todas tus canciones, vídeos y videojuegos favoritos, y la combinación adecuada de procesador y pantalla para crear elementos visuales envolventes. Este ordenador tiene los tres.",
                "titleArray": [
                  {
                    "tr_title": "Videojuegos y transmisiones impresionantes",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crea tu biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Una ventana al entretenimiento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Videojuegos y streaming fluidos",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crea tu biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Una ventana al entretenimiento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sé más productivo. En&nbsp;cualquier&nbsp;lugar.",
              "tr_tileTitle1": "Consigue hacer más, rápidamente.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Este ordenador es el equilibrio perfecto entre potencia y portabilidad. Comenzando por un procesador creado para diseños finos y ligeros, este ordenador también incluye el tipo de tecnología Wi&#8209;Fi necesaria para mantenerte conectado y la batería adecuada para permitirte utilizarlo sin&nbsp;cables.",
                "tr_subtitle1": "Este ordenador tiene el equilibrio perfecto entre potencia y rendimiento para ayudarte a conseguir más en menos tiempo. La combinación de un procesador robusto acelerado por una amplia memoria y una conexión Wi-Fi rápida como el rayo optimizará tus tareas cotidianas.",
                "tr_subtitle2": "Este ordenador es el equilibrio perfecto entre potencia y portabilidad. Comenzando por un procesador creado para diseños finos y ligeros, este ordenador también incluye el tipo de tecnología Wi‑Fi necesaria para mantenerte conectado y la memoria adecuada para la multitarea.",
                "titleArray": [
                  {
                    "tr_title": "Rendimiento inteligente que marca la diferencia",
                    "tr_title1": "Acelera con el rendimiento inteligente",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La mejor conectividad de su clase",
                    "tr_title1": "Conéctate a lo importante",
                    "tr_title2": "Un avance importante en Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Sin cables durante más tiempo",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Cambia entre programas como un pro",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Libertad para crear",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "La creación de contenido es una de las tareas más exigentes para tu ordenador. Por suerte, estos componentes cuentan con la potencia para simplificar tu proceso creativo.",
                "titleArray": [
                  {
                    "tr_title": "Creatividad acelerada",
                    "tr_title1": "Desarrolla tus proyectos creativos",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Creación mejorada",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memoria para la multitarea",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mira en el interior de&nbsp;este&nbsp;ordenador",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Estos son los tres componentes principales a los que debes prestar atención cuando elijas un ordenador nuevo. Decidir cómo vas a utilizar tu nuevo ordenador te será de gran ayuda para determinar en qué medida necesitarás estos tres componentes clave. Son:",
                "titleArray": [
                  {
                    "tr_title": "Procesador",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Almacenamiento",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memoria",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Libertad para crear",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "La creación de contenido es una de las tareas más exigentes para tu ordenador. Por suerte, estos componentes cuentan con la potencia para simplificar tu proceso creativo.",
                "titleArray": [
                  {
                    "tr_title": "Una forma más inteligente de crear",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Da vida a tus imágenes",
                    "tr_title1": "Vamos a crear",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memoria para la multitarea",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sé más productivo. En&nbsp;cualquier&nbsp;lugar.",
              "tr_tileTitle1": "Consigue hacer más, rápidamente.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Este ordenador es el equilibrio perfecto entre potencia y portabilidad. Comenzando por un procesador creado para diseños finos y ligeros, este ordenador también incluye el tipo de tecnología Wi&#8209;Fi necesaria para mantenerte conectado y la batería adecuada para permitirte utilizarlo sin&nbsp;cables.",
                "tr_subtitle1": "Este ordenador tiene el equilibrio perfecto entre potencia y rendimiento para ayudarte a conseguir más en menos tiempo. La combinación de un procesador robusto acelerado por una amplia memoria y una conexión Wi-Fi rápida como el rayo optimizará tus tareas cotidianas.",
                "tr_subtitle2": "Este ordenador es el equilibrio perfecto entre potencia y portabilidad. Comenzando por un procesador creado para diseños finos y ligeros, este ordenador también incluye el tipo de tecnología Wi‑Fi necesaria para mantenerte conectado y la memoria adecuada para la multitarea.",
                "titleArray": [
                  {
                    "tr_title": "Diseñado para hacer más",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La mejor conectividad de su clase",
                    "tr_title1": "Conéctate a lo importante",
                    "tr_title2": "Un avance importante en Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Sin cables durante más tiempo",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Cambia entre programas como un pro",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Listo para la VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Donde la VR cobra vida",
                "tr_subtitle": "¿Qué hace falta para crear un mundo completamente virtual? En un ordenador necesitarás de la coordinación entre el procesador, la tarjeta gráfica y la memoria.",
                "titleArray": [
                  {
                    "tr_title": "Creación de mundos virtuales",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La realidad está en los detalles",
                    "tr_title1": "Sumérgete",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>un regalo para la vista",
      "tr_sub_header": "La luz azul puede causar molestias visuales e interrumpir el ciclo de sueño. Las pantallas OLED de Samsung, con certificación Eye Care de SGS, no ayudan cambiando el color, sino que reducen la longitud de onda de la luz azul potencialmente dañina, proporcionando comodidad ocular y reducción de fatiga ocular.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Menos luz azul nociva"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra en todos los sentidos",
      "tr_sub_header": "El diseño ultraligero y ultrafino de las pantallas OLED maximiza la calidad de imagen y minimiza el peso."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Negro puro",
      "tr_sub_header": "0,0005 nits"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74 %<br/><span class='conventional_text'>Convencional</span>",
      "tr_sub_header": "120 %<br/><span class='samsungoled_text'>OLED de Samsung</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Transmisión",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "TRANSMISIÓN",
                "tr_subtitle": "Entretenimiento sin fin",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para transmisión:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede transmitir en HD."
                  },
                  {
                    "tr_title": "Una pantalla ideal para imágenes brillantes y hermosas."
                  },
                  {
                    "tr_title": "Un Wi‑Fi que ofrece menos tiempo de almacenamiento y de carga."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Navegación por Internet",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NAVEGACIÓN POR INTERNET",
                "tr_subtitle": "Internet fácil",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para navegar por Internet:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede cargar rápidamente páginas con contenido multimedia."
                  },
                  {
                    "tr_title": "Un Wi‑Fi rápido y fiable."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videoconferencia",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCONFERENCIA",
                "tr_subtitle": "Conexiones fluidas",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para videoconferencias:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede grabar y compartir imágenes al mismo tiempo."
                  },
                  {
                    "tr_title": "Un Wi‑Fi rápido, potente y fiable."
                  },
                  {
                    "tr_title": "Una cámara web que te ayudará a que te vean con claridad."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitarea de alta velocidad",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITAREA DE ALTA VELOCIDAD",
                "tr_subtitle": "Haz más sin ralentizaciones",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para la multitarea:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede hacer funcionar varios programas a la vez."
                  },
                  {
                    "tr_title": "Un Wi‑Fi rápido, potente y fiable."
                  },
                  {
                    "tr_title": "Memoria que cambia sin problemas entre aplicaciones."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videoconferencia",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCONFERENCIA",
                "tr_subtitle": "Conexiones fluidas",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para videoconferencias:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede grabar y compartir imágenes al mismo tiempo."
                  },
                  {
                    "tr_title": "Un Wi‑Fi rápido, potente y fiable."
                  },
                  {
                    "tr_title": "Una cámara web que te ayudará a que te vean con claridad."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videojuegos ocasionales",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOJUEGOS OCASIONALES",
                "tr_subtitle": "Hora de jugar",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para videojuegos ligeros:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que funciona sin problemas al jugar."
                  },
                  {
                    "tr_title": "Memoria suficiente para charlas de voz y transmisión al mismo tiempo."
                  },
                  {
                    "tr_title": "Gráficos que dan vida a imágenes increíbles."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Edición de fotos",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDICIÓN DE FOTOS",
                "tr_subtitle": "Fotos perfectas",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para edición de fotos:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Un procesador con la suficiente potencia para editar grandes grupos de imágenes."
                  },
                  {
                    "tr_title": "Memoria que acelera el software de edición."
                  },
                  {
                    "tr_title": "Tecnología Thunderbolt™ 4 que te permite transferir archivos rápidamente."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitarea de alta velocidad",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITAREA DE ALTA VELOCIDAD",
                "tr_subtitle": "Haz más sin ralentizaciones",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para la multitarea:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede hacer funcionar varios programas a la vez."
                  },
                  {
                    "tr_title": "Un Wi‑Fi rápido, potente y fiable."
                  },
                  {
                    "tr_title": "Memoria que cambia sin problemas entre aplicaciones."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Colaboración en tiempo real",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLABORACIÓN EN TIEMPO REAL",
                "tr_subtitle": "Trabaja desde cualquier lugar",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para colaborar en línea:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que hace funcionar software de ofimática."
                  },
                  {
                    "tr_title": "Un Wi‑Fi en el que se puede confiar."
                  },
                  {
                    "tr_title": "Una cámara web que graba en HD brillante."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Edición de vídeo",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDICIÓN DE VÍDEO",
                "tr_subtitle": "La magia del cine",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para edición de vídeo:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede manejar archivos grandes."
                  },
                  {
                    "tr_title": "Memoria que hace que el software de edición sea rápido y responda."
                  },
                  {
                    "tr_title": "Gráficos que reducirán los tiempos de renderización."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Edición de fotos",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDICIÓN DE FOTOS",
                "tr_subtitle": "Fotos perfectas",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para edición de fotos:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Un procesador con la suficiente potencia para editar grandes grupos de imágenes."
                  },
                  {
                    "tr_title": "Memoria que acelera el software de edición."
                  },
                  {
                    "tr_title": "Tecnología Thunderbolt™ 4 que te permite transferir archivos rápidamente."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Edición de vídeo",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDICIÓN DE VÍDEO",
                "tr_subtitle": "La magia del cine",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para edición de vídeo:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede manejar archivos grandes."
                  },
                  {
                    "tr_title": "Memoria que hace que el software de edición sea rápido y responda."
                  },
                  {
                    "tr_title": "Gráficos que reducirán los tiempos de renderización."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Modelado en 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODELADO EN 3D",
                "tr_subtitle": "Creaciones de nivel superior",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para el modelado en 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que puede manejar software creativo avanzado."
                  },
                  {
                    "tr_title": "Memoria que puede cargar rápidamente archivos grandes."
                  },
                  {
                    "tr_title": "Gráficos para potente creación y edición de imágenes."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Colaboración en tiempo real",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLABORACIÓN EN TIEMPO REAL",
                "tr_subtitle": "Trabaja desde cualquier lugar",
                "tr_subtitle1": "En este ordenador hemos encontrado esto que es ideal para colaborar en línea:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Un procesador que hace funcionar software de ofimática."
                  },
                  {
                    "tr_title": "Un Wi‑Fi en el que se puede confiar."
                  },
                  {
                    "tr_title": "Una cámara web que graba en HD brillante."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "App Intel® Unison™",
    "tr_title": "Accede a un mundo conectado",
    "tr_subtitle": "Transfiere archivos y fotos sin problemas mientras gestionas las notificaciones, las llamadas y los mensajes de texto de tu teléfono desde el ordenador.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "TRANSFIERE ARCHIVOS Y FOTOS",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "REALIZA Y RECIBE LLAMADAS",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "ENVÍA Y RECIBE MENSAJES DE TEXTO",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "ADMINISTRA NOTIFICACIONES DEL TELÉFONO",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "ACCEDE A UN MUNDO CONECTADO",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}