const Library_mobile_it = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Con processore [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Con processore [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Proprio come un cervello umano, il processore controlla le attività svolte dal PC. I video che guardi, i videogiochi a cui giochi e i siti web che consulti: tutto ha inizio dal processore.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Il processore è il&nbsp;cervello&nbsp;del&nbsp;tuo&nbsp;PC"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Prestazioni a costi contenuti.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processore Intel® Pentium® Silver",
                    "tr_subtitle": "Tieni sotto controllo il tuo mondo digitale con prestazioni adeguate per accedere ai file dal Web. Il multitasking semplice consente di passare facilmente da un programma all'altro e di fare di più in meno tempo.",
                    "tr_gen_core_title": "Processore Intel® Pentium® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processore Intel® Pentium® Gold",
                    "tr_subtitle": "Produttività migliorata: prestazioni elevate per affrontare anche le giornate più impegnative. Navigazione Web semplificata: caricamento rapido delle pagine Web ricche di contenuti per una navigazione migliorata con meno attese.",
                    "tr_gen_core_title": "Processore Intel® Pentium® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processore Intel® Celeron®",
                    "tr_subtitle": "Prestazioni per potenziare il lavoro e il divertimento. Goditi film e video sul Web con dettagli straordinari. Dedica più tempo alle tue attività e meno tempo alle attese.",
                    "tr_gen_core_title": "Processore Intel® Celeron®"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processore Intel® Pentium®",
                    "tr_subtitle": "Per alti livelli di intrattenimento, streaming di video e produttività. Goditi film e video sul Web con dettagli straordinari. Dedica più tempo alle tue attività e meno tempo alle attese.",
                    "tr_gen_core_title": "Processore Intel® Pentium®"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Le prestazioni di cui hai bisogno. La convenienza che vuoi.",
                "tr_title": "Processore Intel®",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Valore in una categoria a sé",
                    "tr_subtitle": "Un processore Intel® è in grado di gestire praticamente qualsiasi attività di cui hai bisogno a un prezzo per te conveniente. Naviga facilmente da un software all'altro oppure goditi grafiche di alta qualità con prestazioni costanti.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Connessioni migliori",
                    "tr_subtitle": "Grazie a una qualità video più vivida, a una maggiore chiarezza audio e a una connettività Internet quasi 3 volte più veloce, avrai la certezza di poter collaborare ovunque ti trovi.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "La differenza è come il giorno e la notte",
                    "tr_subtitle": "Dalle maratone di film mattutine ai ritocchi dell'ultimo minuto, il processore Intel® estende la durata della batteria del tuo dispositivo per esperienze veramente immersive e ininterrotte.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Ogni ambiente è perfetto per l'apprendimento",
                    "tr_subtitle": "Esegui più strumenti di apprendimento online in tutta facilità da qualsiasi stanza della tua casa."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Apprendi ovunque ti trovi",
                    "tr_subtitle": "Completa le lezioni ovunque tu sia eseguendo contemporaneamente vari strumenti di e-learning."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Prestazioni per l'intrattenimento e la produttività adatte al tuo budget e stile di vita.",
                "tr_title": "Prestazioni di livello Intel per qualsiasi budget",
                "tr_gen_core_title": "Processore Intel® Celeron®",
                "processorCompare": {
                  "tr_title": "TROVA L'EQUILIBRIO GIUSTO TRA PRESTAZIONI E VALORE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Processore Intel® Celeron®"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Processore Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navigazione sul Web veloce su siti ricchi di contenuti multimediali",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Passaggio semplice tra programmi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming occasionale",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming di video 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotoritocco ed editing di video non intensivi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Produttività rapida con prestazioni intelligenti",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Con processore Intel®&nbsp;Core™&nbsp;[cpu_fam] di&nbsp;settima&nbsp;generazione"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Con processore Intel®&nbsp;Core™&nbsp;[cpu_fam] di&nbsp;sesta&nbsp;generazione"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Con processore Intel®&nbsp;Core™&nbsp;[cpu_fam] di&nbsp;quinta&nbsp;generazione"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Con processore Intel®&nbsp;Core™&nbsp;[cpu_fam] di&nbsp;quarta&nbsp;generazione"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Con processore Intel®&nbsp;Core™&nbsp;[cpu_fam] di&nbsp;terza&nbsp;generazione"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Con processore Intel®&nbsp;Core™&nbsp;[cpu_fam] di&nbsp;seconda&nbsp;generazione"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Con processore Intel®&nbsp;Core™&nbsp;[cpu_fam] di&nbsp;prima&nbsp;generazione"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Opzione consigliata per:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Con processore Intel® Core™ i3 di ottava generazione",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;ottava generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "I processori Intel® Core™ di nona generazione sono stati creati per stare al passo con il tuo mondo digitale. Grazie alla combinazione di velocità e prestazioni, potrai svolgere molte più attività che ami con il tuo PC, senza frustrazioni.",
                "tr_title": "Con processore Intel®&nbsp;Core™&nbsp;i3 di nona generazione",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;nona generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Le prestazioni versatili abbinate a una lunga durata della batteria ti permettono di continuare a usare il tuo PC ovunque tu vada.",
                "tr_title": "Energia per l'intera giornata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;decima generazione",
                "processorCompare": {
                  "tr_title": "SCEGLI IL GIUSTO LIVELLO DI PRESTAZIONI NEI PROCESSORI DI DECIMA GENERAZIONE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming di film in 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Connettività veloce e affidabile",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Mascheratura fotografica e filtri video",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Giocare in 1080p con frame rate fluidi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ideale per lavorare con file di grandi dimensioni e programmi per la creazione",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Passa facilmente dallo streaming di film alla creazione di presentazioni grazie alle prestazioni elevate a tua disposizione.",
                "tr_title": "Energia per l'intera giornata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;decima generazione"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Un notebook progettato appositamente per chi è sempre in viaggio.",
                "tr_title": "Una nuova categoria di notebook",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;decima generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Progettato appositamente per i PC portatili, il processore Intel® Core™ i3 di undicesima generazione ti consente di fare ancora di più, ovunque tu sia.",
                "tr_title": "Ottieni di più dal tuo nuovo notebook",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i3 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Quando la tua famiglia si affida a un PC desktop per tutto ciò che fa, sono necessarie la potenza e la versatilità di un processore Intel® Core™ i3 di undicesima generazione.",
                "tr_title": "Prestazioni rivoluzionarie per essere pronti a tutto",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i3 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Con processore Intel® Core™ i5 di ottava generazione",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;ottava generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "I processori Intel® Core™ di nona generazione sono stati creati per stare al passo con il tuo mondo digitale. Grazie alla combinazione di velocità e prestazioni, potrai svolgere molte più attività che ami con il tuo PC, senza frustrazioni.",
                "tr_title": "Con processore Intel® Core™ i5 di nona generazione",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;nona generazione"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "GAMEPLAY E REALTÀ VIRTUALE FLUIDI CON I VIDEOGAME PIÙ IMPEGNATIVI",
                "tr_title": "Il gaming di alto livello inizia da qui",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;nona generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming, streaming e registrazione senza compromessi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Migliore processore Intel® Core™ di nona generazione per il gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 4<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gestisci molteplici programmi con facilità per essere sempre pronto a tutto, ovunque ti porti la tua giornata.",
                "tr_title": "Multitasking in movimento",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;decima generazione",
                "processorCompare": {
                  "tr_title": "SCEGLI IL GIUSTO LIVELLO DI PRESTAZIONI NEI PROCESSORI DI DECIMA GENERAZIONE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming di film in 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Connettività veloce e affidabile",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Mascheratura fotografica e filtri video",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Giocare in 1080p con frame rate fluidi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ideale per lavorare con file di grandi dimensioni e programmi per la creazione",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Migliora tutte le attività che svolgi, dall'intrattenimento alla produttività passando per la creazione di contenuti.",
                "tr_title": "Passa a un PC più potente",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;decima generazione"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Un notebook progettato appositamente per chi è sempre in viaggio.",
                "tr_title": "Una nuova categoria di notebook",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;decima generazione"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Creato per lo streaming fluido e il gaming AAA",
                "tr_title": "Gioca a modo tuo",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;decima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ DI DECIMA GENERAZIONE IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA senza interruzioni",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e registrazione",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creazione avanzata di contenuti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prestazioni di gaming ai massimi livelli",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Sbloccato e overcloccabile<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Creato per lo streaming fluido e il gaming AAA",
                "tr_title": "Gioca a modo tuo",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;decima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ DI DECIMA GENERAZIONE IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA senza interruzioni",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e registrazione",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creazione avanzata di contenuti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prestazioni di gaming ai massimi livelli",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Sbloccato e overcloccabile<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 10<br/>Thread: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Gestisci molteplici programmi con facilità per essere sempre pronto a tutto, ovunque ti porti la tua giornata.",
                "tr_title": "Multitasking in movimento",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;decima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI DECIMA GENERAZIONE"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Il processore Intel® Core™ i5 di undicesima generazione offre esperienze visive brillanti e una produttività di ampia portata ai PC più sottili e leggeri.",
                "tr_title": "Potenza impressionante per i notebook",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Quando la tua famiglia si affida a un PC desktop per tutto ciò che fa, sono necessarie la potenza e la versatilità di un processore Intel® Core™ i5 di undicesima generazione.",
                "tr_title": "Prestazioni rivoluzionarie per essere pronti a tutto",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Il processore Intel® Core™ i5 di undicesima generazione offre esperienze visive incredibili e una produttività ad ampio raggio sui notebook sottili e leggeri.",
                "tr_title": "Potenza impressionante per i notebook",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI UNDICESIMA GENERAZIONE"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Con processore Intel® Core™ i7 di ottava generazione",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;ottava generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "I processori Intel® Core™ di nona generazione sono stati creati per stare al passo con il tuo mondo digitale. Grazie alla combinazione di velocità e prestazioni, potrai svolgere molte più attività che ami con il tuo PC, senza frustrazioni.",
                "tr_title": "Con processore Intel® Core™ i7 di nona generazione",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;nona generazione"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FINO AL 32% DI FPS IN PIÙ durante il gaming, lo streaming e la registrazione rispetto a un PC DI 3 ANNI FA<sup>3</sup>",
                "tr_title": "La potenza per condividere i migliori momenti di gioco",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;nona generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming, streaming e registrazione senza compromessi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Migliore processore Intel® Core™ di nona generazione per il gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 4<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Questo dispositivo elegante e progettato per l'efficienza può avere un notevole impatto sul tuo modo di creare, restare in contatto e guardare contenuti in streaming.",
                "tr_title": "Prestazioni avanzate ovunque",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;decima generazione",
                "processorCompare": {
                  "tr_title": "SCEGLI IL GIUSTO LIVELLO DI PRESTAZIONI NEI PROCESSORI DI DECIMA GENERAZIONE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming di film in 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Connettività veloce e affidabile",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Mascheratura fotografica e filtri video",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Giocare in 1080p con frame rate fluidi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ideale per lavorare con file di grandi dimensioni e programmi per la creazione",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Prestazioni avanzate per portare tutte le tue attività a un livello superiore.",
                "tr_title": "Potenza per tutte le tue attività",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;decima generazione"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Un notebook progettato appositamente per chi è sempre in viaggio.",
                "tr_title": "Una nuova categoria di notebook",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;decima generazione"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gaming, streaming e registrazione con un vantaggio competitivo",
                "tr_title": "Potenzia il tuo mondo dei videogiochi",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;decima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ DI DECIMA GENERAZIONE IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA senza interruzioni",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e registrazione",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creazione avanzata di contenuti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prestazioni di gaming ai massimi livelli",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Sbloccato e overcloccabile<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gaming, streaming e registrazione con un vantaggio competitivo",
                "tr_title": "Potenzia il tuo mondo dei videogiochi",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;decima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ DI DECIMA GENERAZIONE IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA senza interruzioni",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e registrazione",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creazione avanzata di contenuti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prestazioni di gaming ai massimi livelli",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Sbloccato e overcloccabile<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 10<br/>Thread: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Progettato per garantire la massima efficienza, questo dispositivo elegante può avere un notevole impatto sul tuo modo di creare contenuti, connetterti e trasmettere in streaming.",
                "tr_title": "Prestazioni superiori ovunque",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;decima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI DECIMA GENERAZIONE"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Il processore Intel® Core™ i7 di undicesima generazione offre creazione avanzata di contenuti, gaming fluido e un nuovo livello di intrattenimento ai PC ultra portatili.",
                "tr_title": "Prestazioni rivoluzionarie per i notebook",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Rendi il PC desktop il punto di riferimento per tutto ciò di cui la tua casa ha bisogno con le prestazioni rivoluzionarie di un processore Intel® Core™ i7 di undicesima generazione.",
                "tr_title": "Lavoro. Apprendimento. Gioco. Senza confini.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Il processore Intel® Core™ i7 di undicesima generazione consente la creazione avanzata di contenuti, un gaming fluido e un nuovo livello di intrattenimento sui notebook sottili e leggeri.",
                "tr_title": "Prestazioni rivoluzionarie per i notebook",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI UNDICESIMA GENERAZIONE"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Rendi il PC desktop il punto di riferimento per tutto ciò di cui la tua casa ha bisogno con le prestazioni rivoluzionarie di un processore Intel® Core™ i7 di undicesima generazione.",
                "tr_title": "Crea senza limiti",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI UNDICESIMA GENERAZIONE"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Con la creazione avanzata di contenuti, un gaming fluido e un nuovo livello di intrattenimento sui notebook sottili e leggeri, il processore Intel® Core™ i7 di undicesima generazione è migliorato ulteriormente.",
                "tr_title": "Prestazioni rivoluzionarie per i notebook",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI UNDICESIMA GENERAZIONE"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Con processore Intel® Core™ i9 di ottava generazione",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;ottava generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "I processori Intel® Core™ di nona generazione sono stati creati per stare al passo con il tuo mondo digitale. Grazie alla combinazione di velocità e prestazioni, potrai svolgere molte più attività che ami con il tuo PC, senza frustrazioni.",
                "tr_title": "Con processore Intel® Core™ i9 di nona generazione",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;nona generazione"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FINO AL 41% DI FPS IN PIÙ durante il gaming, lo streaming e la registrazione rispetto a un PC DI 3 ANNI FA<sup>4</sup>",
                "tr_title": "I gamer esprimono un desiderio. I processori Intel® Core™ i9 lo soddisfano.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;nona generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming, streaming e registrazione senza compromessi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Migliore processore Intel® Core™ di nona generazione per il gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 4<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Spingi il tuo potenziale con un'esperienza di gioco all'avanguardia",
                "tr_title": "Gareggia ai massimi livelli",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;decima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ DI DECIMA GENERAZIONE IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA senza interruzioni",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e registrazione",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creazione avanzata di contenuti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prestazioni di gaming ai massimi livelli",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Sbloccato e overcloccabile<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Spingi il tuo potenziale con un'esperienza di gioco all'avanguardia",
                "tr_title": "Gareggia ai massimi livelli",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;decima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ DI DECIMA GENERAZIONE IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA senza interruzioni",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e registrazione",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creazione avanzata di contenuti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prestazioni di gaming ai massimi livelli",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Sbloccato e overcloccabile<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 10<br/>Thread: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Spingi al limite il tuo potenziale con un'esperienza di creazione di contenuti all'avanguardia",
                "tr_title": "Creazione ai massimi livelli",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;decima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI DECIMA GENERAZIONE",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "I notebook Intel® Evo™ con processore Intel® Core™ i5 di undicesima generazione e grafica Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> coniugano velocità e prestazioni mai viste prima in un design sottile e leggero.",
                "tr_title": "Potenza elevata per notebook evoluti",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "I notebook Intel® Evo™ con processore Intel® Core™ i7 di undicesima generazione e grafica Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> offrono velocità e prestazioni avanzate per un'esperienza mobile premium.",
                "tr_title": "Potenza avanzata per un notebook evoluto",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Scopri un ottimo equilibrio di prestazioni, contenuti multimediali e connettività a un valore straordinario.",
                "tr_title": "Prestazioni e connettività a un valore straordinario.",
                "tr_gen_core_title": "Processore Intel® Pentium® Silver",
                "processorCompare": {
                  "tr_title": "TROVA L'EQUILIBRIO GIUSTO TRA PRESTAZIONI E VALORE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Processore Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Processore Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navigazione sul Web veloce su siti ricchi di contenuti multimediali",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Passaggio semplice tra programmi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming occasionale",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming di video 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotoritocco ed editing di video non intensivi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Produttività rapida con prestazioni intelligenti",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Scopri multitasking fluido, editing semplice di foto e video, video 4K e grafica brillante a un valore incredibile.",
                "tr_title": "Prestazioni sbalorditive per il lavoro e il divertimento.",
                "tr_gen_core_title": "Processore Intel® Pentium® Gold",
                "processorCompare": {
                  "tr_title": "TROVA L'EQUILIBRIO GIUSTO TRA PRESTAZIONI E VALORE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Processore Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Processore Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navigazione sul Web veloce su siti ricchi di contenuti multimediali",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Passaggio semplice tra programmi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming occasionale",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming di video 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotoritocco ed editing di video non intensivi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Produttività rapida con prestazioni intelligenti",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Gaming AAA e lunga durata della batteria in un PC ultraportatile. Quando giochi non serve scendere a compromessi.",
                "tr_title": "Non serve scendere a compromessi.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;undicesima generazione",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TROVA IL TUO PROCESSORE INTEL® CORE™ ULTRAPORTATILE PER IL GAMING.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i5&#8209;11300H di undicesima generazione"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i7&#8209;11370H di undicesima generazione"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i7&#8209;11375H di undicesima generazione Special Edition"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Velocità di&nbsp;clock massima fino a <span class='style_badge'>4,4 GHz</span>",
                      "tr_td2_title": "Velocità di&nbsp;clock massima fino a <span class='style_badge'>4,8 GHz</span>",
                      "tr_td3_title": "Velocità di&nbsp;clock massima fino a <span class='style_badge'>5,0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 core/ 8&nbsp;thread",
                      "tr_td2_title": "4 core/ 8&nbsp;thread",
                      "tr_td3_title": "4 core/ 8&nbsp;thread"
                    },
                    {
                      "tr_td1_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td2_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td3_title": "Tecnologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB di Intel® Smart Cache",
                      "tr_td2_title": "12 MB di Intel® Smart Cache",
                      "tr_td3_title": "12 MB di Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Grafica Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafica Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafica Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Gaming AAA a 1080p con FPS elevati e impostazioni ottimali e prestazioni eccellenti senza collegamento all'alimentazione in un PC ultraportatile.",
                "tr_title": "Non scendere a compromessi.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TROVA IL TUO PROCESSORE INTEL® CORE™ ULTRAPORTATILE PER IL GAMING.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i5&#8209;11300H di undicesima generazione"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i7&#8209;11370H di undicesima generazione"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i7&#8209;11375H di undicesima generazione Special Edition"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Velocità di&nbsp;clock massima fino a <span class='style_badge'>4,4 GHz</span>",
                      "tr_td2_title": "Velocità di&nbsp;clock massima fino a <span class='style_badge'>4,8 GHz</span>",
                      "tr_td3_title": "Velocità di&nbsp;clock massima fino a <span class='style_badge'>5,0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 core/ 8&nbsp;thread",
                      "tr_td2_title": "4 core/ 8&nbsp;thread",
                      "tr_td3_title": "4 core/ 8&nbsp;thread"
                    },
                    {
                      "tr_td1_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td2_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td3_title": "Tecnologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB di Intel® Smart Cache",
                      "tr_td2_title": "12 MB di Intel® Smart Cache",
                      "tr_td3_title": "12 MB di Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Grafica Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafica Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafica Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Velocità di clock massima fino a 5,0 GHz per rivoluzionare il gaming in un PC ultraportatile da portare ovunque tu vada.",
                "tr_title": "Più potenza per meno peso.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione Special&nbsp;Edition",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TROVA IL TUO PROCESSORE INTEL® CORE™ ULTRAPORTATILE PER IL GAMING.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i5&#8209;11300H di undicesima generazione"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i7&#8209;11370H di undicesima generazione"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i7&#8209;11375H di undicesima generazione Special Edition"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Velocità di&nbsp;clock massima fino a <span class='style_badge'>4,4 GHz</span>",
                      "tr_td2_title": "Velocità di&nbsp;clock massima fino a <span class='style_badge'>4,8 GHz</span>",
                      "tr_td3_title": "Velocità di&nbsp;clock massima fino a <span class='style_badge'>5,0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 core/ 8&nbsp;thread",
                      "tr_td2_title": "4 core/ 8&nbsp;thread",
                      "tr_td3_title": "4 core/ 8&nbsp;thread"
                    },
                    {
                      "tr_td1_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td2_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td3_title": "Tecnologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB di Intel® Smart Cache",
                      "tr_td2_title": "12 MB di Intel® Smart Cache",
                      "tr_td3_title": "12 MB di Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Grafica Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafica Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafica Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Il processore Intel® Core™ i7 di undicesima generazione Special Edition porta la creazione avanzata di contenuti, il gaming fluido e un nuovo livello di intrattenimento sui PC ultraportatili.",
                "tr_title": "Prestazioni rivoluzionarie per i notebook",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione Special&nbsp;Edition",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI&nbsp;UNDICESIMA&nbsp;GENERAZIONE SPECIAL&nbsp;EDITION"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Il processore Intel® Core™ i7 di undicesima generazione Special Edition porta la creazione avanzata di contenuti, il gaming fluido e un nuovo livello di intrattenimento sui PC ultraportatili.",
                "tr_title": "Prestazioni rivoluzionarie per i notebook",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione Special&nbsp;Edition",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI&nbsp;UNDICESIMA&nbsp;GENERAZIONE SPECIAL&nbsp;EDITION",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Velocità di clock ottimali e core elevati lavorano insieme per ottenere di più dal gaming competitivo e dalla creazione di contenuti complessi.",
                "tr_title": "Potenza eccezionale, perfettamente bilanciata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;undicesima generazione",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Il giusto equilibrio tra velocità di clock e prestazioni a livello di core consente di ottenere il massimo dai tuoi videogiochi, dalle modalità competitive con FPS elevati alle esperienze AAA immersive.",
                "tr_title": "Potenza mirata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;undicesima generazione",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Riproduci, registra e trasmetti in streaming titoli impegnativi con FPS elevati e passa facilmente a carichi di lavoro intensivi di multitasking.",
                "tr_title": "Potenza per superare i&nbsp;limiti",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Velocità di clock ottimali e core elevati lavorano insieme per ottenere di più dal gaming competitivo e dalla creazione di contenuti complessi.",
                "tr_title": "Potenza eccezionale, perfettamente bilanciata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;undicesima generazione",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Passa facilmente dallo streaming di film alla creazione di presentazioni grazie alle prestazioni elevate a tua disposizione.",
                "tr_title": "Energia per l'intera giornata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 con Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processore Intel®&nbsp;Core™&nbsp;i3 con Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Le prestazioni versatili abbinate a una lunga durata della batteria ti permettono di continuare a usare il tuo PC ovunque tu vada.",
                "tr_title": "Energia per l'intera giornata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 con Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processore Intel®&nbsp;Core™&nbsp;i3 con Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gestisci molteplici programmi con facilità per essere sempre pronto a tutto, ovunque ti porti la tua giornata.",
                "tr_title": "Multitasking in movimento",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 con Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processore Intel®&nbsp;Core™&nbsp;i5 con Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Migliora tutte le attività che svolgi, dall'intrattenimento alla produttività passando per la creazione di contenuti.",
                "tr_title": "Passa a un PC più potente",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 con Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processore Intel®&nbsp;Core™&nbsp;i5 con Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Prestazioni per il gaming di livello desktop anche in movimento per un gameplay competitivo con FPS elevati ed esperienze AAA realistiche.",
                "tr_title": "Potenza mirata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;undicesima generazione",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Prestazioni per il gaming di livello desktop anche in movimento per un gameplay competitivo con FPS elevati ed esperienze AAA realistiche.",
                "tr_title": "Potenza mirata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Un notebook per giocare, registrare e trasmettere in streaming contemporaneamente con FPS elevati e per passare senza problemi a carichi di lavoro di multitasking complessi.",
                "tr_title": "Potenza per superare i&nbsp;limiti",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Un notebook per giocare, registrare e trasmettere in streaming contemporaneamente con FPS elevati e per passare senza problemi a carichi di lavoro di multitasking complessi.",
                "tr_title": "Potenza per superare i&nbsp;limiti",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Velocità di clock ottimali e un numero elevato di core si combinano per ottenere di più dal gaming competitivo e dalla creazione di contenuti complessi, ovunque ti trovi.",
                "tr_title": "Potenza eccezionale, perfettamente bilanciata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;undicesima generazione",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Velocità di clock ottimali e un numero elevato di core si combinano per ottenere di più dal gaming competitivo e dalla creazione di contenuti complessi, ovunque ti trovi.",
                "tr_title": "Potenza eccezionale, perfettamente bilanciata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Velocità di clock ottimali, un numero elevato di core e prestazioni sbloccate si combinano per ottenere di più dal gaming competitivo e dalla creazione di contenuti complessi, ovunque ti trovi.",
                "tr_title": "Potenza sbloccata e perfettamente bilanciata.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;undicesima generazione",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Velocità di clock ottimali, un numero elevato di core e prestazioni sbloccate si combinano per ottenere di più dal gaming competitivo e dalla creazione di contenuti complessi, ovunque ti trovi.",
                "tr_title": "Potenza sbloccata e perfettamente bilanciata.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Velocità di clock ottimali e core elevati lavorano insieme per ottenere di più dal gaming competitivo e dalla creazione di contenuti complessi.",
                "tr_title": "Potenza eccezionale, perfettamente bilanciata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Velocità di clock ottimali e un numero elevato di core lavorano insieme per consentirti di ottenere di più dalla creazione di contenuti complessi, ovunque ti trovi.",
                "tr_title": "Potenza eccezionale, perfettamente bilanciata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI UNDICESIMA GENERAZIONE",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Velocità di clock ottimali e un numero elevato di core lavorano insieme per consentirti di ottenere di più dalla creazione di contenuti complessi.",
                "tr_title": "Potenza eccezionale, perfettamente bilanciata",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI UNDICESIMA GENERAZIONE",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Spingi il tuo potenziale con un'esperienza di gioco all'avanguardia",
                "tr_title": "Gareggia ai massimi livelli",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;decima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ DI DECIMA GENERAZIONE IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA senza interruzioni",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e registrazione",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creazione avanzata di contenuti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prestazioni di gaming ai massimi livelli",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Sbloccato e overcloccabile<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Spingi il tuo potenziale con un'esperienza di gioco all'avanguardia",
                "tr_title": "Gareggia ai massimi livelli",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;decima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ DI DECIMA GENERAZIONE IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA senza interruzioni",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e registrazione",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Creazione avanzata di contenuti",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Prestazioni di gaming ai massimi livelli",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Sbloccato e overcloccabile<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 10<br/>Thread: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Un notebook progettato appositamente per chi è sempre in viaggio.",
                "tr_title": "Una nuova categoria di notebook",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;decima generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Velocità di clock ottimali e core elevati lavorano insieme per ottenere di più dal gaming competitivo e dalla creazione di contenuti complessi.",
                "tr_title": "Potenza sbloccata e perfettamente bilanciata.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI UNDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Velocità di clock ottimali, un numero elevato di core e prestazioni sbloccate lavorano insieme per consentirti di ottenere di più dalla creazione di contenuti complessi, ovunque ti trovi.",
                "tr_title": "Potenza sbloccata e perfettamente bilanciata.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI UNDICESIMA GENERAZIONE",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Velocità di clock ottimali e un numero elevato di core lavorano insieme per consentirti di ottenere di più dalla creazione di contenuti complessi.",
                "tr_title": "Potenza sbloccata e perfettamente bilanciata.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;undicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI UNDICESIMA GENERAZIONE",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Intel® Smart Cache da <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gameplay e realtà virtuale fluidi sui titoli più impegnativi",
                "tr_title": "Il gaming di alto livello inizia da qui",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;nona generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming, streaming e registrazione senza compromessi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Migliore processore Intel® Core™ di nona generazione per il gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 4<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FINO AL 32% DI FPS IN PIÙ <br/><span class='tr_subtitle_font'>durante il gaming, lo streaming e la registrazione rispetto a un PC di 3 anni fa<sup>3</sup></span>",
                "tr_title": "La potenza per condividere i migliori momenti di gioco",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;nona generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming, streaming e registrazione senza compromessi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Migliore processore Intel® Core™ di nona generazione per il gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 4<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FINO AL 41% DI FPS IN PIÙ <br/><span class='tr_subtitle_font'>durante il gaming, lo streaming e la registrazione rispetto a un PC di 3 anni fa<sup>4</sup></span>",
                "tr_title": "I gamer esprimono un desiderio. I processori Intel® Core™ i9 lo soddisfano.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;nona generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>thread</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>core</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Fino a <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>di cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Sbloccato",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROVA IL PROCESSORE INTEL® CORE™ IDEALE PER LE TUE ESIGENZE DI GIOCO.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Processore Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Processore Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming competitivo",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming, streaming e registrazione senza compromessi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Migliore processore Intel® Core™ di nona generazione per il gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 4<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 6<br/>Thread: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocità di clock<sup>2</sup>: fino a 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Core: 8<br/>Thread: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Nato per la prossima generazione di gaming",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;dodicesima generazione",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI DODICESIMA GENERAZIONE"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;dodicesima generazione",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI DODICESIMA GENERAZIONE"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;dodicesima generazione",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI DODICESIMA GENERAZIONE"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>core</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] su Performance-core",
                    "tr_badgeSubTitle": "[e_thread] su Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Dove la battlestation incontra la workstation",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;dodicesima generazione",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI DODICESIMA GENERAZIONE"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;dodicesima generazione",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI DODICESIMA GENERAZIONE"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>core</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] su Performance-core",
                    "tr_badgeSubTitle": "[e_thread] su Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni affidabili, quando sono maggiormente necessarie",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;dodicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i3 DI DODICESIMA GENERAZIONE",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Un aumento delle prestazioni dove è maggiormente necessario",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;dodicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI DODICESIMA GENERAZIONE",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Un aumento delle prestazioni dove è maggiormente necessario",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;dodicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI DODICESIMA GENERAZIONE",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni superiori dove sono maggiormente necessarie",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;dodicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI DODICESIMA GENERAZIONE",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni superiori dove sono maggiormente necessarie",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;dodicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI DODICESIMA GENERAZIONE",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Con i notebook Intel® Evo™, ottieni prestazioni elevate, intelligenza integrata per videochattare senza problemi, Internet più veloce, una batteria che dura a lungo e altro ancora.",
                "tr_title": "Un aumento delle prestazioni dove sono maggiormente necessarie.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;dodicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI DODICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Con i notebook Intel® Evo™, ottieni prestazioni elevate, intelligenza integrata per videochattare senza problemi, Internet più veloce, una batteria che dura a lungo e altro ancora.",
                "tr_title": "Prestazioni superiori dove sono maggiormente necessarie",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;dodicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI DODICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Con i notebook Intel® Evo™, ottieni prestazioni elevate, intelligenza integrata per videochattare senza problemi, Internet più veloce, una batteria che dura a lungo e altro ancora.",
                "tr_title": "Prestazioni estreme in un notebook premium",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;dodicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI DODICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Con tecnologia Intel® Turbo Boost 2.0 fino&nbsp;a&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Progettato per le aziende",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;dodicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI DODICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Soppressione del rumore e sfocatura dello sfondo",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Personalizzazione automatica delle prestazioni per dare priorità alle attività più frequenti",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Chiamate web di alta qualità senza disturbi",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Sicurezza basata su hardware preconfigurata",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Funzionalità per la gestione remota dei dispositivi",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Ottimizzazione automatica delle prestazioni Wi&#8209;Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Accelerazione dello sviluppo delle app basate sui dati",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "La maggiore produttività con prestazioni migliorate",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Con tecnologia Intel® Turbo Boost 2.0 fino&nbsp;a&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Garantisce la massima produttività, così tu puoi concentrarti sul tuo business.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;dodicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI DODICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Soppressione del rumore e sfocatura dello sfondo",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Personalizzazione automatica delle prestazioni per dare priorità alle attività più frequenti",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Chiamate web di alta qualità senza disturbi",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Sicurezza basata su hardware preconfigurata",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Funzionalità per la gestione remota dei dispositivi",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Ottimizzazione automatica delle prestazioni Wi&#8209;Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Accelerazione dello sviluppo delle app basate sui dati",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "La maggiore produttività con prestazioni migliorate",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Con tecnologia Intel® Turbo Boost 2.0 fino&nbsp;a&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Aumenta la velocità del business",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;dodicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI DODICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Soppressione del rumore e sfocatura dello sfondo",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Personalizzazione automatica delle prestazioni per dare priorità alle attività più frequenti",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Chiamate web di alta qualità senza disturbi",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Sicurezza basata su hardware preconfigurata",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Funzionalità per la gestione remota dei dispositivi",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Ottimizzazione automatica delle prestazioni Wi&#8209;Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Accelerazione dello sviluppo delle app basate sui dati",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "La maggiore produttività con prestazioni migliorate",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni affidabili dedicate a fare di più",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;tredicesima generazione",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni per il mondo reale dedicate a fare di più",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;tredicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI TREDICESIMA GENERAZIONE",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni per il mondo reale dedicate a fare di più",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;tredicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI TREDICESIMA GENERAZIONE",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni potenti dedicate a fare di più",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;tredicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI TREDICESIMA GENERAZIONE",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni potenti dedicate a fare di più",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;tredicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI TREDICESIMA GENERAZIONE",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Oltre le prestazioni",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;tredicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI TREDICESIMA GENERAZIONE",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>core</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] su Performance-core",
                    "tr_badgeSubTitle": "[e_thread] su Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Collegati in modo immediato al tuo telefono<sup>24</sup>, seleziona in maniera intelligente il Wi-Fi migliore e carica velocemente la batteria di lunga durata.",
                "tr_title": "Supera i confini delle prestazioni per fare di più ovunque ti trovi",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;tredicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI TREDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Collegati in modo immediato al tuo telefono<sup>24</sup>, seleziona in maniera intelligente il Wi-Fi migliore e carica velocemente la batteria di lunga durata.",
                "tr_title": "Supera i confini delle prestazioni e permetti al tuo PC di fare ancora di più contemporaneamente",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;tredicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI TREDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Collegati in modo immediato al tuo telefono<sup>24</sup>, seleziona in maniera intelligente il Wi-Fi migliore e carica velocemente la batteria di lunga durata.",
                "tr_title": "Supera i confini delle prestazioni per accelerare le attività più impegnative",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;tredicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI TREDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Oltre le prestazioni",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;tredicesima generazione",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI TREDICESIMA GENERAZIONE",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;tredicesima generazione",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI TREDICESIMA GENERAZIONE",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;tredicesima generazione",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI TREDICESIMA GENERAZIONE",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>core</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] su Performance-core",
                    "tr_badgeSubTitle": "[e_thread] su Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Oltre le prestazioni",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>core</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] su Performance-core",
                    "tr_badgeSubTitle": "[e_thread] su Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni affidabili dedicate a fare di più",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni per il mondo reale dedicate a fare di più",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni potenti dedicate a fare di più",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Oltre le prestazioni",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>core</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] su Performance-core",
                    "tr_badgeSubTitle": "[e_thread] su Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni affidabili dedicate a fare di più",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni per il mondo reale dedicate a fare di più",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;5",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ 5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Prestazioni potenti dedicate a fare di più",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;7",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ 7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Processore Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processore",
                "tr_title": "Gioca senza compromessi",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;5",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;7",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;9",
                    "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>core</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core",
                    "tr_badgeSubTitle1": "[l_core] LPE-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>thread</span></span>",
                    "tr_badgePreTitle": "[p_thread] su Performance-core",
                    "tr_badgeSubTitle": "[e_thread] su Efficient-core",
                    "tr_badgeSubTitle1": "[l_thread] su LPE-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Processore Intel® Core™ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Gioca senza compromessi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Gioca senza compromessi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Gioca senza compromessi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Gioca senza compromessi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Gioca senza compromessi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Gioca senza compromessi",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Le piccole imprese si meritano grandi soluzioni aziendali.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;tredicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i5 DI TREDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Soppressione del rumore e sfocatura dello sfondo",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Personalizzazione automatica delle prestazioni per dare priorità alle attività più frequenti",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Chiamate web di alta qualità senza disturbi",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Protezioni di sicurezza basate su hardware pronte all'uso",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Aiuta a proteggere i dispositivi e i dati dalle manomissioni fisiche",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Le piccole imprese si meritano grandi soluzioni aziendali.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;tredicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i7 DI TREDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Soppressione del rumore e sfocatura dello sfondo",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Personalizzazione automatica delle prestazioni per dare priorità alle attività più frequenti",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Chiamate web di alta qualità senza disturbi",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Protezioni di sicurezza basate su hardware pronte all'uso",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Aiuta a proteggere i dispositivi e i dati dalle manomissioni fisiche",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Le piccole imprese si meritano grandi soluzioni aziendali.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9 di&nbsp;tredicesima generazione",
                "tr_gen_core_title_uppercase": "PROCESSORE INTEL® CORE™ i9 DI TREDICESIMA GENERAZIONE",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE QUESTO PROCESSORE?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo attuale",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Soppressione del rumore e sfocatura dello sfondo",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Personalizzazione automatica delle prestazioni per dare priorità alle attività più frequenti",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Chiamate web di alta qualità senza disturbi",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Protezioni di sicurezza basate su hardware pronte all'uso",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Aiuta a proteggere i dispositivi e i dati dalle manomissioni fisiche",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Libera nuove esperienze IA per le aziende",
                "tr_title": "Preparati per il futuro con i processori Intel® Core™ Ultra&nbsp;[XX] e Intel vPro®",
                "tr_gen_core_title": "Processore Intel® Core™ Ultra [XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "DAI UNA SPINTA ALLA TUA AZIENDA CON I PC CON IA DI INTEL",
                    "tr_badgeSubTitle": "I potenti PC in grado di fornire una grande esperienza d'uso sono al centro della produttività aziendale. Intel vPro® aumenta l'efficienza e le prestazioni dei dipendenti su tutti i carichi di lavoro e le applicazioni grazie a una nuova architettura che offre prestazioni superiori a basso consumo energetico per svariati carichi di lavoro aziendali complessi inviando l'attività giusta al motore giusto al momento giusto.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "FUNZIONALITÀ DI SICUREZZA MULTILAYER ABILITATE FIN DA SUBITO",
                    "tr_badgeSubTitle": "Nuove minacce compaiono ogni giorno e richiedono un approccio completo alla sicurezza. Intel vPro® offre protezioni a livello hardware e in tutto lo stack, così da tutelare al meglio le aziende. Queste misure di sicurezza uniche e pronte all'uso includono le funzionalità di rilevamento delle minacce basate sull'IA, che aiutano a proteggere gli utenti, i dati e l'azienda.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "STRUMENTI INTEGRATI PER MANTENERE GLI UTENTI PRODUTTIVI PRATICAMENTE OVUNQUE",
                    "tr_badgeSubTitle": "Gli uffici sono ovunque e così anche i PC. Intel vPro® offre funzionalità di gestione moderne basate su hardware che facilitano la gestione della flotta di PC da parte delle aziende, semplificano il supporto e migliorano le esperienze d'uso degli utenti finali, il tutto rendendo possibili pratiche informatiche sostenibili.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Più di un ottimo PC, un PC con IA",
                "tr_subtitle1": "BASATO SU PROCESSORI INTEL® CORE™ ULTRA (SERIE 2)",
                "tr_subtitle2": "Ora è possibile sul tuo PC Intel con IA",
                "tr_subtitle": "Il processore per sistemi portatili più efficiente, sicuro e performante di Intel<sup>39</sup>",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;5 (Serie&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Più creatività</span> grazie agli strumenti IA per creare più velocemente"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Più produttività</span> con un'assistenza IA che fa risparmiare tempo"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Più sicurezza</span> con protezione dei dati integrata"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Più di un ottimo PC, un PC con IA",
                "tr_subtitle1": "BASATO SU PROCESSORI INTEL® CORE™ ULTRA (SERIE 2)",
                "tr_subtitle2": "Ora è possibile sul tuo PC Intel con IA",
                "tr_subtitle": "Il processore per sistemi portatili più efficiente, sicuro e performante di Intel<sup>39</sup>",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;7 (Serie&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Più creatività</span> grazie agli strumenti IA per creare più velocemente"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Più produttività</span> con un'assistenza IA che fa risparmiare tempo"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Più sicurezza</span> con protezione dei dati integrata"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Più di un ottimo PC, un PC con IA",
                "tr_subtitle1": "BASATO SU PROCESSORI INTEL® CORE™ ULTRA (SERIE 2)",
                "tr_subtitle2": "Ora è possibile sul tuo PC Intel con IA",
                "tr_subtitle": "Il processore per sistemi portatili più efficiente, sicuro e performante di Intel<sup>39</sup>",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;5 (Serie&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Più di un ottimo PC, un PC con IA",
                "tr_subtitle1": "BASATO SU PROCESSORI INTEL® CORE™ ULTRA (SERIE 2)",
                "tr_subtitle2": "Ora è possibile sul tuo PC Intel con IA",
                "tr_subtitle": "Il processore per sistemi portatili più efficiente, sicuro e performante di Intel<sup>39</sup>",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;7 (Serie&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_tileTitle1": "Grafica dedicata",
                "tr_title": "CHE COS’È UN’UNITÀ DI ELABORAZIONE GRAFICA?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "L’unità di elaborazione grafica o GPU crea le immagini che vedi sullo schermo.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU integrata",
                    "tr_badgeSubTitle": "Una GPU integrata utilizza la stessa RAM del resto del computer, rendendo maggiore l'efficienza energetica.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU dedicata",
                    "tr_badgeSubTitle": "Questa è la categoria più potente di GPU, dotata di RAM dedicata e altre risorse destinate alla creazione di una grafica eccezionale.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "GPU ibrida",
                    "tr_badgeSubTitle": "Questa è la categoria più potente di GPU, dotata di RAM dedicata e altre risorse destinate alla creazione di una grafica eccezionale.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Editing di foto e video ottimizzato",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Maratone di contenuti video in 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Giochi popolari con dettagli straordinari",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Doppia GPU",
                    "tr_badgeSubTitle": "Alcuni sistemi, come quelli con grafica Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, sono in grado di sfruttare sia la GPU del processore sia quella dedicata per distribuire il carico di lavoro di grafica.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU integrata",
                    "tr_badgeSubTitle": "Una GPU integrata è costruita nello stesso chip del processore principale o CPU. Questo tipo di GPU utilizza la stessa RAM e altre risorse del resto del computer, rendendolo più efficiente dal punto di vista energetico e rendendo possibili dispositivi più sottili e più leggeri.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU dedicata",
                    "tr_badgeSubTitle": "Questa è la categoria più potente di GPU, dotata di RAM dedicata e altre risorse destinate alla creazione di una grafica eccezionale.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Standard",
                    "tr_badgeSubTitle": "Fino a 4&nbsp;GB di VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Competitiva",
                    "tr_badgeSubTitle": "Fino a 8&nbsp;GB di VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Più di 8&nbsp;GB di VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "Potenza grafica estrema",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Un'elaborazione grafica di massimo livello richiede una scheda grafica che si occupa esclusivamente di creare immagini straordinariamente nitide e animazioni fluide.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "Videografica integrata nitida e brillante",
                "tr_pretitle": "Grafica Intel® Iris® Plus",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "La Grafica Intel® Iris® Plus ti permette di immergerti in video 4K HDR e nel gaming Full HD senza la necessità di una scheda grafica aggiuntiva.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "Immagini mozzafiato, prestazioni senza sforzo",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "La Grafica Intel® offre rivoluzionarie prestazioni di grafica integrata per supportare una progettazione fluida per i creatori di contenuti ed esperienze di gaming avanzate.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "Grafica Intel® Iris™ Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "Grafica UHD Intel® con architettura X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "Esperienze visive immersive",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Scopri l'editing di foto e video ottimizzato, fai una maratona di contenuti video premium in 4K o goditi i dettagli straordinari dei videogame.",
                "tr_photo_text": "Editing di foto e video ottimizzato",
                "tr_video_text": "Maratone di contenuti video in 4K",
                "tr_game_text": "Giochi popolari con dettagli straordinari",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MOLTO PIÙ DI BELLE IMMAGINI",
                "tr_subtitle": "La scheda grafica (o GPU) lavora in combinazione con il processore (o CPU) per determinare il funzionamento e l'aspetto dei videogame. Dedicata solo alla grafica, la VRAM (o video RAM) è un modo veloce per valutare la GPU del sistema.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PER UNA GRAFICA DI GIOCO SUPERIORE",
                "tr_subtitle": "Un'elaborazione grafica di massimo livello richiede una scheda grafica dedicata che si occupa esclusivamente di creare immagini straordinariamente nitide e animazioni fluide.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PER UNA GRAFICA DI GIOCO SUPERIORE",
                "tr_subtitle": "Un'elaborazione grafica di massimo livello richiede una scheda grafica dedicata che si occupa esclusivamente di creare immagini straordinariamente nitide e animazioni fluide.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MOLTO PIÙ DI BELLE IMMAGINI",
                "tr_subtitle": "La scheda grafica (o GPU) lavora in combinazione con il processore (o CPU) per determinare il funzionamento e l'aspetto dei videogame. Dedicata solo alla grafica, la VRAM (o video RAM) è un modo veloce per valutare la GPU del sistema.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "Potenza grafica estrema",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Un'elaborazione grafica di massimo livello richiede una scheda grafica che si occupa esclusivamente di creare immagini straordinariamente nitide e animazioni fluide.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "PER UNA GRAFICA DI GIOCO SUPERIORE",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Un'elaborazione grafica di massimo livello richiede una scheda grafica che si occupa esclusivamente di creare immagini straordinariamente nitide e animazioni fluide.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Un'elaborazione grafica di massimo livello richiede una scheda grafica dedicata che si occupa esclusivamente di creare immagini straordinariamente nitide e animazioni fluide.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Offre una notevole potenza grafica per soddisfare i requisiti minimi di gioco.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Per i gamer di fascia alta che richiedono gaming di livello professionale",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Per i gamer di livello avanzato che usano il PC anche per la creazione di contenuti impegnativi",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Video brillanti e gaming immersivo",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Ottieni il meglio dal tuo schermo ad alta risoluzione con la grafica HD Intel® e goditi una fantastica esperienza multimediale e l'elaborazione grafica integrata.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Per dare vita a immagini e video brillanti",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Ottieni più dettagli e un colore brillante dai tuoi streaming di video e foto.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "Creatività e gaming sorprendenti",
                "tr_pretitle": "Grafica Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intrattenimento immersivo, creazione avanzata di contenuti e gaming mainstream: la grafica Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> offre prestazioni rivoluzionarie su PC sottili e leggeri.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_pretitle": "PER UNA GRAFICA DI GIOCO SUPERIORE",
                "tr_title": "Grafica Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Un'elaborazione grafica di massimo livello richiede una scheda grafica dedicata che si occupa esclusivamente di creare immagini straordinariamente nitide e animazioni fluide.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>DI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "Libera la creatività",
                "tr_pretitle": "Grafica Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Grazie alla potenza combinata della grafica integrata nel processore e al primo motore grafico dedicato Intel®, ora è possibile passare da un progetto creativo all'altro e immergersi in una vasta gamma di giochi.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "tr_title": "Grafica Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "tr_pretitle": "PER UNA GRAFICA DI GIOCO SUPERIORE",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Un'elaborazione grafica di massimo livello richiede una scheda grafica dedicata che si occupa esclusivamente di creare immagini straordinariamente nitide e animazioni fluide.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>DI VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Grafica",
                "tr_title": "Straordinaria videografica integrata",
                "tr_subtitle": "Goditi giochi, film e video Web con dettagli e chiarezza straordinari.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafica",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafica",
                    "tr_specText": "Potenzia i tuoi flussi di lavoro"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafica",
                    "tr_specText": "Potenzia la tua esperienza di gioco"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafica",
                    "tr_specText": "Potenzia la tua esperienza di gioco"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafica",
                    "tr_specText": "Fai salire di livello il tuo gioco"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "LIBERA LA CREATIVITÀ",
                    "tr_specText": "Velocizza la tua produttività ovunque ti trovi con le funzionalità multimediali più complete, come la <strong>prima codifica hardware AV1 del settore</strong> e la creazione di contenuti accelerata dall'IA.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "LIBERA LA CREATIVITÀ",
                    "tr_specText": "Velocizza la tua produttività con le funzionalità multimediali più complete, come la <strong>prima codifica hardware AV1 del settore</strong> e la creazione di contenuti accelerata dall'IA.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "LIBERA LA CREATIVITÀ",
                    "tr_specText": "Sperimenta la creazione di contenuti accelerata dall'IA all'avanguardia con la <strong>prima codifica hardware AV1 del settore</strong> e il <strong>motore multimediale X<sup>e</sup></strong> avanzato.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "LIBERA LA CREATIVITÀ",
                    "tr_specText": "Sperimenta la creazione di contenuti accelerata dall'IA all'avanguardia con la <strong>prima codifica hardware AV1 del settore</strong> e il <strong>motore multimediale X<sup>e</sup></strong> avanzato.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "LIBERA LA CREATIVITÀ",
                    "tr_specText": "Sperimenta la creazione di contenuti accelerata dall'IA all'avanguardia con la <strong>prima codifica hardware AV1 del settore</strong> e il <strong>motore multimediale X<sup>e</sup></strong> avanzato.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "LA PORTABILITÀ INCONTRA LE PRESTAZIONI",
                    "tr_specText": "Gioca ai giochi più recenti in movimento e ottieni prestazioni e fedeltà potenziate con funzionalità di gaming moderne. <br/><br/><strong>Intel® X<sup>e</sup>SS (Intel® X<sup>e</sup> Super Sampling): </strong>tecnologia di upscaling potenziata dall'IA di Intel® per prestazioni migliori e un gaming fluido. <br/><br/>Supporto di <strong>DirectX* 12 Ultimate: </strong> porta il tuo gaming a un nuovo livello di realismo con le tecnologie grafiche come il ray tracing e lo shading a frequenza variabile."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "ASSUMI IL CONTROLLO DEI TUOI GIOCHI",
                    "tr_specText": "<strong>Il Centro di controllo Intel® Arc™</strong> ti permette di perfezionare la tua esperienza con un layout intuitivo e moderno. Ottieni aggiornamenti dei driver senza problemi, ottimizza i tuoi giochi e altro."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "ASSUMI IL CONTROLLO DEI TUOI GIOCHI",
                    "tr_specText": "<strong>Il Centro di controllo Intel® Arc™</strong> ti permette di perfezionare la tua esperienza con un layout intuitivo e moderno. Ottieni aggiornamenti dei driver senza problemi, ottimizza i tuoi giochi e altro."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "ASSUMI IL CONTROLLO DEI TUOI GIOCHI",
                    "tr_specText": "Ottieni aggiornamenti dei driver senza problemi e ottimizza molti dei tuoi giochi preferiti con il software del <strong>Centro di controllo Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MASSIMIZZA LA TUA PIATTAFORMA INTEL®",
                    "tr_specText": "L'abbinamento con i processori Intel® Core™ compatibili libera la <strong>tecnologia Intel® Deep Link</strong> per migliorare la tua creazione, il tuo gaming e la tua esperienza di streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MASSIMIZZA IL TUO SISTEMA INTEL®",
                    "tr_specText": "L'abbinamento con i processori Intel® Core™ compatibili libera la <strong>tecnologia Intel® Deep Link</strong> per migliorare la tua creazione, il tuo gaming e la tua esperienza di streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MASSIMIZZA LA TUA PIATTAFORMA INTEL®",
                    "tr_specText": "L'abbinamento con i processori Intel® Core™ compatibili libera la <strong>tecnologia Intel® Deep Link</strong> per migliorare la tua creazione, il tuo gaming e la tua esperienza di streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MASSIMIZZA LA TUA PIATTAFORMA INTEL®",
                    "tr_specText": "L'abbinamento con i processori Intel® Core™ compatibili libera la <strong>tecnologia Intel® Deep Link</strong> per migliorare la tua creazione, il tuo gaming e la tua esperienza di streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MASSIMIZZA IL TUO SISTEMA INTEL®",
                    "tr_specText": "L'abbinamento con i processori Intel® Core™ compatibili libera la <strong>tecnologia Intel® Deep Link</strong> per migliorare la tua creazione, il tuo gaming e la tua esperienza di streaming."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "SII PROTAGONISTA",
                    "tr_specText": "Trasmetti in modo fluido con tecnologie di streaming avanzate abilitate dal software del <strong>Centro di controllo Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "SII PROTAGONISTA",
                    "tr_specText": "Trasmetti in modo fluido con tecnologie di streaming avanzate abilitate dal software del <strong>Centro di controllo Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "SPERIMENTA UN GAMING IMMERSIVO AD ALTE PRESTAZIONI",
                    "tr_specText": "Metti le prestazioni migliori al primo posto con i giochi AAA più recenti ad alte risoluzioni e frequenze di aggiornamento elevate. <br/><br/><strong>Intel® X<sup>e</sup>SS (Intel® X<sup>e</sup> Super Sampling): </strong>tecnologia di upscaling potenziata dall'IA di Intel® per prestazioni migliori e un gaming fluido. <br/><br/>Supporto di <strong>DirectX* 12 Ultimate: </strong> porta il tuo gaming a un nuovo livello di realismo con le tecnologie grafiche come il ray tracing e lo shading a frequenza variabile."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "SPERIMENTA UN GAMING IMMERSIVO AD ALTE PRESTAZIONI",
                    "tr_specText": "Metti le prestazioni migliori al primo posto con i giochi AAA più recenti ad alte risoluzioni e frequenze di aggiornamento elevate. <br/><br/><strong>Intel® X<sup>e</sup>SS (Intel® X<sup>e</sup> Super Sampling): </strong>tecnologia di upscaling potenziata dall'IA di Intel® per prestazioni migliori e un gaming fluido. <br/><br/>Supporto di <strong>DirectX* 12 Ultimate: </strong> porta il tuo gaming a un nuovo livello di realismo con le tecnologie grafiche come il ray tracing e lo shading a frequenza variabile."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "POTENZIA LA TUA ESPERIENZA DI GIOCO",
                    "tr_specText": "Porta la tua esperienza di gaming al livello successivo con <br/><br/><strong>Intel® X<sup>e</sup>SS (Intel® X<sup>e</sup> Super Sampling): </strong> tecnologia di upscaling potenziata dall'IA di Intel® per prestazioni migliori e un gaming fluido. <br/><br/>Supporto di <strong>DirectX* 12 Ultimate: </strong> porta il tuo gaming a un nuovo livello di realismo con le tecnologie grafiche come il ray tracing e lo shading a frequenza variabile."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "PROVA IL GAMING AVANZATO A TUTTA VELOCITÀ",
                    "tr_specText": "Sperimenta la massima fluidità di gioco su tanti dei più famosi videogiochi AAA e goditi un gaming a elevata frequenza di aggiornamento, dagli sparatutto di eSport ad alto tasso di azione agli incalzanti RTS di maggior successo. <br/><br/><strong>Intel® X<sup>e</sup>SS (Intel® X<sup>e</sup> Super Sampling): </strong>tecnologia di upscaling potenziata dall'IA di Intel® per prestazioni migliori e un gaming fluido. <br/><br/>Supporto di <strong>DirectX* 12 Ultimate: </strong> porta il tuo gaming a un nuovo livello di realismo con le tecnologie grafiche come il ray tracing e lo shading a frequenza variabile."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "SPERIMENTA UN GAMING IMMERSIVO AD ALTE PRESTAZIONI",
                    "tr_specText": "Metti le prestazioni migliori al primo posto con i giochi AAA più recenti ad alte risoluzioni e frequenze di aggiornamento elevate. <br/><br/><strong>Intel® X<sup>e</sup>SS (Intel® X<sup>e</sup> Super Sampling): </strong>tecnologia di upscaling potenziata dall'IA di Intel® per prestazioni migliori e un gaming fluido. <br/><br/>Supporto di <strong>DirectX* 12 Ultimate: </strong> porta il tuo gaming a un nuovo livello di realismo con le tecnologie grafiche come il ray tracing e lo shading a frequenza variabile."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafica",
                    "tr_specText": "Un universo grafico con spazio per tutti"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "ACCELERA I TUOI FRAME, ACCELERA I TUOI GIOCHI",
                    "tr_specText": "La tecnologia Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) porta il gaming a un livello superiore grazie all'upscaling ottimizzato dall'IA, per prestazioni ottimali con un'elevata fedeltà delle immagini. X<sup class='small_text_sup_xe'>e</sup>SS è ottimizzato per i prodotti di grafica Intel® Arc™, consentendo di sfruttare appieno l'accelerazione hardware di Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX)."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "IMMAGINI PERFETTE",
                    "tr_specText": "Con il supporto completo per DirectX* 12 Ultimate su tutte le grafiche Intel® Arc™, avrai a disposizione un gioco immersivo e mozzafiato grazie alle ultime tecnologie grafiche come il ray tracing, l'ombreggiatura a tasso variabile, l'ombreggiatura mesh e il feedback del campionatore, fondamentali nel gaming di nuova generazione."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "CREAZIONE DI FOTO, VIDEO E CONTENUTI MULTIMEDIALI IN TUTTA SEMPLICITÀ",
                    "tr_specText": "Libera la tua immaginazione e cattura l'attenzione del pubblico creando contenuti digitali ricchi grazie a un motore multimediale iperavanzato, potenziato dall'IA e accelerato dalla tecnologia Intel®. Crea contenuti spettacolari con la grafica supportata per tutti i principali formati multimediali attuali, senza perdere nemmeno una delle funzionalità di codifica video AV1 più avanzate."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "SCOPRI LA CREAZIONE DI CONTENUTI E IL GAMING IA ALL'AVANGUARDIA",
                    "tr_specText": "La grafica Intel® Arc™ sblocca potenti funzionalità IA, dalla creazione avanzata al gaming immersivo. Ottieni il massimo dai software di creazione di contenuti avanzati di oggi, tra cui la generazione da testo a immagini e l'editing video accelerato dall'IA. Inoltre, prova il gioco ad alta risoluzione grazie all'upscaling X<sup class='small_text_sup_xe'>e</sup>SS accelerato dall'IA."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Grafica",
                    "tr_specText": "La grafica professionale inizia qui"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "IDEALE PER CREATIVI, DESIGNER E INGEGNERI SEMPRE IN MOVIMENTO",
                    "tr_specText": "Disponibile su processori Intel® Core™ Ultra serie H selezionati, la grafica Intel® Arc™ Pro integrata consente di raddoppiare le prestazioni grafiche con le certificazioni Independent Software Vendor (ISV) per una maggiore affidabilità, oltre a offrire accelerazione hardware IA e ray tracing efficienti per creare contenuti mozzafiato ovunque tu sia.<br/><br/><span class='expandable_heading'>Certificazioni del software<br/></span><br/>Dopo aver lavorato a stretto contatto con centinaia di aziende di software nel corso degli anni, Intel ha incluso questa esperienza senza pari nelle sue schede grafiche Intel® Arc™ Pro serie A. Le certificazioni sono importanti per noi come per te."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "RENDERE L'IA ACCESSIBILE",
                    "tr_specText": "Questa gamma di GPU include un'ampia selezione di tecnologie straordinarie, tra cui il supporto per gli strumenti di intelligenza artificiale compatibili con l'accelerazione IA integrata e dedicata, laddove necessario."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING A UN BUON PREZZO",
                    "tr_specText": "Le schede grafiche devono fare molto di più che inviare pixel ai display, devono anche accelerare la creazione di immagini stupende. La tecnologia hardware di ray tracing integrata nella grafica Intel® Arc™ Pro serie A consente al software compatibile di iniziare a esplorare questi nuovi flussi di lavoro professionali. Solitamente, si pensa che il ray tracing sia disponibile solo su grafiche costose, ma l'ultima gamma di grafiche professionali Intel va oltre questa aspettativa."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "POTENZIARE I CREATORI DI CONTENUTI CON DISPLAY IMMERSIVI",
                    "tr_specText": "Con il supporto completo per DirectX* 12 Ultimate su tutte le grafiche Intel® Arc™, avrai a disposizione un gioco immersivo e mozzafiato grazie alle ultime tecnologie grafiche come il ray tracing, l'ombreggiatura a tasso variabile, l'ombreggiatura mesh e il feedback del campionatore, fondamentali nel gaming di nuova generazione."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "IMMAGINI OTTIMIZZATE DINAMICAMENTE",
                    "tr_specText": "Grazie alla decodifica hardware dedicata sulle GPU Pro, Dolby Vision* contribuisce ad aggiungere una maggiore profondità, un contrasto più netto e un maggior numero di colori agli schermi compatibili. Dolby Vision* libera tutto il potenziale della tecnologia HDR ottimizzando dinamicamente la qualità dell'immagine in base al servizio, al dispositivo di visualizzazione e alla piattaforma, per offrire sempre immagini straordinarie."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "SOFTWARE VERIFICATO DA ESPERTI",
                    "tr_specText": "Intel ha lavorato a stretto contatto con centinaia di aziende di software nel corso degli anni, e questa esperienza ineguagliabile è confluita nella gamma di prodotti grafici professionali Intel® Arc™ Pro serie A. Le certificazioni e le qualifiche relative al flusso di lavoro del software sono importanti per noi come per te."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING A UN BUON PREZZO",
                    "tr_specText": "Le schede grafiche devono fare molto di più che inviare pixel ai display, devono anche accelerare la creazione di immagini stupende. La tecnologia hardware di ray tracing integrata nella grafica Intel® Arc™ Pro serie A consente al software compatibile di iniziare a esplorare questi nuovi flussi di lavoro professionali. Solitamente, si pensa che il ray tracing sia disponibile solo su grafiche costose, ma l'ultima gamma di grafiche professionali Intel va oltre questa aspettativa."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "GRANDI DISPLAY MULTIPLI CON UNA SOLA PICCOLA GPU PER DISPOSITIVI PORTATILI",
                    "tr_specText": "La grafica Intel® Arc™ Pro per notebook può supportare fino a quattro display ultralarge per migliorare l'efficienza del flusso di lavoro. Se si aggiunge anche il supporto per l'alta gamma dinamica (HDR), si ha tutto ciò che serve per una configurazione multischermo a casa o in ufficio da mettere in mostra sui social media."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "PIENO POTENZIALE DELLA LARGHEZZA DI BANDA DELLA MEMORIA",
                    "tr_specText": "La larghezza di banda della scheda grafica è un fattore fondamentale in una workstation. Se si sbaglia, si rischia di rallentare i flussi di lavoro professionali: ecco perché abbiamo fatto in modo che la GPU Intel® Arc™ Pro serie A supportasse il moderno PCIe* 4.0, senza penalizzare la retro-compatibilità. La memoria grafica è ulteriormente potenziata da un'elevata velocità di banda, che consente al software di accedere ai dati del progetto in modo ancora più rapido."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "OGNI FOTOGRAMMA PRENDE VITA",
                    "tr_specText": "<span class='expandable_heading'>Potenziato dall'IA. Supportato da Intel.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS è una tecnologia di upscaling ottimizzata dall'IA che aumenta le prestazioni senza rinunciare alla bellezza di ogni fotogramma, offrendoti un'esperienza di gioco così come era stata pensata."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "IN TEMPO REALE.<br/> REALMENTE IMMERSIVO.<br/> IL REALISMO AL SUO MASSIMO.",
                    "tr_specText": "Mondi più realistici ti aspettano. Con ray tracing integrato, potrai sperimentare di tutto, da ombre e riflessi realistici alla perfetta illuminazione. In altre parole, non vorrai assolutamente perderti questa esperienza visiva di gaming."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "IL TUO GAMEPLAY MIGLIORE, CONDIVISO AL MEGLIO",
                    "tr_specText": "Offri al tuo pubblico il gameplay senza interruzioni che merita di vedere. Con le funzionalità di codifica AV1 accelerate dall'hardware della grafica Intel® Arc™, resteranno affascinati da ogni tua mossa. Una qualità visiva superiore allo stesso bitrate equivale a uno streaming migliore risparmiando larghezza di banda."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Tutti i creator sono benvenuti",
                    "tr_specText": "Nuove e migliori esperienze IA sono ora a portata di mano. Con acceleratori IA avanzati integrati nella grafica Intel® Arc™ serie A, dedica più tempo alla creazione di contenuti su numerose applicazioni, riducendo i tempi di attesa.<br/><br/>Porta la creazione di video a un livello superiore e apri la strada per i video AV1 di alta qualità e dallo storage efficiente, grazie alle funzionalità hardware AV1 avanzate integrate nella grafica Intel® Arc™."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Tutto il necessario per la tua produttività",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Prestazioni potenti e dispositivi sottili, leggeri e creati per durare a lungo. Puoi avere tutto! Con una batteria a lunghissima durata, splendidi touchscreen* e le nuove unità a stato solido (SSD), i PC Windows 10 rispondono a ogni tua esigenza.",
                "tr_noteSection": "*In base all'hardware",
                "titleArray": [
                  {
                    "tr_title": "Meno è meglio",
                    "tr_subtitle": "In genere, i PC di oggi consumano meno energia per prolungare la durata utile media della batteria, consentendoti di ottenere almeno 8 ore di riproduzione video.**",
                    "tr_noteSection": "**La durata della batteria varia notevolmente a seconda delle impostazioni, dell'utilizzo e di altri fattori."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "TUTTO IL NECESSARIO PER LA TUA PRODUTTIVITÀ",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Prestazioni potenti e dispositivi sottili, leggeri e creati per durare a lungo. Puoi avere tutto! Con una batteria a lunghissima durata, splendidi touchscreen* e le nuove unità a stato solido (SSD), i PC Windows 10 rispondono a ogni tua esigenza.",
                "tr_noteSection": "*In base all'hardware",
                "titleArray": [
                  {
                    "tr_title": "MENO È MEGLIO",
                    "tr_subtitle": "In genere, i PC di oggi consumano meno energia per prolungare la durata utile media della batteria, consentendoti di ottenere almeno 8 ore di riproduzione video.**",
                    "tr_noteSection": "**La durata della batteria varia notevolmente a seconda delle impostazioni, dell'utilizzo e di altri fattori."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Ottimizzazione per la sicurezza e le prestazioni",
                "tr_pretitle": "Windows* 10 in modalità S",
                "tr_subtitle": "Windows* 10 in modalità S offre un livello aggiuntivo di protezione che garantisce la sicurezza del tuo dispositivo mentre lavori, giochi ed esplori."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "OTTIMIZZAZIONE PER LA SICUREZZA E LE PRESTAZIONI",
                "tr_title": "Windows* 10 in modalità S",
                "tr_subtitle": "Windows* 10 in modalità S offre un livello aggiuntivo di protezione che garantisce la sicurezza del tuo dispositivo mentre lavori, giochi ed esplori."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Non c'è limite a ciò che puoi fare",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Prestazioni, prezzo e ampia scelta. 2 in 1 innovativi, notebook sottili e leggeri, schermi touch e tastiere rimovibili: qualsiasi sia il tuo budget o lo stile che ti contraddistingue, c'è sempre un PC Windows* adatto alle tue esigenze."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "NON C'È LIMITE A CIÒ CHE PUOI FARE",
                "tr_title": "Windows*",
                "tr_subtitle": "Prestazioni, prezzo e ampia scelta. 2 in 1 innovativi, notebook sottili e leggeri, schermi touch e tastiere rimovibili: qualsiasi sia il tuo budget o lo stile che ti contraddistingue, c'è sempre un PC Windows* adatto alle tue esigenze."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memoria",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "DI QUANTA RAM HAI BISOGNO?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Utilizzo quotidiano - DA 2 GB FINO A 8 GB DI RAM",
                    "tr_badgeSubTitle": "Ideale per chi utilizza il PC principalmente per l'elaborazione di testi e la navigazione sul Web e tende a concentrarsi su un programma alla volta."
                  },
                  {
                    "tr_badgeTitle": "Utilizzo dove è richiesta potenza - DA 8 GB FINO A 16 GB DI RAM",
                    "tr_badgeSubTitle": "Con più RAM, le applicazioni saranno in grado di funzionare più velocemente e potrai anche eseguire molteplici applicazioni contemporaneamente."
                  },
                  {
                    "tr_badgeTitle": "Utilizzo avanzato - PIÙ DI 16 GB DI RAM",
                    "tr_badgeSubTitle": "Se ti interessano videogame, editing di foto e video o modellazione 3D, avrai bisogno di RAM aggiuntiva per gestire questi programmi intensivi."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB di RAM per il multitasking quotidiano",
                "tr_subtitle": "La memoria consentirà il caricamento e l’esecuzione fluidi di applicazioni di base, come elaborazione di testi e navigazione sul Web.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DI RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB di RAM per il multitasking potente",
                "tr_subtitle": "Se usi più programmi contemporaneamente, [memory_total] GB di memoria ti permettono di passare facilmente da un'applicazione all'altra.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DI RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB di RAM per il multitasking avanzato",
                "tr_subtitle": "Avrai molta memoria per eseguire diversi programmi intensivi contemporaneamente, senza rallentare il tuo PC.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DI RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1]&nbsp;GB di memoria = [Value2]&nbsp;GB di memoria Intel®&nbsp;Optane™ + [Value3]&nbsp;GB di RAM",
                "tr_subtitle": "Questa combinazione di soluzioni di memoria ti assicura le prestazioni accelerate del disco fisso offerte dalla memoria Intel® Optane™ e in più i tempi di risposta rapidi della RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>DI MEMORIA</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memoria Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Meno attese. Divertiti di più con i contenuti che ami.",
                "tr_subtitle": "Quando la memoria Intel® Optane™ si combina con la memoria di sistema del PC, puoi accedere più rapidamente ai programmi che utilizzi più spesso, in modo che si avviino e carichino i contenuti nell'istante in cui ne hai bisogno.",
                "tr_pretitle": "[XX] GB di memoria Intel® Optane™",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "QUAL È IL COMPITO DELLA MEMORIA DEI PC?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Creazione più veloce di contenuti",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Gaming più reattivo",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Multitasking più semplice",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Avvio più rapido dei programmi",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memoria Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Accelera il gaming</span>",
                "tr_pretitle": "[XX] GB di memoria Intel® Optane™",
                "tr_pretitle1": "La memoria Intel® Optane™ da [XX] GB accelera il gaming",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Avvio dei videogame fino al<br/><span class=\"optane-game\">98% più veloce</span><br/>dall'unità dati con un processore [optane_legal_processor_name] con memoria Intel® Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Caricamento dei livelli fino a<br/><span class=\"optane-game\">4,1 volte più rapido</span><br/>dall'unità dati con un processore [optane_legal_processor_name] con memoria Intel® Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Memoria Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Accelera il gaming</span>",
                "tr_pretitle": "[XX] GB di memoria Intel® Optane™",
                "tr_pretitle1": "La memoria Intel® Optane™ da [XX] GB accelera il gaming",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Avvio dei videogame fino al<br/><span class=\"optane-game\">98% più veloce</span><br/>dall'unità dati con un processore [optane_legal_processor_name] con memoria Intel® Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Caricamento dei livelli fino a<br/><span class=\"optane-game\">4,1 volte più rapido</span><br/>dall'unità dati con un processore [optane_legal_processor_name] con memoria Intel® Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1]&nbsp;GB di memoria = [Value2]&nbsp;GB di memoria Intel®&nbsp;Optane™ + [Value3]&nbsp;GB di RAM",
                "tr_subtitle": "Questa combinazione di soluzioni di memoria ti assicura le prestazioni accelerate del disco fisso offerte dalla memoria Intel® Optane™ e in più i tempi di risposta rapidi della RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB di RAM per il multitasking",
                    "tr_subtitle": "Se usi più programmi contemporaneamente, [memory_total] GB di memoria ti permettono di passare facilmente da un'applicazione all'altra."
                  },
                  {
                    "tr_title": "Per le attività quotidiane",
                    "tr_subtitle": "La memoria consentirà il caricamento e l’esecuzione fluidi di applicazioni di base, come elaborazione di testi e navigazione sul Web."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB DI RAM",
                "tr_pretitle": "PER UN MULTITASKING SUPERIORE",
                "tr_subtitle": "La RAM offre al processore accesso più rapido ai file di programma, consentendo alla CPU di lavorare a più attività contemporaneamente.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Memoria standard",
                    "tr_badgeSubTitle": "Fino a 8&nbsp;GB di RAM"
                  },
                  {
                    "tr_badgeTitle": "Memoria avanzata",
                    "tr_badgeSubTitle": "Più di 8 GB e fino a 16 GB di RAM"
                  },
                  {
                    "tr_badgeTitle": "Memoria d'élite",
                    "tr_badgeSubTitle": "Più di 16&nbsp;GB di RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMORIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB per fare più multitasking",
                "tr_pretitle": "PER UN MULTITASKING SUPERIORE",
                "tr_subtitle": "La RAM offre al processore accesso più rapido ai file di programma, consentendo alla CPU di lavorare a più attività contemporaneamente.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Memoria standard",
                    "tr_badgeSubTitle": "Fino a 8&nbsp;GB di RAM"
                  },
                  {
                    "tr_badgeTitle": "Memoria avanzata",
                    "tr_badgeSubTitle": "Più di 8 GB e fino a 16 GB di RAM"
                  },
                  {
                    "tr_badgeTitle": "Memoria d'élite",
                    "tr_badgeSubTitle": "Più di 16&nbsp;GB di RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE LA MEMORIA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Standard",
                      "tr_badgeSubTitle": "<span class='memory-value'>8&nbsp;GB <br/>DI <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Utilizzo avanzato",
                      "tr_badgeSubTitle": "<span class='memory-value'>16&nbsp;GB <br/>DI <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Top",
                      "tr_badgeSubTitle": "<span class='memory-value'>32&nbsp;GB <br/>DI <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memoria",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "PER COSA SI CONTRADDISTINGUE LA MEMORIA?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "PER COSA SI CONTRADDISTINGUE LA MEMORIA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo attuale",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Utilizzo quotidiano",
                      "tr_badgeSubTitle": "<span class='memory-value'>8&nbsp;GB <br/>DI <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Utilizzo potenziato",
                      "tr_badgeSubTitle": "<span class='memory-value'>16&nbsp;GB <br/>DI <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Utilizzo avanzato",
                      "tr_badgeSubTitle": "<span class='memory-value'>32&nbsp;GB <br/>DI <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Storage",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "QUANTI DATI È IN GRADO DI ARCHIVIARE IL TUO PC?",
                "tr_subtitle": "Non serve indovinare. Ti mostreremo esattamente che cosa puoi archiviare su questo PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Crea una libreria di</span> <br/><span class=\"storage-standard\">[num_songs] canzoni<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>In base a una media di 5 MB per canzone",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Crea una libreria di</span><br/><span class=\"storage-standard\">[num_games] videogame<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>In base a una media di 40 GB per gioco",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Storage per</span><br/><span class=\"storage-standard\">[num_videos] video<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>In base a una media di 5 GB per video",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Scatta e memorizza</span><br/><span class=\"storage-standard\">[num_photos] foto<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>In base a una media di 4 MB per foto",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "HDD da [capacity] GB"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "SSD da [capacity] GB"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "Memoria Intel® Optane™ da [total_optane_memory] GB + SSD da [convertedSSD] GB"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STORAGE",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "Storage SSD da [capacity] GB",
                "tr_preTitle1": "Storage SSD da [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Questo PC è dotato di un'unità SSD con [capacity] GB di storage. Questo significa che potrai salvare un numero incredibile di foto, video e programmi e accedervi molto più rapidamente rispetto a un'unità disco fisso.",
                    "tr_title": "Lo spazio per archiviare ogni cosa sul tuo PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Questo PC è dotato di un'unità SSD con [capacity] TB di storage. Questo significa che potrai salvare un numero incredibile di foto, video e programmi e accedervi molto più rapidamente rispetto a un'unità disco fisso.",
                    "tr_title": "Questo dispositivo è dotato di una notevole capacità di&nbsp;storage"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DI STORAGE</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DI STORAGE</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STORAGE",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "Storage HDD da [capacity] GB",
                "tr_preTitle1": "Storage HDD da [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "I dischi fissi rappresentano una scelta valida per chi vuole avere una grande quantità di storage direttamente nel PC.",
                    "tr_title": "Lo spazio per archiviare ogni cosa sul tuo PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "I dischi fissi rappresentano una scelta valida per chi vuole avere una grande quantità di storage direttamente nel PC.",
                    "tr_title": "Questo dispositivo è dotato di una notevole capacità di&nbsp;storage"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>DI STORAGE</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STORAGE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "QUANTI DATI È IN GRADO DI ARCHIVIARE IL TUO PC?",
                "tr_subtitle": "Non serve indovinare. Ti mostreremo esattamente che cosa puoi archiviare su questo PC.",
                "titleArray": [
                  {
                    "tr_title": "HDD DA [XX] GB"
                  },
                  {
                    "tr_title": "SSD DA [XX] GB"
                  },
                  {
                    "tr_title": "Unità SSD Intel® da [total_capacity] GB accelerata dalla memoria Intel® Optane™ da [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "Unità SSD Intel® da [total_capacity] TB, accelerata dalla memoria Intel® Optane™ da [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "SSD da [convertedSSD] GB + HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD da [convertedSSD] TB + HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD da [convertedSSD] GB + HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;TB"
                  },
                  {
                    "tr_title": "SSD da [convertedSSD] TB + HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;TB"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DI STORAGE</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DI STORAGE</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DI STORAGE</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DI STORAGE</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DI STORAGE</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DI STORAGE</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STORAGE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "Storage SSD da [convertedSSD] GB + HDD da [convertedHDD] GB",
                "tr_preTitle1": "Storage SSD da [convertedSSD] TB + HDD da [convertedHDD] GB",
                "tr_preTitle2": "Storage SSD da [convertedSSD] GB + HDD da [convertedHDD] TB",
                "tr_preTitle3": "Storage SSD da [convertedSSD] TB + HDD da [convertedHDD] TB",
                "titleArray": [
                  {
                    "tr_title": "Lo spazio per archiviare ogni cosa sul tuo PC",
                    "tr_title1": "Questo dispositivo è dotato di una notevole capacità di&nbsp;storage",
                    "tr_subtitle": "Questo PC è dotato di un'unità SSD con [convertedSSD] GB di storage e di un'unità disco fisso da [convertedHDD] GB. In questo modo, avrai a disposizione una capacità notevole e prestazioni di storage veloci.",
                    "tr_subtitle1": "Questo PC è dotato di un'unità SSD con [convertedSSD] TB di storage e di un'unità disco fisso da [convertedHDD] GB. In questo modo, avrai a disposizione una capacità notevole e prestazioni di storage veloci.",
                    "tr_subtitle2": "Questo PC è dotato di un'unità SSD con [convertedSSD] GB di storage e di un'unità disco fisso da [convertedHDD] TB. In questo modo, avrai a disposizione una capacità notevole e prestazioni di storage veloci.",
                    "tr_subtitle3": "Questo PC è dotato di un'unità SSD con [convertedSSD] TB di storage e di un'unità disco fisso da [convertedHDD] TB. In questo modo, avrai a disposizione una capacità notevole e prestazioni di storage veloci."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DI STORAGE</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DI STORAGE</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STORAGE",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Ottieni accesso accelerato allo storage ad alta capacità con la memoria Intel® Optane™ con SSD.",
                "tr_title": "Benvenuti file enormi",
                "tr_pretitle": "Unità SSD Intel® da [total_capacity] GB accelerata dalla memoria Intel® Optane™ da [optane_memory_size] GB",
                "tr_pretitle1": "Unità SSD Intel® da [total_capacity] TB, accelerata dalla memoria Intel® Optane™ da [optane_memory_size] GB",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Questa soluzione di storage rivoluzionaria è la prima in grado di combinare la memoria Intel® Optane™ e un'unità SSD su un unico chip. Ecco che cosa offre:",
                    "tr_title": "CHE COS'È LA MEMORIA INTEL® OPTANE™ CON SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Apri rapidamente tutto ciò che ti serve",
                    "tr_badgeSubTitle": "Precaricando i file e i programmi che utilizzi con maggiore frequenza, potrai dedicare più tempo alla creazione di contenuti senza inutili attese.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Storage e velocità",
                    "tr_badgeSubTitle": "Avrai tutto lo spazio che ti serve per i file multimediali con le prestazioni veloci di un'unità SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Maggiore reattività nel tempo",
                    "tr_badgeSubTitle": "La memoria Intel® Optane™ con SSD apprende il tuo modo di lavorare, rendendo il tuo PC più efficiente.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STORAGE",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Conserva tutti i tuoi contenuti multimediali sempre a portata di mano con moltissimo spazio di archiviazione affidabile.",
                "tr_title": "Spazio per la tua collezione di foto, video e canzoni"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Schermo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] milioni di pixel per scorrere, pizzicare e meravigliarti",
                "tr_subtitle": "Interagisci con il tuo PC come con uno smartphone con comandi intuitivi e dettagli più nitidi e vividi.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Risoluzione: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Pixel: [screenResolution] milioni"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Schermo da [screenDiagonal]\"",
                    "tr_badgeSubTitle": "Altezza dello schermo: [screenHeight]\"",
                    "tr_badgePreTitle": "Larghezza dello schermo: [YY]”"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Schermo touch",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Schermo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Immergiti in [screenResolution] milioni di pixel",
                "tr_subtitle": "Dipende tutto dai pixel. Più alto è il loro numero, più&nbsp;nitida e brillante sarà l'immagine.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Risoluzione: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Pixel: [screenResolution] milioni"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Schermo da [screenDiagonal]\"",
                    "tr_badgeSubTitle": "Altezza dello schermo: [screenHeight]\"",
                    "tr_badgePreTitle": "Larghezza dello schermo: [YY]”"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Schermo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal]” <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "LA FINESTRA SUI MONDI DEI TUOI VIDEOGAME",
                "tr_subtitle": "Proprio come per un televisore, gli aspetti principali da prendere in considerazione per uno schermo per il gaming sono le dimensioni e la risoluzione.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Risoluzione: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Pixel: [screenResolution] milioni"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Schermo da [screenDiagonal]\"",
                    "tr_badgeSubTitle": "Altezza dello schermo: [screenHeight]\"",
                    "tr_badgePreTitle": "Larghezza dello schermo: [screenWidth]\""
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Schermo touch"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Schermo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Questo dispositivo è dotato di un fantastico schermo",
                "tr_subtitle": "Con un numero di pixel otto volte superiore rispetto all'HD, la nitidezza e i colori di video e videogiochi raggiungono livelli mai visti prima.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Risoluzione: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Pixel: [screenResolution] milioni"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Schermo da [screenDiagonal]\"",
                    "tr_badgeSubTitle": "Altezza dello schermo: [screenHeight]\"",
                    "tr_badgePreTitle": "Larghezza dello schermo: [YY]”"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Schermo",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] milioni di pixel per tocco e scorrimento",
                    "tr_subTitle": "Guarda e interagisci con i contenuti che ami con dettagli più nitidi e vividi."
                  },
                  {
                    "tr_title": "[screenResolution] milioni di pixel per ottimizzare il tuo mondo digitale",
                    "tr_subTitle": "Dipende tutto dai pixel. Più alto è il loro numero, più&nbsp;nitida e brillante sarà l'immagine."
                  },
                  {
                    "tr_title": "Questo dispositivo è dotato di un fantastico schermo",
                    "tr_subTitle": "Con un numero di pixel circa quattro volte superiore rispetto all’HD, i video e i videogame raggiungeranno nuovi livelli di nitidezza e colore."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connettività",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "La tecnologia Thunderbolt™ 3 assicura le connessioni più rapide e versatili per qualsiasi dock, schermo o dispositivo per i dati.",
                "tr_title": "Una porta per tutte le esigenze",
                "tr_title1": "UNA PORTA PER TUTTE LE ESIGENZE",
                "titleArray": [
                  {
                    "tr_title": "COME SI USA LA TECNOLOGIA THUNDERBOLT™&nbsp;3?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tecnologia Thunderbolt™ 3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "Schermi 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Collega schermi con risoluzione, contrasto e colore straordinari"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Scheda grafica esterna",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Fai un salto di qualità immediato per quanto riguarda la grafica di qualsiasi PC"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Connessioni di rete veloci",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Connessioni peer-to-peer rapide"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connettività",
                "detailBGUrl": "",
                "tr_subtitle": "La tecnologia Thunderbolt™ 3 trasferisce i dati a una velocità superiore rispetto alle connessioni USB tradizionali, consentendoti di spostare i file in modo rapido e di collegare facilmente le periferiche, come diversi monitor o schede grafiche esterne.",
                "tr_title": "Tecnologia Thunderbolt™ 3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "DATI SUPER VELOCI",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Tecnologia Thunderbolt™ 3",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connettività",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Una porta e un cavo universali per supportare tutti i tuoi accessori preferiti</li> <li>Ricarica affidabile dei notebook</li> <li>Robusta certificazione per stare tranquilli</li></ul>",
                "tr_title": "Connettività via cavo davvero universale",
                "tr_title1": "CONNETTIVITÀ VIA CAVO DAVVERO UNIVERSALE",
                "tr_noteSection": "<sup>†</sup>In base ai requisiti minimi della specifica",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "COME SI USA LA TECNOLOGIA THUNDERBOLT™&nbsp;4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tecnologia Thunderbolt™ 4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Crea",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Collega un monitor esterno e un dispositivo di storage rapido per visualizzare e modificare foto e video."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dock",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Ottieni una scrivania semplice e ordinata collegando al tuo notebook tutti i tuoi accessori con un unico cavo."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Gioca",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Aggiungi maggiore capacità SSD e HDD con velocità fino a 3.000 MB/s per archiviare librerie di videogiochi e file multimediali."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Massime prestazioni<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4 volte più veloce rispetto allo standard USB* 3.2 <br/>2 volte più veloce rispetto all'interfaccia HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connettività",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Goditi le stesse straordinarie prestazioni della tecnologia Thunderbolt™ 3 con ancora più funzionalità che ti consentono di collegarti e utilizzare facilmente i più recenti dock, monitor, dispositivi di archiviazione rapida o qualsiasi accessorio USB* Thunderbolt™.",
                "tr_title": "Tecnologia Thunderbolt™ 4",
                "tr_pretitle": "CONNETTIVITÀ VIA CAVO DAVVERO UNIVERSALE",
                "tr_noteSection": "<sup>†</sup>In base ai requisiti minimi della specifica",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Un cavo universale Thunderbolt™ 4 può velocizzare e semplificare la scelta dei cavi sostituendo quelli di tipo USB-C*."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "I dock con tecnologia Thunderbolt™ 4 offrono fino a quattro porte Thunderbolt™, dandoti una maggiore flessibilità per collegare tutti i tuoi accessori."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Massime prestazioni<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4 volte più veloce rispetto allo standard USB* 3.2 <br/>2 volte più veloce rispetto all'interfaccia HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connettività",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Una porta e un cavo universali per supportare tutti i tuoi accessori preferiti</li> <li>Ricarica affidabile dei notebook</li> <li>Robusta certificazione per stare tranquilli</li></ul>",
                "tr_title": "Massimizza la tua esperienza con gli accessori Thunderbolt™",
                "tr_noteSection": "<sup>†</sup>In base ai requisiti minimi della specifica",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "COME SI USA LA TECNOLOGIA THUNDERBOLT™&nbsp;4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Crea",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Collega un monitor esterno e un dispositivo di storage rapido per visualizzare e modificare foto e video."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dock",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Ottieni una scrivania semplice e ordinata collegando al tuo notebook tutti i tuoi accessori con un unico cavo."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Gioca",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Aggiungi maggiore capacità SSD e HDD con velocità fino a 3.000 MB/s per archiviare librerie di videogiochi e file multimediali."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Massime prestazioni<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4 volte più veloce rispetto allo standard USB* 3.2 <br/>2 volte più veloce rispetto all'interfaccia HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "I tipi più comuni di Wi‑Fi sono:",
                "tr_title": "COMPRENDERE GLI STANDARD WI‑FI",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 consente velocità superiori anche quando la rete è utilizzata da molti utenti.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Con la più recente innovazione di Intel nel settore Wi-Fi, puoi raggiungere velocità più elevate rispetto al Wi-Fi gigabit, anche con più utenti sullo stesso segnale.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Il Wi‑Fi 6E è il più significativo progresso della tecnologia Wi‑Fi degli ultimi 20 anni e consente nuovi canali ad alta velocità per migliorare le prestazioni e l'affidabilità con meno interferenze."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 consente velocità superiori anche quando la rete è utilizzata da molti utenti.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Tutti i vantaggi del Wi-Fi 6E con il motore di prioritizzazione Intel® Killer™ che sposta la larghezza di banda ai siti web e alle applicazioni che ne hanno più bisogno."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Con meno concorrenza sui tuoi canali ad alta velocità, godrai prestazioni e affidabilità migliorate."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 consente velocità superiori anche quando la rete è utilizzata da molti utenti.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Grazie all'inferenza minore del Wi-Fi 6 e alle prestazioni intelligenti del motore di prioritizzazione Intel® Killer™, le esperienze online saranno più veloci e reattive.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Con meno concorrenza sui tuoi canali ad alta velocità, godrai prestazioni e affidabilità migliorate."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Velocità Wi-Fi su PC superiori a 5 Gbps, con una latenza sempre estremamente ridotta: condividi file nel giro di secondi, anziché minuti."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) è la tecnologia più recente che consente affidabilità, bassa latenza e velocità “estreme” grazie all'ottimizzazione della rete basata sull'intelligenza artificiale."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 consente velocità superiori anche quando la rete è utilizzata da molti utenti.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) raggiunge velocità superiori a 1 Gbps con affidabilità e bassa latenza con l'utilizzo dei canali a 6 GHz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Migliore efficienza, capacità e velocità rispetto al Wi-Fi 5 sulle reti congestionate."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Sperimenta una connettività migliore con il Wi‑Fi [XX], che ti permette di navigare su Internet a velocità più elevate<sup>†</sup> e con maggiore affidabilità.",
                "tr_title": "Ottieni un segnale e una velocità migliori",
                "tr_noteSection": "<sup>†</sup>Il throughput wireless e il campo di copertura effettivi possono variare.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Nell'era dei videogame multiplayer e del gaming online, rimanere connessi è più importante che mai. Oltre alla velocità stessa della connessione, devi poter contare anche su un segnale wireless in grado di mantenere tale velocità anche a distanza.",
                "tr_title": "[XX]",
                "tr_pretitle": "LA CONNESSIONE È UN ASPETTO FONDAMENTALE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Connettiti ai router Wi‑Fi 6 con Intel® Wi‑Fi 6 (Gig+), con supporto del canale a 160 MHz per connessioni più rapide e una maggiore affidabilità per un numero superiore di utenti attivi.",
                "tr_title": "Velocità quasi triplicate con Intel®&nbsp;<span class='no-warp'>Wi&#8209;Fi 6&nbsp;</span>&nbsp;(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Rispetto al Wi‑Fi AC standard. Alcune funzioni sono disponibili solo su SKU selezionate. Per maggiori dettagli, consultare il produttore. Per ulteriori informazioni sulla tecnologia Wi‑Fi, consultare la pagina intel.com/wifi6disclaimers."
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Wi-Fi 6 dual band entry level supporta prestazioni migliorate per le necessità di connettività di base, come l'invio di e&#8209;mail e la navigazione su Internet su reti congestionate.<sup>9</sup>",
                "tr_title": "Wi‑Fi essenziale",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Nell'era dei videogame multiplayer e del gaming online, rimanere connessi è più importante che mai. Oltre alla velocità stessa della connessione, devi poter contare anche su un segnale wireless in grado di mantenere tale velocità anche a distanza.",
                "tr_title": "[XX]",
                "tr_pretitle": "LA CONNESSIONE È UN ASPETTO FONDAMENTALE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Grazie a questi nuovi canali Wi‑Fi, non devi condividere la larghezza di banda con i dispositivi meno recenti del tuo quartiere e puoi goderti uno streaming fluido, una navigazione rapida e la possibilità di lavorare e seguire le lezioni da remoto.",
                "tr_title": "Connettiti senza concorrenza",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Sperimenta una connettività migliore con [XX], che ti permette di navigare su Internet a velocità più elevate<sup>†</sup> e con maggiore affidabilità.",
                "tr_title": "Ottieni un segnale e una velocità migliori",
                "tr_noteSection": "<sup>†</sup>Il throughput wireless e il campo di copertura effettivi possono variare.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Nell'era dei videogame multiplayer e del gaming online, rimanere connessi è più importante che mai. Oltre alla velocità stessa della connessione, devi poter contare anche su un segnale wireless in grado di mantenere tale velocità anche a distanza.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "LA CONNESSIONE È UN ASPETTO FONDAMENTALE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Wi‑Fi integrato ultraveloce",
                "tr_subtitle": "Stacca la spina e resta connesso con il tuo mondo online."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Grazie alle linee ad alta velocità dedicate per minori interferenze e al motore di prioritizzazione Intel® Killer™ che assegna priorità al gaming, ora giocare online è tutta un'altra cosa.",
                "tr_title": "Non hai più rivali",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Grazie alle linee ad alta velocità dedicate per minori interferenze e al motore di prioritizzazione Intel® Killer™ che assegna priorità al gaming, ora giocare online è tutta un'altra cosa.",
                "tr_title": "[XX]",
                "tr_pretitle": "NON HAI PIÙ RIVALI",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Grazie alle linee ad alta velocità dedicate che riducono le interferenze e assegnano priorità al gaming, giocare online è tutta un'altra storia.",
                "tr_title": "[XX]",
                "tr_pretitle": "NON HAI PIÙ RIVALI",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "La tecnologia Intel® Killer™ Wi-Fi accelera automaticamente il traffico di rete per le applicazioni che ne hanno più bisogno, in modo che i video in streaming presentino dettagli nitidi e valori di buffer inferiori.",
                "tr_title": "Un modo più veloce e intelligente per connettersi.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Nell'era dei videogame multiplayer e del gaming online, rimanere connessi è più importante che mai. Oltre alla velocità stessa della connessione, devi poter contare anche su un segnale wireless in grado di mantenere tale velocità anche a distanza.",
                "tr_title": "[XX]",
                "tr_pretitle": "LA CONNESSIONE È UN ASPETTO FONDAMENTALE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Sperimenta una connettività migliore con il Wi-Fi 5, che ti permette di navigare su Internet a velocità più elevate<sup>†</sup> e con maggiore affidabilità.",
                "tr_title": "Ottieni un segnale e una velocità migliori",
                "tr_noteSection": "<sup>†</sup>Il throughput wireless e il campo di copertura effettivi possono variare.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Nell'era dei videogame multiplayer e del gaming online, rimanere connessi è più importante che mai. Oltre alla velocità stessa della connessione, devi poter contare anche su un segnale wireless in grado di mantenere tale velocità anche a distanza.",
                "tr_title": "[XX]",
                "tr_pretitle": "NON HAI PIÙ RIVALI",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Le funzionalità Wi-Fi 7 si baseranno, migliorandole, sulle generazioni Wi-Fi precedenti. Ciò non significherà solo velocità superiori, ma reattività e affidabilità notevolmente migliorate per esperienze consumatore immersive e sofisticate tecnologie future.",
                "tr_title": "Prestazioni delle reti PC simili a quelle cablate",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Le funzionalità Wi-Fi 7 si baseranno, migliorandole, sulle generazioni Wi-Fi precedenti. Ciò non significherà solo velocità superiori, ma reattività e affidabilità notevolmente migliorate per esperienze consumatore immersive e sofisticate tecnologie future.",
                "tr_title": "[XX]",
                "tr_pretitle": "TRASFORMA L'ESPERIENZA UTENTE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Velocità, latenza e affidabilità migliorate grazie all'ottimizzazione della rete basata sull'IA",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Velocità, latenza e affidabilità migliorate grazie all'ottimizzazione della rete basata sull'IA.",
                "tr_title": "[XX]",
                "tr_pretitle": "REATTIVITÀ PARI A QUELLA DEL CABLATO",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Come viene misurata?",
                    "tr_badgeSubTitle": "La maggior parte dei produttori esprime la durata della batteria in ore. Anche se questo calcolo non è accurato al 100%, risulta utile per confrontare la durata della batteria di un PC con quella di un altro."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteria",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PER GIOCARE IN MOVIMENTO",
                "tr_subtitle": "Con la potenza degli attuali notebook per il gaming, non c'è motivo di restare seduti a una scrivania. Da oggi puoi portare con i te i tuoi videogame ovunque vai.",
                "tr_title": "[Dynamic.battery.values.hours] ore di durata della batteria"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Stacca la spina e resta connesso per ore con le tue app, i tuoi giochi e la tua musica.",
                "tr_title": "[XX] ore di streaming con un'unica ricarica<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>In base alla durata della batteria dichiarata dal produttore del PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] ore",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Stacca la spina e resta connesso per ore con le tue app, i tuoi giochi e la tua musica.",
                "tr_title": "[Dynamic.battery.values.hours] ore di alimentazione in movimento<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>In base alla durata della batteria dichiarata dal produttore del PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] ore",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Stacca la spina e resta connesso per ore con le tue app, i tuoi giochi e la tua musica.",
                "tr_title": "[Dynamic.battery.values.hours] ore per una batteria che dura tutto il giorno con un'unica ricarica<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>In base alla durata della batteria dichiarata dal produttore del PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] ore",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteria",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Questo PC ottimizza la durata della batteria per farla durare più a lungo e offre almeno 4 ore di durata della batteria con una carica di 30 minuti.<sup>4</sup>",
                "tr_title": "Resta più a lungo scollegato dalle prese di corrente con [Dynamic.battery.values.hours] ore di durata della batteria<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>In base alla durata della batteria dichiarata dal produttore del PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] ore",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Home",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Perché questo PC",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Visualizza tutte le specifiche",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Da remoto",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobile",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Informazioni legali",
        "tr_paragraph_array": [
          "Il software e i carichi di lavoro usati nei test delle prestazioni potrebbero essere stati ottimizzati a livello prestazionale solo sui microprocessori Intel®. I test delle prestazioni, come SYSmark* e MobileMark*, sono calcolati utilizzando specifici sistemi informatici, componenti, software, operazioni e funzioni. Qualsiasi modifica a uno di questi fattori può determinare risultati diversi. Gli acquirenti dovrebbero consultare altre fonti di informazioni e test prestazionali per valutare&nbsp;appieno i prodotti che intendono acquistare, nonché le loro prestazioni se abbinati ad altri prodotti. Per informazioni più complete sulle prestazioni e&nbsp;i&nbsp;risultati dei benchmark, consultare www.intel.com/benchmarks.",
          "¹I prodotti Intel® Wi-Fi 6 (Gig+) supportano canali facoltativi da 160 MHz, consentendo le massime velocità teoriche possibili (2402 Mbps) per i tipici prodotti Wi-Fi per PC con standard 802.11ax 2x2. I prodotti Premium Intel® Wi-Fi 6 (Gig+) consentono velocità teoriche massime 2-4 volte superiori rispetto ai prodotti Wi-Fi per PC con standard 802.11ax 2x2 (1201 Mbps) o 1x1 (600 Mbps), che supportano solo i requisiti obbligatori dei canali a 80 MHz.",
          "²Raccomandazione fornita solo a scopo informativo. Consultare altre fonti di informazioni prima di prendere una decisione di acquisto.",
          "³Le caratteristiche e i vantaggi delle tecnologie Intel dipendono dalla configurazione di sistema e potrebbero richiedere hardware e software abilitati o l'attivazione di servizi. Le prestazioni variano in base alla configurazione di sistema. Nessun sistema informatico può essere totalmente sicuro. Per ulteriori informazioni, rivolgersi al produttore o al rivenditore del sistema o consultare il sito intel.com.",
          "⁴Per i sistemi dotati di schermi FHD, quando vengono utilizzati per la navigazione Web wireless. Quando spenti, dal livello di spegnimento predefinito dall’OEM.",
          "⁵Rispetto ad altre tecnologie di connessione di I/O dei PC, tra cui eSATA, USB e IEEE 1394* FireWire. Le prestazioni possono variare a seconda delle specifiche configurazioni hardware e software. È necessario utilizzare un dispositivo abilitato per la tecnologia Thunderbolt™.",
          "⁶Quasi 3 volte più veloce: lo standard 802.11ax 2x2 a 160 MHz consente velocità teoriche massime di trasferimento dei dati di 2402 Mbps. ~3 volte più veloce dello standard Wi‑Fi 802.11ac 2x2 a 80 MHz (867 Mbps), come documentato nelle specifiche dello standard wireless IEEE 802.11* e richiede l'uso di router di rete wireless 802.11ax configurati in modo simile.",
          "⁷Riduzione della latenza pari al 75%: il calcolo è basato su dati di simulazioni condotte da Intel (79%) sullo standard 802.11ax con e senza OFDMA utilizzando 9 client. La latenza media senza OFDMA è di 36 ms, mentre con OFDMA si riduce a 7,6 ms. Il miglioramento della latenza richiede che il router 802.11ax (Wi-Fi 6) e tutti i client supportino OFDMA.",
          "⁸In base al confronto delle velocità teoriche massime di trasferimento dei dati nella specifica 802.11, il Wi‑Fi 6/6E (802.11ax) 2x2 a 160 MHz può arrivare a 2402 Mbps, mentre il Wi‑Fi 5 (802.11ac) 2x2 a 80 MHz può arrivare a 867 Mbps. Il dato corrisponde a velocità 2,8 volte superiori.",
          "⁹Connettività Wi‑Fi 6 all'avanguardia: i prodotti Intel® Wi‑Fi 6 (Gig+) supportano canali facoltativi a 160 MHz, consentendo le massime velocità teoriche possibili (2402 Mbps) per i tipici prodotti Wi‑Fi per PC con standard 802.11ax 2x2. I prodotti Premium Intel® Wi‑Fi 6 (Gig+) consentono velocità teoriche massime 2-4 volte superiori rispetto ai prodotti Wi‑Fi per PC con standard 802.11ax 2x2 (1201 Mbps) o 1x1 (600 Mbps), che supportano solo i requisiti obbligatori dei canali a 80 MHz.",
          "Solo alcune SKU, le funzionalità variano in base al design degli OEM. Per maggiori dettagli, rivolgersi all'OEM o al rivenditore. Per ulteriori informazioni sulla tecnologia Wi‑Fi, visitare intel.com/wifi6disclaimers.",
          "¹⁰La tecnologia Thunderbolt™ 4, a 40 Gbps, offre la soluzione via cavo più affidabile, semplice e veloce per qualunque dock, schermo o dispositivo dati rispetto ad altre tecnologie di connessione I/O per PC, tra cui eSATA*, USB* e IEEE 1394* FireWire.",
          "¹¹Richiede un router basato su 802.11ax che supporti OFDMA e più client sulla rete con supporto per AX. Il risultato migliore in ambienti densi è ottenibile dalle funzionalità OFDMA supportate da client e access point 802.11ax. 2 Gbps basati su ipotesi di circa il 70% delle velocità teoriche massime di trasferimento dei dati della specifica IEEE 802.11* di 802.11ax a 160 MHz e 2402 Mbps.",
          "¹²Misurazioni effettuate con carico di lavoro di apertura di un documento con attività in background confrontando un sistema basato su processore Intel® Core™ i7-8565U di ottava generazione (SSD TLC da 512 GB) con un sistema con processore Intel® Core™ i7-8565U di ottava generazione (32 GB di memoria Intel® Optane™ H10 con storage a stato solido da 512 GB).",
          "¹³Intel® Thread Director è integrato nei processori Intel® Core™ di dodicesima generazione e aiuta a supportare i sistemi operativi per indirizzare in maniera più intelligente i carichi di lavoro al core giusto. Gli utenti non devono fare nulla. Per maggiori dettagli, consultare intel.com.",
          "¹⁴Non disponibile su determinati processori Intel® Core™ di dodicesima generazione. L'architettura ibrida ad alte prestazioni combina due nuove microarchitetture di core, i Performance-core (P-core) e gli Efficient-core (E-core), su un singolo die di processore. Determinati processori Intel® Core™ di dodicesima generazione (alcuni processori Intel® Core™ i5 di dodicesima generazione e versioni inferiori) non supportano l'architettura ibrida delle prestazioni, ma solo P-core.",
          "¹⁵ Misurazioni effettuate con carico di lavoro di lancio di videogame confrontando un sistema basato su processore Intel® Core™ i7-8750H di ottava generazione (modulo di memoria Intel® Optane™ da 32 GB + SSD PCIe* Intel® da 256 GB + HDD da 1 TB) con un sistema con processore Intel® Core™ i7-8750H di ottava generazione (SSD PCIe* Intel® da 256 GB + HDD da 1 TB). Carico di lavoro di lancio di videogame: carico di lavoro sviluppato da Intel che misura il tempo trascorso per lanciare Total War*: WARHAMMER* II Build: 5577.0 e visualizzare il menu principale con i video introduttivi disabilitati (lancio). Accelerazione dell'unità dati con memoria Intel® Optane™ - Configurazioni: processore Intel® Core™ i7-8750H, PL1=TDP 45 W, 6C12T, Turbo fino a 4 GHz in un sistema OEM di pre-produzione, Grafica: NVIDIA* GeForce* GTX 1070, Memoria: 2 x 4 GB DDR4, Storage: SSD Intel® serie 760p da 256 GB (PCle*) + HDD da 1 TB + 32 GB di memoria Intel® Optane™, Sistema operativo: Windows* 10 RS3 Build 1709, MCU 0x84; processore Intel® Core™ i7-8750H, PL1=TDP 45 W, 6C12T, Turbo fino a 4 GHz in un sistema OEM di pre-produzione, Grafica: NVIDIA* GeForce* GTX 1070, Memoria: 2 x 4 GB DDR4, Storage: SSD Intel® serie 760p da 256 GB (PCle*) + HDD da 1 TB, Sistema operativo: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁶ Misurazioni effettuate con carico di lavoro di caricamento dei livelli di videogame confrontando un sistema basato su processore Intel® Core™ i7-8750H di ottava generazione (modulo di memoria Intel® Optane™ da 32 GB) con un sistema con processore Intel® Core™ i7-8750H di ottava generazione (solo HDD). Carico di lavoro di caricamento dei livelli di videogame: carico di lavoro sviluppato da Intel che misura il tempo trascorso per passare dal menu principale al completamento del caricamento di un livello (caricamento di un livello) con Total War*: WARHAMMER* II Build: 5577.0. Accelerazione dell'unità dati con memoria Intel® Optane™ - Configurazioni: processore Intel® Core™ i7-8750H, PL1=TDP 45 W, 6C12T, Turbo fino a 4 GHz in un sistema OEM di pre-produzione, Grafica: NVIDIA* GeForce* GTX 1070, Memoria: 2 x 4 GB DDR4, Storage: SSD Intel® serie 760p da 256 GB (PCle*) + HDD da 1 TB + 32 GB di memoria Intel® Optane™, Sistema operativo: Windows* 10 RS3 Build 1709, MCU 0x84; processore Intel® Core™ i7-8750H, PL1=TDP 45 W, 6C12T, Turbo fino a 4 GHz in un sistema OEM di pre-produzione, Grafica: NVIDIA* GeForce* GTX 1070, Memoria: 2 x 4 GB DDR4, Storage: SSD Intel® serie 760p da 256 GB (PCle*) + HDD da 1 TB, Sistema operativo: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁷Su Performance-core. Le prestazioni variano in base all'utilizzo, alla configurazione e ad altri fattori. Maggiori informazioni su www.intel.com/PerformanceIndex.",
          "¹⁸La modifica della frequenza di clock o della tensione può danneggiare o ridurre la vita utile del processore e di altri componenti del sistema nonché comprometterne la stabilità e le prestazioni. Le garanzie sui prodotti potrebbero non essere valide qualora il processore venga utilizzato oltre le specifiche previste. Rivolgersi ai produttori del sistema e dei componenti per ulteriori dettagli.",
          "¹⁹Come misurato dalla combinazione unica delle funzionalità di sicurezza senza rivali della piattaforma Intel vPro® al di sopra e al di sotto del sistema operativo, delle protezioni di app e dati, delle protezioni avanzate dalle minacce a imprese di qualsiasi dimensione e dell'approccio di Intel focalizzato sulla sicurezza nella progettazione, nella realizzazione e nel supporto del prodotto. Tutti i PC aziendali basati sulla piattaforma Intel vPro® sono stati convalidati in base a specifiche rigorose, tra cui funzionalità di sicurezza uniche basate su hardware. Consultare www.intel.com/PerformanceIndex (piattaforme) per maggiori informazioni. Nessun prodotto o componente può essere totalmente sicuro.",
          "²⁰Intel® Connectivity Performance Suite è disponibile solo su sistemi basati su Windows*. Test di Intel Over The Air (OTA) Wi-Fi su una rete congestionata rispetto a prioritizzazione del traffico wireless senza applicazione del software ICPS e cambio del punto d'accesso intelligente. Consultare www.intel.com/PerformanceIndex (connettività) per maggiori informazioni. I risultati possono variare.",
          "²¹Rispetto ai processori di generazione precedente.",
          "²²La grafica Intel® Arc™ è la prima sul mercato a supportare la codifica AV1 (T1 2022). Maggiori informazioni su intel.com/PerformanceIndex.",
          "²³Come misurato dalle funzionalità uniche dei design Intel® Evo™, verificati anche per soddisfare le soglie chiave dell'esperienza utente portatile. Test di febbraio 2022.",
          "²⁴La soluzione Intel® Unison™ è attualmente disponibile solo per i design Intel® Evo™ idonei su PC basati su Windows* e abbinata solo a telefoni basati su Android o iOS; tutti i dispositivi devono eseguire una versione supportata del sistema operativo.",
          "²⁵Le velocità massime della memoria sono associate a configurazioni a 1 DIMM per canale (1DPC). Il carico aggiuntivo di DIMM su ogni canale può influire sulla velocità massima della memoria. Fino a DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 e DDR5-5200 1Rx8, 1Rx16, 2Rx8 su SKU selezionate. La capacità di memoria massima è raggiungibile con le configurazioni 2DPC.",
          "²⁶Rispetto al Wi-Fi standard. Per maggiori dettagli, consultare intel.com/performance-wireless. I risultati possono variare.",
          "²⁷L'architettura ibrida delle prestazioni combina due microarchitetture di core, i Performance-core (P-core) e gli Efficient-core (E-core), in un singolo die di processore introdotto per la prima volta sui processori Intel® Core™ di dodicesima generazione. Alcuni processori Intel® Core™ di dodicesima generazione e di generazione successiva non presentano l'architettura ibrida delle prestazioni, ma solo P-core, e sono dotati di cache con dimensioni analoghe alla generazione precedente. Per maggiori dettagli sulle SKU, consulta il sito ark.intel.com.",
          "²⁸Integrato nell'hardware, l'Intel® Thread Director è disponibile solo per le configurazioni con architettura ibrida delle prestazioni dei processori Intel® Core™ di dodicesima generazione o di generazione successiva; è richiesta l'abilitazione del sistema operativo. Le opzioni e le funzionalità disponibili variano in base al sistema operativo.",
          "²⁹Integrato nell'hardware, l'Intel® Thread Director è disponibile solo per le configurazioni con architettura ibrida delle prestazioni dei processori Intel® Core™ di dodicesima e tredicesima generazione; è richiesta l'attivazione del sistema operativo. Le opzioni e le funzionalità disponibili variano in base al sistema operativo.",
          "³⁰Misurato in base alle prestazioni della piattaforma di convalida di riferimento del processore Intel® Core™ Ultra per sistemi portatili (Serie 2, nome in codice: Lunar Lake) rispetto al processore Intel® Core™ Ultra per sistemi portatili (Serie 1, nome in codice: Meteor Lake) su 3DMark Time Spy. Visitare il sito web all'indirizzo intel.com/PerformanceIndex per ulteriori informazioni. I risultati possono variare.",
          "³¹Sfocatura dello sfondo e monitoraggio del volto disponibili solo su sistemi Windows*.",
          "³²I prodotti Wi-Fi 7 possono accedere a canali da 320 MHz in 6 GHz e a nuove combinazioni di canali da 160 MHz in 5 GHz con nuove funzionalità di Multi-Resource Unit Puncturing.",
          "³³In base alle specifiche dello standard wireless IEEE e alle velocità di trasmissione dati teoriche massime per i dispositivi che supportano due streaming in contemporanea.",
          "³⁴Le simulazioni di Intel Engineering su ambienti di rete congestionati indicano che è possibile ridurre notevolmente la latenza con le nuove funzionalità Wi-Fi 7 Multi-Link Operation.",
          "³⁵Carica minima ottenuta secondo la procedura di spegnimento predefinita dal produttore. I singoli risultati di sistema potrebbero variare. Per maggiori dettagli, consultare intel.com/performance-evo.",
          "³⁶La banda Wi-Fi a 6 GHz non è disponibile per l'utilizzo in tutti i paesi o le regioni.",
          "³⁷Solo Windows*",
          "³⁸Carica ottenuta secondo la procedura di spegnimento predefinita dal produttore. Per informazioni più complete sulle prestazioni e i risultati dei benchmark, consultare intel.com/Evo.",
          "³⁹Si riferisce ai processori Intel® Core™ Ultra per sistemi portatili (Serie 2), basati su caratteristiche di sicurezza uniche a livello hardware, una combinazione senza pari al di sopra e al di sotto delle capacità di sicurezza del sistema operativo, e prestazioni sorprendenti con un consumo energetico inferiore rispetto alla generazione precedente (nel settembre 2024). Visitare il sito web all'indirizzo intel.com/PerformanceIndex per ulteriori informazioni. I risultati possono variare.",
          "⁴⁰Le applicazioni software sono mostrate solo a scopo illustrativo. Le funzionalità IA possono richiedere l'acquisto di ulteriore software, l'abbonamento o l'attivazione da parte di un provider di software o piattaforma oppure possono presentare requisiti di configurazione o compatibilità specifici. Maggiori informazioni su www.intel.com/PerformanceIndex. I risultati possono variare. © Intel Corporation.",
          "⁴¹Le immagini mostrate potrebbero essere state alterate o simulate. Le funzionalità IA possono richiedere l'acquisto di ulteriore software, l'abbonamento o l'attivazione da parte di un provider di software o piattaforma oppure possono presentare requisiti di configurazione o compatibilità specifici. Maggiori informazioni su www.intel.com/AIPC.",
          "⁴²Implementazione graduale in anteprima all'interno dell'ultimo aggiornamento di Windows* 11 in mercati globali selezionati. La tempistica della disponibilità varia in base al dispositivo e al mercato. Per saperne di più clicca qui: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³La soluzione Intel® Unison™ è attualmente disponibile per i dispositivi idonei. Per ulteriori informazioni, visitare il sito Web all'indirizzo www.intel.com/PerformanceIndex.",
          "⁴⁴FPS migliorati da Intel® X<sup>e</sup>SS rispetto agli FPS nativi misurati da Dying Light 2 Stay Human in esecuzione sul processore Intel® Core™ Ultra 7 165H. Per ulteriori informazioni, visitare il sito Web all'indirizzo www.intel.com/PerformanceIndex. I risultati possono variare.",
          "⁴⁵Misurato in base al minor consumo energetico del SoC sulla piattaforma di convalida di riferimento del processore Intel® Core™ Ultra per sistemi portatili (Serie 2, nome in codice: Lunar Lake) rispetto alla piattaforma di convalida di riferimento del processore Intel® Core™ Ultra per sistemi portatili (Serie 1, nome in codice: Meteor Lake) su YouTube* 4K 30 FPS AV1. Visitare il sito web all'indirizzo intel.com/PerformanceIndex per ulteriori informazioni. I risultati possono variare.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel non si assume responsabilità per prezzi non corretti.",
          "* Altri marchi e altre denominazioni potrebbero essere rivendicati da terzi.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES E I DESIGN E I MARCHI COMPOSITI CORRELATI SONO MARCHI DI INFLEXION STUDIOS INC. TUTTI I DIRITTI SONO RISERVATI.",
          "Le tecnologie Intel potrebbero richiedere hardware abilitato, software specifico o l'attivazione di servizi.",
          "Tutti i risultati e i dati di NERO* sono forniti per comodità e unicamente a scopo informativo. Intel Corporation non si assume alcuna responsabilità in merito, ad esempio, all'accuratezza dell'app NERO*, dei risultati e dei punteggi relativi alle prestazioni o delle potenziali variazioni di altre versioni scaricabili dell'app disponibili su www.nero.com o su altri siti Web di terze parti. I punteggi dei benchmark per PC NERO* sono misurati utilizzando specifici sistemi informatici, componenti, software, operazioni e funzioni. Qualsiasi modifica a uno di questi fattori può determinare risultati diversi. Gli acquirenti sono tenuti a consultare altre fonti di informazioni e test prestazionali per valutare appieno i prodotti che intendono acquistare, nonché le loro prestazioni se abbinati ad altri prodotti.",
          "Tutte le versioni della piattaforma Intel vPro® richiedono un processore Intel® Core™ idoneo, un sistema operativo supportato, un chip LAN e/o WLAN Intel®, miglioramenti del firmware e altri componenti hardware e software necessari per assicurare i casi d'uso di gestibilità, le caratteristiche di sicurezza, le prestazioni del sistema e la stabilità che caratterizzano la piattaforma.",
          "Le prestazioni variano in base all'utilizzo, alla configurazione e ad altri fattori. Maggiori informazioni su www.intel.com/PerformanceIndex.",
          "Intel non controlla né verifica i dati di terze parti. È necessario consultare altre fonti per valutare la precisione.",
          "© Intel Corporation. Intel, il logo Intel e altri marchi Intel sono marchi commerciali di Intel Corporation o di società controllate da Intel. *Altri marchi e altre denominazioni potrebbero essere rivendicati da terzi.",
          "Nessun prodotto o componente può essere totalmente sicuro.",
          "Costi e risultati possono variare.",
          "Intel, il logo Intel, Intel Core, Intel Optane, Intel Iris, Thunderbolt e il logo Thunderbolt sono marchi di Intel Corporation o di società controllate da Intel. Altri marchi e altre denominazioni potrebbero essere rivendicati da terzi.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Home",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Scansiona per confrontare",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Popolari",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/evo/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/video_link.mp4",
      "iconURL": "",
      "videoName": "video_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ Performance to Power Their World (16_9).mp4",
      "iconURL": "",
      "videoName": "Video_ Performance to Power Their World (16_9)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "TI DIAMO IL BENVENUTO",
    "tr_welcomePagetrSubtitle": "Controlla questo PC da remoto con il tuo smartphone",
    "tr_welcomePagembSubtitle": "Ti diamo il benvenuto! Scopri le specifiche di questo PC sul tuo smartphone",
    "tr_welcomePageBtnText": "Inizia qui",
    "tr_welcomePageText": "Scopri e confronta i dettagli di questo PC sul tuo smartphone",
    "tr_scrollText": "Esplora il dispositivo che hai davanti",
    "tr_toolTipTextc1": "Utilizza questi pulsanti per consultare le diverse pagine sul dispositivo di fronte a te.",
    "tr_toolTipTextc2": "Seleziona le schede di seguito per consultare una pagina ricca di dettagli sulle specifiche di ciascun dispositivo.",
    "tr_toolTipTextc3": "Utilizza questi pulsanti per spostarti in alto e in basso sullo schermo davanti a te.",
    "tr_toolTipTextc4": "Disattiva la modalità da remoto per visualizzare le specifiche del dispositivo sul tuo smartphone.",
    "tr_toolTipTextmb1": "Seleziona le schede per visualizzare le pagine di contenuti dettagliati.",
    "tr_toolTipTextmb2": "Seleziona per visualizzare un riepilogo di tutte le specifiche",
    "tr_toolTipTextmb3": "Seleziona il pulsante per scansionare il codice QR e confrontare fino a 4 dispositivi",
    "tr_nextButton": "Avanti",
    "tr_prevButton": "Indietro",
    "tr_gotItButton": "OK",
    "tr_skipButton": "Ignora",
    "tr_placeholderText": "*segnaposto",
    "tr_processorHeading": "Processore",
    "tr_processorSubheading": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;decima generazione",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "[XX] GB di memoria Intel® Optane™ + SSD da [YY] GB",
    "tr_coachmarkProcessor": "Processore XX",
    "tr_coachmarkGraphics": "Grafica [XX]",
    "tr_coachmarkMemory": "Valore spec.",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999,95 $",
    "tr_compare": "Confronta",
    "tr_viewallspecs": "VISUALIZZA TUTTE LE SPECIFICHE",
    "tr_operatingSystem": "OS",
    "tr_AmdProcessorNonIntel": "Processore AMD*",
    "tr_QualcommProcessorNonIntel": "Processore Qualcomm*",
    "tr_GBText": "GB",
    "tr_remoteText": "Specifiche visualizzate sulla schermata del notebook",
    "tr_scanErrorText": "È possibile scansionare solo fino a 3 dispositivi alla volta. Elimina uno o più dispositivi.",
    "tr_scanErrorText2": "Hai già eseguito la scansione di questo dispositivo, scansionane uno differente.",
    "tr_scanMsg": "Scansiona per aggiungere un dispositivo",
    "tr_backToScan": "Torna al confronto",
    "tr_scanToCompare": "Scansiona per confrontare",
    "tr_compareDevice": "Confronta i dispositivi",
    "tr_processorTitle": "PROCESSORE",
    "tr_graphicTitle": "GRAFICA",
    "tr_storageTitle": "STORAGE",
    "tr_displayTitle": "SCHERMO",
    "tr_batteryTitle": "BATTERIA",
    "tr_memoryTitle": "MEMORIA",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "CONNETTIVITÀ",
    "tr_priceTitle": "PREZZO",
    "tr_operatingSystemTitle": "SISTEMA OPERATIVO",
    "tr_batteryHoursText": "[XX] ore",
    "tr_hrsText": "ore",
    "tr_touchscreeenText": "Schermo touch da [screenDiagonal]”",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, schermo touch",
    "tr_FHDTouchScreenText": "FHD, schermo touch",
    "tr_4KTouchscreenText": "4K, schermo touch",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Salva",
    "tr_device_comparison": "Documento di confronto dei dispositivi [dd]-[mm]-[yyyy]",
    "tr_Save_As_JPEG": "Salva come JPEG",
    "tr_Save_As_PDF": "Salva come PDF",
    "tr_Downloading_PDF": "Download del PDF in corso",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Aperto",
    "tr_logo": "logo",
    "tr_laptop_name": "Nome del notebook",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Memoria Intel® Optane™",
    "tr_inchUnit": "”",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (Gig+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "MEMORIA INTEL® OPTANE™",
    "tr_Thunderbolt3": "Tecnologia Thunderbolt™ 3",
    "tr_Thunderbolt4": "Tecnologia Thunderbolt™ 4",
    "tr_processorGraphicsCaps": "GRAFICA DEL PROCESSORE",
    "tr_processorGraphicsSmall": "Grafica del processore",
    "tr_graphicsCardCaps": "GRAFICA DEDICATA",
    "tr_graphicsCardSmall": "Grafica dedicata",
    "tr_processorTitleCamel": "Processore",
    "tr_graphicTitleCamel": "Grafica",
    "tr_storageTitleCamel": "Storage",
    "tr_displayTitleCamel": "Schermo",
    "tr_batteryTitleCamel": "Batteria",
    "tr_memoryTitleCamel": "Memoria",
    "tr_connectivityTitleCamel": "Connettività",
    "tr_priceTitleCamel": "Prezzo",
    "tr_operatingSystemTitleCamel": "Sistema operativo",
    "tr_viewallspecsCamel": "Visualizza tutte le specifiche",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenResolutionType] da [screenDiagonal]”",
    "tr_displayTextResolutionTouchscreen": "Schermo touch [screenResolutionType] da&nbsp;[screenDiagonal]”",
    "tr_OS": "OS",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Specifiche dei PC",
    "tr_explorePC_text": "Scopri tutto su questo PC",
    "tr_mtlPC_text": "Scopri cosa significa veramente “progettati per tutte le tue attività”",
    "tr_optaneMemory": "Memoria Intel®&nbsp;Optane™",
    "tr_displayText_Display": "SCHERMO DA [screenDiagonal]”",
    "tr_displayTextResolution_Display": "SCHERMO [screenResolutionType] DA [screenDiagonal]”",
    "tr_displayTextResolutionTouchscreen_Display": "SCHERMO TOUCHSCREEN [screenResolutionType] DA [screenDiagonal]”",
    "tr_displayTextTouchscreeenText": "SCHERMO TOUCHSCREEN DA [screenDiagonal]”",
    "tr_learnMoreLabel": "Per saperne di più",
    "tr_batteryHoursTextUpperCase": "[XX] ORE",
    "tr_processorGraphicsIrisXe": "PROCESSORE INTEL® CORE™ [cpu_fam] DI UNDICESIMA GENERAZIONE E GRAFICA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "PROCESSORE INTEL® CORE™ [cpu_fam] DI UNDICESIMA GENERAZIONE E GRAFICA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_Thunderbolt3UpperCase": "TECNOLOGIA THUNDERBOLT™&nbsp;3",
    "tr_Thunderbolt4UpperCase": "TECNOLOGIA THUNDERBOLT™&nbsp;4",
    "tr_processorWithArcGraphics": "PROCESSORE INTEL® CORE™ [cpu_fam] DI DODICESIMA GENERAZIONE E GRAFICA INTEL® ARC™",
    "tr_processorGraphicsIrisXe12Gen": "PROCESSORE INTEL® CORE™ [cpu_fam] DI DODICESIMA GENERAZIONE E GRAFICA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "PROCESSORE INTEL® CORE™ [cpu_fam] DI DODICESIMA GENERAZIONE E GRAFICA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "PROCESSORE INTEL® CORE™ [cpu_fam] DI TREDICESIMA GENERAZIONE E GRAFICA INTEL® ARC™",
    "tr_processorGraphicsIrisXe13Gen": "PROCESSORE INTEL® CORE™ [cpu_fam] DI TREDICESIMA GENERAZIONE E GRAFICA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "PROCESSORE INTEL® CORE™ [cpu_fam] DI TREDICESIMA GENERAZIONE E GRAFICA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "GRAFICA INTEL® ARC™ A350M",
      "tr_A370M": "GRAFICA INTEL® ARC™ A370M",
      "tr_A730M": "GRAFICA INTEL® ARC™ A730M",
      "tr_A770M": "GRAFICA INTEL® ARC™ A770M",
      "tr_A310": "GRAFICA INTEL® ARC™ A310",
      "tr_A380": "GRAFICA INTEL® ARC™ A380",
      "tr_A580": "GRAFICA INTEL® ARC™ A580",
      "tr_A750": "GRAFICA INTEL® ARC™ A750",
      "tr_A770": "GRAFICA INTEL® ARC™ A770"
    },
    "tr_memoryValue": "[XX] GB di RAM",
    "tr_memoryWithOptaneDesc": "[Value1]&nbsp;GB di memoria = [Value2]&nbsp;GB di memoria Intel®&nbsp;Optane™ + [Value3]&nbsp;GB di RAM",
    "tr_textGBGB": "SSD da [convertedSSD] GB + HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;GB",
    "tr_textGBTB": "SSD da [convertedSSD] GB + HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;TB",
    "tr_textTBGB": "SSD da [convertedSSD] TB + HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;GB",
    "tr_textTBTB": "SSD da [convertedSSD] TB + HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;TB",
    "tr_textGBSSD": "SSD&nbsp;da&nbsp;[convertedSSD]&nbsp;GB",
    "tr_textTBSSD": "SSD&nbsp;da&nbsp;[convertedSSD]&nbsp;TB",
    "tr_textGBHDD": "HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;GB",
    "tr_textTBHDD": "HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;TB",
    "tr_textStorageWithOptaneGB": "[XX]&nbsp;GB di memoria Intel®&nbsp;Optane™&nbsp;+ SSD&nbsp;da&nbsp;[convertedSSD]&nbsp;GB",
    "tr_textStorageWithOptaneTB": "Memoria Intel®&nbsp;Optane™ da&nbsp;[XX]&nbsp;GB&nbsp;+ SSD&nbsp;da&nbsp;[convertedSSD]&nbsp;TB",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home in modalità S",
      "tr_OS21": "Windows* 10 Pro in modalità S",
      "tr_OS22": "Windows* 10 Enterprise in modalità S",
      "tr_OS23": "Windows* 11 Home in modalità S",
      "tr_OS24": "Windows* 11 Pro in modalità S",
      "tr_OS25": "Windows* 11 Enterprise in modalità S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Grafica Intel® Arc™ A310",
      "tr_GR387": "Grafica Intel® Arc™ A350M",
      "tr_GR388": "Grafica Intel® Arc™ A370M",
      "tr_GR389": "Grafica Intel® Arc™ A380",
      "tr_GR390": "Grafica Intel® Arc™ A530M",
      "tr_GR391": "Grafica Intel® Arc™ A550M",
      "tr_GR392": "Grafica Intel® Arc™ A570M",
      "tr_GR393": "Grafica Intel® Arc™ A580",
      "tr_GR394": "Grafica Intel® Arc™ A730M",
      "tr_GR395": "Grafica Intel® Arc™ A750",
      "tr_GR396": "Grafica Intel® Arc™ A770",
      "tr_GR397": "Grafica Intel®&nbsp;Arc™ A770M",
      "tr_GR398": "Grafica Intel® Arc™",
      "tr_GR399": "Grafica Intel® Arc™ Pro A30M",
      "tr_GR400": "Grafica Intel® Arc™ Pro A40/A50",
      "tr_GR401": "Grafica Intel® Arc™ Pro A60",
      "tr_GR402": "Grafica Intel® Arc™ Pro A60M",
      "tr_GR403": "Grafica Intel®",
      "tr_GR404": "Grafica HD Intel® 2000",
      "tr_GR405": "Grafica HD Intel® 2500",
      "tr_GR406": "Grafica HD Intel® 3000",
      "tr_GR407": "Grafica HD Intel® 400",
      "tr_GR408": "Grafica HD Intel® 4000",
      "tr_GR409": "Grafica HD Intel® 405",
      "tr_GR410": "Grafica HD Intel® 4200",
      "tr_GR411": "Grafica HD Intel® 4400",
      "tr_GR412": "Grafica HD Intel® 4600",
      "tr_GR413": "Grafica HD Intel® 500",
      "tr_GR414": "Grafica HD Intel® 5000",
      "tr_GR415": "Grafica HD Intel® 505",
      "tr_GR416": "Grafica HD Intel® 510",
      "tr_GR417": "Grafica HD Intel® 515",
      "tr_GR418": "Grafica HD Intel® 520",
      "tr_GR419": "Grafica HD Intel® 530",
      "tr_GR420": "Grafica HD Intel® 5300",
      "tr_GR421": "Grafica HD Intel® 5500",
      "tr_GR422": "Grafica HD Intel® 5600",
      "tr_GR423": "Grafica HD Intel® 6000",
      "tr_GR424": "Grafica HD Intel® 610",
      "tr_GR425": "Grafica HD Intel® 615",
      "tr_GR426": "Grafica HD Intel® 620",
      "tr_GR427": "Grafica HD Intel®&nbsp;630",
      "tr_GR428": "Grafica HD Intel®",
      "tr_GR429": "Grafica Intel® Iris® 5100",
      "tr_GR430": "Grafica Intel® Iris® 540",
      "tr_GR431": "Grafica Intel® Iris® 550",
      "tr_GR432": "Grafica Intel® Iris® 6100",
      "tr_GR433": "Grafica Intel® Iris® Plus 640",
      "tr_GR434": "Grafica Intel® Iris® Plus 645",
      "tr_GR435": "Grafica Intel® Iris® Plus 650",
      "tr_GR436": "Grafica Intel® Iris® Plus 655",
      "tr_GR437": "Grafica Intel® Iris® Plus",
      "tr_GR438": "Grafica Intel® Iris® Pro 5200",
      "tr_GR439": "Grafica Intel® Iris® Pro 580",
      "tr_GR440": "Grafica Intel® Iris® Pro 6200",
      "tr_GR441": "Grafica Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "Grafica Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "Grafica Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "Grafica Intel® integrata",
      "tr_GR445": "Grafica UHD Intel® 600",
      "tr_GR446": "Grafica UHD Intel® 605",
      "tr_GR447": "Grafica UHD Intel® 610",
      "tr_GR448": "Grafica UHD Intel® 615",
      "tr_GR449": "Grafica UHD Intel® 617",
      "tr_GR450": "Grafica UHD Intel® 620",
      "tr_GR451": "Grafica UHD Intel® 630",
      "tr_GR452": "Grafica UHD Intel® 710",
      "tr_GR453": "Grafica UHD Intel® 730",
      "tr_GR454": "Grafica UHD Intel® 770",
      "tr_GR455": "Grafica UHD Intel®",
      "tr_GR456": "Grafica UHD Intel®",
      "tr_GR457": "Grafica UHD Intel®",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 con design Max-Q",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti con design Max-Q",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 con design Max-Q",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 con design Max-Q",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 con design Max-Q",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 con design Max-Q",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti con design Max-Q",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti con design Max-Q",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 con design Max-Q",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 con design Max-Q",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super con design Max-Q",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 con design Max-Q",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super con design Max-Q",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 con design Max-Q",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 con design Max-Q",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 con design Max-Q",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 con design Max-Q",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 con design Max-Q",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 con design Max-Q",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 con design Max-Q",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 con design Max-Q",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 con design Max-Q",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 con design Max-Q",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "Grafica Intel® Arc™",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Acquista ora",
    "tr_textTryagain": "Riprova",
    "tr_textBuynowErr": "A causa di un'interruzione della connessione, l'opzione Acquista ora non è disponibile",
    "tr_chatbotPlaceholder_text": "Chiedimi qualsiasi cosa sul dispositivo",
    "tr_deviceSpecs_text": "Specifiche del dispositivo"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 con Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 con Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Preparati per un'esperienza di gioco più potente con Intel®",
                "tr_subtitle": "Dai vita a Marvel's Avengers* grazie alle ottimizzazioni di gioco per PC, con tecnologia Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Vivi l'esperienza di Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Dove i migliori gamer del mondo si incontrano per gareggiare",
                "tr_subtitle": "Intel® Extreme Masters, la serie di eventi che dura da più tempo nella storia dei videogame, definisce gli standard nel mondo degli eSport.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Gioca da professionista",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Con Intel si diventa campioni.",
                "tr_subtitle": "Dopo aver vinto il campionato di Rainbow Six Siege* della North American League nel 2021, i Soniqs riflettono sul proprio passato nel mondo dei videogiochi, sul futuro degli eSport e sulla loro collaborazione con Intel.",
                "tr_pretitle": "Con i Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Predisposto per la realtà virtuale",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Dove la realtà virtuale prende vita",
                "tr_subtitle": "Le esperienze di realtà virtuale più strabilianti, realistiche e immersive sono possibili su un computer. Con i più recenti processori Intel® Core™ puoi diventare chiunque, andare ovunque e sperimentare il gaming in una nuova dimensione.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Gioca a più di 100 giochi da console di grande qualità come Sea of Thieves* e Psychonauts* 2 sul tuo PC Windows*, telefono cellulare e tablet con Xbox* Game Pass Ultimate e un controller compatibile.",
                "titleArray": [
                  {
                    "tr_title": "Gioca con gli amici su vari dispositivi",
                    "tr_subtitle": "Tuffati nel cuore di Xbox* con una community di milioni di giocatori pronti a giocare insieme a te. Connettiti e gioca da una libreria condivisa di titoli con altri gamer, che si trovino dall'altra parte del mondo o siano seduti accanto a te."
                  },
                  {
                    "tr_title": "Gaming su PC Windows*",
                    "tr_subtitle": "Gioca a un catalogo di giochi per console dal cloud utilizzando l'app Xbox* per Windows* e un controller compatibile."
                  },
                  {
                    "tr_title": "Gioca quando vuoi",
                    "tr_subtitle": "Divertiti con i grandi giochi Xbox* ovunque tu sia. Gioca a più di 100 titoli sul tuo PC, telefono cellulare e tablet, che tu sia a casa e abbia la TV occupata, in un bar o in qualsiasi altro luogo con una connessione Internet ad alta velocità."
                  },
                  {
                    "tr_title": "Divertimento senza controller",
                    "tr_subtitle": "Il gaming con controllo touch di Xbox* consente nuovi modi di giocare che offrono un'esperienza familiare e supportano un livello di gioco simile a un controller fisico."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "In primo piano",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Oltre 100 esperienze IA",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Controlla di più, tocca meno",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Rimani nell'inquadratura, in qualsiasi ambiente",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creatività",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Correggi l'illuminazione come un professionista",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Aggiungi effetti straordinari con facilità",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Remixa le canzoni con il potere dell'IA",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produttività",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Aumenta la tua produttività e la tua privacy",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Trascorri più tempo lontano dalla presa",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Il tuo mondo. Sui tuoi dispositivi.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Intrattenimento",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Streaming fluido, anche senza collegamento all'alimentazione",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Gaming veloce e immersivo",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "In primo piano",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Oltre 100 esperienze IA",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Rendi i tuoi brani preferiti davvero tuoi",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Scopri la tua visione in tempo reale",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creatività",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "La via più rapida verso il montaggio finale",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Rendi ogni foto perfetta",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produttività",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Aumenta la tua produttività e la tua privacy",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Più efficiente. Più libertà.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Il tuo mondo. Sui tuoi dispositivi.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Intrattenimento",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Streaming HD fluido, anche senza collegamento all'alimentazione",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Gaming immersivo, in più luoghi",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Più creatività",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Editing di foto più facile",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Crea foto da incorniciare in pochi secondi",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Tu digiti. L'IA esegue.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Prestazioni più elevate",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Il segreto per un gioco più fluido",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Trascorri più tempo lontano dalla presa",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra immersivi",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Più produttività",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "L'IA al tuo fianco ogni giorno",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Videochiamata perfetta",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Il tuo mondo. Sui tuoi dispositivi.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Più sicurezza",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Protezioni integrate per mantenere i dati più sicuri e privati",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Più creatività",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Editing di foto più facile",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "IA. La scelta ideale per il tuo montaggio finale.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Trascrivi velocemente le parole della tua canzone preferita",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Risparmia ore di editing con l'IA",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Prestazioni più elevate",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Il segreto per un gioco più fluido",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Trascorri più tempo lontano dalla presa",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra immersivi",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Più produttività",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "L'IA al tuo fianco ogni giorno",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Videochiamata perfetta",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Il tuo mondo. Sui tuoi dispositivi.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Più sicurezza",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Protezioni integrate per mantenere i dati più sicuri e privati",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Tutto il tuo intrattenimento. Un unico PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Per un PC che funge anche da centro di intrattenimento, ti serve una quantità di storage adeguata per tutti i tuoi brani, video e videogame preferiti e la giusta combinazione di processore e schermo per una grafica immersiva. Questo PC risponde a tutte e tre le esigenze.",
                "titleArray": [
                  {
                    "tr_title": "Videografica di ultima generazione",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crea la tua raccolta digitale",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Una finestra sull'intrattenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Esperienze di gaming e streaming fluide",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crea la tua raccolta digitale",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Una finestra sull'intrattenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Aumenta la tua produttività. Ovunque.",
              "tr_tileTitle1": "Fai di più. A tutta velocità.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Questo PC offre l’equilibrio perfetto tra potenza e portabilità. Oltre a un processore progettato per design sottili e leggeri, questo PC offre anche il tipo di Wi&#8209;Fi per mantenerti connesso e una durata della batteria tale da liberarti dalle prese elettriche.",
                "tr_subtitle1": "Questo PC ha un equilibro perfetto di potenza e prestazioni per aiutarti a fare di più in meno tempo. La combinazione di un processore potente accelerato da un'ampia memoria e Wi-Fi super veloce ottimizza le tue attività quotidiane.",
                "tr_subtitle2": "Questo PC offre l'equilibrio perfetto tra potenza e portabilità. Oltre a un processore progettato per design sottili e leggeri, questo PC offre anche il tipo di Wi‑Fi per mantenerti connesso e la memoria appropriata per il multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Progettati per fare di più",
                    "tr_title1": "Prestazioni intelligenti che&nbsp;contano",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Le migliori connessioni della categoria",
                    "tr_title1": "Connettiti a quello che conta per te",
                    "tr_title2": "Un grande progresso nella tecnologia Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Senza cavi, più a lungo",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Gestisci i programmi da professionista",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "La libertà di creare",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "La creazione di contenuti è una delle attività più impegnative per il PC. Fortunatamente, questi componenti offrono la potenza necessaria per semplificare il tuo processo creativo.",
                "titleArray": [
                  {
                    "tr_title": "Creazione di contenuti più intelligente",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Dai vita alle tue immagini",
                    "tr_title1": "Creiamo",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memoria per il multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Guarda all’interno di&nbsp;questo&nbsp;PC",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Questi sono i tre componenti principali da tenere in considerazione per la scelta di un nuovo PC. Decidendo come userai il tuo nuovo PC, potrai capire quali specifiche scegliere per questi tre componenti essenziali. Sono:",
                "titleArray": [
                  {
                    "tr_title": "Prestazioni ideali per il gaming e lo streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Storage",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memoria",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Tutto il tuo intrattenimento. Un unico PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Per un PC che funge anche da centro di intrattenimento, ti serve una quantità di storage adeguata per tutti i tuoi brani, video e videogame preferiti e la giusta combinazione di processore e schermo per una grafica immersiva. Questo PC risponde a tutte e tre le esigenze.",
                "titleArray": [
                  {
                    "tr_title": "Videografica di ultima generazione",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crea la tua raccolta digitale",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Una finestra sull'intrattenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Esperienze di gaming e streaming fluide",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crea la tua raccolta digitale",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Una finestra sull'intrattenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Memoria",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Schermo",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Multitasking ad alta velocità",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING AD ALTA VELOCITÀ",
                "tr_subtitle": "Fai di più senza rallentare",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per il multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può destreggiarsi tra diversi programmi alla volta."
                  },
                  {
                    "tr_title": "Wi‑Fi veloce e affidabile."
                  },
                  {
                    "tr_title": "Una memoria che passa in modo fluido tra le applicazioni."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochat",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHAT",
                "tr_subtitle": "Connessioni cristalline",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per le videochat:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può registrare e condividere immagini contemporaneamente."
                  },
                  {
                    "tr_title": "Wi‑Fi veloce e affidabile."
                  },
                  {
                    "tr_title": "Una webcam che ti farà apparire in maniera cristallina."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Predisposto per la realtà virtuale",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Dove la realtà virtuale prende vita",
                "tr_subtitle": "Cosa ci vuole per creare un mondo completamente virtuale? In un PC, serve il coordinamento tra processore, scheda grafica e memoria.",
                "titleArray": [
                  {
                    "tr_title": "Dare vita a mondi virtuali",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La realtà si trova nei dettagli",
                    "tr_title1": "Immergiti",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Editing di video",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDITING DI VIDEO",
                "tr_subtitle": "Realizza video magici",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per l'editing di video:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può gestire file di grandi dimensioni."
                  },
                  {
                    "tr_title": "Una memoria che rende il software di editing veloce e reattivo."
                  },
                  {
                    "tr_title": "Una grafica che diminuisce i tempi di rendering."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Multitasking ad alta velocità",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING AD ALTA VELOCITÀ",
                "tr_subtitle": "Fai di più senza rallentare",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per il multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può destreggiarsi tra diversi programmi alla volta."
                  },
                  {
                    "tr_title": "Wi‑Fi veloce e affidabile."
                  },
                  {
                    "tr_title": "Una memoria che passa in modo fluido tra le applicazioni."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Predisposto per la realtà virtuale",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Dove la realtà virtuale prende vita",
                "tr_subtitle": "Cosa ci vuole per creare un mondo completamente virtuale? In un PC, serve il coordinamento tra processore, scheda grafica e memoria.",
                "titleArray": [
                  {
                    "tr_title": "Dare vita a mondi virtuali",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La realtà si trova nei dettagli",
                    "tr_title1": "Immergiti",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Modellazione 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODELLAZIONE 3D",
                "tr_subtitle": "Creazioni di livello superiore",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per la modellazione 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può gestire software creativi avanzati."
                  },
                  {
                    "tr_title": "Una memoria che può caricare rapidamente file di grandi dimensioni."
                  },
                  {
                    "tr_title": "Una grafica per la creazione e l'editing potenti di immagini."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Collaborazione in tempo reale",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLLABORAZIONE IN TEMPO REALE",
                "tr_subtitle": "Lavora da qualsiasi luogo",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per la collaborazione online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Un processore per i software per il lavoro."
                  },
                  {
                    "tr_title": "Wi‑Fi su cui puoi contare."
                  },
                  {
                    "tr_title": "Una webcam che registra in HD brillante."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Predisposto per la realtà virtuale",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Dove la realtà virtuale prende vita",
                "tr_subtitle": "Cosa ci vuole per creare un mondo completamente virtuale? In un PC, serve il coordinamento tra processore, scheda grafica e memoria.",
                "titleArray": [
                  {
                    "tr_title": "Dare vita a mondi virtuali",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La realtà si trova nei dettagli",
                    "tr_title1": "Immergiti",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Notebook Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "È impossibile che notebook sottili e leggeri come questi siano così potenti",
                "tr_subtitle": "Progettati per prestazioni in movimento, i notebook Intel® Evo™ offrono tutte le caratteristiche che desideri in termini di velocità, grafica e durata della batteria in un formato sorprendentemente sottile e leggero.",
                "titleArray": [
                  {
                    "tr_title": "I notebook sono sempre più importanti, per questo ne abbiamo aumentato le potenzialità.",
                    "tr_subtitle": "Utilizza il touchscreen per esplorare, ruotare e ingrandire."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Riavvio immediato",
                    "tr_subtitle": "Grazie al riavvio immediato, il tuo PC può passare dalla sospensione alla piena operatività in meno di&nbsp;un&nbsp;secondo."
                  },
                  {
                    "tr_title": "Visualizzazione immersiva",
                    "tr_subtitle": "Un fantastico schermo dà vita alle immagini&nbsp;con risoluzione nitida e&nbsp;colori vividi."
                  },
                  {
                    "tr_title": "Completa velocemente le tue attività",
                    "tr_subtitle": "Goditi le prestazioni estremamente reattive dei processori Intel®&nbsp;Core™ di&nbsp;undicesima generazione."
                  },
                  {
                    "tr_title": "Libero dai fili, più a lungo",
                    "tr_subtitle": "Mantieni il tuo ritmo con un PC che ottimizza&nbsp;la durata della batteria e&nbsp;si&nbsp;ricarica in un baleno."
                  },
                  {
                    "tr_title": "Connettività via cavo universale",
                    "tr_subtitle": "Rende facile, rapido e sicuro il collegamento di altri dispositivi al tuo PC con la tecnologia Thunderbolt™&nbsp;4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Connessioni veloci e affidabili",
                    "tr_subtitle": "Videochatta o condividi i file con una connessione su cui puoi contare utilizzando Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Notebook Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tutto ciò di cui hai bisogno (e molto altro) in un notebook sottile e leggero.",
                "tr_subtitle": "I notebook Intel® Evo™ sono progettati per prestazioni in movimento e per offrirti la migliore esperienza possibile<sup>2</sup>, qualsiasi attività tu stia svolgendo.",
                "titleArray": [
                  {
                    "tr_title": "I notebook sono sempre più importanti, per questo ne abbiamo aumentato le potenzialità.",
                    "tr_subtitle": "Utilizza il touchscreen per esplorare, ruotare e ingrandire."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Riavvio immediato",
                    "tr_subtitle": "Grazie al riavvio immediato, il tuo PC può passare dalla sospensione alla piena operatività in meno di&nbsp;un&nbsp;secondo."
                  },
                  {
                    "tr_title": "Visualizzazione immersiva",
                    "tr_subtitle": "Un fantastico schermo dà vita alle immagini&nbsp;con risoluzione nitida e&nbsp;colori vividi."
                  },
                  {
                    "tr_title": "Prestazioni rivoluzionarie",
                    "tr_subtitle": "Ottieni potenza aggiuntiva dove è maggiormente necessaria con le prestazioni intelligenti dei processori Intel® Core™ di dodicesima generazione."
                  },
                  {
                    "tr_title": "Libero dai fili, più a lungo",
                    "tr_subtitle": "Mantieni il tuo ritmo con un PC che ottimizza&nbsp;la durata della batteria e&nbsp;si&nbsp;ricarica in un baleno."
                  },
                  {
                    "tr_title": "Connettività via cavo universale",
                    "tr_subtitle": "Rende facile, rapido e sicuro il collegamento di altri dispositivi al tuo PC con la tecnologia Thunderbolt™&nbsp;4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Connessioni veloci e affidabili",
                    "tr_subtitle": "Videochatta o condividi i file con una connessione su cui puoi contare utilizzando Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Notebook Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "È impossibile che notebook sottili e leggeri come questi siano così potenti",
                "tr_subtitle": "Progettati per prestazioni in movimento, i notebook Intel® Evo™ offrono tutte le caratteristiche che desideri in termini di velocità, grafica e durata della batteria in un formato sorprendentemente sottile e leggero.",
                "titleArray": [
                  {
                    "tr_title": "I notebook sono sempre più importanti, per questo ne abbiamo aumentato le potenzialità.",
                    "tr_subtitle": "Utilizza il touchscreen per esplorare, ruotare e ingrandire."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Riavvio immediato",
                    "tr_subtitle": "Grazie al riavvio immediato, il tuo PC può passare dalla sospensione alla piena operatività in meno di&nbsp;un&nbsp;secondo."
                  },
                  {
                    "tr_title": "Visualizzazione immersiva",
                    "tr_subtitle": "Un fantastico schermo dà vita alle immagini&nbsp;con risoluzione nitida e&nbsp;colori vividi."
                  },
                  {
                    "tr_title": "Completa velocemente le tue attività",
                    "tr_subtitle": "Goditi le prestazioni estremamente reattive dei processori Intel®&nbsp;Core™ di&nbsp;undicesima generazione."
                  },
                  {
                    "tr_title": "Libero dai fili, più a lungo",
                    "tr_subtitle": "Mantieni il tuo ritmo con un PC che ottimizza&nbsp;la durata della batteria e&nbsp;si&nbsp;ricarica in un baleno."
                  },
                  {
                    "tr_title": "Connettività via cavo universale",
                    "tr_subtitle": "Rende facile, rapido e sicuro il collegamento di altri dispositivi al tuo PC con la tecnologia Thunderbolt™&nbsp;4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Connessioni veloci e affidabili",
                    "tr_subtitle": "Videochatta o condividi i file con una connessione su cui puoi contare utilizzando Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Notebook Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tutto ciò di cui hai bisogno (e molto altro) in un notebook sottile e leggero.",
                "tr_subtitle": "I notebook Intel® Evo™ sono progettati per prestazioni in movimento e per offrirti la migliore esperienza possibile<sup>2</sup>, qualsiasi attività tu stia svolgendo.",
                "titleArray": [
                  {
                    "tr_title": "I notebook sono sempre più importanti, per questo ne abbiamo aumentato le potenzialità.",
                    "tr_subtitle": "Utilizza il touchscreen per esplorare, ruotare e ingrandire."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Riavvio immediato",
                    "tr_subtitle": "Grazie al riavvio immediato, il tuo PC può passare dalla sospensione alla piena operatività in meno di&nbsp;un&nbsp;secondo."
                  },
                  {
                    "tr_title": "Visualizzazione immersiva",
                    "tr_subtitle": "Un fantastico schermo dà vita alle immagini&nbsp;con risoluzione nitida e&nbsp;colori vividi."
                  },
                  {
                    "tr_title": "Prestazioni rivoluzionarie",
                    "tr_subtitle": "Ottieni potenza aggiuntiva dove è maggiormente necessaria con le prestazioni intelligenti dei processori Intel® Core™ di dodicesima generazione."
                  },
                  {
                    "tr_title": "Libero dai fili, più a lungo",
                    "tr_subtitle": "Mantieni il tuo ritmo con un PC che ottimizza&nbsp;la durata della batteria e&nbsp;si&nbsp;ricarica in un baleno."
                  },
                  {
                    "tr_title": "Connettività via cavo universale",
                    "tr_subtitle": "Rende facile, rapido e sicuro il collegamento di altri dispositivi al tuo PC con la tecnologia Thunderbolt™&nbsp;4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Connessioni veloci e affidabili",
                    "tr_subtitle": "Videochatta o condividi i file con una connessione su cui puoi contare utilizzando Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Notebook Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tutto ciò di cui hai bisogno (e molto altro) in un notebook sottile e leggero.",
                "tr_subtitle": "I notebook Intel® Evo™ sono progettati per prestazioni in movimento e per offrirti la migliore esperienza possibile<sup>2</sup>, qualsiasi attività tu stia svolgendo.",
                "titleArray": [
                  {
                    "tr_title": "I notebook sono sempre più importanti, per questo ne abbiamo aumentato le potenzialità.",
                    "tr_subtitle": "Utilizza il touchscreen per esplorare, ruotare e ingrandire."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Riavvio immediato",
                    "tr_subtitle": "Grazie al riavvio immediato, il tuo PC può passare dalla sospensione alla piena operatività in meno di&nbsp;un&nbsp;secondo."
                  },
                  {
                    "tr_title": "Visualizzazione immersiva",
                    "tr_subtitle": "Un fantastico schermo dà vita alle immagini&nbsp;con risoluzione nitida e&nbsp;colori vividi."
                  },
                  {
                    "tr_title": "Prestazioni rivoluzionarie",
                    "tr_subtitle": "Ottieni potenza aggiuntiva dove è maggiormente necessaria con le prestazioni intelligenti dei processori Intel® Core™ di tredicesima generazione."
                  },
                  {
                    "tr_title": "Libero dai fili, più a lungo",
                    "tr_subtitle": "Mantieni il tuo ritmo con un PC che ottimizza&nbsp;la durata della batteria e&nbsp;si&nbsp;ricarica in un baleno."
                  },
                  {
                    "tr_title": "Connettività via cavo universale",
                    "tr_subtitle": "Rende facile, rapido e sicuro il collegamento di altri dispositivi al tuo PC con la tecnologia Thunderbolt™&nbsp;4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Connessioni veloci e affidabili",
                    "tr_subtitle": "Videochatta o condividi i file con una connessione su cui puoi contare utilizzando Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Notebook Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tutto ciò di cui hai bisogno (e molto altro) in un notebook sottile e leggero.",
                "tr_subtitle": "I notebook Intel® Evo™ sono progettati per prestazioni in movimento e per offrirti la migliore esperienza possibile<sup>2</sup>, qualsiasi attività tu stia svolgendo.",
                "titleArray": [
                  {
                    "tr_title": "I notebook sono sempre più importanti, per questo ne abbiamo aumentato le potenzialità.",
                    "tr_subtitle": "Utilizza il touchscreen per esplorare, ruotare e ingrandire."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Riavvio immediato",
                    "tr_subtitle": "Grazie al riavvio immediato, il tuo PC può passare dalla sospensione alla piena operatività in meno di&nbsp;un&nbsp;secondo."
                  },
                  {
                    "tr_title": "Visualizzazione immersiva",
                    "tr_subtitle": "Un fantastico schermo dà vita alle immagini&nbsp;con risoluzione nitida e&nbsp;colori vividi."
                  },
                  {
                    "tr_title": "Prestazioni rivoluzionarie",
                    "tr_subtitle": "Ottieni potenza aggiuntiva dove è maggiormente necessaria con le prestazioni intelligenti dei processori Intel® Core™ di tredicesima generazione."
                  },
                  {
                    "tr_title": "Libero dai fili, più a lungo",
                    "tr_subtitle": "Mantieni il tuo ritmo con un PC che ottimizza&nbsp;la durata della batteria e&nbsp;si&nbsp;ricarica in un baleno."
                  },
                  {
                    "tr_title": "Connettività via cavo universale",
                    "tr_subtitle": "Rende facile, rapido e sicuro il collegamento di altri dispositivi al tuo PC con la tecnologia Thunderbolt™&nbsp;4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Connessioni veloci e affidabili",
                    "tr_subtitle": "Videochatta o condividi i file con una connessione su cui puoi contare utilizzando Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "In primo piano",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Che cos'è Intel®&nbsp;Evo™&nbsp;Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Perché scegliere Intel®&nbsp;Evo™&nbsp;Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creatività",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Più creatività in più luoghi",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Editing di foto a tutta velocità",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Crea opere d'arte originali sul momento",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produttività",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Massimizza la produttività con Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Rileva i deepfake prima di condividerli grazie all'IA",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Il tuo mondo. Sui tuoi dispositivi.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilità",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Dai il benvenuto al controllo gestuale",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "La durata della batteria non ti limiterà",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Ti mantiene automaticamente connesso",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "In primo piano",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Che cos'è Intel®&nbsp;Evo™&nbsp;Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Perché scegliere Intel®&nbsp;Evo™&nbsp;Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creatività",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Riproduzione video rapida e fluida",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Produci dei successi con la potenza dell'IA",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "La via più rapida verso il montaggio finale",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produttività",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Massimizza la produttività con Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Rileva i deepfake prima di condividerli grazie all'IA",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Il tuo mondo. Sui tuoi dispositivi.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilità",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Collabora praticamente ovunque",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "La durata della batteria non ti limiterà",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Ti mantiene automaticamente connesso",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Tutto il tuo intrattenimento. Un unico PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Per un PC che funge anche da centro di intrattenimento, ti serve una quantità di storage adeguata per tutti i tuoi brani, video e videogame preferiti e la giusta combinazione di processore e schermo per una grafica immersiva. Questo PC risponde a tutte e tre le esigenze.",
                "titleArray": [
                  {
                    "tr_title": "Gaming e streaming straordinari",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crea la tua raccolta digitale",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Una finestra sull'intrattenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Esperienze di gaming e streaming fluide",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crea la tua raccolta digitale",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Una finestra sull'intrattenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Aumenta la tua produttività. Ovunque.",
              "tr_tileTitle1": "Fai di più. A tutta velocità.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Questo PC offre l’equilibrio perfetto tra potenza e portabilità. Oltre a un processore progettato per design sottili e leggeri, questo PC offre anche il tipo di Wi&#8209;Fi per mantenerti connesso e una durata della batteria tale da liberarti dalle prese elettriche.",
                "tr_subtitle1": "Questo PC ha un equilibro perfetto di potenza e prestazioni per aiutarti a fare di più in meno tempo. La combinazione di un processore potente accelerato da un'ampia memoria e Wi-Fi super veloce ottimizza le tue attività quotidiane.",
                "tr_subtitle2": "Questo PC offre l'equilibrio perfetto tra potenza e portabilità. Oltre a un processore progettato per design sottili e leggeri, questo PC offre anche il tipo di Wi‑Fi per mantenerti connesso e la memoria appropriata per il multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Prestazioni intelligenti che&nbsp;contano",
                    "tr_title1": "Più velocità con le prestazioni intelligenti",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Le migliori connessioni della categoria",
                    "tr_title1": "Connettiti a quello che conta per te",
                    "tr_title2": "Un grande progresso nella tecnologia Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Senza cavi, più a lungo",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Gestisci i programmi da professionista",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "La libertà di creare",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "La creazione di contenuti è una delle attività più impegnative per il PC. Fortunatamente, questi componenti offrono la potenza necessaria per semplificare il tuo processo creativo.",
                "titleArray": [
                  {
                    "tr_title": "Creatività accelerata",
                    "tr_title1": "Realizzazione dei progetti creativi senza interruzioni",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Creatività migliorata",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memoria per il multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Guarda all’interno di&nbsp;questo&nbsp;PC",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Questi sono i tre componenti principali da tenere in considerazione per la scelta di un nuovo PC. Decidendo come userai il tuo nuovo PC, potrai capire quali specifiche scegliere per questi tre componenti essenziali. Sono:",
                "titleArray": [
                  {
                    "tr_title": "Processore",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Storage",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memoria",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "La libertà di creare",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "La creazione di contenuti è una delle attività più impegnative per il PC. Fortunatamente, questi componenti offrono la potenza necessaria per semplificare il tuo processo creativo.",
                "titleArray": [
                  {
                    "tr_title": "Creazione di contenuti più intelligente",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Dai vita alle tue immagini",
                    "tr_title1": "Creiamo",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memoria per il multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Aumenta la tua produttività. Ovunque.",
              "tr_tileTitle1": "Fai di più. A tutta velocità.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Questo PC offre l’equilibrio perfetto tra potenza e portabilità. Oltre a un processore progettato per design sottili e leggeri, questo PC offre anche il tipo di Wi&#8209;Fi per mantenerti connesso e una durata della batteria tale da liberarti dalle prese elettriche.",
                "tr_subtitle1": "Questo PC ha un equilibro perfetto di potenza e prestazioni per aiutarti a fare di più in meno tempo. La combinazione di un processore potente accelerato da un'ampia memoria e Wi-Fi super veloce ottimizza le tue attività quotidiane.",
                "tr_subtitle2": "Questo PC offre l'equilibrio perfetto tra potenza e portabilità. Oltre a un processore progettato per design sottili e leggeri, questo PC offre anche il tipo di Wi‑Fi per mantenerti connesso e la memoria appropriata per il multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Progettati per fare di più",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Le migliori connessioni della categoria",
                    "tr_title1": "Connettiti a quello che conta per te",
                    "tr_title2": "Un grande progresso nella tecnologia Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Senza cavi, più a lungo",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Gestisci i programmi da professionista",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Predisposto per la realtà virtuale",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Dove la realtà virtuale prende vita",
                "tr_subtitle": "Cosa ci vuole per creare un mondo completamente virtuale? In un PC, serve il coordinamento tra processore, scheda grafica e memoria.",
                "titleArray": [
                  {
                    "tr_title": "Dare vita a mondi virtuali",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La realtà si trova nei dettagli",
                    "tr_title1": "Immergiti",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>un sollievo per gli occhi stanchi",
      "tr_sub_header": "La luce blu può causare disturbi visivi e alterare il ciclo sonno/veglia. Il monitor OLED di Samsung, certificato come Eye Care Display da SGS, non cambia i colori, ma riduce la lunghezza d'onda della luce blu potenzialmente dannosa, per il massimo comfort visivo e la riduzione della fatica oculare.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Luce blu meno dannosa"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra in ogni modo",
      "tr_sub_header": "Il design ultraleggero e ultrasottile di OLED massimizza la qualità delle immagini riducendo al contempo il peso."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Nero intenso",
      "tr_sub_header": "0,0005 nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>Convenzionale</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streaming",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMING",
                "tr_subtitle": "Divertimento senza fine",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per lo streaming:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Un processore che supporta lo streaming in HD."
                  },
                  {
                    "tr_title": "Uno schermo per immagini meravigliosamente luminose."
                  },
                  {
                    "tr_title": "Wi‑Fi che garantisce meno buffering e tempi di caricamento."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Navigazione sul Web",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NAVIGAZIONE SUL WEB",
                "tr_subtitle": "Accesso a Internet semplificato",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per la navigazione sul Web:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può caricare rapidamente le pagine ricche di contenuti multimediali."
                  },
                  {
                    "tr_title": "Wi‑Fi veloce e affidabile."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochat",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHAT",
                "tr_subtitle": "Connessioni cristalline",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per le videochat:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può registrare e condividere immagini contemporaneamente."
                  },
                  {
                    "tr_title": "Wi‑Fi veloce e affidabile."
                  },
                  {
                    "tr_title": "Una webcam che ti farà apparire in maniera cristallina."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitasking ad alta velocità",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING AD ALTA VELOCITÀ",
                "tr_subtitle": "Fai di più senza rallentare",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per il multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può destreggiarsi tra diversi programmi alla volta."
                  },
                  {
                    "tr_title": "Wi‑Fi veloce e affidabile."
                  },
                  {
                    "tr_title": "Una memoria che passa in modo fluido tra le applicazioni."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochat",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHAT",
                "tr_subtitle": "Connessioni cristalline",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per le videochat:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può registrare e condividere immagini contemporaneamente."
                  },
                  {
                    "tr_title": "Wi‑Fi veloce e affidabile."
                  },
                  {
                    "tr_title": "Una webcam che ti farà apparire in maniera cristallina."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Gaming occasionale",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "GAMING OCCASIONALE",
                "tr_subtitle": "È giunto il tempo di giocare",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per il gaming occasionale:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Un processore per il gameplay fluido."
                  },
                  {
                    "tr_title": "Una memoria che supporta contemporaneamente le chat vocali e lo streaming."
                  },
                  {
                    "tr_title": "Una grafica per immagini sorprendenti."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Editing di foto",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDITING DI FOTO",
                "tr_subtitle": "Foto con pixel perfetti",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per l'editing di foto:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Un processore in grado di modificare gruppi di immagini di grandi dimensioni."
                  },
                  {
                    "tr_title": "Una memoria che accelera il software di editing."
                  },
                  {
                    "tr_title": "La tecnologia Thunderbolt™ 4, che ti consente di trasferire rapidamente i file."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitasking ad alta velocità",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING AD ALTA VELOCITÀ",
                "tr_subtitle": "Fai di più senza rallentare",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per il multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può destreggiarsi tra diversi programmi alla volta."
                  },
                  {
                    "tr_title": "Wi‑Fi veloce e affidabile."
                  },
                  {
                    "tr_title": "Una memoria che passa in modo fluido tra le applicazioni."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Collaborazione in tempo reale",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLLABORAZIONE IN TEMPO REALE",
                "tr_subtitle": "Lavora da qualsiasi luogo",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per la collaborazione online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Un processore per i software per il lavoro."
                  },
                  {
                    "tr_title": "Wi‑Fi su cui puoi contare."
                  },
                  {
                    "tr_title": "Una webcam che registra in HD brillante."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Editing di video",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDITING DI VIDEO",
                "tr_subtitle": "Realizza video magici",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per l'editing di video:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può gestire file di grandi dimensioni."
                  },
                  {
                    "tr_title": "Una memoria che rende il software di editing veloce e reattivo."
                  },
                  {
                    "tr_title": "Una grafica che diminuisce i tempi di rendering."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Editing di foto",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDITING DI FOTO",
                "tr_subtitle": "Foto con pixel perfetti",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per l'editing di foto:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Un processore in grado di modificare gruppi di immagini di grandi dimensioni."
                  },
                  {
                    "tr_title": "Una memoria che accelera il software di editing."
                  },
                  {
                    "tr_title": "La tecnologia Thunderbolt™ 4, che ti consente di trasferire rapidamente i file."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Editing di video",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDITING DI VIDEO",
                "tr_subtitle": "Realizza video magici",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per l'editing di video:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può gestire file di grandi dimensioni."
                  },
                  {
                    "tr_title": "Una memoria che rende il software di editing veloce e reattivo."
                  },
                  {
                    "tr_title": "Una grafica che diminuisce i tempi di rendering."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Modellazione 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODELLAZIONE 3D",
                "tr_subtitle": "Creazioni di livello superiore",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per la modellazione 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Un processore che può gestire software creativi avanzati."
                  },
                  {
                    "tr_title": "Una memoria che può caricare rapidamente file di grandi dimensioni."
                  },
                  {
                    "tr_title": "Una grafica per la creazione e l'editing potenti di immagini."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Collaborazione in tempo reale",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLLABORAZIONE IN TEMPO REALE",
                "tr_subtitle": "Lavora da qualsiasi luogo",
                "tr_subtitle1": "Ecco le caratteristiche che rendono questo PC ideale per la collaborazione online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Un processore per i software per il lavoro."
                  },
                  {
                    "tr_title": "Wi‑Fi su cui puoi contare."
                  },
                  {
                    "tr_title": "Una webcam che registra in HD brillante."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "App Intel® Unison™",
    "tr_title": "Libera il tuo mondo connesso",
    "tr_subtitle": "Trasferisci file e foto in modo fluido mentre gestisci le notifiche, le chiamate e i messaggi del tuo telefono dal PC.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "TRASFERISCI FILE E FOTO",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "EFFETTUA E RICEVI TELEFONATE",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "INVIA E RICEVI MESSAGGI",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "GESTISCI LE NOTIFICHE DEL TELEFONO",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "LIBERA IL TUO MONDO CONNESSO",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}