const Library_mobile_tr = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[cpuDisplay] işlemcili"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[cpuDisplay] işlemcili"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Tıpkı bir beyin gibi, işlemciniz de bilgisayarınızın ne yaptığını kontrol eder. Yani izlediğiniz videolar, oynadığınız oyunlar, ziyaret ettiğiniz web siteleri dahil her şey işlemcinizde başlar.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "İşlemci bilgisayarınızın beynidir"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Performans ve değer.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Silver işlemci",
                    "tr_subtitle": "İnternetteki dosyalara erişme performansıyla dijital dünyanın kontrolünü elinize alın. Programlar arasında zahmetsizce geçiş yaparak kolayca çoklu görev yürütün ve daha kısa sürede daha fazlasını yapın.",
                    "tr_gen_core_title": "Intel® Pentium® Silver işlemci"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Gold işlemci",
                    "tr_subtitle": "Gelişmiş Üretkenlik - En talepkar günlerinizde ihtiyacınız olan gücü sağlayacak gelişmiş performans. Daha Hızlı Web Gezintisi - Daha fazla keşif ve daha az bekleme için medya açısından zengin web sayfalarını hızlıca yükleyin.",
                    "tr_gen_core_title": "Intel® Pentium® Gold işlemci"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Celeron® işlemci",
                    "tr_subtitle": "Çalışmalarınızı ve eğlencenizi artıracak performans. Filmleri ve videoları çarpıcı ayrıntılarıyla izleyin. Bekleyerek zaman kaybetmeyin, yapacaklarınıza daha fazla zaman ayırın.",
                    "tr_gen_core_title": "Intel® Celeron® işlemci"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® işlemci",
                    "tr_subtitle": "Olağanüstü eğlence, veri akışıyla video izleme ve üretkenlik için tasarlandı. Filmleri ve videoları çarpıcı ayrıntılarla izleyin. Bekleyerek zaman kaybetmeyin, yapacaklarınıza daha fazla zaman ayırın.",
                    "tr_gen_core_title": "Intel® Pentium® işlemci"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "İhtiyaç duyduğunuz performans. İstediğiniz uygun fiyat.",
                "tr_title": "Intel® İşlemci",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Kendi kategorisinde değer yaratır",
                    "tr_subtitle": "Bir Intel® İşlemci, uygun bir fiyatla, ihtiyaç duyduğunuz görevlerin neredeyse tümünün üstesinden gelebilir. Bir yazılımdan diğerine kolayca geçiş yapın. İstikrarlı performansla yüksek kaliteli grafiklerin tadını çıkarın.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Daha güçlü bağlantılar kurun",
                    "tr_subtitle": "Daha canlı video kalitesi, daha zengin ses netliği ve yaklaşık üç kat daha hızlı internet bağlantısı, ihtiyaç duyduğunuz her yerde güvenle işbirliği yapmanıza olanak tanır.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Farkı gün boyu, apaçık ortada",
                    "tr_subtitle": "Sabah saatlerindeki film maratonlarından çalışmalarınızda yaptığınız son dakika rötuşlarına kadar, Intel® İşlemci, gerçek anlamda sürükleyici ve kesintisiz deneyimler için cihazınızın pil ömrünü uzatır.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Her ortamda bir şeyler öğrenebilirsiniz",
                    "tr_subtitle": "Birden fazla çevrimiçi eğitim aracını evinizin her köşesinden kolaylıkla çalıştırın."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Derslerinizi istediğiniz yere götürün",
                    "tr_subtitle": "Aynı anda çeşitli e-eğitim araçlarını çalıştırırken derslerinizi istediğiniz her yerde bitirin."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Bütçenize ve yaşam tarzınıza uygun eğlence ve üretkenlik için gereken gücü elde edin.",
                "tr_title": "Her bütçeye uygun Intel performansı.",
                "tr_gen_core_title": "Intel® Celeron® işlemci",
                "processorCompare": {
                  "tr_title": "SİZE EN UYGUN PERFORMANS VE DEĞER DÜZEYİNİ BULUN",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Intel® Celeron® işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Web'de hızlı ve medya açısından zengin gezinme",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Programlar arasında kolayca geçiş",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Günlük oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K video akışı",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Basit fotoğraf ve video düzenleme",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Akıllı performansla hızlı üretkenlik",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "7. Nesil Intel® Core™ [cpu_fam] işlemcili"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "6. Nesil Intel® Core™ [cpu_fam] işlemcili"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "5. Nesil Intel® Core™ [cpu_fam] işlemcili"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "4. Nesil Intel® Core™ [cpu_fam] işlemcili"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "3. Nesil Intel® Core™ [cpu_fam] işlemcili"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "2. Nesil Intel® Core™ [cpu_fam] işlemcili"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "1. Nesil Intel® Core™ [cpu_fam] işlemcili"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Önerilen kullanım amacı:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "8. Nesil Intel® Core™ i3 işlemcili",
                "tr_gen_core_title": "8. Nesil Intel®&nbsp;Core™&nbsp;i3 işlemci"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. Nesil Intel® Core™ işlemciler, dijital dünyanıza ayak uydurmak için geliştirildi. Hızı ve performansı bir araya getiren bu işlemcilerle, bilgisayarınızda en sevdiğiniz işlemleri zorlanmadan yapabilirsiniz.",
                "tr_title": "9. Nesil Intel® Core™ i3 işlemcili",
                "tr_gen_core_title": "9. Nesil Intel®&nbsp;Core™&nbsp;i3 işlemci"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Çok yönlü performans uzun pil ömrüyle bir araya gelerek, her yerde mükemmel bir bilgisayar deneyimini garantiler.",
                "tr_title": "Gücünüzü gün boyu koruyun",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i3 işlemci",
                "processorCompare": {
                  "tr_title": "SİZE EN UYGUN 10. NESİL PERFORMANS&nbsp;DÜZEYİNİ&nbsp;BULUN",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "İnternetten veri akışıyla 4K film izleme",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hızlı, güvenilir bağlantı",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotoğraf maskeleme ve video filtreleme",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Yüksek kare hızlarıyla 1080p çözünürlükte oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Büyük dosyalar ve içerik üretme programlarıyla çalışmak için ideal",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Performanstan ödün vermeden veri akışıyla film izlemekten sunum hazırlamaya kolayca geçiş yapın.",
                "tr_title": "Gücünüzü gün boyu koruyun",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i3 işlemci"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Her zaman hareket halinde olanlar için özel olarak tasarlanan bir cihaz.",
                "tr_title": "Yeni dizüstü bilgisayar sınıfıyla tanışın",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i3 işlemci"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Taşınabilir bilgisayarlar için özel olarak tasarlanan 11.&nbsp;Nesil Intel®&nbsp;Core™&nbsp;i3 işlemci ile nerede olursanız olun daha fazlasını yapabilirsiniz.",
                "tr_title": "Yeni dizüstü bilgisayarınızdan daha fazlasını elde edin",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i3 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i3 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Tüm aile üyeleriniz yaptıkları her iş için tek bir masaüstü bilgisayar kullanıyorsa 11. Nesil Intel® Core™ i3 işlemcinin gücüne ve çok yönlülüğüne ihtiyaç duyuyorsunuz demektir.",
                "tr_title": "Ezber bozan performansla her şeye hazır olun.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i3 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i3 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "8. Nesil Intel® Core™ i5 işlemcili",
                "tr_gen_core_title": "8. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. Nesil Intel® Core™ işlemciler, dijital dünyanıza ayak uydurmak için geliştirildi. Hızı ve performansı bir araya getiren bu işlemcilerle, bilgisayarınızda en sevdiğiniz işlemleri zorlanmadan yapabilirsiniz.",
                "tr_title": "9. Nesil Intel® Core™ i5 işlemcili",
                "tr_gen_core_title": "9. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "EN TALEPKÂR OYUNLARDA KUSURSUZ OYUN VE VR DENEYİMİ",
                "tr_title": "Ciddi oyun deneyimi burada başlar",
                "tr_gen_core_title": "9. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN INTEL® CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA düzeyindeki oyunlar",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Taviz vermeden oyun oynama, canlı yayın yapma ve kaydetme",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Oyuna yönelik en iyi 9. Nesil Intel® Core™ işlemci",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 4<br/>İş parçacığı: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,1&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gün boyu gittiğiniz her yerde, dilediğiniz zaman programlar arasında kolay geçişler yapın.",
                "tr_title": "Hareket halindeyken çoklu görev çalıştırma",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "processorCompare": {
                  "tr_title": "SİZE EN UYGUN 10. NESİL PERFORMANS&nbsp;DÜZEYİNİ&nbsp;BULUN",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "İnternetten veri akışıyla 4K film izleme",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hızlı, güvenilir bağlantı",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotoğraf maskeleme ve video filtreleme",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Yüksek kare hızlarıyla 1080p çözünürlükte oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Büyük dosyalar ve içerik üretme programlarıyla çalışmak için ideal",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Eğlenceden üretkenliğe ve içerik üretimine kadar her işlemde performansı artırın.",
                "tr_title": "Daha güçlü bir bilgisayara geçin",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Her zaman hareket halinde olanlar için özel olarak tasarlanan bir cihaz.",
                "tr_title": "Yeni dizüstü bilgisayar sınıfıyla tanışın",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Kesintisiz veri akışı ve AAA düzeyinde oyun deneyimi için tasarlanmıştır",
                "tr_title": "Kontrolü ele geçirin",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN 10. NESİL INTEL®&nbsp;CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Kusursuz AAA düzeyinde oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın ve kayıt",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gelişmiş içerik oluşturma",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimum oyun performansı",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Çarpan kilitsiz ve hız aşırtılabilir<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,1&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Kesintisiz veri akışı ve AAA düzeyinde oyun deneyimi için tasarlanmıştır",
                "tr_title": "Kontrolü ele geçirin",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN 10. NESİL INTEL®&nbsp;CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Kusursuz AAA düzeyinde oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın ve kayıt",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gelişmiş içerik oluşturma",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimum oyun performansı",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Çarpan kilitsiz ve hız aşırtılabilir<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,8&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,1&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 10<br/>İş parçacığı: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Gün boyu gittiğiniz her yerde, dilediğiniz zaman programlar arasında kolay geçişler yapın.",
                "tr_title": "Hareket halindeyken çoklu görev çalıştırma",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "10. NESİL INTEL® CORE™ i5 İŞLEMCİ"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. Nesil Intel® Core™ i5 işlemci, en ince ve hafif bilgisayarlarda capcanlı görsel deneyimler ve geniş bir yelpazede üretkenlik özellikleri sunar.",
                "tr_title": "Dizüstü bilgisayarda etkileyici güç",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Tüm aile üyeleriniz yaptıkları her iş için tek bir masaüstü bilgisayar kullanıyorsa 11. Nesil Intel® Core™ i5 işlemcinin gücüne ve çok yönlülüğüne ihtiyaç duyuyorsunuz demektir.",
                "tr_title": "Ezber bozan performansla her şeye hazır olun.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. Nesil Intel® Core™ i5 işlemci, capcanlı görsel deneyimleri ve çok çeşitli üretkenlik özelliklerini ince ve hafif dizüstü bilgisayarlara getiriyor.",
                "tr_title": "Dizüstü bilgisayarda etkileyici güç",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i5 İŞLEMCİ"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "8. Nesil Intel® Core™ i7 işlemcili",
                "tr_gen_core_title": "8. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "9. Nesil Intel® Core™ işlemciler, dijital dünyanıza ayak uydurmak için geliştirildi. Hızı ve performansı bir araya getiren bu işlemcilerle, bilgisayarınızda en sevdiğiniz işlemleri zorlanmadan yapabilirsiniz.",
                "tr_title": "9. Nesil Intel® Core™ i7 işlemcili",
                "tr_gen_core_title": "9. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "%32'YE KADAR DAHA YÜKSEK FPS<sup>3</sup> 3 YILLIK BİR BİLGİSAYARA kıyasla oyun, canlı yayın ve kayıt esnasında",
                "tr_title": "Oyunların en heyecanlı anlarını paylaşma gücü",
                "tr_gen_core_title": "9. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN INTEL® CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA düzeyindeki oyunlar",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Taviz vermeden oyun oynama, canlı yayın yapma ve kaydetme",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Oyuna yönelik en iyi 9. Nesil Intel® Core™ işlemci",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 4<br/>İş parçacığı: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,1&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Verimlilik için tasarlanan bu ince ve şık cihaz, çok daha iyi yaratıcılık, bağlantı ve veri akışı deneyimleri elde etmenizi sağlar.",
                "tr_title": "Her yerde gelişmiş performans",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "processorCompare": {
                  "tr_title": "SİZE EN UYGUN 10. NESİL PERFORMANS&nbsp;DÜZEYİNİ&nbsp;BULUN",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "İnternetten veri akışıyla 4K film izleme",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hızlı, güvenilir bağlantı",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotoğraf maskeleme ve video filtreleme",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Yüksek kare hızlarıyla 1080p çözünürlükte oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Büyük dosyalar ve içerik üretme programlarıyla çalışmak için ideal",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Yaptığınız her işlemde bir üst seviyeye erişmenizi sağlayacak gelişmiş performans.",
                "tr_title": "Her görev için gereken güç",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Her zaman hareket halinde olanlar için özel olarak tasarlanan bir cihaz.",
                "tr_title": "Yeni dizüstü bilgisayar sınıfıyla tanışın",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Rekabet avantajını koruyarak oyun oynayın, canlı yayın yapın ve kaydedin",
                "tr_title": "Oyun dünyanızı güçlendirin",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN 10. NESİL INTEL®&nbsp;CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Kusursuz AAA düzeyinde oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın ve kayıt",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gelişmiş içerik oluşturma",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimum oyun performansı",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Çarpan kilitsiz ve hız aşırtılabilir<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,1&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Rekabet avantajını koruyarak oyun oynayın, canlı yayın yapın ve kaydedin",
                "tr_title": "Oyun dünyanızı güçlendirin",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN 10. NESİL INTEL®&nbsp;CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Kusursuz AAA düzeyinde oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın ve kayıt",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gelişmiş içerik oluşturma",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimum oyun performansı",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Çarpan kilitsiz ve hız aşırtılabilir<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,8&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,1&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 10<br/>İş parçacığı: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Verimlilik için tasarlanan bu şık cihaz; içerik oluşturma, bağlantı ve yayın yöntemleriniz üzerinde büyük bir etki yaratabilir.",
                "tr_title": "Her yerde üst düzey performans",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "10. NESİL INTEL® CORE™ i7 İŞLEMCİ"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. Nesil Intel® Core™ i7 işlemci, ultra taşınabilir bilgisayarlarda gelişmiş içerik oluşturma, akıcı oyun deneyimi ve yeni nesil eğlence özellikleri sunar.",
                "tr_title": "Sınırları aşan dizüstü bilgisayar performansı",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "11. Nesil Intel® Core™ i7 işlemcinin ezber bozan performansıyla masaüstü bilgisayarınızı evdekilerin ihtiyaç duyabileceği tüm özelliklerle donatın.",
                "tr_title": "Çalışın. Öğrenin. Oynayın. Sınır tanımayın.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. Nesil Intel® Core™ i7 işlemci, gelişmiş içerik oluşturma, akıcı oyun deneyimi ve yeni nesil eğlence özelliklerini ince ve hafif dizüstü bilgisayarlara getiriyor.",
                "tr_title": "Sınırları aşan dizüstü bilgisayar performansı",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i7 İŞLEMCİ"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. Nesil Intel® Core™ i7 işlemcinin ezber bozan performansıyla masaüstü bilgisayarınızı evdekilerin ihtiyaç duyabileceği tüm özelliklerle donatın.",
                "tr_title": "Sınır tanımadan içerik oluşturun",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i7 İŞLEMCİ"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "İnce ve hafif dizüstü bilgisayarlardaki gelişmiş içerik oluşturma, akıcı oyun deneyimi ve yeni nesil eğlence özellikleriyle bu 11. Nesil Intel® Core™ i7 işlemci daha da iyi hale geldi.",
                "tr_title": "Sınırları aşan dizüstü bilgisayar performansı",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i7 İŞLEMCİ"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "8. Nesil Intel® Core™ i9 işlemcili",
                "tr_gen_core_title": "8. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. Nesil Intel® Core™ işlemciler, dijital dünyanıza ayak uydurmak için geliştirildi. Hızı ve performansı bir araya getiren bu işlemcilerle, bilgisayarınızda en sevdiğiniz işlemleri zorlanmadan yapabilirsiniz.",
                "tr_title": "9. Nesil Intel® Core™ i9 işlemcili",
                "tr_gen_core_title": "9. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "%41'E KADAR DAHA YÜKSEK FPS<sup>4</sup> 3 YILLIK BİR BİLGİSAYARA kıyasla oyun, canlı yayın ve kayıt esnasında",
                "tr_title": "Oyun tutkunları ister. Intel® Core™ i9 yapar.",
                "tr_gen_core_title": "9. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN INTEL® CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA düzeyindeki oyunlar",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Taviz vermeden oyun oynama, canlı yayın yapma ve kaydetme",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Oyuna yönelik en iyi 9. Nesil Intel® Core™ işlemci",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 4<br/>İş parçacığı: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,1&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "En üst düzey oyun deneyimi için kapasitenizin sınırlarını zorlayın",
                "tr_title": "En yüksek düzeylerde rekabet edin",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN 10. NESİL INTEL®&nbsp;CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Kusursuz AAA düzeyinde oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın ve kayıt",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gelişmiş içerik oluşturma",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimum oyun performansı",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Çarpan kilitsiz ve hız aşırtılabilir<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,1&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "En üst düzey oyun deneyimi için kapasitenizin sınırlarını zorlayın",
                "tr_title": "En yüksek düzeylerde rekabet edin",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN 10. NESİL INTEL®&nbsp;CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Kusursuz AAA düzeyinde oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın ve kayıt",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gelişmiş içerik oluşturma",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimum oyun performansı",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Çarpan kilitsiz ve hız aşırtılabilir<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,8&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,1&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 10<br/>İş parçacığı: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "En üst düzey içerik oluşturma deneyimi için kapasitenizin sınırlarını zorlayın",
                "tr_title": "En üst seviyede içerik oluşturun",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "tr_gen_core_title_uppercase": "10. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. Nesil Intel® Core™ i5 işlemci ve Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleriyle desteklenen Intel® Evo™ dizüstü bilgisayarlar, tamamen ince ve hafif bir tasarımla size inanılmaz hız ve performans sunar.",
                "tr_title": "Gelişmiş bir dizüstü bilgisayar için daha yüksek güç",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. Nesil Intel® Core™ i7 işlemci ve Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleriyle desteklenen Intel® Evo™ dizüstü bilgisayarlar, üst düzey mobil deneyim için size gelişmiş hız ve performans sunar.",
                "tr_title": "Gelişmiş bir dizüstü bilgisayar için gelişmiş güç",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Performans, medya ve bağlantı deneyimleri arasında kurduğunuz mükemmel dengenin keyfini çıkarırken inanılmaz bir değer elde edin.",
                "tr_title": "Mükemmel bir değer sunan performans ve bağlantı.",
                "tr_gen_core_title": "Intel® Pentium® Silver işlemci",
                "processorCompare": {
                  "tr_title": "SİZE EN UYGUN PERFORMANS VE DEĞER DÜZEYİNİ BULUN",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Web'de hızlı ve medya açısından zengin gezinme",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Programlar arasında kolayca geçiş",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Günlük oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K video akışı",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Basit fotoğraf ve video düzenleme",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Akıllı performansla hızlı üretkenlik",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "İnanılmaz bir değer elde ederek kusursuz çoklu görev yürütün, basit fotoğraf ve video düzenlemesi yapın, 4K videolar ve canlı grafiklerin keyfini çıkarın.",
                "tr_title": "İş ve oyun için etkileyici performans.",
                "tr_gen_core_title": "Intel® Pentium® Gold işlemci",
                "processorCompare": {
                  "tr_title": "SİZE EN UYGUN PERFORMANS VE DEĞER DÜZEYİNİ BULUN",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Web'de hızlı ve medya açısından zengin gezinme",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Programlar arasında kolayca geçiş",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Günlük oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K video akışı",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Basit fotoğraf ve video düzenleme",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Akıllı performansla hızlı üretkenlik",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Ultra taşınabilir bir bilgisayarda AAA düzeyinde oyun deneyimi ve uzun pil ömrü. Oyun oynarken taviz vermenize gerek yok.",
                "tr_title": "Tavize gerek yok.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 işlemci",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ULTRA TAŞINABİLİR INTEL® CORE™ OYUN İŞLEMCİNİZİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. Nesil Intel®&nbsp;Core™ i5&#8209;11300H işlemci"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. Nesil Intel®&nbsp;Core™ i7&#8209;11370H işlemci"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. Nesil Intel®&nbsp;Core™ i7&#8209;11375H Special edition işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "<span class='style_badge'>4,4 GHz'e</span> kadar maks. saat hızı",
                      "tr_td2_title": "<span class='style_badge'>4,8 GHz'e</span> kadar maks. saat hızı",
                      "tr_td3_title": "<span class='style_badge'>5,0 GHz'e</span> kadar maks. saat hızı"
                    },
                    {
                      "tr_td1_title": "4 çekirdek/ 8&nbsp;iş&nbsp;parçacığı",
                      "tr_td2_title": "4 çekirdek/ 8&nbsp;iş&nbsp;parçacığı",
                      "tr_td3_title": "4 çekirdek/ 8&nbsp;iş&nbsp;parçacığı"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost Teknolojisi",
                      "tr_td2_title": "Intel® Turbo Boost Teknolojisi",
                      "tr_td3_title": "Intel® Turbo Boost Max Teknolojisi 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel®&nbsp;Akıllı Önbellek",
                      "tr_td2_title": "12 MB Intel®&nbsp;Akıllı Önbellek",
                      "tr_td3_title": "12 MB Intel®&nbsp;Akıllı Önbellek"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleri",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleri",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleri"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Yüksek ayarlarda yüksek FPS'li 1080p AAA düzeyinde oyunlar ve ultra taşınabilir bilgisayarda şarja bağlı olmadan mükemmel performans.",
                "tr_title": "Beklemeyin. Hepsine&nbsp;sahip&nbsp;olun.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 işlemci",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ULTRA TAŞINABİLİR INTEL® CORE™ OYUN İŞLEMCİNİZİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. Nesil Intel®&nbsp;Core™ i5&#8209;11300H işlemci"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. Nesil Intel®&nbsp;Core™ i7&#8209;11370H işlemci"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. Nesil Intel®&nbsp;Core™ i7&#8209;11375H Special edition işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "<span class='style_badge'>4,4 GHz'e</span> kadar maks. saat hızı",
                      "tr_td2_title": "<span class='style_badge'>4,8 GHz'e</span> kadar maks. saat hızı",
                      "tr_td3_title": "<span class='style_badge'>5,0 GHz'e</span> kadar maks. saat hızı"
                    },
                    {
                      "tr_td1_title": "4 çekirdek/ 8&nbsp;iş&nbsp;parçacığı",
                      "tr_td2_title": "4 çekirdek/ 8&nbsp;iş&nbsp;parçacığı",
                      "tr_td3_title": "4 çekirdek/ 8&nbsp;iş&nbsp;parçacığı"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost Teknolojisi",
                      "tr_td2_title": "Intel® Turbo Boost Teknolojisi",
                      "tr_td3_title": "Intel® Turbo Boost Max Teknolojisi 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel®&nbsp;Akıllı Önbellek",
                      "tr_td2_title": "12 MB Intel®&nbsp;Akıllı Önbellek",
                      "tr_td3_title": "12 MB Intel®&nbsp;Akıllı Önbellek"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleri",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleri",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleri"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Oyunlarınızı ileriye taşıyacak 5,0 GHz'e kadar maks. saat hızı ve istediğiniz her yere götürebileceğiniz ultra taşınabilir bilgisayar.",
                "tr_title": "Daha güçlü ve hafif.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition işlemci",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition işlemci",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ULTRA TAŞINABİLİR INTEL® CORE™ OYUN İŞLEMCİNİZİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. Nesil Intel®&nbsp;Core™ i5&#8209;11300H işlemci"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. Nesil Intel®&nbsp;Core™ i7&#8209;11370H işlemci"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. Nesil Intel®&nbsp;Core™ i7&#8209;11375H Special edition işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "<span class='style_badge'>4,4 GHz'e</span> kadar maks. saat hızı",
                      "tr_td2_title": "<span class='style_badge'>4,8 GHz'e</span> kadar maks. saat hızı",
                      "tr_td3_title": "<span class='style_badge'>5,0 GHz'e</span> kadar maks. saat hızı"
                    },
                    {
                      "tr_td1_title": "4 çekirdek/ 8&nbsp;iş&nbsp;parçacığı",
                      "tr_td2_title": "4 çekirdek/ 8&nbsp;iş&nbsp;parçacığı",
                      "tr_td3_title": "4 çekirdek/ 8&nbsp;iş&nbsp;parçacığı"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost Teknolojisi",
                      "tr_td2_title": "Intel® Turbo Boost Teknolojisi",
                      "tr_td3_title": "Intel® Turbo Boost Max Teknolojisi 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel®&nbsp;Akıllı Önbellek",
                      "tr_td2_title": "12 MB Intel®&nbsp;Akıllı Önbellek",
                      "tr_td3_title": "12 MB Intel®&nbsp;Akıllı Önbellek"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleri",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleri",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleri"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. Nesil Intel® Core™ i7 Special Edition işlemci; gelişmiş içerik oluşturma, sorunsuz oyun deneyimi ve yeni nesil eğlence teknolojisini ultra taşınabilir bilgisayarlara taşıyor.",
                "tr_title": "Sınırları aşan dizüstü bilgisayar performansı",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i7 SPECIAL EDITION İŞLEMCİ"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. Nesil Intel® Core™ i7 Special Edition işlemci; gelişmiş içerik oluşturma, sorunsuz oyun deneyimi ve yeni nesil eğlence teknolojisini ultra taşınabilir bilgisayarlara taşıyor.",
                "tr_title": "Sınırları aşan dizüstü bilgisayar performansı",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i7 SPECIAL EDITION İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Yüksek saat hızları ve çekirdek sayısı bir arada mükemmel bir şekilde çalışarak rekabetçi oyunlardan ve karmaşık içerik oluşturmadan en iyi şekilde yararlanmanızı sağlar.",
                "tr_title": "İnanılmaz güç. Mükemmel denge.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 işlemci",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Saat hızı ve çekirdek performansı arasında kurulan doğru denge ile ister yüksek FPS'li rekabetçi oyunlarda ister etkileyici AAA deneyimlerinde oyunların keyfini çıkarın.",
                "tr_title": "Amaca yönelik güç.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Yüksek FPS ile zorlu oyunları oynayın, kaydedin, canlı yayın yapın ve ağır çoklu görev yürütme iş yüklerine kolayca geçiş yapın.",
                "tr_title": "Sınırları zorlayacak güç.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Yüksek saat hızları ve çekirdek sayısı bir arada mükemmel bir şekilde çalışarak rekabetçi oyunlardan ve karmaşık içerik oluşturmadan en iyi şekilde yararlanmanızı sağlar.",
                "tr_title": "İnanılmaz güç. Mükemmel denge.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Performanstan ödün vermeden veri akışıyla film izlemekten sunum hazırlamaya kolayca geçiş yapın.",
                "tr_title": "Gücünüzü gün boyu koruyun",
                "tr_gen_core_title": "Intel® Hybrid Teknolojili Intel®&nbsp;Core™&nbsp;i3 işlemci",
                "tr_subTitle2": "Intel® Hybrid Teknolojili Intel®&nbsp;Core™&nbsp;i3 işlemci"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Çok yönlü performans uzun pil ömrüyle bir araya gelerek, her yerde mükemmel bir bilgisayar deneyimini garantiler.",
                "tr_title": "Gücünüzü gün boyu koruyun",
                "tr_gen_core_title": "Intel® Hybrid Teknolojili Intel®&nbsp;Core™&nbsp;i3 işlemci",
                "tr_subTitle2": "Intel® Hybrid Teknolojili Intel®&nbsp;Core™&nbsp;i3 işlemci"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gün boyu gittiğiniz her yerde, dilediğiniz zaman programlar arasında kolay geçişler yapın.",
                "tr_title": "Hareket halindeyken çoklu görev çalıştırma",
                "tr_gen_core_title": "Intel® Hybrid Teknolojili Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_subTitle2": "Intel® Hybrid Teknolojili Intel®&nbsp;Core™&nbsp;i5 işlemci"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Eğlenceden üretkenliğe ve içerik üretimine kadar her işlemde performansı artırın.",
                "tr_title": "Daha güçlü bir bilgisayara geçin",
                "tr_gen_core_title": "Intel® Hybrid Teknolojili Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_subTitle2": "Intel® Hybrid Teknolojili Intel®&nbsp;Core™&nbsp;i5 işlemci"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "İster yüksek FPS'li rekabetçi oyunlar ister büyüleyici AAA düzeyinde deneyimler için masaüstü bilgisayar gücünde oyun performansı mobilite ile buluşuyor.",
                "tr_title": "Amaca yönelik güç.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "İster yüksek FPS'li rekabetçi oyunlar ister büyüleyici AAA düzeyinde deneyimler için masaüstü bilgisayar gücünde oyun performansı mobilite ile buluşuyor.",
                "tr_title": "Amaca yönelik güç.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Yüksek FPS ile aynı anda oyun, kayıt ve canlı yayın yapabilen, ayrıca ağır çoklu görev yürütme iş yüklerine kolayca geçiş yapabilen bir dizüstü bilgisayar.",
                "tr_title": "Sınırları zorlayacak güç.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Yüksek FPS ile aynı anda oyun, kayıt ve canlı yayın yapabilen, ayrıca ağır çoklu görev yürütme iş yüklerine kolayca geçiş yapabilen bir dizüstü bilgisayar.",
                "tr_title": "Sınırları zorlayacak güç.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Bilgisayarınızı nereye götürürseniz götürün, rekabetçi oyunlar ve karmaşık içerik oluşturma işlemlerinin hakkını verebilmeniz için yüksek saat hızları ve yüksek çekirdek sayıları birlikte sorunsuz bir şekilde çalışır.",
                "tr_title": "İnanılmaz güç. Mükemmel denge.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Bilgisayarınızı nereye götürürseniz götürün, rekabetçi oyunlar ve karmaşık içerik oluşturma işlemlerinin hakkını verebilmeniz için yüksek saat hızları ve yüksek çekirdek sayıları birlikte sorunsuz bir şekilde çalışır.",
                "tr_title": "İnanılmaz güç. Mükemmel denge.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Bilgisayarınızı nereye götürürseniz götürün, rekabetçi oyunlar ve karmaşık içerik oluşturma işlemlerinin hakkını verebilmeniz için yüksek saat hızları, yüksek çekirdek sayıları ve çarpan kilitsiz performans birlikte sorunsuz bir şekilde çalışır.",
                "tr_title": "Çarpan kilitsiz güç, kusursuz denge",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Bilgisayarınızı nereye götürürseniz götürün, rekabetçi oyunlar ve karmaşık içerik oluşturma işlemlerinin hakkını verebilmeniz için yüksek saat hızları, yüksek çekirdek sayıları ve çarpan kilitsiz performans birlikte sorunsuz bir şekilde çalışır.",
                "tr_title": "Çarpan kilitsiz güç, kusursuz denge",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Yüksek saat hızları ve çekirdek sayısı bir arada mükemmel bir şekilde çalışarak rekabetçi oyunlardan ve karmaşık içerik oluşturmadan en iyi şekilde yararlanmanızı sağlar.",
                "tr_title": "İnanılmaz güç. Mükemmel denge.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Bilgisayarınızı nereye götürürseniz götürün, yüksek saat hızları ve yüksek çekirdek sayıları birlikte kusursuz bir şekilde çalışarak karmaşık içerik oluşturma işlemlerinden en iyi şekilde faydalanmanızı sağlar.",
                "tr_title": "İnanılmaz güç. Mükemmel denge.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Yüksek saat hızları ve yüksek çekirdek sayısı birlikte kusursuz bir şekilde çalışarak karmaşık içerik oluşturma işlemlerinden en iyi şekilde faydalanmanızı sağlar.",
                "tr_title": "İnanılmaz güç. Mükemmel denge.",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "En üst düzey oyun deneyimi için kapasitenizin sınırlarını zorlayın",
                "tr_title": "En yüksek düzeylerde rekabet edin",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN 10. NESİL INTEL®&nbsp;CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Kusursuz AAA düzeyinde oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın ve kayıt",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gelişmiş içerik oluşturma",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimum oyun performansı",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Çarpan kilitsiz ve hız aşırtılabilir<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,1&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "En üst düzey oyun deneyimi için kapasitenizin sınırlarını zorlayın",
                "tr_title": "En yüksek düzeylerde rekabet edin",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN 10. NESİL INTEL®&nbsp;CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Kusursuz AAA düzeyinde oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Canlı yayın ve kayıt",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gelişmiş içerik oluşturma",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimum oyun performansı",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Çarpan kilitsiz ve hız aşırtılabilir<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,8&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,1&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 10<br/>İş parçacığı: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Her zaman hareket halinde olanlar için özel olarak tasarlanan bir cihaz.",
                "tr_title": "Yeni dizüstü bilgisayar sınıfıyla tanışın",
                "tr_gen_core_title": "10. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Yüksek saat hızları ve çekirdek sayısı bir arada mükemmel bir şekilde çalışarak rekabetçi oyunlardan ve karmaşık içerik oluşturmadan en iyi şekilde yararlanmanızı sağlar.",
                "tr_title": "Çarpan kilitsiz güç, kusursuz denge",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Bilgisayarınızı nereye götürürseniz götürün, yüksek saat hızları, yüksek çekirdek sayıları ve çarpan kilitsiz performans birlikte kusursuz bir şekilde çalışarak karmaşık içerik oluşturma işlemlerinden en iyi şekilde faydalanmanızı sağlar.",
                "tr_title": "Çarpan kilitsiz güç, kusursuz denge",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Yüksek saat hızları ve yüksek çekirdek sayısı birlikte kusursuz bir şekilde çalışarak karmaşık içerik oluşturma işlemlerinden en iyi şekilde faydalanmanızı sağlar.",
                "tr_title": "Çarpan kilitsiz güç, kusursuz denge",
                "tr_gen_core_title": "11. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "tr_gen_core_title_uppercase": "11. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Akıllı Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "En zorlu oyunlarda kusursuz oyun deneyimi ve VR",
                "tr_title": "Ciddi oyun deneyimi burada başlar",
                "tr_gen_core_title": "9. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN INTEL® CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA düzeyindeki oyunlar",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Taviz vermeden oyun oynama, canlı yayın yapma ve kaydetme",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Oyuna yönelik en iyi 9. Nesil Intel® Core™ işlemci",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 4<br/>İş parçacığı: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,6&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,6&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,9&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "%32'YE KADAR DAHA YÜKSEK FPS<sup>3</sup> <br/><span class='tr_subtitle_font'>3 yıllık bir bilgisayara kıyasla oyun, canlı yayın ve kayıt esnasında</span>",
                "tr_title": "Oyunların en heyecanlı anlarını paylaşma gücü",
                "tr_gen_core_title": "9. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN INTEL® CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA düzeyindeki oyunlar",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Taviz vermeden oyun oynama, canlı yayın yapma ve kaydetme",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Oyuna yönelik en iyi 9. Nesil Intel® Core™ işlemci",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 4<br/>İş parçacığı: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,6&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,6&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,9&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "%41'E KADAR DAHA YÜKSEK FPS<sup>4</sup> <br/><span class='tr_subtitle_font'>3 yıllık bir bilgisayara kıyasla oyun, canlı yayın ve kayıt esnasında</span>",
                "tr_title": "Oyun tutkunları ister. Intel® Core™ i9 yapar.",
                "tr_gen_core_title": "9. Nesil Intel®&nbsp;Core™&nbsp;i9 işlemci",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>İş Parçacığı</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Çekirdek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Maks. <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Önbellek</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Çarpan kilitsiz",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "OYUN TARZINIZA UYGUN INTEL® CORE™ İŞLEMCİYİ BULUN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 işlemci"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 işlemci"
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 işlemci"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA düzeyindeki oyunlar",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rekabetçi oyun deneyimi",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Taviz vermeden oyun oynama, canlı yayın yapma ve kaydetme",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Oyuna yönelik en iyi 9. Nesil Intel® Core™ işlemci",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,3&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 4<br/>İş parçacığı: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,6&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,6&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 6<br/>İş parçacığı: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 4,9&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Saat hızı<sup>2</sup>: 5&nbsp;GHz'e&nbsp;kadar</span>",
                      "tr_subTitle": "<span class='small_font'>Çekirdek: 8<br/>İş parçacığı: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Yeni nesil oyun için tasarlandı",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                    "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i5 İŞLEMCİ"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                    "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i7 İŞLEMCİ"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i9 İşlemci",
                    "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i9 İŞLEMCİ"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Çekirdek</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core çekirdeği",
                    "tr_badgeSubTitle": "[e_core] Efficient-core çekirdeği"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>İş Parçacığı</span></span>",
                    "tr_badgePreTitle": "Performance-core çekirdeklerinde&nbsp;[p_thread]",
                    "tr_badgeSubTitle": "Efficient-core çekirdeklerinde&nbsp;[e_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Savaş istasyonu, iş istasyonuyla buluştu",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                    "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i7 İŞLEMCİ"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i9 İşlemci",
                    "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i9 İŞLEMCİ"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Çekirdek</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core çekirdeği",
                    "tr_badgeSubTitle": "[e_core] Efficient-core çekirdeği"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>İş Parçacığı</span></span>",
                    "tr_badgePreTitle": "Performance-core çekirdeklerinde&nbsp;[p_thread]",
                    "tr_badgeSubTitle": "Efficient-core çekirdeklerinde&nbsp;[e_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "En ihtiyaç duyduğunuz anda güvenilir performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i3 işlemci",
                "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i3 İŞLEMCİ",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "En çok ihtiyaç duyulan alanda performans artışı",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "En çok ihtiyaç duyulan alanda performans artışı",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "En çok ihtiyaç duyduğunuz alanda üstün performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "En çok ihtiyaç duyduğunuz alanda üstün performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ dizüstü bilgisayarlarla performans, kendinizden emin bir şekilde görüntülü sohbetler yapabilmeniz için yerleşik zeka, daha hızlı internet, uzun pil ömrü ve daha fazlasını elde edersiniz.",
                "tr_title": "En çok ihtiyaç duyulan alanda performans artışı.",
                "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ dizüstü bilgisayarlarla performans, kendinizden emin bir şekilde görüntülü sohbetler yapabilmeniz için yerleşik zeka, daha hızlı internet, uzun pil ömrü ve daha fazlasını elde edersiniz.",
                "tr_title": "En çok ihtiyaç duyduğunuz alanda üstün performans",
                "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ dizüstü bilgisayarlarla performans, kendinizden emin bir şekilde görüntülü sohbetler yapabilmeniz için yerleşik zeka, daha hızlı internet, uzun pil ömrü ve daha fazlasını elde edersiniz.",
                "tr_title": "Üst düzey bir dizüstü bilgisayarda sıra dışı performans",
                "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i9 İşlemci",
                "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Intel® Turbo Boost Teknolojisi 2.0 ile [max_turbo_frequency] GHz'e kadar",
                "tr_title": "İşletmeler için tasarlanmıştır",
                "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gürültü bastırma, arka plan bulanıklaştırma",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "En çok yaptığınız işlere öncelik vermek için otomatik olarak uyarlanan performans",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Akıcı, yüksek kaliteli web aramaları",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hemen işe koyulmaya hazır, donanım tabanlı güvenlik",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Uzaktan cihaz yönetimi özellikleri",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Otomatik Wi-Fi performansı optimizasyonu<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Veri odaklı uygulamalar için ihtiyaç duyulan güç",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Gelişmiş performansla en yüksek üretkenlik",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Intel® Turbo Boost Teknolojisi 2.0 ile [max_turbo_frequency] GHz'e kadar",
                "tr_title": "Siz işlerinize odaklanın diye çok çalışır.",
                "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gürültü bastırma, arka plan bulanıklaştırma",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "En çok yaptığınız işlere öncelik vermek için otomatik olarak uyarlanan performans",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Akıcı, yüksek kaliteli web aramaları",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hemen işe koyulmaya hazır, donanım tabanlı güvenlik",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Uzaktan cihaz yönetimi özellikleri",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Otomatik Wi-Fi performansı optimizasyonu<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Veri odaklı uygulamalar için ihtiyaç duyulan güç",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Gelişmiş performansla en yüksek üretkenlik",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Intel® Turbo Boost Teknolojisi 2.0 ile [max_turbo_frequency] GHz'e kadar",
                "tr_title": "İşinize hız kazandırın",
                "tr_gen_core_title": "12. Nesil Intel®&nbsp;Core™&nbsp;i9 İşlemci",
                "tr_gen_core_title_uppercase": "12. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gürültü bastırma, arka plan bulanıklaştırma",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "En çok yaptığınız işlere öncelik vermek için otomatik olarak uyarlanan performans",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Akıcı, yüksek kaliteli web aramaları",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hemen işe koyulmaya hazır, donanım tabanlı güvenlik",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Uzaktan cihaz yönetimi özellikleri",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Otomatik Wi-Fi performansı optimizasyonu<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Veri odaklı uygulamalar için ihtiyaç duyulan güç",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Gelişmiş performansla en yüksek üretkenlik",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Daha fazlasını yapmaya yönelik güvenilir performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i3 işlemci",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Daha fazlasını yapmanız için gerçek kullanıma yönelik performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Daha fazlasını yapmanız için gerçek kullanıma yönelik performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Daha fazlasını yapmaya yönelik güçlü performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Daha fazlasını yapmaya yönelik güçlü performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Performansın ötesinde",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i9 İşlemci",
                "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Çekirdek</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core çekirdeği",
                    "tr_badgeSubTitle": "[e_core] Efficient-core çekirdeği"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>İş Parçacığı</span></span>",
                    "tr_badgePreTitle": "Performance-core çekirdeklerinde&nbsp;[p_thread]",
                    "tr_badgeSubTitle": "Efficient-core çekirdeklerinde&nbsp;[e_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mevcut telefonunuza sorunsuz bir şekilde bağlanın<sup>24</sup>, en iyi Wi-Fi'yi akıllıca seçin ve uzun ömürlü pilinizi hızla şarj edin.",
                "tr_title": "Her yerde daha fazlasını yapmak için üstün performansa erişin",
                "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mevcut telefonunuza sorunsuz bir şekilde bağlanın<sup>24</sup>, en iyi Wi-Fi'yi akıllıca seçin ve uzun ömürlü pilinizi hızla şarj edin.",
                "tr_title": "Bilgisayarınızın aynı anda daha fazlasını yapmasına olanak tanımak için üstün performansa erişin",
                "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mevcut telefonunuza sorunsuz bir şekilde bağlanın<sup>24</sup>, en iyi Wi-Fi'yi akıllıca seçin ve uzun ömürlü pilinizi hızla şarj edin.",
                "tr_title": "En zorlu görevleri hızla başarmak için üstün performansa erişin",
                "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i9 İşlemci",
                "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Performansın ötesinde",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                    "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                    "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i9 İşlemci",
                    "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Çekirdek</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core çekirdeği",
                    "tr_badgeSubTitle": "[e_core] Efficient-core çekirdeği"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>İş Parçacığı</span></span>",
                    "tr_badgePreTitle": "Performance-core çekirdeklerinde&nbsp;[p_thread]",
                    "tr_badgeSubTitle": "Efficient-core çekirdeklerinde&nbsp;[e_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Performansın ötesinde",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 işlemci",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 İŞLEMCİ",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 işlemci",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 İŞLEMCİ",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 işlemci",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 İŞLEMCİ",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Çekirdek</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core çekirdeği",
                    "tr_badgeSubTitle": "[e_core] Efficient-core çekirdeği"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>İş Parçacığı</span></span>",
                    "tr_badgePreTitle": "Performance-core çekirdeklerinde&nbsp;[p_thread]",
                    "tr_badgeSubTitle": "Efficient-core çekirdeklerinde&nbsp;[e_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Daha fazlasını yapmaya yönelik güvenilir performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Intel® Core™ i3 işlemci",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Daha fazlasını yapmanız için gerçek kullanıma yönelik performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel® Core™ i5 işlemci",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 İŞLEMCİ",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Daha fazlasını yapmaya yönelik güçlü performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel® Core™ i7 işlemci",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 İŞLEMCİ",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Performansın ötesinde",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 işlemci",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 İŞLEMCİ",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 işlemci",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 İŞLEMCİ",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 işlemci",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 İŞLEMCİ",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Çekirdek</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core çekirdeği",
                    "tr_badgeSubTitle": "[e_core] Efficient-core çekirdeği"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>İş Parçacığı</span></span>",
                    "tr_badgePreTitle": "Performance-core çekirdeklerinde&nbsp;[p_thread]",
                    "tr_badgeSubTitle": "Efficient-core çekirdeklerinde&nbsp;[e_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Daha fazlasını yapmaya yönelik güvenilir performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 3 işlemci",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Daha fazlasını yapmanız için gerçek kullanıma yönelik performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 5 işlemci",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 5 İŞLEMCİ",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Daha fazlasını yapmaya yönelik güçlü performans",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 7 işlemci",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 7 İŞLEMCİ",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "tr_title": "Taviz vermeden oyun oynayın",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 işlemci",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 5 IŞLEMCI",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 işlemci",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 7 IŞLEMCI",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;9 işlemci",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 9 IŞLEMCI",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Çekirdek</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core çekirdeği",
                    "tr_badgeSubTitle": "[e_core] Efficient-core çekirdeği",
                    "tr_badgeSubTitle1": "[l_core] LPE-core çekirdeği"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>İş Parçacığı</span></span>",
                    "tr_badgePreTitle": "Performance-core çekirdeklerinde&nbsp;[p_thread]",
                    "tr_badgeSubTitle": "Efficient-core çekirdeklerinde&nbsp;[e_thread]",
                    "tr_badgeSubTitle1": "LPE-core çekirdeklerinde [l_thread]"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 5 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 7 işlemci",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 9 işlemci",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Taviz vermeden oyun oynayın",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Taviz vermeden oyun oynayın",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Taviz vermeden oyun oynayın",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Taviz vermeden oyun oynayın",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Taviz vermeden oyun oynayın",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Taviz vermeden oyun oynayın",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Küçük işletmeler, büyük iş çözümlerini hak eder.",
                "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i5 işlemci",
                "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i5 İŞLEMCİ",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gürültü bastırma, arka plan bulanıklaştırma",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "En çok yaptığınız işlere öncelik vermek için otomatik olarak uyarlanan performans",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Akıcı, yüksek kaliteli web aramaları",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Kullanıma hazır, donanım tabanlı güvenlik koruması",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Cihazları ve verileri fiziksel kurcalama saldırılarına karşı koruma",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Küçük işletmeler, büyük iş çözümlerini hak eder.",
                "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
                "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i7 İŞLEMCİ",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gürültü bastırma, arka plan bulanıklaştırma",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "En çok yaptığınız işlere öncelik vermek için otomatik olarak uyarlanan performans",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Akıcı, yüksek kaliteli web aramaları",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Kullanıma hazır, donanım tabanlı güvenlik koruması",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Cihazları ve verileri fiziksel kurcalama saldırılarına karşı koruma",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Küçük işletmeler, büyük iş çözümlerini hak eder.",
                "tr_gen_core_title": "13. Nesil Intel®&nbsp;Core™&nbsp;i9 İşlemci",
                "tr_gen_core_title_uppercase": "13. NESİL INTEL® CORE™ i9 İŞLEMCİ",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "BU İŞLEMCİ DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Seçili cihaz",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Gürültü bastırma, arka plan bulanıklaştırma",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "En çok yaptığınız işlere öncelik vermek için otomatik olarak uyarlanan performans",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Akıcı, yüksek kaliteli web aramaları",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Kullanıma hazır, donanım tabanlı güvenlik koruması",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Cihazları ve verileri fiziksel kurcalama saldırılarına karşı koruma",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "İşletmelere yönelik yeni nesil yapay zekayı deneyimleyin",
                "tr_title": "Intel® Core™ Ultra&nbsp;[XX] işlemci ve Intel&nbsp;vPro® kullanarak geleceğe hazır&nbsp;olun",
                "tr_gen_core_title": "Intel® Core™ Ultra [XX] işlemci",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "INTEL’İN YAPAY ZEKA BİLGİSAYARLARI İLE İŞLETMENİZE GÜÇ KATIN",
                    "tr_badgeSubTitle": "Harika deneyimler sunan güçlü bilgisayarlar, iş üretkenliğinin temelini oluşturur. Intel vPro®; doğru görevi, doğru zamanda doğru motora göndererek çeşitli ve karmaşık iş yükleri için üstün güç verimliliği sunan yeni mimarisi sayesinde iş yükleri ve uygulamalar genelinde çalışan verimliliğini ve performansını artırır.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "KULLANIMA HAZIR ÇOK KATMANLI GÜVENLİK ÖZELLİKLERİ",
                    "tr_badgeSubTitle": "Yeni ve gelişmekte olan tehditler, güvenliğe yönelik bütüncül bir yaklaşım gerektirir. Intel vPro®, donanım seviyesinde ve yığın genelinde koruma sağlar; böylece işletmenizin daha iyi korunması sağlanır. Yapay zeka destekli tehdit algılama özellikleri de dahil olmak üzere kullanıma hazır bu benzersiz güvenlik önemleri; kullanıcılarınızı, verilerinizi ve işletmenizi korumaya yardımcı olur.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "KULLANICILARIN NEREDEYSE HER YERDEN ÜRETKEN OLMASINI SAĞLAYAN YERLEŞİK ARAÇLAR",
                    "tr_badgeSubTitle": "Ofisler artık her yerde; bilgisayarlar da öyle. Intel vPro®; işletmelerin bilgisayar filosu yönetim yükünü hafifletmesine, destek süreçlerini basitleştirmesine ve son kullanıcı deneyimlerini geliştirmesine yardımcı olurken sürdürülebilir bilgi işlem uygulamalarına olanak tanıyan donanım tabanlı modern yönetim özellikleri sunar.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Harika bir PC'den fazlası, o bir AI PC",
                "tr_subtitle1": "GÜCÜNÜ INTEL® CORE™ ULTRA İŞLEMCİLERDEN (SERİ 2) ALIYOR",
                "tr_subtitle2": "Çok daha fazlası Intel AI PC'nizde",
                "tr_subtitle": "Intel'in en verimli, güvenli ve yüksek performanslı mobil işlemcisi<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 işlemci (Seri&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "Daha hızlı içerik oluşturmak için yapay zeka araçlarıyla <span class='spec-heading'>daha yaratıcı</span>"
                  },
                  {
                    "tr_title": "Zamandan tasarruf sağlayan yapay zeka desteği ile <span class='spec-heading'>daha üretken</span>"
                  },
                  {
                    "tr_title": "Dahili veri koruma ile <span class='spec-heading'>daha güvenli</span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Harika bir PC'den fazlası, o bir AI PC",
                "tr_subtitle1": "GÜCÜNÜ INTEL® CORE™ ULTRA İŞLEMCİLERDEN (SERİ 2) ALIYOR",
                "tr_subtitle2": "Çok daha fazlası Intel AI PC'nizde",
                "tr_subtitle": "Intel'in en verimli, güvenli ve yüksek performanslı mobil işlemcisi<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 işlemci (Seri&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "Daha hızlı içerik oluşturmak için yapay zeka araçlarıyla <span class='spec-heading'>daha yaratıcı</span>"
                  },
                  {
                    "tr_title": "Zamandan tasarruf sağlayan yapay zeka desteği ile <span class='spec-heading'>daha üretken</span>"
                  },
                  {
                    "tr_title": "Dahili veri koruma ile <span class='spec-heading'>daha güvenli</span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Harika bir PC'den fazlası, o bir AI PC",
                "tr_subtitle1": "GÜCÜNÜ INTEL® CORE™ ULTRA İŞLEMCİLERDEN (SERİ 2) ALIYOR",
                "tr_subtitle2": "Çok daha fazlası Intel AI PC'nizde",
                "tr_subtitle": "Intel'in en verimli, güvenli ve yüksek performanslı mobil işlemcisi<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 işlemci (Seri&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşlemci",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Harika bir PC'den fazlası, o bir AI PC",
                "tr_subtitle1": "GÜCÜNÜ INTEL® CORE™ ULTRA İŞLEMCİLERDEN (SERİ 2) ALIYOR",
                "tr_subtitle2": "Çok daha fazlası Intel AI PC'nizde",
                "tr_subtitle": "Intel'in en verimli, güvenli ve yüksek performanslı mobil işlemcisi<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 işlemci (Seri&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_tileTitle1": "Özel Grafikler",
                "tr_title": "GRAFİK İŞLEME BİRİMİ NEDİR?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Grafik işleme birimi ya da kısa adıyla GPU, ekranınızda gördüğünüz görselleri oluşturur.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Entegre GPU",
                    "tr_badgeSubTitle": "Entegre GPU, bilgisayarın geri kalanıyla aynı RAM'i kullanır; böylece enerji bakımından daha verimli hale gelir.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Harici GPU",
                    "tr_badgeSubTitle": "Kendi RAM’ine sahip olduğu ve olağanüstü grafikler oluşturmak için ayrılan diğer kaynakları kullandığı için en güçlü GPU sınıfıdır.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Hibrit GPU",
                    "tr_badgeSubTitle": "Kendi RAM’ine sahip olduğu ve olağanüstü grafikler oluşturmak için ayrılan diğer kaynakları kullandığı için en güçlü GPU sınıfıdır.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Kolaylaştırılmış fotoğraf ve video düzenleme",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "4K kalitesinde video izleme",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Popüler oyunları çarpıcı ayrıntılarıyla oynama",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Çift GPU",
                    "tr_badgeSubTitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafiklerine sahip sistemler gibi bazı sistemler, grafik iş yükünü dağıtmak üzere hem işlemci üzerindeki GPU'nun hem de harici GPU'nun avantajlarından faydalanabilir.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Entegre GPU",
                    "tr_badgeSubTitle": "Entegre GPU, ana işlemci ya da CPU ile aynı çip üzerine yerleştirilir. Bu tür bir GPU, bilgisayarın geri kalan bileşenleriyle aynı RAM’i ve aynı kaynakları kullandığından, enerjiyi daha verimli kullanan, daha ince ve daha hafif bir cihaz tasarlanmasını mümkün kılar.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Özel GPU",
                    "tr_badgeSubTitle": "Kendi RAM’ine sahip olduğu ve olağanüstü grafikler oluşturmak için ayrılan diğer kaynakları kullandığı için en güçlü GPU sınıfıdır.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Standart",
                    "tr_badgeSubTitle": "Maks. 4&nbsp;GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Rekabetçi",
                    "tr_badgeSubTitle": "Maks. 8&nbsp;GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "8 GB’tan yüksek VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "Yoğun grafik gücü",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "En güçlü grafik işleme performansı, güzel ve canlı görseller ile akıcı bir animasyon oluşturmaya özel olarak odaklanan bir grafik kartı gerektirir.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "Zengin ve canlı dahili grafikler",
                "tr_pretitle": "Intel® Iris® Plus Grafikleri",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Iris® Plus grafikleriyle ekstra grafik kartına ihtiyaç duymadan 4K HDR videoların ve tam HD oyunların keyfini çıkarabilirsiniz.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "Çarpıcı görseller, kusursuz performans",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Grafikleri, içerik oluşturucular için akıcı tasarım sürecini ve zengin oyun deneyimlerini desteklemek için devrim niteliğinde entegre grafik performansı sunar.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "Intel® Iris™ Pro grafikleri",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "X<sup class='small_text_sup_xe'>e</sup> mimarisine sahip Intel® UHD Grafikleri",
                "tr_pretitle": "Etkileyici görsel deneyimler",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Daha kolay hale gelen fotoğraf ve video düzenleme deneyimini yaşayın, video içeriklerini 4K görüntü kalitesiyle izleyin ve popüler oyunları çarpıcı ayrıntılarıyla oynayın.",
                "tr_photo_text": "Kolaylaştırılmış fotoğraf ve video düzenleme",
                "tr_video_text": "4K kalitesinde video izleme",
                "tr_game_text": "Popüler oyunları çarpıcı ayrıntılarıyla oynama",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "GÜZEL GÖRÜNTÜLERDEN ÇOK DAHA FAZLASI",
                "tr_subtitle": "İşlemci (veya CPU) ile yakın ilişki içinde çalışan grafik kartı (veya GPU), oyunlarınızın nasıl çalıştığını ve nasıl göründüğünü belirler. Yalnızca grafik üretmeye ayrılan VRAM (diğer adıyla video RAM), GPU’nuzun durumunu öğrenmenin hızlı bir yoludur.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "YOĞUN OYUN GRAFİKLERİ",
                "tr_subtitle": "En güçlü grafik işleme performansı, özellikle güzel ve canlı görseller ile akıcı bir animasyon oluşturmaya odaklanan ayrı bir grafik kartı gerektirir",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "YOĞUN OYUN GRAFİKLERİ",
                "tr_subtitle": "En güçlü grafik işleme performansı, özellikle güzel ve canlı görseller ile akıcı bir animasyon oluşturmaya odaklanan ayrı bir grafik kartı gerektirir",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "GÜZEL GÖRÜNTÜLERDEN ÇOK DAHA FAZLASI",
                "tr_subtitle": "İşlemci (veya CPU) ile yakın ilişki içinde çalışan grafik kartı (veya GPU), oyunlarınızın nasıl çalıştığını ve nasıl göründüğünü belirler. Yalnızca grafik üretmeye ayrılan VRAM (diğer adıyla video RAM), GPU’nuzun durumunu öğrenmenin hızlı bir yoludur.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "Yoğun grafik gücü",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "En güçlü grafik işleme performansı, güzel ve canlı görseller ile akıcı bir animasyon oluşturmaya özel olarak odaklanan bir grafik kartı gerektirir.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "YOĞUN OYUN GRAFİKLERİ",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "En güçlü grafik işleme performansı, güzel ve canlı görseller ile akıcı bir animasyon oluşturmaya özel olarak odaklanan bir grafik kartı gerektirir.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "En güçlü grafik işleme performansı, özellikle güzel ve canlı görseller ile akıcı bir animasyon oluşturmaya odaklanan ayrı bir grafik kartı gerektirir",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Oyununuzun minimum gerekliliklerini karşılamak için bol grafik gücü sunar.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Yarışma düzeyinde oyun deneyimi talep eden üst düzey oyun tutkunlarına yöneliktir",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Bilgisayarlarını aynı zamanda ciddi içerik oluşturma işleri için kullanan en ileri düzey oyun tutkunlarına yöneliktir",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Capcanlı video ve etkileyici oyun deneyimi",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Intel® HD Grafikleri ile yüksek çözünürlüklü ekranınızdan en iyi şekilde yararlanın, zengin bir multimedya deneyiminin ve entegre grafik işleme özelliklerinin keyfini çıkarın.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Canlı video ve görüntülere güç verir",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Veri akışı ile izlediğiniz videolardan ve fotoğraflarınızdan daha fazla detay ve daha parlak renkler elde edin.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "Muhteşem içerik oluşturma ve oyun deneyimi",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleri",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleri; sürükleyici eğlence ve gelişmiş içerik oluşturma özelliklerinden ana akım oyun deneyimine kadar ezber bozan grafik performansını ince ve hafif bilgisayarla buluşturuyor.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_pretitle": "YOĞUN OYUN GRAFİKLERİ",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafikleri",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "En güçlü grafik işleme performansı, özellikle güzel ve canlı görseller ile akıcı bir animasyon oluşturmaya odaklanan ayrı bir grafik kartı gerektirir",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "Yaratıcılığınızı serbest bırakın",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX grafikleri",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "İşlemci üzerindeki GPU'nun ve ilk Intel® harici grafik motorunun gücünü bir araya getiren teknolojiyle yaratıcı projelerinizi ışık hızında tamamlayın ve kendinizi envai çeşit oyuna kaptırın.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX grafikleri",
                "tr_pretitle": "YOĞUN OYUN GRAFİKLERİ",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "En güçlü grafik işleme performansı, özellikle güzel ve canlı görseller ile akıcı bir animasyon oluşturmaya odaklanan ayrı bir grafik kartı gerektirir",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Grafikler",
                "tr_title": "Muhteşem entegre grafikler",
                "tr_subtitle": "Oyunlarınızın, filmlerin ve çevrimiçi videoların keyfini büyüleyici ayrıntılarla ve netlikle çıkarın.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikler",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafikler",
                    "tr_specText": "İş akışlarınızı hızlandırın"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafikler",
                    "tr_specText": "Oyununuza olağanüstü güç katın"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafikler",
                    "tr_specText": "Oyununuza olağanüstü güç katın"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafikler",
                    "tr_specText": "Oyunda seviye atlayın"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "YARATICILIĞINIZI SERBEST BIRAKIN",
                    "tr_specText": "<strong>Sektörde bir ilk olan AV1 donanım kodlama</strong> ve yapay zeka ile hızlandırılmış içerik oluşturma gibi en kapsamlı medya özellikleriyle, gittiğiniz her yerde üretkenliğinizi artırın.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "YARATICILIĞINIZI SERBEST BIRAKIN",
                    "tr_specText": "<strong>Sektörde bir ilk olan AV1 donanımı kodlama</strong> ve yapay zeka ile hızlandırılmış içerik oluşturma gibi en kapsamlı medya özellikleriyle üretkenliğinizi artırın.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "YARATICILIĞINIZI SERBEST BIRAKIN",
                    "tr_specText": "<strong>Sektörde bir ilk olan AV1 donanım kodlama</strong> ve gelişmiş <strong>X<sup>e</sup> Medya Motoru</strong> sayesinde, son teknoloji yapay zeka ile hızlandırılmış içerik oluşturma deneyimini yaşayın.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "YARATICILIĞINIZI SERBEST BIRAKIN",
                    "tr_specText": "<strong>Sektörde bir ilk olan AV1 donanım kodlama</strong> ve gelişmiş <strong>X<sup>e</sup> Medya Motoru</strong> sayesinde, son teknoloji yapay zeka ile hızlandırılmış içerik oluşturma deneyimini yaşayın.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "YARATICILIĞINIZI SERBEST BIRAKIN",
                    "tr_specText": "<strong>Sektörde bir ilk olan AV1 donanım kodlama</strong> ve gelişmiş <strong>X<sup>e</sup> Medya Motoru</strong> sayesinde, son teknoloji yapay zeka ile hızlandırılmış içerik oluşturma deneyimini yaşayın.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "TAŞINABİLİRLİK PERFORMANSLA BULUŞUYOR",
                    "tr_specText": "Hareket halindeyken en yeni oyunları oynayın ve modern oyun özellikleriyle süper güçlü performans ve üstün görüntü kalitesine sahip olun. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Daha yüksek performans ve akıcı oyun deneyimi için Intel® yapay zeka ile geliştirilmiş ölçekleme teknolojisi. <br/><br/><strong>DirectX* 12 Ultimate </strong> Desteği: Işın izleme ve değişken oranlı gölgelendirme gibi grafik teknolojileriyle oyun deneyiminizi yeni bir gerçekçilik seviyesine taşıyın."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "OYUNLARINIZIN KONTROLÜ SİZDE OLSUN",
                    "tr_specText": "<strong>Intel® Arc™ Denetim Paneli</strong>, sezgisel ve modern bir düzenle deneyiminizi geliştirmenize olanak tanır. Sürücü güncellemelerini sorunsuzca alın, oyunlarınızı optimize edin ve daha fazlasını yapın."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "OYUNLARINIZIN KONTROLÜ SİZDE OLSUN",
                    "tr_specText": "<strong>Intel® Arc™ Denetim Paneli</strong>, sezgisel ve modern bir düzenle deneyiminizi geliştirmenize olanak tanır. Sürücü güncellemelerini sorunsuzca alın, oyunlarınızı optimize edin ve daha fazlasını yapın."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "OYUNLARINIZIN KONTROLÜ SİZDE OLSUN",
                    "tr_specText": "<strong>Intel® Arc™ Denetim Paneli</strong> yazılımıyla sürücü güncellemelerini sorunsuzca alın ve en sevdiğiniz oyunların çoğunu optimize edin."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "INTEL® PLATFORMUNUZDAN EN BÜYÜK FAYDAYI SAĞLAYIN",
                    "tr_specText": "İçerik oluşturma, oyun oynama ve canlı yayın deneyiminizi iyileştirmek üzere <strong>Intel® Deep Link Teknolojisinin</strong> gücünden yararlanmak için uyumlu Intel®&nbsp;Core™ işlemcilerle eşleştirin."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "INTEL® SİSTEMİNİZDEN EN İYİ ŞEKİLDE FAYDALANIN",
                    "tr_specText": "İçerik oluşturma, oyun oynama ve canlı yayın deneyiminizi iyileştirmek üzere <strong>Intel® Deep Link Teknolojisinin</strong> gücünden yararlanmak için uyumlu Intel®&nbsp;Core™ işlemcilerle eşleştirin."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "INTEL® PLATFORMUNUZDAN EN BÜYÜK FAYDAYI SAĞLAYIN",
                    "tr_specText": "İçerik oluşturma, oyun oynama ve canlı yayın deneyiminizi iyileştirmek üzere <strong>Intel® Deep Link Teknolojisinin</strong> gücünden yararlanmak için uyumlu Intel®&nbsp;Core™ işlemcilerle eşleştirin."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "INTEL® PLATFORMUNUZDAN EN BÜYÜK FAYDAYI SAĞLAYIN",
                    "tr_specText": "İçerik oluşturma, oyun oynama ve canlı yayın deneyiminizi iyileştirmek üzere <strong>Intel® Deep Link Teknolojisinin</strong> gücünden yararlanmak için uyumlu Intel®&nbsp;Core™ işlemcilerle eşleştirin."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "INTEL® SİSTEMİNİZDEN EN İYİ ŞEKİLDE FAYDALANIN",
                    "tr_specText": "İçerik oluşturma, oyun oynama ve canlı yayın deneyiminizi iyileştirmek üzere <strong>Intel® Deep Link Teknolojisinin</strong> gücünden yararlanmak için uyumlu Intel®&nbsp;Core™ işlemcilerle eşleştirin."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "SAHNENİN MERKEZİNDE YER ALIN",
                    "tr_specText": "<strong>Intel® Arc™ Denetim Paneli</strong> yazılımı sayesinde etkinleştirilen gelişmiş canlı yayın teknolojileri ile sorunsuz şekilde yayın yapın."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "SAHNENİN MERKEZİNDE YER ALIN",
                    "tr_specText": "<strong>Intel® Arc™ Denetim Paneli</strong> yazılımı sayesinde etkinleştirilen gelişmiş canlı yayın teknolojileri ile sorunsuz şekilde yayın yapın."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "SÜRÜKLEYİCİ, YÜKSEK PERFORMANSLI OYUN DENEYİMİNİ YAŞAYIN",
                    "tr_specText": "Yüksek çözünürlüklerde ve yüksek yenileme hızlarında en yeni AAA düzeyi oyunlarla en iyi performansınıza öncelik verin. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Daha yüksek performans ve akıcı oyun deneyimi için Intel® yapay zeka ile geliştirilmiş ölçekleme teknolojisi. <br/><br/><strong>DirectX* 12 Ultimate </strong> Desteği: Işın izleme ve değişken oranlı gölgelendirme gibi grafik teknolojileriyle oyun deneyiminizi yeni bir gerçekçilik seviyesine taşıyın."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "SÜRÜKLEYİCİ, YÜKSEK PERFORMANSLI OYUN DENEYİMİNİ YAŞAYIN",
                    "tr_specText": "Yüksek çözünürlüklerde ve yüksek yenileme hızlarında en yeni AAA düzeyi oyunlarla en iyi performansınıza öncelik verin. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Daha yüksek performans ve akıcı oyun deneyimi için Intel® yapay zeka ile geliştirilmiş ölçekleme teknolojisi. <br/><br/><strong>DirectX* 12 Ultimate </strong> Desteği: Işın izleme ve değişken oranlı gölgelendirme gibi grafik teknolojileriyle oyun deneyiminizi yeni bir gerçekçilik seviyesine taşıyın."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "OYUNLARINIZA HIZ VERİN",
                    "tr_specText": "Oyun deneyiminizi bir üst seviyeye çıkarın: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling): </strong>Daha yüksek performans ve akıcı oyun deneyimi için Intel® yapay zeka ile geliştirilmiş ölçekleme teknolojisi<br/><br/><strong>DirectX* 12 Ultimate </strong> Desteği: Işın izleme ve değişken oranlı gölgelendirme gibi grafik teknolojileriyle oyun deneyiminizi yeni bir gerçekçilik seviyesine taşıyın."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "GELİŞMİŞ VE HIZLI TEMPOLU OYUN OYNAMA DENEYİMİNİ KEŞFEDİN",
                    "tr_specText": "AAA düzeyi birçok popüler oyunda kusursuz oyun oynama deneyimini keşfedin. Yüksek performans gerektiren e-spor nişancı oyunlarından popüler ve hızlı tempolu gerçek zamanlı strateji oyunlarına kadar birçok oyunda yüksek yenileme hızlarına ulaşın. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Daha yüksek performans ve akıcı oyun deneyimi için Intel® yapay zeka ile geliştirilmiş ölçekleme teknolojisi. <br/><br/><strong>DirectX* 12 Ultimate </strong> Desteği: Işın izleme ve değişken oranlı gölgelendirme gibi grafik teknolojileriyle oyun deneyiminizi yeni bir gerçekçilik seviyesine taşıyın."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "SÜRÜKLEYİCİ, YÜKSEK PERFORMANSLI OYUN DENEYİMİNİ YAŞAYIN",
                    "tr_specText": "Yüksek çözünürlüklerde ve yüksek yenileme hızlarında en yeni AAA düzeyi oyunlarla en iyi performansınıza öncelik verin. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Daha yüksek performans ve akıcı oyun deneyimi için Intel® yapay zeka ile geliştirilmiş ölçekleme teknolojisi. <br/><br/><strong>DirectX* 12 Ultimate </strong> Desteği: Işın izleme ve değişken oranlı gölgelendirme gibi grafik teknolojileriyle oyun deneyiminizi yeni bir gerçekçilik seviyesine taşıyın."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafikler",
                    "tr_specText": "Herkese uygun bir grafik evreni"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "KARELERİNİZİ HIZLANDIRIN, OYUNLARINIZI HIZLANDIRIN",
                    "tr_specText": "Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling teknolojisi (X<sup class='small_text_sup_xe'>e</sup>SS), üstün performans ve yüksek görüntü kalitesi için yapay zeka ile geliştirilmiş ölçeklendirme sayesinde oyun deneyiminizi bir üst seviyeye taşır. X<sup class='small_text_sup_xe'>e</sup>SS, Intel® Arc™ grafikleri için optimize edilmiştir; bu sayede Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX) donanım hızlandırmasından en iyi şekilde yararlanabilirsiniz."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "OLAĞANÜSTÜ GÖRSELLER SİZİNLE",
                    "tr_specText": "Tüm Intel® Arc™ grafiklerinde DirectX* 12 Ultimate desteği ile yeni nesil oyun deneyiminin temellerini oluşturan ışın izleme, değişken hızda gölgelendirme, mesh gölgelendirme ve örnekleme geri bildirimi gibi en yeni teknolojiler sayesinde çarpıcı ve sürükleyici bir oyun deneyiminin keyfini çıkaracaksınız."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "SORUNSUZ FOTOĞRAF, VİDEO VE MEDYA OLUŞTURMA",
                    "tr_specText": "Yapay zeka ile güçlendirilmiş ve Intel® teknolojisi ile hızlandırılmış ileri düzey bir medya motoru kullanarak hayal gücünüzü serbest bırakın ve zengin içerik oluşturma özellikleri sayesinde kitleleri büyüleyin. En gelişmiş AV1 video kodlama özelliklerini takip ederken önde gelen tüm mevcut medya formatlarını destekleyen grafikler sayesinde muhteşem içerikler oluşturun."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "EN YENİ YAPAY ZEKA OLUŞTURMA VE OYUN DENEYİMİNİ YAŞAYIN",
                    "tr_specText": "Intel® Arc™ grafikleri, gelişmiş içerik oluşturmadan sürükleyici oyun deneyimine kadar güçlü yapay zeka özelliklerini ortaya çıkarır. Metinden görüntü oluşturma ve yapay zeka ile hızlandırılmış video düzenleme dahil olmak üzere günümüzün gelişmiş içerik oluşturma yazılımından en iyi şekilde yararlanın. Ayrıca yapay zeka ile hızlandırılmış X<sup class='small_text_sup_xe'>e</sup>SS ölçeklendirme ile yüksek çözünürlüklü oyun deneyiminin keyfini çıkarın."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Grafikler",
                    "tr_specText": "Profesyonel grafikler burada başlıyor"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "HAREKET HALİNDEKİ İÇERİK OLUŞTURUCULAR, TASARIMCILAR VE MÜHENDİSLER İÇİN MÜKEMMEL",
                    "tr_specText": "Belirli H-serisi Intel® Core™ Ultra işlemcilerde bulunan yerleşik Intel® Arc™ Pro grafikleri, daha fazla güvenilirlik için Bağımsız Yazılım Sağlayıcısı (ISV) sertifikaları ve hareket halindeyken çarpıcı içerik oluşturma performansı için ışın izleme donanım hızlandırması ile 2 kat daha iyi grafik performansı sunar.<br/><br/><span class='expandable_heading'>Yazılım sertifikaları<br/></span><br/>Intel, yıllar boyunca yüzlerce yazılım şirketi ile birlikte çalıştı ve bu eşsiz deneyim, Intel® Arc™ Pro A-serisi grafik kartlarında kullanıldı. Sertifikalar sizin için olduğu kadar bizim için de önemli."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "YAPAY ZEKAYI ULAŞILABİLİR KILMA",
                    "tr_specText": "Bu GPU serisi, yerleşik özel yapay zeka hızlandırma ile uyumlu yapay zeka araçlarınız için ihtiyacınız olduğunda sağlanan destek de dahil olmak üzere geniş bir üstün teknoloji yelpazesi içerir."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "YÜKSEK MALİYET OLMADAN IŞIN İZLEME",
                    "tr_specText": "Grafik kartlarının yalnızca pikselleri ekrana göndermekten daha fazlasını yapması, aynı zamanda güzel görüntülerin oluşturulmasını hızlandırması gerekir. Intel® Arc™ Pro A-serisi grafiklerinde yerleşik olarak bulunan ışın izleme donanımı teknolojisi, uyumlu yazılımınızın bu yeni profesyonel iş akışlarını keşfetmesine olanak tanır. Işın izleme özelliğinin genellikle yalnızca pahalı grafiklerde bulunduğu düşünülür ancak Intel'in en yeni profesyonel grafik yelpazesi bu algıyı kırıyor."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "SÜRÜKLEYİCİ EKRANLARLA İÇERİK OLUŞTURUCULARI DESTEKLEME",
                    "tr_specText": "Tüm Intel® Arc™ grafiklerinde DirectX* 12 Ultimate desteği ile yeni nesil oyun deneyiminin temellerini oluşturan ışın izleme, değişken hızda gölgelendirme, mesh gölgelendirme ve örnekleme geri bildirimi gibi en yeni teknolojiler sayesinde çarpıcı ve sürükleyici bir oyun deneyiminin keyfini çıkaracaksınız."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "DİNAMİK OLARAK OPTİMİZE EDİLMİŞ GÖRÜNTÜLER",
                    "tr_specText": "Dolby Vision*, Pro GPU'lar üzerinde özel donanım kod çözme özelliği sayesinde uyumlu ekranınıza daha fazla derinlik, daha keskin kontrast ve daha fazla renk eklemenize yardımcı olur. Dolby Vision*, her seferinde çarpıcı görseller sunmak için hizmetinize, ekranınıza ve platformunuza dayalı olarak görüntü kalitesini dinamik olarak optimize ederek HDR teknolojisinin tüm potansiyelini açığa çıkarır."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "UZMANLAR TARAFINDAN DOĞRULANMIŞ YAZILIM",
                    "tr_specText": "Intel, yıllar boyunca yüzlerce yazılım şirketi ile birlikte çalıştı ve bu eşsiz deneyim, profesyonel Intel® Arc™ Pro A-serisi grafikleri ürün yelpazesinde kullanıldı. Sertifikalar ve yazılım iş akışı nitelikleri, sizin için olduğu kadar bizim için de önemli."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "YÜKSEK MALİYET OLMADAN IŞIN İZLEME",
                    "tr_specText": "Grafik kartlarının yalnızca pikselleri ekrana göndermekten daha fazlasını yapması, aynı zamanda güzel görüntülerin oluşturulmasını hızlandırması gerekir. Intel® Arc™ Pro A-serisi grafiklerinde yerleşik olarak bulunan ışın izleme donanımı teknolojisi, uyumlu yazılımınızın bu yeni profesyonel iş akışlarını keşfetmesine olanak tanır. Işın izleme özelliğinin genellikle yalnızca pahalı grafiklerde bulunduğu düşünülür ancak Intel'in en yeni profesyonel grafik yelpazesi bu algıyı kırıyor."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "TEK BİR KÜÇÜK MOBİL GPU İLE BİRDEN FAZLA BÜYÜK EKRAN",
                    "tr_specText": "Dizüstü bilgisayarlara yönelik Intel® Arc™ Pro grafikleri, iş akışı verimliliğinizi artırmak için dört adede kadar ultra büyük ekranı destekleyebilir. Yüksek dinamik aralık (HDR) desteğini de eklediğinizde sosyal medyaya hitap edecek sıradaki evinizde veya ofisinizde çok ekranlı bir kurulum için ihtiyacınız olan her şeye sahip olursunuz."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "TAM BELLEK BANT GENİŞLİĞİ POTANSİYELİ",
                    "tr_specText": "Grafik kartınızın bant genişliği, bir iş istasyonunda önemli bir husustur. Hata yaparsanız profesyonel iş akışlarını yavaşlatabilirsiniz, bu nedenle Intel® Arc™ Pro A-serisi GPU'nun geriye dönük uyumluluktan ödün vermeden PCIe* 4.0 desteği verdiğinden emin olduk. Grafik belleği, yüksek bant genişliği hızıyla daha da güçlendirilerek proje verilerine yazılımınız tarafından daha da hızlı erişilmesini sağlar."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "HER KAREYE HAYAT VERİN",
                    "tr_specText": "<span class='expandable_heading'>Yapay zeka ve Intel gücü.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS, hiçbir karenin güzelliğinden ödün vermeden performansınızı artıran, yapay zeka ile geliştirilmiş bir ölçeklendirme teknolojisidir; böylece oyun deneyiminizi olması gerektiği gibi yaşayabilirsiniz."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "GERÇEK ZAMANLI.<br/> GERÇEKTEN SÜRÜKLEYİCİ.<br/> GERÇEKLİĞİN EN İYİSİ.",
                    "tr_specText": "Daha gerçekçi dünyalar sizi bekliyor. Yerleşik ışın izleme özelliği ile gerçekçi gölgeler ve yansımalardan ustaca oluşturulmuş aydınlatmaya kadar her şeyi deneyimleyebilirsiniz. Başka bir deyişle, kaçırmak istemeyeceğiniz görsel bir oyun deneyimi."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "EN İYİ OYUN PERFORMANSINIZI EN İYİ ŞEKİLDE PAYLAŞIN",
                    "tr_specText": "Kitlenize hak ettikleri kusursuz oyun deneyimini sunun. Intel® Arc™ grafiklerinin donanım ile hızlandırılmış AV1 kodlama özellikleri sayesinde kitleniz her hamlenizle büyülenecek. Aynı bit hızında daha yüksek görsel kalitesi, bant genişliğini korurken en iyi şekilde canlı yayın yapmanıza olanak verir."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Tüm içerik oluşturucular, hoş geldiniz!",
                    "tr_specText": "Yeni ve gelişmiş yapay zeka deneyimleri parmaklarınızın ucunda. Intel® Arc™ A-serisi grafiklerde yerleşik olarak bulunan gelişmiş yapay zeka hızlandırıcılar ile birçok uygulamada beklemeye daha az, içerik oluşturmaya daha fazla vakit ayırın.<br/><br/>Video oluşturma sürecinizi bir üst seviyeye çıkarın ve Intel® Arc™ grafiklerine yerleşik gelişmiş AV1 donanım özellikleriyle desteklenen yüksek kaliteli, depolama açısından verimli AV1 video ile öncülük edin."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşletim Sistemi",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "İşlerinizi halletmek için gerekli olan her şey",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "İnce, hafif ve dayanıklı bir tasarımda güçlü performans elde edin. Uzun pil ömrü, güzel dokunmatik ekranlar* ve en yeni Katı Hal Sürücüleri (SSD) sayesinde aradıklarınızı bir Windows 10 bilgisayarda bulacaksınız.",
                "tr_noteSection": "*Donanıma bağlı",
                "titleArray": [
                  {
                    "tr_title": "Az ama öz",
                    "tr_subtitle": "Günümüzün bilgisayarları genellikle daha az güç tüketir. Bu sayede, ortalama sekiz saat video oynatımı sunacak şekilde daha fazla pil ömrü elde edersiniz.**",
                    "tr_noteSection": "**Pil ömrü; ayarlara, kullanıma ve diğer faktörlere göre önemli ölçüde değişiklik gösterir."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşletim Sistemi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "İŞLERİNİZİ HALLETMEK İÇİN GEREKLİ OLAN HER ŞEY",
                "tr_title": "Windows* 10",
                "tr_subtitle": "İnce, hafif ve dayanıklı bir tasarımda güçlü performans elde edin. Uzun pil ömrü, güzel dokunmatik ekranlar* ve en yeni Katı Hal Sürücüleri (SSD) sayesinde aradıklarınızı bir Windows 10 bilgisayarda bulacaksınız.",
                "tr_noteSection": "*Donanıma bağlı",
                "titleArray": [
                  {
                    "tr_title": "AZ AMA ÖZ",
                    "tr_subtitle": "Günümüzün bilgisayarları genellikle daha az güç tüketir. Bu sayede, ortalama sekiz saat video oynatımı sunacak şekilde daha fazla pil ömrü elde edersiniz.**",
                    "tr_noteSection": "**Pil ömrü; ayarlara, kullanıma ve diğer faktörlere göre önemli ölçüde değişiklik gösterir."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşletim Sistemi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Güvenlik ve performans için en iyi şekilde geliştirildi",
                "tr_pretitle": "S modunda Windows* 10",
                "tr_subtitle": "Windows* 10'u S modunda kullanırken cihazınızın güvenli olduğunu bilmenin rahatlığıyla çalışmanızı, oyun oynamanızı ve Internet'te gezinmenizi sağlayan ek bir koruma katmanına sahip olursunuz."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşletim Sistemi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "GÜVENLİK VE PERFORMANS İÇİN EN İYİ ŞEKİLDE GELİŞTİRİLDİ",
                "tr_title": "S modunda Windows* 10",
                "tr_subtitle": "Windows* 10'u S modunda kullanırken cihazınızın güvenli olduğunu bilmenin rahatlığıyla çalışmanızı, oyun oynamanızı ve Internet'te gezinmenizi sağlayan ek bir koruma katmanına sahip olursunuz."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşletim Sistemi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Harika şeyler yapın",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Performans, fiyat ve çok fazla seçenek. Yenilikçi 2'si 1 Arada cihazlardan ince ve hafif dizüstü bilgisayarlara, dokunmatik ekranlara ve ayrılabilir klavyelere kadar, bütçeniz ve stiliniz ne olursa olsun size uygun bir Windows* bilgisayar bulabilirsiniz."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşletim Sistemi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "HARİKA ŞEYLER YAPIN",
                "tr_title": "Windows*",
                "tr_subtitle": "Performans, fiyat ve çok fazla seçenek. Yenilikçi 2'si 1 Arada cihazlardan ince ve hafif dizüstü bilgisayarlara, dokunmatik ekranlara ve ayrılabilir klavyelere kadar, bütçeniz ve stiliniz ne olursa olsun size uygun bir Windows* bilgisayar bulabilirsiniz."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşletim Sistemi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "İşletim Sistemi",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bellek",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "NE KADAR RAM'E İHTİYACINIZ VAR?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Günlük - 2 GB'TAN 8 GB'A KADAR RAM",
                    "tr_badgeSubTitle": "Bilgisayarını genellikle sözcük işleme ve internette gezinme uygulamalarıyla kullanan ve tek seferde bir programa odaklananlar için idealdir."
                  },
                  {
                    "tr_badgeTitle": "Güçlü - 8 GB'TAN 16 GB'A KADAR RAM",
                    "tr_badgeSubTitle": "Daha fazla RAM ile uygulamalarınız daha hızlı çalışır ve aynı anda daha fazla uygulama kullanabilirsiniz."
                  },
                  {
                    "tr_badgeTitle": "Gelişmiş - 16 GB'TAN FAZLA RAM",
                    "tr_badgeSubTitle": "Oyun oynama, fotoğraf ya da video düzenleme veya 3D modellemeyle ilgileniyorsanız, bu yoğun programları çalıştırmak için daha fazla RAM'e ihtiyacınız olur."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BELLEK",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "Günlük çoklu görev yürütmeye yönelik [XX]&nbsp;GB RAM",
                "tr_subtitle": "Belleğiniz, sözcük işleme ve internette gezinme gibi temel uygulamaların kusursuz bir şekilde yüklenmesini ve çalışmasını sağlar.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BELLEK",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "Güçlü çoklu görev yürütmeye yönelik [XX]&nbsp;GB RAM",
                "tr_subtitle": "Aynı anda birden çok program kullanıyorsanız, [memory_total] GB bellek bu programlar arasında kolaylıkla geçiş yapmanızı sağlar.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BELLEK",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "Gelişmiş çoklu görev yürütmeye yönelik [XX]&nbsp;GB RAM",
                "tr_subtitle": "Birkaç yoğun programı bilgisayarınız yavaşlamadan aynı anda çalıştırmak için yeterli belleğe sahip olursunuz.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BELLEK",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1] GB bellek = [Value2] GB Intel®&nbsp;Optane™ Bellek + [Value3] GB RAM",
                "tr_subtitle": "Bu bellek çözümleri kombinasyonu, Intel® Optane™ bellek ile hızlandırılmış sürücü performansı ve daha yüksek RAM yanıt hızı elde etmenizi sağlar.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>BELLEK</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ Bellek",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Daha az bekleyin. Sevdiğiniz işlere daha çok zaman ayırın.",
                "tr_subtitle": "Intel® Optane™ bellek sistem belleğinizle birlikte çalıştığında, sık kullandığınız programlara daha hızı erişmenizi sağlar ve bu sayede programlar ihtiyaç duyduğunuz anda derhal başlatılır ve yüklenir.",
                "tr_pretitle": "[XX] GB Intel® Optane™ Bellek",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "HAFIZANIN BİLGİSAYARIMDAKİ İŞLEVİ NEDİR?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Hızlı içerik üretme",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Daha yüksek yanıt hızı sunan oyun performansı",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Kolay çoklu görev yürütme",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Programları daha hızlı açma",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ Bellek",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Oyun deneyiminize hız kazandırın</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ Bellek",
                "tr_pretitle1": "[XX] GB Intel® Optane™ Bellek, oyun deneyiminizi hızlandırır",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Oyun başlatma<br/><span class=\"optane-game\">%98'e kadar daha hızlı</span><br/>Intel® Optane™ bellekli [optane_legal_processor_name] işlemciyle veri sürücüsünden<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Seviyeleri yükleme<br/><span class=\"optane-game\">4,1 kata kadar daha hızlı</span><br/>Intel® Optane™ bellekli [optane_legal_processor_name] işlemciyle veri sürücüsünden<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Intel® Optane™ Bellek",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Oyun deneyiminize hız kazandırın</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ Bellek",
                "tr_pretitle1": "[XX] GB Intel® Optane™ Bellek, oyun deneyiminizi hızlandırır",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Oyun başlatma<br/><span class=\"optane-game\">%98'e kadar daha hızlı</span><br/>Intel® Optane™ bellekli [optane_legal_processor_name] işlemciyle veri sürücüsünden<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Seviyeleri yükleme<br/><span class=\"optane-game\">4,1 kata kadar daha hızlı</span><br/>Intel® Optane™ bellekli [optane_legal_processor_name] işlemciyle veri sürücüsünden<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BELLEK",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1] GB bellek = [Value2] GB Intel®&nbsp;Optane™ Bellek + [Value3] GB RAM",
                "tr_subtitle": "Bu bellek çözümleri kombinasyonu, Intel® Optane™ bellek ile hızlandırılmış sürücü performansı ve daha yüksek RAM yanıt hızı elde etmenizi sağlar.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB RAM ile çoklu görev yürütme",
                    "tr_subtitle": "Aynı anda birden çok program kullanıyorsanız, [memory_total] GB bellek bu programlar arasında kolaylıkla geçiş yapmanızı sağlar."
                  },
                  {
                    "tr_title": "Günlük işleriniz için biçilmiş kaftan",
                    "tr_subtitle": "Belleğiniz, sözcük işleme ve internette gezinme gibi temel uygulamaların kusursuz bir şekilde yüklenmesini ve çalışmasını sağlar."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BELLEK",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB RAM",
                "tr_pretitle": "DAHA FAZLA&nbsp;ÇOKLU GÖREV ÇALIŞTIRIN",
                "tr_subtitle": "RAM, işlemcinizin program dosyalarına erişmesini sağlayarak, CPU’nuzun bu dosyalara daha hızlı erişmesine ve aynı anda birden çok görev üzerinde çalışmasına olanak tanır.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standart bellek",
                    "tr_badgeSubTitle": "8 GB'a kadar RAM"
                  },
                  {
                    "tr_badgeTitle": "Gelişmiş bellek",
                    "tr_badgeSubTitle": "8 GB'ın üzerinde ve 16 GB'a kadar RAM"
                  },
                  {
                    "tr_badgeTitle": "Seçkin bellek",
                    "tr_badgeSubTitle": "16 GB’tan yüksek RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BELLEK",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "Çoklu görev yürütme için [XX] GB",
                "tr_pretitle": "DAHA FAZLA&nbsp;ÇOKLU GÖREV ÇALIŞTIRIN",
                "tr_subtitle": "RAM, işlemcinizin program dosyalarına erişmesini sağlayarak, CPU’nuzun bu dosyalara daha hızlı erişmesine ve aynı anda birden çok görev üzerinde çalışmasına olanak tanır.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standart bellek",
                    "tr_badgeSubTitle": "8 GB'a kadar RAM"
                  },
                  {
                    "tr_badgeTitle": "Gelişmiş bellek",
                    "tr_badgeSubTitle": "8 GB'ın üzerinde ve 16 GB'a kadar RAM"
                  },
                  {
                    "tr_badgeTitle": "Seçkin bellek",
                    "tr_badgeSubTitle": "16 GB’tan yüksek RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "BU BELLEK DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Standart",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Gelişmiş",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Üst düzey",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bellek",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "BU BELLEK DİĞERLERİNE KIYASLA NELER SUNAR?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "BU BELLEK DİĞERLERİNE KIYASLA NELER SUNAR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Seçili cihaz",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Her gün",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Güçlü",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Gelişmiş",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Depolama",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "BİLGİSAYARINIZ NE KADAR VERİ DEPOLAYABİLİR?",
                "tr_subtitle": "Tahmin etmenize gerek yok. Bu bilgisayarda tam olarak ne kadar veri depolayabileceğinizi göstereceğiz.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class=\"storage-standard\">[num_songs] şarkı<sup>†</sup></span> <br/><span class='storage-preTile'>içeren bir koleksiyon oluşturun</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>şarkı başına ortalama 5 MB boyuta göre",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class=\"storage-standard\">[num_games] oyun<sup>†</sup></span> <br/><span class='storage-preTile'>içeren bir koleksiyon oluşturun</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>oyun başına ortalama 40 GB boyuta göre",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class=\"storage-standard\">[num_videos] video<sup>§</sup></span> <br/><span class='storage-preTile'>depolayın</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>video başına ortalama 5 GB boyuta göre",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class=\"storage-standard\">[num_photos] fotoğraf<sup>^</sup></span> <br/><span class='storage-preTile'>çekin ve saklayın</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>fotoğraf başına ortalama 4 MB boyuta göre",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity] GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity] GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory] GB Intel® Optane™ Bellek + [convertedSSD] GB SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "DEPOLAMA",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity] GB SSD Depolama",
                "tr_preTitle1": "[capacity] TB SSD Depolama",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Bu bilgisayar, [capacity] GB depolamaya sahip bir SSD içerir. Başka bir deyişle, çok sayıda fotoğraf, video ve programı kaydedebilir ve hepsine sabit disk sürücüde olduğundan çok daha hızlı erişebilirsiniz.",
                    "tr_title": "Bilgisayarınızda her şeye yer var"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Bu bilgisayar, [capacity] TB depolamaya sahip bir SSD içerir. Başka bir deyişle, çok sayıda fotoğraf, video ve programı kaydedebilir ve hepsine sabit disk sürücüde olduğundan çok daha hızlı erişebilirsiniz.",
                    "tr_title": "Bu cihaz, etkileyici bir depolama alanına sahiptir"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DEPOLAMA</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DEPOLAMA</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "DEPOLAMA",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity] GB HDD Depolama",
                "tr_preTitle1": "[capacity] TB HDD Depolama",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Sabit disk sürücüler, bilgisayarında büyük bir depolama alanı isteyen herkes için iyi bir seçenektir.",
                    "tr_title": "Bilgisayarınızda her şeye yer var"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Sabit disk sürücüler, bilgisayarında büyük bir depolama alanı isteyen herkes için iyi bir seçenektir.",
                    "tr_title": "Bu cihaz, etkileyici bir depolama alanına sahiptir"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>DEPOLAMA</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "DEPOLAMA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "BİLGİSAYARINIZ NE KADAR VERİ DEPOLAYABİLİR?",
                "tr_subtitle": "Tahmin etmenize gerek yok. Bu bilgisayarda tam olarak ne kadar veri depolayabileceğinizi göstereceğiz.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB HDD"
                  },
                  {
                    "tr_title": "[XX] GB SSD"
                  },
                  {
                    "tr_title": "[optane_memory_size] GB Intel® Optane™ bellek ile hızlandırılmış [total_capacity] GB Intel® SSD"
                  },
                  {
                    "tr_title": "[optane_memory_size] GB Intel® Optane™ bellek ile hızlandırılmış [total_capacity] TB Intel® SSD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD] GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD] GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD] TB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD] TB HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DEPOLAMA</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DEPOLAMA</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DEPOLAMA</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DEPOLAMA</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DEPOLAMA</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DEPOLAMA</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "DEPOLAMA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
                "tr_preTitle1": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
                "tr_preTitle2": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
                "tr_preTitle3": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
                "titleArray": [
                  {
                    "tr_title": "Bilgisayarınızda her şeye yer var",
                    "tr_title1": "Bu cihaz, etkileyici bir depolama alanına sahiptir",
                    "tr_subtitle": "Bu bilgisayarda, [convertedSSD] GB depolama alanına sahip bir SSD ve [convertedHDD] GB sabit disk sürücü bulunur. Bu, size hızlı depolama performansının yanı sıra mükemmel depolama kapasitesi sunar.",
                    "tr_subtitle1": "Bu bilgisayarda, [convertedSSD] TB depolama alanına sahip bir SSD ve [convertedHDD] GB sabit disk sürücü bulunur. Bu, size hızlı depolama performansının yanı sıra mükemmel depolama kapasitesi sunar.",
                    "tr_subtitle2": "Bu bilgisayarda, [convertedSSD] GB depolama alanına sahip bir SSD ve [convertedHDD] TB sabit disk sürücü bulunur. Bu, size hızlı depolama performansının yanı sıra mükemmel depolama kapasitesi sunar.",
                    "tr_subtitle3": "Bu bilgisayarda, [convertedSSD] TB depolama alanına sahip bir SSD ve [convertedHDD] TB sabit disk sürücü bulunur. Bu, size hızlı depolama performansının yanı sıra mükemmel depolama kapasitesi sunar."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DEPOLAMA</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DEPOLAMA</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "DEPOLAMA",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "SSD'li Intel® Optane™ bellek sayesinde yüksek kapasiteli depolama alanına daha hızlı erişin.",
                "tr_title": "Dev dosyalardan korkmayın",
                "tr_pretitle": "[optane_memory_size] GB Intel® Optane™ bellek ile hızlandırılmış [total_capacity] GB Intel® SSD",
                "tr_pretitle1": "[optane_memory_size] GB Intel® Optane™ bellek ile hızlandırılmış [total_capacity] TB Intel® SSD",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Devrim niteliğindeki bu depolama çözümü, Intel® Optane™ bellek ile SSD'yi tek bir çipte sunan ilk üründür. Sunduğu ayrıcalıklar:",
                    "tr_title": "SSD'Lİ INTEL® OPTANE™ BELLEK NEDİR?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "İhtiyaç duyduğunuz dosyaları hızla açın",
                    "tr_badgeSubTitle": "Sık kullanılan dosya ve programları önceden yükleyerek bekleme süresini azaltabilir, böylece içerik oluşturmaya daha fazla vakit ayırabilirsiniz.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Depolama ve hız",
                    "tr_badgeSubTitle": "Hem büyük medya dosyaları için yeterince alana sahip olur hem de SSD'nin hızlı performansından yararlanırsınız.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Zaman içinde artan yanıt hızı",
                    "tr_badgeSubTitle": "SSD'li Intel® Optane™ Bellek, çalışma şeklinizi öğrenerek bilgisayarınızı daha verimli hale getirir.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "DEPOLAMA",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Güvenilir ve geniş depolama alanıyla tüm medya içerikleriniz elinizin altında olsun.",
                "tr_title": "Tüm fotoğraflarınız, videolarınız ve şarkılarınız için yer var"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Ekran",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Kaydırmak, büyütmek ve hayran bırakmak için [screenResolution] milyon piksel",
                "tr_subtitle": "Sezgisel kontroller ve daha keskin, daha canlı detaylar eşliğinde bilgisayarınızla tıpkı bir akıllı telefon gibi etkileşim kurun.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal] inç<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Çözünürlük: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Piksel: [screenResolution] milyon"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] inç ekran",
                    "tr_badgeSubTitle": "Ekran Yüksekliği: [screenHeight] inç",
                    "tr_badgePreTitle": "Ekran Genişliği: [YY] inç"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Dokunmatik ekran",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Ekran",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] milyon pikseli keşfedin",
                "tr_subtitle": "Tüm mesele piksellerde. Ne kadar fazlaysa, görüntüleriniz de o kadar net ve canlı olur.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal] inç<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Çözünürlük: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Piksel: [screenResolution] milyon"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] inç ekran",
                    "tr_badgeSubTitle": "Ekran Yüksekliği: [screenHeight] inç",
                    "tr_badgePreTitle": "Ekran Genişliği: [YY] inç"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Ekran",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] inç <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "OYUN DÜNYALARINIZA AÇILAN KAPI",
                "tr_subtitle": "Tıpkı televizyonda olduğu gibi, bir oyun ekranındaki en önemli öncelikleriniz de boyut ve çözünürlük olacaktır.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Çözünürlük: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Piksel: [screenResolution] milyon"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] inç ekran",
                    "tr_badgeSubTitle": "Ekran Yüksekliği: [screenHeight] inç",
                    "tr_badgePreTitle": "Ekran Genişliği: [screenWidth] inç"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Dokunmatik ekran"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Ekran",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Bu cihaz etkileyici bir ekrana sahiptir",
                "tr_subtitle": "HD'ye kıyasla sekiz kattan daha fazla piksel sayesinde videolarınız ve oyunlarınız, keskinlik ve renk açısından yepyeni bir seviyeye çıkacak.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Çözünürlük: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Piksel: [screenResolution] milyon"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] inç ekran",
                    "tr_badgeSubTitle": "Ekran Yüksekliği: [screenHeight] inç",
                    "tr_badgePreTitle": "Ekran Genişliği: [YY] inç"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Ekran",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Basma, dokunma ve kaydırma alanı sunan [screenResolution] milyon piksel",
                    "tr_subTitle": "Sevdiğiniz her şeyi daha net ve daha canlı ayrıntılarla görüntüleyin ve etkileşim kurun."
                  },
                  {
                    "tr_title": "Dijital dünyanızı zenginleştirecek [screenResolution] milyon piksel",
                    "tr_subTitle": "Tüm mesele piksellerde. Ne kadar fazlaysa, görüntüleriniz de o kadar net ve canlı olur."
                  },
                  {
                    "tr_title": "Bu cihaz etkileyici bir ekrana sahiptir",
                    "tr_subTitle": "HD’den yaklaşık dört kat daha fazla piksel ile videolarınız ve oyunlarınız yepyeni bir netlik ve renk kalitesi düzeyine ulaşır."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bağlantı",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3 Teknolojisi, tüm dock'lar, ekranlar veya veri cihazları için en hızlı ve en çok amaçlı bağlantıyı sunar.",
                "tr_title": "Çok amaçlı bir bağlantı noktası",
                "tr_title1": "ÇOK AMAÇLI BİR BAĞLANTI NOKTASI",
                "titleArray": [
                  {
                    "tr_title": "THUNDERBOLT™ 3 TEKNOLOJİSİ NASIL KULLANILIR?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 3 Teknolojisi"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K Monitörler",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Büyüleyici çözünürlük, kontrast ve renk sunan ekranlara bağlanın"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Harici grafik kartı",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Her bilgisayarın grafik kalitesini anında yükseltir"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Hızlı ağ bağlantısı",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Eşler arası hızlı bağlantılar sağlar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bağlantı",
                "detailBGUrl": "",
                "tr_subtitle": "Thunderbolt™ 3 Teknolojisi, verileri geleneksel USB’lerden daha yüksek hızla aktarır ve birden çok monitör veya harici grafik kartı gibi çevre birimleriyle kolaylıkla entegrasyona olanak sağlar.",
                "tr_title": "Thunderbolt™ 3 Teknolojisi",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "IŞIK HIZINDA VERİLER",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mb/sn",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gb/sn",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Thunderbolt™ 3 Teknolojisi",
                    "tr_badgeSubTitle": "40 Gb/sn",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bağlantı",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Tüm favori aksesuarlarınızı destekleyen tek bir evrensel bağlantı noktası ve kablo</li> <li>Güvenilir dizüstü bilgisayar şarjı</li> <li>Güçlü sertifikasyonla içiniz rahat</li></ul>",
                "tr_title": "Tamamen evrensel kablo bağlantısı",
                "tr_title1": "TAMAMEN EVRENSEL KABLO BAĞLANTISI",
                "tr_noteSection": "<sup>†</sup>Minimum özellik gereksinimlerine göre",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "THUNDERBOLT™ 4 TEKNOLOJİSİ NASIL KULLANILIR?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 4 Teknolojisi"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "İçerik oluşturmak",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Fotoğraf ve videoları görüntülemek ve düzenlemek için harici bir monitör ve hızlı bir depolama cihazı bağlayın."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dock",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Dizüstü bilgisayarınızı tüm aksesuarlarınıza tek bir kabloyla bağlayarak sade ve karmaşadan uzak bir masa düzenine kavuşun."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Oyun",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Oyun kütüphanenizi ve medya dosyalarınızı depolamak için 3.000 MB/sn'ye varan hızlarla daha fazla SSD ve HDD kapasitesi ekleyin."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maksimum performans<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "USB* 3.2'den 4 kat daha hızlı <br/>HDMI* 2'den 2 kat daha hızlı"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bağlantı",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3 Teknolojisinin aynı muhteşem performansından ve ayrıca en yeni Thunderbolt™ dock'lar, monitörler, hızlı depolama cihazları ve tüm USB* aksesuarlara kolayca bağlanmanıza olanak tanıyan daha fazla özellikten faydalanın.",
                "tr_title": "Thunderbolt™ 4 Teknolojisi",
                "tr_pretitle": "TAMAMEN EVRENSEL KABLO BAĞLANTISI",
                "tr_noteSection": "<sup>†</sup>Minimum özellik gereksinimlerine göre",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Tek bir evrensel Thunderbolt™ 4 kablosu, tüm USB-C* kablolarınızın yerine geçerek kablo seçimini hızlı ve kolay hale getirebilir."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Thunderbolt™ 4 Teknolojisi dock'ları, tüm aksesuarlarınızı bağlamanıza olanak tanıyarak daha fazla esneklik sağlamak için dört adede kadar Thunderbolt™ bağlantı noktası sunar."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maksimum performans<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "USB* 3.2'den 4 kat daha hızlı <br/>HDMI* 2'den 2 kat daha hızlı"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bağlantı",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Tüm favori aksesuarlarınızı destekleyen tek bir evrensel bağlantı noktası ve kablo</li> <li>Güvenilir dizüstü bilgisayar şarjı</li> <li>Güçlü sertifikasyonla içiniz rahat</li></ul>",
                "tr_title": "Thunderbolt™ aksesuarlarıyla deneyiminizi en üst düzeye çıkarın",
                "tr_noteSection": "<sup>†</sup>Minimum özellik gereksinimlerine göre",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "THUNDERBOLT™ 4 TEKNOLOJİSİ NASIL KULLANILIR?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "İçerik oluşturmak",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Fotoğraf ve videoları görüntülemek ve düzenlemek için harici bir monitör ve hızlı bir depolama cihazı bağlayın."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dock",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Dizüstü bilgisayarınızı tüm aksesuarlarınıza tek bir kabloyla bağlayarak sade ve karmaşadan uzak bir masa düzenine kavuşun."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Oyun",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Oyun kütüphanenizi ve medya dosyalarınızı depolamak için 3.000 MB/sn'ye varan hızlarla daha fazla SSD ve HDD kapasitesi ekleyin."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maksimum performans<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "USB* 3.2'den 4 kat daha hızlı <br/>HDMI* 2'den 2 kat daha hızlı"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "En yaygın Wi‑Fi türleri şunlardır:",
                "tr_title": "WI‑FI STANDARTLARI",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6, birçok kullanıcı aynı sinyali kullanırken bile daha yüksek hızlara olanak tanır.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Intel'in Wi-Fi alanındaki en son inovasyonu sayesinde aynı sinyali birden fazla kişi kullanırken bile gigabit Wi-Fi'dan daha yüksek hızlara ulaşabilirsiniz.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Son 20 yıldaki en büyük Wi‑Fi gelişimi olan Wi‑Fi 6E; daha yüksek performans, daha fazla güvenilirlik ve daha az enterferans için yeni yüksek hızlı kanallar sağlar."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6, birçok kullanıcı aynı sinyali kullanırken bile daha yüksek hızlara olanak tanır.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Wi-Fi 6E'nin tüm avantajları, bant genişliğini en çok ihtiyaç duyan web sitelerine ve uygulamalara kaydıran Intel® Killer™ Öncelik Belirleyici Motor ile birlikte sunulur."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Yüksek hızlı kanallarınızda daha az rekabet olunca gelişmiş performansın ve güvenilirliğin keyfini çıkaracaksınız."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6, birçok kullanıcı aynı sinyali kullanırken bile daha yüksek hızlara olanak tanır.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 ",
                    "tr_badgeSubTitle": "Wi-Fi 6'nın sunduğu daha düşük enterferans ve Intel® Killer™ Öncelik Belirleyici Motorun akıllı performansı sayesinde çevrimiçi deneyiminiz daha çabuk ve yanıt hızı daha yüksek olacaktır.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Yüksek hızlı kanallarınızda daha az rekabet olunca gelişmiş performansın ve güvenilirliğin keyfini çıkaracaksınız."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Tutarlı ultra düşük gecikme süresi ile 5 Gb/sn'nin üzerinde bilgisayar Wi-Fi hızları, dosyaları dakikalar değil saniyeler içinde paylaşmaya olanak tanır."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig), yapay zeka tabanlı ağ optimizasyonu sayesinde olağanüstü güvenilirlik, düşük gecikme süresi ve yüksek hız sağlayan son teknoloji bir üründür."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6, birçok kullanıcı aynı sinyali kullanırken bile daha yüksek hızlara olanak tanır.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+), 6 GHz kanalının kullanımıyla 1 Gb/sn'den yüksek hız, güvenilirlik ve düşük gecikme süresi sunar."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Yoğun ağlarda Wi-Fi 5'e kıyasla daha yüksek verimlilik, kapasite ve hız."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Wi‑Fi [XX] sayesinde bağlantınızı güçlendirin. Daha hızlı<sup>†</sup> ve daha güvenilir bir internet bağlantısının keyfini sürün.",
                "tr_title": "Daha geniş kapsama alanı ve daha fazla hız elde edin",
                "tr_noteSection": "<sup>†</sup>Gerçekleşen kablosuz bağlantı iş hacmi ve/veya kapsama alanı değişiklik gösterir.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Çok oyunculu ve çevrimiçi oyun çağında bağlantıda kalmak her zamankinden daha önemli. Hızın yanı sıra, bu hızı uzak bir mesafeden sağlayabilen kablosuz bir sinyale ihtiyacınız var.",
                "tr_title": "[XX]",
                "tr_pretitle": "BAĞLANTI OLMAZSA OLMAZ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Daha fazla aktif kullanıcı için daha hızlı bağlantı ve daha çok güvenilirlik sağlamak amacıyla Intel® Wi‑Fi 6 (Gig+) ile 160 MHz kanal desteği bulunan Wi‑Fi 6 yönlendiricilere bağlanın.",
                "tr_title": "Intel® <span class='no-warp'>Wi‑Fi 6  </span>(Gig+) ile neredeyse 3 kat daha yüksek hızlar<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Standart AC Wi‑Fi bağlantısına kıyasla. Bazı özellikler yalnızca belirli SKU'larda mevcuttur. Ayrıntılar için üreticiye danışın. Wi‑Fi hakkında daha fazla bilgi için lütfen intel.com/wifi6disclaimers adresini ziyaret edin"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Giriş seviyesi, çift bant Intel® Wi-Fi 6, sıkışık ağlarda e-postaya göz atma ve internette gezinme gibi temel bağlantı ihtiyaçları için gelişmiş performansı destekler.<sup>9</sup>",
                "tr_title": "Temel Wi‑Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Çok oyunculu ve çevrimiçi oyun çağında bağlantıda kalmak her zamankinden daha önemli. Hızın yanı sıra, bu hızı uzak bir mesafeden sağlayabilen kablosuz bir sinyale ihtiyacınız var.",
                "tr_title": "[XX]",
                "tr_pretitle": "BAĞLANTI OLMAZSA OLMAZ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Bu yeni Wi‑Fi kanalları, bant genişliğinizi mahallenizdeki daha eski cihazlarla paylaşmanıza gerek kalmayacağı anlamına gelir; böylece sorunsuz veri akışı, hızlı web gezintisi ve kusursuz uzaktan çalışma ve öğrenme deneyimleri sağlar.",
                "tr_title": "Rekabet etmeden bağlantı kurun",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "[XX] sayesinde bağlantınızı güçlendirin. Daha hızlı<sup>†</sup> ve daha güvenilir bir internet bağlantısının keyfini sürün.",
                "tr_title": "Daha geniş kapsama alanı ve daha fazla hız elde edin",
                "tr_noteSection": "<sup>†</sup>Gerçekleşen kablosuz bağlantı iş hacmi ve/veya kapsama alanı değişiklik gösterir.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Çok oyunculu ve çevrimiçi oyun çağında bağlantıda kalmak her zamankinden daha önemli. Hızın yanı sıra, bu hızı uzak bir mesafeden sağlayabilen kablosuz bir sinyale ihtiyacınız var.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "BAĞLANTI OLMAZSA OLMAZ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Entegre, ultra hızlı Wi‑Fi",
                "tr_subtitle": "Kablolar olmadan çevrimiçi dünyaya bağlanın."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Daha az enterferans için özel yüksek hızlı hatlar ve oyun deneyimine öncelik veren Intel® Killer™ Öncelik Belirleyici Motor ile çevrimiçi oyun deneyiminde önemli güncellemeler yapıldı.",
                "tr_title": "Rakiplerinizi geride bırakın",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Daha az enterferans için özel yüksek hızlı hatlar ve oyun deneyimine öncelik veren Intel® Killer™ Öncelik Belirleyici Motor ile çevrimiçi oyun deneyiminde önemli güncellemeler yapıldı.",
                "tr_title": "[XX]",
                "tr_pretitle": "RAKİPLERİNİZİ GERİDE BIRAKIN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Oyun deneyimine öncelik veren ve daha az parazit sağlayan özel yüksek hızlı şeritler sayesinde çevrimiçi oyun deneyiminde önemli bir gelişme sağlandı.",
                "tr_title": "[XX]",
                "tr_pretitle": "RAKİPLERİNİZİ GERİDE BIRAKIN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi, ağ trafiğini en çok ihtiyaç duyan uygulamalar için otomatik olarak hızlandırır. Bu sayede video yayınlarınız daha keskin ayrıntılara sahip olur ve daha kesintisiz görüntülenir.",
                "tr_title": "Bağlantının daha hızlı, daha akıllı bir yolu.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Çok oyunculu ve çevrimiçi oyun çağında bağlantıda kalmak her zamankinden daha önemli. Hızın yanı sıra, bu hızı uzak bir mesafeden sağlayabilen kablosuz bir sinyale ihtiyacınız var.",
                "tr_title": "[XX]",
                "tr_pretitle": "BAĞLANTI OLMAZSA OLMAZ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Wi-Fi 5 sayesinde bağlantınızı güçlendirin. Daha hızlı<sup>†</sup> ve daha güvenilir bir internet bağlantısının keyfini sürün.",
                "tr_title": "Daha geniş kapsama alanı ve daha fazla hız elde edin",
                "tr_noteSection": "<sup>†</sup>Gerçekleşen kablosuz bağlantı iş hacmi ve/veya kapsama alanı değişiklik gösterir.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Çok oyunculu ve çevrimiçi oyun çağında bağlantıda kalmak her zamankinden daha önemli. Hızın yanı sıra, bu hızı uzak bir mesafeden sağlayabilen kablosuz bir sinyale ihtiyacınız var.",
                "tr_title": "[XX]",
                "tr_pretitle": "RAKİPLERİNİZİ GERİDE BIRAKIN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Wi-Fi 7'nin özellikleri, önceki Wi-Fi nesillerini temel alacak ve bunları geliştirecektir. Bu yalnızca daha yüksek hızlar sağlamakla kalmayıp aynı zamanda etkileyici tüketici deneyimleri ve gelecekteki gelişmiş teknolojiler için yanıt hızında ve güvenilirlikte önemli ölçüde artış sağlayacaktır.",
                "tr_title": "Kablolu Bağlantı ile Benzer Bilgisayar Ağı Oluşturma",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Wi-Fi 7'nin özellikleri, önceki Wi-Fi nesillerini temel alacak ve bunları geliştirecektir. Bu yalnızca daha yüksek hızlar sağlamakla kalmayıp aynı zamanda etkileyici tüketici deneyimleri ve gelecekteki gelişmiş teknolojiler için yanıt hızında ve güvenilirlikte önemli ölçüde artış sağlayacaktır.",
                "tr_title": "[XX]",
                "tr_pretitle": "KULLANICI DENEYİMLERİ DÖNÜŞÜYOR",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Yapay zeka tabanlı ağ optimizasyonu sayesinde iyileştirilmiş hız, gecikme süresi ve güvenilirlik",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Yapay zeka tabanlı ağ optimizasyonu sayesinde iyileştirilmiş hız, gecikme süresi ve güvenilirlik.",
                "tr_title": "[XX]",
                "tr_pretitle": "KABLOLU BAĞLANTI İLE BENZER YANIT HIZI",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pil",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Nasıl ölçülür?",
                    "tr_badgeSubTitle": "Çoğu üretici pil ömrünü saat bazında ifade eder. Bu hesaplama kesin olarak doğru olmasa da bilgisayarların pil ömrünü karşılaştırmanıza yardımcı olur."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pil",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "HAREKET HALİNDEYKEN OYUN İÇİN",
                "tr_subtitle": "Günümüzün dizüstü oyun bilgisayarları sayesinde masanıza bağlı kalmak zorunda değilsiniz. Artık oyunlarınızı her yerde oynayabilirsiniz.",
                "tr_title": "[Dynamic.battery.values.hours] saat pil ömrü"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pil",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Prizden ayrıldıktan sonra, saatlerce uygulamalarınızın, oyunlarınızın ve müziklerinizin keyfini çıkarmaya devam edin.",
                "tr_title": "Tek şarjla [XX] saat canlı yayın<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Bilgisayar üreticisinin belirttiği pil ömrüne göre.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] saat",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pil",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Prizden ayrıldıktan sonra, saatlerce uygulamalarınızın, oyunlarınızın ve müziklerinizin keyfini çıkarmaya devam edin.",
                "tr_title": "Hareket halindeyken [Dynamic.battery.values.hours] saat güç<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Bilgisayar üreticisinin belirttiği pil ömrüne göre.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] saat",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pil",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Prizden ayrıldıktan sonra, saatlerce uygulamalarınızın, oyunlarınızın ve müziklerinizin keyfini çıkarmaya devam edin.",
                "tr_title": "Tek bir şarjla gün boyu güç için [Dynamic.battery.values.hours] saat<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Bilgisayar üreticisinin belirttiği pil ömrüne göre.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] saat",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pil",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Bu bilgisayar, pil ömrünüzü daha uzun dayanacak şekilde optimize eder ve 30 dakikalık şarj ile en az 4 saat pil ömrü sunar.<sup>4</sup>",
                "tr_title": "[Dynamic.battery.values.hours] saatlik pil ömrüyle daha uzun süre fişe takmadan çalışın<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Bilgisayar üreticisinin belirttiği pil ömrüne göre.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] saat",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Ana Sayfa",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Neden Bu Bilgisayarı Tercih Etmelisiniz",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Tüm teknik özellikleri görüntüleyin",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Uzaktan",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobil Cihazlar",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Yasal",
        "tr_paragraph_array": [
          "Performans testlerinde kullanılan yazılımlar ve iş yükleri, yalnızca Intel mikroişlemcilerdeki performans için optimize edilmiş olabilir. SYSmark* ve MobileMark* gibi performans testleri; belirli bilgisayar sistemleri, bileşenler ve işlevler kullanılarak ölçülür. Bu etkenlerden herhangi birinde yapılacak bir değişiklik, sonuçların da değişmesine neden olabilir. Satın almayı düşündüğünüz ürünler hakkında tam bir değerlendirme yapabilmek için, bu ürünlerin başka ürünlerle birlikte gösterdiği performans gibi bilgilere ve performans testlerine de başvurmanız gerekir. Performans ve karşılaştırma testi sonuçları ile ilgili daha fazla bilgi için lütfen www.intel.com/benchmarks sayfasına gidin.",
          "¹Intel® Wi-Fi 6 (Gig+) ürünleri, isteğe bağlı 160 MHz kanallarını destekleyerek tipik 2x2 802.11ax bilgisayar Wi-Fi ürünleri için mümkün olan en hızlı teorik maksimum hızı (2402 Mb/sn) mümkün kılar. Üst düzey Intel® Wi-Fi 6 (Gig+) ürünleri, yalnızca 80 MHz kanalların zorunlu gereksinimlerini destekleyen standart 2x2 (1201 Mb/sn) veya 1x1 (600 Mb/sn) 802.11ax bilgisayar Wi-Fi ürünlerine kıyasla 2 ila 4 kat daha yüksek maksimum teorik hız sağlar.",
          "²Öneriler yalnızca bilgilendirme amaçlıdır. Satın almaya karar vermeden önce lütfen diğer kaynaklara da başvurun.",
          "³Intel teknolojilerinin özellikleri ve avantajları, sistem yapılandırmasına bağlıdır ve etkinleştirilmiş donanım, yazılım ya da hizmet aktivasyonu gerektirebilir. Sistem yapılandırmasına bağlı olarak performansta farklılıklar görülebilir. Hiçbir bilgisayar sistemi mutlak güvenlik sağlayamaz. Daha fazla bilgi için sistem üreticinize veya bayinize danışın ya da intel.com adresini ziyaret edin.",
          "⁴FHD ekranlı sistemlerde, kablosuz bağlantıyla internette gezinme amacıyla kullanıldığında. Kapatıldığında, OEM varsayılan kapatma düzeyinden.",
          "⁵eSATA, USB ve IEEE 1394* FireWire gibi diğer bilgisayar G/Ç bağlantı teknolojilerine kıyasla. Performans, kullandığınız özel donanıma ve yazılıma bağlı olarak değişecektir. Thunderbolt™ teknolojisi etkinleştirilmiş bir cihaz kullanılmalıdır.",
          "⁶Yaklaşık 3 kat daha hızlı: 802.11ax 2x2 160 MHz, 2402 Mb/sn'lik maksimum teorik veri hızlarını mümkün kılar. IEEE 802.11* standart kablosuz bağlantı özelliklerinde belirtilen standart 802.11ac 2x2 80 MHz (867 Mb/sn) bağlantıdan yaklaşık 3 kat daha hızlıdır ve benzer şekilde yapılandırılan 802.11ax kablosuz ağ yönlendiricilerinin kullanılmasını gerektirir.",
          "⁷%75 Gecikme azaltma: Intel'in, 9 istemcili OFDMA kullanılarak ve kullanmadan gerçekleştirilen 802.11ax simülasyon verilerine (%79) dayanır. OFDM kullanılmadan elde edilen ortalama gecikme süresi 36 msn (milisaniye) olmuş, OFDMA kullanıldığında elde edilen ortalama gecikme süresi ise 7,6 msn’ye düşmüştür. Gecikme süresinde iyileşme için 802.11ax (Wi-Fi 6) yönlendiricinin ve tüm istemcilerin OFDMA kullanımını desteklemesi gereklidir.",
          "⁸802.11 teknik özelliklerindeki teorik maksimum veri hızı karşılaştırmasına dayanarak, 2x2 80 MHz Wi‑Fi 5 (802.11ac) 867 Mb/sn hız sunabilirken 2x2 160 MHz Wi‑Fi 6/6E (802.11ax) 2402 Mb/sn hız sağlayabilmektedir; bu da 2,8 kat daha yüksek hız anlamına gelir.",
          "⁹Sınıfının En İyi Wi‑Fi 6: Intel® Wi‑Fi 6 (Gig+) ürünleri, isteğe bağlı 160 MHz kanalları destekleyerek tipik 2x2 802.11ax bilgisayar Wi‑Fi ürünleri için mümkün olan en yüksek teorik maksimum hızları (2402 Mb/sn) hızları mümkün kılar. Üst düzey Intel® Wi‑Fi 6 (Gig+) ürünleri, yalnızca 80 MHz kanalların zorunlu gerekliliklerini destekleyen standart 2x2 (1201 Mb/sn) veya 1x1 (600 Mb/sn) 802.11ax bilgisayar Wi‑Fi ürünlerine kıyasla 2 ila 4 kat daha yüksek maksimum teorik hız sağlar.",
          "Yalnızca belirli SKU'lar; işlevler OEM tasarımına göre değişiklik gösterir. Ayrıntılar için OEM veya bayiye danışın. Wi‑Fi hakkında daha fazla bilgi için lütfen intel.com/wifi6disclaimers adresini ziyaret edin.",
          "¹⁰Thunderbolt™ 4 teknolojisi, sunduğu 40 Gb/sn hızla eSATA*, USB* ve IEEE 1394* FireWire dahil olmak üzere diğer bilgisayar G/Ç bağlantı teknolojilerine kıyasla tüm bağlantı istasyonu, ekran veya veri depolama cihazı için en hızlı, kolay ve güvenilir kablo çözümünü sunar.",
          "¹¹OFDMA'yı destekleyen 802.11ax tabanlı bir yönlendirici ve aynı ağda AX özellikli birden fazla istemci gerektirir. 802.11ax istemciler ve AP'ler ile desteklenen OFDMA özellikleri sayesinde, yoğun ortamlarda daha iyi verim elde etmek mümkündür. 2 Gb/sn tahmini, 802.11ax 160 MHz 2402 Mb/sn için IEEE 802.11* teknik özelliklerindeki teorik maksimum veri hızlarının yaklaşık %70'lik kısmına dayanır.",
          "¹²Arka Plan Aktiviteli Belge Başlatma İş Yükü kullanılarak, 8. Nesil Intel® Core™ i7-8565U işlemci (512 GB TLC SSD) ile 8. Nesil Intel® Core™ i7-8565U işlemcinin (32 GB + 512 GB Solid State Depolama Özellikli Intel® Optane™ bellek H10) ölçümünün karşılaştırılmasına dayanır.",
          "¹³Intel® Thread Director, 12. Nesil Intel® Core™ işlemcilerle birlikte çalışacak şekilde tasarlanmıştır ve işlemciyi destekleyen işletim sistemlerinin iş yüklerini doğru çekirdeğe daha akıllı bir şekilde kanalize etmesine yardımcı olur. Kullanıcının bir eylemde bulunması gerekmez. Ayrıntılar için intel.com adresini ziyaret edin.",
          "¹⁴Belirli 12. Nesil Intel® Core™ işlemcilerde mevcut değildir. Performans hibrit mimarisi; iki yeni çekirdek mikro mimarisini, yani Performance-core çekirdeklerini (P-core çekirdekleri) ve Efficient-core çekirdeklerini (E-core çekirdekleri) tek bir işlemcide bir araya getirir. Belirli 12. Nesil Intel® Core™ işlemciler (belirli 12. Nesil Intel® Core™ i5 ve daha düşük işlemciler) performans hibrit mimarisini desteklemez; yalnızca P-core çekirdekleri bulunur.",
          "¹⁵Oyun Başlatma İş Yükü ile 8. Nesil Intel® Core™ i7-8750H işlemci (32 GB Intel® Optane™ bellek modülü + Intel® SSD 256 GB PCIe* + 1 TB HDD) ve 8. Nesil Intel® Core™ i7-8750H işlemci (Intel® SSD 256 GB PCIe* + 1 TB HDD) karşılaştırılarak ölçülmüştür. Oyun Başlatma İş Yükü: Total War*: WARHAMMER* II (Derleme: 5577.0) oyunu başlatıldıktan sonra ana tanıtım videoları devre dışıyken Ana Menü’ye ulaşana kadar geçen süreyi (Başlatma) ölçen, Intel tarafından geliştirilmiş iş yükü. Intel® Optane™ bellek Veri Sürücüsü Hızlandırma Yapılandırmaları: Intel® Core™ i7-8750H işlemci, PL1=45&nbsp;W TDP, 6 Çekirdek/12 İş Parçacığı, Üretim öncesi OEM Sisteminde 4&nbsp;GHz’e kadar Turbo, Grafik kartı: NVIDIA* GeForce* GTX 1070, Bellek: 2x4&nbsp;GB DDR4, Depolama: Intel® SSD Serisi 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB HDD + 32&nbsp;GB Intel® Optane™ bellek, İşletim Sistemi: Windows* 10 RS3 Build 1709, MCU 0x84; Intel® Core™ i7-8750H işlemci, PL1=45&nbsp;W TDP, 6 Çekirdek/12 İş Parçacığı, Üretim öncesi OEM Sisteminde 4&nbsp;GHz’e kadar Turbo, Grafik kartı: NVIDIA* GeForce* GTX 1070, Bellek: 2x4&nbsp;GB DDR4, Depolama: Intel® SSD Serisi 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB HDD, İşletim Sistemi: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁶Oyun Seviyesi Yükleme İş Yükü ile 8. Nesil Intel® Core™ i7-8750H işlemci (32 GB Intel® Optane™ bellek modülü) ve 8. Nesil Intel® Core™ i7-8750H işlemci (yalnızca HDD) karşılaştırılarak ölçülmüştür. Oyun Seviyesi Yükleme İş Yükü: Total War*: WARHAMMER* II (Derleme: 5577.0) oyununda ana menüden başlayarak seviye yükleme işlemi tamamlanana kadar geçen süreyi (Seviye Yükleme) ölçen, Intel tarafından geliştirilmiş iş yükü. Intel® Optane™ bellek Veri Sürücüsü Hızlandırma Yapılandırmaları: Intel® Core™ i7-8750H işlemci, PL1=45&nbsp;W TDP, 6 Çekirdek/12 İş Parçacığı, Üretim öncesi OEM Sisteminde 4&nbsp;GHz’e kadar Turbo, Grafik kartı: NVIDIA* GeForce* GTX 1070, Bellek: 2x4&nbsp;GB DDR4, Depolama: Intel® SSD Serisi 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB HDD + 32&nbsp;GB Intel® Optane™ bellek, İşletim Sistemi: Windows* 10 RS3 Build 1709, MCU 0x84; Intel® Core™ i7-8750H işlemci, PL1=45&nbsp;W TDP, 6 Çekirdek/12 İş Parçacığı, Üretim öncesi OEM Sisteminde 4&nbsp;GHz’e kadar Turbo, Grafik kartı: NVIDIA* GeForce* GTX 1070, Bellek: 2x4&nbsp;GB DDR4, Depolama: Intel® SSD Serisi 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB HDD, İşletim Sistemi: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁷Performance-core çekirdeklerinde. Performans; kullanım, yapılandırma ve diğer faktörlere göre değişebilir. Daha fazla bilgi için lütfen şu sayfayı ziyaret edin: www.intel.com/PerformanceIndex.",
          "¹⁸Saat frekansının veya gerilimin değiştirilmesi, işlemcinin ve diğer sistem bileşenlerinin kullanım ömrüne zarar verebilir ya da kullanım ömrünü kısaltabilir ve sistem kararlılığı ile performansını azaltabilir. İşlemcinin teknik özelliklerinin ötesinde çalıştırılması ürün garantilerinin geçerliliğini yitirmesine neden olabilir. Detaylı bilgi için sistem ve bileşen üreticilerine danışın.",
          "¹⁹Intel vPro® Platformu’nun her boyuttaki işletme için sunduğu, işletim sisteminin üstündeki ve altındaki güvenlik özellikleri, uygulama ve veri korumaları ile gelişmiş tehdit korumalarının rakipsiz kombinasyonunun yanı sıra Intel’in ürün tasarımı, üretimi ve desteğindeki “önce güvenlik” yaklaşımı ile ölçüldüğü üzere. Intel vPro® platformunda geliştirilen tüm kurumsal bilgisayarlar, donanım tabanlı benzersiz güvenlik özellikleri dahil olmak üzere titiz spesifikasyonlara göre doğrulanmaktadır. Ayrıntılar için www.intel.com/PerformanceIndex (platformlar) adresini ziyaret edin. Hiçbir ürün veya bileşen mutlak güvenlik sağlayamaz.",
          "²⁰Intel® Connectivity Performance Suite yalnızca Windows* tabanlı sistemlerde sunulur. ICPS yazılım uygulaması kablosuz trafik önceliği belirleyicisinin ve akıllı erişim noktası değiştirmenin olmadığı senaryoya kıyasla yoğun bir ağda Intel Over The Air (OTA) Wi-Fi testi. Ayrıntılar için&nbsp;www.intel.com/PerformanceIndex (bağlantı) adresini ziyaret edin. Sonuçlar farklılık gösterebilir.",
          "²¹Önceki işlemci nesline kıyasla.",
          "²²Intel® Arc™ grafikleri, AV1 kodlama desteği ile piyasaya sürülen ilk grafik ürünleridir (2022 1. Çeyrek itibarıyla). Detaylar için şu sayfayı ziyaret edin: intel.com/performanceindex.",
          "²³Temel mobil kullanıcı deneyimi eşikleri için de doğrulanmış olan Intel® Evo™ tasarımlarının benzersiz özellikleriyle ölçülmüştür. Şubat 2022 itibarıyla yapılan testlere dayalıdır.",
          "²⁴Intel® Unison™ çözümü şu anda yalnızca Windows* teknolojili bilgisayarlarda uygun Intel® Evo™ tasarımlarında mevcuttur ve yalnızca Android veya iOS tabanlı telefonlarla eşleşir. Tüm cihazlar desteklenen bir işletim sistemi sürümünü çalıştırmalıdır.",
          "²⁵Maksimum bellek hızları, Kanal başına 1 DIMM (1DPC) yapılandırmalarına bağlıdır. Herhangi bir kanaldaki ek DIMM yükü, maksimum bellek hızını etkileyebilir. Belirli SKU'larda DDR5-5600 MT/sn'ye kadar 1DPC UDIMM 1Rx8, 1Rx16 ve DDR5-5200 1Rx8, 1Rx16, 2Rx8. Maksimum bellek kapasitesine 2DPC yapılandırmalarıyla ulaşılabilir.",
          "²⁶Standart Wi-Fi ile karşılaştırıldığında. Ayrıntılar için intel.com/performance-wireless adresini ziyaret edin. Sonuçlar farklılık gösterebilir.",
          "²⁷Performans hibrit mimarisi, ilk kez 12. Nesil Intel® Core™ işlemcilerde tanıtılmış olan Performance-core (P-core) çekirdekleri ve Efficient-core (E-core) çekirdeklerinden oluşan iki çekirdek mikro mimarisini tek bir işlemcide bir araya getirir. Seçili 12. Nesil ve daha yeni Intel® Core™ işlemcilerde, performans hibrit mimarisi bulunmayıp yalnızca P-core çekirdekleri bulunur ve bu işlemciler önceki nesil ile aynı önbellek boyutuna sahiptir. SKU ayrıntıları için ark.intel.com adresini ziyaret edin.",
          "²⁸Donanımda yerleşik olarak bulunan Intel® Thread Director, yalnızca 12. Nesil veya daha yeni Intel® Core™ işlemcilerin performans hibrit mimarili yapılandırmalarında sunulur; işletim sistemi etkinleştirmesi gereklidir. Kullanılabilir özellikler ve işlevler işletim sistemine göre değişir.",
          "²⁹Donanımda yerleşik olarak bulunan Intel® Thread Director, yalnızca 12. ve 13. Nesil Intel® Core™ işlemcilerin performans hibrit mimarili yapılandırmalarında sunulur; işletim sistemi etkinleştirmesi gereklidir. Kullanılabilir özellikler ve işlevler işletim sistemine göre değişir.",
          "³⁰Intel® Core™ Ultra mobil işlemcinin (Seri 2 - Kod adı: Lunar Lake) 3DMark Time Spy üzerindeki performansıyla Intel® Core™ Ultra mobil işlemcinin (Seri 1 - Kod adı: Meteor Lake) referans doğrulama platformu performansı karşılaştırılarak ölçülmüştür. Ayrıntılı bilgi için şu sayfaya bakın: intel.com/PerformanceIndex. Sonuçlar farklılık gösterebilir.",
          "³¹Arka plan bulanıklaştırma ve yüz izleme özellikleri, yalnızca Windows* tabanlı sistemlerde sunulur.",
          "³²Wi-Fi 7 ürünleri 6 GHz bandında 320 MHz kanallara ve yeni Çoklu Kaynak Birimi Hızlandırma (Multi-RU Puncturing) özellikleriyle 5 GHz bandında yeni 160 MHz kanal birleşimlerine erişebilmektedir.",
          "³³IEEE'nin kablosuz bağlantı standardı teknik özelliklerine ve 2 akışlı cihazlar için maksimum teorik veri hızlarına göre belirlenmiştir.",
          "³⁴Intel'in yoğun ağ ortamlarında yaptığı mühendislik simülasyonları sonucunda yeni Wi-Fi 7 Çoklu Bağlantı İşletimi özellikleriyle gecikme süresinde önemli bir azalma sağlanabileceği görülmüştür.",
          "³⁵OEM olarak varsayılan kapanma seviyesinden alınan minimum şarj. Tekil bazda incelenen sistem sonuçları farklılık gösterebilir. Ayrıntılar için intel.com/performance-evo adresini ziyaret edin.",
          "³⁶6 GHz Wi-Fi bandı tüm ülkelerde/bölgelerde kullanım için mevcut değildir.",
          "³⁷Yalnızca Windows*",
          "³⁸OEM olarak varsayılan kapanma seviyesinden alınan şarj. Performans ve karşılaştırma testi sonuçları ile ilgili daha fazla bilgi için lütfen şu sayfayı ziyaret edin: intel.com/Evo.",
          "³⁹Benzersiz donanım tabanlı güvenlik özelliklerine, işletim sisteminin üstünde ve altında yer alan güvenlik özelliklerinin rakipsiz kombinasyonuna ve önceki nesle kıyasla daha düşük güçte bile etkileyici performansa sahip Intel® Core™ Ultra mobil işlemcilere (Seri 2) atıfta bulunur (Eylül 2024 itibarıyla). Ayrıntılı bilgi için şu adresi ziyaret edin: intel.com/PerformanceIndex. Sonuçlar farklılık gösterebilir.",
          "⁴⁰Yazılım uygulamaları yalnızca açıklama amacıyla gösterilmiştir. Yapay zeka özellikleri; ilave yazılım satın almayı, abone olmayı ya da bir yazılım veya platform sağlayıcısı tarafından etkinleştirmeyi gerektirebilir ya da özel yapılandırma veya uyumluluk gerekliliklerine sahip olabilir. Ayrıntılı bilgi için şu sayfaya bakın: www.intel.com/PerformanceIndex. Sonuçlar farklılık gösterebilir. © Intel Corporation.",
          "⁴¹Gösterilen görüntüler değiştirilmiş veya simüle edilmiş olabilir. Yapay zeka özellikleri; ilave yazılım satın almayı, abone olmayı ya da bir yazılım veya platform sağlayıcısı tarafından etkinleştirmeyi gerektirebilir ya da özel yapılandırma veya uyumluluk gerekliliklerine sahip olabilir. Ayrıntılı bilgi www.intel.com/AIPC sayfasında.",
          "⁴²Belirli küresel pazarlarda en yeni Windows* 11 güncellemesi ile birlikte ön izleme olarak kademeli şekilde kullanıma sunulacaktır. Erişime açılma zamanı cihaza ve pazara göre değişir. Daha fazla bilgiyi buradan edinin: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Intel® Unison™ çözümü şu anda uygun tasarımlarda kullanılabilir. Ayrıntılı bilgi için şu sayfaya bakın: www.intel.com/PerformanceIndex.",
          "⁴⁴Intel® X<sup>e</sup>SS, Yerel FPS'ye kıyasla FPS'yi artırmıştır; bu ölçüm Intel® Core™ Ultra 7 işlemci 165H üzerinde Dying Light 2 Stay Human ile yapılmıştır. Ayrıntılı bilgi için şu sayfaya bakın: www.intel.com/PerformanceIndex. Sonuçlar farklılık gösterebilir.",
          "⁴⁵YouTube* 4K 30FPS AV1'de daha düşük Çip Üzerinde Sistem gücüne sahip Intel® Core™ Ultra mobil işlemci (Seri 2 - Kod adı: Lunar Lake) referans doğrulama platformu ile Intel® Core™ Ultra mobil işlemci (Seri 1 - Kod adı: Meteor Lake) referans doğrulama platformu karşılaştırılarak ölçülmüştür. Ayrıntılı bilgi için şu adresi ziyaret edin: intel.com/PerformanceIndex. Sonuçlar farklılık gösterebilir.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel hatalı fiyatlar için sorumluluk kabul etmez.",
          "*Diğer adlar ve markalar başkalarının mülkiyetinde olabilir.",
          "TELİF HAKKI 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES VE İLGİLİ TASARIM VE KOMPOZİT MARKALAR INFLEXION STUDIOS INC.'NİN TİCARİ MARKALARIDIR. TÜM HAKLARI SAKLIDIR.",
          "Intel teknolojileri; etkinleştirilmiş donanım, yazılım ya da hizmet aktivasyonu gerektirebilir.",
          "NERO* tarafından sağlanan tüm veriler ve sonuçlar kolaylık olarak sunulur ve yalnızca bilgi amaçlıdır. Intel Corporation, örneğin, NERO* uygulamasının, performans sonuçlarının ve skorlarının doğruluğundan veya www.nero.com ya da başka üçüncü taraf web sitelerinde mevcut uygulamanın diğer indirilebilir sürümleri arasındaki olası farklılıklardan sorumlu değildir. NERO* bilgisayar karşılaştırma testi skorları; belirli bilgisayar sistemleri, bileşenler, yazılımlar, işlemler ve işlevler kullanılarak ölçülür. Bu etkenlerden herhangi birinde yapılacak bir değişiklik, sonuçların da değişmesine neden olabilir. Satın almayı düşündüğünüz ürünler hakkında tam bir değerlendirme yapabilmek için, bu ürünlerin başka ürünlerle birlikte gösterdiği performans gibi bilgilere ve performans testlerine de başvurmanız gerekir.",
          "Intel vPro® platformunun tüm sürümleri için platformu tanımlayan yönetilebilir kulanım durumlarını, güvenlik özelliklerini, sistem performansını ve kararlılığı sunmak üzere uygun bir Intel® Core™ işlemci, desteklenen bir işletim sistemi, Intel LAN ve/veya WLAN silikon, sabit yazılım geliştirmeleri ve diğer donanım ve yazılım bileşenleri gerekir.",
          "Performans; kullanım, yapılandırma ve diğer faktörlere göre değişebilir. Daha fazla bilgi için lütfen şu sayfayı ziyaret edin: www.intel.com/PerformanceIndex.",
          "Intel üçüncü taraf verilerini kontrol etmez veya denetlemez. Almayı düşündüğünüz ürünün performansını değerlendirmek için diğer kaynaklara başvurmanız gerekir.",
          "© Intel Corporation. Intel, Intel logosu ve diğer Intel markaları, Intel Corporation veya bağlı kuruluşlarının ticari markalarıdır. *Diğer adlar ve markalar başkalarının mülkiyetinde olabilir.",
          "Hiçbir ürün veya bileşen mutlak güvenlik sağlayamaz.",
          "Maliyet ve sonuçlar farklılık gösterebilir.",
          "Intel, Intel logosu, Intel Core, Intel Optane, Intel Iris, Thunderbolt ve Thunderbolt logosu, Intel Corporation veya bağlı kuruluşlarının ticari markalarıdır. Diğer adlar ve markalar başkalarının mülkiyetinde olabilir.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Ana Sayfa",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Karşılaştırmak İçin Tara",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "En Çok Konuşulanlar",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/evo/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/video_link.mp4",
      "iconURL": "",
      "videoName": "video_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ Performance to Power Their World (16_9).mp4",
      "iconURL": "",
      "videoName": "Video_ Performance to Power Their World (16_9)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "HOŞ GELDİNİZ",
    "tr_welcomePagetrSubtitle": "Bu bilgisayarı akıllı telefonunuzla uzaktan kontrol edin",
    "tr_welcomePagembSubtitle": "Hoş geldiniz, bu bilgisayarın teknik özelliklerini akıllı telefonunuzda inceleyin",
    "tr_welcomePageBtnText": "Başlayın",
    "tr_welcomePageText": "Bu bilgisayarın ayrıntılarını akıllı telefonunuzda inceleyin ve karşılaştırın",
    "tr_scrollText": "Parmaklarınızın ucundaki cihazda gezinin ",
    "tr_toolTipTextc1": "Parmaklarınızın ucundaki cihazın farklı sayfalarında gezinmek için bu düğmeleri kullanın.",
    "tr_toolTipTextc2": "Aşağıdaki kartlara dokunduğunuzda cihazın her bir teknik özelliğine dair ayrıntılı sayfaya gidersiniz.",
    "tr_toolTipTextc3": "Önünüzdeki ekranda yukarı ve aşağı gitmek için bu düğmeleri kullanın.",
    "tr_toolTipTextc4": "Cihaz teknik özelliklerini akıllı telefonunuzda görüntülemek için uzaktan kumanda modunu kapatın.",
    "tr_toolTipTextmb1": "Ayrıntılı içerik sayfalarını görüntülemek için kartlara dokunun.",
    "tr_toolTipTextmb2": "Cihazın tüm teknik özelliklerini görüntülemek için dokunun",
    "tr_toolTipTextmb3": "QR kodunu taramak ve 4'e kadar cihazı karşılaştırmak için düğmeye dokunun",
    "tr_nextButton": "İleri",
    "tr_prevButton": "Geri",
    "tr_gotItButton": "ANLADIM",
    "tr_skipButton": "Atla",
    "tr_placeholderText": "*yer tutucu",
    "tr_processorHeading": "İşlemci",
    "tr_processorSubheading": "10. Nesil Intel®&nbsp;Core™&nbsp;i7 işlemci",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "[XX] GB Intel® Optane™ Bellek + [YY] GB SSD",
    "tr_coachmarkProcessor": "XX işlemci",
    "tr_coachmarkGraphics": "[XX] Grafikleri",
    "tr_coachmarkMemory": "Teknik değer",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999,95 $",
    "tr_compare": "Karşılaştırma",
    "tr_viewallspecs": "TÜM ÖZELLİKLERİ GÖRÜNTÜLE",
    "tr_operatingSystem": "İŞLETİM SİSTEMİ",
    "tr_AmdProcessorNonIntel": "AMD* işlemci",
    "tr_QualcommProcessorNonIntel": "Qualcomm* işlemci",
    "tr_GBText": "GB",
    "tr_remoteText": "Dizüstü bilgisayar ekranında görüntülenen teknik özellikler",
    "tr_scanErrorText": "Aynı anda en fazla 3 cihazı tarayabilirsiniz. Lütfen bir veya daha fazla cihazı silin.",
    "tr_scanErrorText2": "Bu cihazı zaten taradınız, lütfen başka bir cihazı tarayın.",
    "tr_scanMsg": "Cihaz eklemek için tarayın",
    "tr_backToScan": "Karşılaştırmaya Geri Dön",
    "tr_scanToCompare": "Karşılaştırmak İçin Tara",
    "tr_compareDevice": "Cihazları Karşılaştırın",
    "tr_processorTitle": "İŞLEMCİ",
    "tr_graphicTitle": "GRAFİK KARTI",
    "tr_storageTitle": "DEPOLAMA",
    "tr_displayTitle": "EKRAN",
    "tr_batteryTitle": "PİL",
    "tr_memoryTitle": "BELLEK",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "BAĞLANTI",
    "tr_priceTitle": "FİYAT",
    "tr_operatingSystemTitle": "İŞLETİM SİSTEMİ",
    "tr_batteryHoursText": "[XX] saat",
    "tr_hrsText": "s",
    "tr_touchscreeenText": "[screenDiagonal] inç Dokunmatik Ekran",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, Dokunmatik Ekran",
    "tr_FHDTouchScreenText": "FHD, Dokunmatik Ekran",
    "tr_4KTouchscreenText": "4K, Dokunmatik Ekran",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Kaydet",
    "tr_device_comparison": "Cihaz karşılaştırma belgesi [dd]-[mm]-[yyyy]",
    "tr_Save_As_JPEG": "JPEG olarak kaydet",
    "tr_Save_As_PDF": "PDF olarak kaydet",
    "tr_Downloading_PDF": "PDF indiriliyor",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Açık",
    "tr_logo": "logo",
    "tr_laptop_name": "Dizüstü bilgisayar adı",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Intel® Optane™ Bellek",
    "tr_inchUnit": "inç",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "INTEL® OPTANE™ BELLEK",
    "tr_Thunderbolt3": "Thunderbolt™ 3 Teknolojisi",
    "tr_Thunderbolt4": "Thunderbolt™ 4 Teknolojisi",
    "tr_processorGraphicsCaps": "İŞLEMCİ GRAFİKLERİ",
    "tr_processorGraphicsSmall": "İşlemci Grafikleri",
    "tr_graphicsCardCaps": "ÖZEL GRAFİKLER",
    "tr_graphicsCardSmall": "Özel Grafikler",
    "tr_processorTitleCamel": "İşlemci",
    "tr_graphicTitleCamel": "Grafikler",
    "tr_storageTitleCamel": "Depolama",
    "tr_displayTitleCamel": "Ekran",
    "tr_batteryTitleCamel": "Pil",
    "tr_memoryTitleCamel": "Bellek",
    "tr_connectivityTitleCamel": "Bağlantı",
    "tr_priceTitleCamel": "Fiyat",
    "tr_operatingSystemTitleCamel": "İşletim Sistemi",
    "tr_viewallspecsCamel": "Tüm teknik özellikleri görüntüleyin",
    "tr_displayText": "[screenDiagonal] inç",
    "tr_displayTextResolution": "[screenDiagonal] inç [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal] inç [screenResolutionType] Dokunmatik Ekran",
    "tr_OS": "İŞLETİM SİSTEMİ",
    "tr_Chrome_OS": "Chrome* İşletim Sistemi",
    "tr_PCSpecs_text": "Bilgisayar Teknik Özellikleri",
    "tr_explorePC_text": "Bu bilgisayara dair her şeyi keşfedin",
    "tr_mtlPC_text": "“Tüm ihtiyaçlarınızı karşılamak için tasarlandı” sözünün gerçekte ne anlama geldiğini görün",
    "tr_optaneMemory": "Intel® Optane™ Bellek",
    "tr_displayText_Display": "[screenDiagonal] İNÇ EKRAN",
    "tr_displayTextResolution_Display": "[screenDiagonal] İNÇ [screenResolutionType] EKRAN",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal] İNÇ [screenResolutionType] DOKUNMATİK EKRAN",
    "tr_displayTextTouchscreeenText": "[screenDiagonal] İNÇ DOKUNMATİK EKRAN",
    "tr_learnMoreLabel": "Daha Fazla Bilgi",
    "tr_batteryHoursTextUpperCase": "[XX] SAAT",
    "tr_processorGraphicsIrisXe": "11. NESİL INTEL® CORE™ [cpu_fam] İŞLEMCİ VE INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAFİKLERİ",
    "tr_processorGraphicsIrisXeMax": "11. NESİL INTEL® CORE™ [cpu_fam] İŞLEMCİ VE INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAFİKLERİ",
    "tr_Thunderbolt3UpperCase": "THUNDERBOLT™ 3 TEKNOLOJİSİ",
    "tr_Thunderbolt4UpperCase": "THUNDERBOLT™ 4 TEKNOLOJİSİ",
    "tr_processorWithArcGraphics": "12. NESİL INTEL® CORE™ [cpu_fam] İŞLEMCİ VE INTEL® ARC™ GRAFİKLERİ",
    "tr_processorGraphicsIrisXe12Gen": "12. NESİL INTEL® CORE™ [cpu_fam] İŞLEMCİ VE INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAFİKLERİ",
    "tr_processorGraphicsIrisXeMax12Gen": "12. NESİL INTEL® CORE™ [cpu_fam] İŞLEMCİ VE INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAFİKLERİ",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "13. NESİL INTEL® CORE™ [cpu_fam] İŞLEMCİ VE INTEL® ARC™ GRAFİKLERİ",
    "tr_processorGraphicsIrisXe13Gen": "13. NESİL INTEL® CORE™ [cpu_fam] İŞLEMCİ VE INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;GRAFİKLERİ",
    "tr_processorGraphicsIrisXeMax13Gen": "13. NESİL INTEL® CORE™ [cpu_fam] İŞLEMCİ VE INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX&nbsp;GRAFİKLERİ",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "INTEL® ARC™ A350M GRAFİKLERİ",
      "tr_A370M": "INTEL® ARC™ A370M GRAFİKLERİ",
      "tr_A730M": "INTEL® ARC™ A730M GRAFİKLERİ",
      "tr_A770M": "INTEL® ARC™ A770M GRAFİKLERİ",
      "tr_A310": "INTEL® ARC™ A310 GRAFİKLERİ",
      "tr_A380": "INTEL® ARC™ A380 GRAFİKLERİ",
      "tr_A580": "INTEL® ARC™ A580 GRAFİKLERİ",
      "tr_A750": "INTEL® ARC™ A750 GRAFİKLERİ",
      "tr_A770": "INTEL® ARC™ A770 GRAFİKLERİ"
    },
    "tr_memoryValue": "[XX] GB RAM",
    "tr_memoryWithOptaneDesc": "[Value1] GB bellek = [Value2] GB Intel®&nbsp;Optane™ Bellek + [Value3] GB RAM",
    "tr_textGBGB": "[convertedSSD]&nbsp;GB&nbsp;SSD&nbsp;+ [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textGBTB": "[convertedSSD]&nbsp;GB&nbsp;SSD&nbsp;+ [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textTBGB": "[convertedSSD]&nbsp;TB&nbsp;SSD&nbsp;+ [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBTB": "[convertedSSD]&nbsp;TB&nbsp;SSD&nbsp;+ [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textGBSSD": "[convertedSSD] GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_textStorageWithOptaneGB": "[XX] GB Intel®&nbsp;Optane™ Bellek&nbsp;+ [convertedSSD]&nbsp;GB&nbsp;SSD",
    "tr_textStorageWithOptaneTB": "[XX] GB Intel®&nbsp;Optane™ Bellek&nbsp;+ [convertedSSD]&nbsp;TB&nbsp;SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "S modunda Windows* 10 Home",
      "tr_OS21": "S modunda Windows* 10 Pro",
      "tr_OS22": "S modunda Windows* 10 Enterprise",
      "tr_OS23": "S modunda Windows* 11 Home",
      "tr_OS24": "S modunda Windows* 11 Pro",
      "tr_OS25": "S modunda Windows* 11 Enterprise"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Intel® Arc™ A310 Grafikleri",
      "tr_GR387": "Intel® Arc™ A350M Grafikleri",
      "tr_GR388": "Intel® Arc™ A370M Grafikleri",
      "tr_GR389": "Intel® Arc™ A380 Grafikleri",
      "tr_GR390": "Intel® Arc™ A530M Grafikleri",
      "tr_GR391": "Intel® Arc™ A550M Grafikleri",
      "tr_GR392": "Intel® Arc™ A570M Grafikleri",
      "tr_GR393": "Intel® Arc™ A580 Grafikleri",
      "tr_GR394": "Intel® Arc™ A730M Grafikleri",
      "tr_GR395": "Intel® Arc™ A750 Grafikleri",
      "tr_GR396": "Intel® Arc™ A770 Grafikleri",
      "tr_GR397": "Intel® Arc™ A770M Grafikleri",
      "tr_GR398": "Intel® Arc™ Grafikleri",
      "tr_GR399": "Intel® Arc™ Pro A30M Grafikleri",
      "tr_GR400": "Intel® Arc™ Pro A40/A50 Grafikleri",
      "tr_GR401": "Intel® Arc™ Pro A60 Grafikleri",
      "tr_GR402": "Intel® Arc™ Pro A60M Grafikleri",
      "tr_GR403": "Intel® Grafikleri",
      "tr_GR404": "Intel® HD Grafikleri 2000",
      "tr_GR405": "Intel® HD Grafikleri 2500",
      "tr_GR406": "Intel® HD Grafikleri 3000",
      "tr_GR407": "Intel® HD Grafikleri 400",
      "tr_GR408": "Intel® HD Grafikleri 4000",
      "tr_GR409": "Intel® HD Grafikleri 405",
      "tr_GR410": "Intel® HD Grafikleri 4200",
      "tr_GR411": "Intel® HD Grafikleri 4400",
      "tr_GR412": "Intel® HD Grafikleri 4600",
      "tr_GR413": "Intel® HD Grafikleri 500",
      "tr_GR414": "Intel® HD Grafikleri 5000",
      "tr_GR415": "Intel® HD Grafikleri 505",
      "tr_GR416": "Intel® HD Grafikleri 510",
      "tr_GR417": "Intel® HD Grafikleri 515",
      "tr_GR418": "Intel® HD Grafikleri 520",
      "tr_GR419": "Intel® HD Grafikleri 530",
      "tr_GR420": "Intel® HD Grafikleri 5300",
      "tr_GR421": "Intel® HD Grafikleri 5500",
      "tr_GR422": "Intel® HD Grafikleri 5600",
      "tr_GR423": "Intel® HD Grafikleri 6000",
      "tr_GR424": "Intel® HD Grafikleri 610",
      "tr_GR425": "Intel® HD Grafikleri 615",
      "tr_GR426": "Intel® HD Grafikleri 620",
      "tr_GR427": "Intel® HD Grafikleri 630",
      "tr_GR428": "Intel® HD Grafikleri",
      "tr_GR429": "Intel® Iris® Grafikleri 5100",
      "tr_GR430": "Intel® Iris® Grafikleri 540",
      "tr_GR431": "Intel® Iris® Grafikleri 550",
      "tr_GR432": "Intel® Iris® Grafikleri 6100",
      "tr_GR433": "Intel® Iris® Plus Grafikleri 640",
      "tr_GR434": "Intel® Iris® Plus Grafikleri 645",
      "tr_GR435": "Intel® Iris® Plus Grafikleri 650",
      "tr_GR436": "Intel® Iris® Plus Grafikleri 655",
      "tr_GR437": "Intel® Iris® Plus Grafikleri",
      "tr_GR438": "Intel® Iris® Pro Grafikleri 5200",
      "tr_GR439": "Intel® Iris® Pro Grafikleri 580",
      "tr_GR440": "Intel® Iris® Pro Grafikleri 6200",
      "tr_GR441": "Intel® Iris® X<sup>e</sup> Grafikleri",
      "tr_GR442": "Intel® Iris® X<sup>e</sup> MAX Grafikleri A220M",
      "tr_GR443": "Intel® Iris® X<sup>e</sup> MAX Grafikleri",
      "tr_GR444": "Intel® Entegre Grafikleri",
      "tr_GR445": "Intel® UHD Grafikleri 600",
      "tr_GR446": "Intel® UHD Grafikleri 605",
      "tr_GR447": "Intel® UHD Grafikleri 610",
      "tr_GR448": "Intel® UHD Grafikleri 615",
      "tr_GR449": "Intel® UHD Grafikleri 617",
      "tr_GR450": "Intel® UHD Grafikleri 620",
      "tr_GR451": "Intel® UHD Grafikleri 630",
      "tr_GR452": "Intel® UHD Grafikleri 710",
      "tr_GR453": "Intel® UHD Grafikleri 730",
      "tr_GR454": "Intel® UHD Grafikleri 770",
      "tr_GR455": "Intel® UHD Grafikleri",
      "tr_GR456": "Intel® UHD Grafikleri",
      "tr_GR457": "Intel® UHD Grafikleri",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "Max-Q Tasarımı ile NVIDIA* GeForce* GTX 1050",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "Max-Q Tasarımı ile NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "Max-Q Tasarımı ile NVIDIA* GeForce* GTX 1060",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "Max-Q Tasarımı ile NVIDIA* GeForce* GTX 1070",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "Max-Q Tasarımı ile NVIDIA* GeForce* GTX 1080",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "Max-Q Tasarımı ile NVIDIA* GeForce* GTX 1650",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "Max-Q Tasarımı ile NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "Max-Q Tasarımı ile NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "Max-Q Tasarımı ile NVIDIA* GeForce* RTX 2060",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "Max-Q Tasarımı ile NVIDIA* GeForce* RTX 2070",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "Max-Q Tasarımı ile NVIDIA* GeForce* RTX 2070 Super",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "Max-Q Tasarımı ile NVIDIA* GeForce* RTX 2080",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "Max-Q Tasarımı ile NVIDIA* GeForce* RTX 2080 Super",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "Max-Q Tasarımı ile NVIDIA* Quadro* P2000",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "Max-Q Tasarımı ile NVIDIA* Quadro* P3200",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "Max-Q Tasarımı ile NVIDIA* Quadro* P4000",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "Max-Q Tasarımı ile NVIDIA* Quadro* P4200",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "Max-Q Tasarımı ile NVIDIA* Quadro* P5200",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "Max-Q Tasarımı ile NVIDIA* Quadro* RTX 3000",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "Max-Q Tasarımı ile NVIDIA* Quadro* RTX 4000",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "Max-Q Tasarımı ile NVIDIA* Quadro* RTX 5000",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "Max-Q Tasarımı ile NVIDIA* Quadro* T1000",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "Max-Q Tasarımı ile NVIDIA* Quadro* T2000",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Nesli",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Nesli",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Nesli",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Nesli",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Nesli",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Nesli",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Nesli",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Nesli",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Nesli",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Nesli",
      "tr_GR812": "Intel® Arc™ Grafikleri",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Nesli",
      "tr_GR815": "AMD Radeon* Pro W7900 Çift Yuva"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Hemen Satın Alın",
    "tr_textTryagain": "Tekrar dene",
    "tr_textBuynowErr": "Bağlantı kesintisi nedeniyle, Hemen Satın Alın seçeneği kullanılamıyor",
    "tr_chatbotPlaceholder_text": "Bana cihaz hakkında dilediğiniz her şeyi sorun",
    "tr_deviceSpecs_text": "Cihazın Teknik Özellikleri"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Intel® Hybrid Teknolojili Intel®&nbsp;Core™&nbsp;i3 işlemci"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Intel® Hybrid Teknolojili Intel®&nbsp;Core™&nbsp;i5 işlemci"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Daha Güçlü Bir Oyun Deneyimi için Intel® İşlemcileri Tercih Edin",
                "tr_subtitle": "Intel® teknolojisinin oyun içi bilgisayar optimizasyonları ile Marvel's Avengers'a* hayat verin.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Intel® Extreme Masters Deneyimini Yaşayın",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Dünyanın en iyi oyuncularının rekabet ettiği yer",
                "tr_subtitle": "Video oyunları tarihinin en uzun soluklu etkinlik serisi Intel® Extreme Masters, e-sporun standartlarını belirliyor.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Profesyonellere layık bir oyun deneyimi elde edin",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Intel şampiyonlar yaratır.",
                "tr_subtitle": "2021 Rainbow Six Siege* North American League şampiyonasını kazandıktan sonra, Soniqs oyun geçmişlerini, e-sporun geleceğini ve Intel ile ortaklıklarını değerlendiriyor.",
                "tr_pretitle": "Soniqs'in Katılımıyla",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "VR kullanımına hazır",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Sanal gerçeklik hayat buluyor",
                "tr_subtitle": "Bir bilgisayarda hayat bulan en harika, gerçekçi ve etkileyici sanal gerçeklik deneyimleri. En yeni Intel® Core™ işlemcilerle dilediğiniz yerde, dilediğiniz kişi olabilir ve yepyeni bir boyutta oyun oynamanın keyfine varabilirsiniz.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Xbox* Game Pass Ultimate ve uyumlu bir kontrol cihazına sahip Windows* bilgisayarınızda, cep telefonunuzda ve tabletinizde Sea of Thieves* ve Psychonauts* 2 gibi 100'den fazla yüksek kaliteli konsol oyunu oynayın.",
                "titleArray": [
                  {
                    "tr_title": "Farklı cihazlarda bırlıkte oynayın",
                    "tr_subtitle": "Birlikte oynamak için hazır bekleyen milyonlarca oyuncudan oluşan bir topluluğa sahip Xbox*'un temeline inin. İster dünyanın diğer ucunda ister yanınızda oturuyor olsun, paylaşılan bir oyun kitaplığı üzerinden diğerleriyle bağlantı kurun ve oynayın."
                  },
                  {
                    "tr_title": "Windows* oyun bilgisayarı",
                    "tr_subtitle": "Windows* ve uyumlu bir kontrol cihazına yönelik Xbox* uygulamasını kullanarak konsol oyunları kataloğundaki oyunları buluttan oynayın."
                  },
                  {
                    "tr_title": "Seçin ve oynayın",
                    "tr_subtitle": "Hiç olmadığı kadar fazla yerde harika Xbox* oyunlarının keyfini çıkarın. İster televizyonunuz kullanılırken evde ister kafede veya yüksek hızlı internet bağlantısına sahip herhangi bir yerde, 100'den fazla oyunu bilgisayarınızdan, cep telefonunuzdan ve tabletinizden oynayın."
                  },
                  {
                    "tr_title": "Kontrol cihazı olmadan eğlence",
                    "tr_subtitle": "Xbox* dokunmatik kontrollü oyun deneyimi, tanıdık bir deneyim sağlayarak yeni atlama ve oynama yöntemleri sunar ve fiziksel bir kontrol cihazından alışkın olduğunuz bir oyun deneyimini destekler."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spot",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100'den fazla yapay zeka deneyimi",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Daha az dokunuşla daha fazla kontrol",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Hangi ortamda olursanız olun çerçevede kalın",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Yaratıcılık",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Aydınlatmayı ustaca düzeltin",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Göz alıcı olağanüstü efektleri kolayca ekleyin",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Yapay zekanın gücüyle şarkılara remix yapın",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Üretkenlik",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Üretkenliğinizi ve gizliliğinizi artırın",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Prizden uzakta daha fazla vakit geçirin",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Sizin dünyanız. Sizin cihazlarınızda.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Eğlence",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Prizden uzaktayken bile akıcı yayın izleme",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Hızlı ve sürükleyici oyun deneyimi",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spot",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100'den fazla yapay zeka deneyimi",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "En sevdiğiniz şarkıları kendi tarzınızla tamamen baştan yaratın",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Vizyonunuzu gerçek zamanlı olarak görün",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Yaratıcılık",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Videonuza son halini vermenin en hızlı yolu",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Tüm fotoğrafları kusursuz hale getirin",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Üretkenlik",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Üretkenliğinizi ve gizliliğinizi artırın",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Daha verimli. Daha fazla özgürlük.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Sizin dünyanız. Sizin cihazlarınızda.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Eğlence",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Prizden uzaktayken bile akıcı HD yayın izleme",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Daha fazla yerde sürükleyici oyun deneyimi",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Daha Yaratıcı",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Kolaylaşan fotoğraf düzenleme",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Saniyeler içinde göz alıcı resimler oluşturun",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Siz yazın. Yapay zeka üretsin.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Daha Fazla Performans",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Daha akıcı oyun deneyimine kolayca ulaşmanın yolu",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Prizden uzakta daha fazla vakit geçirin",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra sürükleyici",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Daha Üretken",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Günlük tüm işleriniz için yapay zeka yardımcınız",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Kusursuz video görüşmeleri",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Sizin dünyanız. Sizin cihazlarınızda.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Daha Güvenli",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Verilerinizi daha güvenli ve gizli tutmaya yardımcı olmak için dahili koruma",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Daha Yaratıcı",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Kolaylaşan fotoğraf düzenleme",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Yapay zeka. Bir çırpıda sorunsuz montaj için.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "En sevdiğiniz şarkı sözlerini hızlıca metne çevirin",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Yapay zeka ile saatlerce düzenleme yapmaktan kurtulun",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Daha Fazla Performans",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Daha akıcı oyun deneyimine kolayca ulaşmanın yolu",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Prizden uzakta daha fazla vakit geçirin",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra sürükleyici",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Daha Üretken",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Günlük tüm işleriniz için yapay zeka yardımcınız",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Kusursuz video görüşmeleri",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Sizin dünyanız. Sizin cihazlarınızda.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Daha Güvenli",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Verilerinizi daha güvenli ve gizli tutmaya yardımcı olmak için dahili koruma",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Tüm eğlenceniz tek bir bilgisayarda",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Eğlence merkezi işlevi gören bir bilgisayarda, en sevdiğiniz şarkılar, videolar ve oyunlar için yeterli depolama alanına ve etkileyici görseller oluşturmak için doğru işlemci ve ekran kombinasyonuna ihtiyacınız vardır. Bu bilgisayar üçüne de sahiptir.",
                "titleArray": [
                  {
                    "tr_title": "Yeni nesil görsellere hazır olun",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Dijital kitaplığınızı oluşturun",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Eğlenceye açılan pencere",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Kusursuz Oyun ve Canlı Yayın",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Dijital kitaplığınızı oluşturun",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Eğlenceye açılan pencere",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Daha fazla üretin. İstediğiniz&nbsp;her&nbsp;yerde.",
              "tr_tileTitle1": "Daha fazlasını yapın. Hem&nbsp;de&nbsp;hızla.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Bu bilgisayar, mükemmel bir güç ve taşınabilirlik dengesi sunar. İnce ve hafif cihazlara uygun bir işlemcinin yanı sıra, bu bilgisayarda her zaman bağlantıda kalmanız için gereken Wi&#8209;Fi teknolojisini ve prizden uzak geçireceğiniz saatler için doğru pili bulacaksınız.",
                "tr_subtitle1": "Bu bilgisayar, daha kısa zamanda daha fazla iş yapmanızı sağlayacak mükemmel güç ve performans dengesi sağlar. Geniş kapasiteli bellekle hızlandırılmış güçlü işlemci ve ışık hızında Wi-Fi bir araya gelerek günlük görevlerinizi kolaylaştırır.",
                "tr_subtitle2": "Bu bilgisayar, mükemmel bir güç ve taşınabilirlik dengesi sunar. Bu bilgisayar, ince ve hafif cihazlara uygun bir işlemcinin yanı sıra, her zaman bağlantıda kalmanız için gereken Wi‑Fi teknolojisini ve çoklu görev çalıştırma için doğru belleği sunar.",
                "titleArray": [
                  {
                    "tr_title": "Daha fazlası için tasarlandı",
                    "tr_title1": "İhtiyaç duyduğunuz akıllı performans",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Sınıfının en iyisi bağlantılar",
                    "tr_title1": "Önemli olan tüm bağlantıları kurun",
                    "tr_title2": "Wi‑Fi'da büyük bir gelişme",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Daha uzun süre kablosuz kalın",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Programlar arasında kusursuz geçişler",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Yaratma özgürlüğü",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "İçerik oluşturma, bilgisayarınız için zorlu görevlerden biridir. Neyse&nbsp;ki bu bileşenler yaratıcılık sürecinizi kolaylaştıracak güce sahiptir.",
                "titleArray": [
                  {
                    "tr_title": "İçerik oluşturmanın daha akıllı bir yolu",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Görsellerinize hayat verin",
                    "tr_title1": "Haydi Oluşturun",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Çoklu görev yürütme için bellek",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bu bilgisayarın içine bakın",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Aşağıdakiler, yeni bir bilgisayar seçerken dikkat etmeniz gereken üç ana bileşendir. Yeni bilgisayarınızı nasıl kullanacağınıza karar vererek, bu üç bileşenden ne kadarına ihtiyacınız olacağını büyük ölçüde belirlemiş olursunuz.",
                "titleArray": [
                  {
                    "tr_title": "Oyun ve Veri Akışına Yönelik Performans",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Depolama",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Bellek",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Tüm eğlenceniz tek bir bilgisayarda",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Eğlence merkezi işlevi gören bir bilgisayarda, en sevdiğiniz şarkılar, videolar ve oyunlar için yeterli depolama alanına ve etkileyici görseller oluşturmak için doğru işlemci ve ekran kombinasyonuna ihtiyacınız vardır. Bu bilgisayar üçüne de sahiptir.",
                "titleArray": [
                  {
                    "tr_title": "Yeni nesil görsellere hazır olun",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Dijital kitaplığınızı oluşturun",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Eğlenceye açılan pencere",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Kusursuz Oyun ve Canlı Yayın",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Dijital kitaplığınızı oluşturun",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Eğlenceye açılan pencere",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bellek",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Ekran",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Yüksek hızlı çoklu görev yürütme",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "YÜKSEK HIZLI ÇOKLU GÖREV YÜRÜTME",
                "tr_subtitle": "Hız kaybetmeden daha fazlasını yapın",
                "tr_subtitle1": "İşte, hızlı çoklu görev yürütme için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Aynı anda birden fazla programla başa çıkabilen bir işlemci."
                  },
                  {
                    "tr_title": "Hızlı, güçlü ve güvenilir Wi‑Fi."
                  },
                  {
                    "tr_title": "Uygulamalar arasında sorunsuzca geçiş yapan bellek."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Görüntülü sohbet",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "GÖRÜNTÜLÜ SOHBET",
                "tr_subtitle": "Kristal netliğinde bağlantılar",
                "tr_subtitle1": "İşte, görüntülü sohbet için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Aynı anda görselleri hem kaydedebilen hem&nbsp;de paylaşabilen bir işlemci."
                  },
                  {
                    "tr_title": "Hızlı, güçlü ve güvenilir Wi‑Fi."
                  },
                  {
                    "tr_title": "Kendinizi son derece net bir şekilde ifade etmenize yardımcı olacak bir web kamerası."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR kullanımına hazır",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Sanal gerçeklik hayat buluyor",
                "tr_subtitle": "Tamamıyla sanal bir dünya yaratmak için ne gerekir? Bilgisayarda işlemci, grafik kartı ve bellek arasında koordinasyona ihtiyaç vardır.",
                "titleArray": [
                  {
                    "tr_title": "Sanal dünyalarınızı canlandırın",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Gerçekler ayrıntılarda gizlidir",
                    "tr_title1": "Maceraya katılın",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Video düzenleme",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VİDEO DÜZENLEME",
                "tr_subtitle": "Filmlerin sihrini gerçeğe dönüştürün",
                "tr_subtitle1": "İşte, video düzenleme için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Büyük dosyalarla başa çıkabilen bir işlemci."
                  },
                  {
                    "tr_title": "Düzenleme yazılımlarını hızlı ve duyarlı hale getiren bellek."
                  },
                  {
                    "tr_title": "Video işleme sürelerini kısaltan grafikler."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Yüksek hızlı çoklu görev yürütme",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "YÜKSEK HIZLI ÇOKLU GÖREV YÜRÜTME",
                "tr_subtitle": "Hız kaybetmeden daha fazlasını yapın",
                "tr_subtitle1": "İşte, hızlı çoklu görev yürütme için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Aynı anda birden fazla programla başa çıkabilen bir işlemci."
                  },
                  {
                    "tr_title": "Hızlı, güçlü ve güvenilir Wi‑Fi."
                  },
                  {
                    "tr_title": "Uygulamalar arasında sorunsuzca geçiş yapan bellek."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR kullanımına hazır",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Sanal gerçeklik hayat buluyor",
                "tr_subtitle": "Tamamıyla sanal bir dünya yaratmak için ne gerekir? Bilgisayarda işlemci, grafik kartı ve bellek arasında koordinasyona ihtiyaç vardır.",
                "titleArray": [
                  {
                    "tr_title": "Sanal dünyalarınızı canlandırın",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Gerçekler ayrıntılarda gizlidir",
                    "tr_title1": "Maceraya katılın",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D modelleme",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D MODELLEME",
                "tr_subtitle": "Üst düzey eserler",
                "tr_subtitle1": "İşte, 3D modelleme için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "İleri seviye yaratıcılık yazılımlarıyla başa çıkabilen bir işlemci."
                  },
                  {
                    "tr_title": "Büyük dosyaları hızla açabilen bellek."
                  },
                  {
                    "tr_title": "Güçlü görüntü oluşturma ve düzenleme için grafikler."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Gerçek zamanlı iş birliği",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "GERÇEK ZAMANLI İŞ BİRLİĞİ",
                "tr_subtitle": "Dilediğiniz yerden çalışın",
                "tr_subtitle1": "İşte, çevrimiçi iş birlikleri için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Ofis yazılımlarına güç veren bir işlemci."
                  },
                  {
                    "tr_title": "Güvenebileceğiniz Wi‑Fi."
                  },
                  {
                    "tr_title": "Berrak HD kalitesinde kayıt yapan bir web kamerası."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR kullanımına hazır",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Sanal gerçeklik hayat buluyor",
                "tr_subtitle": "Tamamıyla sanal bir dünya yaratmak için ne gerekir? Bilgisayarda işlemci, grafik kartı ve bellek arasında koordinasyona ihtiyaç vardır.",
                "titleArray": [
                  {
                    "tr_title": "Sanal dünyalarınızı canlandırın",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Gerçekler ayrıntılarda gizlidir",
                    "tr_title1": "Maceraya katılın",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ dizüstü bilgisayarlar",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Bu kadar ince ve hafif dizüstü bilgisayarların böylesine güçlü olması beklenmez",
                "tr_subtitle": "Mobil performans için tasarlanan Intel® Evo™ dizüstü bilgisayarlar, en çarpıcı ince ve hafif tasarımlarda ihtiyacınız olan tüm hızı, grafikleri ve pil ömrünü sunar.",
                "titleArray": [
                  {
                    "tr_title": "Dizüstü bilgisayarlar giderek daha fazla önem kazanıyor, o yüzden biz de daha çok iş yapabilmelerini sağladık.",
                    "tr_subtitle": "Gezinmek, döndürmek ve yakınlaştırmak için dokunmatik ekranınızı kullanın."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Işık hızında uyanır",
                    "tr_subtitle": "Anında uyanma özelliğiyle bilgisayarınız uykudan bir saniyeden daha kısa bir sürede hazır hale geçebilir."
                  },
                  {
                    "tr_title": "Sürükleyici görüntüler",
                    "tr_subtitle": "Harika bir ekran ile net çözünürlük ve&nbsp;capcanlı&nbsp;renklerle görüntülerinize hayat katın."
                  },
                  {
                    "tr_title": "İşlemleri hızla tamamlayın",
                    "tr_subtitle": "11.&nbsp;Nesil Intel®&nbsp;Core™ işlemcilerin sunduğu ultra hızlı performansın keyfini çıkarın."
                  },
                  {
                    "tr_title": "Daha uzun süre prizlerden uzak kalın",
                    "tr_subtitle": "Pil ömrünüzü optimize eden ve ışık hızında şarj olan bir bilgisayarla asla geride kalmayın."
                  },
                  {
                    "tr_title": "Evrensel kablo bağlantısı",
                    "tr_subtitle": "Thunderbolt™&nbsp;4 teknolojisi diğer cihazlarınızı bilgisayarınıza bağlamayı kolay, hızlı ve güvenli hale getirir.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Hızlı ve güvenilir bağlantılar",
                    "tr_subtitle": "Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+) ile güvenebileceğiniz bir bağlantı üzerinden görüntülü sohbet edin veya dosya paylaşın.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ dizüstü bilgisayarlar",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "İhtiyacınız olan her şey ve daha fazlası ince ve hafif bir dizüstü bilgisayarda.",
                "tr_subtitle": "Intel® Evo™ dizüstü bilgisayarlar, mobi̇l performans için tasarlanmıştır ve ne yapıyor olursanız olun size en iyi deneyimi<sup>2</sup> sunmayı amaçlar.",
                "titleArray": [
                  {
                    "tr_title": "Dizüstü bilgisayarlar giderek daha fazla önem kazanıyor, o yüzden biz de daha çok iş yapabilmelerini sağladık.",
                    "tr_subtitle": "Gezinmek, döndürmek ve yakınlaştırmak için dokunmatik ekranınızı kullanın."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Işık hızında uyanır",
                    "tr_subtitle": "Anında uyanma özelliğiyle bilgisayarınız uykudan bir saniyeden daha kısa bir sürede hazır hale geçebilir."
                  },
                  {
                    "tr_title": "Sürükleyici görüntüler",
                    "tr_subtitle": "Harika bir ekran ile net çözünürlük ve&nbsp;capcanlı&nbsp;renklerle görüntülerinize hayat katın."
                  },
                  {
                    "tr_title": "Devrim niteliğinde performans",
                    "tr_subtitle": "12. Nesil Intel® Core™ işlemcilerin akıllı performansıyla en çok ihtiyaç duyduğunuz alanda ekstra güç elde edin."
                  },
                  {
                    "tr_title": "Daha uzun süre prizlerden uzak kalın",
                    "tr_subtitle": "Pil ömrünüzü optimize eden ve ışık hızında şarj olan bir bilgisayarla asla geride kalmayın."
                  },
                  {
                    "tr_title": "Evrensel kablo bağlantısı",
                    "tr_subtitle": "Thunderbolt™&nbsp;4 teknolojisi diğer cihazlarınızı bilgisayarınıza bağlamayı kolay, hızlı ve güvenli hale getirir.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Hızlı ve güvenilir bağlantılar",
                    "tr_subtitle": "Intel® Wi-Fi 6E (Gig+) ile güvenebileceğiniz bir bağlantı üzerinden görüntülü görüşme yapın veya dosya paylaşın.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ dizüstü bilgisayarlar",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Bu kadar ince ve hafif dizüstü bilgisayarların böylesine güçlü olması beklenmez",
                "tr_subtitle": "Mobil performans için tasarlanan Intel® Evo™ dizüstü bilgisayarlar, en çarpıcı ince ve hafif tasarımlarda ihtiyacınız olan tüm hızı, grafikleri ve pil ömrünü sunar.",
                "titleArray": [
                  {
                    "tr_title": "Dizüstü bilgisayarlar giderek daha fazla önem kazanıyor, o yüzden biz de daha çok iş yapabilmelerini sağladık.",
                    "tr_subtitle": "Gezinmek, döndürmek ve yakınlaştırmak için dokunmatik ekranınızı kullanın."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Işık hızında uyanır",
                    "tr_subtitle": "Anında uyanma özelliğiyle bilgisayarınız uykudan bir saniyeden daha kısa bir sürede hazır hale geçebilir."
                  },
                  {
                    "tr_title": "Sürükleyici görüntüler",
                    "tr_subtitle": "Harika bir ekran ile net çözünürlük ve&nbsp;capcanlı&nbsp;renklerle görüntülerinize hayat katın."
                  },
                  {
                    "tr_title": "İşlemleri hızla tamamlayın",
                    "tr_subtitle": "11.&nbsp;Nesil Intel®&nbsp;Core™ işlemcilerin sunduğu ultra hızlı performansın keyfini çıkarın."
                  },
                  {
                    "tr_title": "Daha uzun süre prizlerden uzak kalın",
                    "tr_subtitle": "Pil ömrünüzü optimize eden ve ışık hızında şarj olan bir bilgisayarla asla geride kalmayın."
                  },
                  {
                    "tr_title": "Evrensel kablo bağlantısı",
                    "tr_subtitle": "Thunderbolt™&nbsp;4 teknolojisi diğer cihazlarınızı bilgisayarınıza bağlamayı kolay, hızlı ve güvenli hale getirir.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Hızlı ve güvenilir bağlantılar",
                    "tr_subtitle": "Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+) ile güvenebileceğiniz bir bağlantı üzerinden görüntülü sohbet edin veya dosya paylaşın.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ dizüstü bilgisayarlar",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "İhtiyacınız olan her şey ve daha fazlası ince ve hafif bir dizüstü bilgisayarda.",
                "tr_subtitle": "Intel® Evo™ dizüstü bilgisayarlar, mobi̇l performans için tasarlanmıştır ve ne yapıyor olursanız olun size en iyi deneyimi<sup>2</sup> sunmayı amaçlar.",
                "titleArray": [
                  {
                    "tr_title": "Dizüstü bilgisayarlar giderek daha fazla önem kazanıyor, o yüzden biz de daha çok iş yapabilmelerini sağladık.",
                    "tr_subtitle": "Gezinmek, döndürmek ve yakınlaştırmak için dokunmatik ekranınızı kullanın."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Işık hızında uyanır",
                    "tr_subtitle": "Anında uyanma özelliğiyle bilgisayarınız uykudan bir saniyeden daha kısa bir sürede hazır hale geçebilir."
                  },
                  {
                    "tr_title": "Sürükleyici görüntüler",
                    "tr_subtitle": "Harika bir ekran ile net çözünürlük ve&nbsp;capcanlı&nbsp;renklerle görüntülerinize hayat katın."
                  },
                  {
                    "tr_title": "Devrim niteliğinde performans",
                    "tr_subtitle": "12. Nesil Intel® Core™ işlemcilerin akıllı performansıyla en çok ihtiyaç duyduğunuz alanda ekstra güç elde edin."
                  },
                  {
                    "tr_title": "Daha uzun süre prizlerden uzak kalın",
                    "tr_subtitle": "Pil ömrünüzü optimize eden ve ışık hızında şarj olan bir bilgisayarla asla geride kalmayın."
                  },
                  {
                    "tr_title": "Evrensel kablo bağlantısı",
                    "tr_subtitle": "Thunderbolt™&nbsp;4 teknolojisi diğer cihazlarınızı bilgisayarınıza bağlamayı kolay, hızlı ve güvenli hale getirir.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Hızlı ve güvenilir bağlantılar",
                    "tr_subtitle": "Intel® Wi-Fi 6E (Gig+) ile güvenebileceğiniz bir bağlantı üzerinden görüntülü görüşme yapın veya dosya paylaşın.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ dizüstü bilgisayarlar",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "İhtiyacınız olan her şey ve daha fazlası ince ve hafif bir dizüstü bilgisayarda.",
                "tr_subtitle": "Intel® Evo™ dizüstü bilgisayarlar, mobi̇l performans için tasarlanmıştır ve ne yapıyor olursanız olun size en iyi deneyimi<sup>2</sup> sunmayı amaçlar.",
                "titleArray": [
                  {
                    "tr_title": "Dizüstü bilgisayarlar giderek daha fazla önem kazanıyor, o yüzden biz de daha çok iş yapabilmelerini sağladık.",
                    "tr_subtitle": "Gezinmek, döndürmek ve yakınlaştırmak için dokunmatik ekranınızı kullanın."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Işık hızında uyanır",
                    "tr_subtitle": "Anında uyanma özelliğiyle bilgisayarınız uykudan bir saniyeden daha kısa bir sürede hazır hale geçebilir."
                  },
                  {
                    "tr_title": "Sürükleyici görüntüler",
                    "tr_subtitle": "Harika bir ekran ile net çözünürlük ve&nbsp;capcanlı&nbsp;renklerle görüntülerinize hayat katın."
                  },
                  {
                    "tr_title": "Devrim niteliğinde performans",
                    "tr_subtitle": "13. Nesil Intel® Core™ işlemcilerin akıllı performansıyla en çok ihtiyaç duyduğunuz alanda ekstra güç elde edin."
                  },
                  {
                    "tr_title": "Daha uzun süre prizlerden uzak kalın",
                    "tr_subtitle": "Pil ömrünüzü optimize eden ve ışık hızında şarj olan bir bilgisayarla asla geride kalmayın."
                  },
                  {
                    "tr_title": "Evrensel kablo bağlantısı",
                    "tr_subtitle": "Thunderbolt™&nbsp;4 teknolojisi diğer cihazlarınızı bilgisayarınıza bağlamayı kolay, hızlı ve güvenli hale getirir.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Hızlı ve güvenilir bağlantılar",
                    "tr_subtitle": "Intel® Wi-Fi 6E (Gig+) ile güvenebileceğiniz bir bağlantı üzerinden görüntülü görüşme yapın veya dosya paylaşın.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ dizüstü bilgisayarlar",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "İhtiyacınız olan her şey ve daha fazlası ince ve hafif bir dizüstü bilgisayarda.",
                "tr_subtitle": "Intel® Evo™ dizüstü bilgisayarlar, mobi̇l performans için tasarlanmıştır ve ne yapıyor olursanız olun size en iyi deneyimi<sup>2</sup> sunmayı amaçlar.",
                "titleArray": [
                  {
                    "tr_title": "Dizüstü bilgisayarlar giderek daha fazla önem kazanıyor, o yüzden biz de daha çok iş yapabilmelerini sağladık.",
                    "tr_subtitle": "Gezinmek, döndürmek ve yakınlaştırmak için dokunmatik ekranınızı kullanın."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Işık hızında uyanır",
                    "tr_subtitle": "Anında uyanma özelliğiyle bilgisayarınız uykudan bir saniyeden daha kısa bir sürede hazır hale geçebilir."
                  },
                  {
                    "tr_title": "Sürükleyici görüntüler",
                    "tr_subtitle": "Harika bir ekran ile net çözünürlük ve&nbsp;capcanlı&nbsp;renklerle görüntülerinize hayat katın."
                  },
                  {
                    "tr_title": "Devrim niteliğinde performans",
                    "tr_subtitle": "13. Nesil Intel® Core™ işlemcilerin akıllı performansıyla en çok ihtiyaç duyduğunuz alanda ekstra güç elde edin."
                  },
                  {
                    "tr_title": "Daha uzun süre prizlerden uzak kalın",
                    "tr_subtitle": "Pil ömrünüzü optimize eden ve ışık hızında şarj olan bir bilgisayarla asla geride kalmayın."
                  },
                  {
                    "tr_title": "Evrensel kablo bağlantısı",
                    "tr_subtitle": "Thunderbolt™&nbsp;4 teknolojisi diğer cihazlarınızı bilgisayarınıza bağlamayı kolay, hızlı ve güvenli hale getirir.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Hızlı ve güvenilir bağlantılar",
                    "tr_subtitle": "Intel® Wi-Fi 6E (Gig+) ile güvenebileceğiniz bir bağlantı üzerinden görüntülü görüşme yapın veya dosya paylaşın.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spot",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Intel® Evo™ Edition nedir?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Neden Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Yaratıcılık",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Daha fazla yerde daha&nbsp;yaratıcı olun",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Son derece hızlı fotoğraf düzenleme",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Anında orijinal sanat eserleri oluşturun",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Üretkenlik",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rewind AI ile üretkenliğinizi en üst düzeye çıkarın",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Video paylaşmadan önce yapay zeka ile sahte görüntüleri tespit edin",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Sizin dünyanız. Sizin cihazlarınızda.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilite",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hareketle kontrol özelliğiyle tanışmak için ellerinizi kullanın",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Sizi yavaşlatmayan pil ömrü",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Otomatik olarak bağlantıda kalmanızı sağlar",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spot",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Intel® Evo™ Edition nedir?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Neden Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Yaratıcılık",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hızlı ve sorunsuz video oynatma",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Yapay zekanın gücüyle hit şarkılar üretin",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Videonuza son halini vermenin en hızlı yolu",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Üretkenlik",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rewind AI ile üretkenliğinizi en üst düzeye çıkarın",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Video paylaşmadan önce yapay zeka ile sahte görüntüleri tespit edin",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Sizin dünyanız. Sizin cihazlarınızda.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilite",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Neredeyse her yerden iş birliği yapın",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Sizi yavaşlatmayan pil ömrü",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Otomatik olarak bağlantıda kalmanızı sağlar",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Tüm eğlenceniz tek bir bilgisayarda",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Eğlence merkezi işlevi gören bir bilgisayarda, en sevdiğiniz şarkılar, videolar ve oyunlar için yeterli depolama alanına ve etkileyici görseller oluşturmak için doğru işlemci ve ekran kombinasyonuna ihtiyacınız vardır. Bu bilgisayar üçüne de sahiptir.",
                "titleArray": [
                  {
                    "tr_title": "Etkileyici oyun ve canlı yayın",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Dijital kitaplığınızı oluşturun",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Eğlenceye açılan pencere",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Kusursuz Oyun ve Canlı Yayın",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Dijital kitaplığınızı oluşturun",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Eğlenceye açılan pencere",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Daha fazla üretin. İstediğiniz&nbsp;her&nbsp;yerde.",
              "tr_tileTitle1": "Daha fazlasını yapın. Hem&nbsp;de&nbsp;hızla.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Bu bilgisayar, mükemmel bir güç ve taşınabilirlik dengesi sunar. İnce ve hafif cihazlara uygun bir işlemcinin yanı sıra, bu bilgisayarda her zaman bağlantıda kalmanız için gereken Wi&#8209;Fi teknolojisini ve prizden uzak geçireceğiniz saatler için doğru pili bulacaksınız.",
                "tr_subtitle1": "Bu bilgisayar, daha kısa zamanda daha fazla iş yapmanızı sağlayacak mükemmel güç ve performans dengesi sağlar. Geniş kapasiteli bellekle hızlandırılmış güçlü işlemci ve ışık hızında Wi-Fi bir araya gelerek günlük görevlerinizi kolaylaştırır.",
                "tr_subtitle2": "Bu bilgisayar, mükemmel bir güç ve taşınabilirlik dengesi sunar. Bu bilgisayar, ince ve hafif cihazlara uygun bir işlemcinin yanı sıra, her zaman bağlantıda kalmanız için gereken Wi‑Fi teknolojisini ve çoklu görev çalıştırma için doğru belleği sunar.",
                "titleArray": [
                  {
                    "tr_title": "İhtiyaç duyduğunuz akıllı performans",
                    "tr_title1": "Akıllı performans ile hızlanın",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Sınıfının en iyisi bağlantılar",
                    "tr_title1": "Önemli olan tüm bağlantıları kurun",
                    "tr_title2": "Wi‑Fi'da büyük bir gelişme",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Daha uzun süre kablosuz kalın",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Programlar arasında kusursuz geçişler",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Yaratma özgürlüğü",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "İçerik oluşturma, bilgisayarınız için zorlu görevlerden biridir. Neyse&nbsp;ki bu bileşenler yaratıcılık sürecinizi kolaylaştıracak güce sahiptir.",
                "titleArray": [
                  {
                    "tr_title": "Hızlandırılmış yaratıcılık",
                    "tr_title1": "Yaratıcı projelerinizi ışık hızında tamamlayın",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Üstün içerik oluşturma deneyimi",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Çoklu görev yürütme için bellek",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bu bilgisayarın içine bakın",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Aşağıdakiler, yeni bir bilgisayar seçerken dikkat etmeniz gereken üç ana bileşendir. Yeni bilgisayarınızı nasıl kullanacağınıza karar vererek, bu üç bileşenden ne kadarına ihtiyacınız olacağını büyük ölçüde belirlemiş olursunuz.",
                "titleArray": [
                  {
                    "tr_title": "İşlemci",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Depolama",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Bellek",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Yaratma özgürlüğü",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "İçerik oluşturma, bilgisayarınız için zorlu görevlerden biridir. Neyse&nbsp;ki bu bileşenler yaratıcılık sürecinizi kolaylaştıracak güce sahiptir.",
                "titleArray": [
                  {
                    "tr_title": "İçerik oluşturmanın daha akıllı bir yolu",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Görsellerinize hayat verin",
                    "tr_title1": "Haydi Oluşturun",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Çoklu görev yürütme için bellek",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Daha fazla üretin. İstediğiniz&nbsp;her&nbsp;yerde.",
              "tr_tileTitle1": "Daha fazlasını yapın. Hem&nbsp;de&nbsp;hızla.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Bu bilgisayar, mükemmel bir güç ve taşınabilirlik dengesi sunar. İnce ve hafif cihazlara uygun bir işlemcinin yanı sıra, bu bilgisayarda her zaman bağlantıda kalmanız için gereken Wi&#8209;Fi teknolojisini ve prizden uzak geçireceğiniz saatler için doğru pili bulacaksınız.",
                "tr_subtitle1": "Bu bilgisayar, daha kısa zamanda daha fazla iş yapmanızı sağlayacak mükemmel güç ve performans dengesi sağlar. Geniş kapasiteli bellekle hızlandırılmış güçlü işlemci ve ışık hızında Wi-Fi bir araya gelerek günlük görevlerinizi kolaylaştırır.",
                "tr_subtitle2": "Bu bilgisayar, mükemmel bir güç ve taşınabilirlik dengesi sunar. Bu bilgisayar, ince ve hafif cihazlara uygun bir işlemcinin yanı sıra, her zaman bağlantıda kalmanız için gereken Wi‑Fi teknolojisini ve çoklu görev çalıştırma için doğru belleği sunar.",
                "titleArray": [
                  {
                    "tr_title": "Daha fazlası için tasarlandı",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Sınıfının en iyisi bağlantılar",
                    "tr_title1": "Önemli olan tüm bağlantıları kurun",
                    "tr_title2": "Wi‑Fi'da büyük bir gelişme",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Daha uzun süre kablosuz kalın",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Programlar arasında kusursuz geçişler",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR kullanımına hazır",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Sanal gerçeklik hayat buluyor",
                "tr_subtitle": "Tamamıyla sanal bir dünya yaratmak için ne gerekir? Bilgisayarda işlemci, grafik kartı ve bellek arasında koordinasyona ihtiyaç vardır.",
                "titleArray": [
                  {
                    "tr_title": "Sanal dünyalarınızı canlandırın",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Gerçekler ayrıntılarda gizlidir",
                    "tr_title1": "Maceraya katılın",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>Gözler bayram ediyor",
      "tr_sub_header": "Mavi ışık, gözlerinize rahatsızlık verebilir ve uyku düzeninizi bozabilir. Samsung’un SGS Eye Care ekran testi yapılmış OLED ekranı yalnızca renkleri değiştirerek değil, potansiyel olarak zararlı mavi ışığın dalga boyunu azaltıp gözleri rahatlatarak ve göz yorgunluğunu azaltarak yardımcı olur.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Daha Az Zararlı Mavi Işık"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Her yönüyle ultra",
      "tr_sub_header": "OLED’in ultra hafif ve ultra ince tasarımı, ağırlığı minimuma indirirken görüntü kalitesini maksimuma çıkarır."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Gerçek Siyah",
      "tr_sub_header": "0,0005 nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "%74<br/><span class='conventional_text'>Geleneksel</span>",
      "tr_sub_header": "%120<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Veri akışı ile yayın",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VERİ AKIŞI İLE YAYIN",
                "tr_subtitle": "Sonsuz eğlence",
                "tr_subtitle1": "İşte, veri akışı ile yayın için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "HD yayın yapabilen bir işlemci."
                  },
                  {
                    "tr_title": "Parlak, büyüleyici görseller sunan bir ekran."
                  },
                  {
                    "tr_title": "Daha kısa arabelleğe alma ve yükleme süreleri sağlayan Wi‑Fi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "İnternette gezinme",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "İNTERNETTE GEZİNME",
                "tr_subtitle": "İnternet artık daha kolay",
                "tr_subtitle1": "İşte, internette gezinmek için bu bilgisayarda bulduğumuz harika özellikler:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Medya açısından zengin sayfaları hızla yükleyebilen bir işlemci."
                  },
                  {
                    "tr_title": "Hızlı ve güvenilir Wi‑Fi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Görüntülü sohbet",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "GÖRÜNTÜLÜ SOHBET",
                "tr_subtitle": "Kristal netliğinde bağlantılar",
                "tr_subtitle1": "İşte, görüntülü sohbet için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Aynı anda görselleri hem kaydedebilen hem&nbsp;de paylaşabilen bir işlemci."
                  },
                  {
                    "tr_title": "Hızlı, güçlü ve güvenilir Wi‑Fi."
                  },
                  {
                    "tr_title": "Kendinizi son derece net bir şekilde ifade etmenize yardımcı olacak bir web kamerası."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Yüksek hızlı çoklu görev yürütme",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "YÜKSEK HIZLI ÇOKLU GÖREV YÜRÜTME",
                "tr_subtitle": "Hız kaybetmeden daha fazlasını yapın",
                "tr_subtitle1": "İşte, hızlı çoklu görev yürütme için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Aynı anda birden fazla programla başa çıkabilen bir işlemci."
                  },
                  {
                    "tr_title": "Hızlı, güçlü ve güvenilir Wi‑Fi."
                  },
                  {
                    "tr_title": "Uygulamalar arasında sorunsuzca geçiş yapan bellek."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Görüntülü sohbet",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "GÖRÜNTÜLÜ SOHBET",
                "tr_subtitle": "Kristal netliğinde bağlantılar",
                "tr_subtitle1": "İşte, görüntülü sohbet için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Aynı anda görselleri hem kaydedebilen hem&nbsp;de paylaşabilen bir işlemci."
                  },
                  {
                    "tr_title": "Hızlı, güçlü ve güvenilir Wi‑Fi."
                  },
                  {
                    "tr_title": "Kendinizi son derece net bir şekilde ifade etmenize yardımcı olacak bir web kamerası."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Günlük oyun deneyimi",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "GÜNLÜK OYUN DENEYİMİ",
                "tr_subtitle": "Oyun zamanı",
                "tr_subtitle1": "İşte, hafif oyun deneyimi için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Akıcı oyun deneyimine güç veren bir işlemci."
                  },
                  {
                    "tr_title": "Aynı anda hem sesli sohbet hem de canlı yayına olanak veren bellek."
                  },
                  {
                    "tr_title": "Şaşırtıcı görsellere güç veren grafikler."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Fotoğraf düzenleme",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FOTOĞRAF DÜZENLEME",
                "tr_subtitle": "Her pikseliyle mükemmel fotoğraflar",
                "tr_subtitle1": "İşte, fotoğraf düzenleme için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Büyük görsel gruplarını düzenleyebilecek güce sahip bir işlemci."
                  },
                  {
                    "tr_title": "Düzenleme yazılımlarınızı hızlandıran bellek."
                  },
                  {
                    "tr_title": "Dosyaları hızla aktarmanıza olanak veren Thunderbolt™ 4 teknolojisi."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Yüksek hızlı çoklu görev yürütme",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "YÜKSEK HIZLI ÇOKLU GÖREV YÜRÜTME",
                "tr_subtitle": "Hız kaybetmeden daha fazlasını yapın",
                "tr_subtitle1": "İşte, hızlı çoklu görev yürütme için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Aynı anda birden fazla programla başa çıkabilen bir işlemci."
                  },
                  {
                    "tr_title": "Hızlı, güçlü ve güvenilir Wi‑Fi."
                  },
                  {
                    "tr_title": "Uygulamalar arasında sorunsuzca geçiş yapan bellek."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Gerçek zamanlı iş birliği",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "GERÇEK ZAMANLI İŞ BİRLİĞİ",
                "tr_subtitle": "Dilediğiniz yerden çalışın",
                "tr_subtitle1": "İşte, çevrimiçi iş birlikleri için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Ofis yazılımlarına güç veren bir işlemci."
                  },
                  {
                    "tr_title": "Güvenebileceğiniz Wi‑Fi."
                  },
                  {
                    "tr_title": "Berrak HD kalitesinde kayıt yapan bir web kamerası."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Video düzenleme",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VİDEO DÜZENLEME",
                "tr_subtitle": "Filmlerin sihrini gerçeğe dönüştürün",
                "tr_subtitle1": "İşte, video düzenleme için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Büyük dosyalarla başa çıkabilen bir işlemci."
                  },
                  {
                    "tr_title": "Düzenleme yazılımlarını hızlı ve duyarlı hale getiren bellek."
                  },
                  {
                    "tr_title": "Video işleme sürelerini kısaltan grafikler."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Fotoğraf düzenleme",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FOTOĞRAF DÜZENLEME",
                "tr_subtitle": "Her pikseliyle mükemmel fotoğraflar",
                "tr_subtitle1": "İşte, fotoğraf düzenleme için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Büyük görsel gruplarını düzenleyebilecek güce sahip bir işlemci."
                  },
                  {
                    "tr_title": "Düzenleme yazılımlarınızı hızlandıran bellek."
                  },
                  {
                    "tr_title": "Dosyaları hızla aktarmanıza olanak veren Thunderbolt™ 4 teknolojisi."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Video düzenleme",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VİDEO DÜZENLEME",
                "tr_subtitle": "Filmlerin sihrini gerçeğe dönüştürün",
                "tr_subtitle1": "İşte, video düzenleme için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Büyük dosyalarla başa çıkabilen bir işlemci."
                  },
                  {
                    "tr_title": "Düzenleme yazılımlarını hızlı ve duyarlı hale getiren bellek."
                  },
                  {
                    "tr_title": "Video işleme sürelerini kısaltan grafikler."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D modelleme",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D MODELLEME",
                "tr_subtitle": "Üst düzey eserler",
                "tr_subtitle1": "İşte, 3D modelleme için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "İleri seviye yaratıcılık yazılımlarıyla başa çıkabilen bir işlemci."
                  },
                  {
                    "tr_title": "Büyük dosyaları hızla açabilen bellek."
                  },
                  {
                    "tr_title": "Güçlü görüntü oluşturma ve düzenleme için grafikler."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Gerçek zamanlı iş birliği",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "GERÇEK ZAMANLI İŞ BİRLİĞİ",
                "tr_subtitle": "Dilediğiniz yerden çalışın",
                "tr_subtitle1": "İşte, çevrimiçi iş birlikleri için bu bilgisayarda bulduğumuz muhteşem özellikler:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Ofis yazılımlarına güç veren bir işlemci."
                  },
                  {
                    "tr_title": "Güvenebileceğiniz Wi‑Fi."
                  },
                  {
                    "tr_title": "Berrak HD kalitesinde kayıt yapan bir web kamerası."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Intel® Unison™ uygulaması",
    "tr_title": "Bağlantılı dünyanızı serbest bırakın",
    "tr_subtitle": "Telefon bildirimlerini, aramaları ve mesajları bilgisayarınızdan yönetirken dosyaları ve fotoğrafları sorunsuz bir şekilde aktarın.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "DOSYA VE FOTOĞRAF AKTARIN",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "ARAMA YAPIN VE YANITLAYIN",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "MESAJ GÖNDERİN VE ALIN",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "TELEFON BİLDİRİMLERİNİ YÖNETİN",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "BAĞLANTILI DÜNYANIZI SERBEST BIRAKIN",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}