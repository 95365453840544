const Library_mobile_de = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Ausgestattet mit einem [cpuDisplay] Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Ausgestattet mit einem [cpuDisplay] Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Ähnlich wie ein Gehirn steuert der Prozessor, was der PC macht. Das bedeutet, dass die Videos, die du dir ansiehst, die Spiele, die du spielst, die Websites, die du besuchst, alle mit deinem Prozessor beginnen.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Der Prozessor ist das Gehirn deines PCs."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Leistung zum attraktiven Preis.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Silver Prozessor",
                    "tr_subtitle": "Mit ausreichend Leistung, um auf Web-Dateien zuzugreifen, kannst du mühelos mit deiner digitalen Welt Schritt halten. Durch reibungsloses Hin- und Herschalten zwischen Programmen gelingt müheloses Multitasking und in kürzerer Zeit ist mehr erledigt.",
                    "tr_gen_core_title": "Intel® Pentium® Silver Prozessor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Gold Prozessor",
                    "tr_subtitle": "Gesteigerte Produktivität: Mehr Verarbeitungsleistung für Zeiten mit höchsten Anforderungen. Optimiertes Web-Browsing: Medienintensive Webseiten werden schnell geladen, um mehr erleben zu können und weniger warten zu müssen.",
                    "tr_gen_core_title": "Intel® Pentium® Gold Prozessor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Celeron® Prozessor",
                    "tr_subtitle": "Verbesserte Leistung für Arbeit und Unterhaltung. Spielfilme und Web-Videos mit fantastischer Bildschärfe genießen. Mehr tun können, weniger warten müssen.",
                    "tr_gen_core_title": "Intel® Celeron® Prozessor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Prozessor",
                    "tr_subtitle": "Konzipiert für hervorragende Unterhaltung, Videostreaming und Produktivität. Spielfilme und Web-Videos mit fantastischer Bildschärfe genießen. Mehr tun können, weniger warten müssen.",
                    "tr_gen_core_title": "Intel® Pentium® Prozessor"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Die nötige Leistung. Für&nbsp;den&nbsp;richtigen&nbsp;Preis.",
                "tr_title": "Intel® Prozessor",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Mehrwert der Extraklasse",
                    "tr_subtitle": "Ein Intel® Prozessor kann nahezu jede deiner Aufgaben bewältigen, und das zu einem erschwinglichen Preis. Navigiere ganz einfach zwischen verschiedenen Anwendungen. Oder genieße hochwertige Grafik mit konstanter Leistung.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Zusammenarbeit auf höchstem Niveau",
                    "tr_subtitle": "Die lebendigere Videoqualität, die klarere Audioqualität und die fast 3-mal schnellere Internetverbindung sorgen für eine zuverlässige Zusammenarbeit – wo immer du auch bist.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Es ist ein Unterschied wie Tag und Nacht",
                    "tr_subtitle": "Von morgendlichen Film-Marathons bis hin zu Retuschierarbeiten in letzter Minute – der Intel® Prozessor verlängert die Akkulaufzeit deines Geräts und sorgt so für ein wirklich faszinierendes, unterbrechungsfreies Erlebnis.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Jede Umgebung wird zur Lernumgebung",
                    "tr_subtitle": "Führe mehrere Online-Lerntools ganz einfach und aus jeder Ecke deines Zuhauses aus."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Lerne überall",
                    "tr_subtitle": "Erledige deinen Unterricht, wo immer du willst, und nutze gleichzeitig verschiedene E-Learning-Tools."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hol dir starke Leistung und hohe Produktivität, die perfekt zu deinem Budget und deinem Lifestyle passen.",
                "tr_title": "Leistung von Intel für jedes Budget.",
                "tr_gen_core_title": "Intel® Celeron® Prozessor",
                "processorCompare": {
                  "tr_title": "DIE RICHTIGE KOMBINATION AUS LEISTUNGSNIVEAU UND PREISSEGMENT",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Intel® Celeron® Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™&nbsp;i3 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Schnelles Web-Browsing mit brillanter Mediendarstellung",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Leichter Wechsel zwischen Programmen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gelegenheitsgaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K-Videos streamen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Leistung für einfache Foto- und Videobearbeitung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hohe Produktivität mit intelligenter Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Mit Intel® Core™ [cpu_fam] Prozessor der&nbsp;7.&nbsp;Generation"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Mit Intel® Core™ [cpu_fam] Prozessor der&nbsp;6.&nbsp;Generation"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Mit Intel® Core™ [cpu_fam] Prozessor der&nbsp;5.&nbsp;Generation"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Mit Intel® Core™ [cpu_fam] Prozessor der&nbsp;4.&nbsp;Generation"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Mit Intel® Core™ [cpu_fam] Prozessor der&nbsp;3.&nbsp;Generation"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Mit Intel® Core™ [cpu_fam] Prozessor der&nbsp;2.&nbsp;Generation"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Mit Intel® Core™ [cpu_fam] Prozessor der&nbsp;1.&nbsp;Generation"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Empfehlenswert für:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Mit Intel® Core™ i3 Prozessor der 8. Generation",
                "tr_gen_core_title": "Intel® Core™ i3 Prozessor der 8.&nbsp;Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Die Intel® Core™ Prozessoren der 9.&nbsp;Generation sind darauf ausgelegt, mit deiner digitalen Welt Schritt zu halten. Die Kombination aus Geschwindigkeit und Leistung ermöglicht dir mehr PC-Genuss ohne Frustration.",
                "tr_title": "Mit Intel® Core™ i3 Prozessor der 9. Generation",
                "tr_gen_core_title": "Intel® Core™ i3 Prozessor der 9.&nbsp;Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vielseitige Leistung trifft auf lange Akkulaufzeit, damit du immer und überall arbeiten kannst.",
                "tr_title": "Leistung für den ganzen&nbsp;Tag",
                "tr_gen_core_title": "Intel® Core™ i3 Prozessor der 10.&nbsp;Generation",
                "processorCompare": {
                  "tr_title": "FINDE DAS IDEALE LEISTUNGSNIVEAU DER 10. GENERATION",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™&nbsp;i3 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streamen von Videos in 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Schnelle, zuverlässige Konnektivität",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotomaskierung und Videofilterung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming bei 1080p mit flüssigen Bildraten",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ideal für die Arbeit mit großen Dateien und Kreativprogrammen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mit reichlicher Leistungsreserve wechselst du spielend zwischen gestreamten Spielfilmen und der Ausarbeitung einer Präsentation.",
                "tr_title": "Leistung für den ganzen&nbsp;Tag",
                "tr_gen_core_title": "Intel® Core™ i3 Prozessor der 10.&nbsp;Generation"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Für alle, die immer unterwegs sind: ein Notebook, das speziell für dich entwickelt wurde.",
                "tr_title": "Eine neue Klasse fortschrittlicher Laptops",
                "tr_gen_core_title": "Intel® Core™ i3 Prozessor der 10.&nbsp;Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Mit einem Intel® Core™ i3 Prozessor der 11. Generation, der speziell für tragbare PCs entwickelt wurde, kannst du mehr erledigen als je zuvor, wo immer du auch bist.",
                "tr_title": "Hol mehr aus deinem neuen Laptop heraus",
                "tr_gen_core_title": "Intel® Core™ i3 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i3 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Wenn deine ganze Familie einen Desktop-PC gemeinsam nutzt, um alle Aufgaben zu erledigen, brauchst du die Leistungsfähigkeit und Vielseitigkeit eines Intel® Core™ i3 Prozessors der 11. Generation.",
                "tr_title": "Sei bereit für alles, was bahnbrechende Leistung braucht.",
                "tr_gen_core_title": "Intel® Core™ i3 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i3 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Mit Intel® Core™ i5 Prozessor der 8. Generation",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 8.&nbsp;Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Die Intel® Core™ Prozessoren der 9.&nbsp;Generation sind darauf ausgelegt, mit deiner digitalen Welt Schritt zu halten. Die Kombination aus Geschwindigkeit und Leistung ermöglicht dir mehr PC-Genuss ohne Frustration.",
                "tr_title": "Mit Intel® Core™ i5 Prozessor der 9. Generation",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 9.&nbsp;Generation"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FLÜSSIGE GAMEPLAY- UND VR&#8209;DARSTELLUNG BEI DEN ANSPRUCHSVOLLSTEN TITELN",
                "tr_title": "Intensives Gaming beginnt hier",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 9.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR, DER AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gleichzeitig gamen, streamen und aufnehmen&nbsp;– ohne Kompromisse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Der beste Intel® Core™ Prozessor der 9.&nbsp;Generation für Gamer",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Nutze mühelos mehrere Programme gleichzeitig und sei für alles bereit, egal wo der Tag dich hinführt.",
                "tr_title": "Multitasking unterwegs",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 10.&nbsp;Generation",
                "processorCompare": {
                  "tr_title": "FINDE DAS IDEALE LEISTUNGSNIVEAU DER 10. GENERATION",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™&nbsp;i3 Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streamen von Videos in 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Schnelle, zuverlässige Konnektivität",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotomaskierung und Videofilterung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming bei 1080p mit flüssigen Bildraten",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ideal für die Arbeit mit großen Dateien und Kreativprogrammen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mach mehr aus allem, was du mit dem Computer erledigst oder erlebst – von der Unterhaltung bis zu produktiver Arbeit und Content-Erstellung.",
                "tr_title": "Upgrade auf einen stärkeren PC",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 10.&nbsp;Generation"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Für alle, die immer unterwegs sind: ein Notebook, das speziell für dich entwickelt wurde.",
                "tr_title": "Eine neue Klasse fortschrittlicher Laptops",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 10.&nbsp;Generation"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Speziell für flüssiges Streaming und AAA-Gaming entwickelt",
                "tr_title": "Gaming nach deinen eigenen Regeln",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 10.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR DER 10.&nbsp;GENERATION, DER&nbsp;AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemloses AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen und Aufnehmen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Anspruchsvolle Content-Erstellung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximale Gaming-Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Zur Übertaktung geeignet<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Speziell für flüssiges Streaming und AAA-Gaming entwickelt",
                "tr_title": "Gaming nach deinen eigenen Regeln",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 10.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR DER 10.&nbsp;GENERATION, DER&nbsp;AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemloses AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen und Aufnehmen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Anspruchsvolle Content-Erstellung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximale Gaming-Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Zur Übertaktung geeignet<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Nutze mühelos mehrere Programme gleichzeitig und sei für alles bereit, egal wo der Tag dich hinführt.",
                "tr_title": "Multitasking unterwegs",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 10.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 10.&nbsp;GEN."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Der Intel® Core™ i5 Prozessor der 11.&nbsp;Generation bringt brillante Bildqualität und vielseitige Produktivität auf die flachsten und leichtesten PCs.",
                "tr_title": "Beeindruckende Laptop-Power",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Wenn deine ganze Familie einen Desktop-PC gemeinsam nutzt, um alle Aufgaben zu erledigen, brauchst du die Leistungsfähigkeit und Vielseitigkeit eines Intel® Core™ i5 Prozessors der 11. Generation.",
                "tr_title": "Sei bereit für alles, was bahnbrechende Leistung braucht.",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Der Intel® Core™ i5 Prozessor der 11.&nbsp;Generation bringt brillante Bildqualität und vielseitige Produktivität auf flache und leichte Laptops.",
                "tr_title": "Beeindruckende Laptop-Power",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 11.&nbsp;GEN."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Mit Intel® Core™ i7 Prozessor der 8. Generation",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 8.&nbsp;Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Die Intel® Core™ Prozessoren der 9.&nbsp;Generation sind darauf ausgelegt, mit deiner digitalen Welt Schritt zu halten. Die Kombination aus Geschwindigkeit und Leistung ermöglicht dir mehr PC-Genuss ohne Frustration.",
                "tr_title": "Mit Intel® Core™ i7 Prozessor der 9. Generation",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 9.&nbsp;Generation"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "BIS ZU 32 % MEHR FPS beim Spielen, Streamen und Aufzeichnen im Vergleich zu einem 3 JAHRE ALTEN PC<sup>3</sup>",
                "tr_title": "Die nötige Power, um deine besten Gaming-Momente zu teilen",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 9.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR, DER AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gleichzeitig gamen, streamen und aufnehmen&nbsp;– ohne Kompromisse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Der beste Intel® Core™ Prozessor der 9.&nbsp;Generation für Gamer",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dieses schlanke Gerät wurde speziell für Effizienz optimiert und kann die Art und Weise, wie du Inhalte erstellst, streamst oder mit anderen in Verbindung bleibst, enorm beeinflussen.",
                "tr_title": "Hervorragende Leistung überall",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 10.&nbsp;Generation",
                "processorCompare": {
                  "tr_title": "FINDE DAS IDEALE LEISTUNGSNIVEAU DER 10. GENERATION",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™&nbsp;i3 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streamen von Videos in 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Schnelle, zuverlässige Konnektivität",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotomaskierung und Videofilterung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming bei 1080p mit flüssigen Bildraten",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ideal für die Arbeit mit großen Dateien und Kreativprogrammen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Fortschrittliche Funktionalität macht mehr aus allem, wozu du den Computer nutzt.",
                "tr_title": "Starke Leistung für Alles",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 10.&nbsp;Generation"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Für alle, die immer unterwegs sind: ein Notebook, das speziell für dich entwickelt wurde.",
                "tr_title": "Eine neue Klasse fortschrittlicher Laptops",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 10.&nbsp;Generation"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Profitiere beim Gamen, Streamen und Aufzeichnen von einem Wettbewerbsvorteil.",
                "tr_title": "Power für deine Gaming&#8209;Welt",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 10.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR DER 10.&nbsp;GENERATION, DER&nbsp;AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemloses AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen und Aufnehmen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Anspruchsvolle Content-Erstellung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximale Gaming-Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Zur Übertaktung geeignet<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Profitiere beim Gamen, Streamen und Aufzeichnen von einem Wettbewerbsvorteil.",
                "tr_title": "Power für deine Gaming&#8209;Welt",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 10.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR DER 10.&nbsp;GENERATION, DER&nbsp;AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemloses AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen und Aufnehmen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Anspruchsvolle Content-Erstellung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximale Gaming-Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Zur Übertaktung geeignet<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dieses auf Effizienz ausgelegte, schlanke Gerät kann die Art und Weise, wie du Inhalte erstellst, dich mit anderen verbindest und Videos streamst, erheblich beeinflussen.",
                "tr_title": "Erstklassige Leistung überall",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 10.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 10.&nbsp;GEN."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Der Intel® Core™ i7 Prozessor der 11.&nbsp;Generation ermöglicht anspruchsvolle Content-Erstellung, flüssiges Gaming und Entertainment der nächsten Generation auf ultraportablen PCs.",
                "tr_title": "Bahnbrechende Laptop-Leistung",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Mit der bahnbrechenden Leistung eines Intel® Core™ i7 Prozessors der 11. Generation wird dein Desktop-PC zum Gerät der Wahl für alles, was es bei dir zu Hause zu erledigen gilt.",
                "tr_title": "Arbeiten. Lernen. Spielen. Ohne Grenzen.",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Der Intel® Core™ i7 Prozessor der 11.&nbsp;Generation ermöglicht anspruchsvolle Content-Erstellung, flüssiges Gaming und Entertainment der nächsten Generation auf flachen und leichten Laptops.",
                "tr_title": "Bahnbrechende Laptop-Leistung",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 11.&nbsp;GEN."
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mit der bahnbrechenden Leistung eines Intel® Core™ i7 Prozessors der 11. Generation wird dein Desktop-PC zum Gerät der Wahl für alles, was es bei dir zu Hause zu erledigen gilt.",
                "tr_title": "Content-Erstellung ohne Grenzen",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 11.&nbsp;GEN."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mit Leistung für anspruchsvolle Content-Erstellung, flüssiges Gaming und Entertainment der nächsten Generation auf flachen und leichten Laptops ist dieser Intel® Core™ i7 Prozessor der 11.&nbsp;Generation jetzt noch besser.",
                "tr_title": "Bahnbrechende Laptop-Leistung",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 11.&nbsp;GEN."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Mit Intel® Core™ i9 Prozessor der 8. Generation",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 8.&nbsp;Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Die Intel® Core™ Prozessoren der 9.&nbsp;Generation sind darauf ausgelegt, mit deiner digitalen Welt Schritt zu halten. Die Kombination aus Geschwindigkeit und Leistung ermöglicht dir mehr PC-Genuss ohne Frustration.",
                "tr_title": "Mit Intel® Core™ i9 Prozessor der 9. Generation",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 9.&nbsp;Generation"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "BIS ZU 41 % MEHR FPS beim Spielen, Streamen und Aufzeichnen im Vergleich zu einem 3 JAHRE ALTEN PC<sup>4</sup>",
                "tr_title": "Gamer wollen Power. Der Intel® Core™ i9 liefert sie.",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 9.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR, DER AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gleichzeitig gamen, streamen und aufnehmen&nbsp;– ohne Kompromisse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Der beste Intel® Core™ Prozessor der 9.&nbsp;Generation für Gamer",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Steigere dein Potenzial beim ultimativen Gaming-Erlebnis",
                "tr_title": "Konkurrenzfähig auf höchstem Level",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 10.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR DER 10.&nbsp;GENERATION, DER&nbsp;AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemloses AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen und Aufnehmen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Anspruchsvolle Content-Erstellung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximale Gaming-Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Zur Übertaktung geeignet<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Steigere dein Potenzial beim ultimativen Gaming-Erlebnis",
                "tr_title": "Konkurrenzfähig auf höchstem Level",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 10.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR DER 10.&nbsp;GENERATION, DER&nbsp;AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemloses AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen und Aufnehmen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Anspruchsvolle Content-Erstellung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximale Gaming-Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Zur Übertaktung geeignet<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Steigere dein Potenzial mit dem ultimativen Schöpfererlebnis",
                "tr_title": "Auf höchstem Level Inhalte erstellen",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 10.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 10.&nbsp;GEN.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ Laptops mit einem Intel® Core™ i5 Prozessor der 11. Generation und Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik bieten unglaubliche Geschwindigkeit und Leistung in einem flachen und leichten Design.",
                "tr_title": "Erhöhte Leistung für einen weiterentwickelten Laptop",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ Laptops mit einem Intel® Core™ i7 Prozessor der 11. Generation und Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik bieten fortschrittliche Geschwindigkeit und Leistung für ein erstklassiges mobiles Erlebnis.",
                "tr_title": "Fortschrittliche Leistung für einen weiterentwickelten Laptop",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hol dir hervorragende Ausgewogenheit zwischen Leistung, Mediennutzung und Vernetzungstechnik zu einem fantastischen Preis.",
                "tr_title": "Leistungsfähigkeit und Vernetzung mit sensationellem Preis-Leistungs-Verhältnis.",
                "tr_gen_core_title": "Intel® Pentium® Silver Prozessor",
                "processorCompare": {
                  "tr_title": "DIE RICHTIGE KOMBINATION AUS LEISTUNGSNIVEAU UND PREISSEGMENT",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™&nbsp;i3 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Schnelles Web-Browsing mit brillanter Mediendarstellung",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Leichter Wechsel zwischen Programmen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gelegenheitsgaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K-Videos streamen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Leistung für einfache Foto- und Videobearbeitung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hohe Produktivität mit intelligenter Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Genieße flüssiges Multitasking, einfache Foto- und Videobearbeitung, 4K-Videos und brillante Grafikqualität zu einem unglaublichen Preis.",
                "tr_title": "Beeindruckende Leistung für Arbeit und Freizeit.",
                "tr_gen_core_title": "Intel® Pentium® Gold Prozessor",
                "processorCompare": {
                  "tr_title": "DIE RICHTIGE KOMBINATION AUS LEISTUNGSNIVEAU UND PREISSEGMENT",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™&nbsp;i3 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Schnelles Web-Browsing mit brillanter Mediendarstellung",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Leichter Wechsel zwischen Programmen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gelegenheitsgaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K-Videos streamen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Leistung für einfache Foto- und Videobearbeitung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hohe Produktivität mit intelligenter Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA-Gaming und lange Akkulaufzeit in einem ultraportablen PC. Spiel dein Spiel - ohne Kompromisse.",
                "tr_title": "Mach keine Kompromisse!",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 11.&nbsp;Generation",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 Prozessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "FINDE DEINEN INTEL® CORE™ PROZESSOR FÜR ULTRAPORTABLES GAMING.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Intel® Core™ i5-11300H Prozessor der 11.&nbsp;Gen."
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Intel® Core™ i7-11370H Prozessor der 11.&nbsp;Gen."
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Intel® Core™ i7-11375H Special Edition Prozessor der 11.&nbsp;Gen."
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Bis zu <span class='style_badge'>4,4 GHz</span> max. Taktfrequenz",
                      "tr_td2_title": "Bis zu <span class='style_badge'>4,8 GHz</span> max. Taktfrequenz",
                      "tr_td3_title": "Bis zu <span class='style_badge'>5,0 GHz</span> max. Taktfrequenz"
                    },
                    {
                      "tr_td1_title": "4 Kerne, 8&nbsp;Threads",
                      "tr_td2_title": "4 Kerne, 8&nbsp;Threads",
                      "tr_td3_title": "4 Kerne, 8&nbsp;Threads"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo-Boost-Technik",
                      "tr_td2_title": "Intel® Turbo-Boost-Technik",
                      "tr_td3_title": "Intel® Turbo-Boost-Max-Technik 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Smart-Cache",
                      "tr_td2_title": "12&nbsp;MB Intel® Smart-Cache",
                      "tr_td3_title": "12&nbsp;MB Intel® Smart-Cache"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "1080p-AAA-Gaming mit hohen FPS bei hohen Einstellungen und exzellente Leistung im Akkubetrieb in einem ultraportablen PC.",
                "tr_title": "Hol dir das AAA-Sorglospaket.",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 11.&nbsp;Generation",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 Prozessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "FINDE DEINEN INTEL® CORE™ PROZESSOR FÜR ULTRAPORTABLES GAMING.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Intel® Core™ i5-11300H Prozessor der 11.&nbsp;Gen."
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Intel® Core™ i7-11370H Prozessor der 11.&nbsp;Gen."
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Intel® Core™ i7-11375H Special Edition Prozessor der 11.&nbsp;Gen."
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Bis zu <span class='style_badge'>4,4 GHz</span> max. Taktfrequenz",
                      "tr_td2_title": "Bis zu <span class='style_badge'>4,8 GHz</span> max. Taktfrequenz",
                      "tr_td3_title": "Bis zu <span class='style_badge'>5,0 GHz</span> max. Taktfrequenz"
                    },
                    {
                      "tr_td1_title": "4 Kerne, 8&nbsp;Threads",
                      "tr_td2_title": "4 Kerne, 8&nbsp;Threads",
                      "tr_td3_title": "4 Kerne, 8&nbsp;Threads"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo-Boost-Technik",
                      "tr_td2_title": "Intel® Turbo-Boost-Technik",
                      "tr_td3_title": "Intel® Turbo-Boost-Max-Technik 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Smart-Cache",
                      "tr_td2_title": "12&nbsp;MB Intel® Smart-Cache",
                      "tr_td3_title": "12&nbsp;MB Intel® Smart-Cache"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Bis zu 5,0&nbsp;GHz max. Taktfrequenz holen noch mehr aus deinen Games heraus und ein ultraportabler PC sorgt dafür, dass du sie immer dabei hast.",
                "tr_title": "Mehr Leistung in jedem Gramm.",
                "tr_gen_core_title": "Intel® Core™ i7 Special Edition Prozessor der 11.&nbsp;Generation",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition Prozessor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "FINDE DEINEN INTEL® CORE™ PROZESSOR FÜR ULTRAPORTABLES GAMING.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Intel® Core™ i5-11300H Prozessor der 11.&nbsp;Gen."
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Intel® Core™ i7-11370H Prozessor der 11.&nbsp;Gen."
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Intel® Core™ i7-11375H Special Edition Prozessor der 11.&nbsp;Gen."
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Bis zu <span class='style_badge'>4,4 GHz</span> max. Taktfrequenz",
                      "tr_td2_title": "Bis zu <span class='style_badge'>4,8 GHz</span> max. Taktfrequenz",
                      "tr_td3_title": "Bis zu <span class='style_badge'>5,0 GHz</span> max. Taktfrequenz"
                    },
                    {
                      "tr_td1_title": "4 Kerne, 8&nbsp;Threads",
                      "tr_td2_title": "4 Kerne, 8&nbsp;Threads",
                      "tr_td3_title": "4 Kerne, 8&nbsp;Threads"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo-Boost-Technik",
                      "tr_td2_title": "Intel® Turbo-Boost-Technik",
                      "tr_td3_title": "Intel® Turbo-Boost-Max-Technik 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Smart-Cache",
                      "tr_td2_title": "12&nbsp;MB Intel® Smart-Cache",
                      "tr_td3_title": "12&nbsp;MB Intel® Smart-Cache"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Der Intel® Core™ i7 Special Edition Prozessor der 11. Generation bietet in ultraportablen Computern die Grundlage für anspruchsvolle Content-Erstellung, flüssiges Gaming und Entertainment auf noch höherem Niveau.",
                "tr_title": "Bahnbrechende Laptop-Leistung",
                "tr_gen_core_title": "Intel® Core™ i7 Special Edition Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 SPECIAL EDITION PROZESSOR DER 11. GEN."
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Der Intel® Core™ i7 Special Edition Prozessor der 11. Generation bietet in ultraportablen Computern die Grundlage für anspruchsvolle Content-Erstellung, flüssiges Gaming und Entertainment auf noch höherem Niveau.",
                "tr_title": "Bahnbrechende Laptop-Leistung",
                "tr_gen_core_title": "Intel® Core™ i7 Special Edition Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 SPECIAL EDITION PROZESSOR DER 11. GEN.",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Schnelle Taktfrequenzen und hohe Kernzahlen arbeiten nahtlos zusammen, um wettbewerbsfähiges Gaming und komplexe Content-Erstellung zu ermöglichen.",
                "tr_title": "Enorme Leistung. Perfekt abgestimmt.",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 11.&nbsp;Generation",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 Prozessor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Die richtige Balance zwischen Taktfrequenz und Kernleistung holt alles aus deinen Spielen heraus, egal ob für hohe FPS oder ein packendes AAA-Erlebnis.",
                "tr_title": "Leistung, die Sinn macht!",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 11.&nbsp;Generation",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Nun kannst du anspruchsvolle Titel mit hohen FPS gleichzeitig spielen, streamen und aufzeichnen und auch mühelos zu intensiven Multitasking-Aufgaben wechseln.",
                "tr_title": "Power, die Berge versetzt.",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 11.&nbsp;Generation",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Schnelle Taktfrequenzen und hohe Kernzahlen arbeiten nahtlos zusammen, um wettbewerbsfähiges Gaming und komplexe Content-Erstellung zu ermöglichen.",
                "tr_title": "Enorme Leistung. Perfekt abgestimmt.",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 11.&nbsp;Generation",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Mit reichlicher Leistungsreserve wechselst du spielend zwischen gestreamten Spielfilmen und der Ausarbeitung einer Präsentation.",
                "tr_title": "Leistung für den ganzen&nbsp;Tag",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i3 Prozessor&nbsp;mit Intel®&nbsp;Hybrid-Technik",
                "tr_subTitle2": "Intel®&nbsp;Core™&nbsp;i3 Prozessor&nbsp;mit Intel®&nbsp;Hybrid-Technik"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vielseitige Leistung trifft auf lange Akkulaufzeit, damit du immer und überall arbeiten kannst.",
                "tr_title": "Leistung für den ganzen&nbsp;Tag",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i3 Prozessor&nbsp;mit Intel®&nbsp;Hybrid-Technik",
                "tr_subTitle2": "Intel®&nbsp;Core™&nbsp;i3 Prozessor&nbsp;mit Intel®&nbsp;Hybrid-Technik"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Nutze mühelos mehrere Programme gleichzeitig und sei für alles bereit, egal wo der Tag dich hinführt.",
                "tr_title": "Multitasking unterwegs",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i5 Prozessor&nbsp;mit Intel®&nbsp;Hybrid-Technik",
                "tr_subTitle2": "Intel®&nbsp;Core™&nbsp;i5 Prozessor&nbsp;mit Intel®&nbsp;Hybrid-Technik"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Mach mehr aus allem, was du mit dem Computer erledigst oder erlebst – von der Unterhaltung bis zu produktiver Arbeit und Content-Erstellung.",
                "tr_title": "Upgrade auf einen stärkeren PC",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i5 Prozessor&nbsp;mit Intel®&nbsp;Hybrid-Technik",
                "tr_subTitle2": "Intel®&nbsp;Core™&nbsp;i5 Prozessor&nbsp;mit Intel®&nbsp;Hybrid-Technik"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Mobile Gaming-Leistung auf Desktop-PC-Niveau – für wettkampffähiges Spielen mit hoher Einzelbildrate (FPS) und ein eindrucksvolles AAA-Erlebnis.",
                "tr_title": "Leistung, die Sinn macht!",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 11.&nbsp;Generation",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Mobile Gaming-Leistung auf Desktop-PC-Niveau – für wettkampffähiges Spielen mit hoher Einzelbildrate (FPS) und ein eindrucksvolles AAA-Erlebnis.",
                "tr_title": "Leistung, die Sinn macht!",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Ein Laptop, mit dem du gleichzeitig mit hoher Einzelbildrate (FPS) spielen, aufnehmen und streamen sowie mühelos anspruchsvolle Multitasking-Workloads bewältigen kannst.",
                "tr_title": "Power, die Berge versetzt.",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 11.&nbsp;Generation",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Ein Laptop, mit dem du gleichzeitig mit hoher Einzelbildrate (FPS) spielen, aufnehmen und streamen sowie mühelos anspruchsvolle Multitasking-Workloads bewältigen kannst.",
                "tr_title": "Power, die Berge versetzt.",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hohe Taktfrequenzen und eine hohe Anzahl von CPU-Kernen ermöglichen ortsunabhängig wettkampffähiges Gaming und problemloses Erstellen von komplexem Content.",
                "tr_title": "Enorme Leistung. Perfekt abgestimmt.",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 11.&nbsp;Generation",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hohe Taktfrequenzen und eine hohe Anzahl von CPU-Kernen ermöglichen ortsunabhängig wettkampffähiges Gaming und problemloses Erstellen von komplexem Content.",
                "tr_title": "Enorme Leistung. Perfekt abgestimmt.",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hohe Taktfrequenzen, eine hohe Anzahl von CPU-Kernen und übertaktbare Leistungsfähigkeit ermöglichen ortsunabhängig wettkampffähiges Gaming und problemloses Erstellen von komplexem Content.",
                "tr_title": "Entfesselte Power, ideal abgestimmt",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 11.&nbsp;Generation",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hohe Taktfrequenzen, eine hohe Anzahl von CPU-Kernen und übertaktbare Leistungsfähigkeit ermöglichen ortsunabhängig wettkampffähiges Gaming und problemloses Erstellen von komplexem Content.",
                "tr_title": "Entfesselte Power, ideal abgestimmt",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Schnelle Taktfrequenzen und hohe Kernzahlen arbeiten nahtlos zusammen, um wettbewerbsfähiges Gaming und komplexe Content-Erstellung zu ermöglichen.",
                "tr_title": "Enorme Leistung. Perfekt abgestimmt.",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hohe Taktfrequenzen und eine hohe Anzahl von Kernen arbeiten nahtlos zusammen, um komplexe Content-Erstellung zu optimieren, egal wo du deinen PC einsetzt.",
                "tr_title": "Enorme Leistung. Perfekt abgestimmt.",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 11.&nbsp;GEN.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hohe Taktfrequenzen und eine hohe Anzahl von Kernen arbeiten nahtlos zusammen, um komplexe Content-Erstellung zu optimieren.",
                "tr_title": "Enorme Leistung. Perfekt abgestimmt.",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 11.&nbsp;GEN.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Steigere dein Potenzial beim ultimativen Gaming-Erlebnis",
                "tr_title": "Konkurrenzfähig auf höchstem Level",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 10.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR DER 10.&nbsp;GENERATION, DER&nbsp;AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemloses AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen und Aufnehmen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Anspruchsvolle Content-Erstellung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximale Gaming-Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Zur Übertaktung geeignet<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Steigere dein Potenzial beim ultimativen Gaming-Erlebnis",
                "tr_title": "Konkurrenzfähig auf höchstem Level",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 10.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR DER 10.&nbsp;GENERATION, DER&nbsp;AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemloses AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen und Aufnehmen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Anspruchsvolle Content-Erstellung",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximale Gaming-Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Zur Übertaktung geeignet<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Für alle, die immer unterwegs sind: ein Notebook, das speziell für dich entwickelt wurde.",
                "tr_title": "Eine neue Klasse fortschrittlicher Laptops",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 10.&nbsp;Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Schnelle Taktfrequenzen und hohe Kernzahlen arbeiten nahtlos zusammen, um wettbewerbsfähiges Gaming und komplexe Content-Erstellung zu ermöglichen.",
                "tr_title": "Entfesselte Power, ideal abgestimmt",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 11.&nbsp;GEN.",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hohe Taktfrequenzen, eine hohe Anzahl von Kernen und übertaktbare Leistung arbeiten nahtlos zusammen, um komplexe Content-Erstellung zu optimieren, egal wo du deinen PC einsetzt.",
                "tr_title": "Entfesselte Power, ideal abgestimmt",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 11.&nbsp;GEN.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hohe Taktfrequenzen und eine hohe Anzahl von Kernen arbeiten nahtlos zusammen, um komplexe Content-Erstellung zu optimieren.",
                "tr_title": "Entfesselte Power, ideal abgestimmt",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 11.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 11.&nbsp;GEN.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>Intel® Smart-Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Flüssige Gameplay- und VR&#8209;Darstellung bei den anspruchsvollsten Titeln",
                "tr_title": "Intensives Gaming beginnt hier",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 9.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR, DER AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gleichzeitig gamen, streamen und aufnehmen&nbsp;– ohne Kompromisse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Der beste Intel® Core™ Prozessor der 9.&nbsp;Generation für Gamer",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "BIS ZU 32 % MEHR FPS <br/><span class='tr_subtitle_font'>bei gleichzeitigem Gamen, Streamen und Aufzeichnen im Vergleich zu einem 3 Jahre alten&nbsp;PC<sup>3</sup></span>",
                "tr_title": "Die nötige Power, um deine besten Gaming-Momente zu teilen",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 9.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR, DER AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gleichzeitig gamen, streamen und aufnehmen&nbsp;– ohne Kompromisse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Der beste Intel® Core™ Prozessor der 9.&nbsp;Generation für Gamer",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "BIS ZU 41 % MEHR FPS <br/><span class='tr_subtitle_font'>bei gleichzeitigem Gamen, Streamen und Aufzeichnen im Vergleich zu einem 3 Jahre alten&nbsp;PC<sup>4</sup></span>",
                "tr_title": "Gamer wollen Power. Der Intel® Core™ i9 liefert sie.",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor der 9.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kerne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bis zu <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Entsperrt",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINDE DEN INTEL® CORE™ PROZESSOR, DER AM BESTEN ZU DEINEM GAMING-STIL PASST.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 Prozessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 Prozessor"
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 Prozessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Wettkampffähiges Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gleichzeitig gamen, streamen und aufnehmen&nbsp;– ohne Kompromisse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Der beste Intel® Core™ Prozessor der 9.&nbsp;Generation für Gamer",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 6<br/>Threads: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Taktfrequenz<sup>2</sup>: Bis zu 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerne: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Entworfen für Gaming der nächsten Generation",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™&nbsp;i5 Prozessor der 12.&nbsp;Generation",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 12.&nbsp;GENERATION"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 12.&nbsp;Generation",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 12.&nbsp;GENERATION"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™&nbsp;i9 Prozessor der 12.&nbsp;Generation",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 12.&nbsp;GENERATION"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kerne</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] auf Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] auf Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Gamingstation trifft auf Workstation",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 12.&nbsp;Generation",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 12.&nbsp;GENERATION"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™&nbsp;i9 Prozessor der 12.&nbsp;Generation",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 12.&nbsp;GENERATION"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kerne</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] auf Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] auf Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Zuverlässige Leistung, wenn du sie am meisten brauchst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Intel® Core™&nbsp;i3 Prozessor der 12.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i3 PROZESSOR DER 12.&nbsp;GENERATION",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Eine Leistungssteigerung, wo&nbsp;sie&nbsp;am meisten gebraucht wird",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel® Core™&nbsp;i5 Prozessor der 12.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 12.&nbsp;GENERATION",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Eine Leistungssteigerung, wo&nbsp;sie&nbsp;am meisten gebraucht wird",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel® Core™&nbsp;i5 Prozessor der 12.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 12.&nbsp;GENERATION",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Überlegene Leistung, wo du sie am meisten brauchst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 12.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 12.&nbsp;GENERATION",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Überlegene Leistung, wo du sie am meisten brauchst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 12.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 12.&nbsp;GENERATION",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mit Intel® Evo™ Laptops erhältst du Leistung, integrierte Intelligenz für vertrauliche Videochats, schnelleres Internet, lange Akkulaufzeit und mehr.",
                "tr_title": "Bringt eine Leistungssteigerung, wo&nbsp;du&nbsp;sie am meisten brauchst.",
                "tr_gen_core_title": "Intel® Core™&nbsp;i5 Prozessor der 12.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 12.&nbsp;GENERATION",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mit Intel® Evo™ Laptops erhältst du Leistung, integrierte Intelligenz für vertrauliche Videochats, schnelleres Internet, lange Akkulaufzeit und mehr.",
                "tr_title": "Überlegene Leistung, wo du sie am meisten brauchst",
                "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 12.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 12.&nbsp;GENERATION",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mit Intel® Evo™ Laptops erhältst du Leistung, integrierte Intelligenz für vertrauliche Videochats, schnelleres Internet, lange Akkulaufzeit und mehr.",
                "tr_title": "Extreme Power in einem Premium-Laptop",
                "tr_gen_core_title": "Intel® Core™&nbsp;i9 Prozessor der 12.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 12.&nbsp;GENERATION",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Mit Intel® Turbo-Boost-Technik 2.0 bis&nbsp;zu&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Built for Business",
                "tr_gen_core_title": "Intel® Core™&nbsp;i5 Prozessor der 12.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 12.&nbsp;GENERATION",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rausch- und Hintergrundunterdrückung",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatische Leistungsanpassung, um die häufigsten Aufgaben zu priorisieren",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Reibungslose Web-Anrufe hoher Qualität",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Sofort einsatzbereite hardwareunterstützte Sicherheit",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Bereitstellung von Funktionen zur Ferngeräteverwaltung",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatische Optimierung der WLAN-Leistung<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Leistung für mehr datengestützte Anwendungen",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Die höchste Produktivität mit verbesserter Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Mit Intel® Turbo-Boost-Technik 2.0 bis&nbsp;zu&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Leistet Schwerstarbeit, damit sich Benutzer auf&nbsp;ihr&nbsp;Geschäft konzentrieren können.",
                "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 12.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 12.&nbsp;GENERATION",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rausch- und Hintergrundunterdrückung",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatische Leistungsanpassung, um die häufigsten Aufgaben zu priorisieren",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Reibungslose Web-Anrufe hoher Qualität",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Sofort einsatzbereite hardwareunterstützte Sicherheit",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Bereitstellung von Funktionen zur Ferngeräteverwaltung",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatische Optimierung der WLAN-Leistung<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Leistung für mehr datengestützte Anwendungen",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Die höchste Produktivität mit verbesserter Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Mit Intel® Turbo-Boost-Technik 2.0 bis&nbsp;zu&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Beschleunigen Sie Ihre Geschäftsabläufe",
                "tr_gen_core_title": "Intel® Core™&nbsp;i9 Prozessor der 12.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 12.&nbsp;GENERATION",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rausch- und Hintergrundunterdrückung",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatische Leistungsanpassung, um die häufigsten Aufgaben zu priorisieren",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Reibungslose Web-Anrufe hoher Qualität",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Sofort einsatzbereite hardwareunterstützte Sicherheit",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Bereitstellung von Funktionen zur Ferngeräteverwaltung",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatische Optimierung der WLAN-Leistung<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Leistung für mehr datengestützte Anwendungen",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Die höchste Produktivität mit verbesserter Leistung",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Zuverlässige Leistung, mit der du mehr tun kannst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Intel® Core™&nbsp;i3 Prozessor der 13.&nbsp;Generation",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Echte Performance, mit der du mehr tun kannst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel® Core™&nbsp;i5 Prozessor der 13.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 13.&nbsp;GENERATION",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Echte Performance, mit der du mehr tun kannst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel® Core™&nbsp;i5 Prozessor der 13.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 13.&nbsp;GENERATION",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Hohe Leistung, mit der du mehr tun kannst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 13.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 13.&nbsp;GENERATION",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Hohe Leistung, mit der du mehr tun kannst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 13.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 13.&nbsp;GENERATION",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Performance weitergedacht",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Intel® Core™&nbsp;i9 Prozessor der 13.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 13.&nbsp;GENERATION",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kerne</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] auf Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] auf Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Nahtlose Verbindung mit deinem vorhandenen Handy<sup>24</sup>, intelligente Auswahl des besten WLAN und schnelles Aufladen mit einem langlebigen Akku.",
                "tr_title": "Performance weitergedacht, um überall mehr zu erledigen",
                "tr_gen_core_title": "Intel® Core™&nbsp;i5 Prozessor der 13.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 13.&nbsp;GENERATION",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Nahtlose Verbindung mit deinem vorhandenen Handy<sup>24</sup>, intelligente Auswahl des besten WLAN und schnelles Aufladen mit einem langlebigen Akku.",
                "tr_title": "Performance weitergedacht, damit dein PC noch mehr gleichzeitig erledigen kann",
                "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 13.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 13.&nbsp;GENERATION",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Nahtlose Verbindung mit deinem vorhandenen Handy<sup>24</sup>, intelligente Auswahl des besten WLAN und schnelles Aufladen mit einem langlebigen Akku.",
                "tr_title": "Performance weitergedacht, um auch die anspruchsvollsten Aufgaben schneller zu erledigen",
                "tr_gen_core_title": "Intel® Core™&nbsp;i9 Prozessor der 13.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 13.&nbsp;GENERATION",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Performance weitergedacht",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™&nbsp;i5 Prozessor der 13.&nbsp;Generation",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 13.&nbsp;GENERATION",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 13.&nbsp;Generation",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 13.&nbsp;GENERATION",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™&nbsp;i9 Prozessor der 13.&nbsp;Generation",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 13.&nbsp;GENERATION",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kerne</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] auf Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] auf Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Performance weitergedacht",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 Prozessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROZESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 Prozessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROZESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 Prozessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 PROZESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kerne</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] auf Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] auf Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Zuverlässige Leistung, mit der du mehr tun kannst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Intel® Core™&nbsp;i3 Prozessor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Echte Performance, mit der du mehr tun kannst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROZESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Hohe Leistung, mit der du mehr tun kannst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROZESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™&nbsp;i3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Performance weitergedacht",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 Prozessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROZESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 Prozessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROZESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 Prozessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 PROZESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kerne</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] auf Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] auf Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Zuverlässige Leistung, mit der du mehr tun kannst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 3 Prozessor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Echte Performance, mit der du mehr tun kannst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 5 Prozessor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 5 PROZESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Hohe Leistung, mit der du mehr tun kannst",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 7 Prozessor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 7 PROZESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "tr_title": "Gaming ohne Kompromisse",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 5 Prozessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 5 PROZESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 7 Prozessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 7 PROZESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 9 Prozessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 9 PROZESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kerne</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] auf Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] auf Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] auf LPE-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 5 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 7 Prozessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 9 Prozessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Gaming ohne Kompromisse",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Gaming ohne Kompromisse",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Gaming ohne Kompromisse",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Gaming ohne Kompromisse",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Gaming ohne Kompromisse",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Gaming ohne Kompromisse",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Kleine Unternehmen verdienen umfassende Geschäftslösungen.",
                "tr_gen_core_title": "Intel® Core™&nbsp;i5 Prozessor der 13.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i5 PROZESSOR DER 13.&nbsp;GENERATION",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rausch- und Hintergrundunterdrückung",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatische Leistungsanpassung, um die häufigsten Aufgaben zu priorisieren",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Reibungslose Web-Anrufe hoher Qualität",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Sofort einsatzbereite hardwareunterstützte Sicherheitsfunktionen",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Schutz von Geräten und Daten vor physischen Manipulationsangriffen",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Kleine Unternehmen verdienen umfassende Geschäftslösungen.",
                "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 13.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i7 PROZESSOR DER 13.&nbsp;GENERATION",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rausch- und Hintergrundunterdrückung",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatische Leistungsanpassung, um die häufigsten Aufgaben zu priorisieren",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Reibungslose Web-Anrufe hoher Qualität",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Sofort einsatzbereite hardwareunterstützte Sicherheitsfunktionen",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Schutz von Geräten und Daten vor physischen Manipulationsangriffen",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Kleine Unternehmen verdienen umfassende Geschäftslösungen.",
                "tr_gen_core_title": "Intel® Core™&nbsp;i9 Prozessor der 13.&nbsp;Generation",
                "tr_gen_core_title_uppercase": "INTEL® CORE™&nbsp;i9 PROZESSOR DER 13.&nbsp;GENERATION",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER PROZESSOR IM VERGLEICH AB?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuelles Gerät",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rausch- und Hintergrundunterdrückung",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatische Leistungsanpassung, um die häufigsten Aufgaben zu priorisieren",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Reibungslose Web-Anrufe hoher Qualität",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Sofort einsatzbereite hardwareunterstützte Sicherheitsfunktionen",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Schutz von Geräten und Daten vor physischen Manipulationsangriffen",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Entdecke neue KI&#8209;Möglichkeiten für Unternehmen",
                "tr_title": "Bereit für neue Herausforderungen − mit Intel® Core™ Ultra&nbsp;[XX] Prozessoren und Intel vPro®",
                "tr_gen_core_title": "Intel® Core™ Ultra [XX] Prozessor",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "STEIGERUNG DER LEISTUNG VON UNTERNEHMEN MIT KI-PCS VON INTEL",
                    "tr_badgeSubTitle": "Leistungsstarke PCs, die ein einzigartiges Erlebnis schaffen, sind Kern der Produktivität von Unternehmen. Intel vPro® steigert die Effektivität und Leistung der Mitarbeiter über Workloads und Anwendungen hinweg. Eine neue Architektur, die überlegene energieeffiziente Leistung für verschiedene, komplexe geschäftliche Workloads bietet, sendet die richtige Aufgabe zur richtigen Zeit an die richtige Engine.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "MEHRSCHICHTIGE SICHERHEITSFUNKTIONEN, DIE DIREKT EINSATZBEREIT SIND",
                    "tr_badgeSubTitle": "Neue und aufkommende Bedrohungen erfordern einen umfassenden Ansatz für die Sicherheit. Intel vPro® bietet Schutzfunktionen auf Hardware-Ebene und im gesamten Stack, damit Unternehmen besser geschützt sind. Diese einzigartigen und sofort einsatzbereiten Sicherheitsmaßnahmen, einschließlich KI-gestützter Funktionen zur Bedrohungserkennung, tragen dazu bei, die Benutzer, Daten und das Unternehmen zu schützen.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "INTEGRIERTE TOOLS, UM ANWENDER VON PRAKTISCH ÜBERALL AUS PRODUKTIV ZU HALTEN",
                    "tr_badgeSubTitle": "Büros befinden sich überall und so auch PCs. Intel vPro® bietet moderne hardwarebasierte Verwaltungsfunktionen, die Unternehmen dabei helfen, die PC-Flottenverwaltung zu erleichtern, Support zu vereinfachen und Nutzererlebnisse zu verbessern, während sie nachhaltige Computing-Praktiken ermöglichen.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mehr als ein erstklassiger PC. Ein&nbsp;KI&#8209;PC.",
                "tr_subtitle1": "MIT INTEL® CORE™ ULTRA PROZESSOREN (SERIE 2)",
                "tr_subtitle2": "Jetzt möglich mit deinem Intel KI-PC",
                "tr_subtitle": "Der effizienteste, sicherste und leistungsstärkste Mobilprozessor von Intel<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 Prozessor (Serie&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Kreativer und schneller</span> mit KI-Tools"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Produktiver</span> mit zeitsparender KI-Unterstützung"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Sicherer</span> mit integriertem Datenschutz"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mehr als ein erstklassiger PC. Ein&nbsp;KI&#8209;PC.",
                "tr_subtitle1": "MIT INTEL® CORE™ ULTRA PROZESSOREN (SERIE 2)",
                "tr_subtitle2": "Jetzt möglich mit deinem Intel KI-PC",
                "tr_subtitle": "Der effizienteste, sicherste und leistungsstärkste Mobilprozessor von Intel<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 Prozessor (Serie&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Kreativer und schneller</span> mit KI-Tools"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Produktiver</span> mit zeitsparender KI-Unterstützung"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Sicherer</span> mit integriertem Datenschutz"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mehr als ein erstklassiger PC. Ein&nbsp;KI&#8209;PC.",
                "tr_subtitle1": "MIT INTEL® CORE™ ULTRA PROZESSOREN (SERIE 2)",
                "tr_subtitle2": "Jetzt möglich mit deinem Intel KI-PC",
                "tr_subtitle": "Der effizienteste, sicherste und leistungsstärkste Mobilprozessor von Intel<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 Prozessor (Serie&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mehr als ein erstklassiger PC. Ein&nbsp;KI&#8209;PC.",
                "tr_subtitle1": "MIT INTEL® CORE™ ULTRA PROZESSOREN (SERIE 2)",
                "tr_subtitle2": "Jetzt möglich mit deinem Intel KI-PC",
                "tr_subtitle": "Der effizienteste, sicherste und leistungsstärkste Mobilprozessor von Intel<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 Prozessor (Serie&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_tileTitle1": "Dedizierte Grafik",
                "tr_title": "WAS IST EIN GRAFIKPROZESSOR BZW. EINE GPU?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Ein Grafikprozessor, auch GPU (Graphics Processing Unit) genannt, produziert die Bilder, die du auf dem Bildschirm siehst.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integrierte GPU",
                    "tr_badgeSubTitle": "Eine integrierte GPU verwendet den gleichen RAM wie der Rest des Computers, was die Energieeffizienz verbessert.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Separate GPU",
                    "tr_badgeSubTitle": "Dies ist die leistungsfähigste GPU-Kategorie, denn sie verfügt über ihren eigenen Arbeitsspeicher und weitere eigene Ressourcen, mit denen sie für herausragende Grafikqualität sorgt.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Hybrid-GPU",
                    "tr_badgeSubTitle": "Dies ist die leistungsfähigste GPU-Kategorie, denn sie verfügt über ihren eigenen Arbeitsspeicher und weitere eigene Ressourcen, mit denen sie für herausragende Grafikqualität sorgt.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Optimiert für Foto- und Videobearbeitung",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Stundenlang 4K-Video-Inhalte genießen",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Beliebte Spiele mit faszinierenden Details erleben",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Zwei GPUs",
                    "tr_badgeSubTitle": "Einige Systeme, wie diejenigen mit Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik, können sowohl die im Prozessor integrierte Grafik als auch die separate Grafik nutzen, um die Grafikaufgaben auf beide GPUs zu verteilen.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integrierte GPU",
                    "tr_badgeSubTitle": "Eine integrierte Grafik befindet sich auf demselben Chip wie die CPU. Diese Art von GPU nutzt dieselben Arbeitsspeicher- und anderen Ressourcen wie die restlichen Computerkomponenten. Dies verbessert die Energieeffizienz und ermöglicht den Bau flacherer, leichterer Geräte.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Separate GPU",
                    "tr_badgeSubTitle": "Dies ist die leistungsfähigste GPU-Kategorie, denn sie verfügt über ihren eigenen Arbeitsspeicher und weitere eigene Ressourcen, mit denen sie für herausragende Grafikqualität sorgt.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Standard",
                    "tr_badgeSubTitle": "Bis zu 4&nbsp;GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wettkampf-Gaming",
                    "tr_badgeSubTitle": "Bis zu 8&nbsp;GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Mehr als 8&nbsp;GB VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intensive Grafikleistung",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Für besonders starke Grafikverarbeitung brauchst du eine Grafikkarte, die ausschließlich für die Bereitstellung wunderschöner Bilder in gestochen scharfer Qualität und flüssig dargestellte Animationen zuständig ist.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Integrierte Grafik für brillante und lebendige Darstellung",
                "tr_pretitle": "Intel® Iris® Plus Grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mit der Intel® Iris® Plus Grafik kannst du in die Welt von 4K-HDR-Videos und Full-HD-Spielen eintauchen – ohne separate Grafikkarte.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Atemberaubende Grafik, reibungslose Leistung",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Grafik bietet transformative integrierte Grafikleistung für müheloses Design bei der Content-Gestaltung und überwältigende Gaming-Erlebnisse.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intel® Iris® Pro Grafik",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intel® UHD-Grafik mit X<sup class='small_text_sup_xe'>e</sup> Architektur",
                "tr_pretitle": "Beeindruckende visuelle Erfahrung",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Erlebe, was es heißt, Fotos und Videos verzögerungsfrei bearbeiten, Premium-Videoinhalte stundenlang in 4K genießen oder sich in beliebte Spiele mit faszinierenden Details vertiefen zu können.",
                "tr_photo_text": "Optimiert für Foto- und Videobearbeitung",
                "tr_video_text": "Stundenlang 4K-Video-Inhalte genießen",
                "tr_game_text": "Beliebte Spiele mit faszinierenden Details erleben",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "VIEL MEHR ALS NUR HÜBSCHE BILDER",
                "tr_subtitle": "In enger Zusammenarbeit mit dem Prozessor (CPU) hilft die Grafikkarte (GPU) zu bestimmen, wie gut deine Spiele laufen und wie sie aussehen. Der VRAM (bzw. Video-RAM), der ausschließlich für die Grafikdarstellung zuständig ist, gibt dir einen schnellen Hinweis darauf, wie gut deine GPU ist.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "GRAFIK FÜR INTENSIVES GAMING",
                "tr_subtitle": "Für besonders starke Grafikverarbeitung brauchst du eine separate Grafikkarte, die ausschließlich für die Bereitstellung wunderschöner Bilder in gestochen scharfer Qualität und flüssig dargestellte Animationen zuständig ist.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "GRAFIK FÜR INTENSIVES GAMING",
                "tr_subtitle": "Für besonders starke Grafikverarbeitung brauchst du eine separate Grafikkarte, die ausschließlich für die Bereitstellung wunderschöner Bilder in gestochen scharfer Qualität und flüssig dargestellte Animationen zuständig ist.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "VIEL MEHR ALS NUR HÜBSCHE BILDER",
                "tr_subtitle": "In enger Zusammenarbeit mit dem Prozessor (CPU) hilft die Grafikkarte (GPU) zu bestimmen, wie gut deine Spiele laufen und wie sie aussehen. Der VRAM (bzw. Video-RAM), der ausschließlich für die Grafikdarstellung zuständig ist, gibt dir einen schnellen Hinweis darauf, wie gut deine GPU ist.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intensive Grafikleistung",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Für besonders starke Grafikverarbeitung brauchst du eine Grafikkarte, die ausschließlich für die Bereitstellung wunderschöner Bilder in gestochen scharfer Qualität und flüssig dargestellte Animationen zuständig ist.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "GRAFIK FÜR INTENSIVES GAMING",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Für besonders starke Grafikverarbeitung brauchst du eine Grafikkarte, die ausschließlich für die Bereitstellung wunderschöner Bilder in gestochen scharfer Qualität und flüssig dargestellte Animationen zuständig ist.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Für besonders starke Grafikverarbeitung brauchst du eine separate Grafikkarte, die ausschließlich für die Bereitstellung wunderschöner Bilder in gestochen scharfer Qualität und flüssig dargestellte Animationen zuständig ist.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Liefert gute Grafikleistung für die Mindestanforderungen deiner Spiele.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Für anspruchsvollere Gamer, die Leistung auf Wettkampfniveau verlangen",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Für anspruchsvollste Gamer, die ihren PC auch für komplexe Content-Erstellung verwenden",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Brillante Videoqualität und fesselndes Gaming",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Mit Intel® HD-Grafik ziehst du den größten Nutzen aus deinem hochauflösenden Bildschirm und genießt ein brillantes Multimedia-Erlebnis mit integrierter Grafikverarbeitung.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Lebendig dargestellte Videos und Bilder",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Videostreams und Fotos werden mit höherem Detailreichtum und in brillanter Farbe dargestellt.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Fantastische Leistung für Kreativität und Gaming",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Von fesselnder Unterhaltung und anspruchsvoller Content-Gestaltung bis hin zum Mainstream-Gaming bietet die Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup> Grafik revolutionäre Grafikleistung bei flachen und leichten Laptops.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_pretitle": "GRAFIK FÜR INTENSIVES GAMING",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> Grafik",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Für besonders starke Grafikverarbeitung brauchst du eine separate Grafikkarte, die ausschließlich für die Bereitstellung wunderschöner Bilder in gestochen scharfer Qualität und flüssig dargestellte Animationen zuständig ist.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Entfessle deine Kreativität",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX Grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Mit der kombinierten Power der im Prozessor integrierten Grafik und der ersten separaten Intel® Grafik-Engine kannst du deine kreativen Projekte mühelos umsetzen und unterschiedlichste Games spielen.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX Grafik",
                "tr_pretitle": "GRAFIK FÜR INTENSIVES GAMING",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Für besonders starke Grafikverarbeitung brauchst du eine separate Grafikkarte, die ausschließlich für die Bereitstellung wunderschöner Bilder in gestochen scharfer Qualität und flüssig dargestellte Animationen zuständig ist.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Grafik",
                "tr_title": "Wunderschöne integrierte Grafik",
                "tr_subtitle": "Erlebe deine Spiele, Filme und Webvideos in beeindruckender Detailwiedergabe und Klarheit.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Mehr Power für deine Workflows"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Der Energie-Boost für dein Gaming"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Der Energie-Boost für dein Gaming"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Bring dein Spiel auf ein neues Level"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "ENTFESSLE DEINE KREATIVITÄT",
                    "tr_specText": "Steigere deine Produktivität – wo immer du bist – mit den umfangreichsten Multimediafunktionen, wie der <strong>branchenweit ersten AV1-Hardware-Codierung</strong> und KI-beschleunigter Content-Gestaltung.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "ENTFESSLE DEINE KREATIVITÄT",
                    "tr_specText": "Steigere deine Produktivität mit den umfangreichsten Multimediafunktionen, wie der <strong>branchenweit ersten AV1-Hardware-Codierung</strong> und KI-beschleunigter Content-Gestaltung.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "ENTFESSLE DEINE KREATIVITÄT",
                    "tr_specText": "Erlebe bahnbrechende KI-beschleunigte Content-Gestaltung mit der <strong>branchenweit ersten AV1-Hardware-Codierung</strong> und der fortschrittlichen <strong>X<sup>e</sup> Media-Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "ENTFESSLE DEINE KREATIVITÄT",
                    "tr_specText": "Erlebe bahnbrechende KI-beschleunigte Content-Gestaltung mit der <strong>branchenweit ersten AV1-Hardware-Codierung</strong> und der fortschrittlichen <strong>X<sup>e</sup> Media-Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "ENTFESSLE DEINE KREATIVITÄT",
                    "tr_specText": "Erlebe bahnbrechende KI-beschleunigte Content-Gestaltung mit der <strong>branchenweit ersten AV1-Hardware-Codierung</strong> und der fortschrittlichen <strong>X<sup>e</sup> Media-Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "MOBILITÄT BEGEGNET LEISTUNG",
                    "tr_specText": "Spiele die neuesten Spiele unterwegs und genieße überragende Leistung und Realitätsnähe mit modernen Gaming-Funktionen. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® KI-verstärkte Upscaling-Technik für mehr Leistung und flüssiges Gaming. <br/><br/>Unterstützung von <strong>DirectX* 12 Ultimate:  </strong>Grafiktechnologien wie Raytracing und Variable Rate Shading hauchen deinen Spielen eine neue Dimension von Realismus ein."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "ÜBERNIMM DIE KONTROLLE ÜBER DEINE SPIELE",
                    "tr_specText": "Das intuitive und moderne Layout des <strong>Intel® Arc™ Kontrollcenters</strong> verbessert dein Gaming-Erlebnis. Freue dich über mühelose Treiber-Updates, optimiere deine Spiele und vieles mehr."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "ÜBERNIMM DIE KONTROLLE ÜBER DEINE SPIELE",
                    "tr_specText": "Das intuitive und moderne Layout des <strong>Intel® Arc™ Kontrollcenters</strong> verbessert dein Gaming-Erlebnis. Freue dich über mühelose Treiber-Updates, optimiere deine Spiele und vieles mehr."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "ÜBERNIMM DIE KONTROLLE ÜBER DEINE SPIELE",
                    "tr_specText": "Freue dich über mühelose Treiber-Updates und optimiere viele deiner Lieblingsspiele mit der <strong>Intel® Arc™ Kontrollcenter</strong>-Software."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAXIMIERE DEINE INTEL® PLATTFORM",
                    "tr_specText": "Die Kombination mit kompatiblen Intel® Core™ Prozessoren ermöglicht es dir, die <strong>Intel® Deep Link Technik</strong> zu nutzen, um dein Erlebnis bei Content-Gestaltung, Gaming und Streaming zu verbessern."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "OPTIMIERE DEIN INTEL® SYSTEM",
                    "tr_specText": "Die Kombination mit kompatiblen Intel® Core™ Prozessoren ermöglicht es dir, die <strong>Intel® Deep Link Technik</strong> zu nutzen, um dein Erlebnis bei Content-Gestaltung, Gaming und Streaming zu verbessern."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAXIMIERE DEINE INTEL® PLATTFORM",
                    "tr_specText": "Die Kombination mit kompatiblen Intel® Core™ Prozessoren ermöglicht es dir, die <strong>Intel® Deep Link Technik</strong> zu nutzen, um dein Erlebnis bei Content-Gestaltung, Gaming und Streaming zu verbessern."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMIERE DEINE INTEL® PLATTFORM",
                    "tr_specText": "Die Kombination mit kompatiblen Intel® Core™ Prozessoren ermöglicht es dir, die <strong>Intel® Deep Link Technik</strong> zu nutzen, um dein Erlebnis bei Content-Gestaltung, Gaming und Streaming zu verbessern."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "OPTIMIERE DEIN INTEL® SYSTEM",
                    "tr_specText": "Die Kombination mit kompatiblen Intel® Core™ Prozessoren ermöglicht es dir, die <strong>Intel® Deep Link Technik</strong> zu nutzen, um dein Erlebnis bei Content-Gestaltung, Gaming und Streaming zu verbessern."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "STEH IM MITTELPUNKT",
                    "tr_specText": "Nahtlose Übertragung mit fortschrittlichen Streaming-Technologien mit <strong>Intel® Arc™ Kontrollcenter</strong>-Software."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "STEH IM MITTELPUNKT",
                    "tr_specText": "Nahtlose Übertragung mit fortschrittlichen Streaming-Technologien mit <strong>Intel® Arc™ Kontrollcenter</strong>-Software."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "ERLEBE FASZINIERENDES HIGH-PERFORMANCE-GAMING",
                    "tr_specText": "Konzentriere dich auf deine Höchstleistung mit den neuesten AAA-Games bei hohen Auflösungen und hohen Bildwiederholraten. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® KI-verstärkte Upscaling-Technik für mehr Leistung und flüssiges Gaming. <br/><br/>Unterstützung von <strong>DirectX* 12 Ultimate:  </strong>Grafiktechnologien wie Raytracing und Variable Rate Shading hauchen deinen Spielen eine neue Dimension von Realismus ein."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "ERLEBE FASZINIERENDES HIGH-PERFORMANCE-GAMING",
                    "tr_specText": "Konzentriere dich auf deine Höchstleistung mit den neuesten AAA-Games bei hohen Auflösungen und hohen Bildwiederholraten. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® KI-verstärkte Upscaling-Technik für mehr Leistung und flüssiges Gaming. <br/><br/>Unterstützung von <strong>DirectX* 12 Ultimate:  </strong>Grafiktechnologien wie Raytracing und Variable Rate Shading hauchen deinen Spielen eine neue Dimension von Realismus ein."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "HOL DIR MEHR GAMING-POWER",
                    "tr_specText": "Bring dein Gaming-Erlebnis auf ein neues Level mit: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® KI-verstärkte Upscaling-Technik für mehr Leistung und flüssiges Gaming. <br/><br/>Unterstützung von <strong>DirectX* 12 Ultimate:  </strong>Grafiktechnologien wie Raytracing und Variable Rate Shading hauchen deinen Spielen eine neue Dimension von Realismus ein."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "ERLEBE MODERNES, RASANTES GAMING",
                    "tr_specText": "Genieße ein flüssiges Spielerlebnis bei vielen beliebten AAA-Spielen und Gaming mit hoher Bildwiederholrate – von actiongeladenen E-Sport-Shootern bis hin zu rasanten RTS-Hits. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® KI-verstärkte Upscaling-Technik für mehr Leistung und flüssiges Gaming. <br/><br/>Unterstützung von <strong>DirectX* 12 Ultimate:  </strong>Grafiktechnologien wie Raytracing und Variable Rate Shading hauchen deinen Spielen eine neue Dimension von Realismus ein."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "ERLEBE FASZINIERENDES HIGH-PERFORMANCE-GAMING",
                    "tr_specText": "Konzentriere dich auf deine Höchstleistung mit den neuesten AAA-Games bei hohen Auflösungen und hohen Bildwiederholraten. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® KI-verstärkte Upscaling-Technik für mehr Leistung und flüssiges Gaming. <br/><br/>Unterstützung von <strong>DirectX* 12 Ultimate:  </strong>Grafiktechnologien wie Raytracing und Variable Rate Shading hauchen deinen Spielen eine neue Dimension von Realismus ein."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Ein Grafikerlebnis für jedes Level"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "BESCHLEUNIGE DEINE FRAMES, BESCHLEUNIGE DEIN GAMEPLAY",
                    "tr_specText": "Die Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling-Technik (X<sup class='small_text_sup_xe'>e</sup>SS) bringt Gaming dank KI-gestützter Hochskalierung auf das nächste Level – mit Spitzenleistung und großer Realitätsnähe. X<sup class='small_text_sup_xe'>e</sup>SS ist für Intel® Arc™ Grafikprodukte optimiert, damit du die Vorteile der Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX) Hardware-Beschleunigung voll ausnutzen kannst."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "DAS ULTIMATIVE BILDERLEBNIS IST&nbsp;DA",
                    "tr_specText": "Durch die vollständige Unterstützung für DirectX* 12 Ultimate auf jeder Intel® Arc™ Grafik kannst du dank neuester Grafiktechnologien wie Raytracing, Variable Rate Shading, Mesh Shading und Sampler-Feedback atemberaubendes, immersives Gameplay genießen."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "NAHTLOSE FOTO-, VIDEO- UND MEDIENERSTELLUNG",
                    "tr_specText": "Entfessle deine Fantasie und begeistere dein Publikum mit immersiver digitaler Content-Gestaltung unter Verwendung einer ultramodernen Medien-Engine – aufgewertet durch KI und beschleunigt mit Intel® Technologie. Erzeuge spektakuläre Inhalte dank der Grafiklösung mit Unterstützung für alle aktuell führenden Medienformate und bleibe mit den fortschrittlichsten AV1-Videocodierfunktionen auf dem Laufenden."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "ERLEBE MODERNSTE KI&#8209;BASIERTE KREATIV&#8209;&nbsp;UND GAMING&#8209;FUNKTIONEN",
                    "tr_specText": "Intel® Arc™ Grafik ermöglicht leistungsstarke KI-Funktionen, von fortschrittlicher Content-Gestaltung bis hin zu immersivem Gaming. Topaktuelle hoch entwickelte Kreativsoftware, einschließlich Bilderzeugung aus Texteingabe und KI-beschleunigter Videobearbeitung, bietet ungekannte Möglichkeiten. Dazu kommt der Genuss von hochaufgelöstem Gameplay durch KI-beschleunigte X<sup class='small_text_sup_xe'>e</sup>SS-Hochskalierung."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Professionelle Grafik beginnt hier"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "IDEAL FÜR UNTERWEGS TÄTIGE KREATIVE, DESIGNER UND INGENIEURE",
                    "tr_specText": "Die bei bestimmten Intel® Core™ Ultra Prozessoren der H-Reihe verfügbare, integrierte Intel® Arc™ Pro Grafik sorgt für eine 2-mal höhere Grafikleistung mit Independent Software Vendor (ISV)-Zertifizierungen für zusätzliche Zuverlässigkeit und bietet effiziente KI- und Raytracing-Hardwarebeschleunigung für die Erstellung beeindruckender Inhalte unterwegs.<br/><br/><span class='expandable_heading'>Software-Zertifizierungen<br/></span><br/>Intel hat im Laufe der Jahre mit Hunderten von Softwareunternehmen eng zusammengearbeitet und diese unübertroffene Erfahrung in unsere Intel® Arc™ Pro Grafikkarten der A-Reihe einfließen lassen. Zertifizierungen sind uns genauso wichtig wie dir."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "KI ZUGÄNGLICH GEMACHT",
                    "tr_specText": "Diese GPU-Reihe bietet eine umfassende Auswahl an großartiger Technologie, einschließlich der Unterstützung deiner kompatiblen KI-Tools mit integrierter KI-Beschleunigung, wo du diese benötigst."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "RAYTRACING OHNE DAS HOHE PREISSCHILD",
                    "tr_specText": "Grafikkarten müssen mehr tun als nur Pixel zu Displays zu schieben, sie müssen auch die Generierung schöner Bilder beschleunigen. Raytracing-Hardware-Technik, die in die Intel® Arc™ Pro Grafik der A-Reihe integriert ist, ermöglicht deiner kompatiblen Software, diese neuen professionellen Workflows zu erkunden. Bisher dachte man, dass Raytracing nur bei teuren Grafikkarten verfügbar ist, doch Intels neueste professionelle Grafik belehrt uns eines Besseren."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "CONTENT-GESTALTER PROFITIEREN VON IMMERSIVEN DISPLAYS",
                    "tr_specText": "Durch die vollständige Unterstützung für DirectX* 12 Ultimate auf jeder Intel® Arc™ Grafik kannst du dank neuester Grafiktechnologien wie Raytracing, Variable Rate Shading, Mesh Shading und Sampler-Feedback atemberaubendes, immersives Gameplay genießen."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "DYNAMISCH OPTIMIERTE BILDER",
                    "tr_specText": "Mit Dekodierung durch dedizierte Hardware bei den Pro-GPUs verhilft Dolby Vision* kompatiblen Displays zu mehr Tiefe, schärferem Kontrast und mehr Farben. Dolby Vision* eröffnet das volle Potenzial der HDR-Technik durch eine dynamische Optimierung der Bildqualität je nach Dienst, Anzeigegerät und Plattform und bietet so immer eine atemberaubende Grafik."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "VON EXPERTEN GEPRÜFTE SOFTWARE",
                    "tr_specText": "Intel hat im Laufe der Jahre mit Hunderten von Softwareunternehmen eng zusammengearbeitet und diese unübertroffene Erfahrung in die professionelle Intel® Arc™ Pro Grafik der A-Reihe einfließen lassen. Zertifizierungen und Software-Workflow-Qualifizierungen sind uns genauso wichtig wie dir."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "RAYTRACING OHNE DAS HOHE PREISSCHILD",
                    "tr_specText": "Grafikkarten müssen mehr tun als nur Pixel zu Displays zu schieben, sie müssen auch die Generierung schöner Bilder beschleunigen. Raytracing-Hardware-Technik, die in die Intel® Arc™ Pro Grafik der A-Reihe integriert ist, ermöglicht deiner kompatiblen Software, diese neuen professionellen Workflows zu erkunden. Bisher dachte man, dass Raytracing nur bei teuren Grafikkarten verfügbar ist, doch Intels neueste professionelle Grafik belehrt uns eines Besseren."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "MEHRERE GROẞE DISPLAYS MIT EINER KLEINEN MOBILEN GPU",
                    "tr_specText": "Die Intel® Arc™ Pro Grafik für Laptops unterstützt bis zu vier ultragroße Displays, um deine Workflow-Effizienz zu steigern. Darüber hinaus bietet sie auch Unterstützung für HDR (High Dynamic Range), sodass du alles hast, was du für dein Multi-Display-Setup zuhause oder im Büro benötigst."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "VOLLES POTENZIAL IN SACHEN SPEICHERBANDBREITE",
                    "tr_specText": "Die Bandbreite deiner Grafikkarte ist bei einer Workstation ein wichtiger Aspekt. Wenn diese falsch ist, kann das professionelle Workflows verlangsamen, weshalb wir sichergestellt haben, dass die Intel® Arc™ Pro GPU der A-Reihe Unterstützung für modernes PCIe* 4.0 bietet, ohne dass die Rückwärtskompatibilität beeinträchtigt wird. Der Grafikspeicher wird zudem durch eine hohe Bandbreitengeschwindigkeit erhöht, dadurch kann deine Software noch schneller auf Projektdaten zugreifen."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "ERWECKE JEDEN FRAME ZUM LEBEN",
                    "tr_specText": "<span class='expandable_heading'>KI-gestützt. Von Intel unterstützt.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS ist eine KI-unterstützte Upscaling-Technologie, die die PC-Leistung steigert, ohne die Brillanz eines einzigen Bildes zu opfern – damit du dein Gameplay so erleben kannst, wie es sein sollte."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "ECHTZEIT.<br/> ECHT REALISTISCH.<br/> ECHT BEEINDRUCKEND.",
                    "tr_specText": "Dich erwarten realistischere Welten. Mit dem integrierten Raytracing erlebst du alles von lebensechten Schatten und Reflexionen bis hin zu meisterhafter Beleuchtung. Mit anderen Worten: Ein visuelles Gaming-Erlebnis, das du nicht verpassen möchtest."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "ZEIG DEIN GAMEPLAY VON SEINER BESTEN SEITE",
                    "tr_specText": "Biete deinen Zuschauern das nahtlose Gameplay, das sie gerne sehen möchten. Die hardwarebeschleunigten AV1-Kodierungsfunktionen der Intel® Arc™ Grafik reißen dein Publikum mitten ins Geschehen. Höhere Bildqualität bei gleicher Bitrate bedeutet bestes Streaming bei gleichzeitiger Einsparung von Bandbreite."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Hallo an alle Content-Gestalter",
                    "tr_specText": "Neue und verbesserte KI-Möglichkeiten stehen dir direkt zur Verfügung. Dank der fortschrittlichen KI-Beschleuniger, die in Intel® Arc™ Grafik der A-Reihe integriert sind, bleibt bei vielen Anwendungen mehr Zeit für die Umsetzung kreativer Ideen, sodass du weniger Zeit mit Warten verbringst.<br/><br/>Erreiche die nächste Stufe in der Videoerstellung und geh voran bei hochwertigem, speichersparendem AV1-Video mit fortschrittlichen AV1-Hardwarefunktionen, die in Intel® Arc™ Grafik integriert sind."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Betriebssystem",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Alles, was Sie benötigen, um Ihre Arbeit zu erledigen",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Starke Leistung, auf einem dünnen, leichten und langlebigen Gerät — Sie können alles haben. Mit einer hervorragenden Akkulaufzeit, schönen Touchscreens* und den neuesten Solid-State-Laufwerken (SSDs) erfüllt ein Windows 10-PC all Ihre Wünsche.",
                "tr_noteSection": "Hardwareabhängig.",
                "titleArray": [
                  {
                    "tr_title": "Weniger ist mehr",
                    "tr_subtitle": "Heutige PCs verbrauchen normalerweise weniger Strom, sodass der Akku länger hält — sie bieten durchschnittlich mehr als acht Stunden Videowiedergabe**.",
                    "tr_noteSection": "** Die Akkulaufzeit schwankt erheblich, abhängig von Einstellungen, Nutzung und anderen Faktoren."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Betriebssystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "ALLES, WAS SIE BENÖTIGEN, UM IHRE ARBEIT ZU ERLEDIGEN",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Starke Leistung, auf einem dünnen, leichten und langlebigen Gerät — Sie können alles haben. Mit einer hervorragenden Akkulaufzeit, schönen Touchscreens* und den neuesten Solid-State-Laufwerken (SSDs) erfüllt ein Windows 10-PC all Ihre Wünsche.",
                "tr_noteSection": "Hardwareabhängig.",
                "titleArray": [
                  {
                    "tr_title": "WENIGER IST MEHR",
                    "tr_subtitle": "Heutige PCs verbrauchen normalerweise weniger Strom, sodass der Akku länger hält — sie bieten durchschnittlich mehr als acht Stunden Videowiedergabe**.",
                    "tr_noteSection": "** Die Akkulaufzeit schwankt erheblich, abhängig von Einstellungen, Nutzung und anderen Faktoren."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Betriebssystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Optimiert für Sicherheit und starke Leistung",
                "tr_pretitle": "Windows* 10 im S-Modus",
                "tr_subtitle": "Wenn Windows* 10 im S Modus läuft, wird eine zusätzliche Sicherheitsstufe hinzugefügt, die Arbeit, Spiel und Browsen mit der beruhigenden Gewissheit ermöglicht, dass das Gerät geschützt ist."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Betriebssystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "OPTIMIERT FÜR SICHERHEIT UND STARKE LEISTUNG",
                "tr_title": "Windows* 10 im S-Modus",
                "tr_subtitle": "Wenn Windows* 10 im S Modus läuft, wird eine zusätzliche Sicherheitsstufe hinzugefügt, die Arbeit, Spiel und Browsen mit der beruhigenden Gewissheit ermöglicht, dass das Gerät geschützt ist."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Betriebssystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Für Großartiges gemacht",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Leistung, Preis und jede Menge Auswahl. Von innovativen 2in1-Computern über flache und leichte Laptops bis hin zu Touchscreens und abnehmbaren Tastaturen: In der großen Auswahl findest du einen passenden PC mit Windows*, der perfekt zu deinem Budget und deinem Style passt."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Betriebssystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FÜR GROẞARTIGES GEMACHT",
                "tr_title": "Windows*",
                "tr_subtitle": "Leistung, Preis und jede Menge Auswahl. Von innovativen 2in1-Computern über flache und leichte Laptops bis hin zu Touchscreens und abnehmbaren Tastaturen: In der großen Auswahl findest du einen passenden PC mit Windows*, der perfekt zu deinem Budget und deinem Style passt."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Betriebssystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Betriebssystem",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Arbeitsspeicher",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "WIE VIEL ARBEITSSPEICHER BRAUCHST DU?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Alltag – 2&nbsp;BIS 8 GB RAM",
                    "tr_badgeSubTitle": "Ideal für alle, die ihren PC in erster Linie für Textverarbeitung und Web-Browsing nutzen und normalerweise nur jeweils ein Programm verwenden."
                  },
                  {
                    "tr_badgeTitle": "Starke Leistung – 8 BIS 16 GB RAM",
                    "tr_badgeSubTitle": "Mit mehr RAM laufen deine Anwendungen nicht nur schneller, sondern du kannst auch mehr Anwendungen gleichzeitig verwenden."
                  },
                  {
                    "tr_badgeTitle": "Enorm starke Leistung – MEHR ALS 16&nbsp;GB RAM",
                    "tr_badgeSubTitle": "Wenn du dich für Gaming, Foto- und Videobearbeitung oder 3D&#8209;Modellierung interessierst, brauchst du mehr RAM, um diese rechenintensiven Programme zu verarbeiten."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARBEITS&shy;SPEICHER",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM für Multitasking im Alltag",
                "tr_subtitle": "Der Arbeitsspeicher sorgt dafür, dass einfache Anwendungen wie Textverarbeitungsprogramme und Web-Browsing reibungslos geladen und ausgeführt werden.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARBEITS&shy;SPEICHER",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM für leistungsstarkes Multitasking",
                "tr_subtitle": "Wenn du häufig mehrere Programme gleichzeitig verwendest, kannst du mit [memory_total]&nbsp;GB RAM mühelos zwischen den Anwendungen wechseln.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARBEITS&shy;SPEICHER",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM für enorme Multitasking-Power",
                "tr_subtitle": "Mit dieser Ausstattung hast du ausreichend Arbeitsspeicher, um mehrere rechenintensive Programme gleichzeitig auszuführen, ohne dass dein PC langsamer wird.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARBEITS&shy;SPEICHER",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1] GB Arbeits&shy;speicher = [Value2]&nbsp;GB Intel®&nbsp;Optane™ Speicher + [Value3]&nbsp;GB&nbsp;RAM",
                "tr_subtitle": "Diese Kombination von Speicherlösungen bietet dir eine beschleunigte Festplattenleistung durch den Intel® Optane™ Speicher sowie eine höhere Reaktionsgeschwindigkeit mithilfe des RAM-Speichers.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>ARBEITS&shy;SPEICHER</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ Speicher",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Weniger Wartezeiten. Mehr von deinen Lieblingsinhalten.",
                "tr_subtitle": "Wenn Intel® Optane™ Speicher mit dem Systemspeicher deines PCs zusammenarbeitet, hast du schnelleren Zugriff auf häufig verwendete Programme, sodass diese sofort gestartet und geladen werden können, wenn du sie benötigst.",
                "tr_pretitle": "[XX]&nbsp;GB Intel® Optane™ Speicher",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "WAS BEWIRKT DER ARBEITSSPEICHER IN MEINEM PC?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Schnelleres Erstellen von Content",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Gaming mit besserer Reaktionsgeschwindigkeit",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Einfacheres Multitasking",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Schnellere Programmstarts",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ Speicher",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Mehr Tempo für deine Games</span>",
                "tr_pretitle": "[XX]&nbsp;GB Intel® Optane™ Speicher",
                "tr_pretitle1": "[XX]&nbsp;GB Intel® Optane™ Speicher beschleunigen das Gaming",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Starten von Spielen bis zu <br/><span class=\"optane-game\">98&nbsp;% schneller</span><br/>– vom Datenträger eines Systems mit [optane_legal_processor_name] Prozessor mit Intel® Optane™ Speicher<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Laden von Spielelevels bis zu <br/><span class=\"optane-game\">4,1-mal schneller</span><br/>– vom Datenträger eines Systems mit [optane_legal_processor_name] Prozessor mit Intel® Optane™ Speicher<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Intel® Optane™ Speicher",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Mehr Tempo für deine Games</span>",
                "tr_pretitle": "[XX]&nbsp;GB Intel® Optane™ Speicher",
                "tr_pretitle1": "[XX]&nbsp;GB Intel® Optane™ Speicher beschleunigen das Gaming",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Starten von Spielen bis zu <br/><span class=\"optane-game\">98&nbsp;% schneller</span><br/>– vom Datenträger eines Systems mit [optane_legal_processor_name] Prozessor mit Intel® Optane™ Speicher<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Laden von Spielelevels bis zu <br/><span class=\"optane-game\">4,1-mal schneller</span><br/>– vom Datenträger eines Systems mit [optane_legal_processor_name] Prozessor mit Intel® Optane™ Speicher<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARBEITS&shy;SPEICHER",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1] GB Arbeits&shy;speicher = [Value2]&nbsp;GB Intel®&nbsp;Optane™ Speicher + [Value3]&nbsp;GB&nbsp;RAM",
                "tr_subtitle": "Diese Kombination von Speicherlösungen bietet dir eine beschleunigte Festplattenleistung durch den Intel® Optane™ Speicher sowie eine höhere Reaktionsgeschwindigkeit mithilfe des RAM-Speichers.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB RAM für Multitasking",
                    "tr_subtitle": "Wenn du häufig mehrere Programme gleichzeitig verwendest, kannst du mit [memory_total]&nbsp;GB RAM mühelos zwischen den Anwendungen wechseln."
                  },
                  {
                    "tr_title": "Für alltägliche Aufgaben",
                    "tr_subtitle": "Der Arbeitsspeicher sorgt dafür, dass einfache Anwendungen wie Textverarbeitungsprogramme und Web-Browsing reibungslos geladen und ausgeführt werden."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARBEITS&shy;SPEICHER",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB RAM",
                "tr_pretitle": "FÜR MEHR MULTITASKING",
                "tr_subtitle": "RAM gibt deinem Prozessor Zugriff auf Programmdateien: Die CPU kann schneller darauf zugreifen und mehrere Aufgaben gleichzeitig verarbeiten.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standard-Arbeitsspeicher",
                    "tr_badgeSubTitle": "Bis zu 8&nbsp;GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Erweiterter Arbeitsspeicher",
                    "tr_badgeSubTitle": "Mehr als 8 GB und bis zu 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Ultimativer Arbeitsspeicher",
                    "tr_badgeSubTitle": "Mehr als 16&nbsp;GB RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARBEITS&shy;SPEICHER",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX]&nbsp;GB für mehr Multitasking",
                "tr_pretitle": "FÜR MEHR MULTITASKING",
                "tr_subtitle": "RAM gibt deinem Prozessor Zugriff auf Programmdateien: Die CPU kann schneller darauf zugreifen und mehrere Aufgaben gleichzeitig verarbeiten.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standard-Arbeitsspeicher",
                    "tr_badgeSubTitle": "Bis zu 8&nbsp;GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Erweiterter Arbeitsspeicher",
                    "tr_badgeSubTitle": "Mehr als 8 GB und bis zu 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Ultimativer Arbeitsspeicher",
                    "tr_badgeSubTitle": "Mehr als 16&nbsp;GB RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER SPEICHER IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Standard",
                      "tr_badgeSubTitle": "<span class='memory-value'>8&nbsp;GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Fortgeschritten",
                      "tr_badgeSubTitle": "<span class='memory-value'>16&nbsp;GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Elite",
                      "tr_badgeSubTitle": "<span class='memory-value'>32&nbsp;GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Arbeitsspeicher",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "WIE SCHNEIDET DIESER SPEICHER IM VERGLEICH AB?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "WIE SCHNEIDET DIESER SPEICHER IM VERGLEICH AB?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuelles Gerät",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Alltagsstandard",
                      "tr_badgeSubTitle": "<span class='memory-value'>8&nbsp;GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Leistungsstark",
                      "tr_badgeSubTitle": "<span class='memory-value'>16&nbsp;GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Fortgeschritten",
                      "tr_badgeSubTitle": "<span class='memory-value'>32&nbsp;GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Massenspeicher",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "WIE GROẞ IST DIE SPEICHERKAPAZITÄT DEINES PCs?",
                "tr_subtitle": "Du musst nicht raten. Wir zeigen dir genau, was du auf diesem PC speichern kannst.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Erstellen einer Bibliothek mit</span> <br/><span class=\"storage-standard\">[num_songs] Songs<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>basierend auf durchschnittlich 5&nbsp;MB pro Musiktitel",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Erstellen einer Sammlung mit</span> <br/><span class=\"storage-standard\">[num_games] Games<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>basierend auf durchschnittlich 40&nbsp;GB pro Spiel",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Datenspeicher für</span> <br/><span class=\"storage-standard\">[num_videos] Videos<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>basierend auf durchschnittlich 5&nbsp;GB pro Video",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Aufnehmen und Speichern von</span> <br/><span class=\"storage-standard\">[num_photos] Fotos<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>basierend auf durchschnittlich 4&nbsp;MB pro Foto",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "HDD mit [capacity]&nbsp;GB"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "SSD mit [capacity]&nbsp;GB"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory] GB Intel® Optane™ Speicher + SSD mit [convertedSSD] GB"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MASSEN&shy;SPEICHER",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "SSD-Datenspeicher mit [capacity]&nbsp;GB",
                "tr_preTitle1": "SSD-Datenspeicher mit [capacity]&nbsp;TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Dieser PC hat als Datenspeicher eine SSD mit einer Kapazität von [capacity]&nbsp;GB. Das bedeutet, dass du eine beeindruckende Anzahl an Fotos, Videos und Programmen speichern und im Vergleich zu einer HDD viel schneller darauf zugreifen kannst.",
                    "tr_title": "Ein Platz für alles auf deinem PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Dieser PC hat als Datenspeicher eine SSD mit einer Kapazität von [capacity]&nbsp;TB. Das bedeutet, dass du eine beeindruckende Anzahl an Fotos, Videos und Programmen speichern und im Vergleich zu einer HDD viel schneller darauf zugreifen kannst.",
                    "tr_title": "Dieses Gerät verfügt über einen beeindruckenden Datenspeicher."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;GB<br/></span><span class='subtitle-value'>DATENSPEICHER</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity]&nbsp;TB<br/></span><span class='subtitle-value'>DATENSPEICHER</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MASSEN&shy;SPEICHER",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "HDD-Datenspeicher mit [capacity]&nbsp;GB",
                "tr_preTitle1": "HDD-Datenspeicher mit [capacity]&nbsp;TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Festplattenlaufwerke sind eine gute Wahl für Benutzer, die eine große Menge an Speicherplatz direkt auf dem PC benötigen.",
                    "tr_title": "Ein Platz für alles auf deinem PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Festplattenlaufwerke sind eine gute Wahl für Benutzer, die eine große Menge an Speicherplatz direkt auf dem PC benötigen.",
                    "tr_title": "Dieses Gerät verfügt über einen beeindruckenden Datenspeicher."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>DATEN&shy;SPEICHER</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MASSEN&shy;SPEICHER",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "WIE GROẞ IST DIE SPEICHERKAPAZITÄT DEINES PCs?",
                "tr_subtitle": "Du musst nicht raten. Wir zeigen dir genau, was du auf diesem PC speichern kannst.",
                "titleArray": [
                  {
                    "tr_title": "HDD mit [XX] GB"
                  },
                  {
                    "tr_title": "SSD mit [XX] GB"
                  },
                  {
                    "tr_title": "Intel® SSD mit [total_capacity]&nbsp;GB, beschleunigt durch [optane_memory_size]&nbsp;GB Intel® Optane™ Speicher"
                  },
                  {
                    "tr_title": "Intel® SSD mit [total_capacity]&nbsp;TB, beschleunigt durch [optane_memory_size]&nbsp;GB Intel® Optane™ Speicher"
                  },
                  {
                    "tr_title": "SSD mit [convertedSSD]&nbsp;GB + HDD mit [convertedHDD]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD mit [convertedSSD] TB + HDD mit [convertedHDD] GB"
                  },
                  {
                    "tr_title": "SSD mit [convertedSSD] GB + HDD mit [convertedHDD] TB"
                  },
                  {
                    "tr_title": "SSD mit [convertedSSD] TB + HDD mit [convertedHDD] TB"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;GB<br/></span><span class='subtitle-value'>DATENSPEICHER</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;GB<br/></span><span class='subtitle-value'>DATENSPEICHER</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;GB<br/></span><span class='subtitle-value'>DATENSPEICHER</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;TB<br/></span><span class='subtitle-value'>DATENSPEICHER</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;TB<br/></span><span class='subtitle-value'>DATENSPEICHER</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;TB<br/></span><span class='subtitle-value'>DATENSPEICHER</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MASSEN&shy;SPEICHER",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "SSD mit [convertedSSD]&nbsp;GB + HDD mit [convertedHDD]&nbsp;GB",
                "tr_preTitle1": "SSD mit [convertedSSD]&nbsp;TB + HDD mit [convertedHDD]&nbsp;GB",
                "tr_preTitle2": "SSD mit [convertedSSD]&nbsp;GB + HDD mit [convertedHDD]&nbsp;TB",
                "tr_preTitle3": "SSD mit [convertedSSD]&nbsp;TB + HDD mit [convertedHDD]&nbsp;TB",
                "titleArray": [
                  {
                    "tr_title": "Ein Platz für alles auf deinem PC",
                    "tr_title1": "Dieses Gerät verfügt über einen beeindruckenden Datenspeicher.",
                    "tr_subtitle": "Dieser PC verfügt über eine SSD mit [convertedSSD] GB Speicherkapazität und eine HDD mit [convertedHDD] GB Speicherkapazität. So erhältst du sowohl enorme Speicherkapazität als auch schnelle Speicherleistung.",
                    "tr_subtitle1": "Dieser PC verfügt über eine SSD mit [convertedSSD] TB Speicherkapazität und eine HDD mit [convertedHDD] GB Speicherkapazität. So erhältst du sowohl enorme Speicherkapazität als auch schnelle Speicherleistung.",
                    "tr_subtitle2": "Dieser PC verfügt über eine SSD mit [convertedSSD] GB Speicherkapazität und eine HDD mit [convertedHDD] TB Speicherkapazität. So erhältst du sowohl enorme Speicherkapazität als auch schnelle Speicherleistung.",
                    "tr_subtitle3": "Dieser PC verfügt über eine SSD mit [convertedSSD] TB Speicherkapazität und eine HDD mit [convertedHDD] TB Speicherkapazität. So erhältst du sowohl enorme Speicherkapazität als auch schnelle Speicherleistung."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;GB<br/></span><span class='subtitle-value'>DATENSPEICHER</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity]&nbsp;TB<br/></span><span class='subtitle-value'>DATENSPEICHER</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MASSEN&shy;SPEICHER",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Intel® Optane™ Speicher mit SSD&nbsp;– schnellerer Zugriff&nbsp;auf Speicher mit hoher Kapazität.",
                "tr_title": "Keine Angst vor großen Dateien",
                "tr_pretitle": "Intel® SSD mit [total_capacity]&nbsp;GB, beschleunigt durch [optane_memory_size]&nbsp;GB Intel® Optane™ Speicher",
                "tr_pretitle1": "Intel® SSD mit [total_capacity]&nbsp;TB, beschleunigt durch [optane_memory_size]&nbsp;GB Intel® Optane™ Speicher",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Diese revolutionäre Speicherlösung ist die erste Lösung, die Intel® Optane™ Speicher und eine SSD auf einem Chip vereinigt. Die Vorteile:",
                    "tr_title": "WAS IST INTEL® OPTANE™ SPEICHER MIT SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Alles, was du brauchst, ist blitzschnell geöffnet",
                    "tr_badgeSubTitle": "Da häufig verwendete Dateien und Programme bereits vorab geladen werden, kannst du gleich loslegen, statt lange zu warten.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Speicherkapazität plus Geschwindigkeit",
                    "tr_badgeSubTitle": "Du hast ausreichend Platz für Mediendateien und kannst zugleich die schnelle Leistung einer SSD genießen.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Schnellere Reaktionen mit zunehmender Nutzung",
                    "tr_badgeSubTitle": "Intel® Optane™ Speicher mit SSD passt sich an deine individuelle Arbeitsweise an. So wird der Umgang mit deinem PC noch effizienter.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MASSEN&shy;SPEICHER",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Auf einem zuverlässigen Datenspeicher mit hoher Kapazität sind deine Medien stets griffbereit.",
                "tr_title": "Speicherkapazität für alle deine Fotos, Videos und Musiktitel"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Display",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] Millionen fantastischer Pixel zum Wischen, Schieben und Ziehen",
                "tr_subtitle": "Mit intuitiven Steuerungen und lebendigeren, schärfer dargestellten Details kannst du mit deinem PC genauso interagieren wie mit einem Smartphone.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Auflösung: [screenResolutionX] x [screenResolutionY]",
                    "tr_badgePreTitle": "Pixel: [screenResolution] Millionen"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Bildschirm mit [screenDiagonal]&nbsp;Zoll",
                    "tr_badgeSubTitle": "Bildschirmhöhe: [screenHeight] Zoll",
                    "tr_badgePreTitle": "Bildschirmbreite: [YY] Zoll"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Touchscreen",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Display",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Immersive Erlebnisse mit [screenResolution]&nbsp;Millionen Pixeln",
                "tr_subtitle": "Alles hängt von den Pixeln ab. Je mehr du zur Verfügung hast, desto schärfer und brillanter werden deine Bilder dargestellt.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Auflösung: [screenResolutionX] x [screenResolutionY]",
                    "tr_badgePreTitle": "Pixel: [screenResolution] Millionen"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Bildschirm mit [screenDiagonal]&nbsp;Zoll",
                    "tr_badgeSubTitle": "Bildschirmhöhe: [screenHeight] Zoll",
                    "tr_badgePreTitle": "Bildschirmbreite: [YY] Zoll"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Display",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] Zoll <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "DAS FENSTER ZU DEINER GAMING-WELT",
                "tr_subtitle": "Wie bei einem TV-Gerät spielen auch bei einem Gaming-Bildschirm Größe und Auflösung die wichtigste Rolle.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Auflösung: [screenResolutionX] x [screenResolutionY]",
                    "tr_badgePreTitle": "Pixel: [screenResolution] Millionen"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Bildschirm mit [screenDiagonal]&nbsp;Zoll",
                    "tr_badgeSubTitle": "Bildschirmhöhe: [screenHeight] Zoll",
                    "tr_badgePreTitle": "Bildschirmbreite: [screenWidth] Zoll"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Touchscreen"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Display",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Dieses Gerät verfügt über ein beeindruckendes Display",
                "tr_subtitle": "Mit der achtfachen Anzahl von Pixeln im Vergleich zu HD erreichen Videos und Spiele eine ganz neue Schärfe und Farbintensität.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Auflösung: [screenResolutionX] x [screenResolutionY]",
                    "tr_badgePreTitle": "Pixel: [screenResolution] Millionen"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Bildschirm mit [screenDiagonal]&nbsp;Zoll",
                    "tr_badgeSubTitle": "Bildschirmhöhe: [screenHeight] Zoll",
                    "tr_badgePreTitle": "Bildschirmbreite: [YY] Zoll"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Display",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] Millionen Pixel, einfach antippen, wischen oder berühren",
                    "tr_subTitle": "Schau dir die Dinge an, die dir gefallen, und interagiere mit ihnen – in einer gestochen scharfen und lebendigeren Darstellung."
                  },
                  {
                    "tr_title": "[screenResolution] Millionen Pixel verschönern deine digitale Welt",
                    "tr_subTitle": "Alles hängt von den Pixeln ab. Je mehr du zur Verfügung hast, desto schärfer und brillanter werden deine Bilder dargestellt."
                  },
                  {
                    "tr_title": "Dieses Gerät verfügt über ein beeindruckendes Display",
                    "tr_subTitle": "Bei fast viermal so vielen Pixeln wie HD erlangen Videos und Spiele eine völlig neue Dimension der Schärfe und Farbe."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Konnektivität",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Die Thunderbolt™ 3 Technik bietet die schnellste, vielseitigste Schnittstellenverbindung zu Dockingstations, Bildschirmen oder Datengeräten.",
                "tr_title": "Ein Anschluss, der alles kann",
                "tr_title1": "EIN ANSCHLUSS, DER ALLES KANN",
                "titleArray": [
                  {
                    "tr_title": "WIE KANN ICH DIE THUNDERBOLT™ 3 TECHNIK VERWENDEN?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™&nbsp;3 Technik"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K-Bildschirme",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Schließe Bildschirme mit beeindruckender Leistung bei Auflösung, Kontrast und Farbe an"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Externe Grafikkarte",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Gib jedem PC ein sofortiges umfassendes Grafikupgrade"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Schnelle Netzwerkverbindungen",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Bietet schnelle Peer-to-Peer-Verbindungen"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Konnektivität",
                "detailBGUrl": "",
                "tr_subtitle": "Die Thunderbolt™&nbsp;3 Technik bietet eine höhere Datenübertragungsrate als herkömmliche USB-Schnittstellen. So kannst du Dateien zügig übertragen und Peripheriekomponenten wie Bildschirme oder externe Grafikdocks einfach anschließen.",
                "tr_title": "Thunderbolt™&nbsp;3 Technik",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "BLITZSCHNELLE DATEN",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbit/s",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbit/s",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Thunderbolt™&nbsp;3 Technik",
                    "tr_badgeSubTitle": "40 Gbit/s",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Konnektivität",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Ein Anschluss und ein Kabel, die universell verwendbar sind, unterstützen jede Komponente deines Lieblingszubehörs.</li> <li>Zuverlässige Ladefunktion für deinen Laptop</li> <li>Robuste Zertifizierung für beruhigende Sicherheit</li></ul>",
                "tr_title": "Eine wirklich universelle Kabelverbindung",
                "tr_title1": "EINE WIRKLICH UNIVERSELLE KABELVERBINDUNG",
                "tr_noteSection": "<sup>†</sup>Basierend auf den Mindestanforderungen der technischen Spezifikationen",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "WIE KANN ICH DIE THUNDERBOLT™ 4 TECHNIK VERWENDEN?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™&nbsp;4 Technik"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Content-Entwicklung",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Schließe einen externen Monitor und ein schnelles Speichermedium an, um deine Fotos und Videos anzuzeigen und zu bearbeiten."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dock",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Verabschiede dich vom Kabelgewirr auf deinem Schreibtisch und schließe dein gesamtes Zubehör über ein einziges Kabel an deinen Laptop an."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Spiel",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Erweitere die SSD- und HDD-Kapazität für deine Spiele- und Mediensammlung mit Geschwindigkeiten von bis zu 3000 MB/s."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximale Leistung<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4-mal so schnell wie USB* 3.2 <br/>2-mal schneller als HDMI*&nbsp;2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Konnektivität",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Erlebe die fantastische Leistung, die du von der Thunderbolt™&nbsp;3 Technik kennst, mit noch mehr Funktionalität&nbsp;– für einfache und praktische Verbindungen zu den neuesten Docks, Monitoren und schnellen externen Datenspeichern, die für Thunderbolt™ geeignet sind, sowie beliebigen USB*-Peripheriekomponenten.",
                "tr_title": "Thunderbolt™&nbsp;4 Technik",
                "tr_pretitle": "EINE WIRKLICH UNIVERSELLE KABELVERBINDUNG",
                "tr_noteSection": "<sup>†</sup>Basierend auf den Mindestanforderungen der technischen Spezifikationen",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Ein universelles Thunderbolt™ 4 Kabel kann alle deine USB-C* Kabel ersetzen, was die Kabelauswahl schnell und einfach macht."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Dockingstationen für die Thunderbolt™ 4 Technik unterstützen bis zu vier Thunderbolt™ Ports für mehr Flexibilität beim Anschluss von beliebigem Zubehör."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximale Leistung<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4-mal so schnell wie USB* 3.2 <br/>2-mal schneller als HDMI*&nbsp;2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Konnektivität",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Ein Anschluss und ein Kabel, die universell verwendbar sind, unterstützen jede Komponente deines Lieblingszubehörs.</li> <li>Zuverlässige Ladefunktion für deinen Laptop</li> <li>Robuste Zertifizierung für beruhigende Sicherheit</li></ul>",
                "tr_title": "Maximiere dein Erlebnis mit Thunderbolt™ Zubehör.",
                "tr_noteSection": "<sup>†</sup>Basierend auf den Mindestanforderungen der technischen Spezifikationen",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "WIE KANN ICH DIE THUNDERBOLT™ 4 TECHNIK VERWENDEN?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Content-Entwicklung",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Schließe einen externen Monitor und ein schnelles Speichermedium an, um deine Fotos und Videos anzuzeigen und zu bearbeiten."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dock",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Verabschiede dich vom Kabelgewirr auf deinem Schreibtisch und schließe dein gesamtes Zubehör über ein einziges Kabel an deinen Laptop an."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Spiel",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Erweitere die SSD- und HDD-Kapazität für deine Spiele- und Mediensammlung mit Geschwindigkeiten von bis zu 3000 MB/s."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximale Leistung<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4-mal so schnell wie USB* 3.2 <br/>2-mal schneller als HDMI*&nbsp;2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Die gängigsten Typen von Wi‑Fi sind:",
                "tr_title": "BEDEUTUNG DER WLAN-STANDARDS",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 ermöglicht höhere Geschwindigkeiten, auch wenn viele Benutzer dasselbe Signal verwenden.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Mit der neuesten Wi-Fi-Innovation von Intel erzielst du mehr Geschwindigkeit als mit Gigabit-Wi-Fi, selbst wenn mehrere Benutzer das gleiche Signal verwenden.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Wi‑Fi 6E ist der größte Fortschritt für das WLAN seit 20 Jahren und macht neue Hochgeschwindigkeitskanäle mit verbesserter Leistung und Zuverlässigkeit sowie weniger Interferenzen verfügbar."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 ermöglicht höhere Geschwindigkeiten, auch wenn viele Benutzer dasselbe Signal verwenden.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Alle Vorteile von Wi-Fi 6E in Kombination mit der Intel® Killer™ Prioritization Engine, die die Bandbreite zu den Websites und Anwendungen verlagert, die sie am meisten benötigen."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Weniger Konkurrenz auf den Hochgeschwindigkeitskanälen sorgt für verbesserte Leistung und Zuverlässigkeit."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 ermöglicht höhere Geschwindigkeiten, auch wenn viele Benutzer dasselbe Signal verwenden.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Mit weniger Interferenzen durch Wi-Fi 6 und der intelligenten Leistung der Intel® Killer™ Prioritization Engine wird dein Online-Erlebnis noch schneller und reaktionsfähiger.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Weniger Konkurrenz auf den Hochgeschwindigkeitskanälen sorgt für verbesserte Leistung und Zuverlässigkeit."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "PC-Wi-Fi-Geschwindigkeiten von über 5 Gbit/s mit gleichbleibend extrem niedriger Latenz - teile Dateien in Sekunden statt Minuten."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) ist die neueste Technologie, die durch KI-basierte Netzwerkoptimierung „extrem“ hohe Zuverlässigkeit, niedrige Latenz und hohe Geschwindigkeiten ermöglicht."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 ermöglicht höhere Geschwindigkeiten, auch wenn viele Benutzer dasselbe Signal verwenden.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) erreicht mithilfe des 6-GHz-Kanals Geschwindigkeiten von mehr als 1 Gbit/s mit Zuverlässigkeit und geringer Latenz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Höhere Effizienz, Kapazität und Geschwindigkeit im Vergleich zu Wi-Fi 5 in überlasteten Netzwerken."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Mit Wi‑Fi [XX] bleibst du besser in Verbindung und genießt das Internet mit höherer Geschwindigkeit<sup>†</sup> und Zuverlässigkeit.",
                "tr_title": "Verbesserte Reichweite und Geschwindigkeit",
                "tr_noteSection": "<sup>†</sup>Der tatsächliche Durchsatz und die Reichweite der drahtlosen Verbindung hängen von verschiedenen Faktoren ab.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Im Zeitalter der Multiplayer- und Online-Games ist deine Internetverbindung wichtiger denn je. Neben Geschwindigkeit brauchst du zudem ein Funksignal, das diese Geschwindigkeit auch aus einiger Entfernung beibehält.",
                "tr_title": "[XX]",
                "tr_pretitle": "BESTENS VERNETZT",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Die Verbindung mit Intel® Wi‑Fi 6 (Gig+) zu Wi‑Fi 6-Routern mit Unterstützung für 160-MHz-Kanäle ermöglicht schnellere Datenübertragung und höhere Zuverlässigkeit für mehr aktive Nutzer.",
                "tr_title": "Fast 3-mal so schnell mit Intel® <span class='no-warp'>Wi-Fi&nbsp;6  </span>(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Im Vergleich zu standardmäßigem AC Wi‑Fi. Einige Funktionen sind nur für bestimmte SKUs verfügbar. Wenden Sie sich an den Hersteller, um Einzelheiten zu erfahren. Weitere Informationen zum Thema Wi‑Fi finden Sie unter intel.com/wifi6disclaimers."
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dualband Intel® Wi-Fi 6 der Einstiegsklasse bietet verbesserte Leistung für grundlegende Konnektivitätsanforderungen wie E&#8209;Mail und das Surfen im Internet in überlasteten Netzwerken.<sup>9</sup>",
                "tr_title": "Unverzichtbares WLAN",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Im Zeitalter der Multiplayer- und Online-Games ist deine Internetverbindung wichtiger denn je. Neben Geschwindigkeit brauchst du zudem ein Funksignal, das diese Geschwindigkeit auch aus einiger Entfernung beibehält.",
                "tr_title": "[XX]",
                "tr_pretitle": "BESTENS VERNETZT",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Diese neuen Wi‑Fi-Kanäle bieten die Voraussetzungen, dass die Bandbreite nicht mit älteren Geräten in der näheren Umgebung geteilt werden muss. So kannst du flüssig streamen, schnell im Internet surfen und zu Hause reibungslos arbeiten und lernen.",
                "tr_title": "Zugang zum Netz ohne Kampf um die Bandbreite",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Mit [XX] bleibst du besser in Verbindung und genießt das Internet mit höherer Geschwindigkeit<sup>†</sup> und Zuverlässigkeit.",
                "tr_title": "Verbesserte Reichweite und Geschwindigkeit",
                "tr_noteSection": "<sup>†</sup>Der tatsächliche Durchsatz und die Reichweite der drahtlosen Verbindung hängen von verschiedenen Faktoren ab.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Im Zeitalter der Multiplayer- und Online-Games ist deine Internetverbindung wichtiger denn je. Neben Geschwindigkeit brauchst du zudem ein Funksignal, das diese Geschwindigkeit auch aus einiger Entfernung beibehält.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "BESTENS VERNETZT",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Integriertes, ultraschnelles WLAN",
                "tr_subtitle": "Bleib auch ohne Kabel stets vernetzt."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Spezielle Hochgeschwindigkeits-Lanes, die für weniger Interferenzen sorgen, und die Intel® Killer™ Prioritization Engine, die Gaming über alles stellt, sind ein gewichtiges Upgrade für das Online-Gaming.",
                "tr_title": "Lass die Rivalen hinter dir",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Spezielle Hochgeschwindigkeits-Lanes, die für weniger Interferenzen sorgen, und die Intel® Killer™ Prioritization Engine, die Gaming über alles stellt, sind ein gewichtiges Upgrade für das Online-Gaming.",
                "tr_title": "[XX]",
                "tr_pretitle": "LASS DIE RIVALEN HINTER DIR",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Mit speziellen Highspeed-Lanes für weniger Störungen steht Gaming im Vordergrund. Online-Gaming hat gerade ein ernsthaftes Upgrade erfahren.",
                "tr_title": "[XX]",
                "tr_pretitle": "LASS DIE RIVALEN HINTER DIR",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi beschleunigt den Netzwerkverkehr automatisch für die Anwendungen, die dies am dringendsten benötigen, damit deine Streaming-Videos mit hoher Detailschärfe und weniger Zwischenspeicherungen dargestellt werden.",
                "tr_title": "Eine schnellere und intelligentere Möglichkeit für deine Verbindungen.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Im Zeitalter der Multiplayer- und Online-Games ist deine Internetverbindung wichtiger denn je. Neben Geschwindigkeit brauchst du zudem ein Funksignal, das diese Geschwindigkeit auch aus einiger Entfernung beibehält.",
                "tr_title": "[XX]",
                "tr_pretitle": "BESTENS VERNETZT",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Mit Wi-Fi 5 bleibst du besser in Verbindung und genießt das Internet mit höherer Geschwindigkeit<sup>†</sup> und Zuverlässigkeit.",
                "tr_title": "Verbesserte Reichweite und Geschwindigkeit",
                "tr_noteSection": "<sup>†</sup>Der tatsächliche Durchsatz und die Reichweite der drahtlosen Verbindung hängen von verschiedenen Faktoren ab.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Im Zeitalter der Multiplayer- und Online-Games ist deine Internetverbindung wichtiger denn je. Neben Geschwindigkeit brauchst du zudem ein Funksignal, das diese Geschwindigkeit auch aus einiger Entfernung beibehält.",
                "tr_title": "[XX]",
                "tr_pretitle": "LASS DIE RIVALEN HINTER DIR",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Die Funktionen von Wi-Fi 7 bauen auf früheren Wi-Fi-Generationen auf und verbessern diese. Dies wird nicht nur schnellere Geschwindigkeiten bedeuten, sondern auch eine drastisch verbesserte Reaktionsfähigkeit und Zuverlässigkeit für immersive Verbrauchererlebnisse und ausgefeilte zukünftige Technologien.",
                "tr_title": "Kabelähnliche PC-Netzwerktechnik",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Die Funktionen von Wi-Fi 7 bauen auf früheren Wi-Fi-Generationen auf und verbessern diese. Dies wird nicht nur schnellere Geschwindigkeiten bedeuten, sondern auch eine drastisch verbesserte Reaktionsfähigkeit und Zuverlässigkeit für immersive Verbrauchererlebnisse und ausgefeilte zukünftige Technologien.",
                "tr_title": "[XX]",
                "tr_pretitle": "TRANSFORMATION DES BENUTZERERLEBNISSES",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Verbesserte Geschwindigkeit, Latenz und Zuverlässigkeit durch KI-basierte Netzwerkoptimierung",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Verbesserte Geschwindigkeit, Latenz und Zuverlässigkeit durch KI-basierte Netzwerkoptimierung.",
                "tr_title": "[XX]",
                "tr_pretitle": "KABELÄHNLICHE REAKTIONSFÄHIGKEIT",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akku",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Wie erfolgt die Messung?",
                    "tr_badgeSubTitle": "Die meisten Hersteller geben die Akkulaufzeit in Stunden an. Obwohl diese Berechnung nicht zu 100 % akkurat ist, solltest du anhand dieser Angabe in der Lage sein, die Akkulaufzeit eines PCs mit der eines anderen zu vergleichen."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akku",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FÜR GAMING UNTERWEGS",
                "tr_subtitle": "Angesichts der starken Leistung moderner Gaming-Laptops gibt es keinen Grund, an einen festen Ort gebunden zu sein. Heute kannst du deine Games überallhin mitnehmen.",
                "tr_title": "[Dynamic.battery.values.hours] Stunden Akkulaufzeit"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akku",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Einfach Stromkabel ausstecken und stundenlang Apps oder Computerspiele nutzen und Musik hören.",
                "tr_title": "[XX] Stunden Streaming mit einer Akkuladung<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Basierend auf der vom PC-Hersteller angegebenen Akkulaufzeit.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] Stunden",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akku",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Einfach Stromkabel ausstecken und stundenlang Apps oder Computerspiele nutzen und Musik hören.",
                "tr_title": "[Dynamic.battery.values.hours] Stunden Akkulaufzeit für unterwegs<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Basierend auf der vom PC-Hersteller angegebenen Akkulaufzeit.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] Stunden",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akku",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Einfach Stromkabel ausstecken und stundenlang Apps oder Computerspiele nutzen und Musik hören.",
                "tr_title": "[Dynamic.battery.values.hours] Stunden Akkulaufzeit für den ganzen Tag mit&nbsp;nur&nbsp;einer Akkuladung<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Basierend auf der vom PC-Hersteller angegebenen Akkulaufzeit.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] Stunden",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Akku",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Dieser PC optimiert die Akkulaufzeit und wenn der Akku leer ist, reicht ein Ladevorgang von 30 Minuten, damit du das Gerät wieder mindestens vier Stunden im Akkubetrieb verwenden kannst.<sup>4</sup>",
                "tr_title": "Längere kabellose Freiheit mit [Dynamic.battery.values.hours]&nbsp;Stunden Akkulaufzeit<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Basierend auf der vom PC-Hersteller angegebenen Akkulaufzeit.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] Stunden",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Homepage",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Gründe für diesen PC",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Alle Spezifikationen anzeigen",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Fernverwaltung",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobil",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Rechtliche Hinweise",
        "tr_paragraph_array": [
          "In Leistungstests verwendete Software und Workloads können speziell für die Leistungseigenschaften von Intel® Mikroprozessoren optimiert worden sein. Leistungstests wie SYSmark* und MobileMark* werden mit spezifischen Computersystemen, Komponenten und Funktionen durchgeführt. Jede Veränderung bei einem dieser Faktoren kann abweichende Ergebnisse zur Folge haben. Als Unterstützung für eine umfassende Bewertung Ihrer vorgesehenen Anschaffung, auch im Hinblick auf die Leistung des betreffenden Produkts in Verbindung mit anderen Produkten, sollten Sie noch andere Informationen und Leistungstests heranziehen. Ausführlichere Angaben über Leistungs- und Benchmarkergebnisse finden Sie unter www.intel.com/benchmarks.",
          "¹Intel® Wi-Fi 6 (Gig+) Produkte unterstützen optionale 160-MHz-Kanäle und ermöglichen so die schnellstmögliche theoretische Maximalgeschwindigkeit (2402 Mbit/s) für PC-Wi-Fi-Produkte mit herkömmlichem 2x2 802.11ax. Intel® Wi-Fi-6-Premiumprodukte (Gig+) ermöglichen eine 2- bis 4-fache theoretische Maximalübertragungsrate im Vergleich zu üblichen 802.11ax-PC-WLAN-Produkten mit 2x2- (1201 Mbit/s) oder 1x1-Standard (600 Mbit/s), die lediglich die obligatorische Anforderung von 80-MHz-Kanälen unterstützen.",
          "²Empfehlung dient ausschließlich zu Informationszwecken. Für die Kaufentscheidung sollten Sie andere Informationsquellen heranziehen.",
          "³Die Funktionsmerkmale und Vorteile von Intel Technologie hängen von der Systemkonfiguration ab und können geeignete Hardware, Software oder die Aktivierung von Diensten erfordern. Die Leistung kann je nach Systemkonfiguration unterschiedlich ausfallen. Kein Computersystem bietet absolute Sicherheit. Informieren Sie sich beim Systemhersteller, Fachhändler oder auf intel.com.",
          "⁴Für Systeme mit FHD-Displays, die für Web-Browsing über eine Funkverbindung verwendet werden. Im ausgeschalteten Zustand, vom Standard-Shutdown-Level des OEMs.",
          "⁵Im Vergleich zu anderen PC-I/O-Schnittstellen, wie eSATA, USB und IEEE 1394* FireWire. Die Leistungseigenschaften können je nach verwendeter Hardware und Software unterschiedlich sein. Setzt ein für Thunderbolt™ Technik geeignetes Gerät voraus.",
          "⁶Beinahe 3-mal schneller: 802.11ax 2x2 160 MHz ermöglicht theoretische maximale Datenübertragungsraten von 2402 Mbit/s. Dies ist ca. 3-mal schneller als der Standard 802.11ac 2x2 80 MHz (867 Mbit/s). Angaben gemäß der Dokumentation in den IEEE-802.11*-Wireless-Standard-Spezifikationen. Erfordert die Verwendung von ähnlich konfigurierten 802.11ax-Wireless-Netzwerkroutern.",
          "⁷Reduzierung der Latenz um 75&nbsp;%: Basiert auf Simulationsdaten von Intel (79&nbsp;%) bei 802.11ax mit und ohne OFDMA unter Verwendung von 9 Clients. Die durchschnittliche Latenz ohne OFDMA beträgt 36&nbsp;ms; mit OFDMA lässt sich die durchschnittliche Latenz auf 7,6&nbsp;ms reduzieren. Für Latenzverbesserungen müssen der 802.11ax-Router (Wi-Fi 6) und alle Clients OFDMA unterstützen.",
          "⁸Basierend auf dem Vergleich der theoretischen maximalen Datenrate in der 802.11-Spezifikation kann 2x2 160 MHz Wi‑Fi 6/6E (802.11ax) 2402 Mbit/s übertragen. Im Vergleich dazu kann 2x2 80 MHz Wi‑Fi 5 (802.11ac) 867 Mbit/s übertragen, was einer 2,8-mal höheren Geschwindigkeit entspricht.",
          "⁹Branchenführendes Wi‑Fi 6: Intel® Wi‑Fi-6-Produkte (Gig+) unterstützen optionale 160-MHz-Kanäle und ermöglichen so die schnellstmögliche theoretische Maximalgeschwindigkeit (2402 Mbit/s) für PC-WLAN-Produkte mit herkömmlichem 2x2 802.11ax. Intel® Wi‑Fi-6-Premiumprodukte (Gig+) ermöglichen eine 2- bis 4-fache theoretische Maximalübertragungsrate im Vergleich zu üblichen 802.11ax-PC-WLAN-Produkten mit 2x2- (1201 Mbit/s) oder 1x1-Standard (600 Mbit/s), die lediglich die obligatorische Anforderung von 80-MHz-Kanälen unterstützen.",
          "Nur ausgewählte Modelle, Funktionalität unterschiedlich je nach Herstellerdesign. Kontaktieren Sie den OEM oder Fachhändler, um weitere Einzelheiten zu erhalten. Weitere Informationen zum Thema Wi‑Fi finden Sie unter intel.com/wifi6disclaimers.",
          "¹⁰Die Thunderbolt™&nbsp;4 Technik bietet mit 40&nbsp;Gbit/s im Vergleich zu anderer PC-I/O-Anschlusstechnik wie eSATA*, USB* und IEEE&nbsp;1394* FireWire die schnellste, einfachste und zuverlässigste Lösung für Kabelverbindungen zu Dockingstations, Bildschirmen oder Datengeräten.",
          "¹¹Erfordert einen Router, der auf 802.11ax basiert und für OFDMA und mehrere Clients im Netzwerk mit AX-Unterstützung geeignet ist. In Umgebungen mit hoher Dichte lassen sich mit der OFDMA-Funktion, die von 802.11ax-Clients und -Access-Points unterstützt wird, Verbesserungen erzielen. 2 Gbit/s basiert auf Annahmen von ca. 70&nbsp;% der mit IEEE-802.11*-Spezifikationen theoretisch maximal erreichbaren Datenübertragungsraten für 802.11ax (160&nbsp;MHz, 2402&nbsp;Mbit/s).",
          "¹²Gemessen beim Öffnen eines Dokuments mit Hintergrundaktivität. Vergleich zwischen Intel® Core™ i7-8565U Prozessor (512 GB TLC-SSD) und Intel® Core™ i7-8565U Prozessor (32 GB + 512 GB Intel® Optane™ Speicher H10 mit Solid-State-Datenträger).",
          "¹³Intel® Thread Director ist bei Intel® Core™ Prozessoren der 12.&nbsp;Generation integriert und unterstützt Betriebssysteme dabei, Workloads auf intelligentere Weise an den richtigen Kern weiterzuleiten. Keine Maßnahmen durch den Benutzer erforderlich. Weitere Einzelheiten siehe intel.com.",
          "¹⁴Nicht verfügbar für bestimmte Intel® Core™ Prozessoren der 12. Generation. Die Performance-Hybridarchitektur kombiniert zwei neue Kern-Mikroarchitekturen – Performance-cores (P-cores) und Efficient-cores (E-cores) – auf einem einzigen Prozessor-Die. Bestimmte Intel® Core™ Prozessoren der 12. Generation (verschiedene Intel® Core™&nbsp;i5 Prozessoren der 12. Generation und darunter) unterstützen keine Performance-Hybridarchitektur, sondern nur P-cores.",
          "¹⁵Ermittelt mit Workload für den Start eines Spiels: Intel® Core™ i7-8750H Prozessor der 8.&nbsp;Generation (32 GB Intel® Optane™ Speichermodul + Intel® SSD mit 256 GB (PCIe*) + HDD mit 1 TB) im Vergleich zum Intel® Core™&nbsp;i7-8750H Prozessor der 8.&nbsp;Generation (Intel® SSD mit 256 GB (PCIe*) + HDD mit 1 TB). Workload für den Start eines Spiels&nbsp;– Von Intel kreierter Workload für die Messung der Zeit, die benötigt wird, um Total War*: WARHAMMER*&nbsp;II, Build:&nbsp;5577.0 zu starten und das Hauptmenü anzuzeigen, wobei die Intro-Videos deaktiviert sind (Start). Konfigurationen für Data-Drive-Acceleration-Funktion von Intel® Optane™ Speicher: Intel® Core™ i7-8750H Prozessor, PL1&nbsp;= 45&nbsp;W TDP, 6&nbsp;Kerne und 12&nbsp;Threads, Turbo bis zu 4&nbsp;GHz auf OEM-Vorseriensystem, Grafik: NVIDIA* GeForce*&nbsp;GTX 1070, Arbeitsspeicher: 2&nbsp;x 4 GB DDR4, Datenspeicher: Intel® SSD der Produktreihe 760p mit 256&nbsp;GB (PCIe*) + HDD mit 1 TB + 32 GB Intel® Optane™ Speicher, Betriebssystem: Windows*&nbsp;10 RS3 Build 1709, MCU 0x84; Intel® Core™ i7-8750H Prozessor, PL1&nbsp;= 45&nbsp;W TDP, 6&nbsp;Kerne und 12&nbsp;Threads, Turbo bis zu 4&nbsp;GHz auf OEM-Vorseriensystem, Grafik: NVIDIA*&nbsp;GeForce*&nbsp;GTX 1070, Arbeitsspeicher: 2&nbsp;x 4 GB DDR4, Datenspeicher: Intel® SSD der Produktreihe 760p mit 256&nbsp;GB (PCIe*)&nbsp;+ HDD mit 1 TB, Betriebssystem: Windows*&nbsp;10 RS3 Build 1709, MCU 0x84.",
          "¹⁶Ermittelt mit Workload für das Laden eines Spielelevels: Intel® Core™ i7-8750H Prozessor der 8. Generation (32 GB Intel® Optane™ Speichermodul) im Vergleich zum Intel® Core™ i7-8750H Prozessor der 8. Generation (nur HDD). Workload für das Laden eines Spielelevels&nbsp;– Von Intel kreierter Workload für die Messung der Zeit, die benötigt wird, bis in Total War*: WARHAMMER*&nbsp;II, Build: 5577.0 vom Hauptmenü aus ein Level vollständig geladen ist (Laden eines Spielelevels). Konfigurationen für Data-Drive-Acceleration-Funktion von Intel® Optane™ Speicher: Intel® Core™ i7-8750H Prozessor, PL1&nbsp;= 45&nbsp;W TDP, 6&nbsp;Kerne und 12&nbsp;Threads, Turbo bis zu 4&nbsp;GHz auf OEM-Vorseriensystem, Grafik: NVIDIA*&nbsp;GeForce*&nbsp;GTX 1070, Arbeitsspeicher: 2&nbsp;x 4 GB DDR4, Datenspeicher: Intel® SSD der Produktreihe 760p mit 256&nbsp;GB (PCIe*) + HDD mit 1 TB + 32 GB Intel® Optane™ Speicher, Betriebssystem: Windows*&nbsp;10 RS3 Build 1709, MCU 0x84; Intel® Core™ i7-8750H Prozessor, PL1&nbsp;= 45&nbsp;W TDP, 6&nbsp;Kerne und 12&nbsp;Threads, Turbo bis zu 4&nbsp;GHz auf OEM-Vorseriensystem, Grafik: NVIDIA* GeForce*&nbsp;GTX 1070, Arbeitsspeicher: 2&nbsp;x 4 GB DDR4, Datenspeicher: Intel® SSD der Produktreihe 760p mit 256&nbsp;GB (PCIe*)&nbsp;+ HDD mit 1 TB, Betriebssystem: Windows*&nbsp;10 RS3 Build 1709, MCU 0x84.",
          "¹⁷Bei Performance-cores. Die Leistung variiert je nach Verwendung, Konfiguration und anderen Faktoren. Ausführliche Informationen erhalten Sie unter www.intel.com/PerformanceIndex.",
          "¹⁸Eine Änderung der Taktfrequenz bzw. der Versorgungsspannung kann den Prozessor oder andere Systemkomponenten beschädigen oder deren Lebensdauer verkürzen sowie die Stabilität und Leistung des Systems beeinträchtigen. Wenn der Prozessor außerhalb der für ihn veröffentlichten Spezifikationen verwendet wird, besteht möglicherweise keine Produktgarantie. Weitere Einzelheiten erfahren Sie bei den Herstellern des Systems und der Komponenten.",
          "¹⁹Ermittelt mit der für die Intel vPro® Plattform konkurrenzlosen Kombination von Sicherheitsfunktionen oberhalb und unterhalb des Betriebssystems, Schutzmechanismen für Anwendungen und Daten und hochentwickeltem Schutz vor Bedrohungen für Unternehmen jeder Größe sowie Intels sicherheitsorientiertem Ansatz bei Produktdesign, Fertigung und Support. Alle auf der Intel vPro® Plattform basierenden Business-PCs wurden anhand strenger Spezifikationen, einschließlich besonderer hardwareunterstützter Sicherheitsmerkmale, validiert. Weitere Informationen finden Sie unter www.intel.com/PerformanceIndex (Plattformen). Kein Produkt und keine Komponente bietet absolute Sicherheit.",
          "²⁰Intel® Connectivity Performance Suite ist nur auf Windows*-basierten Systemen verfügbar. Intel Over The Air (OTA) WLAN-Tests in einem überlasteten Netzwerk im Vergleich zu einem Netzwerk ohne ICPS-Softwareanwendung zur Priorisierung des drahtlosen Datenverkehrs und intelligentem Accesspoint-Switching. Einzelheiten siehe www.intel.com/PerformanceIndex (Konnektivität). Die Ergebnisse können von Fall zu Fall abweichen.",
          "²¹Im Vergleich zur vorherigen Prozessorgeneration.",
          "²²Intel® Arc™ Grafik ist die erste Lösung auf dem Markt mit Unterstützung der AV1-Codierung (Stand 1. Quartal 2022). Einzelheiten siehe intel.com/performanceindex.",
          "²³Gemessen an den einzigartigen Funktionen der Intel® Evo™ Designs, die zudem verifiziert wurden, um die wichtigsten Schwellenwerte für die mobile Benutzererfahrung zu erfüllen. Tests vom Februar 2022.",
          "²⁴Die Intel® Unison™ Lösung ist derzeit nur für qualifizierte Intel® Evo™ Designs auf Windows*-basierten PCs verfügbar und lässt sich nur mit Android- oder iOS-basierten Telefonen kombinieren; alle Geräte müssen eine unterstützte Betriebssystemversion verwenden.",
          "²⁵Maximale Speichertaktraten beziehen sich auf Konfigurationen mit 1 DIMM/Kanal (1 DPC). Zusätzliche DIMM-Ladung auf einem beliebigen Kanal kann die maximale Speichergeschwindigkeit beeinflussen. Bis zu DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 und DDR5-5200 1Rx8, 1Rx16, 2Rx8 auf bestimmten Modellen. Maximale Arbeitsspeicherkapazität kann mit 2DPC-Konfigurationen erzielt werden.",
          "²⁶Im Vergleich mit Standard-WLAN. Ausführliche Informationen siehe intel.com/performance-wireless. Die Ergebnisse können von Fall zu Fall abweichen.",
          "²⁷Die Performance-Hybridarchitektur kombiniert zwei Kern-Mikroarchitekturen, Performance-cores (P-cores) und Efficient-cores (E-cores), auf einem einzigen Prozessor-Die, der erstmals bei Intel® Core™ Prozessoren der 12. Generation eingeführt wurde. Einige Intel® Core™ Prozessoren der 12. Generation und neuere Prozessoren haben keine Performance-Hybridarchitektur bzw. nur P-cores und verfügen über dieselbe Cachegröße wie die Vorgängergeneration; Einzelheiten zu Prozessormodellen siehe ark.intel.com.",
          "²⁸Der in die Hardware integrierte Intel® Thread Director ist nur in Konfigurationen mit Intel® Core™ Prozessoren der 12. Generation oder neuer verfügbar, die die Performance-Hybridarchitektur nutzen; die Aktivierung des Betriebssystems ist erforderlich. Die verfügbaren Funktionsmerkmale variieren je nach Betriebssystem.",
          "²⁹Der in die Hardware integrierte Intel® Thread Director ist nur in Konfigurationen mit Intel® Core™ Prozessoren der 12. und 13. Generation verfügbar, die die Performance-Hybridarchitektur nutzen; die Aktivierung des Betriebssystems ist erforderlich. Die verfügbaren Funktionsmerkmale variieren je nach Betriebssystem.",
          "³⁰Gemessen anhand der Leistung der Referenzvalidierungsplattform des Intel® Core™ Ultra Mobilprozessors (Serie 2 – Codename: Lunar Lake) im Vergleich zum Intel® Core™ Ultra Mobilprozessor (Serie 1 – Codename: Meteor Lake) mit 3DMark Time Spy. Einzelheiten siehe intel.com/performanceindex. Die Ergebnisse können von Fall zu Fall abweichen.",
          "³¹Hintergrundweichzeichnung und Gesichtsverfolgung sind nur auf Windows*-basierten Systemen verfügbar.",
          "³²Wi-Fi 7-Produkte können mit neuen Multi-Resource Unit Puncturing-Funktionen auf 320-MHz-Kanäle im 6-GHz-Band und neue 160-MHz-Kanalkombinationen im 5-GHz-Band zugreifen.",
          "³³Basierend auf den Spezifikationen des IEEE-Wireless-Standards und den maximalen theoretischen Datenraten für 2-Stream-Geräte.",
          "³⁴Von Intel durchgeführte Engineering-Simulationen überlasteter Netzwerkumgebungen zeigen, dass mit den neuen Multi-Link-Betriebsfunktionen von Wi&#8209;Fi&nbsp;7 eine erhebliche Reduzierung der Latenz möglich ist.",
          "³⁵Vom Standard-Shutdown-Level des OEMs erreichter Mindestladezustand. Einzelne Ergebnisse können davon abweichen. Einzelheiten siehe intel.com/performance-evo.",
          "³⁶Das 6-GHz-Wi-Fi-Band ist nicht in allen Ländern/Regionen verfügbar.",
          "³⁷Nur Windows*",
          "³⁸Vom Standard-Shutdown-Level des OEMs erreichter Ladezustand. Ausführlichere Informationen über die Leistungsmerkmale und Benchmarkergebnisse finden Sie unter intel.com/evo.",
          "³⁹Bezieht sich auf Intel® Core™ Ultra Mobilprozessoren (Serie 2), basierend auf den besonderen hardwarebasierten Sicherheitsfunktionen, einer konkurrenzlosen Kombination von Sicherheitsfunktionen oberhalb und unterhalb des Betriebssystems sowie beeindruckender Leistung selbst bei geringerem Energieverbrauch im Vergleich zur vorherigen Generation (Stand September 2024). Einzelheiten siehe intel.com/performanceindex. Die Ergebnisse können von Fall zu Fall abweichen.",
          "⁴⁰Die abgebildeten Softwareanwendungen dienen nur zur Veranschaulichung. KI-Funktionen können den Kauf zusätzlicher Software, das Abschließen eines Abonnements oder die Aktivierung durch einen Software- oder Plattformanbieter erfordern oder bestimmte Konfigurations- oder Kompatibilitätsanforderungen haben. Einzelheiten unter www.intel.com/PerformanceIndex. Die Ergebnisse können von Fall zu Fall abweichen. © Intel Corporation.",
          "⁴¹Die angezeigten Bilder wurden möglicherweise abgeändert oder simuliert. KI-Funktionen können den Kauf zusätzlicher Software, das Abschließen eines Abonnements oder die Aktivierung durch einen Software- oder Plattformanbieter erfordern oder bestimmte Konfigurations- oder Kompatibilitätsanforderungen haben. Einzelheiten auf www.intel.com/AIPC.",
          "⁴²Schrittweise Einführung als Vorschau im Rahmen des neuesten Updates für Windows* 11 in ausgewählten Märkten weltweit. Der Zeitpunkt der Verfügbarkeit variiert je nach Gerät und Markt. Weitere Informationen auf: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Die Intel® Unison™ Lösung ist derzeit für qualifizierte Designs verfügbar. Weitere Details finden Sie unter www.intel.com/PerformanceIndex.",
          "⁴⁴FPS-Upscaling von Intel® X<sup>e</sup>SS im Vergleich zu Native FPS, gemessen mit Dying Light 2 Stay Human auf Intel® Core™ Ultra 7 Prozessor 165H. Weitere Details finden Sie unter www.intel.com/PerformanceIndex. Die Ergebnisse können von Fall zu Fall abweichen.",
          "⁴⁵Gemessen an der geringeren SoC-Leistungsaufnahme der Referenz-Validierungsplattform für den Intel® Core™ Ultra Mobilprozessor (Serie 2 – Codename: Lunar Lake) im Vergleich zur Referenz-Validierungsplattform für den Intel® Core™ Ultra Mobilprozessor (Serie 1 – Codename: Meteor Lake) auf YouTube* (4K, 30 FPS, AV1). Einzelheiten siehe intel.com/performanceindex. Die Ergebnisse können von Fall zu Fall abweichen.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel übernimmt keine Garantie für inkorrekte Preisangaben.",
          "*Andere Marken oder Produktnamen sind Eigentum der jeweiligen Inhaber.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES UND DAMIT VERBUNDENE DESIGNMARKEN UND ZUSAMMENGESETZTE MARKEN SIND MARKEN VON INFLEXION STUDIOS INC. ALLE RECHTE VORBEHALTEN.",
          "Intel Technik kann passende Hardware, Software oder die Aktivierung von Diensten erfordern.",
          "Ergebnisse und Daten von NERO* werden als Service bereitgestellt und dienen ausschließlich informatorischen Zwecken. Die Intel Corporation übernimmt (als Beispiel) keine Verantwortung für die Genauigkeit der NERO*-App, für die damit erzielten Leistungswerte und Ergebnisse oder für mögliche Abweichungen von anderen Versionen der App, die von www.nero.com oder anderen Websites Dritter heruntergeladen werden können. Die Ergebnisse des NERO*-PC-Benchmarks werden mit spezifischen Computersystemen, Komponenten, Softwarekonfigurationen, Abläufen und Funktionen ermittelt. Jede Veränderung bei einem dieser Faktoren kann abweichende Ergebnisse zur Folge haben. Als Unterstützung für eine umfassende Bewertung Ihrer vorgesehenen Anschaffung, auch im Hinblick auf die Leistung des betreffenden Produkts in Verbindung mit anderen Produkten, sollten Sie noch andere Informationen und Leistungstests heranziehen.",
          "Alle Versionen der Intel vPro® Plattform erfordern einen qualifizierten Intel® Core™ Prozessor, ein unterstütztes Betriebssystem, Intel LAN- und/oder WLAN-Chips, Firmware-Erweiterungen und andere Hardware und Software, die erforderlich sind, um die Verwaltbarkeit, die Sicherheitsfunktionen, die Systemleistung und die Stabilität bereitzustellen, die die Plattform definieren.",
          "Die Leistung variiert je nach Verwendung, Konfiguration und anderen Faktoren. Ausführliche Informationen erhalten Sie unter www.intel.com/PerformanceIndex.",
          "Intel hat keinen Einfluss auf und keine Aufsicht über die Daten Dritter. Sie sollten andere Quellen heranziehen, um die Genauigkeit zu beurteilen.",
          "© Intel Corporation. Intel, das Intel Logo und andere Intel Markenbezeichnungen sind Marken der Intel Corporation oder ihrer Tochtergesellschaften. *Andere Marken oder Produktnamen sind Eigentum der jeweiligen Inhaber.",
          "Kein Produkt und keine Komponente bieten absolute Sicherheit.",
          "Ihre Kosten und Ergebnisse können variieren.",
          "Intel, das Intel Logo, Intel Core, Intel Optane, Intel Iris, Thunderbolt und das Thunderbolt-Logo sind Marken der Intel Corporation oder ihrer Tochtergesellschaften. Andere Marken oder Produktnamen sind Eigentum der jeweiligen Inhaber.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Homepage",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Zum Vergleichen scannen",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Im Trend",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/evo/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/video_link.mp4",
      "iconURL": "",
      "videoName": "video_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ Performance to Power Their World (16_9).mp4",
      "iconURL": "",
      "videoName": "Video_ Performance to Power Their World (16_9)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "WILLKOMMEN",
    "tr_welcomePagetrSubtitle": "Nutze dein Smartphone als Fernsteuerung für diesen PC.",
    "tr_welcomePagembSubtitle": "Willkommen, verwende dein Smartphone, um mehr über die Spezifikationen dieses PCs zu erfahren.",
    "tr_welcomePageBtnText": "Gleich loslegen!",
    "tr_welcomePageText": "Entdecke und vergleiche die Details dieses PCs auf deinem Smartphone.",
    "tr_scrollText": "Steuere das Gerät, das sich direkt vor dir befindet.",
    "tr_toolTipTextc1": "Verwende diese Tasten, um auf dem Gerät, das sich direkt vor dir befindet, zu verschiedenen Seiten navigieren.",
    "tr_toolTipTextc2": "Durch Antippen der Karten unten gelangst du zu einer Detailübersicht zu jeder der Spezifikationen dieses Geräts.",
    "tr_toolTipTextc3": "Verwende diese Tasten, um auf dem Bildschirm, der sich direkt vor dir befindet, nach oben oder unten zu gehen.",
    "tr_toolTipTextc4": "Schalte den Fernbedienungsmodus aus, um die Gerätespezifikationen auf deinem Smartphone anzuzeigen.",
    "tr_toolTipTextmb1": "Tippe die Karten an, um Seiten mit detaillierten Inhalten anzuzeigen.",
    "tr_toolTipTextmb2": "Tippe, um eine Zusammenfassung aller Gerätespezifikationen anzuzeigen.",
    "tr_toolTipTextmb3": "Tippe auf den Button, um den QR-Code zu scannen und bis zu 4 Geräte zu vergleichen.",
    "tr_nextButton": "Weiter",
    "tr_prevButton": "Zurück",
    "tr_gotItButton": "VERSTANDEN",
    "tr_skipButton": "Überspringen",
    "tr_placeholderText": "*Platzhalter",
    "tr_processorHeading": "Prozessor",
    "tr_processorSubheading": "Intel® Core™ i7 Prozessor der 10.&nbsp;Generation",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "[XX] GB Intel® Optane™ Speicher + SSD mit [YY] GB",
    "tr_coachmarkProcessor": "XX Prozessor",
    "tr_coachmarkGraphics": "[XX] Grafik",
    "tr_coachmarkMemory": "Spezifikation",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999,95 $",
    "tr_compare": "Vergleich",
    "tr_viewallspecs": "ALLE SPEZIFIKATIONEN ANZEIGEN",
    "tr_operatingSystem": "BETRIEBSSYSTEM",
    "tr_AmdProcessorNonIntel": "AMD* Prozessor",
    "tr_QualcommProcessorNonIntel": "Qualcomm* Prozessor",
    "tr_GBText": "GB",
    "tr_remoteText": "Spezifikationen, die auf dem Laptop-Bildschirm angezeigt werden",
    "tr_scanErrorText": "Du kannst nur jeweils bis zu 3 Geräte gleichzeitig scannen. Lösche bitte eines oder mehrere Geräte.",
    "tr_scanErrorText2": "Du hast dieses Gerät bereits gescannt. Scanne bitte ein anderes.",
    "tr_scanMsg": "Scannen, um Gerät hinzuzufügen",
    "tr_backToScan": "Zurück zum Vergleich",
    "tr_scanToCompare": "Für Vergleich scannen",
    "tr_compareDevice": "Geräte vergleichen",
    "tr_processorTitle": "PROZESSOR",
    "tr_graphicTitle": "GRAFIK",
    "tr_storageTitle": "MASSEN&shy;SPEICHER",
    "tr_displayTitle": "DISPLAY",
    "tr_batteryTitle": "AKKU",
    "tr_memoryTitle": "ARBEITS&shy;SPEICHER",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "KONNEKTIVITÄT",
    "tr_priceTitle": "PREIS",
    "tr_operatingSystemTitle": "BETRIEBSSYSTEM",
    "tr_batteryHoursText": "[XX] Stunden",
    "tr_hrsText": "Stunden",
    "tr_touchscreeenText": "[screenDiagonal]-Zoll-Touchscreen",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, Touchscreen",
    "tr_FHDTouchScreenText": "FHD, Touchscreen",
    "tr_4KTouchscreenText": "4K, Touchscreen",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Speichern",
    "tr_device_comparison": "Dokument für Gerätevergleich [dd].[mm].[yyyy]",
    "tr_Save_As_JPEG": "Als JPEG speichern",
    "tr_Save_As_PDF": "Als PDF speichern",
    "tr_Downloading_PDF": "PDF wird heruntergeladen",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Offen",
    "tr_logo": "Logo",
    "tr_laptop_name": "Laptop-Bezeichnung",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Intel® Optane™ Speicher",
    "tr_inchUnit": "Zoll",
    "tr_wifi1": "Wi‑Fi&nbsp;1",
    "tr_wifi2": "Wi‑Fi&nbsp;2",
    "tr_wifi3": "Wi‑Fi&nbsp;3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi&#8209;Fi&nbsp;6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "INTEL® OPTANE™ SPEICHER",
    "tr_Thunderbolt3": "Thunderbolt™&nbsp;3 Technik",
    "tr_Thunderbolt4": "Thunderbolt™&nbsp;4 Technik",
    "tr_processorGraphicsCaps": "PROZESSORGRAFIK",
    "tr_processorGraphicsSmall": "Prozessorgrafik",
    "tr_graphicsCardCaps": "DEDIZIERTE GRAFIK",
    "tr_graphicsCardSmall": "Dedizierte Grafik",
    "tr_processorTitleCamel": "Prozessor",
    "tr_graphicTitleCamel": "Grafik",
    "tr_storageTitleCamel": "Massenspeicher",
    "tr_displayTitleCamel": "Display",
    "tr_batteryTitleCamel": "Akku",
    "tr_memoryTitleCamel": "Arbeitsspeicher",
    "tr_connectivityTitleCamel": "Konnektivität",
    "tr_priceTitleCamel": "Preis",
    "tr_operatingSystemTitleCamel": "Betriebssystem",
    "tr_viewallspecsCamel": "Alle Spezifikationen anzeigen",
    "tr_displayText": "[screenDiagonal] Zoll",
    "tr_displayTextResolution": "[screenDiagonal] Zoll, [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal]-Zoll-[screenResolutionType]-Touchscreen",
    "tr_OS": "BETRIEBSSYSTEM",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "PC-Daten",
    "tr_explorePC_text": "Erfahre alles über diesen PC",
    "tr_mtlPC_text": "Was bietet die Technik, die alles kann?",
    "tr_optaneMemory": "Intel® Optane™ Speicher",
    "tr_displayText_Display": "[screenDiagonal]-ZOLL-BILDSCHIRM",
    "tr_displayTextResolution_Display": "[screenDiagonal]-Zoll-[screenResolutionType]-BILDSCHIRM",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal]-ZOLL-[screenResolutionType]-TOUCHSCREEN",
    "tr_displayTextTouchscreeenText": "[screenDiagonal]-ZOLL-TOUCHSCREEN",
    "tr_learnMoreLabel": "Weitere Informationen",
    "tr_batteryHoursTextUpperCase": "[XX] STUNDEN",
    "tr_processorGraphicsIrisXe": "INTEL® CORE™ [cpu_fam] PROZESSOR DER&nbsp;11.&nbsp;GENERATION&nbsp;UND INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;GRAFIK",
    "tr_processorGraphicsIrisXeMax": "INTEL® CORE™ [cpu_fam] PROZESSOR DER&nbsp;11.&nbsp;GENERATION&nbsp;UND INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX&nbsp;GRAFIK",
    "tr_Thunderbolt3UpperCase": "THUNDERBOLT™ 3 TECHNIK",
    "tr_Thunderbolt4UpperCase": "THUNDERBOLT™ 4 TECHNIK",
    "tr_processorWithArcGraphics": "INTEL® CORE™ [cpu_fam] PROZESSOR DER 12. GENERATION UND INTEL® ARC™ GRAFIK",
    "tr_processorGraphicsIrisXe12Gen": "INTEL® CORE™ [cpu_fam] PROZESSOR DER 12. GENERATION UND INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAFIK",
    "tr_processorGraphicsIrisXeMax12Gen": "INTEL® CORE™ [cpu_fam] PROZESSOR DER 12. GENERATION UND INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAFIK",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "INTEL® CORE™ [cpu_fam] PROZESSOR DER 13. GENERATION UND INTEL® ARC™ GRAFIK",
    "tr_processorGraphicsIrisXe13Gen": "INTEL® CORE™ [cpu_fam] PROZESSOR DER 13. GENERATION UND INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAFIK",
    "tr_processorGraphicsIrisXeMax13Gen": "INTEL® CORE™ [cpu_fam] PROZESSOR DER 13. GENERATION UND INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAFIK",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "INTEL® ARC™ A350M GRAFIK",
      "tr_A370M": "INTEL® ARC™ A370M GRAFIK",
      "tr_A730M": "INTEL® ARC™ A730M GRAFIK",
      "tr_A770M": "INTEL® ARC™ A770M GRAFIK",
      "tr_A310": "INTEL® ARC™ A310 GRAFIK",
      "tr_A380": "INTEL® ARC™ A380 GRAFIK",
      "tr_A580": "INTEL® ARC™ A580 GRAFIK",
      "tr_A750": "INTEL® ARC™ A750 GRAFIK",
      "tr_A770": "INTEL® ARC™ A770 GRAFIK"
    },
    "tr_memoryValue": "[XX] GB RAM",
    "tr_memoryWithOptaneDesc": "[Value1] GB Arbeits&shy;speicher = [Value2]&nbsp;GB Intel®&nbsp;Optane™ Speicher + [Value3]&nbsp;GB&nbsp;RAM",
    "tr_textGBGB": "SSD mit [convertedSSD]&nbsp;GB + HDD mit [convertedHDD]&nbsp;GB",
    "tr_textGBTB": "SSD mit [convertedSSD] GB + HDD mit [convertedHDD] TB",
    "tr_textTBGB": "SSD mit [convertedSSD] TB + HDD mit [convertedHDD] GB",
    "tr_textTBTB": "SSD mit [convertedSSD] TB + HDD mit [convertedHDD] TB",
    "tr_textGBSSD": "SSD mit [convertedSSD]&nbsp;GB",
    "tr_textTBSSD": "SSD mit [convertedSSD]&nbsp;TB",
    "tr_textGBHDD": "HDD mit [convertedHDD]&nbsp;GB",
    "tr_textTBHDD": "HDD mit [convertedHDD]&nbsp;TB",
    "tr_textStorageWithOptaneGB": "[XX] GB Intel® Optane™ Speicher + SSD mit [convertedSSD]&nbsp;GB",
    "tr_textStorageWithOptaneTB": "[XX] GB Intel® Optane™ Speicher + SSD mit [convertedSSD]&nbsp;TB",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home im S&#8209;Modus",
      "tr_OS21": "Windows* 10 Pro im S&#8209;Modus",
      "tr_OS22": "Windows* 10 Enterprise im S&#8209;Modus",
      "tr_OS23": "Windows* 11 Home im S&#8209;Modus",
      "tr_OS24": "Windows* 11 Pro im S&#8209;Modus",
      "tr_OS25": "Windows* 11 Enterprise im S&#8209;Modus"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Intel® Arc™ A310 Grafik",
      "tr_GR387": "Intel® Arc™ A350M Grafik",
      "tr_GR388": "Intel® Arc™ A370M Grafik",
      "tr_GR389": "Intel® Arc™ A380 Grafik",
      "tr_GR390": "Intel® Arc™ A530M Grafik",
      "tr_GR391": "Intel® Arc™ A550M Grafik",
      "tr_GR392": "Intel® Arc™ A570M Grafik",
      "tr_GR393": "Intel® Arc™ A580 Grafik",
      "tr_GR394": "Intel® Arc™ A730M Grafik",
      "tr_GR395": "Intel® Arc™ A750 Grafik",
      "tr_GR396": "Intel® Arc™ A770 Grafik",
      "tr_GR397": "Intel® Arc™ A770M Grafik",
      "tr_GR398": "Intel® Arc™ Grafik",
      "tr_GR399": "Intel® Arc™ Pro A30M Grafik",
      "tr_GR400": "Intel® Arc™ Pro A40/A50 Grafik",
      "tr_GR401": "Intel® Arc™ Pro A60 Grafik",
      "tr_GR402": "Intel® Arc™ Pro A60M Grafik",
      "tr_GR403": "Intel® Grafik",
      "tr_GR404": "Intel® HD-Grafik 2000",
      "tr_GR405": "Intel® HD-Grafik 2500",
      "tr_GR406": "Intel® HD-Grafik 3000",
      "tr_GR407": "Intel® HD-Grafik 400",
      "tr_GR408": "Intel® HD-Grafik 4000",
      "tr_GR409": "Intel® HD-Grafik 405",
      "tr_GR410": "Intel® HD-Grafik 4200",
      "tr_GR411": "Intel® HD-Grafik 4400",
      "tr_GR412": "Intel® HD-Grafik 4600",
      "tr_GR413": "Intel® HD-Grafik 500",
      "tr_GR414": "Intel® HD-Grafik 5000",
      "tr_GR415": "Intel® HD-Grafik 505",
      "tr_GR416": "Intel® HD-Grafik 510",
      "tr_GR417": "Intel® HD-Grafik 515",
      "tr_GR418": "Intel® HD-Grafik 520",
      "tr_GR419": "Intel® HD-Grafik 530",
      "tr_GR420": "Intel® HD-Grafik 5300",
      "tr_GR421": "Intel® HD-Grafik 5500",
      "tr_GR422": "Intel® HD-Grafik 5600",
      "tr_GR423": "Intel® HD-Grafik 6000",
      "tr_GR424": "Intel® HD-Grafik 610",
      "tr_GR425": "Intel® HD-Grafik 615",
      "tr_GR426": "Intel® HD-Grafik 620",
      "tr_GR427": "Intel® HD-Grafik 630",
      "tr_GR428": "Intel® HD-Grafik",
      "tr_GR429": "Intel® Iris® Grafik 5100",
      "tr_GR430": "Intel® Iris® Grafik 540",
      "tr_GR431": "Intel® Iris® Grafik 550",
      "tr_GR432": "Intel® Iris® Grafik 6100",
      "tr_GR433": "Intel® Iris® Plus Grafik 640",
      "tr_GR434": "Intel® Iris® Plus Grafik 645",
      "tr_GR435": "Intel® Iris® Plus Grafik 650",
      "tr_GR436": "Intel® Iris® Plus Grafik 655",
      "tr_GR437": "Intel® Iris® Plus Grafik",
      "tr_GR438": "Intel® Iris® Pro Grafik 5200",
      "tr_GR439": "Intel® Iris® Pro Grafik 580",
      "tr_GR440": "Intel® Iris® Pro Grafik 6200",
      "tr_GR441": "Intel® Iris® X<sup>e</sup> Grafik",
      "tr_GR442": "Intel® Iris® X<sup>e</sup> MAX Grafik A220M",
      "tr_GR443": "Intel® Iris® X<sup>e</sup> MAX Grafik",
      "tr_GR444": "Integrierte Intel® Grafik",
      "tr_GR445": "Intel® UHD-Grafik 600",
      "tr_GR446": "Intel® UHD-Grafik 605",
      "tr_GR447": "Intel® UHD-Grafik 610",
      "tr_GR448": "Intel® UHD-Grafik 615",
      "tr_GR449": "Intel® UHD-Grafik 617",
      "tr_GR450": "Intel® UHD-Grafik 620",
      "tr_GR451": "Intel® UHD-Grafik 630",
      "tr_GR452": "Intel® UHD-Grafik 710",
      "tr_GR453": "Intel® UHD-Grafik 730",
      "tr_GR454": "Intel® UHD-Grafik 770",
      "tr_GR455": "Intel® UHD-Grafik",
      "tr_GR456": "Intel® UHD-Grafik",
      "tr_GR457": "Intel® UHD-Grafik",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 mit Max-Q Design",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti mit Max-Q Design",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 mit Max-Q Design",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 mit Max-Q Design",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 mit Max-Q Design",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 mit Max-Q Design",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti mit Max-Q Design",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti mit Max-Q Design",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 mit Max-Q Design",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 mit Max-Q Design",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super mit Max-Q Design",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 mit Max-Q Design",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super mit Max-Q Design",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 mit Max-Q Design",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 mit Max-Q Design",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 mit Max-Q Design",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 mit Max-Q Design",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 mit Max-Q Design",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 mit Max-Q Design",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 mit Max-Q Design",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 mit Max-Q Design",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 mit Max-Q Design",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 mit Max-Q Design",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "Intel® Arc™ Grafik",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Jetzt kaufen",
    "tr_textTryagain": "Erneut versuchen",
    "tr_textBuynowErr": "Aufgrund einer Verbindungsunterbrechung ist die Option „Jetzt kaufen“ nicht verfügbar.",
    "tr_chatbotPlaceholder_text": "Stell mir eine Frage zu diesem Gerät",
    "tr_deviceSpecs_text": "Gerätespezifikationen"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i3 Prozessor mit&nbsp;Intel®&nbsp;Hybrid&#8209;Technik"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i5 Prozessor mit&nbsp;Intel®&nbsp;Hybrid&#8209;Technik"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Echte Power für echte Gamer: Intel® Technik",
                "tr_subtitle": "Erwecke Marvel’s Avengers* mit In-Game-Optimierungen, die Intel® Technik nutzen, zum Leben.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Erlebe die Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Wo die weltbesten Gamer gegeneinander antreten",
                "tr_subtitle": "Die am längsten bestehende Eventserie in der Gaming-Geschichte, das Intel® Extreme Masters, definiert den Standard für die E&#8209;Sport&#8209;Welt.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Wie die Profis spielen",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Champions entfalten sich mit Intel.",
                "tr_subtitle": "Nach dem Gewinn der 2021 Rainbow Six Siege* North American League Championship sprechen die Soniqs über ihre Geschichte als Gamer, die Zukunft von E-Sports und ihre Partnerschaft mit Intel.",
                "tr_pretitle": "Wir stellen vor – die Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "VR-fähig",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Die Virtuelle Realität erwacht zum Leben",
                "tr_subtitle": "Am eindrucksvollsten, realistischsten und intensivsten erlebst du die virtuelle Realität mit einem PC. Mit den neuesten Intel® Core™ Prozessoren kannst du sein, wer du willst, und gehen, wohin du willst. Das ist Gaming der nächsten Dimension!",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Spiele auf deinem Windows* PC, Handy und Tablet mit Xbox* Game Pass Ultimate und einem kompatiblen Controller mehr als 100 hochwertige Konsolenspiele wie Sea of Thieves* und Psychonauts* 2.",
                "titleArray": [
                  {
                    "tr_title": "Gemeinsam geräteübergreifend spielen",
                    "tr_subtitle": "Erlebe die Xbox* mit einer Community aus Millionen von Gamern, die bereitstehen, mit dir zusammen zu spielen. Verbinde dich und spiele mit anderen Titel aus einer gemeinsamen Bibliothek, egal ob sie auf der anderen Seite der Welt sind oder direkt neben dir sitzen."
                  },
                  {
                    "tr_title": "Windows* PC-Gaming",
                    "tr_subtitle": "Spiele einen Katalog von Konsolenspielen aus der Cloud mit der Xbox* App für Windows* und einem kompatiblen Controller."
                  },
                  {
                    "tr_title": "Sofort spielen",
                    "tr_subtitle": "Genieße großartige Xbox* Spiele an mehr Orten als je zuvor. Spiele 100+ Games auf deinem PC, Handy und Tablet, ganz gleich, ob du zu Hause bist während dein Fernseher benutzt wird, in einem Café oder irgendwo sonst mit einer Highspeed-Internetverbindung."
                  },
                  {
                    "tr_title": "Spaß ohne Controller",
                    "tr_subtitle": "Xbox* Touch-Control-Gaming bietet neue Möglichkeiten, sofort zu spielen, die dir ein vertrautes Erlebnis bieten und ein Maß an Gameplay unterstützen, das du von einem physischen Controller gewohnt bist."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Mehr als 100 KI-Möglichkeiten",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Mehr steuern, weniger Hand anlegen",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Bleibe in jeder Umgebung im Bild",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativanwendungen",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Beleuchtungskorrektur wie vom Profi",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Füge erstaunliche Effekte ganz einfach hinzu",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Mische Songs mit der Leistung von KI neu ab",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivität",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Verbessere deine Produktivität und den Datenschutz",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Verbringe mehr Zeit ohne kabelgebundenen Stromanschluss",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Deine Welt. Auf deinen Geräten.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Unterhaltung",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Reibungsloses Streaming, auch kabellos",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Schnelles, immersives Gaming",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Mehr als 100 KI-Möglichkeiten",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Mach deine Top-Songs zu eigenen Hits",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Sieh deine Vision in Echtzeit",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativanwendungen",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Der schnellste Weg zur endgültigen Schnittfassung",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Jedes Foto wird zur perfekten Aufnahme",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivität",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Verbessere deine Produktivität und den Datenschutz",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Noch effizienter. Mehr Freiheit.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Deine Welt. Auf deinen Geräten.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Unterhaltung",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Reibungsloses HD-Streaming, auch kabellos",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Immersives Gaming an mehr Orten",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mehr Kreativität",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Fotobearbeitung leicht gemacht",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Umgehende Erstellung von Fotos, die einen schönen Rahmen verdienen",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Du tippst es. Die KI macht es.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mehr Leistung",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Der Cheatcode für flüssiges Gaming",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Verbringe mehr Zeit ohne kabelgebundenen Stromanschluss",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-immersiv",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mehr Produkivität",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Dein täglicher KI-Begleiter",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Perfekte Videokonferenzen",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Deine Welt. Auf deinen Geräten.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mehr Sicherheit",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Integrierte Schutzmechanismen für&nbsp;mehr&nbsp;Datensicherheit",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mehr Kreativität",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Fotobearbeitung leicht gemacht",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "KI: Die Abkürzung zum endgültigen Produkt.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Texte deiner Lieblingslieder im Handumdrehen transkribieren",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Mit KI viele Stunden Bearbeitungszeit sparen",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mehr Leistung",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Der Cheatcode für flüssiges Gaming",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Verbringe mehr Zeit ohne kabelgebundenen Stromanschluss",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-immersiv",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mehr Produktivität",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Dein täglicher KI-Begleiter",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Perfekte Videokonferenzen",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Deine Welt. Auf deinen Geräten.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mehr Sicherheit",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Integrierte Schutzmechanismen für&nbsp;mehr&nbsp;Datensicherheit",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Dein gesamtes Entertainment. Ein&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Für einen PC, der gleichzeitig als Unterhaltungscenter fungiert, benötigst du ausreichend Speicherplatz für deine Lieblingssongs, Videos und Spiele sowie die richtige Kombination aus Prozessor und Bildschirm, um ein brillantes Multimedia-Erlebnis zu genießen. Dieser PC bietet alle drei Komponenten.",
                "titleArray": [
                  {
                    "tr_title": "Bereit für das visuelle Erlebnis einer neuen Generation",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Erstelle deine eigene digitale Bibliothek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ein Fenster für dein Entertainment",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reibungsloses Gaming und Streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Erstelle deine eigene digitale Bibliothek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ein Fenster für dein Entertainment",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Höhere Produktivität. Überall.",
              "tr_tileTitle1": "Schneller mehr erledigen.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dieser PC bietet die perfekte Balance aus Leistung und Mobilität. Er ist mit einem für flache und leichte Designs konzipierten Prozessor ausgestattet und verfügt zudem über Wi&#8209;Fi&#8209;Technik, die Verbindungen stabil hält, sowie über den richtigen Akku, damit du auch ohne Netzanschluss produktiv bleibst.",
                "tr_subtitle1": "Dieser Computer bietet perfekt aufeinander abgestimmte Leistung und Funktion, um in kürzerer Zeit mehr zu erreichen. Die Kombination von robuster, durch großen Arbeitsspeicher verstärkter Prozessorleistung und blitzschnellem WLAN erleichtert die alltäglichen Aufgaben.",
                "tr_subtitle2": "Dieser PC bietet die perfekte Balance aus Leistung und Mobilität. Er ist mit einem für flache und leichte Designs konzipierten Prozessor ausgestattet und verfügt zudem über Wi‑Fi-Technik, die Verbindungen stabil hält, sowie über den für Multitasking-Aufgaben richtigen Arbeitsspeicher.",
                "titleArray": [
                  {
                    "tr_title": "Bereit für mehr",
                    "tr_title1": "Intelligente Leistung, die den Unterschied bewirkt",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Führende Vernetzungstechnik",
                    "tr_title1": "Alle wichtigen Verbindungen",
                    "tr_title2": "Ein bedeutender Fortschritt für das WLAN",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Ohne lästige Kabel länger mobil",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Mühelos mehrere Programme gleichzeitig nutzen",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreative Freiheit",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Die Erstellung von Inhalten ist eine der anspruchsvollsten Aufgaben für deinen PC. Mit diesen Komponenten kannst du deine kreativen Prozesse hervorragend optimieren.",
                "titleArray": [
                  {
                    "tr_title": "Smarter kreativ sein",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Erwecke deine Bilder zum Leben",
                    "tr_title1": "Let’s Create",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Arbeitsspeicher für Multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "PC-Einblicke",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dies sind die drei Hauptkomponenten, die du bei der Auswahl eines neuen PCs im Auge behalten solltest. Wenn du eine Entscheidung dazu getroffen hast, wie du deinen neuen PC verwenden möchtest, ist bereits viel dazu entschieden, wie viel du von jeder Schlüsselkomponente benötigst. Die Komponenten sind:",
                "titleArray": [
                  {
                    "tr_title": "Power für Gaming und Streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Massenspeicher",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Arbeitsspeicher",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Dein gesamtes Entertainment. Ein&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Für einen PC, der gleichzeitig als Unterhaltungscenter fungiert, benötigst du ausreichend Speicherplatz für deine Lieblingssongs, Videos und Spiele sowie die richtige Kombination aus Prozessor und Bildschirm, um ein brillantes Multimedia-Erlebnis zu genießen. Dieser PC bietet alle drei Komponenten.",
                "titleArray": [
                  {
                    "tr_title": "Bereit für das visuelle Erlebnis einer neuen Generation",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Erstelle deine eigene digitale Bibliothek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ein Fenster für dein Entertainment",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reibungsloses Gaming und Streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Erstelle deine eigene digitale Bibliothek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ein Fenster für dein Entertainment",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Arbeitsspeicher",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Display",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Schnelles Multitasking",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SCHNELLES MULTITASKING",
                "tr_subtitle": "Mehr machen, ohne langsamer zu werden",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Multitasking geeignet:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor für mehrere Programme gleichzeitig."
                  },
                  {
                    "tr_title": "Schnelles, starkes und zuverlässiges Wi‑Fi."
                  },
                  {
                    "tr_title": "Arbeitsspeicher, der nahtlos zwischen Anwendungen wechselt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Video-Chats",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEO-CHATS",
                "tr_subtitle": "Kristallklare Verbindungen",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Video-Chats geeignet:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor, der gleichzeitig Bilder aufnimmt und teilt."
                  },
                  {
                    "tr_title": "Schnelles, starkes und zuverlässiges Wi‑Fi."
                  },
                  {
                    "tr_title": "Eine Webcam, die dir hilft, kristallklar zu kommunizieren."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-fähig",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Die Virtuelle Realität erwacht zum Leben",
                "tr_subtitle": "Was ist nötig, um eine vollständig virtuelle Welt zu erschaffen? Auf einem PC müssen Prozessor, Grafikkarte und Speicher miteinander koordiniert werden.",
                "titleArray": [
                  {
                    "tr_title": "Starke Power für virtuelle Welten",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Die Realität liegt im Detail",
                    "tr_title1": "Genieße das volle Erlebnis",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Videobearbeitung",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOBEARBEITUNG",
                "tr_subtitle": "Erstelle magische Filme",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Videobearbeitung geeignet:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor, der große Dateien verarbeiten kann."
                  },
                  {
                    "tr_title": "Arbeitsspeicher, der Bearbeitungssoftware schnell und reaktionssicher macht."
                  },
                  {
                    "tr_title": "Grafik, welche die Rendering-Zeiten reduziert."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Schnelles Multitasking",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SCHNELLES MULTITASKING",
                "tr_subtitle": "Mehr machen, ohne langsamer zu werden",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Multitasking geeignet:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor für mehrere Programme gleichzeitig."
                  },
                  {
                    "tr_title": "Schnelles, starkes und zuverlässiges Wi‑Fi."
                  },
                  {
                    "tr_title": "Arbeitsspeicher, der nahtlos zwischen Anwendungen wechselt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-fähig",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Die Virtuelle Realität erwacht zum Leben",
                "tr_subtitle": "Was ist nötig, um eine vollständig virtuelle Welt zu erschaffen? Auf einem PC müssen Prozessor, Grafikkarte und Speicher miteinander koordiniert werden.",
                "titleArray": [
                  {
                    "tr_title": "Starke Power für virtuelle Welten",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Die Realität liegt im Detail",
                    "tr_title1": "Genieße das volle Erlebnis",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D-Modellierung",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D-MODELLIERUNG",
                "tr_subtitle": "Kreationen auf der nächsten Ebene",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für 3D-Modellierung geeignet:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor für fortgeschrittene kreative Software."
                  },
                  {
                    "tr_title": "Arbeitsspeicher, der schnell große Dateien aufrufen kann."
                  },
                  {
                    "tr_title": "Grafik für leistungsstarke Bilderstellung und -bearbeitung."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Zusammenarbeit in Echtzeit",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ZUSAMMENARBEIT IN ECHTZEIT",
                "tr_subtitle": "Arbeite von überall aus",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Online-Zusammenarbeit geeignet:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor für Office-Software."
                  },
                  {
                    "tr_title": "Wi‑Fi, auf das man sich verlassen kann."
                  },
                  {
                    "tr_title": "Eine Webcam, die in brillantem HD aufnimmt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-fähig",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Die Virtuelle Realität erwacht zum Leben",
                "tr_subtitle": "Was ist nötig, um eine vollständig virtuelle Welt zu erschaffen? Auf einem PC müssen Prozessor, Grafikkarte und Speicher miteinander koordiniert werden.",
                "titleArray": [
                  {
                    "tr_title": "Starke Power für virtuelle Welten",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Die Realität liegt im Detail",
                    "tr_title1": "Genieße das volle Erlebnis",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ Laptops",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Laptops, die so flach und leicht sind, sollten nicht so leistungsstark sein.",
                "tr_subtitle": "Intel® Evo™ Laptops wurden für mobile Leistung entwickelt und bieten die Geschwindigkeit, Grafik und Akkulaufzeit, die du benötigst – und das alles in erstaunlich flachen und leichten Designs.",
                "titleArray": [
                  {
                    "tr_title": "Wir machen Laptops so vielseitig wie noch nie. Denn sie sind so wichtig wie noch nie.",
                    "tr_subtitle": "Verwende deinen Touchscreen, um dich umzusehen, die Ansicht zu drehen und Ausschnitte zu zoomen."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Blitzschnell einsatzbereit",
                    "tr_subtitle": "Mit der Instant-Wake-Funktion ist dein PC aus dem Standby in&nbsp;weniger als einer Sekunde einsatzbereit."
                  },
                  {
                    "tr_title": "Sensationelle Bildqualität",
                    "tr_subtitle": "Ein wunderschönes Display erweckt Bilder in&nbsp;scharfer Auflösung und brillanten Farben zum&nbsp;Leben."
                  },
                  {
                    "tr_title": "Alles schnell erledigen",
                    "tr_subtitle": "Die 11.&nbsp;Generation der Intel®&nbsp;Core™ Prozessoren sorgt für ultraschnelles Reaktionsverhalten."
                  },
                  {
                    "tr_title": "Längere kabellose Freiheit",
                    "tr_subtitle": "Mit einem PC, der die Akkulaufzeit optimiert und blitzschnell wieder aufgeladen ist, bleibst du länger mobil."
                  },
                  {
                    "tr_title": "Universelle Kabelverbindung",
                    "tr_subtitle": "Mit der Thunderbolt™&nbsp;4  Technik kannst du andere Geräte einfach, schnell und sicher mit deinem PC verbinden.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Schnelle, zuverlässige Verbindungen",
                    "tr_subtitle": "Mit Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+) hast du beim Videochatten und der Weitergabe von Dateien eine Verbindung, auf die du zählen kannst.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ Laptops",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alles, was du brauchst – und mehr – in einem flachen und leichten Laptop.",
                "tr_subtitle": "Intel® Evo™ Laptops sind designt für mobile Leistung und bieten dir die optimale Erfahrung<sup>2</sup>, egal, was du tust.",
                "titleArray": [
                  {
                    "tr_title": "Wir machen Laptops so vielseitig wie noch nie. Denn sie sind so wichtig wie noch nie.",
                    "tr_subtitle": "Verwende deinen Touchscreen, um dich umzusehen, die Ansicht zu drehen und Ausschnitte zu zoomen."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Blitzschnell einsatzbereit",
                    "tr_subtitle": "Mit der Instant-Wake-Funktion ist dein PC aus dem Standby in&nbsp;weniger als einer Sekunde einsatzbereit."
                  },
                  {
                    "tr_title": "Sensationelle Bildqualität",
                    "tr_subtitle": "Ein wunderschönes Display erweckt Bilder in&nbsp;scharfer Auflösung und brillanten Farben zum&nbsp;Leben."
                  },
                  {
                    "tr_title": "Nie dagewesene Leistung",
                    "tr_subtitle": "Erhalte mehr Power, wo du sie am meisten brauchst, mit der intelligenten Leistung von&nbsp;Intel®&nbsp;Core™&nbsp;Prozessoren der&nbsp;12.&nbsp;Generation."
                  },
                  {
                    "tr_title": "Längere kabellose Freiheit",
                    "tr_subtitle": "Mit einem PC, der die Akkulaufzeit optimiert und blitzschnell wieder aufgeladen ist, bleibst du länger mobil."
                  },
                  {
                    "tr_title": "Universelle Kabelverbindung",
                    "tr_subtitle": "Mit der Thunderbolt™&nbsp;4  Technik kannst du andere Geräte einfach, schnell und sicher mit deinem PC verbinden.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Schnelle, zuverlässige Verbindungen",
                    "tr_subtitle": "Mit Intel® Wi-Fi 6E (Gig+) hast du beim Videochatten und der Weitergabe von Dateien eine Verbindung, auf die du zählen kannst.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ Laptops",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Laptops, die so flach und leicht sind, sollten nicht so leistungsstark sein.",
                "tr_subtitle": "Intel® Evo™ Laptops wurden für mobile Leistung entwickelt und bieten die Geschwindigkeit, Grafik und Akkulaufzeit, die du benötigst – und das alles in erstaunlich flachen und leichten Designs.",
                "titleArray": [
                  {
                    "tr_title": "Wir machen Laptops so vielseitig wie noch nie. Denn sie sind so wichtig wie noch nie.",
                    "tr_subtitle": "Verwende deinen Touchscreen, um dich umzusehen, die Ansicht zu drehen und Ausschnitte zu zoomen."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Blitzschnell einsatzbereit",
                    "tr_subtitle": "Mit der Instant-Wake-Funktion ist dein PC aus dem Standby in&nbsp;weniger als einer Sekunde einsatzbereit."
                  },
                  {
                    "tr_title": "Sensationelle Bildqualität",
                    "tr_subtitle": "Ein wunderschönes Display erweckt Bilder in&nbsp;scharfer Auflösung und brillanten Farben zum&nbsp;Leben."
                  },
                  {
                    "tr_title": "Alles schnell erledigen",
                    "tr_subtitle": "Die 11.&nbsp;Generation der Intel®&nbsp;Core™ Prozessoren sorgt für ultraschnelles Reaktionsverhalten."
                  },
                  {
                    "tr_title": "Längere kabellose Freiheit",
                    "tr_subtitle": "Mit einem PC, der die Akkulaufzeit optimiert und blitzschnell wieder aufgeladen ist, bleibst du länger mobil."
                  },
                  {
                    "tr_title": "Universelle Kabelverbindung",
                    "tr_subtitle": "Mit der Thunderbolt™&nbsp;4  Technik kannst du andere Geräte einfach, schnell und sicher mit deinem PC verbinden.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Schnelle, zuverlässige Verbindungen",
                    "tr_subtitle": "Mit Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+) hast du beim Videochatten und der Weitergabe von Dateien eine Verbindung, auf die du zählen kannst.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ Laptops",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alles, was du brauchst – und mehr – in einem flachen und leichten Laptop.",
                "tr_subtitle": "Intel® Evo™ Laptops sind designt für mobile Leistung und bieten dir die optimale Erfahrung<sup>2</sup>, egal, was du tust.",
                "titleArray": [
                  {
                    "tr_title": "Wir machen Laptops so vielseitig wie noch nie. Denn sie sind so wichtig wie noch nie.",
                    "tr_subtitle": "Verwende deinen Touchscreen, um dich umzusehen, die Ansicht zu drehen und Ausschnitte zu zoomen."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Blitzschnell einsatzbereit",
                    "tr_subtitle": "Mit der Instant-Wake-Funktion ist dein PC aus dem Standby in&nbsp;weniger als einer Sekunde einsatzbereit."
                  },
                  {
                    "tr_title": "Sensationelle Bildqualität",
                    "tr_subtitle": "Ein wunderschönes Display erweckt Bilder in&nbsp;scharfer Auflösung und brillanten Farben zum&nbsp;Leben."
                  },
                  {
                    "tr_title": "Nie dagewesene Leistung",
                    "tr_subtitle": "Erhalte mehr Power, wo du sie am meisten brauchst, mit der intelligenten Leistung von&nbsp;Intel®&nbsp;Core™&nbsp;Prozessoren der&nbsp;12.&nbsp;Generation."
                  },
                  {
                    "tr_title": "Längere kabellose Freiheit",
                    "tr_subtitle": "Mit einem PC, der die Akkulaufzeit optimiert und blitzschnell wieder aufgeladen ist, bleibst du länger mobil."
                  },
                  {
                    "tr_title": "Universelle Kabelverbindung",
                    "tr_subtitle": "Mit der Thunderbolt™&nbsp;4  Technik kannst du andere Geräte einfach, schnell und sicher mit deinem PC verbinden.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Schnelle, zuverlässige Verbindungen",
                    "tr_subtitle": "Mit Intel® Wi-Fi 6E (Gig+) hast du beim Videochatten und der Weitergabe von Dateien eine Verbindung, auf die du zählen kannst.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ Laptops",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alles, was du brauchst – und mehr – in einem flachen und leichten Laptop.",
                "tr_subtitle": "Intel® Evo™ Laptops sind designt für mobile Leistung und bieten dir die optimale Erfahrung<sup>2</sup>, egal, was du tust.",
                "titleArray": [
                  {
                    "tr_title": "Wir machen Laptops so vielseitig wie noch nie. Denn sie sind so wichtig wie noch nie.",
                    "tr_subtitle": "Verwende deinen Touchscreen, um dich umzusehen, die Ansicht zu drehen und Ausschnitte zu zoomen."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Blitzschnell einsatzbereit",
                    "tr_subtitle": "Mit der Instant-Wake-Funktion ist dein PC aus dem Standby in&nbsp;weniger als einer Sekunde einsatzbereit."
                  },
                  {
                    "tr_title": "Sensationelle Bildqualität",
                    "tr_subtitle": "Ein wunderschönes Display erweckt Bilder in&nbsp;scharfer Auflösung und brillanten Farben zum&nbsp;Leben."
                  },
                  {
                    "tr_title": "Nie dagewesene Leistung",
                    "tr_subtitle": "Erhalte mehr Power, wo du sie am meisten brauchst, mit der intelligenten Leistung von&nbsp;Intel®&nbsp;Core™&nbsp;Prozessoren der&nbsp;13.&nbsp;Generation."
                  },
                  {
                    "tr_title": "Längere kabellose Freiheit",
                    "tr_subtitle": "Mit einem PC, der die Akkulaufzeit optimiert und blitzschnell wieder aufgeladen ist, bleibst du länger mobil."
                  },
                  {
                    "tr_title": "Universelle Kabelverbindung",
                    "tr_subtitle": "Mit der Thunderbolt™&nbsp;4  Technik kannst du andere Geräte einfach, schnell und sicher mit deinem PC verbinden.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Schnelle, zuverlässige Verbindungen",
                    "tr_subtitle": "Mit Intel® Wi-Fi 6E (Gig+) hast du beim Videochatten und der Weitergabe von Dateien eine Verbindung, auf die du zählen kannst.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ Laptops",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alles, was du brauchst – und mehr – in einem flachen und leichten Laptop.",
                "tr_subtitle": "Intel® Evo™ Laptops sind designt für mobile Leistung und bieten dir die optimale Erfahrung<sup>2</sup>, egal, was du tust.",
                "titleArray": [
                  {
                    "tr_title": "Wir machen Laptops so vielseitig wie noch nie. Denn sie sind so wichtig wie noch nie.",
                    "tr_subtitle": "Verwende deinen Touchscreen, um dich umzusehen, die Ansicht zu drehen und Ausschnitte zu zoomen."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Blitzschnell einsatzbereit",
                    "tr_subtitle": "Mit der Instant-Wake-Funktion ist dein PC aus dem Standby in&nbsp;weniger als einer Sekunde einsatzbereit."
                  },
                  {
                    "tr_title": "Sensationelle Bildqualität",
                    "tr_subtitle": "Ein wunderschönes Display erweckt Bilder in&nbsp;scharfer Auflösung und brillanten Farben zum&nbsp;Leben."
                  },
                  {
                    "tr_title": "Nie dagewesene Leistung",
                    "tr_subtitle": "Erhalte mehr Power, wo du sie am meisten brauchst, mit der intelligenten Leistung von&nbsp;Intel®&nbsp;Core™&nbsp;Prozessoren der&nbsp;13.&nbsp;Generation."
                  },
                  {
                    "tr_title": "Längere kabellose Freiheit",
                    "tr_subtitle": "Mit einem PC, der die Akkulaufzeit optimiert und blitzschnell wieder aufgeladen ist, bleibst du länger mobil."
                  },
                  {
                    "tr_title": "Universelle Kabelverbindung",
                    "tr_subtitle": "Mit der Thunderbolt™&nbsp;4  Technik kannst du andere Geräte einfach, schnell und sicher mit deinem PC verbinden.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Schnelle, zuverlässige Verbindungen",
                    "tr_subtitle": "Mit Intel® Wi-Fi 6E (Gig+) hast du beim Videochatten und der Weitergabe von Dateien eine Verbindung, auf die du zählen kannst.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Was ist die Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Warum Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativanwendungen",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Sei an mehr Orten kreativ",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Fotobearbeitung mit voller Geschwindigkeit",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Gestalte im Handumdrehen originelle Kunst",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivität",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximiere deine Produktivität mit Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Mit KI Deepfakes erkennen, bevor du sie teilst",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Deine Welt. Auf deinen Geräten.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilität",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Sag hallo zur Gestensteuerung",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Akkulaufzeiten, die dich nicht aufhalten",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Hält dich automatisch verbunden",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Was ist die Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Warum Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativanwendungen",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Schnelle, flüssige Videowiedergabe",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Mit KI Hits produzieren",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Der schnellste Weg zu deiner Finalversion",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivität",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximiere deine Produktivität mit Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Mit KI Deepfakes erkennen, bevor du sie teilst",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Deine Welt. Auf deinen Geräten.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilität",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Zusammenarbeit von praktisch überall aus",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Akkulaufzeiten, die dich nicht aufhalten",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Hält dich automatisch verbunden",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Dein gesamtes Entertainment. Ein&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Für einen PC, der gleichzeitig als Unterhaltungscenter fungiert, benötigst du ausreichend Speicherplatz für deine Lieblingssongs, Videos und Spiele sowie die richtige Kombination aus Prozessor und Bildschirm, um ein brillantes Multimedia-Erlebnis zu genießen. Dieser PC bietet alle drei Komponenten.",
                "titleArray": [
                  {
                    "tr_title": "Eindrucksvolles Gaming und Streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Erstelle deine eigene digitale Bibliothek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ein Fenster für dein Entertainment",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reibungsloses Gaming und Streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Erstelle deine eigene digitale Bibliothek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ein Fenster für dein Entertainment",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Höhere Produktivität. Überall.",
              "tr_tileTitle1": "Schneller mehr erledigen.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dieser PC bietet die perfekte Balance aus Leistung und Mobilität. Er ist mit einem für flache und leichte Designs konzipierten Prozessor ausgestattet und verfügt zudem über Wi&#8209;Fi&#8209;Technik, die Verbindungen stabil hält, sowie über den richtigen Akku, damit du auch ohne Netzanschluss produktiv bleibst.",
                "tr_subtitle1": "Dieser Computer bietet perfekt aufeinander abgestimmte Leistung und Funktion, um in kürzerer Zeit mehr zu erreichen. Die Kombination von robuster, durch großen Arbeitsspeicher verstärkter Prozessorleistung und blitzschnellem WLAN erleichtert die alltäglichen Aufgaben.",
                "tr_subtitle2": "Dieser PC bietet die perfekte Balance aus Leistung und Mobilität. Er ist mit einem für flache und leichte Designs konzipierten Prozessor ausgestattet und verfügt zudem über Wi‑Fi-Technik, die Verbindungen stabil hält, sowie über den für Multitasking-Aufgaben richtigen Arbeitsspeicher.",
                "titleArray": [
                  {
                    "tr_title": "Intelligente Leistung, die den Unterschied bewirkt",
                    "tr_title1": "Mehr Tempo durch intelligente Leistung",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Führende Vernetzungstechnik",
                    "tr_title1": "Alle wichtigen Verbindungen",
                    "tr_title2": "Ein bedeutender Fortschritt für das WLAN",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Ohne lästige Kabel länger mobil",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Mühelos mehrere Programme gleichzeitig nutzen",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreative Freiheit",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Die Erstellung von Inhalten ist eine der anspruchsvollsten Aufgaben für deinen PC. Mit diesen Komponenten kannst du deine kreativen Prozesse hervorragend optimieren.",
                "titleArray": [
                  {
                    "tr_title": "Beschleunigte Kreativität",
                    "tr_title1": "Kreative Projekte mit Leichtigkeit meistern",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Mehr Leistung für Kreativität",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Arbeitsspeicher für Multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "PC-Einblicke",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dies sind die drei Hauptkomponenten, die du bei der Auswahl eines neuen PCs im Auge behalten solltest. Wenn du eine Entscheidung dazu getroffen hast, wie du deinen neuen PC verwenden möchtest, ist bereits viel dazu entschieden, wie viel du von jeder Schlüsselkomponente benötigst. Die Komponenten sind:",
                "titleArray": [
                  {
                    "tr_title": "Prozessor",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Massenspeicher",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Arbeitsspeicher",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Kreative Freiheit",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Die Erstellung von Inhalten ist eine der anspruchsvollsten Aufgaben für deinen PC. Mit diesen Komponenten kannst du deine kreativen Prozesse hervorragend optimieren.",
                "titleArray": [
                  {
                    "tr_title": "Smarter kreativ sein",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Erwecke deine Bilder zum Leben",
                    "tr_title1": "Let’s Create",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Arbeitsspeicher für Multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Höhere Produktivität. Überall.",
              "tr_tileTitle1": "Schneller mehr erledigen.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dieser PC bietet die perfekte Balance aus Leistung und Mobilität. Er ist mit einem für flache und leichte Designs konzipierten Prozessor ausgestattet und verfügt zudem über Wi&#8209;Fi&#8209;Technik, die Verbindungen stabil hält, sowie über den richtigen Akku, damit du auch ohne Netzanschluss produktiv bleibst.",
                "tr_subtitle1": "Dieser Computer bietet perfekt aufeinander abgestimmte Leistung und Funktion, um in kürzerer Zeit mehr zu erreichen. Die Kombination von robuster, durch großen Arbeitsspeicher verstärkter Prozessorleistung und blitzschnellem WLAN erleichtert die alltäglichen Aufgaben.",
                "tr_subtitle2": "Dieser PC bietet die perfekte Balance aus Leistung und Mobilität. Er ist mit einem für flache und leichte Designs konzipierten Prozessor ausgestattet und verfügt zudem über Wi‑Fi-Technik, die Verbindungen stabil hält, sowie über den für Multitasking-Aufgaben richtigen Arbeitsspeicher.",
                "titleArray": [
                  {
                    "tr_title": "Bereit für mehr",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Führende Vernetzungstechnik",
                    "tr_title1": "Alle wichtigen Verbindungen",
                    "tr_title2": "Ein bedeutender Fortschritt für das WLAN",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Ohne lästige Kabel länger mobil",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Mühelos mehrere Programme gleichzeitig nutzen",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-fähig",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Die Virtuelle Realität erwacht zum Leben",
                "tr_subtitle": "Was ist nötig, um eine vollständig virtuelle Welt zu erschaffen? Auf einem PC müssen Prozessor, Grafikkarte und Speicher miteinander koordiniert werden.",
                "titleArray": [
                  {
                    "tr_title": "Starke Power für virtuelle Welten",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Die Realität liegt im Detail",
                    "tr_title1": "Genieße das volle Erlebnis",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>Eine Augenweide",
      "tr_sub_header": "Blaues Licht kann visuelle Beschwerden verursachen und deinen Schlafzyklus unterbrechen. Das SGS Eye Care Display von Samsung ist ein getestetes OLED-Display, das, statt die Farben zu ändern, die Wellenlänge des potenziell schädlichen blauen Lichtes verringert, was Augenkomfort und eine reduzierte Augenermüdung bietet.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Weniger schädliches blaues Licht"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra in jeder Hinsicht",
      "tr_sub_header": "Das ultraleichte, ultradünne Design von OLED maximiert die Bildqualität und minimiert das Gewicht."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "True Black",
      "tr_sub_header": "0,0005 Nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74&nbsp;%<br/><span class='conventional_text'>konventionell</span>",
      "tr_sub_header": "120&nbsp;%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streaming",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMING",
                "tr_subtitle": "Endlose Unterhaltung",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für das Streaming geeignet:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor, der in HD streamen kann."
                  },
                  {
                    "tr_title": "Ein Display für helle und schöne Bilder."
                  },
                  {
                    "tr_title": "Wi‑Fi mit weniger Puffer- und Ladezeiten."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Web-Browsing",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WEB-BROWSING",
                "tr_subtitle": "Das Internet ganz einfach",
                "tr_subtitle1": "Hier ist es, was diesen PC für das Web-Browsen ideal macht:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor, der schnell medienintensive Seiten laden kann."
                  },
                  {
                    "tr_title": "Schnelles und zuverlässiges Wi‑Fi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Video-Chats",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEO-CHATS",
                "tr_subtitle": "Kristallklare Verbindungen",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Video-Chats geeignet:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor, der gleichzeitig Bilder aufnimmt und teilt."
                  },
                  {
                    "tr_title": "Schnelles, starkes und zuverlässiges Wi‑Fi."
                  },
                  {
                    "tr_title": "Eine Webcam, die dir hilft, kristallklar zu kommunizieren."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Schnelles Multitasking",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SCHNELLES MULTITASKING",
                "tr_subtitle": "Mehr machen, ohne langsamer zu werden",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Multitasking geeignet:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor für mehrere Programme gleichzeitig."
                  },
                  {
                    "tr_title": "Schnelles, starkes und zuverlässiges Wi‑Fi."
                  },
                  {
                    "tr_title": "Arbeitsspeicher, der nahtlos zwischen Anwendungen wechselt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Video-Chats",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEO-CHATS",
                "tr_subtitle": "Kristallklare Verbindungen",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Video-Chats geeignet:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor, der gleichzeitig Bilder aufnimmt und teilt."
                  },
                  {
                    "tr_title": "Schnelles, starkes und zuverlässiges Wi‑Fi."
                  },
                  {
                    "tr_title": "Eine Webcam, die dir hilft, kristallklar zu kommunizieren."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Gelegenheitsgaming",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "GELEGENHEITSGAMING",
                "tr_subtitle": "Zeit zum Spielen",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für leichtes Gaming geeignet:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor für ein reibungsloses Gameplay."
                  },
                  {
                    "tr_title": "Arbeitsspeicher, der gleichzeitig Voice-Chat und Streaming unterstützt."
                  },
                  {
                    "tr_title": "Grafik für erstaunliche Bilder."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Fotobearbeitung",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FOTOBEARBEITUNG",
                "tr_subtitle": "Pixel-perfekte Fotos",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Fotobearbeitung geeignet:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor mit ausreichend Leistung, um große Gruppen von Bildern zu bearbeiten."
                  },
                  {
                    "tr_title": "Arbeitsspeicher, der deine Bearbeitungssoftware beschleunigt."
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 Technik ermöglicht es dir, schnell Dateien zu übertragen."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Schnelles Multitasking",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SCHNELLES MULTITASKING",
                "tr_subtitle": "Mehr machen, ohne langsamer zu werden",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Multitasking geeignet:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor für mehrere Programme gleichzeitig."
                  },
                  {
                    "tr_title": "Schnelles, starkes und zuverlässiges Wi‑Fi."
                  },
                  {
                    "tr_title": "Arbeitsspeicher, der nahtlos zwischen Anwendungen wechselt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Zusammenarbeit in Echtzeit",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ZUSAMMENARBEIT IN ECHTZEIT",
                "tr_subtitle": "Arbeite von überall aus",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Online-Zusammenarbeit geeignet:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor für Office-Software."
                  },
                  {
                    "tr_title": "Wi‑Fi, auf das man sich verlassen kann."
                  },
                  {
                    "tr_title": "Eine Webcam, die in brillantem HD aufnimmt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videobearbeitung",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOBEARBEITUNG",
                "tr_subtitle": "Erstelle magische Filme",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Videobearbeitung geeignet:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor, der große Dateien verarbeiten kann."
                  },
                  {
                    "tr_title": "Arbeitsspeicher, der Bearbeitungssoftware schnell und reaktionssicher macht."
                  },
                  {
                    "tr_title": "Grafik, welche die Rendering-Zeiten reduziert."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Fotobearbeitung",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FOTOBEARBEITUNG",
                "tr_subtitle": "Pixel-perfekte Fotos",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Fotobearbeitung geeignet:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor mit ausreichend Leistung, um große Gruppen von Bildern zu bearbeiten."
                  },
                  {
                    "tr_title": "Arbeitsspeicher, der deine Bearbeitungssoftware beschleunigt."
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 Technik ermöglicht es dir, schnell Dateien zu übertragen."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Videobearbeitung",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOBEARBEITUNG",
                "tr_subtitle": "Erstelle magische Filme",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Videobearbeitung geeignet:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor, der große Dateien verarbeiten kann."
                  },
                  {
                    "tr_title": "Arbeitsspeicher, der Bearbeitungssoftware schnell und reaktionssicher macht."
                  },
                  {
                    "tr_title": "Grafik, welche die Rendering-Zeiten reduziert."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D-Modellierung",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D-MODELLIERUNG",
                "tr_subtitle": "Kreationen auf der nächsten Ebene",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für 3D-Modellierung geeignet:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor für fortgeschrittene kreative Software."
                  },
                  {
                    "tr_title": "Arbeitsspeicher, der schnell große Dateien aufrufen kann."
                  },
                  {
                    "tr_title": "Grafik für leistungsstarke Bilderstellung und -bearbeitung."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Zusammenarbeit in Echtzeit",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ZUSAMMENARBEIT IN ECHTZEIT",
                "tr_subtitle": "Arbeite von überall aus",
                "tr_subtitle1": "Deshalb ist dieser PC hervorragend für Online-Zusammenarbeit geeignet:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Ein Prozessor für Office-Software."
                  },
                  {
                    "tr_title": "Wi‑Fi, auf das man sich verlassen kann."
                  },
                  {
                    "tr_title": "Eine Webcam, die in brillantem HD aufnimmt."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Intel® Unison™ App",
    "tr_title": "Tauche ein in deine vernetzte Welt",
    "tr_subtitle": "Übertrage nahtlos Dateien und Fotos, während du die Benachrichtigungen, Anrufe und SMS vom PC aus verwaltest.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "ÜBERTRAGE DATEIEN UND FOTOS",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "TÄTIGE UND EMPFANGE ANRUFE",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "SENDE UND EMPFANGE TEXTNACHRICHTEN",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "VERWALTE SMARTPHONE-BENACHRICH&shy;TIGUNGEN",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "TAUCHE EIN IN DEINE VERNETZTE WELT",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}