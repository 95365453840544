const Library_mobile_ko = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[cpuDisplay] 프로세서 기반"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[cpuDisplay] 프로세서 기반"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "프로세서는 마치 두뇌처럼 PC의 모든 것을 통제합니다. 즉, 영상 시청과 게임 플레이, 웹 사이트 방문 등 모든 것이 프로세서로부터 시작됩니다.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "프로세서는 PC의 두뇌입니다"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "성능과 가치",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "인텔® 펜티엄® Silver 프로세서",
                    "tr_subtitle": "웹에서 파일에 액세스할 수 있는 성능을 통해 디지털 세계에서 앞서가십시오. 프로그램 간에 간편하게 전환하여 간편하게 멀티태스킹을 수행하고 더 짧은 시간에 더 많이 처리할 수 있습니다.",
                    "tr_gen_core_title": "인텔® 펜티엄® Silver 프로세서"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "인텔® 펜티엄® Gold 프로세서",
                    "tr_subtitle": "향상된 생산성 - 가장 까다로운 작업을 위해 더 강력한 성능을 제공합니다. 간소화된 웹 탐색 - 미디어가 풍부한 웹 페이지를 빠르게 로드하여 더 짧은 시간에 더 많이 탐색할 수 있습니다.",
                    "tr_gen_core_title": "인텔® 펜티엄® Gold 프로세서"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "인텔® 셀러론® 프로세서",
                    "tr_subtitle": "업무 및 엔터테인먼트를 모두 강력하게 지원하는 성능 영화와 웹 비디오를 놀라운 디테일로 즐기십시오. 더 짧은 시간에 더 많이 처리하십시오.",
                    "tr_gen_core_title": "인텔® 셀러론® 프로세서"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "인텔® 펜티엄® 프로세서",
                    "tr_subtitle": "뛰어난 엔터테인먼트, 비디오 스트리밍 및 생산성을 위해 설계되었습니다. 놀랍도록 선명한 영화와 웹 비디오를 즐기십시오. 더 짧은 시간에 더 많이 처리하십시오.",
                    "tr_gen_core_title": "인텔® 펜티엄® 프로세서"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "최상의 성능 및 비용 효율성",
                "tr_title": "인텔® 프로세서",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "모든 카테고리에서의 탁월한 가치",
                    "tr_subtitle": "인텔® 프로세서는 합리적인 가격으로 필요한 모든 작업을 처리할 수 있습니다. 소프트웨어 간에 쉽게 전환하십시오. 또는 안정적인 성능으로 고품질의 그래픽을 즐기십시오.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "더욱 강력한 연결 구축",
                    "tr_subtitle": "더욱 생동감 넘치는 비디오 품질, 보다 풍부하고 선명한 오디오, 거의 3배 빠른 인터넷 연결을 통해 필요한 곳 어디에서나 자신 있게 협업할 수 있습니다.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "새로운 차원의 차별화",
                    "tr_subtitle": "아침부터 영화를 몰아보거나 마지막 순간까지 작업물을 손보는 경우에도 인텔® 프로세서는 장치의 배터리 수명을 연장하여 몰입도 높고 중단 없는 경험을 제공합니다.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "모든 환경에서 학습 가능",
                    "tr_subtitle": "집 어디에서나 여러 개의 온라인 학습 도구를 간편하게 실행합니다."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "장소에 구애받지 않는 학습",
                    "tr_subtitle": "다양한 e-러닝 도구를 동시에 실행하면서 장소에 구애받지 않고 학습을 완료할 수 있습니다."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "예산과 라이프스타일에 맞는 엔터테인먼트와 생산성을 위한 파워를 확보하십시오.",
                "tr_title": "어떤 예산에도 맞는 인텔 성능.",
                "tr_gen_core_title": "인텔® 셀러론® 프로세서",
                "processorCompare": {
                  "tr_title": "적합한 수준의 성능과 가치 찾기",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "인텔® 셀러론® 프로세서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "인텔® 펜티엄® Gold 프로세서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "인텔® 코어™ i3 프로세서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "빠르고 미디어가 풍부한 웹 탐색",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "프로그램 간의 간편한 전환",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "캐주얼 게임",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K 비디오 스트리밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "가벼운 사진 및 비디오 편집",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "지능적인 성능으로 생산성 가속",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "7세대 인텔® 코어™ [cpu_fam] 프로세서 기반"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "6세대 인텔® 코어™ [cpu_fam] 프로세서 기반"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "5세대 인텔® 코어™ [cpu_fam] 프로세서 기반"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "4세대 인텔® 코어™ [cpu_fam] 프로세서 기반"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "3세대 인텔® 코어™ [cpu_fam] 프로세서 기반"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "2세대 인텔® 코어™ [cpu_fam] 프로세서 기반"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "1세대 인텔® 코어™ [cpu_fam] 프로세서 기반"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "추천 대상:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "8세대 인텔® 코어™ i3 프로세서 기반",
                "tr_gen_core_title": "8세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9세대 인텔® 코어™ 프로세서는 디지털 세상에 적합하도록 설&#65279;계&#65279;되&#65279;었&#65279;습&#65279;니&#65279;다. 속&#65279;도&#65279;와&nbsp;성&#65279;능&#65279;이 결&#65279;합&#65279;하&#65279;여 PC&#65279;로 원하는 작업을 답답함 없이 더&nbsp;많&#65279;이 할 수 있습니다.",
                "tr_title": "9세대 인텔® 코어™ i3 프로세서 기반",
                "tr_gen_core_title": "9세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "다목적 성능에 충분한 배터리 수명을 결합하여 언제 어디서나 업무를 수행할 수 있습니다.",
                "tr_title": "온종일 지속 가능한 배터리 수&#65279;명",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서",
                "processorCompare": {
                  "tr_title": "10세대 성능의 적정 수준 찾기",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "인텔® 코어™ i3 프로세서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "4K 디테일의 영화 스트리밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "빠르고 안정적인 연결성",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "사진 마스킹 및 비디오 필터링",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "원활한 프레임 속도로 1080p에서 게이밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대용량 파일 및 크리에이티브 프로그램 작업에 최적",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "여유로운 성능으로 영화 스트리밍에서 프레젠테이션 제작까지 손쉽게 처리할 수 있&#65279;습&#65279;니&#65279;다.",
                "tr_title": "온종일 지속 가능한 배터리 수&#65279;명",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "이동이 잦은 사용자를 위해 특별히 설계된 노트북입니다.",
                "tr_title": "새로운 차원의 노트북을 만나보세요.",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "휴대용 PC를 위해 특별 설계된 11세대 인텔® 코어™ i3 프로세서를 통해 그 어느 때보다 더 많은 작업을 어디서나 수행할 수 있습니다.",
                "tr_title": "새 노트북 최대한 활용하기",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i3 프로세서",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "가족 구성원 모두가 한 대의 데스크탑 PC로 모든 작업을 수행한다면 11세대 인텔® 코어™ i3 프로세서가 제공하는 파워와 다기능성이 유용할 것입니다.",
                "tr_title": "한계를 뛰어넘는 성능으로 모든 것에 대비하십시오.",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i3 프로세서",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "8세대 인텔® 코어™ i5 프로세서 기반",
                "tr_gen_core_title": "8세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9세대 인텔® 코어™ 프로세서는 디지털 세상에 적합하도록 설&#65279;계&#65279;되&#65279;었&#65279;습&#65279;니&#65279;다. 속&#65279;도&#65279;와&nbsp;성&#65279;능&#65279;이 결&#65279;합&#65279;하&#65279;여 PC&#65279;로 원하는 작업을 답답함 없이 더&nbsp;많&#65279;이 할 수 있습니다.",
                "tr_title": "9세대 인텔® 코어™ i5 프로세서 기반",
                "tr_gen_core_title": "9세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "가장 까다로운 타이틀에서도 매끄러운 게임 플레이와 VR",
                "tr_title": "최고의 게이밍이 여기서 시작됩니다",
                "tr_gen_core_title": "9세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 인텔® 코어™ 프로세서를 찾아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "게이밍, 스트리밍, 레코딩을 원하는 대로",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "게이밍을 위한 최고의 9세대 인텔® 코어™ 프로세서",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 4개<br/>스레드: 8개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.1GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "여러 프로그램 간에 쉽게 전환되어 장소와 관계없이 어떤 작업이든 할 수 있습니다.",
                "tr_title": "이동 중에도 멀티태스킹",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "processorCompare": {
                  "tr_title": "10세대 성능의 적정 수준 찾기",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "인텔® 코어™ i3 프로세서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "4K 디테일의 영화 스트리밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "빠르고 안정적인 연결성",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "사진 마스킹 및 비디오 필터링",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "원활한 프레임 속도로 1080p에서 게이밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대용량 파일 및 크리에이티브 프로그램 작업에 최적",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "엔터테인먼트에서 생산성 및 콘텐츠 제작을 비롯한 모든 작업의 수준이 향상됩니다.",
                "tr_title": "더욱 강력한 PC로 업&#65279;그&#65279;레&#65279;이&#65279;드",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "이동이 잦은 사용자를 위해 특별히 설계된 노트북입니다.",
                "tr_title": "새로운 차원의 노트북을 만나보세요.",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "매끄러운 스트리밍 및 AAA 게이밍을 위한 설계",
                "tr_title": "마음껏 즐기는 플레이",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 10세대 인텔® 코어™ 프로세서를 알아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "원활한 AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍 및 레코딩",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "고급 콘텐츠 제작",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "최상의 게이밍 성능",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "언락 및 오버클러킹 가능<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.1GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "매끄러운 스트리밍 및 AAA 게이밍을 위한 설계",
                "tr_title": "마음껏 즐기는 플레이",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 10세대 인텔® 코어™ 프로세서를 알아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "원활한 AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍 및 레코딩",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "고급 콘텐츠 제작",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "최상의 게이밍 성능",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "언락 및 오버클러킹 가능<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.8GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.1GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 10개<br/>스레드: 20개</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "여러 프로그램 간에 쉽게 전환되어 장소와 관계없이 어떤 작업이든 할 수 있습니다.",
                "tr_title": "이동 중에도 멀티태스킹",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "10세대 인텔® 코어™ i5 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11세대 인텔® 코어™ i5 프로세서는 가장 가볍고 얇은 PC에&nbsp;생생한&nbsp;시각적&nbsp;경험과 폭넓은&nbsp;생산성을&nbsp;제공합니다.",
                "tr_title": "탁월한 노트북 성능",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i5 프로세서",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "가족 구성원 모두가 한 대의 데스크탑 PC로 모든 작업을 수행한다면 11세대 인텔® 코어™ i5 프로세서가 제공하는 파워와 다기능성이 유용할 것입니다.",
                "tr_title": "한계를 뛰어넘는 성능으로 모든 것에 대비하십시오.",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i5 프로세서",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11세대 인텔® 코어™ i5 프로세서는 thin & light 노트북에 생생한 시각적 경험과 광범위한 생산성을 제공합니다.",
                "tr_title": "탁월한 노트북 성능",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i5 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "8세대 인텔® 코어™ i7 프로세서 기반",
                "tr_gen_core_title": "8세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "9세대 인텔® 코어™ 프로세서는 디지털 세상에 적합하도록 설&#65279;계&#65279;되&#65279;었&#65279;습&#65279;니&#65279;다. 속&#65279;도&#65279;와&nbsp;성&#65279;능&#65279;이 결&#65279;합&#65279;하&#65279;여 PC&#65279;로 원하는 작업을 답답함 없이 더&nbsp;많&#65279;이 할 수 있습니다.",
                "tr_title": "9세대 인텔® 코어™ i7 프로세서 기반",
                "tr_gen_core_title": "9세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "게이밍, 스트리밍, 레코딩 수행 시 3년 된 PC 대비 최대 32% 더 높은 FPS<sup>3</sup>",
                "tr_title": "가장 근사한 게이밍 순간을 공유할 수 있는 파워",
                "tr_gen_core_title": "9세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 인텔® 코어™ 프로세서를 찾아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "게이밍, 스트리밍, 레코딩을 원하는 대로",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "게이밍을 위한 최고의 9세대 인텔® 코어™ 프로세서",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 4개<br/>스레드: 8개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.1GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "효율성을 위해 설계된 이 세련된 기기는 창작 작업, 연결, 스트리밍 방식에 큰 영향을 줄 수 있습니다.",
                "tr_title": "어디에서나 고급 성능 발휘",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "processorCompare": {
                  "tr_title": "10세대 성능의 적정 수준 찾기",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "인텔® 코어™ i3 프로세서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "4K 디테일의 영화 스트리밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "빠르고 안정적인 연결성",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "사진 마스킹 및 비디오 필터링",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "원활한 프레임 속도로 1080p에서 게이밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대용량 파일 및 크리에이티브 프로그램 작업에 최적",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "우수한 성능으로 모든 작업 수준 향상.",
                "tr_title": "무엇을 하든 전달되는 파워",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "이동이 잦은 사용자를 위해 특별히 설계된 노트북입니다.",
                "tr_title": "새로운 차원의 노트북을 만나보세요.",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "경쟁력 있는 게이밍, 스트리밍 및 레코딩",
                "tr_title": "게이밍 세상에 전달되는 파워",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 10세대 인텔® 코어™ 프로세서를 알아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "원활한 AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍 및 레코딩",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "고급 콘텐츠 제작",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "최상의 게이밍 성능",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "언락 및 오버클러킹 가능<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.1GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "경쟁력 있는 게이밍, 스트리밍 및 레코딩",
                "tr_title": "게이밍 세상에 전달되는 파워",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 10세대 인텔® 코어™ 프로세서를 알아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "원활한 AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍 및 레코딩",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "고급 콘텐츠 제작",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "최상의 게이밍 성능",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "언락 및 오버클러킹 가능<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.8GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.1GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 10개<br/>스레드: 20개</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "효율성을 위해 설계된 이 세련된 장치는 제작, 연결 및 스트리밍 방식에 큰 영향을 줄 수 있습니다.",
                "tr_title": "어디에서나 프리미엄 성능",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "10세대 인텔® 코어™ i7 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11세대 인텔® 코&#xfeff;어&#xfeff;™ i7 프로세서는 휴대성이 매우 좋은 PC에서 고급 콘텐츠 제작, 원활한 게이밍 및 차세대 엔터테인먼트를 지원합니다.",
                "tr_title": "혁신적인 노트북 성능",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i7 프로세서",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "11세대 인텔® 코&#xfeff;어&#xfeff;™ i7 프로세서가 제공하는 한계를 뛰어넘는 성능을 통해 가정에서 필요한 모든 것을 데스크탑 PC로 해결하세요.",
                "tr_title": "업무. 학습. 게임플레이. 한계 없이 즐기세요.",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i7 프로세서",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11세대 인텔® 코&#xfeff;어&#xfeff;™ i7 프로세서는 thin & light 노트북에 고급 콘텐츠 제작, 원활한 게이밍 및 한 차원 높은 엔터테인먼트를 제공합니다.",
                "tr_title": "혁신적인 노트북 성능",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i7 프로세서"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11세대 인텔® 코&#xfeff;어&#xfeff;™ i7 프로세서가 제공하는 한계를 뛰어넘는 성능을 통해 가정에서 필요한 모든 것을 데스크탑 PC로 해결하세요.",
                "tr_title": "한계 없는 제작",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i7 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "고급 콘텐츠 제작, 원활한 게이밍 및 한 차원 높은 엔터테인먼트를 제공하는 thin & light 노트북으로 11세대 인텔® 코어™ i7&nbsp;프로세서가 한층&nbsp;더&nbsp;강력해졌습니다.",
                "tr_title": "혁신적인 노트북 성능",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i7 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "8세대 인텔® 코어™ i9 프로세서 기반",
                "tr_gen_core_title": "8세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9세대 인텔® 코어™ 프로세서는 디지털 세상에 적합하도록 설&#65279;계&#65279;되&#65279;었&#65279;습&#65279;니&#65279;다. 속&#65279;도&#65279;와&nbsp;성&#65279;능&#65279;이 결&#65279;합&#65279;하&#65279;여 PC&#65279;로 원하는 작업을 답답함 없이 더&nbsp;많&#65279;이 할 수 있습니다.",
                "tr_title": "9세대 인텔® 코어™ i9 프로세서 기반",
                "tr_gen_core_title": "9세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "게이밍, 스트리밍 및 레코딩 수행 시 3년 된 PC 대비 최대 41% 더 높은 FPS<sup>4</sup> ",
                "tr_title": "게이머가 요구하면, 인텔® 코어™ i9이 응답합니다.",
                "tr_gen_core_title": "9세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 인텔® 코어™ 프로세서를 찾아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "게이밍, 스트리밍, 레코딩을 원하는 대로",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "게이밍을 위한 최고의 9세대 인텔® 코어™ 프로세서",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 4개<br/>스레드: 8개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.1GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "최고의 게이밍 경험을 통해 잠재력 발휘",
                "tr_title": "최고 수준에서 경쟁",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 10세대 인텔® 코어™ 프로세서를 알아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "원활한 AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍 및 레코딩",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "고급 콘텐츠 제작",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "최상의 게이밍 성능",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "언락 및 오버클러킹 가능<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.1GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "최고의 게이밍 경험을 통해 잠재력 발휘",
                "tr_title": "최고 수준에서 경쟁",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 10세대 인텔® 코어™ 프로세서를 알아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "원활한 AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍 및 레코딩",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "고급 콘텐츠 제작",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "최상의 게이밍 성능",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "언락 및 오버클러킹 가능<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.8GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.1GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 10개<br/>스레드: 20개</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "최고의 크리에이터 경험을 통해 잠재력을 발휘하세요.",
                "tr_title": "최고 수준에서 제작",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "10세대 인텔® 코어™ i9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11세대 인텔® 코어™ i5 프로세서 및 인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽 기반 인텔® Evo™ 노트북은 thin & light 디자인으로 놀라운 속도와 성능을 제공합니다.",
                "tr_title": "고도로 진화한 노트북을 위한 뛰어난 성능",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i5 프로세서",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11세대 인텔® 코&#xfeff;어&#xfeff;™ i7 프로세서 및 인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽 기반 인텔® Evo™ 노트북은 프리미엄 모바일 경험을 위한 향상된 속도와 성능을 제공합니다.",
                "tr_title": "고도로 진화한 노트북을 위한 강화된 성능",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i7 프로세서",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "뛰어난 성능, 미디어 및 연결 경험의 균형을 통해 놀라운 가치를 누리세요.",
                "tr_title": "놀라운 가치를 선사하는 성능&nbsp;및&nbsp;연결성.",
                "tr_gen_core_title": "인텔® 펜티엄® Silver 프로세서",
                "processorCompare": {
                  "tr_title": "적합한 수준의 성능과 가치 찾기",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "인텔® 펜티엄® Silver 프로세서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "인텔® 펜티엄® Gold 프로세서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "인텔® 코어™ i3 프로세서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "빠르고 미디어가 풍부한 웹 탐색",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "프로그램 간의 간편한 전환",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "캐주얼 게임",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K 비디오 스트리밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "가벼운 사진 및 비디오 편집",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "지능적인 성능으로 생산성 가속",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "원활한 멀티태스킹, 간단한 사진 및 비디오 편집, 4K 비디오 및 생생한 그래픽이 제공하는 놀라운 가치를 누리세요.",
                "tr_title": "일할 때나 즐길때 모두 뛰어난 성능.",
                "tr_gen_core_title": "인텔® 펜티엄® Gold 프로세서",
                "processorCompare": {
                  "tr_title": "적합한 수준의 성능과 가치 찾기",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "인텔® 펜티엄® Silver 프로세서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "인텔® 펜티엄® Gold 프로세서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "인텔® 코어™ i3 프로세서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "빠르고 미디어가 풍부한 웹 탐색",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "프로그램 간의 간편한 전환",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "캐주얼 게임",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K 비디오 스트리밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "가벼운 사진 및 비디오 편집",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "지능적인 성능으로 생산성 가속",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "울트라포터블 PC에서 누리는 AAA 게이밍 및 긴 배터리 수명. 플레이 방식을 타협할 필요가 없습니다.",
                "tr_title": "타협할 필요가 없습니다.",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i9 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "나에게 맞는 인텔® 코어™ 울트라포터블 게이밍 프로세서 찾기.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11세대 인&#65279;텔&#65279;®&nbsp;코&#65279;어&#65279;™ i5&#8209;11300H 프로세서"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11세대 인&#65279;텔&#65279;®&nbsp;코&#65279;어&#65279;™ i7&#8209;11370H 프로세서"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11세대 인&#65279;텔&#65279;®&nbsp;코&#65279;어&#65279;™ i7&#8209;11375H SE&nbsp;프로세서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "최대 <span class='style_badge'>4.4GHz</span> 클럭 속도",
                      "tr_td2_title": "최대 <span class='style_badge'>4.8GHz</span> 클럭 속도",
                      "tr_td3_title": "최대 <span class='style_badge'>5.0GHz</span> 클럭 속도"
                    },
                    {
                      "tr_td1_title": "4개 코어/8개 스레드",
                      "tr_td2_title": "4개 코어/8개 스레드",
                      "tr_td3_title": "4개 코어/8개 스레드"
                    },
                    {
                      "tr_td1_title": "인텔® 터보 부스트 기술",
                      "tr_td2_title": "인텔® 터보 부스트 기술",
                      "tr_td3_title": "인텔® 터보 부스트 맥스 기술  3.0"
                    },
                    {
                      "tr_td1_title": "8MB 인텔® 스마트 캐시",
                      "tr_td2_title": "12MB 인텔® 스마트 캐시",
                      "tr_td3_title": "12MB 인텔® 스마트 캐시"
                    },
                    {
                      "tr_td1_title": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽",
                      "tr_td2_title": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽",
                      "tr_td3_title": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "울트라포터블 PC에서 높은 FPS 및 높은 설정으로 1080p AAA 게이밍을 즐기고 전원이 분리된 상태에서도 탁월한 성능을 경험해보십시오.",
                "tr_title": "남보다 앞서 모든 걸 가지세요",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "나에게 맞는 인텔® 코어™ 울트라포터블 게이밍 프로세서 찾기.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11세대 인&#65279;텔&#65279;®&nbsp;코&#65279;어&#65279;™ i5&#8209;11300H 프로세서"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11세대 인&#65279;텔&#65279;®&nbsp;코&#65279;어&#65279;™ i7&#8209;11370H 프로세서"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11세대 인&#65279;텔&#65279;®&nbsp;코&#65279;어&#65279;™ i7&#8209;11375H SE&nbsp;프로세서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "최대 <span class='style_badge'>4.4GHz</span> 클럭 속도",
                      "tr_td2_title": "최대 <span class='style_badge'>4.8GHz</span> 클럭 속도",
                      "tr_td3_title": "최대 <span class='style_badge'>5.0GHz</span> 클럭 속도"
                    },
                    {
                      "tr_td1_title": "4개 코어/8개 스레드",
                      "tr_td2_title": "4개 코어/8개 스레드",
                      "tr_td3_title": "4개 코어/8개 스레드"
                    },
                    {
                      "tr_td1_title": "인텔® 터보 부스트 기술",
                      "tr_td2_title": "인텔® 터보 부스트 기술",
                      "tr_td3_title": "인텔® 터보 부스트 맥스 기술  3.0"
                    },
                    {
                      "tr_td1_title": "8MB 인텔® 스마트 캐시",
                      "tr_td2_title": "12MB 인텔® 스마트 캐시",
                      "tr_td3_title": "12MB 인텔® 스마트 캐시"
                    },
                    {
                      "tr_td1_title": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽",
                      "tr_td2_title": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽",
                      "tr_td3_title": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "최대 5.0GHz의 클럭 속도로 더 강력한 게임을 즐기고 울트라포터블 PC로 자유롭게 이동할 수 있습니다.",
                "tr_title": "컴팩트하면서도 강력한 파워",
                "tr_gen_core_title": "11세대 인텔® 코&#xfeff;어&#xfeff;™ i7 SE 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i7 스페셜 에디션 프로세서",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "나에게 맞는 인텔® 코어™ 울트라포터블 게이밍 프로세서 찾기.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11세대 인&#65279;텔&#65279;®&nbsp;코&#65279;어&#65279;™ i5&#8209;11300H 프로세서"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11세대 인&#65279;텔&#65279;®&nbsp;코&#65279;어&#65279;™ i7&#8209;11370H 프로세서"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11세대 인&#65279;텔&#65279;®&nbsp;코&#65279;어&#65279;™ i7&#8209;11375H SE&nbsp;프로세서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "최대 <span class='style_badge'>4.4GHz</span> 클럭 속도",
                      "tr_td2_title": "최대 <span class='style_badge'>4.8GHz</span> 클럭 속도",
                      "tr_td3_title": "최대 <span class='style_badge'>5.0GHz</span> 클럭 속도"
                    },
                    {
                      "tr_td1_title": "4개 코어/8개 스레드",
                      "tr_td2_title": "4개 코어/8개 스레드",
                      "tr_td3_title": "4개 코어/8개 스레드"
                    },
                    {
                      "tr_td1_title": "인텔® 터보 부스트 기술",
                      "tr_td2_title": "인텔® 터보 부스트 기술",
                      "tr_td3_title": "인텔® 터보 부스트 맥스 기술  3.0"
                    },
                    {
                      "tr_td1_title": "8MB 인텔® 스마트 캐시",
                      "tr_td2_title": "12MB 인텔® 스마트 캐시",
                      "tr_td3_title": "12MB 인텔® 스마트 캐시"
                    },
                    {
                      "tr_td1_title": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽",
                      "tr_td2_title": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽",
                      "tr_td3_title": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11세대 인텔® 코어™ i7 스페셜 에디션 프로세서는 울트라 포터블 PC를 위한 고급 콘텐츠 제작, 원활한 게이밍 및 차세대 엔터테인먼트를 제공합니다.",
                "tr_title": "혁신적인 노트북 성능",
                "tr_gen_core_title": "11세대 인텔® 코&#xfeff;어&#xfeff;™ i7 SE 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i7 스페셜 에디션 프로세서"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11세대 인텔® 코어™ i7 스페셜 에디션 프로세서는 울트라 포터블 PC를 위한 고급 콘텐츠 제작, 원활한 게이밍 및 차세대 엔터테인먼트를 제공합니다.",
                "tr_title": "혁신적인 노트북 성능",
                "tr_gen_core_title": "11세대 인텔® 코&#xfeff;어&#xfeff;™ i7 SE 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i7 스페셜 에디션 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i7 스페셜 에디션 프로세서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "빠른 클럭 속도와 높은 코어 수가 원활하게 연동되어 경쟁적인 게이밍과 복잡한 콘텐츠 제작을 마음껏 즐길 수 있습니다.",
                "tr_title": "막강한 파워, 완벽한 균형",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "클럭 속도와 코어 성능의 적절한 균형을 통해 높은 FPS에서 대결하거나 몰입도 높은 AAA 타이틀을 마음껏 즐길 수 있습니다.",
                "tr_title": "게임을 위한 파워",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "까다로운 타이틀에서 높은 FPS로 플레이, 레코딩, 스트리밍을 즐기고 까다로운 멀티태스킹 워크로드로 손쉽게 전환할 수 있습니다.",
                "tr_title": "한계를 뛰어넘는 성능",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "빠른 클럭 속도와 높은 코어 수가 원활하게 연동되어 경쟁적인 게이밍과 복잡한 콘텐츠 제작을 마음껏 즐길 수 있습니다.",
                "tr_title": "막강한 파워, 완벽한 균형",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "여유로운 성능으로 영화 스트리밍에서 프레젠테이션 제작까지 손쉽게 처리할 수 있&#65279;습&#65279;니&#65279;다.",
                "tr_title": "온종일 지속 가능한 배터리 수&#65279;명",
                "tr_gen_core_title": "인텔® Hybrid Technology를 탑재한 인텔® 코어™ i3 프로세서",
                "tr_subTitle2": "인텔® Hybrid Technology를 탑재한 인텔® 코어™ i3 프로세서"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "다목적 성능에 충분한 배터리 수명을 결합하여 언제 어디서나 업무를 수행할 수 있습니다.",
                "tr_title": "온종일 지속 가능한 배터리 수&#65279;명",
                "tr_gen_core_title": "인텔® Hybrid Technology를 탑재한 인텔® 코어™ i3 프로세서",
                "tr_subTitle2": "인텔® Hybrid Technology를 탑재한 인텔® 코어™ i3 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "여러 프로그램 간에 쉽게 전환되어 장소와 관계없이 어떤 작업이든 할 수 있습니다.",
                "tr_title": "이동 중에도 멀티태스킹",
                "tr_gen_core_title": "인텔® Hybrid Technology를 탑재한 인텔® 코어™ i5 프로세서",
                "tr_subTitle2": "인텔® Hybrid Technology를 탑재한 인텔® 코어™ i5 프로세서"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "엔터테인먼트에서 생산성 및 콘텐츠 제작을 비롯한 모든 작업의 수준이 향상됩니다.",
                "tr_title": "더욱 강력한 PC로 업&#65279;그&#65279;레&#65279;이&#65279;드",
                "tr_gen_core_title": "인텔® Hybrid Technology를 탑재한 인텔® 코어™ i5 프로세서",
                "tr_subTitle2": "인텔® Hybrid Technology를 탑재한 인텔® 코어™ i5 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "데스크탑급 게이밍 성능에 이동성을 더하여 높은 FPS에서 벌이는 대결 또는 몰입도 높은 AAA 경험 지원",
                "tr_title": "게임을 위한 파워",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "데스크탑급 게이밍 성능에 이동성을 더하여 높은 FPS에서 벌이는 대결 또는 몰입도 높은 AAA 경험 지원",
                "tr_title": "게임을 위한 파워",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i5 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "높은 FPS에서 게임플레이, 레코딩, 스트리밍을 동시에 수행하고 대용량 멀티태스킹 워크로드로 손쉽게 전환할 수 있는 노트북",
                "tr_title": "한계를 뛰어넘는 성능",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "높은 FPS에서 게임플레이, 레코딩, 스트리밍을 동시에 수행하고 대용량 멀티태스킹 워크로드로 손쉽게 전환할 수 있는 노트북",
                "tr_title": "한계를 뛰어넘는 성능",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i7 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "빠른 클럭 속도, 높은 코어 수, 언락 성능의 원활한 연동으로 PC와 함께 어디서나 마음껏 즐기는 대회 수준의 게이밍과 복잡한 콘텐츠 제작",
                "tr_title": "막강한 파워, 완벽한 균형",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "빠른 클럭 속도, 높은 코어 수, 언락 성능의 원활한 연동으로 PC와 함께 어디서나 마음껏 즐기는 대회 수준의 게이밍과 복잡한 콘텐츠 제작",
                "tr_title": "막강한 파워, 완벽한 균형",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i9 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "빠른 클럭 속도, 높은 코어 수, 언락 성능의 원활한 연동으로 PC와 함께 어디서나 마음껏 즐기는 대회 수준의 게이밍과 복잡한 콘텐츠 제작",
                "tr_title": "언락 파워, 완벽한 균형",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "빠른 클럭 속도, 높은 코어 수, 언락 성능의 원활한 연동으로 PC와 함께 어디서나 마음껏 즐기는 대회 수준의 게이밍과 복잡한 콘텐츠 제작",
                "tr_title": "언락 파워, 완벽한 균형",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i9 프로세서",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "빠른 클럭 속도와 높은 코어 수가 원활하게 연동되어 경쟁적인 게이밍과 복잡한 콘텐츠 제작을 마음껏 즐길 수 있습니다.",
                "tr_title": "막강한 파워, 완벽한 균형",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i9 프로세서",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "빠른 클럭 속도와 높은 코어 수의 원활한 연동으로 복잡한 콘텐츠 제작에서도 어디서나 최고의 PC 성능을 발휘합니다.",
                "tr_title": "막강한 파워, 완벽한 균형",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "빠른 클럭 속도와 높은 코어 수의 원활한 연동으로 복잡한 콘텐츠 제작에서도 어디서나 최고의 PC 성능을 발휘합니다.",
                "tr_title": "막강한 파워, 완벽한 균형",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "최고의 게이밍 경험을 통해 잠재력 발휘",
                "tr_title": "최고 수준에서 경쟁",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 10세대 인텔® 코어™ 프로세서를 알아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "원활한 AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍 및 레코딩",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "고급 콘텐츠 제작",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "최상의 게이밍 성능",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "언락 및 오버클러킹 가능<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.1GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "최고의 게이밍 경험을 통해 잠재력 발휘",
                "tr_title": "최고 수준에서 경쟁",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 10세대 인텔® 코어™ 프로세서를 알아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "원활한 AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "스트리밍 및 레코딩",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "고급 콘텐츠 제작",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "최상의 게이밍 성능",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "언락 및 오버클러킹 가능<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.8GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.1GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 10개<br/>스레드: 20개</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "이동이 잦은 사용자를 위해 특별히 설계된 노트북입니다.",
                "tr_title": "새로운 차원의 노트북을 만나보세요.",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "빠른 클럭 속도와 높은 코어 수가 원활하게 연동되어 경쟁적인 게이밍과 복잡한 콘텐츠 제작을 마음껏 즐길 수 있습니다.",
                "tr_title": "언락 파워, 완벽한 균형",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i9 프로세서",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "빠른 클럭 속도와 높은 코어 수, 언락 퍼포먼스의 원활한 연동으로 복잡한 콘텐츠 제작에서도 어디서나 최고의 PC 성능을 발휘합니다.",
                "tr_title": "언락 파워, 완벽한 균형",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "빠른 클럭 속도와 높은 코어 수의 원활한 연동으로 복잡한 콘텐츠 제작에서도 어디서나 최고의 PC 성능을 발휘합니다.",
                "tr_title": "언락 파워, 완벽한 균형",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "11세대 인텔® 코어™ i9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>인텔® 스마트 캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "가장 까다로운 타이틀에서도 매끄러운 게임 플레이 및 VR",
                "tr_title": "최고의 게이밍이 여기서 시작됩니다",
                "tr_gen_core_title": "9세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 인텔® 코어™ 프로세서를 찾아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "게이밍, 스트리밍, 레코딩을 원하는 대로",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "게이밍을 위한 최고의 9세대 인텔® 코어™ 프로세서",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 4개<br/>스레드: 8개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.6GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.6GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 6개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.9GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 8개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "최대 32% 더 높은 FPS <br/><span class='tr_subtitle_font'>게이밍, 스트리밍, 레코딩 수행 시 - 3년 된 PC 대비<sup>3</sup></span>",
                "tr_title": "가장 근사한 게이밍 순간을 공유할 수 있는 파워",
                "tr_gen_core_title": "9세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 인텔® 코어™ 프로세서를 찾아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "게이밍, 스트리밍, 레코딩을 원하는 대로",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "게이밍을 위한 최고의 9세대 인텔® 코어™ 프로세서",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 4개<br/>스레드: 8개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.6GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.6GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 6개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.9GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 8개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "최대 41% 더 높은 FPS <br/><span class='tr_subtitle_font'>게이밍, 스트리밍 및 레코딩 수행 시 - 3년 된 PC 대비<sup>4</sup></span>",
                "tr_title": "게이머가 요구하면, 인텔® 코어™ i9이 응답합니다.",
                "tr_gen_core_title": "9세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>스레드</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]개 <br/></span><span class='subtitle_font'>코어</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>최대 <br/></span><span class='badgetitle_font'>[XX]<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]MB <br/></span><span class='subtitle_font'>캐시</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "언락형",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "나의 게임 방식에 적합한 인텔® 코어™ 프로세서를 찾아보십시오.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서"
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "대회 수준의 게이밍",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "게이밍, 스트리밍, 레코딩을 원하는 대로",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "게이밍을 위한 최고의 9세대 인텔® 코어™ 프로세서",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.3GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 4개<br/>스레드: 8개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.6GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 12개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.6GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 6개<br/>스레드: 6개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;4.9GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 8개</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>클럭 속도<sup>2</sup>: 최대&nbsp;5GHz</span>",
                      "tr_subTitle": "<span class='small_font'>코어: 8개<br/>스레드: 16개</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "차세대 게이밍을 위한 설계",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                    "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i5 프로세서"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                    "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i7 프로세서"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                    "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i9 프로세서"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>코어</span></span>",
                    "tr_badgePreTitle": "[p_core]개의 Performance-core",
                    "tr_badgeSubTitle": "[e_core]개의 Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>스레드</span></span>",
                    "tr_badgePreTitle": "Performance-core에서 [p_thread]개",
                    "tr_badgeSubTitle": "Efficient-core에서 [e_thread]개"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "크리에이터와 게이머를 위한 강력한 노트북",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                    "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i7 프로세서"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                    "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i9 프로세서"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>코어</span></span>",
                    "tr_badgePreTitle": "[p_core]개의 Performance-core",
                    "tr_badgeSubTitle": "[e_core]개의 Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>스레드</span></span>",
                    "tr_badgePreTitle": "Performance-core에서 [p_thread]개",
                    "tr_badgeSubTitle": "Efficient-core에서 [e_thread]개"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "가장 필요한 순간에 안정적인 성능을 발휘합니다",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서",
                "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i3 프로세서",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "가장 필요한 곳에 향상된 성능 제공",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i5 프로세서",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "가장 필요한 곳에 향상된 성능 제공",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i5 프로세서",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "가장 필요한 곳에 탁월한 성능 제공",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i7 프로세서",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "가장 필요한 곳에 탁월한 성능 제공",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i7 프로세서",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "인텔® Evo™ 노트북은 성능, 신뢰할 수 있는 비디오 채팅을 위한 내장 인텔리전스, 더 빠른 인터넷, 오래가는 배터리 등을 제공합니다.",
                "tr_title": "가장 필요한 곳에 성능 부스트 제공",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i5 프로세서",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "인텔® Evo™ 노트북은 성능, 신뢰할 수 있는 비디오 채팅을 위한 내장 인텔리전스, 더 빠른 인터넷, 오래가는 배터리 등을 제공합니다.",
                "tr_title": "가장 필요한 곳에 탁월한 성능 제공",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i7 프로세서",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "인텔® Evo™ 노트북은 성능, 신뢰할 수 있는 비디오 채팅을 위한 내장 인텔리전스, 더 빠른 인터넷, 오래가는 배터리 등을 제공합니다.",
                "tr_title": "프리미엄 노트북에서 절대&nbsp;성능 발휘",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i9 프로세서",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "인텔® 터보 부스트 기술 2.0 기반 최대 [max_turbo_frequency]GHz",
                "tr_title": "비즈니스를 위한 설계",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i5 프로세서",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "소음 억제 및 배경 흐림 처리",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "가장 자주 수행하는 작업을 우선시하는 자동 조정 성능",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "원활한 고품질 웹 통화 경험",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "즉시 사용할 수 있는 하드웨어 기반 보안",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "원격 장치 관리 기능 제공",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Wi-Fi 성능의 자동 최적화<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "데이터 기반 앱 지원",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "향상된 성능으로 생산성 극대화",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "인텔® 터보 부스트 기술 2.0 기반 최대 [max_turbo_frequency]GHz",
                "tr_title": "비즈니스에 집중할 수 있게 해주는 원활한 성능",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i7 프로세서",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "소음 억제 및 배경 흐림 처리",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "가장 자주 수행하는 작업을 우선시하는 자동 조정 성능",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "원활한 고품질 웹 통화 경험",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "즉시 사용할 수 있는 하드웨어 기반 보안",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "원격 장치 관리 기능 제공",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Wi-Fi 성능의 자동 최적화<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "데이터 기반 앱 지원",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "향상된 성능으로 생산성 극대화",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "인텔® 터보 부스트 기술 2.0 기반 최대 [max_turbo_frequency]GHz",
                "tr_title": "비즈니스를 가속화하세요",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "12세대 인텔® 코어™ i9 프로세서",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "소음 억제 및 배경 흐림 처리",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "가장 자주 수행하는 작업을 우선시하는 자동 조정 성능",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "원활한 고품질 웹 통화 경험",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "즉시 사용할 수 있는 하드웨어 기반 보안",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "원격 장치 관리 기능 제공",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Wi-Fi 성능의 자동 최적화<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "데이터 기반 앱 지원",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "향상된 성능으로 생산성 극대화",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "더 많이 처리하는 안정적인&nbsp;성능",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "더 많이 처리하는 실제&nbsp;성능",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i5 프로세서",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "더 많이 처리하는 실제&nbsp;성능",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i5 프로세서",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "더 많이 처리하는 강력한&nbsp;성능",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i7 프로세서",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "더 많이 처리하는 강력한&nbsp;성능",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i7 프로세서",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "차원이 다른 퍼포먼스",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>코어</span></span>",
                    "tr_badgePreTitle": "[p_core]개의 Performance-core",
                    "tr_badgeSubTitle": "[e_core]개의 Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>스레드</span></span>",
                    "tr_badgePreTitle": "Performance-core에서 [p_thread]개",
                    "tr_badgeSubTitle": "Efficient-core에서 [e_thread]개"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "기존 휴대폰에 원활하게 연결하고<sup>24</sup>, 최고의 Wi-Fi를 지능적으로 선택하며, 오래가는 배터리로 빠르게 충전하십시오.",
                "tr_title": "차원이 다른 퍼포먼스로 어디서나 더 많이 처리",
                "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i5 프로세서",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "기존 휴대폰에 원활하게 연결하고<sup>24</sup>, 최고의 Wi-Fi를 지능적으로 선택하며, 오래가는 배터리로 빠르게 충전하십시오.",
                "tr_title": "차원이 다른 퍼포먼스로 PC에서 한 번에 더 많은 작업을 처리할 수 있습니다.",
                "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i7 프로세서",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "기존 휴대폰에 원활하게 연결하고<sup>24</sup>, 최고의 Wi-Fi를 지능적으로 선택하며, 오래가는 배터리로 빠르게 충전하십시오.",
                "tr_title": "차원이 다른 퍼포먼스로 가장 까다로운 작업을 신속하게 처리할 수 있습니다.",
                "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i9 프로세서",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "차원이 다른 퍼포먼스",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                    "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i5 프로세서",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                    "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i7 프로세서",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                    "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i9 프로세서",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>코어</span></span>",
                    "tr_badgePreTitle": "[p_core]개의 Performance-core",
                    "tr_badgeSubTitle": "[e_core]개의 Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>스레드</span></span>",
                    "tr_badgePreTitle": "Performance-core에서 [p_thread]개",
                    "tr_badgeSubTitle": "Efficient-core에서 [e_thread]개"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "차원이 다른 퍼포먼스",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                    "tr_gen_core_title_uppercase": "인텔® 코어™ i5 프로세서",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                    "tr_gen_core_title_uppercase": "인텔® 코어™ i7 프로세서",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서",
                    "tr_gen_core_title_uppercase": "인텔® 코어™ i9 프로세서",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>코어</span></span>",
                    "tr_badgePreTitle": "[p_core]개의 Performance-core",
                    "tr_badgeSubTitle": "[e_core]개의 Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>스레드</span></span>",
                    "tr_badgePreTitle": "Performance-core에서 [p_thread]개",
                    "tr_badgeSubTitle": "Efficient-core에서 [e_thread]개"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "더 많이 처리하는 안정적인&nbsp;성능",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "인텔® 코어™ i3 프로세서",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "더 많이 처리하는 실제&nbsp;성능",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                "tr_gen_core_title_uppercase": "인텔® 코어™ i5 프로세서",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "더 많이 처리하는 강력한&nbsp;성능",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                "tr_gen_core_title_uppercase": "인텔® 코어™ i7 프로세서",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "차원이 다른 퍼포먼스",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                    "tr_gen_core_title_uppercase": "인텔® 코어™ i5 프로세서",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                    "tr_gen_core_title_uppercase": "인텔® 코어™ i7 프로세서",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서",
                    "tr_gen_core_title_uppercase": "인텔® 코어™ i9 프로세서",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>코어</span></span>",
                    "tr_badgePreTitle": "[p_core]개의 Performance-core",
                    "tr_badgeSubTitle": "[e_core]개의 Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>스레드</span></span>",
                    "tr_badgePreTitle": "Performance-core에서 [p_thread]개",
                    "tr_badgeSubTitle": "Efficient-core에서 [e_thread]개"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ i9 프&#65279;로&#65279;세&#65279;서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "더 많이 처리하는 안정적인&nbsp;성능",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "인텔® 코어™ 3 프로세서",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "인텔® 코어™ 3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "인텔® 코어™ 5 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "인텔® 코어™ 7 프로세서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "더 많이 처리하는 실제&nbsp;성능",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "인텔® 코어™ 5 프로세서",
                "tr_gen_core_title_uppercase": "인텔® 코어™ 5 프로세서",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "인텔® 코어™ 3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "인텔® 코어™ 5 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "인텔® 코어™ 7 프로세서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "더 많이 처리하는 강력한&nbsp;성능",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "인텔® 코어™ 7 프로세서",
                "tr_gen_core_title_uppercase": "인텔® 코어™ 7 프로세서",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "인텔® 코어™ 3 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "인텔® 코어™ 5 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "인텔® 코어™ 7 프로세서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "프로세서",
                "tr_title": "게이밍을 원하는&nbsp;대로",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "인텔® 코어™ Ultra 5 프로세서",
                    "tr_gen_core_title_uppercase": "인텔® 코어™ ULTRA 5 프로세서",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "인텔® 코어™ Ultra 7 프로세서",
                    "tr_gen_core_title_uppercase": "인텔® 코어™ ULTRA 7 프로세서",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "인텔® 코어™ Ultra 9 프로세서",
                    "tr_gen_core_title_uppercase": "인텔® 코어™ ULTRA 9 프로세서",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>코어</span></span>",
                    "tr_badgePreTitle": "[p_core]개의 Performance-core",
                    "tr_badgeSubTitle": "[e_core]개의 Efficient-core",
                    "tr_badgeSubTitle1": "[l_core]개의 LPE 코어"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX]개 <span class='core-text'>스레드</span></span>",
                    "tr_badgePreTitle": "Performance-core에서 [p_thread]개",
                    "tr_badgeSubTitle": "Efficient-core에서 [e_thread]개",
                    "tr_badgeSubTitle1": "LPE 코어에서 [l_thread]개"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ Ultra 5 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ Ultra 7 프로세서",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "인텔® 코어™ Ultra 9 프로세서",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "게이밍을 원하는&nbsp;대로",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "게이밍을 원하는&nbsp;대로",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "게이밍을 원하는&nbsp;대로",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "게이밍을 원하는&nbsp;대로",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "게이밍을 원하는&nbsp;대로",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "게이밍을 원하는&nbsp;대로",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "중소기업에도 대규모 비즈니스 솔루션이 필요합니다.",
                "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i5 프로세서",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "소음 억제 및 배경 흐림 처리",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "가장 자주 수행하는 작업을 우선시하는 자동 조정 성능",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "원활한 고품질 웹 통화 경험",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "곧바로 사용할 수 있는 하드웨어 기반의 보안",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "하드웨어 및 데이터에 대한 물리적 공격 차단",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "중소기업에도 대규모 비즈니스 솔루션이 필요합니다.",
                "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i7 프로세서",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "소음 억제 및 배경 흐림 처리",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "가장 자주 수행하는 작업을 우선시하는 자동 조정 성능",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "원활한 고품질 웹 통화 경험",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "곧바로 사용할 수 있는 하드웨어 기반의 보안",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "하드웨어 및 데이터에 대한 물리적 공격 차단",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "중소기업에도 대규모 비즈니스 솔루션이 필요합니다.",
                "tr_gen_core_title": "13세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i9 프로세서",
                "tr_gen_core_title_uppercase": "13세대 인텔® 코어™ i9 프로세서",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "프로세서 비교 결과",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "현재 기기",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "소음 억제 및 배경 흐림 처리",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "가장 자주 수행하는 작업을 우선시하는 자동 조정 성능",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "원활한 고품질 웹 통화 경험",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "곧바로 사용할 수 있는 하드웨어 기반의 보안",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "하드웨어 및 데이터에 대한 물리적 공격 차단",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "비즈니스를 위해 새로워진 AI 경험을 누려보십시오.",
                "tr_title": "인텔® 코어™ Ultra&nbsp;[XX] 프로세서와 인텔® vPro®와 함께 미래를 준비하십시오.",
                "tr_gen_core_title": "인텔® 코어™ Ultra [XX] 프로세서",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "인텔의 AI PC로 비즈니스 성장을 촉진하십시오.",
                    "tr_badgeSubTitle": "뛰어난 경험을 제공하는 강력한 PC는 비즈니스 생산성의 핵심입니다. 적시에 적합한 작업을 적합한 엔진에 전송함으로써 다양하고 복잡한 비즈니스 워크로드에 뛰어난 전력 효율적인 성능을 제공하는 새로운 아키텍처 덕분에 인텔® vPro®는 워크로드와 응용 프로그램 전반에서 직원 효율성과 성능을 향상합니다.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "즉시 사용 가능한 다계층 보안 기능",
                    "tr_badgeSubTitle": "새로운 위협에는 보안에 대한 포괄적인 접근 방식이 필요합니다. 인&#65279;텔&#65279;®&nbsp;vPro&#65279;®&#65279;는 하드웨어 수준과 스택 전반에서 보호 기능을 제공하여 비즈니스를 더욱 잘 보호합니다. AI 기반 위협 탐지 기능을 포함한 이러한 고유한 기본 제공 보안 조치는 사용자, 데이터 및 비즈니스를 보호하는 데 도움이 됩니다.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "거의 모든 곳에서 사용자의 생산성을 유지하는 기본 제공 도구",
                    "tr_badgeSubTitle": "사무실은 어디에나 있으며 PC도 마찬가지입니다. 인텔® vPro®가 제공하는 하드웨어 기반의 최신 관리 기능을 통해 기업은 PC 자산 관리의 부담을 덜고, 지원을 단순화하며, 최종 사용자 경험을 개선하는 동시에 지속 가능한 컴퓨팅 활동을 지원할 수 있습니다.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "AI PC. 무한한 가능성을 열다.",
                "tr_subtitle1": "인텔® 코어™ ULTRA 프로세서(시리즈 2) 탑재",
                "tr_subtitle2": "인텔 AI PC에서 가능합니다",
                "tr_subtitle": "인텔의 가장 효율적이고 안전한 고성능 모바일 프로세서<sup>39</sup>",
                "tr_gen_core_title": "인텔®&nbsp;코어™ Ultra&nbsp;5 프로세서(시리즈&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "AI 툴을 사용하여 <span class='spec-heading'>더 크리에이티브하게</span> 더 빠르게 제작"
                  },
                  {
                    "tr_title": "시간을 절약해 주는 AI 어시스턴스로 <span class='spec-heading'>더 효율적으로</span>"
                  },
                  {
                    "tr_title": "내장 데이터 보호로 <span class='spec-heading'>더 안전하게</span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "AI PC. 무한한 가능성을 열다.",
                "tr_subtitle1": "인텔® 코어™ ULTRA 프로세서(시리즈 2) 탑재",
                "tr_subtitle2": "인텔 AI PC에서 가능합니다",
                "tr_subtitle": "인텔의 가장 효율적이고 안전한 고성능 모바일 프로세서<sup>39</sup>",
                "tr_gen_core_title": "인텔®&nbsp;코어™ Ultra&nbsp;7 프로세서(시리즈&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "AI 툴을 사용하여 <span class='spec-heading'>더 크리에이티브하게</span> 더 빠르게 제작"
                  },
                  {
                    "tr_title": "시간을 절약해 주는 AI 어시스턴스로 <span class='spec-heading'>더 효율적으로</span>"
                  },
                  {
                    "tr_title": "내장 데이터 보호로 <span class='spec-heading'>더 안전하게</span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "AI PC. 무한한 가능성을 열다.",
                "tr_subtitle1": "인텔® 코어™ ULTRA 프로세서(시리즈 2) 탑재",
                "tr_subtitle2": "인텔 AI PC에서 가능합니다",
                "tr_subtitle": "인텔의 가장 효율적이고 안전한 고성능 모바일 프로세서<sup>39</sup>",
                "tr_gen_core_title": "인텔®&nbsp;코어™ Ultra&nbsp;5 프로세서(시리즈&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "AI PC. 무한한 가능성을 열다.",
                "tr_subtitle1": "인텔® 코어™ ULTRA 프로세서(시리즈 2) 탑재",
                "tr_subtitle2": "인텔 AI PC에서 가능합니다",
                "tr_subtitle": "인텔의 가장 효율적이고 안전한 고성능 모바일 프로세서<sup>39</sup>",
                "tr_gen_core_title": "인텔®&nbsp;코어™ Ultra&nbsp;7 프로세서(시리즈&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_tileTitle1": "전용 그래픽",
                "tr_title": "그래픽 처리 장치란 무엇일까요?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "그래픽 처리 장치(GPU)는 화면에 보이는 이미지를 만들어냅니다.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "통합 GPU",
                    "tr_badgeSubTitle": "통합형 GPU가 컴퓨터의 나머지 부분과 같은 RAM을 사용하므로 에너지 효율성이 높아집니다.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "별도 GPU",
                    "tr_badgeSubTitle": "탁월한 그래픽을 제공하는 자체 RAM과 리소스를 가진 가장 강력한 GPU 유형입니다.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "하이브리드 GPU",
                    "tr_badgeSubTitle": "탁월한 그래픽을 제공하는 자체 RAM과 리소스를 가진 가장 강력한 GPU 유형입니다.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "간소화된 사진 및 비디오 편집",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "4K 비디오 콘텐츠 시청",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "뛰어난 디테일로 인기 게임 플레이",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "듀얼 GPU",
                    "tr_badgeSubTitle": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽을 탑재한 일부 시스템은 프로세서 내장 GPU 및 별도 GPU를 모두 활용하여 그래픽 워크로드를 분배할 수 있습니다.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "통합 GPU",
                    "tr_badgeSubTitle": "통합 GPU는 주 프로세서 또는 CPU와 같은 칩에 내장됩니다. 이러한 유형의 GPU는 컴퓨터의 다른 부분과 같은 RAM과 기타 리소스를 사&#65279;용&#65279;하&#65279;므&#65279;로 에너지 효율이 더 높으며 더 얇고 가벼운 기기를 만들 수 있&#65279;습&#65279;니&#65279;다&#65279;.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "전용 GPU",
                    "tr_badgeSubTitle": "탁월한 그래픽을 제공하는 자체 RAM과 리소스를 가진 가장 강력한 GPU 유형입니다.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "표준",
                    "tr_badgeSubTitle": "최대 4GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "대회 수준",
                    "tr_badgeSubTitle": "최대 8GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "울트라급",
                    "tr_badgeSubTitle": "8GB 이상의 VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "강력한 그래픽 성능",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "가장 강력한 그래픽 처리에는 아름답고 선명한 이미지와 매끄러운 애니메이션을 구현하는 데 중점을 둔 그래픽 카드가 필요합니다.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "풍부하고 생동감 넘치는 그&#65279;래&#65279;픽 내장",
                "tr_pretitle": "인텔® Iris® Plus 그래픽",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "인텔® Iris® Plus 그래픽을 사용하면 추가 그래픽 카드 없이 4K HDR 비디오와 풀 HD 게이밍에 몰입할 수 있습니다.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "놀라운 비주얼, 손쉬운 성능",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "인텔® 그래픽은 혁신적인 통합 그래픽 성능을 제공하여 크리에이터를 위한 유연한 설계와 풍부한 게이밍 경험을 지원합니다.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "인텔® Iris® Pro 그래픽",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "X<sup class='small_text_sup_xe'>e</sup> 아키텍처 기반 인텔® UHD 그래픽",
                "tr_pretitle": "몰입도 높은 시각적 경험",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "간소화된 사진 및 비디오 편집을 경험하고, 프리미엄 비디오 콘텐츠를 4K로 시청하고, 인기 게임을 놀라운 디테일로 플레이하십시오.",
                "tr_photo_text": "간소화된 사진 및 비디오 편집",
                "tr_video_text": "4K 비디오 콘텐츠 시청",
                "tr_game_text": "뛰어난 디테일로 인기 게임 플레이",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "멋진 그래픽 그 이상",
                "tr_subtitle": "그래픽 카드(또는 GPU)는 프로세서(또는 CPU)와 긴밀하게 작동하여 게임이 실행되는 방식과 게임의 그래픽을 결정합니다. 그래픽 생성 전용 RAM인 VRAM(Video RAM)을 통해 GPU의 수준을 빠르게 파악할 수 있습니다.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "강렬한 게이밍 그래픽을 위해",
                "tr_subtitle": "가장 강력한 그래픽 처리에는 아름답고 생생한 이미지와 매끄러운 애니메이션을 만드는 데만 집중할 수 있는 별도의 그래픽 카드가 필요합니다",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "강렬한 게이밍 그래픽을 위해",
                "tr_subtitle": "가장 강력한 그래픽 처리에는 아름답고 생생한 이미지와 매끄러운 애니메이션을 만드는 데만 집중할 수 있는 별도의 그래픽 카드가 필요합니다",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "멋진 그래픽 그 이상",
                "tr_subtitle": "그래픽 카드(또는 GPU)는 프로세서(또는 CPU)와 긴밀하게 작동하여 게임이 실행되는 방식과 게임의 그래픽을 결정합니다. 그래픽 생성 전용 RAM인 VRAM(Video RAM)을 통해 GPU의 수준을 빠르게 파악할 수 있습니다.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "강력한 그래픽 성능",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "가장 강력한 그래픽 처리에는 아름답고 선명한 이미지와 매끄러운 애니메이션을 구현하는 데 중점을 둔 그래픽 카드가 필요합니다.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "강렬한 게이밍 그래픽을 위해",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "가장 강력한 그래픽 처리에는 아름답고 선명한 이미지와 매끄러운 애니메이션을 구현하는 데 중점을 둔 그래픽 카드가 필요합니다.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "가장 강력한 그래픽 처리에는 아름답고 생생한 이미지와 매끄러운 애니메이션을 만드는 데만 집중할 수 있는 별도의 그래픽 카드가 필요합니다",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "게임의 최소 요구 사항을 충족하는 충분한 그래픽 파워를 제공합니다.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "대회 수준의 게이밍을 요구하는 하이엔드&nbsp;플레이어용",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "PC를 까다로운 콘텐츠 제작 용도로도 사용하는 고급 게이머용",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "생생한 비디오 및 몰입형 게&#65279;이&#65279;밍",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "인텔® HD 그래픽으로 고해상도 화면의 활용도를 극대화하고 풍부한 멀티미디어 경험을 즐기고 내장된 그래픽 처리 기능을 활용하세요.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "생생한 비디오 및 이미지 구현",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "섬세한 디테일과 풍부한 색상으로 비디오 스트리밍 및 사진 감상",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "놀라운 제작 및 게이밍",
                "tr_pretitle": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "몰임감 높은 엔터테인먼트 및 고급 콘텐츠 제작에서 메인스트림 게이밍까지 아우르는 인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽은 thin & light PC에 혁신적인 그래픽 성능을 제공합니다.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_pretitle": "강렬한 게이밍 그래픽을 위해",
                "tr_title": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> 그래픽",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "가장 강력한 그래픽 처리에는 아름답고 생생한 이미지와 매끄러운 애니메이션을 만드는 데만 집중할 수 있는 별도의 그래픽 카드가 필요합니다",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x]GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "창의력을 맘껏 발휘하세요",
                "tr_pretitle": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX 그래픽",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "프로세서 내장 그래픽과 최초의 별도 인텔® 그래픽 엔진의 성능을 결합하여 창작 프로젝트를 원활하게 진행하면서 다양한 게임에 몰입할 수 있습니다.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "tr_title": "인텔® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX 그래픽",
                "tr_pretitle": "강렬한 게이밍 그래픽을 위해",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "가장 강력한 그래픽 처리에는 아름답고 생생한 이미지와 매끄러운 애니메이션을 만드는 데만 집중할 수 있는 별도의 그래픽 카드가 필요합니다",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x]GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "그래픽",
                "tr_title": "아름다운 그래픽 내장",
                "tr_subtitle": "놀라운 디테일과 선명도로 게임, 영화 및 웹 비&#65279;디&#65279;오&#65279;를 즐기십시오.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "그래픽",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "그래픽",
                    "tr_specText": "워크플로를 강화하십시오"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "그래픽",
                    "tr_specText": "게이밍 강화하기"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "그래픽",
                    "tr_specText": "게이밍 강화하기"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "그래픽",
                    "tr_specText": "한 단계 높은 게임으로 레벨업"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "창의력을 맘껏 발휘하세요",
                    "tr_specText": "<strong>업계 최초 AV1 하드웨어 인코딩</strong>과 AI 가속 콘텐츠 제작 등 가장 포괄적인 미디어 기능으로 어디에서나 생산성을 가속할 수 있습니다.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "창의력을 맘껏 발휘하세요",
                    "tr_specText": "<strong>업계 최초 AV1 하드웨어 인코딩</strong>과 AI 가속 콘텐츠 제작 등 가장 포괄적인 미디어 기능으로 어디에서나 생산성을 가속할 수 있습니다.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "창의력을 맘껏 발휘하세요",
                    "tr_specText": "<strong>업계 최초 AV1 하드웨어 인코딩</strong>과 고급 <strong>X<sup>e</sup> 미디어 엔진</strong>으로 첨단 AI 가속 콘텐츠 제작을 경험해 보십시오.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "창의력을 맘껏 발휘하세요",
                    "tr_specText": "<strong>업계 최초 AV1 하드웨어 인코딩</strong>과 고급 <strong>X<sup>e</sup> 미디어 엔진</strong>으로 첨단 AI 가속 콘텐츠 제작을 경험해 보십시오.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "창의력을 맘껏 발휘하세요",
                    "tr_specText": "<strong>업계 최초 AV1 하드웨어 인코딩</strong>과 고급 <strong>X<sup>e</sup> 미디어 엔진</strong>으로 첨단 AI 가속 콘텐츠 제작을 경험해 보십시오.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "이동 중에도 성능 유지",
                    "tr_specText": "현대적인 게이밍 기능을 통해 강력한 성능과 고품질 이미지를 확보하고 이동 중에도 최신 게임을 플레이하십시오. <br/><br/><strong>X<sup>e</sup>SS(X<sup>e</sup> Super Sampling):  </strong>향상된 성능과 유연한 게이밍을 위한 인텔® AI 강화 업스케일링 기술입니다. <br/><br/><strong>DirectX* 12 Ultimate 지원:  </strong>광선 추적 및 가변 속도 음영(VRS)과 같은 그래픽 기술로 게이밍을 새로운 차원의 현실감으로 끌어 올립니다."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "완벽한 게임 컨트롤",
                    "tr_specText": "<strong>인텔® Arc™ 제어 센터</strong>를 통해 직관적이고 모던한 레이아웃을 사용하여 경험을 조정할 수 있습니다. 원활하게 드라이버 업데이트를 받고 게임을 최적화하십시오."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "완벽한 게임 컨트롤",
                    "tr_specText": "<strong>인텔® Arc™ 제어 센터</strong>를 통해 직관적이고 모던한 레이아웃을 사용하여 경험을 조정할 수 있습니다. 원활하게 드라이버 업데이트를 받고 게임을 최적화하십시오."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "완벽한 게임 컨트롤",
                    "tr_specText": "<strong>인텔® Arc™ 제어 센터</strong> 소프트웨어를 통해 원활하게 드라이버 업데이트를 받고 좋아하는 게임을 최적화하십시오."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "인텔® 플랫폼을 극대화하십시오",
                    "tr_specText": "호환되는 인텔® 코어™ 프로세서와의 페어링을 통해 <strong>인텔® Deep Link 기술</strong>을 활용하여 제작, 게이밍 및 스트리밍 경험을 향상하십시오."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "인텔® 시스템을 극대화하십시오",
                    "tr_specText": "호환되는 인텔® 코어™ 프로세서와의 페어링을 통해 <strong>인텔® Deep Link 기술</strong>을 활용하여 제작, 게이밍 및 스트리밍 경험을 향상하십시오."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "인텔® 플랫폼을 극대화하십시오",
                    "tr_specText": "호환되는 인텔® 코어™ 프로세서와의 페어링을 통해 <strong>인텔® Deep Link 기술</strong>을 활용하여 제작, 게이밍 및 스트리밍 경험을 향상하십시오."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "인텔® 플랫폼을 극대화하십시오",
                    "tr_specText": "호환되는 인텔® 코어™ 프로세서와의 페어링을 통해 <strong>인텔® Deep Link 기술</strong>을 활용하여 제작, 게이밍 및 스트리밍 경험을 향상하십시오."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "인텔® 시스템을 극대화하십시오",
                    "tr_specText": "호환되는 인텔® 코어™ 프로세서와의 페어링을 통해 <strong>인텔® Deep Link 기술</strong>을 활용하여 제작, 게이밍 및 스트리밍 경험을 향상하십시오."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "스포트라이트를 한&nbsp;몸에",
                    "tr_specText": "<strong>인텔® Arc™ 제어 센터</strong> 소프트웨어가 지원하는 고급 스트리밍 기술로 원활하게 방송하십시오."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "스포트라이트를 한&nbsp;몸에",
                    "tr_specText": "<strong>인텔® Arc™ 제어 센터</strong> 소프트웨어가 지원하는 고급 스트리밍 기술로 원활하게 방송하십시오."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "몰입감 높은 게이밍을 경험하십시오",
                    "tr_specText": "고해상도 및 높은 재생률로 최신 AAA 게임에서 가장 중요한 최고의 성능을 발휘하십시오. <br/><br/><strong>X<sup>e</sup>SS(X<sup>e</sup> Super Sampling):  </strong>향상된 성능과 유연한 게이밍을 위한 인텔® AI 강화 업스케일링 기술입니다. <br/><br/><strong>DirectX* 12 Ultimate 지원:  </strong>광선 추적 및 가변 속도 음영(VRS)과 같은 그래픽 기술로 게이밍을 새로운 차원의 현실감으로 끌어 올립니다."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "몰입감 높은 게이밍을 경험하십시오",
                    "tr_specText": "고해상도 및 높은 재생률로 최신 AAA 게임에서 가장 중요한 최고의 성능을 발휘하십시오. <br/><br/><strong>X<sup>e</sup>SS(X<sup>e</sup> Super Sampling):  </strong>향상된 성능과 유연한 게이밍을 위한 인텔® AI 강화 업스케일링 기술입니다. <br/><br/><strong>DirectX* 12 Ultimate 지원:  </strong>광선 추적 및 가변 속도 음영(VRS)과 같은 그래픽 기술로 게이밍을 새로운 차원의 현실감으로 끌어 올립니다."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "더 뛰어난 게이밍 성능을 경험하십시오",
                    "tr_specText": "한 차원 더 높은 게이밍 경험: <br/><br/><strong>X<sup>e</sup>SS(X<sup>e</sup> Super Sampling):  </strong>향상된 성능과 유연한 게이밍을 위한 인텔® AI 강화 업스케일링 기술입니다. <br/><br/><strong>DirectX* 12 Ultimate 지원:  </strong>광선 추적 및 가변 속도 음영(VRS)과 같은 그래픽 기술로 게이밍을 새로운 차원의 현실감으로 끌어 올립니다."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "향상된 초고속 게이밍을 경험하세요",
                    "tr_specText": "수많은 인기 AAA 게임을 원활하게 즐기고, 격렬한 e스포츠 슈팅 게임과 빠른 속도감의 RTS 히트작을 아우르는 고주사율 게임을 즐기십시오. <br/><br/><strong>X<sup>e</sup>SS(X<sup>e</sup> Super Sampling):  </strong>향상된 성능과 유연한 게이밍을 위한 인텔® AI 강화 업스케일링 기술입니다. <br/><br/><strong>DirectX* 12 Ultimate 지원: </strong> 광선 추적 및 가변 속도 음영(VRS)과 같은 그래픽 기술로 게이밍을 새로운 차원의 현실감으로 끌어 올립니다."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "몰입감 높은 게이밍을 경험하십시오",
                    "tr_specText": "고해상도 및 높은 재생률로 최신 AAA 게임에서 가장 중요한 최고의 성능을 발휘하십시오. <br/><br/><strong>X<sup>e</sup>SS(X<sup>e</sup> Super Sampling):  </strong>향상된 성능과 유연한 게이밍을 위한 인텔® AI 강화 업스케일링 기술입니다. <br/><br/><strong>DirectX* 12 Ultimate 지원:  </strong>광선 추적 및 가변 속도 음영(VRS)과 같은 그래픽 기술로 게이밍을 새로운 차원의 현실감으로 끌어 올립니다."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "그래픽",
                    "tr_specText": "모두를 위한 새로운 그래픽의 세계"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "프레임을 가속하고 게임을 가속하십시오",
                    "tr_specText": "인텔® X<sup class='small_text_sup_xe'>e</sup> Super Sampling 기술(X<sup class='small_text_sup_xe'>e</sup>SS)은 AI 강화 업스케일링을 통해 게임 수준을 한 단계 끌어올려 높은 이미지 충실도로 최고의 성능을 제공합니다. X<sup class='small_text_sup_xe'>e</sup>SS는 인텔® Arc™ 그래픽 제품에 최적화되어 있으므로 인텔® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions(인텔® XMX) 하드웨어 가속을 최대한 활용할 수 있습니다."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "궁극의 비주얼을 만나보십시오",
                    "tr_specText": "모든 인텔® Arc™ 그래픽에서 DirectX* 12 Ultimate를 완벽하게 지원하므로 차세대 게이밍의 기본인 광선 추적, 가변 속도 음영, 메시 음영, 샘플러 피드백 등의 최신 그래픽 기술로 놀랍고 몰입감 넘치는 게임 플레이를 즐길 수 있습니다."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "원활한 비디오, 사진 및 미디어 제작",
                    "tr_specText": "AI로 강화되고 인텔® 기술로 가속화된 첨단 미디어 엔진을 사용하고 풍부한 디지털 콘텐츠를 제작하며 상상력을 맘껏 펼치고 오디언스를 사로잡으십시오. 고급 AV1 비디오 인코딩 기능을 통해 최신 성능을 유지하는 동시에, 현재의 모든 주요 미디어 형식을 지원하는 그래픽 기반으로 멋진 콘텐츠를 제작합니다."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "최첨단 AI 제작과 게이밍",
                    "tr_specText": "인텔® Arc™ 그래픽은 고급 제작에서 몰입감 넘치는 게이밍까지 강력한 AI 기능을 제공합니다. 텍스트로 이미지 생성, AI 가속 비디오 편집 등 오늘날의 고급 제작 소프트웨어를 최대한 활용하십시오. 또한, AI 가속 X<sup class='small_text_sup_xe'>e</sup>SS 업스케일링을 통해 고해상도 게임 플레이를 즐기십시오."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "그래픽",
                    "tr_specText": "본격 시작되는 전문적인 그래픽"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "이동 중에 작업하는 크리에이티브, 디자이너 및 엔지니어에 적합",
                    "tr_specText": "일부 인텔® 코어™ Ultra 프로세서 H-시리즈에서 사용 가능한 내장 인텔® Arc™ Pro 그래픽은 독립 소프트웨어 공급업체(ISV) 인증으로 그래픽 성능을 2배 향상하여 신뢰성을 높이고 이동 중에도 놀라운 콘텐츠를 제작할 수 있도록 효율적인 AI 및 광선 추적 하드웨어 가속을 제공합니다.<br/><br/><span class='expandable_heading'>소프트웨어 인증<br/></span><br/>인텔은 수년 동안 수백 곳의 소프트웨어 기업과 협력해 왔으며, 인텔의 탁월한 경험은 인텔® Arc™ Pro A-시리즈 그래픽 카드에 그대로 담겨 있습니다. 인증은 여러분에게 중요한 만큼 당사에도 중요합니다."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "인공 지능의 실현",
                    "tr_specText": "이 GPU 제품군에는 필요한 전용 AI 가속 기능이 내장된 호환 AI 툴에 대한 지원을 포함하여 다양하고 우수한 기술이 포함되어 있습니다."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "프리미엄 없는 광선 추적",
                    "tr_specText": "그래픽 카드는 단순히 픽셀을 디스플레이에 표시하는 것 이상으로 아름다운 이미지의 생성을 가속해야 합니다. 인텔® Arc™ Pro A-시리즈 그래픽에 내장된 광선 추적 하드웨어 기술을 통해 호환되는 소프트웨어에서 새로운 전문 워크플로를 탐색할 수 있습니다. 광선 추적은 일반적으로 고가의 그래픽에서만 사용할 수 있다고 생각하는 경우가 많지만, 인텔의 최신 전문 그래픽 제품군은 그러한 예상을 벗어납니다."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "몰입감 넘치는 디스플레이로 크리에이터 역량 강화",
                    "tr_specText": "모든 인텔® Arc™ 그래픽에서 DirectX* 12 Ultimate를 완벽하게 지원하므로 차세대 게이밍의 기본인 광선 추적, 가변 속도 음영, 메시 음영, 샘플러 피드백 등의 최신 그래픽 기술로 놀랍고 몰입감 넘치는 게임 플레이를 즐길 수 있습니다."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "동적으로 최적화된 이미지",
                    "tr_specText": "Pro GPU의 전용 하드웨어 디코딩을 통해 Dolby Vision*은 호환되는 디스플레이에 더 깊이 있고 선명한 명암과 더 많은 색상을 추가할 수 있도록 지원합니다. Dolby Vision*은 서비스, 디스플레이 장치 및 플랫폼을 기반으로 이미지 품질을 동적으로 최적화하여 매번 놀라운 비주얼을 제공함으로써 HDR 기술의 잠재력을 최대한 끌어냅니다."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "전문적으로 검증된 소프트웨어",
                    "tr_specText": "인텔은 수년 동안 수백 곳의 소프트웨어 기업과 협력해 왔으며, 인텔의 탁월한 경험은 전문 인텔® Arc™ Pro A-시리즈 그래픽 제품군에 그대로 담겨 있습니다. 인증 및 소프트웨어 워크플로 검증 작업은 여러분에게 중요한 만큼 당사에도 중요합니다."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "프리미엄 없는 광선 추적",
                    "tr_specText": "그래픽 카드는 단순히 픽셀을 디스플레이에 표시하는 것 이상으로 아름다운 이미지의 생성을 가속해야 합니다. 인텔® Arc™ Pro A-시리즈 그래픽에 내장된 광선 추적 하드웨어 기술을 통해 호환되는 소프트웨어에서 새로운 전문 워크플로를 탐색할 수 있습니다. 광선 추적은 일반적으로 고가의 그래픽에서만 사용할 수 있다고 생각하는 경우가 많지만, 인텔의 최신 전문 그래픽 제품군은 그러한 예상을 벗어납니다."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "소형 모바일 GPU 하나로 여러 대형 디스플레이 지원",
                    "tr_specText": "노트북용 인텔® Arc™ Pro 그래픽은 워크플로 효율성을 향상하기 위해 최대 4개의 초대형 디스플레이를 지원할 수 있습니다. 높은 동적 범위(HDR)를 추가하면 소셜 미디어에 적합한 차세대 가정용 또는 사무실용 멀티 디스플레이 설정에 필요한 모든 것을 얻을 수 있습니다."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "전체 메모리 대역폭 잠재력",
                    "tr_specText": "그래픽 카드의 대역폭은 워크스테이션에서 중요한 고려 사항입니다. 잘못하면 전문 워크플로가 느려질 수 있지만, 인텔® Arc™ Pro A-시리즈 GPU는 역호환성을 유지하면서 최신 PCIe* 4.0을 지원합니다. 높은 대역폭 속도로 그래픽 메모리를 개선함으로써 소프트웨어에서 프로젝트 데이터에 더 빠르게 액세스할 수 있습니다."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "현실보다 더 현실 같은 프레임",
                    "tr_specText": "<span class='expandable_heading'>AI 부스트. 인텔의 지원으로.<br/></span><br/>인텔® X<sup class='small_text_sup_xe'>e</sup>SS는 AI로 강화된 업스케일링 기술로 모든 프레임의 아름다움을 그대로 유지하면서 성능을 향상하여 게임플레이를 의도된 대로 경험할 수 있습니다."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "실시간으로 경험하는.<br/> 생생한 몰입감.<br/> 최고의 현실감.",
                    "tr_specText": "더 현실적인 세계가 기다립니다. 내장된 광선 추적을 사용하면 실물과 같은 그림자와 반사부터 뛰어난 조명까지 모든 것을 경험할 수 있습니다. 다시 말해, 놓치고 싶지 않은 시각적 게이밍 경험입니다."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "최선의 방식으로 공유하는 최고의 게임 플레이",
                    "tr_specText": "오디언스에게 볼 만한 가치가 있는 원활한 게임 플레이를 제공하십시오. 인텔® Arc™ 그래픽의 하드웨어 가속 AV1 인코딩 기능을 통해 사용자의 모든 움직임에 매료될 것입니다. 같은 비트 전송률에서 시각적 품질이 더 높다는 것은 대역폭을 절약하면서 최고의 스트리밍을 제공하는 것과 같습니다."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "모든 크리에이터를 환영합니다.",
                    "tr_specText": "새롭고 향상된 AI 경험을 손쉽게 이용할 수 있습니다. 인텔® Arc™ A-시리즈 그래픽에 내장된 고급 AI 가속기를 사용하면 다양한 응용 프로그램에서 기다리는 시간을 줄이고 창작에 더 많은 시간을 할애할 수 있습니다.<br/><br/>인텔® Arc™ 그래픽에 내장된 고급 AV1 하드웨어 기능을 통해 스토리지 효율성이 높은 고품질 AV1 비디오로 비디오 제작을 한 단계 더 끌어올리고 앞서나가십시오."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "운영 체제",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "작업을 완료하는 데 필요한 모든 기능 제공",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "내구성이 뛰어난 초경량/초박형의 Windows 10 PC에서는 작업을 완료하는 데 필요한 모든 기능을 활용할 수 있습니다. 오랫동안 사용 가능한 배터리, 멋진 터치 스크린*, 최신 SSD(반도체 드라이브) 등이 적용된 Windows 10 PC에서는 원하는 모든 기능이 제공됩니다.",
                "tr_noteSection": "*기능은 하드웨어에 따라 다릅니다.",
                "titleArray": [
                  {
                    "tr_title": "더욱 길어진 배터리 사용 시간",
                    "tr_subtitle": "현재 시판 중인 PC는 대개 이전보다 전력을 더 적게 사용하므로 배터리를 더 오래 사용할 수 있습니다. 예를 들어 평균 비디오 재생 시간은 8시간 이상입니다.**",
                    "tr_noteSection": "**배터리 사용 시간은 장치, 설정, 사용량 및 기타 요인에 따라 크게 달라집니다."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "운영 체제",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "작업을 완료하는 데 필요한 모든 기능 제공",
                "tr_title": "Windows* 10",
                "tr_subtitle": "내구성이 뛰어난 초경량/초박형의 Windows 10 PC에서는 작업을 완료하는 데 필요한 모든 기능을 활용할 수 있습니다. 오랫동안 사용 가능한 배터리, 멋진 터치 스크린*, 최신 SSD(반도체 드라이브) 등이 적용된 Windows 10 PC에서는 원하는 모든 기능이 제공됩니다.",
                "tr_noteSection": "*기능은 하드웨어에 따라 다릅니다.",
                "titleArray": [
                  {
                    "tr_title": "더욱 길어진 배터리 사용 시간",
                    "tr_subtitle": "현재 시판 중인 PC는 대개 이전보다 전력을 더 적게 사용하므로 배터리를 더 오래 사용할 수 있습니다. 예를 들어 평균 비디오 재생 시간은 8시간 이상입니다.**",
                    "tr_noteSection": "**배터리 사용 시간은 장치, 설정, 사용량 및 기타 요인에 따라 크게 달라집니다."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "운영 체제",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "안전과 성능을 위한 간소화",
                "tr_pretitle": "S 모드의 Windows* 10",
                "tr_subtitle": "S 모드에서 Windows* 10을 실행하면 추가 보호 계층이 제공되어 안전한 장치에서 안심하고 작업, 플레이 및 탐색할 수 있습니다."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "운영 체제",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "안전과 성능을 위한 간소화",
                "tr_title": "S 모드의 Windows* 10",
                "tr_subtitle": "S 모드에서 Windows* 10을 실행하면 추가 보호 계층이 제공되어 안전한 장치에서 안심하고 작업, 플레이 및 탐색할 수 있습니다."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "운영 체제",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "위대한 작업의 시작",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "성능, 가격대를 비롯한 다양한 선택지가 존재합니다. 혁신적인 2-in-1에서 얇고 가벼운 노트북, 터치스크린, 탈부착식 키보드까지 모든 예산과 스타일에 맞는 Windows* PC를 찾을 수 있습니다."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "운영 체제",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "위대한 작업의 시작",
                "tr_title": "Windows*",
                "tr_subtitle": "성능, 가격대를 비롯한 다양한 선택지가 존재합니다. 혁신적인 2-in-1에서 얇고 가벼운 노트북, 터치스크린, 탈부착식 키보드까지 모든 예산과 스타일에 맞는 Windows* PC를 찾을 수 있습니다."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "운영 체제",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "운영 체제",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "메모리",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "어느 정도의 RAM이 필요하십니까?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "일상 - 2GB~최대 8GB RAM",
                    "tr_badgeSubTitle": "주로 워드 프로세싱 및 웹 서핑을 위해 PC를 사용하고 한 번에 하나의 프로그램에만 집중하는 사용자에게 이상적입니다."
                  },
                  {
                    "tr_badgeTitle": "강력 - 8GB~최대 16GB RAM",
                    "tr_badgeSubTitle": "RAM이 더 크면 애플리케이션이 더&nbsp;빠르게 실행되고 더 많은 애플리케이션을 동시에 실행할 수 있습니다."
                  },
                  {
                    "tr_badgeTitle": "고급 - 16GB RAM 이상",
                    "tr_badgeSubTitle": "게임이나 사진 또는 비디오 편집, 3D 모델링에 관심이 있다면 이러한 집중 프로그램을 처리하기 위해 추가 RAM이 필요합니다."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "메모리",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "일상적인 멀티태스킹을 위한 [XX]GB RAM",
                "tr_subtitle": "메모리는 워드 프로세싱이나 웹 탐색과 같은 기본적인 애플리케이션이 매끄럽게 로드되고 실행될 수 있도록 도와줍니다.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "메모리",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "강력한 멀티태스킹을 위한 [XX]GB RAM",
                "tr_subtitle": "동시에 여러 개의 프로그램을 사용하는 경우, [memory_total]GB의 메모리를 사용하면 프로그램 간 전환이 한결 쉬워집니다.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "메모리",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "고급 멀티태스킹을 위한 [XX]GB RAM",
                "tr_subtitle": "PC의 속도 저하 없이 동시에 여러 개의 고사양 프로그램을 실행할 수 있는 메모리가 제공됩니다.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "메모리",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1]GB 메모리 = [Value2]GB 인텔® Optane™ 메모리 + [Value3]GB RAM",
                "tr_subtitle": "이러한 메모리 조합 솔루션은 인텔® Optane™ 메모리로 하드 드라이브의 성능을 가속하고 RAM의 응답 속도를 높여줍니다.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]GB<br/></span><span class='subtitle-value'>메모리</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "인텔® Optane™ 메모리",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "대기 시간은 짧게. 좋아하는 콘텐츠는 더 많이.",
                "tr_subtitle": "인텔® Optane™ 메모리가 시스템 메모리와 함께 작동함으로써 자주 사용하는 프로그램에 빠르게 액세스할 수 있으므로 필요한 즉시 프로그램을 시작하고 로드할 수 있습니다.",
                "tr_pretitle": "[XX]GB 인텔® Optane™ 메모리",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "내 PC에서 메모리는 무엇을 수행합니까?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "더 빠르게 콘텐츠 제작",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "더 응답성이 뛰어난 게이밍",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "더 쉬운 멀티태스킹",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "더 빠르게 프로그램 열기",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "인텔® Optane™ 메모리",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>게이밍 가속</span>",
                "tr_pretitle": "[XX]GB 인텔® Optane™ 메모리",
                "tr_pretitle1": "[XX]GB 인텔® Optane™ 메모리로 게이밍 가속",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "게임 실행 시 최대 <br/><span class=\"optane-game\">98% 더 빠름</span><br/>인텔® Optane™ 메모리가 탑재된 [optane_legal_processor_name] 프로세서에서 데이터 드라이브 액세스 시<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "레벨 로드 시 최대 <br/><span class=\"optane-game\">4.1배 더 빠름</span><br/>인텔® Optane™ 메모리가 탑재된 [optane_legal_processor_name] 프로세서에서 데이터 드라이브 액세스 시<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "인텔® Optane™ 메모리",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>게이밍 가속</span>",
                "tr_pretitle": "[XX]GB 인텔® Optane™ 메모리",
                "tr_pretitle1": "[XX]GB 인텔® Optane™ 메모리로 게이밍 가속",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "게임 실행 시 최대 <br/><span class=\"optane-game\">98% 더 빠름</span><br/>인텔® Optane™ 메모리가 탑재된 [optane_legal_processor_name] 프로세서에서 데이터 드라이브 액세스 시<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "레벨 로드 시 최대 <br/><span class=\"optane-game\">4.1배 더 빠름</span><br/>인텔® Optane™ 메모리가 탑재된 [optane_legal_processor_name] 프로세서에서 데이터 드라이브 액세스 시<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "메모리",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1]GB 메모리 = [Value2]GB 인텔® Optane™ 메모리 + [Value3]GB RAM",
                "tr_subtitle": "이러한 메모리 조합 솔루션은 인텔® Optane™ 메모리로 하드 드라이브의 성능을 가속하고 RAM의 응답 속도를 높여줍니다.",
                "titleArray": [
                  {
                    "tr_title": "멀티태스킹을 위한 [XX]GB의 RAM",
                    "tr_subtitle": "동시에 여러 개의 프로그램을 사용하는 경우, [memory_total]GB의 메모리를 사용하면 프로그램 간 전환이 한결 쉬워집니다."
                  },
                  {
                    "tr_title": "일상 작업용",
                    "tr_subtitle": "메모리는 워드 프로세싱이나 웹 탐색과 같은 기본적인 애플리케이션이 매끄럽게 로드되고 실행될 수 있도록 도와줍니다."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "메모리",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX]GB RAM",
                "tr_pretitle": "더 많은 멀티태스킹",
                "tr_subtitle": "RAM은 프로세서가 프로그램 파일에 더 쉽게 액세스할 수 있도록 하여 CPU가 여러 작업에 더 빠르게 액세스하고 이를 한 번에 처리할 수 있도록 지원합니다.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "표준 메모리",
                    "tr_badgeSubTitle": "최대 8GB RAM"
                  },
                  {
                    "tr_badgeTitle": "고급 메모리",
                    "tr_badgeSubTitle": "8GB 이상 및 최대 16GB RAM"
                  },
                  {
                    "tr_badgeTitle": "엘리트 메모리",
                    "tr_badgeSubTitle": "16GB 이상의 RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "메모리",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX]GB로 더 많은 멀티태스킹",
                "tr_pretitle": "더 많은 멀티태스킹",
                "tr_subtitle": "RAM은 프로세서가 프로그램 파일에 더 쉽게 액세스할 수 있도록 하여 CPU가 여러 작업에 더 빠르게 액세스하고 이를 한 번에 처리할 수 있도록 지원합니다.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "표준 메모리",
                    "tr_badgeSubTitle": "최대 8GB RAM"
                  },
                  {
                    "tr_badgeTitle": "고급 메모리",
                    "tr_badgeSubTitle": "8GB 이상 및 최대 16GB RAM"
                  },
                  {
                    "tr_badgeTitle": "엘리트 메모리",
                    "tr_badgeSubTitle": "16GB 이상의 RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "메모리 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "표준",
                      "tr_badgeSubTitle": "<span class='memory-value'>8GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "고급",
                      "tr_badgeSubTitle": "<span class='memory-value'>16GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "엘리트",
                      "tr_badgeSubTitle": "<span class='memory-value'>32GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "메모리",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "메모리 비교 결과",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "메모리 비교 결과",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "현재 기기",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "일상적인 작업",
                      "tr_badgeSubTitle": "<span class='memory-value'>8GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "강력함",
                      "tr_badgeSubTitle": "<span class='memory-value'>16GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "고급",
                      "tr_badgeSubTitle": "<span class='memory-value'>32GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "스토리지",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "PC에 얼마나 많이 저장할 수 있을까요?",
                "tr_subtitle": "추측할 필요 없습니다. PC에 저장할 수 있는 용량을 정확히 보여드리겠습니다.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class=\"storage-standard\">[num_songs]곡</span> <br/><span class='storage-preTile'>라이브러리 생성<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>노래 1곡당 평균 5MB 기준",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class=\"storage-standard\">[num_games]개</span> <br/><span class='storage-preTile'>게임 라이브러리 생성<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>게임당 평균 40GB 기준",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class=\"storage-standard\">[num_videos]개의 동영상을</span> <br/><span class='storage-preTile'>저장할 수 있는 스토리지<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>동영상 1개당 평균 5GB 기준",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class=\"storage-standard\">[num_photos]장의 사진<sup></sup></span><br/> <span class='storage-preTile'>촬영 및 저장</span>^",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>사진 1장당 평균 4MB 기준",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity]GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity]GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory]GB 인텔® Optane™ 메모리 + [convertedSSD]GB SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "스토리지",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity]GB SSD 스토지리",
                "tr_preTitle1": "[capacity]TB SSD 스토리지",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "이 PC에는 [capacity]GB의 스토리지를 갖춘 SSD가 탑재됩니다. 따라서, 방대한 양의 사진, 동영상, 프로그램을 저장할 수 있으며, 하드 디스크 드라이브에서보다 훨씬 더 빠른 속도로 액세스할 수 있습니다.",
                    "tr_title": "무엇이든 PC에 보관 가능"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "이 PC에는 [capacity]TB의 스토리지를 갖춘 SSD가 탑재됩니다. 따라서, 방대한 양의 사진, 동영상, 프로그램을 저장할 수 있으며, 하드 디스크 드라이브에서보다 훨씬 더 빠른 속도로 액세스할 수 있습니다.",
                    "tr_title": "넉넉한 용량을 갖춘 기기"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'>스토리지</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'>스토리지</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "스토리지",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity]GB HDD 스토리지",
                "tr_preTitle1": "[capacity]TB HDD 스토리지",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "HDD는 PC에서 직접 대용량 스토리지가 필요한 경우에 적합합니다.",
                    "tr_title": "무엇이든 PC에 보관 가능"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "HDD는 PC에서 직접 대용량 스토리지가 필요한 경우에 적합합니다.",
                    "tr_title": "넉넉한 용량을 갖춘 기기"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>스토리지</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "스토리지",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "PC에 얼마나 많이 저장할 수 있을까요?",
                "tr_subtitle": "추측할 필요 없습니다. PC에 저장할 수 있는 용량을 정확히 보여드리겠습니다.",
                "titleArray": [
                  {
                    "tr_title": "[XX]GB HDD"
                  },
                  {
                    "tr_title": "[XX]GB SSD"
                  },
                  {
                    "tr_title": "[optane_memory_size]GB 인텔® Optane™ 메모리로 가속되는 [total_capacity]GB 인텔® SSD"
                  },
                  {
                    "tr_title": "[optane_memory_size]GB 인텔® Optane™ 메모리로 가속되는 [total_capacity]TB 인텔® SSD"
                  },
                  {
                    "tr_title": "[convertedSSD]GB SSD + [convertedHDD]GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD]TB SSD + [convertedHDD]GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD]GB SSD + [convertedHDD]TB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD]TB SSD + [convertedHDD]TB HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'>스토리지</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'>스토리지</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'>스토리지</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'>스토리지</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'>스토리지</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'>스토리지</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "스토리지",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "[convertedSSD]GB SSD + [convertedHDD]GB HDD",
                "tr_preTitle1": "[convertedSSD]TB SSD + [convertedHDD]GB HDD",
                "tr_preTitle2": "[convertedSSD]GB SSD + [convertedHDD]TB HDD",
                "tr_preTitle3": "[convertedSSD]TB SSD + [convertedHDD]TB HDD",
                "titleArray": [
                  {
                    "tr_title": "무엇이든 PC에 보관 가능",
                    "tr_title1": "넉넉한 용량을 갖춘 기기",
                    "tr_subtitle": "이 PC는 [convertedSSD]GB 용량의 SSD와 [convertedHDD]GB의 하드 디스크 드라이브를 제공합니다. 따라서 방대한 용량과 빠른 스토리지 성능을 모두 누릴 수 있습니다.",
                    "tr_subtitle1": "이 PC는 [convertedSSD]TB 용량의 SSD와 [convertedHDD]GB의 하드 디스크 드라이브를 제공합니다. 따라서 방대한 용량과 빠른 스토리지 성능을 모두 누릴 수 있습니다.",
                    "tr_subtitle2": "이 PC는 [convertedSSD]GB 용량의 SSD와 [convertedHDD]TB의 하드 디스크 드라이브를 제공합니다. 따라서 방대한 용량과 빠른 스토리지 성능을 모두 누릴 수 있습니다.",
                    "tr_subtitle3": "이 PC는 [convertedSSD]TB 용량의 SSD와 [convertedHDD]TB의 하드 디스크 드라이브를 제공합니다. 따라서 방대한 용량과 빠른 스토리지 성능을 모두 누릴 수 있습니다."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'>스토리지</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'>스토리지</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "스토리지",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "인텔® Optane™ 메모리 및 SSD로 대용량 스&#65279;토&#65279;리&#65279;지&#65279;에 빠&#65279;르&#65279;게&nbsp;액세스하십시오.",
                "tr_title": "대용량 파일도 거뜬하게",
                "tr_pretitle": "[optane_memory_size]GB 인텔® Optane™ 메모리로 가속되는 [total_capacity]GB 인텔® SSD",
                "tr_pretitle1": "[optane_memory_size]GB 인텔® Optane™ 메모리로 가속되는 [total_capacity]TB 인텔® SSD",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "이 혁신적인 스토리지 솔루션은 인텔® Optane™ 메모리와 SSD를 하나의 칩에 최초로 통합했습니다. 기능은 다음과 같습니다.",
                    "tr_title": "인텔® OPTANE™ 메모리 및 SSD란?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "필요한 것을 빠르게 실행",
                    "tr_badgeSubTitle": "자주 사용하는 파일과 프로그램을 미리 로드하여 기다리는 시간을 단축하고 더 많은 시간을 제작에 투자할 수 있습니다.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "스토리지 + 속도",
                    "tr_badgeSubTitle": "미디어 파일에 필요한 공간을 SSD의 빠른 성능으로 이용할 수 있습니다.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "시간이 지날수록 더 뛰어난 반응성",
                    "tr_badgeSubTitle": "인텔® Optane™ 메모리 및 SSD는 사용자의 작업 방식을 학습하여 PC의 효율을 높입니다.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "스토리지",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "믿을 수 있는 충분한 용량의 스토리지에 모든 미디어를 담아 항상 가까이 두십시오.",
                "tr_title": "모든 사진과 영상 및 음악을 저장할 수 있는 공간"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "디스플레이",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution]만 픽셀로 늘리고 줄여도 놀라운 화질",
                "tr_subtitle": "스마트폰과 같이 직관적인 제어와 선명하고 생생한 디테일로 PC와 상호 작용",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]인치<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "해상도: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "픽셀: [screenResolution]만"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]인치 디&#65279;스&#65279;플&#65279;레&#65279;이",
                    "tr_badgeSubTitle": "화면 높이: [screenHeight]인치",
                    "tr_badgePreTitle": "화면 너비: [YY]인치"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "터치스크린 디스플레이",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "디스플레이",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution]만 픽셀로 몰입도 향상",
                "tr_subtitle": "중요한 것은 픽셀입니다. 픽셀이 더 많으면 이미지가 더 선명하고 더 생동감이 넘칩니다.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]인치<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "해상도: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "픽셀: [screenResolution]만"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]인치 디&#65279;스&#65279;플&#65279;레&#65279;이",
                    "tr_badgeSubTitle": "화면 높이: [screenHeight]인치",
                    "tr_badgePreTitle": "화면 너비: [YY]인치"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "디스플레이",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal]인치 <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "게이밍 세계의 창",
                "tr_subtitle": "TV와 마찬가지로, 게이밍 디스플레이와 관련한 주된 관심사는 크기와 해상도입니다.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "해상도: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "픽셀: [screenResolution]만"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]인치 디&#65279;스&#65279;플&#65279;레&#65279;이",
                    "tr_badgeSubTitle": "화면 높이: [screenHeight]인치",
                    "tr_badgePreTitle": "화면 너비: [screenWidth]인치"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "터치스크린 디스플레이"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "디스플레이",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "탁월한 디스플레이를 갖춘 기기입니다.",
                "tr_subtitle": "HD 픽셀의 8배 이상으로 비디오와 게임은 완전히 새로운 차원의 선명도와 색상을 갖게 됩니다.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "해상도: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "픽셀: [screenResolution]만"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]인치 디&#65279;스&#65279;플&#65279;레&#65279;이",
                    "tr_badgeSubTitle": "화면 높이: [screenHeight]인치",
                    "tr_badgePreTitle": "화면 너비: [YY]인치"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "디스플레이",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution]만 픽셀로 탭하기, 살짝 밀기 및 터치",
                    "tr_subTitle": "더욱 선명하고 생생한 디테일로 좋아하는 것을 보고 대화식으로 작업하십시오."
                  },
                  {
                    "tr_title": "[screenResolution]만 픽셀로 더욱더 선명해지는 디지털 세상",
                    "tr_subTitle": "중요한 것은 픽셀입니다. 픽셀이 더 많으면 이미지가 더 선명하고 더 생동감이 넘칩니다."
                  },
                  {
                    "tr_title": "탁월한 디스플레이를 갖춘 기기입니다.",
                    "tr_subTitle": "HD의 4배에 달하는 픽셀 수로 동영상과 게임을 한층 뚜렷하고 선명하게 구현합니다."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "연결성",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3 기술은 모든 도크, 디스플레이 또는 데이터 장치에 가장 빠르고 가장 다양한 연결을 제공합니다.",
                "tr_title": "하나의 포트로 모든 기능 제공",
                "tr_title1": "하나의 포트로 모든 기능 제공",
                "titleArray": [
                  {
                    "tr_title": "THUNDERBOLT™&nbsp;3&nbsp;기술을 어떻게&nbsp;사용할&nbsp;수&nbsp;있나요?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 3 기술"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K 모니터",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "디스플레이에 연결되어 놀라운 해상도, 대비 및 색상 구현"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "외장 그래픽 카드",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "어떠한 PC에서도 즉각적인 그래픽 업그레이드"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "빠른 네트워킹",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "빠른 P2P 연결 제공"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "연결성",
                "detailBGUrl": "",
                "tr_subtitle": "기존 USB보다 더 빠른 속도로 데이터를 전송하는 Thunderbolt™ 3 기술을 통해 파일을 빠르게 옮기고 여러 모니터 또는 외장 그래픽 카드와 같은 주변 장치를 쉽게 통합할 수 있습니다.",
                "tr_title": "Thunderbolt™ 3 기술",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "엄청나게 빠른 데이터",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Thunderbolt™ 3 기술",
                    "tr_badgeSubTitle": "40Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "연결성",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>즐겨 사용하는 다양한 액세서리를 모두 지원하는 단일 범용 포트와 케이블</li> <li>안정적인 노트북 충전</li> <li>안심할 수 있는 강력한 인증</li></ul>",
                "tr_title": "진정한 범용 케이블 연결성",
                "tr_title1": "진정한 범용 케이블 연결성",
                "tr_noteSection": "<sup>†</sup> 최소 사양 요건 기준",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "THUNDERBOLT™&nbsp;4&nbsp;기술을 어떻게&nbsp;사용할&nbsp;수&nbsp;있나요?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 4 기술"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "제작",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "외장 모니터와 고속 스토리지를 연결하여 사진과 비디오를 보고 편집할 수 있습니다."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "도크",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "단일 케이블로 노트북을 다양한 액세서리와 연결하여 데스크 환경을 단순하고 깔끔하게 정리할 수 있습니다."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "게이밍",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "초당 최대 3,000MB의 속도를 제공하는 SSD 및 HDD 용량을 추가하여 게임 라이브러리와 미디어 파일을 저장하십시오."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "최대 성능 <sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "USB* 3.2 대비 4배 더 빠른 속도 <br/>HDMI* 2 대비 2배 더 빠른 속도"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "연결성",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3 기술은 훨씬 더 많은 기능을 통해 놀라운 성능을 제공하므로 최신 Thunderbolt™ 도크, 모니터, 최신 스토리지나 모든 USB* 액세서리에 쉽게 연결하여 사용할 수 있습니다.",
                "tr_title": "Thunderbolt™ 4 기술",
                "tr_pretitle": "진정한 범용 케이블 연결성",
                "tr_noteSection": "<sup>†</sup> 최소 사양 요건 기준",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "단일 Thunderbolt™ 4 범용 케이블로 모든 USB-C* 케이블을 대체하여 케이블 선택이 빠르고 쉬워집니다."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Thunderbolt™ 4 기술 도크는 모든 액세서리에 더 유연하게 연결할 수 있도록 최대 4개의 Thunderbolt™ 포트를 제공합니다."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "최대 성능 <sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "USB* 3.2 대비 4배 더 빠른 속도 <br/>HDMI* 2 대비 2배 더 빠른 속도"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "연결성",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>즐겨 사용하는 다양한 액세서리를 모두 지원하는 단일 범용 포트와 케이블</li> <li>안정적인 노트북 충전</li> <li>안심할 수 있는 강력한 인증</li></ul>",
                "tr_title": "Thunderbolt™ 액세서리로 최고를 경험하십시오.",
                "tr_noteSection": "<sup>†</sup> 최소 사양 요건 기준",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "THUNDERBOLT™&nbsp;4&nbsp;기술을 어떻게&nbsp;사용할&nbsp;수&nbsp;있나요?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "제작",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "외장 모니터와 고속 스토리지를 연결하여 사진과 비디오를 보고 편집할 수 있습니다."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "도크",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "단일 케이블로 노트북을 다양한 액세서리와 연결하여 데스크 환경을 단순하고 깔끔하게 정리할 수 있습니다."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "게이밍",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "초당 최대 3,000MB의 속도를 제공하는 SSD 및 HDD 용량을 추가하여 게임 라이브러리와 미디어 파일을 저장하십시오."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "최대 성능 <sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "USB* 3.2 대비 4배 더 빠른 속도 <br/>HDMI* 2 대비 2배 더 빠른 속도"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "가장 일반적인 Wi‑Fi 유형은 다음과 같습니다.",
                "tr_title": "Wi‑Fi 표준 이해",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6는 많은 사용자가 동일한 신호를 사용하는 경우에도 더 빠른 속도를 지원합니다.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "인텔® Wi‑Fi 6(Gig+)",
                    "tr_badgeSubTitle": "인텔의 최신 Wi-Fi 혁신이 적용된 이 기술은 여러 사용자가 동일한 신호를 사용할 때에도 기가비트 Wi‑Fi보다 더 빠른 속도를 제공합니다.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "인텔® Wi‑Fi 6E(Gig+)",
                    "tr_badgeSubTitle": "20년 동안 발전을 거듭한 Wi‑Fi의 최고 버전인 Wi‑Fi 6E는 향상된 성능 및 안정성과 간섭 감소를 위한 새로운 고속 채널을 제공합니다."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6는 많은 사용자가 동일한 신호를 사용하는 경우에도 더 빠른 속도를 지원합니다.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "인텔® Killer™ Wi‑Fi 6E(Gig+)",
                    "tr_badgeSubTitle": "대역폭이 가장 필요한 웹 사이트 및 애플리케이션으로 대역폭을 전환해주는 인텔® Killer™ 우선 순위 엔진과 결합하여 더욱더 강력한 이점을 제공하는 Wi-Fi 6E."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "고속 채널에서 경쟁이 적을수록 향상된 성능 및 안정성을 누릴 수 있습니다."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "인텔® Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6는 많은 사용자가 동일한 신호를 사용하는 경우에도 더 빠른 속도를 지원합니다.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "인텔® Killer™ Wi‑Fi 6(Gig+)",
                    "tr_badgeSubTitle": "Wi-Fi 6의 간섭이 적고 인텔® Killer™ 우선 순위 엔진의 지능적인 성능으로 온라인 경험이 더 빠르고 반응성이 높아집니다.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "고속 채널에서 경쟁이 적을수록 향상된 성능 및 안정성을 누릴 수 있습니다."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "인텔® Wi-Fi 7(5 Gig)",
                    "tr_badgeSubTitle": "일관된 초저 대기 시간으로 5Gbps 이상의 PC Wi-Fi 속도 구현 - 몇 초가 아닌 몇 분 만에 파일을 공유합니다."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "인텔® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "인텔® Killer™ Wi-Fi 7(5Gig)은 AI 기반 네트워킹 최적화를 통해 '최강의' 안정성, 짧은 지연 시간 및 속도를 구현하는 최신 기술입니다."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6는 많은 사용자가 동일한 신호를 사용하는 경우에도 더 빠른 속도를 지원합니다.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "인텔® Wi‑Fi 6E(Gig+)",
                    "tr_badgeSubTitle": "인텔® Wi-Fi 6E(Gig+)는 6GHz 채널을 사용하여 1Gbps 이상의 속도, 안정성 및 짧은 지연 시간을 제공합니다."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "혼잡한 네트워크에서 Wi-Fi 5와 비교하여 효율성, 용량 및 속도가 더 좋습니다."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Wi‑Fi [XX]로 연결하여 더 빠르고<sup>†</sup> 안정적인 인터넷을 누리세요.",
                "tr_title": "향상된 범위와 속도",
                "tr_noteSection": "<sup>†</sup>실제 무선 처리량 및/또는 범위는 다릅니다.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "멀티플레이어 온라인 게이밍 시대에는 연결 상태를 유지하는 것이 그 어느 때보다 중요합니다. 속도뿐만 아니라, 먼 거리에서도 속도를 유지할 수 있는 무선 신호도 필요합니다.",
                "tr_title": "[XX]",
                "tr_pretitle": "연결이 핵심입니다",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "160MHz 채널을 지원하는 Wi‑Fi 6 라우터에 인텔® Wi‑Fi 6(Gig+)를 연결하면 더 많은 사용자가 보다 안정적으로 더 빠르게 연결할 수 있습니다.",
                "tr_title": "인텔® <span class='no-warp'>Wi-Fi 6 </span>(Gig+) 로 약 3배 더 빠른 속&#65279;도&#65279;<sup>&#65279;†</sup>",
                "tr_noteSection": "<sup>†</sup>표준 AC Wi‑Fi 대비. 일부 기능은 특정 SKU에서만 사용할 수 있습니다. 자세한 내용은 제조업체에 문의하십시오. Wi‑Fi에 대한 자세한 정보는 intel.com/wifi6disclaimers를 참조하십시오"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "보급형 듀얼 밴드 인텔® Wi-Fi 6는 혼잡한 네트워크에서 이메일 및 인터넷 탐색과 같은 기본 연결 요구 사항을 위해 향상된 성능을 지원합니다.<sup>9</sup>",
                "tr_title": "필수 Wi‑Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "멀티플레이어 온라인 게이밍 시대에는 연결 상태를 유지하는 것이 그 어느 때보다 중요합니다. 속도뿐만 아니라, 먼 거리에서도 속도를 유지할 수 있는 무선 신호도 필요합니다.",
                "tr_title": "[XX]",
                "tr_pretitle": "연결이 핵심입니다",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "새로운 Wi‑Fi 채널로 인근의 기존 장치와 대역폭을 공유할 필요가 없어지며, 이를 통해 원활한 스트리밍, 빠른 탐색, 원활한 원격 업무 및 학습을 경험할 수 있습니다.",
                "tr_title": "경쟁 없이 즐기는 연결",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "[XX] 연결을 통해 더 빠르고<sup>†</sup> 안정적인 인터넷을 누리세요.",
                "tr_title": "향상된 범위와 속도",
                "tr_noteSection": "<sup>†</sup>실제 무선 처리량 및/또는 범위는 다릅니다.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "멀티플레이어 온라인 게이밍 시대에는 연결 상태를 유지하는 것이 그 어느 때보다 중요합니다. 속도뿐만 아니라, 먼 거리에서도 속도를 유지할 수 있는 무선 신호도 필요합니다.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "연결이 핵심입니다",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "초고속 Wi‑Fi 내장",
                "tr_subtitle": "전원 연결 없이 온라인 세상과 연결 상태를 유지할 수 있습니다."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "간섭을 줄여주는 전용 고속 레인과 게이밍을 최우선으로 하는 인텔® Killer™ 우선 순위 엔진을 통해 온라인 게이밍의 수준이 크게 향상됩니다.",
                "tr_title": "압도적인 우위를 점하십시오",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "간섭을 줄여주는 전용 고속 레인과 게이밍을 최우선으로 하는 인텔® Killer™ 우선 순위 엔진을 통해 온라인 게이밍의 수준이 크게 향상됩니다.",
                "tr_title": "[XX]",
                "tr_pretitle": "압도적인 우위를 점하십시오",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "간섭을 줄여 게이밍을 우선으로 처리하는 전용 고속 레인으로 온라인 게이밍이 한층 더 업그레이드되었습니다.",
                "tr_title": "[XX]",
                "tr_pretitle": "압도적인 우위를 점하십시오",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "인텔® Killer™ Wi-Fi는 빠른 속도가 가장 필요한 애플리케이션의 네트워크 트래픽을 자동으로 가속하므로 스트리밍 비디오의 디테일이 선명하고 버퍼가 줄어듭니다.",
                "tr_title": "더 빠르고 스마트하게 연결할 수 있습니다.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "멀티플레이어 온라인 게이밍 시대에는 연결 상태를 유지하는 것이 그 어느 때보다 중요합니다. 속도뿐만 아니라, 먼 거리에서도 속도를 유지할 수 있는 무선 신호도 필요합니다.",
                "tr_title": "[XX]",
                "tr_pretitle": "연결이 핵심입니다",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Wi-Fi 5로 연결하여 더 빠르고<sup>†</sup> 안정적인 인터넷을 누리세요.",
                "tr_title": "향상된 범위와 속도",
                "tr_noteSection": "<sup>†</sup>실제 무선 처리량 및/또는 범위는 다릅니다.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "멀티플레이어 온라인 게이밍 시대에는 연결 상태를 유지하는 것이 그 어느 때보다 중요합니다. 속도뿐만 아니라, 먼 거리에서도 속도를 유지할 수 있는 무선 신호도 필요합니다.",
                "tr_title": "[XX]",
                "tr_pretitle": "압도적인 우위를 점하십시오",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Wi-Fi 7 기능은 이전 Wi-Fi 세대를 기반으로 향상될 것입니다. 이는 빠른 속도뿐만 아니라 몰입도 높은 소비자 경험과 정교한 미래 기술에 대한 대응과 안정성이 극적으로 개선됨을 의미합니다.",
                "tr_title": "유선 연결급 PC 네트워킹",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Wi-Fi 7 기능은 이전 Wi-Fi 세대를 기반으로 향상될 것입니다. 이는 빠른 속도뿐만 아니라 몰입감 높은 소비자 경험과 정교한 미래 기술에 대한 대응과 안정성이 극적으로 개선됨을 의미합니다.",
                "tr_title": "[XX]",
                "tr_pretitle": "사용자 경험 혁신",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "AI 기반 네트워킹 최적화를 통해 속도, 지연 시간, 안정성 향상",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "AI 기반 네트워킹 최적화를 통해 속도, 지연 시간, 안정성 향상.",
                "tr_title": "[XX]",
                "tr_pretitle": "유선 연결급 응답성",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "배터리",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "측정 방법",
                    "tr_badgeSubTitle": "제조업체 대부분이 배터리 수명을 시간으로 표시합니다. 계산 결과가 100% 정확하지는 않더라도, PC 간 배터리 지속 시간을 비교하는 데에는 도움이 될 수 있습니다."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "배터리",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "이동 중 게임",
                "tr_subtitle": "오늘날의 게이밍 노트북은 파워가 뛰어나므로 데스크탑만 고집할 필요가 없습니다. 이제 어디서든 게임을 즐길 수 있습니다.",
                "tr_title": "[Dynamic.battery.values.hours]시간의 배터리 수명"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "배터리",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "전원 연결 없이 앱, 게임 및 음악을 수 시간 동안 즐길 수 있습니다.",
                "tr_title": "1회 충전으로 [XX]시간 동안 스트리밍 가능<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>PC 제조업체에서 명시한 배터리 수명 기준.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]시간",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "배터리",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "전원 연결 없이 앱, 게임 및 음악을 수 시간 동안 즐길 수 있습니다.",
                "tr_title": "이동 중에도 [Dynamic.battery.values.hours]시간 지속<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>PC 제조업체에서 명시한 배터리 수명 기준.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]시간",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "배터리",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "전원 연결 없이 앱, 게임 및 음악을 수 시간 동안 즐길 수 있습니다.",
                "tr_title": "1회 충전으로 [Dynamic.battery.values.hours]시간 온종일 사용<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>PC 제조업체에서 명시한 배터리 수명 기준.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]시간",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "배터리",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "이 PC는 최적화된 배터리 수명으로 더 오래 사용할 수 있습니다. 30분 충전으로 최소 4시간의 배터리 수명을 제공합니다.<sup>4</sup>",
                "tr_title": "전원 연결 없이 [Dynamic.battery.values.hours]시간 지속하는 배터리<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>PC 제조업체에서 명시한 배터리 수명 기준.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]시간",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "홈",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "이 PC를 선택해야 하는 이유",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "모든 사양 보기",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "원격",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "모바일",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "법적 정보",
        "tr_paragraph_array": [
          "성능 테스트에 사용된 소프트웨어 및 워크로드는 인텔® 마이크로프로세서에만 적합하도록 최적화되었을 수 있습니다. SYSmark* 및 MobileMark*와 같은 성능 테스트는 특정 컴퓨터 시스템과 구성 요소 및 기능을 사용하여 측정됩니다. 해당 요소에 변경이 생기면 결과가 달라질 수 있습니다. 고려 중인 구매를 제대로 평가하려면 다른 제품과 결합하여 사용할 경우 해당 제품의 성능을 포함한 기타 정보 및 성능 테스트를 참고해야 합니다. 성능 및 벤치마크 결과에 대한 자세한 정보는 www.intel.com/benchmarks를 참조하십시오.",
          "¹인텔® Wi-Fi 6(Gig+) 제품은 160MHz 채널 옵션을 지원하며 일반적인 2x2 802.11ax PC Wi-Fi 제품에서 가장 빠른 최대 이론 속도(2402Mbps)를 제공합니다. 프리미엄 인텔® Wi-Fi 6(Gig+) 제품은 필수 요구 사항인 80MHz 채널만을 최대 지원하는 표준 2x2(1201Mbps) 또는 1x1(600Mbps) 802.11ax PC Wi-Fi 제품보다 2~4배 더 빠른 최대 이론 속도를 지원합니다.",
          "²추천은 정보 제공 목적으로만 제공됩니다. 구매 결정을 내릴 때 다른 자료를 참조하시기 바랍니다.",
          "³인텔 기술의 특징과 이점은 시스템 구성에 따라 다르며 지원되는 하드웨어, 소프트웨어 및 서비스 활성화가 필요할 수 있습니다. 성능은 시스템 구성에 따라 달라집니다. 어떠한 컴퓨터 시스템도 절대적으로 안전하지는 않습니다. 시스템 제조업체 또는 소매점을 통해 확인하거나 intel.com에서 자세한 내용을 알아보십시오.",
          "⁴FHD 디스플레이가 탑재된 시스템에서 무선 웹 탐색 시 기준. 전원을 끈 상태에서 OEM 기본 종료 수준 기준",
          "⁵eSATA, USB, IEEE 1394* FireWire 등 기타 PC I/O 연결 기술과의 비교 기준. 성능은 하드웨어 및 소프트웨어 구성에 따라 달라집니다. Thunderbolt™ 기술이 지원되는 장치를 사용해야 합니다.",
          "⁶약 3배 더 빠른 속도: 802.11ax 2x2 160MHz는 2402Mbps의 최대 이론 데이터 속도를 지원합니다. IEEE 802.11* 무선 표준 사양에 문서화된 표준 802.11ac 2x2 80MHz(867Mbps) Wi‑Fi보다 약 3배 더 빠르며 유사하게 구성된 802.11ax 무선 네트워크 라우터가 필요합니다.",
          "⁷지연 시간 75% 감소: 9개 클라이언트를 사용하여 OFDMA를 포함하거나 포함하지 않은 상태에서 측정한 802.11ax의 인텔 시뮬레이션 데이터(79%)를 기반으로 합니다. OFDMA가 포함되지 않았을 때의 평균 지연 시간은 36ms, OFDMA가 포함되었을 때의 평균 지연 시간은 7.6ms로 감소했습니다. 지연 시간을 개선하려면 802.11ax(Wi-Fi 6) 라우터와 모든 클라이언트가 OFDMA를 지원해야 합니다.",
          "⁸802.11 사양에서 2x2 160MHz Wi‑Fi 6/6E(802.11ax)의 최대 이론 속도는 2402Mbps로, 2x2 80MHz Wi‑Fi 5(802.11ac)의 최대 이론 속도인 867Mbps보다 2.8배 더 빠릅니다.",
          "⁹동급 최강의 Wi‑Fi 6: 인텔® Wi‑Fi 6(Gig+) 제품은 160MHz 채널 옵션을 지원하며 일반적인 2x2 802.11ax PC Wi‑Fi 제품의 가장 빠른 최대 이론 속도는 2402Mbps입니다. 프리미엄 인텔® Wi‑Fi 6(Gig+) 제품은 필수 요구 사항인 80MHz 채널만을 지원하는 표준 2x2(1201Mbps) 또는 1x1(600Mbps) 802.11ax PC Wi‑Fi 제품보다 2~4배 더 빠른 최대 이론 속도를 지원합니다.",
          "일부 SKU만 해당하며, OEM 설계에 따라 기능이 다릅니다. 자세한 내용은 OEM 또는 소매업체에 문의하십시오. 자세한 Wi‑Fi 정보는 intel.com/wifi6disclaimers를 참조하십시오.",
          "¹⁰40Gbps의 Thunderbolt™ 4는 eSATA*, USB* 및 IEEE 1394* FireWire를 포함한 기타 PC I/O 연결 기술과 비교하여 모든 도크, 디스플레이 또는 데이터 장치에 가장 빠르고 간단하며 가장 안정적인 케이블 솔루션을 제공합니다.",
          "¹¹OFMDA를 지원하는 802.11ax 기반의 라우터와 AX를 지원하는 네트워크상의 여러 클라이언트가 필요합니다. 802.11ax 클라이언트와 AP에서 지원되는 OFDMA 기능을 통해 고밀도 환경에서 더 나은 성능을 얻을 수 있습니다. 2Gbps는 IEEE 802.11* 사양의 최대 이론 데이터 속도 802.11ax 160MHz 2402Mbps의 약 70%를 가정한 속도입니다.",
          "¹²백그라운드 작업 워크로드에서 문서를 실행하여 8세대 인텔® 코어™ i7-8565U 프로세서(512GB TLC SSD)와 8세대 인텔® 코어™ i7-8565U 프로세서(32GB+512GB 인텔® Optane™ 메모리 H10 및 솔리드 스테이트 드라이브)를 비교 측정한 결과입니다.",
          "¹³12세대 인텔® 코어™ 프로세서에 적용된 인텔® Thread Director를 통해 운영 체제가 워크로드를 적합한 코어로 더욱더 지능적으로 전달합니다. 사용자 작업이 필요하지 않습니다. 자세한 내용은 intel.com을 참조하십시오.",
          "¹⁴특정 12세대 인텔® 코어™ 프로세서에서는 사용할 수 없습니다. 성능 하이브리드 아키텍처는 2가지 새로운 코어 마이크로아키텍처, Performance-core(P-core)와 Efficient-core(E-core)를 단일 프로세서 다이에 결합합니다. 일부 12세대 인텔® 코어™ 프로세서(특정 12세대 인텔® 코어™ i5 프로세서 이하)는 성능 하이브리드 아키텍처를 지원하지 않고 P-core만 지원합니다.",
          "¹⁵Game Launch Workload로 측정, 8세대 인텔® 코어™ i7+ 8750H 프로세서(32GB 인텔® Optane™ 메모리 모듈 + 인텔® SSD 256GB PCIe* + 1TB HDD) vs. 8세대 인텔® 코어™ i7-8750H 프로세서(인텔® SSD 256GB PCIe + 1TB HDD). 게임 실행 워크로드 - 인텔에서 개발한 워크로드, Total War*: WARHAMMER* II 빌드: 5577.0을 실행하고 소개 비디오가 비활성화된 상태에서 주메뉴까지 도달(Launch)하는 데 걸리는 시간 측정. 인텔® Optane™ 메모리 데이터 드라이브 가속 구성: 인텔® 코어™ i7+ 8750H 프로세서, PL1=45W TDP, 6C12T, 생산 전 OEM 시스템에서 터보 시 최대 4GHz, 그래픽: NVIDIA* GeForce* GTX 1070, 메모리: 2x4GB DDR4, 스토리지: 인텔® SSD 시리즈 760p 256GB (PCIe*) + 1TB HDD + 32GB 인텔® Optane™ 메모리, OS: Windows* 10 RS3 빌드 1709, MCU 0x84, 인텔® 코어™ i7-8750H 프로세서, PL1=45W TDP, 6C12T, 생산 전 OEM 시스템에서 터보 시 최대 4GHz, 그래픽: NVIDIA* GeForce* GTX 1070, 메모리: 2x4GB DDR4, 스토리지: 인텔® SSD 시리즈 760p 256GB (PCIe*) + 1TB HDD, OS: Windows* 10 RS3 빌드 1709, MCU 0x84",
          "¹⁶Game Level Load Workload로 측정, 8세대 인텔® 코어™ i7+ 8750H 프로세서(32GB 인텔® Optane™ 메모리 모듈) vs. 8세대 인텔® 코어™ i7-8750H 프로세서(HDD만). 게임 레벨 로드 워크로드 - 인텔에서 개발한 워크로드, Total War*: WARHAMMER* II 빌드: 5577.0에서 주메뉴에서 레벨 로드 완료까지 걸리는 시간 측정. 인텔® Optane™ 메모리 데이터 드라이브 가속 구성: 인텔® 코어™ i7+ 8750H 프로세서, PL1=45W TDP, 6C12T, 생산 전 OEM 시스템에서 터보 시 최대 4GHz, 그래픽: NVIDIA* GeForce* GTX 1070, 메모리: 2x4GB DDR4, 스토리지: 인텔® SSD 시리즈 760p 256GB (PCIe*) + 1TB HDD + 32GB 인텔® Optane™ 메모리, OS: Windows* 10 RS3 빌드 1709, MCU 0x84, 인텔® 코어™ i7-8750H 프로세서, PL1=45W TDP, 6C12T, 생산 전 OEM 시스템에서 터보 시 최대 4GHz, 그래픽: NVIDIA* GeForce* GTX 1070, 메모리: 2x4GB DDR4, 스토리지: 인텔® SSD 시리즈 760p 256GB (PCIe*) + 1TB HDD, OS: Windows* 10 RS3 빌드 1709, MCU 0x84",
          "¹⁷Performance-cores 기준 성능은 사용, 구성 및 기타 요인에 따라 다릅니다. www.intel.com/PerformanceIndex에서 자세한 정보를 확인하십시오.",
          "¹⁸클럭 주파수 또는 전압을 변경하면 프로세서 및 기타 시스템 구성 요소가 손상되거나 수명이 줄어들어 시스템 안정성과 성능이 저하될 수 있습니다. 프로세서가 사양 외 환경에서 사용된 경우 제품 보증이 적용되지 않을 수 있습니다. 자세한 내용은 시스템 및 구성 요소 제조업체에 문의하십시오.",
          "¹⁹OS 상위 및 하위 보안 기능, 앱, 데이터 보호, 그리고 모든 규모의 기업을 위한 고급 위협 보호, 제품 설계, 제조 및 지원에 대한 인텔의 보안 우선 접근 방식 등 인텔® vPro® 플랫폼의 압도적인 기능 조합을 측정한 결과입니다. 인텔® vPro® 플랫폼 기반의 모든 비즈니스 PC는 고유한 하드웨어 기반 보안 기능을 비롯한 엄격한 사양에 따라 검증되었습니다. 자세한 내용은 www.intel.com/PerformanceIndex(플랫폼)를 참조하십시오. 어떤 제품 또는 구성 요소도 절대적으로 안전할 수는 없습니다.",
          "²⁰인텔® Connectivity Performance Suite는 Windows* 기반 시스템에서만 사용할 수 있습니다. 인텔 OTA(Over The Air) Wi-Fi 테스트는 혼잡한 네트워크에서 ICPS 소프트웨어 애플리케이션 무선 트래픽 우선순위 지정 및 스마트 액세스 포인트 스위칭이 없을 때와 비교한 결과입니다. 자세한 내용은 www.intel.com/PerformanceIndex(연결성)을 참조하십시오. 결과는 다를 수 있습니다.",
          "²¹이전 세대 프로세서 대비",
          "²²인텔® Arc™ 그래픽은 시장에서 AV1 인코딩을 최초로 지원하는 제품입니다(2022년 1분기 기준). 자세한 내용은 intel.com/PerformanceIndex에서 확인하십시오.",
          "²³인텔® Evo™ 디자인의 고유한 기능으로 측정되었으며, 해당 기능 역시 모바일 사용자의 핵심 경험 기준을 충족하도록 검증되었습니다. 2022년 2월 테스트 기준.",
          "²⁴인텔® Unison™ 솔루션은 현재 Windows* 기반 PC의 적격 인텔® Evo™ 디자인에서만 사용할 수 있으며 Android 또는 iOS 기반 휴대폰만 페어링됩니다. 모든 장치에서 지원되는 OS 버전을 실행해야 합니다.",
          "²⁵최대 메모리 속도는 채널당 1개 DIMM(1DPC) 구성과 연결되어 있습니다. 어떤 채널이라도 추가 DIMM을 로드하면 최대 메모리 속도에 영향을 줄 수 있습니다. 최대 DDR5-5600MT/s 1DPC UDIMM 1Rx8, 1Rx16 및 DDR5-5200 1Rx8, 1Rx16, 2Rx8(일부 SKU만 해당). 최대 메모리 용량은 2DPC 구성으로 가능합니다.",
          "²⁶표준 Wi-Fi 대비. 자세한 내용은 intel.com/performance-wireless를 참조하십시오. 결과는 다를 수 있습니다.",
          "²⁷성능 하이브리드 아키텍처는 12세대 인&#65279;텔&#65279;®&nbsp;코&#65279;어&#65279;™ 프로세서에서 처음으로 도입된 단일 프로세서 다이에 2가지 코어 마이크로아키텍처, Performance-core(P-core)와 Efficient-core(E-core)를 결합합니다. 일부 12세대 이상 인&#65279;텔&#65279;®&nbsp;코&#65279;어&#65279;™ 프로세서에는 성능 하이브리드 아키텍처가 없고 P-core만 있으며, 이전 세대와 캐시 크기가 같습니다. SKU에 대한 자세한 정보는 ark.intel.com에서 확인하십시오.",
          "²⁸하드웨어에 내장된 인텔® Thread Director는 12세대 이상 인텔® 코어™ 프로세서의 성능 하이브리드 아키텍처 구성에서만 제공되며, OS 지원이 필요합니다. OS에 따라 사용 가능한 기능이 다릅니다.",
          "²⁹하드웨어에 내장된 인텔® Thread Director는 12세대 및 13세대 인텔® 코어™ 프로세서의 성능 하이브리드 아키텍처 구성에서만 제공되며, OS 활성화가 필요합니다. OS에 따라 사용 가능한 기능이 다릅니다.",
          "³⁰3DMark Time Spy에서 인텔® 코어™ Ultra 모바일 프로세서(시리즈 2 — 코드명: Lunar Lake)와 인텔® 코어™ Ultra 모바일 프로세서(시리즈 1 — 코드명: Meteor Lake)의 참조 검증 플랫폼 성능을 측정한 결과입니다. 자세한 내용은 intel.com/performanceindex를 참조하십시오. 결과는 다를 수 있습니다.",
          "³¹배경 초점 흐리기 및 얼굴 추적은 Windows* 기반 시스템에서만 이용할 수 있습니다.",
          "³²Wi-Fi 7 제품은 새로운 다중 자원 장치 천공 기능을 통해 6GHz에서는 320MHz 채널, 5GHz에서는 새로운 160MHz 채널 조합에 액세스할 수 있습니다.",
          "³³IEEE 무선 표준 사양과 두 개의 데이터 스트림을 동시에 처리하는 2스트림 장치의 이론상 최대 데이터 속도를 기준으로 합니다.",
          "³⁴혼잡한 네트워크 환경에 대한 인텔 엔지니어링 시뮬레이션은 새로운 Wi-Fi 7 다중 링크 작동 기능을 통해 지연 시간을 크게 줄일 수 있음을 나타냅니다.",
          "³⁵OEM 기본 종료 수준에서 최소로 충전하였습니다. 개별 시스템 결과는 다를 수 있습니다. 자세한 내용은 intel.com/performance-evo를 참조하십시오.",
          "³⁶6GHz Wi-Fi 대역은 일부 국가/지역에서 사용할 수 없습니다.",
          "³⁷Windows* 전용",
          "³⁸OEM 기본 종료 상태에서 충전하였습니다. 성능 및 벤치마크 결과에 대한 자세한 정보는 intel.com/Evo를 참조하십시오.",
          "³⁹인텔® 코어™ Ultra 모바일 프로세서(시리즈 2)를 의미하며, 하드웨어 기반의 고유한 보안 기능, OS 상하위 보안 기능의 독보적인 조합, 이전 세대 대비 저전력에서도 인상적인 성능을 기반으로 합니다(2024년 9월 기준). 자세한 내용은 intel.com/performanceindex를 참조하십시오. 결과는 다를 수 있습니다.",
          "⁴⁰소프트웨어 응용 프로그램은 설명 목적으로만 기술되었습니다. AI 기능을 사용하려면 소프트웨어 또는 플랫폼 제공업체의 추가 소프트웨어 구매, 구독 또는 활성화가 필요하거나 특정 구성 또는 호환성 요구 사항이 있을 수 있습니다. 자세한 내용은 www.intel.com/PerformanceIndex를 참조하십시오. 결과는 다를 수 있습니다. © Intel Corporation.",
          "⁴¹표시된 이미지는 변경되거나 시뮬레이션되었을 수 있습니다. AI 기능을 사용하려면 소프트웨어 또는 플랫폼 제공업체의 추가 소프트웨어 구매, 구독 또는 활성화가 필요하거나 특정 구성 또는 호환성 요구 사항이 있을 수 있습니다. 자세한 내용은 www.intel.com/AIPC에서 확인하십시오.",
          "⁴²일부 글로벌 시장에서 Windows* 11의 최신 업데이트에 포함된 미리 보기를 통해 점진적으로 출시됩니다. 이용할 수 있는 시기는 장치와 시장에 따라 다릅니다. 자세한 내용은 aka.ms/WindowsAIFeatures를 참조하십시오. ©&nbsp;Intel&nbsp;Corporation.",
          "⁴³인텔® Unison™ 솔루션은 현재 적격 디자인에서 사용할 수 있습니다. 자세한 내용은 www.intel.com/PerformanceIndex를 참조하십시오.",
          "⁴⁴인텔® 코어™ Ultra 7 프로세서 165H에서 Dying Light 2 Stay Human을 실행하여 인텔® X<sup>e</sup>SS로 업스케일된 FPS와 네이티브 FPS를 비교 측정한 결과입니다. 자세한 내용은 www.intel.com/PerformanceIndex를 참조하십시오. 결과는 다를 수 있습니다.",
          "⁴⁵YouTube* 4K 30FPS AV1에서 인텔® 코어™ Ultra 모바일 프로세서(시리즈 2 - 코드명: Lunar Lake) 참조 검증 플랫폼과 인텔® 코어™ Ultra 모바일 프로세서(시리즈 1 - 코드명: Meteor Lake) 참조 검증 플랫폼의 낮은 SoC 전력으로 측정한 결과입니다. 자세한 내용은 intel.com/performanceindex를 참조하십시오. 결과는 다를 수 있습니다.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "잘못된 가격에 대해서는 인텔이 책임지지 않습니다.",
          "*기타 명칭 및 브랜드는 해당 소유업체의 자산입니다.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES과 관련 디자인 및 합성 마크는 INFLEXION STUDIOS INC의 상표입니다. 모든 권한은 인텔사에 있습니다.",
          "인텔 기술은 지원되는 하드웨어, 소프트웨어 또는 서비스 활성화가 필요할 수 있습니다.",
          "NERO*의 모든 결과 및 데이터는 편의상 정보를 제공하는 목적으로만 제공됩니다. 예를 들어, 인텔사는 NERO* 앱의 정확성, 성능 결과 및 점수, 또는 www.nero.com 또는 기타 타사 웹 사이트에서 다운로드할 수 있는 앱 버전의 잠재적인 변형에 대해 아무런 책임도 지지 않습니다. NERO* 벤치마크 점수는 특정 컴퓨터 시스템, 구성 요소, 소프트웨어, 작업 및 기능을 사용하여 측정합니다. 해당 요소에 변경이 생기면 결과가 달라질 수 있습니다. 고려 중인 구매를 제대로 평가하려면 다른 제품과 결합하여 사용할 경우 해당 제품의 성능을 포함한 기타 정보 및 성능 테스트를 참고해야 합니다.",
          "모든 버전의 인텔® vPro® 플랫폼은 이 플랫폼을 정의하는 관리 용이성 사용 사례, 보안 기능, 시스템 성능 및 안정성을 제공하는 데 필요한 적격 인텔® 코어™ 프로세서, 지원되는 운영 체제, 인텔® LAN 및/또는 WLAN 실리콘, 펌웨어 개선 및 기타 하드웨어 및 소프트웨어를 갖추어야 합니다. ",
          "성능은 사용, 구성 및 기타 요인에 따라 다릅니다. www.intel.com/PerformanceIndex에서 자세한 정보를 확인하십시오.",
          "인텔은 타사 데이터를 통제하거나 감사하지 않습니다. 정확성을 평가하려면 기타 소스를 참고해야 합니다.",
          "© Intel Corporation. 인텔, 인텔 로고 및 기타 인텔 마크는 인텔사 또는 그 자회사의 상표입니다. *기타 명칭 및 브랜드는 해당 소유업체의 자산입니다.",
          "어떤 제품 또는 구성 요소도 절대적으로 안전할 수는 없습니다.",
          "비용과 결과는 다를 수 있습니다.",
          "인텔, 인텔 로고, 인텔 코어, 인텔 Optane, 인텔 Iris, Thunderbolt 및 Thunderbolt 로고는 인텔사 또는 그 자회사의 상표입니다. 기타 명칭 및 브랜드는 해당 소유업체의 자산입니다.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "홈",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "스캔하여 비교하기",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "인기 게시글",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/evo/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/video_link.mp4",
      "iconURL": "",
      "videoName": "video_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ Performance to Power Their World (16_9).mp4",
      "iconURL": "",
      "videoName": "Video_ Performance to Power Their World (16_9)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "환영합니다",
    "tr_welcomePagetrSubtitle": "스마트폰으로 이 PC를 원격 제어할 수 있습니다",
    "tr_welcomePagembSubtitle": "환영합니다. 스마트폰에서 이 PC의 사양을 살펴보십시오.",
    "tr_welcomePageBtnText": "시작하기",
    "tr_welcomePageText": "스마트폰에서 이 PC의 세부 정보를 살펴보고 비교해보십시오.",
    "tr_scrollText": "앞에 있는 장치에 관해 알아보십시오.",
    "tr_toolTipTextc1": "앞에 있는 장치의 다른 페이지로 이동하려면 이 버튼을 사용하십시오.",
    "tr_toolTipTextc2": "아래 카드를 누르면 각 장치의 세부 사양이 나와 있는 페이지로 이동합니다.",
    "tr_toolTipTextc3": "앞에 있는 화면에서 위아래로 이동하려면 이 버튼을 사용하십시오.",
    "tr_toolTipTextc4": "스마트폰으로 장치 사양을 보려면 원격 모드를 해제하십시오.",
    "tr_toolTipTextmb1": "상세 콘텐츠 페이지를 보려면 카드를 탭하십시오.",
    "tr_toolTipTextmb2": "모든 장치 사양에 대한 요약 정보를 보려면 탭하십시오.",
    "tr_toolTipTextmb3": "QR 코드를 스캔하고 최대 4개의 장치를 비교하려면 버튼을 탭하십시오.",
    "tr_nextButton": "다음",
    "tr_prevButton": "이전",
    "tr_gotItButton": "알겠습니다.",
    "tr_skipButton": "건너뛰기",
    "tr_placeholderText": "*자리 표시자",
    "tr_processorHeading": "프로세서",
    "tr_processorSubheading": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
    "tr_memorySubheading": "[XX]GB",
    "tr_storageSubheading": "[XX]GB 인텔® Optane™ 메모리 + [YY]GB SSD",
    "tr_coachmarkProcessor": "XX 프로세서",
    "tr_coachmarkGraphics": "[XX] 그래픽",
    "tr_coachmarkMemory": "사양 값",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999.95달러",
    "tr_compare": "비교",
    "tr_viewallspecs": "전체 사양 보기",
    "tr_operatingSystem": "OS",
    "tr_AmdProcessorNonIntel": "AMD* 프로세서",
    "tr_QualcommProcessorNonIntel": "Qualcomm* 프로세서",
    "tr_GBText": "GB",
    "tr_remoteText": "노트북 화면에 표시된 사양",
    "tr_scanErrorText": "한 번에 최대 3개의 장치를 스&#65279;캔&#65279;할 수 있습니다. 하나 이&#65279;상&#65279;의 장치를 삭제하십시오.",
    "tr_scanErrorText2": "이 장치를 이미 스캔했습니다. 다른 장치를 스캔하십시오.",
    "tr_scanMsg": "스캔하여 장치 추가",
    "tr_backToScan": "비교로 돌아가기",
    "tr_scanToCompare": "스캔하여 비교",
    "tr_compareDevice": "기기 비교",
    "tr_processorTitle": "프로세서",
    "tr_graphicTitle": "그래픽",
    "tr_storageTitle": "스토리지",
    "tr_displayTitle": "디스플레이",
    "tr_batteryTitle": "배터리",
    "tr_memoryTitle": "메모리",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "연결성",
    "tr_priceTitle": "가격",
    "tr_operatingSystemTitle": "운영 체제",
    "tr_batteryHoursText": "[XX]시간",
    "tr_hrsText": "시간",
    "tr_touchscreeenText": "[screenDiagonal]인치 터&#65279;치&#65279;스&#65279;크&#65279;린",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, 터치스크린",
    "tr_FHDTouchScreenText": "FHD, 터치스크린",
    "tr_4KTouchscreenText": "4K, 터치스크린",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "저장",
    "tr_device_comparison": "장치 비교 문서 [yyyy]-[mm]-[dd]",
    "tr_Save_As_JPEG": "JPEG로 저장",
    "tr_Save_As_PDF": "PDF로 저장",
    "tr_Downloading_PDF": "PDF 다운로드",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "진행 중",
    "tr_logo": "로고",
    "tr_laptop_name": "노트북 이름",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "인텔® Optane™ 메모리",
    "tr_inchUnit": "인치",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "인텔® Wi‑Fi 6(Gig+)",
    "tr_wifi6Caps": "인텔® Wi‑Fi 6(Gig+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "Wi‑Fi [XX]",
    "tr_wifi6E": "인텔® Wi‑Fi 6E(Gig+)",
    "tr_IntelOptaneMemory": "인텔® OPTANE™ 메모리",
    "tr_Thunderbolt3": "Thunderbolt™ 3 기술",
    "tr_Thunderbolt4": "Thunderbolt™ 4 기술",
    "tr_processorGraphicsCaps": "프로세서 그래픽",
    "tr_processorGraphicsSmall": "프로세서 그래픽",
    "tr_graphicsCardCaps": "전용 그래픽",
    "tr_graphicsCardSmall": "전용 그래픽",
    "tr_processorTitleCamel": "프로세서",
    "tr_graphicTitleCamel": "그래픽",
    "tr_storageTitleCamel": "스토리지",
    "tr_displayTitleCamel": "디스플레이",
    "tr_batteryTitleCamel": "배터리",
    "tr_memoryTitleCamel": "메모리",
    "tr_connectivityTitleCamel": "연결성",
    "tr_priceTitleCamel": "가격",
    "tr_operatingSystemTitleCamel": "운영 체제",
    "tr_viewallspecsCamel": "모든 사양 보기",
    "tr_displayText": "[screenDiagonal]인치",
    "tr_displayTextResolution": "[screenDiagonal]인치 [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal]인치 [screenResolutionType] 터&#65279;치&#65279;스&#65279;크&#65279;린",
    "tr_OS": "OS",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "PC 사양",
    "tr_explorePC_text": "이 PC의 모든 것 알아보기",
    "tr_mtlPC_text": "모든 것을 처리하는 놀라운 성능을 경험해보세요",
    "tr_optaneMemory": "인텔® Optane™ 메모리",
    "tr_displayText_Display": "[screenDiagonal]인치 디스플레이",
    "tr_displayTextResolution_Display": "[screenDiagonal]인치 [screenResolutionType] 디스플레이",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal]인치 [screenResolutionType] 터치스크린 디스플레이",
    "tr_displayTextTouchscreeenText": "[screenDiagonal]인치  터치스크린 디스플레이",
    "tr_learnMoreLabel": "자세한 내용",
    "tr_batteryHoursTextUpperCase": "[XX]시간",
    "tr_processorGraphicsIrisXe": "11세대 인텔® 코어™ [cpu_fam] 프로세서 및 인텔® IRIS® X<sup class='small_text_sup_xe'>e</sup> 그래픽",
    "tr_processorGraphicsIrisXeMax": "11세대 인텔® 코어™ [cpu_fam] 프로세서 및 인텔® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX 그래픽",
    "tr_Thunderbolt3UpperCase": "THUNDERBOLT™ 3 기술",
    "tr_Thunderbolt4UpperCase": "THUNDERBOLT™ 4 기술",
    "tr_processorWithArcGraphics": "12세대 인텔® 코어™ [cpu_fam] 프로세서와 인텔® Arc™ 그래픽",
    "tr_processorGraphicsIrisXe12Gen": "12세대 인텔® 코어™ [cpu_fam] 프로세서와 인텔® IRIS® X<sup class='small_text_sup_xe'>e</sup> 그래픽",
    "tr_processorGraphicsIrisXeMax12Gen": "12세대 인텔® 코어™ [cpu_fam] 프로세서와 인텔® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX 그래픽",
    "tr_samsung_oled": "삼성 OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "13세대 인텔® 코어™ [cpu_fam] 프로세서와 인텔® Arc™ 그래픽",
    "tr_processorGraphicsIrisXe13Gen": "13세대 인텔® 코어™ [cpu_fam] 프로세서와 인텔® IRIS® X<sup class='small_text_sup_xe'>e</sup> 그래픽",
    "tr_processorGraphicsIrisXeMax13Gen": "13세대 인텔® 코어™ [cpu_fam] 프로세서와 인텔® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX 그래픽",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "인텔® ARC™ A350M 그래픽",
      "tr_A370M": "인텔® ARC™ A370M 그래픽",
      "tr_A730M": "인텔® ARC™ A730M 그래픽",
      "tr_A770M": "인텔® ARC™ A770M 그래픽",
      "tr_A310": "인텔® ARC™ A310 그래픽",
      "tr_A380": "인텔® ARC™ A380 그래픽",
      "tr_A580": "인텔® ARC™ A580 그래픽",
      "tr_A750": "인텔® ARC™ A750 그래픽",
      "tr_A770": "인텔® ARC™ A770 그래픽"
    },
    "tr_memoryValue": "[XX]GB RAM",
    "tr_memoryWithOptaneDesc": "[Value1]GB 메모리 = [Value2]GB 인텔® Optane™ 메모리 + [Value3]GB RAM",
    "tr_textGBGB": "[convertedSSD]GB SSD + [convertedHDD]GB HDD",
    "tr_textGBTB": "[convertedSSD]GB SSD + [convertedHDD]TB HDD",
    "tr_textTBGB": "[convertedSSD]TB SSD + [convertedHDD]GB HDD",
    "tr_textTBTB": "[convertedSSD]TB SSD + [convertedHDD]TB HDD",
    "tr_textGBSSD": "[convertedSSD]GB SSD",
    "tr_textTBSSD": "[convertedSSD]TB SSD",
    "tr_textGBHDD": "[convertedHDD]GB HDD",
    "tr_textTBHDD": "[convertedHDD]TB HDD",
    "tr_textStorageWithOptaneGB": "[XX]GB 인텔® Optane™ 메모리 + [convertedSSD]GB SSD",
    "tr_textStorageWithOptaneTB": "[XX]GB 인텔® Optane™ 메모리 + [convertedSSD]TB SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home S 모드",
      "tr_OS21": "Windows* 10 Pro S 모드",
      "tr_OS22": "Windows* 10 Enterprise S 모드",
      "tr_OS23": "Windows* 11 Home S 모드",
      "tr_OS24": "Windows* 11 Pro S 모드",
      "tr_OS25": "Windows* 11 Enterprise S 모드"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "인텔®&nbsp;Arc™&nbsp;A310 그래픽",
      "tr_GR387": "인텔®&nbsp;Arc™&nbsp;A350M 그래픽",
      "tr_GR388": "인텔®&nbsp;Arc™&nbsp;A370M 그래픽",
      "tr_GR389": "인텔®&nbsp;Arc™&nbsp;A380 그래픽",
      "tr_GR390": "인텔®&nbsp;Arc™&nbsp;A530M 그래픽",
      "tr_GR391": "인텔®&nbsp;Arc™&nbsp;A550M 그래픽",
      "tr_GR392": "인텔®&nbsp;Arc™&nbsp;A570M 그래픽",
      "tr_GR393": "인텔®&nbsp;Arc™&nbsp;A580 그래픽",
      "tr_GR394": "인텔®&nbsp;Arc™&nbsp;A730M 그래픽",
      "tr_GR395": "인텔®&nbsp;Arc™&nbsp;A750 그래픽",
      "tr_GR396": "인텔®&nbsp;Arc™&nbsp;A770 그래픽",
      "tr_GR397": "인텔®&nbsp;Arc™&nbsp;A770M 그래픽",
      "tr_GR398": "인텔® Arc™ 그래픽",
      "tr_GR399": "인텔®&nbsp;Arc™&nbsp;Pro&nbsp;A30M 그래픽",
      "tr_GR400": "인텔®&nbsp;Arc™&nbsp;Pro&nbsp;A40/A50 그래픽",
      "tr_GR401": "인텔®&nbsp;Arc™&nbsp;Pro&nbsp;A60 그래픽",
      "tr_GR402": "인텔®&nbsp;Arc™&nbsp;Pro&nbsp;A60M 그래픽",
      "tr_GR403": "인텔® 그래픽",
      "tr_GR404": "인텔® HD 그래픽 2000",
      "tr_GR405": "인텔® HD 그래픽 2500",
      "tr_GR406": "인텔® HD 그래픽 3000",
      "tr_GR407": "인텔® HD 그래픽 400",
      "tr_GR408": "인텔® HD 그래픽 4000",
      "tr_GR409": "인텔® HD 그래픽 405",
      "tr_GR410": "인텔® HD 그래픽 4200",
      "tr_GR411": "인텔® HD 그래픽 4400",
      "tr_GR412": "인텔® HD 그래픽 4600",
      "tr_GR413": "인텔® HD 그래픽 500",
      "tr_GR414": "인텔® HD 그래픽 5000",
      "tr_GR415": "인텔® HD 그래픽 505",
      "tr_GR416": "인텔® HD 그래픽 510",
      "tr_GR417": "인텔® HD 그래픽 515",
      "tr_GR418": "인텔® HD 그래픽 520",
      "tr_GR419": "인텔® HD 그래픽 530",
      "tr_GR420": "인텔® HD 그래픽 5300",
      "tr_GR421": "인텔® HD 그래픽 5500",
      "tr_GR422": "인텔® HD 그래픽 5600",
      "tr_GR423": "인텔® HD 그래픽 6000",
      "tr_GR424": "인텔® HD 그래픽 610",
      "tr_GR425": "인텔® HD 그래픽 615",
      "tr_GR426": "인텔® HD 그래픽 620",
      "tr_GR427": "인텔® HD 그래픽 630",
      "tr_GR428": "인텔® HD 그래픽",
      "tr_GR429": "인텔® Iris® 그래픽 5100",
      "tr_GR430": "인텔® Iris® 그래픽 540",
      "tr_GR431": "인텔® Iris® 그래픽 550",
      "tr_GR432": "인텔® Iris® 그래픽 6100",
      "tr_GR433": "인텔® Iris® Plus 그래픽 640",
      "tr_GR434": "인텔® Iris® Plus 그래픽 645",
      "tr_GR435": "인텔® Iris® Plus 그래픽 650",
      "tr_GR436": "인텔® Iris® Plus 그래픽 655",
      "tr_GR437": "인텔® Iris® Plus 그래픽",
      "tr_GR438": "인텔® Iris® Pro 그래픽 5200",
      "tr_GR439": "인텔® Iris® Pro 그래픽 580",
      "tr_GR440": "인텔® Iris® Pro 그래픽 6200",
      "tr_GR441": "인텔® Iris® X<sup>e</sup> 그래픽",
      "tr_GR442": "인텔® Iris® X<sup>e</sup> MAX 그래픽 A220M",
      "tr_GR443": "인텔® Iris® X<sup>e</sup> MAX 그래픽",
      "tr_GR444": "인텔® 통합형 그래픽",
      "tr_GR445": "인텔® UHD 그래픽 600",
      "tr_GR446": "인텔® UHD 그래픽 605",
      "tr_GR447": "인텔® UHD 그래픽 610",
      "tr_GR448": "인텔® UHD 그래픽 615",
      "tr_GR449": "인텔® UHD 그래픽 617",
      "tr_GR450": "인텔® UHD 그래픽 620",
      "tr_GR451": "인텔® UHD 그래픽 630",
      "tr_GR452": "인텔® UHD 그래픽 710",
      "tr_GR453": "인텔® UHD 그래픽 730",
      "tr_GR454": "인텔® UHD 그래픽 770",
      "tr_GR455": "인텔® UHD 그래픽",
      "tr_GR456": "인텔® UHD 그래픽",
      "tr_GR457": "인텔® UHD 그래픽",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* GTX 1050",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* GTX 1060",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* GTX 1070",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* GTX 1080",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* GTX 1650",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* RTX 2060",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* RTX 2070",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* RTX 2070 Super",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* RTX 2080",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "Max-Q 디자인을 탑재한 NVIDIA* GeForce* RTX 2080 Super",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "Max-Q 디자인을 탑재한 NVIDIA* Quadro* P2000",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "Max-Q 디자인을 탑재한 NVIDIA* Quadro* P3200",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "Max-Q 디자인을 탑재한 NVIDIA* Quadro* P4000",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "Max-Q 디자인을 탑재한 NVIDIA* Quadro* P4200",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "Max-Q 디자인을 탑재한 NVIDIA* Quadro* P5200",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "Max-Q 디자인을 탑재한 NVIDIA* Quadro* RTX 3000",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "Max-Q 디자인을 탑재한 NVIDIA* Quadro* RTX 4000",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "Max-Q 디자인을 탑재한 NVIDIA* Quadro* RTX 5000",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "Max-Q 디자인을 탑재한 NVIDIA* Quadro* T1000",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "Max-Q 디자인을 탑재한 NVIDIA* Quadro* T2000",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "인텔® Arc™ 그래픽",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada 세대",
      "tr_GR815": "AMD Radeon* Pro W7900 듀얼 슬롯"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "인텔® 센트리노® Advanced-N 6200 ABG",
      "tr_Wifi2": "인텔® 센트리노® Advanced-N 6200 AGN",
      "tr_Wifi3": "인텔® 센트리노® Advanced-N 6200 BG",
      "tr_Wifi4": "인텔® 센트리노® Advanced-N 6205",
      "tr_Wifi5": "인텔® 센트리노® Advanced-N 6230",
      "tr_Wifi6": "인텔® 센트리노® Advanced-N 6235",
      "tr_Wifi7": "인텔® 센트리노® Advanced-N 6250 ABG",
      "tr_Wifi8": "인텔® 센트리노® Advanced-N 6250 AGN",
      "tr_Wifi9": "인텔® 센트리노® Ultimate-N 6300 AGN",
      "tr_Wifi10": "인텔® 센트리노® Wireless-N 100",
      "tr_Wifi11": "인텔® 센트리노® Wireless-N 1000",
      "tr_Wifi12": "인텔® 센트리노® Wireless-N 1030",
      "tr_Wifi13": "인텔® 센트리노® Wireless-N 105",
      "tr_Wifi14": "인텔® 센트리노® Wireless-N 135",
      "tr_Wifi15": "인텔® 센트리노® Wireless-N 2200",
      "tr_Wifi16": "인텔® 센트리노® Wireless-N 2230",
      "tr_Wifi17": "인텔® 센트리노® Wireless-N WiMAX 6150",
      "tr_Wifi18": "인텔® 듀얼 밴드 Wireless-AC 3160",
      "tr_Wifi19": "인텔® 듀얼 밴드 Wireless-AC 3165",
      "tr_Wifi20": "인텔® 듀얼 밴드 Wireless-AC 7260",
      "tr_Wifi21": "인텔® 듀얼 밴드 Wireless-AC 7265",
      "tr_Wifi22": "인텔® 듀얼 밴드 Wireless-N 3160",
      "tr_Wifi23": "인텔® 듀얼 밴드 Wireless-N 7260",
      "tr_Wifi24": "인텔® 듀얼 밴드 Wireless-N 7265",
      "tr_Wifi25": "인텔® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "인텔® Wi-Fi 6 AX101",
      "tr_Wifi27": "인텔® Wi-Fi 6 AX200",
      "tr_Wifi28": "인텔® Wi-Fi 6 AX201",
      "tr_Wifi29": "인텔® Wi-Fi 6 AX203",
      "tr_Wifi30": "인텔® Wi-Fi 6E AX210",
      "tr_Wifi31": "인텔® Wi-Fi 6E AX211",
      "tr_Wifi32": "인텔® Wi-Fi 6E AX411",
      "tr_Wifi33": "인텔® Wi-Fi 7 BE200",
      "tr_Wifi34": "인텔® Wi-Fi 7 BE202",
      "tr_Wifi35": "인텔® Wireless-AC 3165",
      "tr_Wifi36": "인텔® Wireless-AC 3168",
      "tr_Wifi37": "인텔® Wireless-AC 8265",
      "tr_Wifi38": "인텔® Wireless-AC 9260",
      "tr_Wifi39": "인텔® Wireless-AC 9461",
      "tr_Wifi40": "인텔® Wireless-AC 9462",
      "tr_Wifi41": "인텔® Wireless-AC 9560",
      "tr_Wifi42": "인텔® Wireless-N 3160",
      "tr_Wifi43": "인텔® Wireless-N 7260",
      "tr_Wifi44": "인텔® Wireless-N 7265",
      "tr_Wifi45": "인텔® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "인텔® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "인텔® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "인텔® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "인텔® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "인텔® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "인텔® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "인텔® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "인텔® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "인텔® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "인텔® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "인텔® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "인텔® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "인텔® Wi-Fi 7 BE201",
      "tr_Wifi59": "인텔® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "인텔® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "지금 구매하기",
    "tr_textTryagain": "다시 시도",
    "tr_textBuynowErr": "연결이 중단되어 '지금 구매하기' 옵션을 사용할 수 없습니다.",
    "tr_chatbotPlaceholder_text": "장치에 대해 무엇이든 물어보세요.",
    "tr_deviceSpecs_text": "장치 사양"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "인텔® Hybrid Technology를 탑재한 인텔® 코어™ i3 프로세서"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "인텔® Hybrid Technology를 탑재한 인텔® 코어™ i5 프로세서"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "마블 어벤져스",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "인텔®로 무장하여 더욱 강력한 게이밍 경험 ",
                "tr_subtitle": "인텔이 제공하는 인게임 PC 최적화로 실감 나는 마블 어벤져스*를 즐겨보세요.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "인텔® 익스트림 마스터즈 경험",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "세계 최고 게이머들의 대결이 열리는 경쟁의 장",
                "tr_subtitle": "비디오 게임 역사상 가장 오랫동안 진행된 이벤트 시리즈인 인텔® 익스트림 마스터즈는 e스포츠의 표준을 확립합니다.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "프로처럼 플레이하세요",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "인텔과 함께 챔피언 탄생",
                "tr_subtitle": "2021년 레인보우 식스 시즈* 북미 리그 챔피언십에서 우승한 Soniqs가 게이밍 역사와 e스포츠의 미래, 인텔과의 파트너십을 되돌아봅니다.",
                "tr_pretitle": "Soniqs 소개",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "VR 지원",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "VR을 더욱더 생생하게",
                "tr_subtitle": "실제와 같이 놀랍고 몰입감 높은 가상 현실 경험이 컴퓨터에서 펼쳐집니다. 최신 인텔® 코어™ 프로세서라면 누구나 될 수 있고, 어디든 갈 수 있으며, 또 다른 차원에서 게임을 경험할 수 있습니다.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Xbox* Game Pass Ultimate 및 호환 컨트롤러를 통해 Sea of Thieves*, Psychonauts* 2를 비롯한 100개 이상의 고품질 콘솔 게임을 Windows* PC, 휴대폰 및 태블릿에서 플레이해 보세요.",
                "titleArray": [
                  {
                    "tr_title": "여러 장치에서 함께 플레이",
                    "tr_subtitle": "함께 플레이할 준비를 마친 수백만 명의 플레이어가 참여하는 커뮤니티를 통해 Xbox*의 중심에 다가서세요. 상대방이 지구 반대편에 있든 내 옆에 앉아 있든, 공유 게임 라이브러리를 통해 다른 사람과 연결하고 플레이하세요."
                  },
                  {
                    "tr_title": "Windows* PC 게이밍",
                    "tr_subtitle": "Windows*용 Xbox* 앱과 호환 컨트롤러를 사용하여 클라우드에서 다양한 콘솔 게임을 플레이하세요."
                  },
                  {
                    "tr_title": "원하는 장소에서 플레이",
                    "tr_subtitle": "그 어느 때보다 더 많은 장소에서 Xbox* 게임을 즐겨보세요. 고속 인터넷 연결만 있다면 집과 카페를 비롯한 다양한 장소에서 PC, 휴대폰, 태블릿 등으로 100개 이상의 게임을 플레이할 수 있습니다."
                  },
                  {
                    "tr_title": "컨트롤러 없이 누리는 즐거움",
                    "tr_subtitle": "Xbox* 터치 컨트롤 게이밍은 물리적인 컨트롤러를 사용했을 때와 같은 수준의 친숙한 플레이 경험을 제공합니다."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "주요 특징",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100가지가 넘는 AI 경험",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "더 많이 제어하고, 더 적게 터치하십시오",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "어떤 환경에서도 프레임 안에 담아냅니다",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "창의성",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "전문가 수준의 조명 보정",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "손쉽게 다양한 효과를 추가하세요",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "AI 기능으로 노래를 리믹스하세요",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "생산성",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "생산성 및 보안 향상",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "전원 연결 없이 더 많은 시간을 보내십시오",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "디바이스에서 펼쳐지는 나만의&nbsp;세계",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "엔터테인먼트",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "전원 분리 상태에서도 원활한 스트리밍이 가능합니다",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "빠르고 몰입감 넘치는 게이밍",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "주요 특징",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100가지가 넘는 AI 경험",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "좋아하는 노래에 나만의 스타일 더하기",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "당신의 상상을 현실로 만나보세요",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "창의성",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "최종 편집을 위한 가장 빠른 방법",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "모든 사진을 완벽하게",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "생산성",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "생산성 및 보안 향상",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "더 효율적으로. 더 자유롭게.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "디바이스에서 펼쳐지는 나만의&nbsp;세계",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "엔터테인먼트",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "전원이 분리된 상태에서도 원활한 HD 스트리밍이 가능합니다",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "더 다양한 장소에서 몰입감 넘치는 게이밍",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "더 크리에이티브하게",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "더욱 손쉬워진 사진 편집",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "단 몇 초 내에 간직할 만한 사진을 만드세요",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "원하는 것을 입력하면 AI가 만듭니다",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "더 강력하게",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "더 매끄러운 게이밍을 위한 치트키",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "전원 연결 없이 더 많은 시간을 보내십시오",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-immersive",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "더 빠르게",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "당신 곁의 AI 파트너",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "완벽한 비디오 콜",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "디바이스에서 펼쳐지는 나만의&nbsp;세계",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "더 안전하게",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "데이터를 더 안전하고 비공개로 유지하는 내장 보호 기능",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "더 크리에이티브하게",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "더욱 손쉬워진 사진 편집",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI. 더 쉽고 더 빠르게 최종본을 완성하세요.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "좋아하는 가사를 빠르게 텍스트로 변환하세요",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "AI로 편집 시간 절약",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "더 강력하게",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "더 매끄러운 게이밍을 위한 치트키",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "전원 연결 없이 더 많은 시간을 보내십시오",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-immersive",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "더 빠르게",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "당신 곁의 AI 파트너",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "완벽한 비디오 콜",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "디바이스에서 펼쳐지는 나만의&nbsp;세계",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "더 안전하게",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "데이터를 더 안전하고 비공개로 유지하는 내장 보호 기능",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "좋아하는 모든 엔터테인먼트를 PC&nbsp;하나로.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "PC를 엔터테인먼트 센터로도 사용하려면 좋아하는 노래와 동영상 및 게임을 저장할 수 있는 충분한 스토리지와 몰입도 높은 비주얼을 선사할 프로세서 및 디스플레이를 적절히 갖추어야 합니다. 이 PC는 이 세 가지 구성 요소를 모두 충족합니다.",
                "titleArray": [
                  {
                    "tr_title": "차세대 비주얼 대비",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "나만의 디지털 라이브러리 구축",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "엔터테인먼트를 위한 도구",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "원활한 게이밍 및 스트리밍",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "나만의 디지털 라이브러리 구축",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "엔터테인먼트를 위한 도구",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "생산성을 높이세요. <br/>장소와 상관없이.",
              "tr_tileTitle1": "더 많은 작업을 더 빠르게.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "성능과 휴대성의 완벽한 조화를 자랑하는 PC입니다. 얇고 가벼운 디자인을 위해 고안된 프로세서를 비롯하여 사용자의 연결 유지를 보장하는 Wi&#8209;Fi와 전원이 필요 없는 적합한 배터리를 구비하고 있습니다.",
                "tr_subtitle1": "이 PC는 파워와 성능의 완벽한 균형을 통해 더 많은 작업을 더욱더 빨리 처리합니다. 대용량 메모리와 번개처럼 빠른 Wi-Fi로 더 빨라진 강력한 프로세서로 일상 작업이 더욱더 수월해집니다.",
                "tr_subtitle2": "성능과 휴대성의 완벽한 조화를 자랑하는 PC입니다. 얇고 가벼운 디자인을 위해 고안된 프로세서를 비롯하여 사용자의 연결 유지를 보장하는 Wi‑Fi와 멀티태스킹을 위한 메모리까지 모든 것을 구비하고 있습니다.",
                "titleArray": [
                  {
                    "tr_title": "더 많은 작업을 위한 설계",
                    "tr_title1": "중요한 지능형 성능",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "동급 최강의 연결",
                    "tr_title1": "효과적으로 연결",
                    "tr_title2": "Wi‑Fi의 비약적인 진화",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "전원 연결 없이 오래가는 배터리",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "전문가처럼 여러 프로그램을 동시 처리",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "자유로운 제작",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "콘텐츠 제작은 PC에서 가장 까다로운 작업 중 하나입니다. 다행히 이들 구성 요소를 통해 크리에이티브 프로세스가 간소해집니다.",
                "titleArray": [
                  {
                    "tr_title": "더 스마트한 제작 지원",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "더욱더 생생한 이미지",
                    "tr_title1": "콘텐츠 제작 시작",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "멀티태스킹을 위한 메모리",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "PC 들여다보기",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "새 PC를 선택할 때 눈여겨봐야 할 세 가지 주요 구성 요소가 있습니다. 새로운 PC를 어떤 용도로 사용할지를 결정하고 나면 이 세 가지 주요 구성 요소가 얼마나 필요한지를 파악할 수 있습니다. 세 가지 주요 구성 요소는 다음과 같습니다.",
                "titleArray": [
                  {
                    "tr_title": "게이밍 및 스트리밍을 위한 성능",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "스토리지",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "메모리",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "좋아하는 모든 엔터테인먼트를 PC&nbsp;하나로.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "PC를 엔터테인먼트 센터로도 사용하려면 좋아하는 노래와 동영상 및 게임을 저장할 수 있는 충분한 스토리지와 몰입도 높은 비주얼을 선사할 프로세서 및 디스플레이를 적절히 갖추어야 합니다. 이 PC는 이 세 가지 구성 요소를 모두 충족합니다.",
                "titleArray": [
                  {
                    "tr_title": "차세대 비주얼 대비",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "나만의 디지털 라이브러리 구축",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "엔터테인먼트를 위한 도구",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "원활한 게이밍 및 스트리밍",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "나만의 디지털 라이브러리 구축",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "엔터테인먼트를 위한 도구",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "메모리",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "디스플레이",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "고속 멀티태스킹",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "고속 멀티태스킹",
                "tr_subtitle": "속도 저하 없이 더&nbsp;많은 작업을 처리",
                "tr_subtitle1": "이 PC에서 원활한 멀티태스킹이 가능한 이유.",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "여러 프로그램을 동시에 실행할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "빠르고 강력하며 안정적인 Wi‑Fi"
                  },
                  {
                    "tr_title": "애플리케이션 간에 원활하게 전환되는 메모리"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "비디오 채팅",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "비디오 채팅",
                "tr_subtitle": "매우 깨끗한 연결",
                "tr_subtitle1": "이 PC에는 화상 채팅에 편리한 기능이 있습니다.",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "이미지 녹화와 공유를 동시에 할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "빠르고 강력하며 안정적인 Wi‑Fi"
                  },
                  {
                    "tr_title": "매우 선명한 이미지를 제공하는 웹캠"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR 지원",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR을 더욱더 생생하게",
                "tr_subtitle": "완벽한 가상 세계를 구현하려면 어떤 요소가 필요할까요? PC의 프로세서, 그래픽 카드와 메모리 성능의 조화가 필요합니다.",
                "titleArray": [
                  {
                    "tr_title": "가상 세계 지원",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "현실감을 더해주는 디테일",
                    "tr_title1": "몰입감 넘치는 경험",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "비디오 편집",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "비디오 편집",
                "tr_subtitle": "마법 같은 영상을 제작하세요",
                "tr_subtitle1": "이 PC에는 비디오 편집에 편리한 기능이 있습니다.",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "대용량 파일을 처리할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "빠르고 반응성이 뛰어난 편집 소프트웨어를 위한 메모리"
                  },
                  {
                    "tr_title": "렌더링 시간을 줄여주는 그래픽"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "고속 멀티태스킹",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "고속 멀티태스킹",
                "tr_subtitle": "속도 저하 없이 더&nbsp;많은 작업을 처리",
                "tr_subtitle1": "이 PC에서 원활한 멀티태스킹이 가능한 이유.",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "여러 프로그램을 동시에 실행할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "빠르고 강력하며 안정적인 Wi‑Fi"
                  },
                  {
                    "tr_title": "애플리케이션 간에 원활하게 전환되는 메모리"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR 지원",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR을 더욱더 생생하게",
                "tr_subtitle": "완벽한 가상 세계를 구현하려면 어떤 요소가 필요할까요? PC의 프로세서, 그래픽 카드와 메모리 성능의 조화가 필요합니다.",
                "titleArray": [
                  {
                    "tr_title": "가상 세계 지원",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "현실감을 더해주는 디테일",
                    "tr_title1": "몰입감 넘치는 경험",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D 모델링",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D 모델링",
                "tr_subtitle": "한 차원 높은 창작",
                "tr_subtitle1": "이 PC에는 3D 모델링에 편리한 기능이 있습니다.",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "고급 크리에이티브 소프트웨어를 처리할&nbsp;수 있는 프로세서"
                  },
                  {
                    "tr_title": "대용량 파일을 빠르게 불러올 수 있는 메모리"
                  },
                  {
                    "tr_title": "강력한 이미지 생성 및 편집을 위한 그래픽"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "실시간 협업",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "실시간 협업",
                "tr_subtitle": "어디서나 작업할 수 있습니다",
                "tr_subtitle1": "이 PC에는 온라인 협업에 편리한 기능이 있습니다.",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "사무용 소프트웨어를 구동하는 프로세서"
                  },
                  {
                    "tr_title": "신뢰할 수 있는 Wi‑Fi"
                  },
                  {
                    "tr_title": "선명한 HD로 녹화하는 웹캠"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR 지원",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR을 더욱더 생생하게",
                "tr_subtitle": "완벽한 가상 세계를 구현하려면 어떤 요소가 필요할까요? PC의 프로세서, 그래픽 카드와 메모리 성능의 조화가 필요합니다.",
                "titleArray": [
                  {
                    "tr_title": "가상 세계 지원",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "현실감을 더해주는 디테일",
                    "tr_title1": "몰입감 넘치는 경험",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "인텔® Evo™ 노트북",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "thin & light 노트북이 이&nbsp;정도로 강력할 수 있을까요?",
                "tr_subtitle": "우수한 모바일 성능을 위해 설계된 인텔® Evo™ 노트북은 가장 세련된 thin & light 디자인에 속도, 그래픽, 배터리 수명을 모두 갖추었습니다.",
                "titleArray": [
                  {
                    "tr_title": "노트북, 인텔을 만나 그 이상이 되다.",
                    "tr_subtitle": "터치스크린을 사용하여 탐색하고, 회전하고, 확대할 수 있습니다."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "즉각적인 부팅",
                    "tr_subtitle": "즉각적인 부팅으로 PC가 1초 이내에 절전 모드에서 깨어납니다."
                  },
                  {
                    "tr_title": "몰입감 있는 시청",
                    "tr_subtitle": "아름다운 디스플레이가 선명한 해상도와 생생한 컬러로 새로운 시각적 경험을 구&#65279;현&#65279;합&#65279;니&#65279;다&#65279;."
                  },
                  {
                    "tr_title": "신속한 작업 처리",
                    "tr_subtitle": "11세대 인텔® 코어™ 프로세서의 빠른 반응성과 성능을 즐기십시오."
                  },
                  {
                    "tr_title": "더 오래가는 배터리",
                    "tr_subtitle": "배터리 수명을 최적화하고 초고속 충전을 제공하는 PC에서 여유롭게 작업을 진행하십시오."
                  },
                  {
                    "tr_title": "범용 케이블 연결성",
                    "tr_subtitle": "Thunderbolt™ 4 기술로 다른 장치를 PC에 연결하는 작업이 단순하고, 빠르고, 안전해집니다.<sup>10</sup>"
                  },
                  {
                    "tr_title": "빠르고 안정적인 연결",
                    "tr_subtitle": "인텔® Wi‑Fi 6(Gig+)를 통한 안정적인 연결로 비디오 채팅 또는 파일 공유를 즐&#65279;기&#65279;십&#65279;시&#65279;오&#65279;.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "인텔® Evo™ 노트북",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "필요한 모든 것과 그 이상을 제공하는 thin & light 노트북",
                "tr_subtitle": "인텔® Evo™ 노트북은 우수한 모바일 성능을 위해 설계되었으며 어떤 작업에서나 최고의 경험<sup>2</sup>을 선사합니다.",
                "titleArray": [
                  {
                    "tr_title": "노트북, 인텔을 만나 그 이상이 되다.",
                    "tr_subtitle": "터치스크린을 사용하여 탐색하고, 회전하고, 확대할 수 있습니다."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "즉각적인 부팅",
                    "tr_subtitle": "즉각적인 부팅으로 PC가 1초 이내에 절전 모드에서 깨어납니다."
                  },
                  {
                    "tr_title": "몰입감 있는 시청",
                    "tr_subtitle": "아름다운 디스플레이가 선명한 해상도와 생생한 컬러로 새로운 시각적 경험을 구&#65279;현&#65279;합&#65279;니&#65279;다&#65279;."
                  },
                  {
                    "tr_title": "혁신적인 성능",
                    "tr_subtitle": "12세대 인텔® 코어™ 프로세서의 지능적인 성능으로 가장 필요한 곳에서 더 많은 성능을 확보합니다."
                  },
                  {
                    "tr_title": "더 오래가는 배터리",
                    "tr_subtitle": "배터리 수명을 최적화하고 초고속 충전을 제공하는 PC에서 여유롭게 작업을 진행하십시오."
                  },
                  {
                    "tr_title": "범용 케이블 연결성",
                    "tr_subtitle": "Thunderbolt™ 4 기술로 다른 장치를 PC에 연결하는 작업이 단순하고, 빠르고, 안전해집니다.<sup>10</sup>"
                  },
                  {
                    "tr_title": "빠르고 안정적인 연결",
                    "tr_subtitle": "인텔® Wi-Fi 6E(Gig+)를 통한 신뢰할 수 있는 연결로 비디오 채팅 또는 파일 공유<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "인텔® Evo™ 노트북",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "thin & light 노트북이 이&nbsp;정도로 강력할 수 있을까요?",
                "tr_subtitle": "우수한 모바일 성능을 위해 설계된 인텔® Evo™ 노트북은 가장 세련된 thin & light 디자인에 속도, 그래픽, 배터리 수명을 모두 갖추었습니다.",
                "titleArray": [
                  {
                    "tr_title": "노트북, 인텔을 만나 그 이상이 되다.",
                    "tr_subtitle": "터치스크린을 사용하여 탐색하고, 회전하고, 확대할 수 있습니다."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "즉각적인 부팅",
                    "tr_subtitle": "즉각적인 부팅으로 PC가 1초 이내에 절전 모드에서 깨어납니다."
                  },
                  {
                    "tr_title": "몰입감 있는 시청",
                    "tr_subtitle": "아름다운 디스플레이가 선명한 해상도와 생생한 컬러로 새로운 시각적 경험을 구&#65279;현&#65279;합&#65279;니&#65279;다&#65279;."
                  },
                  {
                    "tr_title": "신속한 작업 처리",
                    "tr_subtitle": "11세대 인텔® 코어™ 프로세서의 빠른 반응성과 성능을 즐기십시오."
                  },
                  {
                    "tr_title": "더 오래가는 배터리",
                    "tr_subtitle": "배터리 수명을 최적화하고 초고속 충전을 제공하는 PC에서 여유롭게 작업을 진행하십시오."
                  },
                  {
                    "tr_title": "범용 케이블 연결성",
                    "tr_subtitle": "Thunderbolt™ 4 기술로 다른 장치를 PC에 연결하는 작업이 단순하고, 빠르고, 안전해집니다.<sup>10</sup>"
                  },
                  {
                    "tr_title": "빠르고 안정적인 연결",
                    "tr_subtitle": "인텔® Wi‑Fi 6(Gig+)를 통한 안정적인 연결로 비디오 채팅 또는 파일 공유를 즐&#65279;기&#65279;십&#65279;시&#65279;오&#65279;.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "인텔® Evo™ 노트북",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "필요한 모든 것과 그 이상을 제공하는 thin & light 노트북",
                "tr_subtitle": "인텔® Evo™ 노트북은 우수한 모바일 성능을 위해 설계되었으며 어떤 작업에서나 최고의 경험<sup>2</sup>을 선사합니다.",
                "titleArray": [
                  {
                    "tr_title": "노트북, 인텔을 만나 그 이상이 되다.",
                    "tr_subtitle": "터치스크린을 사용하여 탐색하고, 회전하고, 확대할 수 있습니다."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "즉각적인 부팅",
                    "tr_subtitle": "즉각적인 부팅으로 PC가 1초 이내에 절전 모드에서 깨어납니다."
                  },
                  {
                    "tr_title": "몰입감 있는 시청",
                    "tr_subtitle": "아름다운 디스플레이가 선명한 해상도와 생생한 컬러로 새로운 시각적 경험을 구&#65279;현&#65279;합&#65279;니&#65279;다&#65279;."
                  },
                  {
                    "tr_title": "혁신적인 성능",
                    "tr_subtitle": "12세대 인텔® 코어™ 프로세서의 지능적인 성능으로 가장 필요한 곳에서 더 많은 성능을 확보합니다."
                  },
                  {
                    "tr_title": "더 오래가는 배터리",
                    "tr_subtitle": "배터리 수명을 최적화하고 초고속 충전을 제공하는 PC에서 여유롭게 작업을 진행하십시오."
                  },
                  {
                    "tr_title": "범용 케이블 연결성",
                    "tr_subtitle": "Thunderbolt™ 4 기술로 다른 장치를 PC에 연결하는 작업이 단순하고, 빠르고, 안전해집니다.<sup>10</sup>"
                  },
                  {
                    "tr_title": "빠르고 안정적인 연결",
                    "tr_subtitle": "인텔® Wi-Fi 6E(Gig+)를 통한 신뢰할 수 있는 연결로 비디오 채팅 또는 파일 공유<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "인텔® Evo™ 노트북",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "필요한 모든 것과 그 이상을 제공하는 thin & light 노트북",
                "tr_subtitle": "인텔® Evo™ 노트북은 우수한 모바일 성능을 위해 설계되었으며 어떤 작업에서나 최고의 경험<sup>2</sup>을 선사합니다.",
                "titleArray": [
                  {
                    "tr_title": "노트북, 인텔을 만나 그 이상이 되다.",
                    "tr_subtitle": "터치스크린을 사용하여 탐색하고, 회전하고, 확대할 수 있습니다."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "즉각적인 부팅",
                    "tr_subtitle": "즉각적인 부팅으로 PC가 1초 이내에 절전 모드에서 깨어납니다."
                  },
                  {
                    "tr_title": "몰입감 있는 시청",
                    "tr_subtitle": "아름다운 디스플레이가 선명한 해상도와 생생한 컬러로 새로운 시각적 경험을 구&#65279;현&#65279;합&#65279;니&#65279;다&#65279;."
                  },
                  {
                    "tr_title": "혁신적인 성능",
                    "tr_subtitle": "13세대 인텔® 코어™ 프로세서의 지능적인 성능으로 가장 필요한 곳에서 더 많은 성능을 확보합니다."
                  },
                  {
                    "tr_title": "더 오래가는 배터리",
                    "tr_subtitle": "배터리 수명을 최적화하고 초고속 충전을 제공하는 PC에서 여유롭게 작업을 진행하십시오."
                  },
                  {
                    "tr_title": "범용 케이블 연결성",
                    "tr_subtitle": "Thunderbolt™ 4 기술로 다른 장치를 PC에 연결하는 작업이 단순하고, 빠르고, 안전해집니다.<sup>10</sup>"
                  },
                  {
                    "tr_title": "빠르고 안정적인 연결",
                    "tr_subtitle": "인텔® Wi-Fi 6E(Gig+)를 통한 신뢰할 수 있는 연결로 비디오 채팅 또는 파일 공유<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "인텔® Evo™ 노트북",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "필요한 모든 것과 그 이상을 제공하는 thin & light 노트북",
                "tr_subtitle": "인텔® Evo™ 노트북은 우수한 모바일 성능을 위해 설계되었으며 어떤 작업에서나 최고의 경험<sup>2</sup>을 선사합니다.",
                "titleArray": [
                  {
                    "tr_title": "노트북, 인텔을 만나 그 이상이 되다.",
                    "tr_subtitle": "터치스크린을 사용하여 탐색하고, 회전하고, 확대할 수 있습니다."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "즉각적인 부팅",
                    "tr_subtitle": "즉각적인 부팅으로 PC가 1초 이내에 절전 모드에서 깨어납니다."
                  },
                  {
                    "tr_title": "몰입감 있는 시청",
                    "tr_subtitle": "아름다운 디스플레이가 선명한 해상도와 생생한 컬러로 새로운 시각적 경험을 구&#65279;현&#65279;합&#65279;니&#65279;다&#65279;."
                  },
                  {
                    "tr_title": "혁신적인 성능",
                    "tr_subtitle": "13세대 인텔® 코어™ 프로세서의 지능적인 성능으로 가장 필요한 곳에서 더 많은 성능을 확보합니다."
                  },
                  {
                    "tr_title": "더 오래가는 배터리",
                    "tr_subtitle": "배터리 수명을 최적화하고 초고속 충전을 제공하는 PC에서 여유롭게 작업을 진행하십시오."
                  },
                  {
                    "tr_title": "범용 케이블 연결성",
                    "tr_subtitle": "Thunderbolt™ 4 기술로 다른 장치를 PC에 연결하는 작업이 단순하고, 빠르고, 안전해집니다.<sup>10</sup>"
                  },
                  {
                    "tr_title": "빠르고 안정적인 연결",
                    "tr_subtitle": "인텔® Wi-Fi 6E(Gig+)를 통한 신뢰할 수 있는 연결로 비디오 채팅 또는 파일 공유<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "주요 특징",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "인텔® Evo™ 에디션이란 무엇입니까?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "인텔® Evo™ 에디션을 선택해야 하는 이유",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "창의성",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "자유롭게 창의성 발휘하기",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "더 빨라진 사진 편집",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "원하는 즉시 예술작품 생성",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "생산성",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rewind AI로 생산성 극대화",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "공유 전에 AI를 통해 딥페이크 탐지",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "디바이스에서 펼쳐지는 나만의&nbsp;세계",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "휴대성",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "제스처 제어를 만나보십시오",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "원하는 작업을 처리하기에 충분한 배터리 성능",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "자동으로 연결 유지",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "주요 특징",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "인텔® Evo™ 에디션이란 무엇입니까?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "인텔® Evo™ 에디션을 선택해야 하는 이유",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "창의성",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "빠르고 부드러운 비디오 재생",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "AI 기능으로 음악 프로듀싱",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "최종 편집을 위한 가장 빠른 방법",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "생산성",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rewind AI로 생산성 극대화",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "공유 전에 AI를 통해 딥페이크 탐지",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "디바이스에서 펼쳐지는 나만의&nbsp;세계",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "휴대성",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "어디서나 자유롭게 협업",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "원하는 작업을 처리하기에 충분한 배터리 성능",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "자동으로 연결 유지",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "좋아하는 모든 엔터테인먼트를 PC&nbsp;하나로.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "PC를 엔터테인먼트 센터로도 사용하려면 좋아하는 노래와 동영상 및 게임을 저장할 수 있는 충분한 스토리지와 몰입도 높은 비주얼을 선사할 프로세서 및 디스플레이를 적절히 갖추어야 합니다. 이 PC는 이 세 가지 구성 요소를 모두 충족합니다.",
                "titleArray": [
                  {
                    "tr_title": "탁월한 게이밍 및 스트리밍 경험",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "나만의 디지털 라이브러리 구축",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "엔터테인먼트를 위한 도구",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "원활한 게이밍 및 스트리밍",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "나만의 디지털 라이브러리 구축",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "엔터테인먼트를 위한 도구",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "생산성을 높이세요. <br/>장소와 상관없이.",
              "tr_tileTitle1": "더 많은 작업을 더 빠르게.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "성능과 휴대성의 완벽한 조화를 자랑하는 PC입니다. 얇고 가벼운 디자인을 위해 고안된 프로세서를 비롯하여 사용자의 연결 유지를 보장하는 Wi&#8209;Fi와 전원이 필요 없는 적합한 배터리를 구비하고 있습니다.",
                "tr_subtitle1": "이 PC는 파워와 성능의 완벽한 균형을 통해 더 많은 작업을 더욱더 빨리 처리합니다. 대용량 메모리와 번개처럼 빠른 Wi-Fi로 더 빨라진 강력한 프로세서로 일상 작업이 더욱더 수월해집니다.",
                "tr_subtitle2": "성능과 휴대성의 완벽한 조화를 자랑하는 PC입니다. 얇고 가벼운 디자인을 위해 고안된 프로세서를 비롯하여 사용자의 연결 유지를 보장하는 Wi‑Fi와 멀티태스킹을 위한 메모리까지 모든 것을 구비하고 있습니다.",
                "titleArray": [
                  {
                    "tr_title": "중요한 지능형 성능",
                    "tr_title1": "지능적인 성능으로 속도 향상",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "동급 최강의 연결",
                    "tr_title1": "효과적으로 연결",
                    "tr_title2": "Wi‑Fi의 비약적인 진화",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "전원 연결 없이 오래가는 배터리",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "전문가처럼 여러 프로그램을 동시 처리",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "자유로운 제작",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "콘텐츠 제작은 PC에서 가장 까다로운 작업 중 하나입니다. 다행히 이들 구성 요소를 통해 크리에이티브 프로세스가 간소해집니다.",
                "titleArray": [
                  {
                    "tr_title": "창의성 촉진",
                    "tr_title1": "원활하게 즐기는 창작 프로젝트",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "제작 경험 향상",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "멀티태스킹을 위한 메모리",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "PC 들여다보기",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "새 PC를 선택할 때 눈여겨봐야 할 세 가지 주요 구성 요소가 있습니다. 새로운 PC를 어떤 용도로 사용할지를 결정하고 나면 이 세 가지 주요 구성 요소가 얼마나 필요한지를 파악할 수 있습니다. 세 가지 주요 구성 요소는 다음과 같습니다.",
                "titleArray": [
                  {
                    "tr_title": "프로세서",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "스토리지",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "메모리",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "자유로운 제작",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "콘텐츠 제작은 PC에서 가장 까다로운 작업 중 하나입니다. 다행히 이들 구성 요소를 통해 크리에이티브 프로세스가 간소해집니다.",
                "titleArray": [
                  {
                    "tr_title": "더 스마트한 제작 지원",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "더욱더 생생한 이미지",
                    "tr_title1": "콘텐츠 제작 시작",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "멀티태스킹을 위한 메모리",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "생산성을 높이세요. <br/>장소와 상관없이.",
              "tr_tileTitle1": "더 많은 작업을 더 빠르게.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "성능과 휴대성의 완벽한 조화를 자랑하는 PC입니다. 얇고 가벼운 디자인을 위해 고안된 프로세서를 비롯하여 사용자의 연결 유지를 보장하는 Wi&#8209;Fi와 전원이 필요 없는 적합한 배터리를 구비하고 있습니다.",
                "tr_subtitle1": "이 PC는 파워와 성능의 완벽한 균형을 통해 더 많은 작업을 더욱더 빨리 처리합니다. 대용량 메모리와 번개처럼 빠른 Wi-Fi로 더 빨라진 강력한 프로세서로 일상 작업이 더욱더 수월해집니다.",
                "tr_subtitle2": "성능과 휴대성의 완벽한 조화를 자랑하는 PC입니다. 얇고 가벼운 디자인을 위해 고안된 프로세서를 비롯하여 사용자의 연결 유지를 보장하는 Wi‑Fi와 멀티태스킹을 위한 메모리까지 모든 것을 구비하고 있습니다.",
                "titleArray": [
                  {
                    "tr_title": "더 많은 작업을 위한 설계",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "동급 최강의 연결",
                    "tr_title1": "효과적으로 연결",
                    "tr_title2": "Wi‑Fi의 비약적인 진화",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "전원 연결 없이 오래가는 배터리",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "전문가처럼 여러 프로그램을 동시 처리",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR 지원",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR을 더욱더 생생하게",
                "tr_subtitle": "완벽한 가상 세계를 구현하려면 어떤 요소가 필요할까요? PC의 프로세서, 그래픽 카드와 메모리 성능의 조화가 필요합니다.",
                "titleArray": [
                  {
                    "tr_title": "가상 세계 지원",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "현실감을 더해주는 디테일",
                    "tr_title1": "몰입감 넘치는 경험",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "삼성 OLED:<br/>시각적으로 편안한 화면",
      "tr_sub_header": "블루라이트는 시각적인 불편함을 유발하고 수면 주기를 방해할 수 있습니다. SGS 눈 건강 관리 디스플레이 테스트를 거친 삼성 OLED는 색상을 변경하는 것이 아니라 잠재적으로 유해한 블루라이트의 파장을 줄여 눈에 편안함을 제공하고 눈의 피로를 줄여줍니다.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "덜 유해한 블루라이트"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "모든 면에서 울트라",
      "tr_sub_header": "OLED의 초경량, 초박형 디자인은 무게를 최소화하면서 화질을 극대화합니다."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "트루 블랙",
      "tr_sub_header": "0.0005nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>기존 방식</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>삼성 OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "스트리밍",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "스트리밍",
                "tr_subtitle": "무한한 엔터테인먼트",
                "tr_subtitle1": "이 PC에는 스트리밍에 편리한 기능이 있습니다.",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "HD로 스트리밍할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "밝고 아름다운 이미지를 위한 디스플레이"
                  },
                  {
                    "tr_title": "버퍼링 및 로드 시간이 적은 Wi‑Fi"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "웹 탐색",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "웹 탐색",
                "tr_subtitle": "인터넷이 간편해집니다",
                "tr_subtitle1": "이 PC에는 웹 탐색에 편리한 기능이 있습니다.",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "미디어가 풍성한 페이지를 빠르게 로드할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "빠르고 안정적인 Wi‑Fi"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "비디오 채팅",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "비디오 채팅",
                "tr_subtitle": "매우 깨끗한 연결",
                "tr_subtitle1": "이 PC에는 화상 채팅에 편리한 기능이 있습니다.",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "이미지 녹화와 공유를 동시에 할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "빠르고 강력하며 안정적인 Wi‑Fi"
                  },
                  {
                    "tr_title": "매우 선명한 이미지를 제공하는 웹캠"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "고속 멀티태스킹",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "고속 멀티태스킹",
                "tr_subtitle": "속도 저하 없이 더&nbsp;많은 작업을 처리",
                "tr_subtitle1": "이 PC에서 원활한 멀티태스킹이 가능한 이유.",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "여러 프로그램을 동시에 실행할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "빠르고 강력하며 안정적인 Wi‑Fi"
                  },
                  {
                    "tr_title": "애플리케이션 간에 원활하게 전환되는 메모리"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "비디오 채팅",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "비디오 채팅",
                "tr_subtitle": "매우 깨끗한 연결",
                "tr_subtitle1": "이 PC에는 화상 채팅에 편리한 기능이 있습니다.",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "이미지 녹화와 공유를 동시에 할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "빠르고 강력하며 안정적인 Wi‑Fi"
                  },
                  {
                    "tr_title": "매우 선명한 이미지를 제공하는 웹캠"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "캐주얼 게임",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "캐주얼 게임",
                "tr_subtitle": "플레이 시작",
                "tr_subtitle1": "이 PC에는 가벼운 게임 플레이에 편리한 기능이 있습니다.",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "원활한 게임 플레이를 지원하는 프로세서"
                  },
                  {
                    "tr_title": "음성 채팅과 스트리밍을 동시에 할 수 있는 메모리"
                  },
                  {
                    "tr_title": "놀라운 이미지를 제공하는 그래픽"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "사진 편집",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "사진 편집",
                "tr_subtitle": "픽셀이 완벽하게 살아 있는 사진",
                "tr_subtitle1": "이 PC에는 사진 편집에 편리한 기능이 있습니다.",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "대용량 이미지 그룹을 편집할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "편집 소프트웨어를 가속하는 메모리"
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 기술로 파일을 빠르게 전송할 수 있습니다."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "고속 멀티태스킹",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "고속 멀티태스킹",
                "tr_subtitle": "속도 저하 없이 더&nbsp;많은 작업을 처리",
                "tr_subtitle1": "이 PC에서 원활한 멀티태스킹이 가능한 이유.",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "여러 프로그램을 동시에 실행할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "빠르고 강력하며 안정적인 Wi‑Fi"
                  },
                  {
                    "tr_title": "애플리케이션 간에 원활하게 전환되는 메모리"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "실시간 협업",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "실시간 협업",
                "tr_subtitle": "어디서나 작업할 수 있습니다",
                "tr_subtitle1": "이 PC에는 온라인 협업에 편리한 기능이 있습니다.",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "사무용 소프트웨어를 구동하는 프로세서"
                  },
                  {
                    "tr_title": "신뢰할 수 있는 Wi‑Fi"
                  },
                  {
                    "tr_title": "선명한 HD로 녹화하는 웹캠"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "비디오 편집",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "비디오 편집",
                "tr_subtitle": "마법 같은 영상을 제작하세요",
                "tr_subtitle1": "이 PC에는 비디오 편집에 편리한 기능이 있습니다.",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "대용량 파일을 처리할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "빠르고 반응성이 뛰어난 편집 소프트웨어를 위한 메모리"
                  },
                  {
                    "tr_title": "렌더링 시간을 줄여주는 그래픽"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "사진 편집",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "사진 편집",
                "tr_subtitle": "픽셀이 완벽하게 살아 있는 사진",
                "tr_subtitle1": "이 PC에는 사진 편집에 편리한 기능이 있습니다.",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "대용량 이미지 그룹을 편집할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "편집 소프트웨어를 가속하는 메모리"
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 기술로 파일을 빠르게 전송할 수 있습니다."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "비디오 편집",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "비디오 편집",
                "tr_subtitle": "마법 같은 영상을 제작하세요",
                "tr_subtitle1": "이 PC에는 비디오 편집에 편리한 기능이 있습니다.",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "대용량 파일을 처리할 수 있는 프로세서"
                  },
                  {
                    "tr_title": "빠르고 반응성이 뛰어난 편집 소프트웨어를 위한 메모리"
                  },
                  {
                    "tr_title": "렌더링 시간을 줄여주는 그래픽"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D 모델링",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D 모델링",
                "tr_subtitle": "한 차원 높은 창작",
                "tr_subtitle1": "이 PC에는 3D 모델링에 편리한 기능이 있습니다.",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "고급 크리에이티브 소프트웨어를 처리할&nbsp;수 있는 프로세서"
                  },
                  {
                    "tr_title": "대용량 파일을 빠르게 불러올 수 있는 메모리"
                  },
                  {
                    "tr_title": "강력한 이미지 생성 및 편집을 위한 그래픽"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "실시간 협업",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "실시간 협업",
                "tr_subtitle": "어디서나 작업할 수 있습니다",
                "tr_subtitle1": "이 PC에는 온라인 협업에 편리한 기능이 있습니다.",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "사무용 소프트웨어를 구동하는 프로세서"
                  },
                  {
                    "tr_title": "신뢰할 수 있는 Wi‑Fi"
                  },
                  {
                    "tr_title": "선명한 HD로 녹화하는 웹캠"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "인텔® Unison™ 앱",
    "tr_title": "연결된 세상을 잠금 해제",
    "tr_subtitle": "원활하게 파일과 사진을 전송하는 동시에 PC에서 휴대폰 알림, 전화와 문자를 관리하십시오.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "파일 및 사진 전송",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "전화 걸기 및 받기",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "문자 메시지 보내기 및 받기",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "휴대폰 알림 관리",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "연결된 세상을 잠금 해제",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}