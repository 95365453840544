const Library_mobile_nb = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Med en [cpuDisplay] &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Med en [cpuDisplay] &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Prosessoren styrer hva PC-en gjør, på samme måte som en hjerne. Det betyr at videoene du ser på, spillene du spiller, og nettstedene du besøker, starter med prosessoren.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Prosessoren er datamaskinens hjerne"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Ytelse og verdi.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Silver &#8209;prosessor",
                    "tr_subtitle": "Hold tritt med den digitale verdenen, med ytelse som gir tilgang til filer fra nettet. Bytt enkelt mellom programmer og få mer gjort på mindre tid.",
                    "tr_gen_core_title": "Intel® Pentium® Silver &#8209;prosessor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Gold &#8209;prosessor",
                    "tr_subtitle": "Forbedret produktivitet – økt ytelse for raskere utførelse av oppgaver. Effektiv nettsurfing – last raskt inn innholdsrike nettsider for mer surfing og mindre venting.",
                    "tr_gen_core_title": "Intel® Pentium® Gold &#8209;prosessor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Celeron® &#8209;prosessor",
                    "tr_subtitle": "Ytelse som gjør arbeidet enklere og underholdningen morsommere. Se på filmer og nettvideoer i fantastiske detaljer. Bruk mer tid på å jobbe og mindre tid på å vente.",
                    "tr_gen_core_title": "Intel® Celeron® &#8209;prosessor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® &#8209;prosessor",
                    "tr_subtitle": "Laget for fantastisk underholdning, videostreaming og produktivitet. Se filmer og nettvideoer i fantastiske detaljer. Bruk mer tid på å jobbe og mindre tid på å vente.",
                    "tr_gen_core_title": "Intel® Pentium® &#8209;prosessor"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Ytelsen du trenger. Kostnadsbesparelsen du ønsker.",
                "tr_title": "Intel® &#8209;prosessor",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Merverdi i sin egen kategori",
                    "tr_subtitle": "En Intel® -prosessor kan håndtere så og si enhver oppgave du har behov for til en pris du har råd til. Naviger enkelt fra et program til det neste. Eller nyt grafikk av høy kvalitet med stabil ytelse.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Få kraftigere forbindelser",
                    "tr_subtitle": "Mer livaktig bildekvalitet, fyldigere og klarere lyd og nesten 3x raskere internettforbindelse lar deg samarbeide uten problemer når du måtte trenge det.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Forskjellen er som dag og natt",
                    "tr_subtitle": "Intel® -prosessoren forlenger enhetens batterilevetid for en oppslukende og uavbrutt opplevelse, enten du ser på filmer fra morgen til kveld eller finpusser en presentasjon.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Hvert miljø er et læremiljø",
                    "tr_subtitle": "Kjør flere læreverktøy på nett med letthet fra en hvilken som helst krok i hjemmet ditt."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Lær der du vil",
                    "tr_subtitle": "Fullfør leksjoner når du trenger det mens du kjører forskjellige e&#8209;læringsverktøy samtidig."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Få kraften til underholdning og produktivitet som passer til budsjettet og behovene dine.",
                "tr_title": "Intel-ytelse for ethvert budsjett.",
                "tr_gen_core_title": "Intel® Celeron® &#8209;prosessor",
                "processorCompare": {
                  "tr_title": "FINN DET RIKTIGE NIVÅET AV YTELSE OG VERDI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Intel® Celeron® &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rask og innholdsrik nettsurfing",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rask veksling mellom programmer",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avslappet gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming av videoer i 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Lett bilde- og videoredigering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bedre produktivitet med intelligent ytelse",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Drevet av en 7.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;[cpu_fam] &#8209;prosessor"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Drevet av en 6.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;[cpu_fam] &#8209;prosessor"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Drevet av en 5.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;[cpu_fam] &#8209;prosessor"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Drevet av en 4.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;[cpu_fam] &#8209;prosessor"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Drevet av en 3.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;[cpu_fam] &#8209;prosessor"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Drevet av en 2.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;[cpu_fam] &#8209;prosessor"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Drevet av en 1.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;[cpu_fam] &#8209;prosessor"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Anbefales for:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Med en 8. generasjons Intel® Core™ i3 &#8209;prosessor",
                "tr_gen_core_title": "8. generasjons Intel® Core™ i3 &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. generasjons Intel® Core™ &#8209;prosessorer er laget for å holde tritt med din digitale verden. Takket være kombinasjonen av hastighet og ytelse vil du kunne gjøre mer av det du liker på datamaskinen, uten frustrasjon.",
                "tr_title": "Drevet av en 9.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i3 &#8209;prosessor",
                "tr_gen_core_title": "9. generasjons Intel® Core™ i3 &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Allsidig ytelse og rikelig med batterilevetid som holder deg i gang, hvor&nbsp;som&nbsp;helst.",
                "tr_title": "Kraft nok til hele dagen",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i3 &#8209;prosessor",
                "processorCompare": {
                  "tr_title": "FINN RIKTIG NIVÅ AV 10. GENERASJONS YTELSE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming av filmer i 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rask og pålitelig tilkobling",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bildemaskering og videofiltrering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming i 1080p og jevn bildefrekvens",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Best for arbeid med store filer og kreative programmer",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Gå fra å streame filmer til å lage presentasjoner med mye ytelse til overs. Helt enkelt.",
                "tr_title": "Kraft nok til hele dagen",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i3 &#8209;prosessor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "En bærbar datamaskin som er utviklet spesielt for dem som alltid er på farten.",
                "tr_title": "Møt en ny klasse med bærbare datamaskiner",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i3 &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. generasjons Intel® Core™ i3 -prossesoren er designet spesifikt for bærbare PC&#8209;er, og lar deg gjøre mer enn noen gang – uansett hvor du befinner deg.",
                "tr_title": "Få mer ut av den nye bærbare datamaskinen",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i3 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i3 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Når familien ser etter en stasjonær datamaskin som kan brukes til alt, trenger du kraften og allsidigheten til en 11. generasjons Intel® Core™ i3 &#8209;prosessor.",
                "tr_title": "Vær klar for alt med banebrytende ytelse.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i3 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i3 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Drevet av en 8.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                "tr_gen_core_title": "8. generasjons Intel® Core™ i5 &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. generasjons Intel® Core™ &#8209;prosessorer er laget for å holde tritt med din digitale verden. Takket være kombinasjonen av hastighet og ytelse vil du kunne gjøre mer av det du liker på datamaskinen, uten frustrasjon.",
                "tr_title": "Drevet av en 9.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                "tr_gen_core_title": "9. generasjons Intel® Core™ i5 &#8209;prosessor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "KNIRKEFRI YTELSE OG VR I SPILLENE SOM KREVER MEST",
                "tr_title": "Seriøs gaming starter her",
                "tr_gen_core_title": "9. generasjons Intel® Core™ i5 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game, stream og gjør opptak uten kompromisser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Beste 9. generasjons Intel® Core™ &#8209;prosessor for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 4<br/>Tråder: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Håndter flere programmer på en enkel måte, slik at du er klar for alt, uansett hvor dagen tar deg.",
                "tr_title": "Multitasking på farten",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i5 &#8209;prosessor",
                "processorCompare": {
                  "tr_title": "FINN RIKTIG NIVÅ AV 10. GENERASJONS YTELSE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming av filmer i 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rask og pålitelig tilkobling",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bildemaskering og videofiltrering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming i 1080p og jevn bildefrekvens",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Best for arbeid med store filer og kreative programmer",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Hev nivået på alt du gjør, fra underholdning til produktivitet og innholdsproduksjon.",
                "tr_title": "Oppgrader til en kraftigere datamaskin",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i5 &#8209;prosessor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "En bærbar datamaskin som er utviklet spesielt for dem som alltid er på farten.",
                "tr_title": "Møt en ny klasse med bærbare datamaskiner",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i5 &#8209;prosessor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Bygget for sømløs streaming og AAA-gaming",
                "tr_title": "Spill på egne vilkår",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i5 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN 10. GENERASJONS INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sømløs AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og opptak",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avansert innholdsproduksjon",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimal ytelse for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og mulig å overklokke<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Bygget for sømløs streaming og AAA-gaming",
                "tr_title": "Spill på egne vilkår",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i5 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN 10. GENERASJONS INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sømløs AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og opptak",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avansert innholdsproduksjon",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimal ytelse for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og mulig å overklokke<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 10<br/>Tråder: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Håndter flere programmer på en enkel måte, slik at du er klar for alt, uansett hvor dagen tar deg.",
                "tr_title": "Multitasking på farten",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "10. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Med 11. generasjons Intel® Core™ i5 &#8209;prosessorer får de tynneste og letteste PC&#8209;ene mer livaktige bildeopplevelser og en rekke produktivitetsfunksjoner.",
                "tr_title": "Imponerende bærbar kraft",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Når familien ser etter en stasjonær datamaskin som kan brukes til alt, trenger du kraften og allsidigheten til en 11. generasjons Intel® Core™ i5 &#8209;prosessor.",
                "tr_title": "Vær klar for alt med banebrytende ytelse.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. generasjons Intel® Core™ i5 -prosessorer bringer livaktige bildeopplevelser og en rekke produktivitetsfunksjoner til tynne og lette bærbare PC&#8209;er.",
                "tr_title": "Imponerende bærbar kraft",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Drevet av en 8.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                "tr_gen_core_title": "8. generasjons Intel® Core™ i7 &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "9. generasjons Intel® Core™ &#8209;prosessorer er laget for å holde tritt med din digitale verden. Takket være kombinasjonen av hastighet og ytelse vil du kunne gjøre mer av det du liker på datamaskinen, uten frustrasjon.",
                "tr_title": "Drevet av en 9.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                "tr_gen_core_title": "9. generasjons Intel® Core™ i7 &#8209;prosessor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "OPPTIL 32 % HØYERE FPS mens du gamer, streamer og gjør opptak kontra en 3 ÅR GAMMEL PC<sup>3</sup>",
                "tr_title": "Ytelse til å dele de beste spilløyeblikkene",
                "tr_gen_core_title": "9. generasjons Intel® Core™ i7 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game, stream og gjør opptak uten kompromisser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Beste 9. generasjons Intel® Core™ &#8209;prosessor for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 4<br/>Tråder: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Denne elegante enheten er utviklet for effektivitet, og kan ha stor innvirkning på måten du skaper, kobler til og streamer på.",
                "tr_title": "Avansert ytelse hvor som helst",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i7 &#8209;prosessor",
                "processorCompare": {
                  "tr_title": "FINN RIKTIG NIVÅ AV 10. GENERASJONS YTELSE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming av filmer i 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rask og pålitelig tilkobling",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bildemaskering og videofiltrering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming i 1080p og jevn bildefrekvens",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Best for arbeid med store filer og kreative programmer",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Forbedret ytelse som tar alt du gjør, til neste nivå.",
                "tr_title": "Kraft til å gjøre alt",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i7 &#8209;prosessor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "En bærbar datamaskin som er utviklet spesielt for dem som alltid er på farten.",
                "tr_title": "Møt en ny klasse med bærbare datamaskiner",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i7 &#8209;prosessor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Game, stream og gjør opptak med overlegen ytelse",
                "tr_title": "Mer kraft til gamingverdenen",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i7 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN 10. GENERASJONS INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sømløs AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og opptak",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avansert innholdsproduksjon",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimal ytelse for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og mulig å overklokke<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Game, stream og gjør opptak med overlegen ytelse",
                "tr_title": "Mer kraft til gamingverdenen",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i7 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN 10. GENERASJONS INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sømløs AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og opptak",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avansert innholdsproduksjon",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimal ytelse for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og mulig å overklokke<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 10<br/>Tråder: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Denne elegante enheten er utviklet for effektivitet, og kan ha stor innvirkning på måten du skaper, kobler til og streamer på.",
                "tr_title": "Førsteklasses ytelse hvor som helst",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "10. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. generasjons Intel® Core™ i7 &#8209;prosessoren bringer avansert innholdsproduksjon, knirkefri gaming og det nyeste innen underholdning til ultrabærbare PC&#8209;er.",
                "tr_title": "Banebrytende bærbar ytelse",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Finn en stasjonær datamaskin som dekker alle hjemmets behov, med den banebrytende ytelsen til 11. generasjons Intel® Core™ i7 &#8209;prosessoren.",
                "tr_title": "Jobb. Lær. Lek. Uten grenser.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. generasjons Intel® Core™ i7 -prosessoren bringer avansert innholdsproduksjon, knirkefri gaming og det nyeste innen underholdning til tynne og lette bærbare PC&#8209;er.",
                "tr_title": "Banebrytende bærbar ytelse",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Finn en stasjonær datamaskin som dekker alle hjemmets behov, med den banebrytende ytelsen til 11. generasjons Intel® Core™ i7 &#8209;prosessoren.",
                "tr_title": "Produser uten grenser",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. generasjons Intel® Core™ i7 -prosessor er nå enda bedre, med avansert innholdsproduksjon, knirkefri gaming og det nyeste innen underholdning, på tynne og bærbare PC&#8209;er.",
                "tr_title": "Banebrytende bærbar ytelse",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Drevet av en 8.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i9 &#8209;prosessor",
                "tr_gen_core_title": "8. generasjons Intel® Core™ i9 &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. generasjons Intel® Core™ &#8209;prosessorer er laget for å holde tritt med din digitale verden. Takket være kombinasjonen av hastighet og ytelse vil du kunne gjøre mer av det du liker på datamaskinen, uten frustrasjon.",
                "tr_title": "Drevet av en 9.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i9 &#8209;prosessor",
                "tr_gen_core_title": "9. generasjons Intel® Core™ i9 &#8209;prosessor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "OPPTIL 41 % HØYERE FPS mens du gamer, streamer og gjør opptak kontra en 3 ÅR GAMMEL PC<sup>4</sup>",
                "tr_title": "Gamerne krever. Intel® Core™ i9 leverer.",
                "tr_gen_core_title": "9. generasjons Intel® Core™ i9 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game, stream og gjør opptak uten kompromisser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Beste 9. generasjons Intel® Core™ &#8209;prosessor for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 4<br/>Tråder: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Utfordre potensialet ditt med den ultimate gamingopplevelsen",
                "tr_title": "Konkurrer på høyeste nivå",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i9 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN 10. GENERASJONS INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sømløs AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og opptak",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avansert innholdsproduksjon",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimal ytelse for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og mulig å overklokke<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Utfordre potensialet ditt med den ultimate gamingopplevelsen",
                "tr_title": "Konkurrer på høyeste nivå",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i9 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN 10. GENERASJONS INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sømløs AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og opptak",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avansert innholdsproduksjon",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimal ytelse for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og mulig å overklokke<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 10<br/>Tråder: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Ta kreativiteten din til nye høyder med en overlegen skaperopplevelse",
                "tr_title": "Skap innhold på høyeste nivå",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "10. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ bærbare PC-er med en 11. generasjons Intel® Core™ i5 &#8209;prosessor og Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;grafikk gir deg utrolig hastighet og ytelse – alt i en tynn og lett design.",
                "tr_title": "Mer kraft for en revolusjonerende bærbar PC",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ bærbare PC-er med en 11. generasjons Intel® Core™ i7 -prosessor og Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk gir deg avansert hastighet og ytelse for en førsteklasses mobil opplevelse.",
                "tr_title": "Avansert kraft for en videreutviklet bærbar PC",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Få en god balanse mellom ytelse, mediafunksjoner og tilkoblingsmuligheter med fantastisk valuta for pengene.",
                "tr_title": "Fantastisk ytelse og mange tilkoblings-muligheter for pengene",
                "tr_gen_core_title": "Intel® Pentium® Silver &#8209;prosessor",
                "processorCompare": {
                  "tr_title": "FINN DET RIKTIGE NIVÅET AV YTELSE OG VERDI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rask og innholdsrik nettsurfing",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rask veksling mellom programmer",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avslappet gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming av videoer i 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Lett bilde- og videoredigering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bedre produktivitet med intelligent ytelse",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Nyt sømløs multitasking, enkel bilde- og videoredigering, videoer i 4K og livaktig grafikk til en utrolig valuta for pengene.",
                "tr_title": "Imponerende ytelse for arbeid og fritid",
                "tr_gen_core_title": "Intel® Pentium® Gold &#8209;prosessor",
                "processorCompare": {
                  "tr_title": "FINN DET RIKTIGE NIVÅET AV YTELSE OG VERDI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rask og innholdsrik nettsurfing",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rask veksling mellom programmer",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avslappet gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming av videoer i 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Lett bilde- og videoredigering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bedre produktivitet med intelligent ytelse",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA-gaming og lang batterilevetid på en ultrabærbar datamaskin. Game helt uten kompromisser.",
                "tr_title": "Ingen grunn til kompromisser.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i5 &#8209;prosessor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 &#8209;prosessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "FINN DIN ULTRABÆRBARE INTEL® CORE™ &#8209;SPILLPROSESSOR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. generasjons Intel® Core™ i5-11300H &#8209;prosessor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. generasjons Intel® Core™ i7-11370H &#8209;prosessor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. generasjons Intel® Core™ i7-11375H Special Edition &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Opptil <span class='style_badge'>4,4&nbsp;GHz</span> maksimal klokke-hastighet",
                      "tr_td2_title": "Opptil <span class='style_badge'>4,8&nbsp;GHz</span> maksimal klokke-hastighet",
                      "tr_td3_title": "Opptil <span class='style_badge'>5,0&nbsp;GHz</span> maksimal klokke-hastighet"
                    },
                    {
                      "tr_td1_title": "4 kjerner / 8&nbsp;tråder",
                      "tr_td2_title": "4 kjerner / 8&nbsp;tråder",
                      "tr_td3_title": "4 kjerner / 8&nbsp;tråder"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost &#8209;teknologi",
                      "tr_td2_title": "Intel® Turbo Boost &#8209;teknologi",
                      "tr_td3_title": "Intel® Turbo Boost Max &#8209;teknologi 3.0"
                    },
                    {
                      "tr_td1_title": "8&nbsp;MB Intel® Smart Cache",
                      "tr_td2_title": "12&nbsp;MB Intel® Smart Cache",
                      "tr_td3_title": "12&nbsp;MB Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA-gaming i 1080p med høy FPS ved høye innstillinger og utmerket ytelse i en ultrabærbar datamaskin selv når den er frakoblet strøm.",
                "tr_title": "Ja takk, begge deler.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 &#8209;prosessor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 &#8209;prosessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "FINN DIN ULTRABÆRBARE INTEL® CORE™ &#8209;SPILLPROSESSOR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. generasjons Intel® Core™ i5-11300H &#8209;prosessor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. generasjons Intel® Core™ i7-11370H &#8209;prosessor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. generasjons Intel® Core™ i7-11375H Special Edition &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Opptil <span class='style_badge'>4,4&nbsp;GHz</span> maksimal klokke-hastighet",
                      "tr_td2_title": "Opptil <span class='style_badge'>4,8&nbsp;GHz</span> maksimal klokke-hastighet",
                      "tr_td3_title": "Opptil <span class='style_badge'>5,0&nbsp;GHz</span> maksimal klokke-hastighet"
                    },
                    {
                      "tr_td1_title": "4 kjerner / 8&nbsp;tråder",
                      "tr_td2_title": "4 kjerner / 8&nbsp;tråder",
                      "tr_td3_title": "4 kjerner / 8&nbsp;tråder"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost &#8209;teknologi",
                      "tr_td2_title": "Intel® Turbo Boost &#8209;teknologi",
                      "tr_td3_title": "Intel® Turbo Boost Max &#8209;teknologi 3.0"
                    },
                    {
                      "tr_td1_title": "8&nbsp;MB Intel® Smart Cache",
                      "tr_td2_title": "12&nbsp;MB Intel® Smart Cache",
                      "tr_td3_title": "12&nbsp;MB Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Med opptil 5,0 GHz maksimal klokkefrekvens kan du presse spillene dine til det ytterste, og med den ultrabærbare datamaskinen kan du ta dem med deg overalt.",
                "tr_title": "Mer kraft per kilo.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 Special Edition &#8209;prosessor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition -prosessor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "FINN DIN ULTRABÆRBARE INTEL® CORE™ &#8209;SPILLPROSESSOR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. generasjons Intel® Core™ i5-11300H &#8209;prosessor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. generasjons Intel® Core™ i7-11370H &#8209;prosessor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. generasjons Intel® Core™ i7-11375H Special Edition &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Opptil <span class='style_badge'>4,4&nbsp;GHz</span> maksimal klokke-hastighet",
                      "tr_td2_title": "Opptil <span class='style_badge'>4,8&nbsp;GHz</span> maksimal klokke-hastighet",
                      "tr_td3_title": "Opptil <span class='style_badge'>5,0&nbsp;GHz</span> maksimal klokke-hastighet"
                    },
                    {
                      "tr_td1_title": "4 kjerner / 8&nbsp;tråder",
                      "tr_td2_title": "4 kjerner / 8&nbsp;tråder",
                      "tr_td3_title": "4 kjerner / 8&nbsp;tråder"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost &#8209;teknologi",
                      "tr_td2_title": "Intel® Turbo Boost &#8209;teknologi",
                      "tr_td3_title": "Intel® Turbo Boost Max &#8209;teknologi 3.0"
                    },
                    {
                      "tr_td1_title": "8&nbsp;MB Intel® Smart Cache",
                      "tr_td2_title": "12&nbsp;MB Intel® Smart Cache",
                      "tr_td3_title": "12&nbsp;MB Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med 11. generasjons Intel® Core™ i7 Special Edition -prosessor får du avansert innholdsproduksjon, gaming og forbedret underholdning på ultrabærbare PC-er.",
                "tr_title": "Banebrytende bærbar ytelse",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 Special Edition &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i7 SPECIAL EDITION &#8209;PROSESSOR"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Med 11. generasjons Intel® Core™ i7 Special Edition -prosessor får du avansert innholdsproduksjon, gaming og forbedret underholdning på ultrabærbare PC-er.",
                "tr_title": "Banebrytende bærbar ytelse",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 Special Edition &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i7 SPECIAL EDITION &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition -prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Med kombinasjonen av rask klokkefrekvens og mange kjerner får du mer ut av konkurransespilling og avansert innholdsproduksjon.",
                "tr_title": "Massiv kraft. Perfekt balansert.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i9 &#8209;prosessor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 &#8209;prosessor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Med riktig balanse mellom klokkehastighet og kjerneytelse får du mer ut av spillene dine, enten det er for konkurransegaming med høy FPS eller for oppslukende AAA-gaming.",
                "tr_title": "Kraft med et formål.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i5 &#8209;prosessor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Game, ta opp og stream krevende titler med høy FPS, og bytt raskt og enkelt mellom arbeidsoppgaver.",
                "tr_title": "Kraft til å tøye grensene.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 &#8209;prosessor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Med kombinasjonen av rask klokkefrekvens og mange kjerner får du mer ut av konkurransespilling og avansert innholdsproduksjon.",
                "tr_title": "Massiv kraft. Perfekt balansert.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i9 &#8209;prosessor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gå fra å streame filmer til å lage presentasjoner med mye ytelse til overs. Helt enkelt.",
                "tr_title": "Kraft nok til hele dagen",
                "tr_gen_core_title": "Intel® Core™ i3 &#8209;prosessor med Intel® Hybrid Technology",
                "tr_subTitle2": "Intel® Core™ i3 &#8209;prosessor med Intel® Hybrid Technology"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Allsidig ytelse og rikelig med batterilevetid som holder deg i gang, hvor&nbsp;som&nbsp;helst.",
                "tr_title": "Kraft nok til hele dagen",
                "tr_gen_core_title": "Intel® Core™ i3 &#8209;prosessor med Intel® Hybrid Technology",
                "tr_subTitle2": "Intel® Core™ i3 &#8209;prosessor med Intel® Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Håndter flere programmer på en enkel måte, slik at du er klar for alt, uansett hvor dagen tar deg.",
                "tr_title": "Multitasking på farten",
                "tr_gen_core_title": "Intel® Core™ i5 &#8209;prosessor med Intel® Hybrid Technology",
                "tr_subTitle2": "Intel® Core™ i5 &#8209;prosessor med Intel® Hybrid Technology"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hev nivået på alt du gjør, fra underholdning til produktivitet og innholdsproduksjon.",
                "tr_title": "Oppgrader til en kraftigere datamaskin",
                "tr_gen_core_title": "Intel® Core™ i5 &#8209;prosessor med Intel® Hybrid Technology",
                "tr_subTitle2": "Intel® Core™ i5 &#8209;prosessor med Intel® Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Gamingytselse i stasjonærklasse møter mobilitet, enten det er for konkurransegaming med høy FPS eller for oppslukende AAA-gaming.",
                "tr_title": "Kraft med et formål.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i5 &#8209;prosessor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Gamingytselse i stasjonærklasse møter mobilitet, enten det er for konkurransegaming med høy FPS eller for oppslukende AAA-gaming.",
                "tr_title": "Kraft med et formål.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "En bærbar datamaskin som kan spille av, spille inn og streame samtidig med høy FPS, og som behersker tung multitasking med glans.",
                "tr_title": "Kraft til å tøye grensene.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 &#8209;prosessor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "En bærbar datamaskin som kan spille av, spille inn og streame samtidig med høy FPS, og som behersker tung multitasking med glans.",
                "tr_title": "Kraft til å tøye grensene.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Med kombinasjonen av rask klokkefrekvens og mange kjerner får du mer ut av konkurransespilling og avansert innholdsproduksjon, uansett hvor du bruker PC-en.",
                "tr_title": "Massiv kraft. Perfekt balansert.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i9 &#8209;prosessor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Med kombinasjonen av rask klokkefrekvens og mange kjerner får du mer ut av konkurransespilling og avansert innholdsproduksjon, uansett hvor du bruker PC-en.",
                "tr_title": "Massiv kraft. Perfekt balansert.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Med kombinasjonen av rask klokkefrekvens, mange kjerner og ulåst ytelse får du mer ut av konkurransespilling og avansert innholdsproduksjon, uansett hvor du bruker PC-en.",
                "tr_title": "Ulåst kraft. Perfekt balansert.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i9 &#8209;prosessor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Med kombinasjonen av rask klokkefrekvens, mange kjerner og ulåst ytelse får du mer ut av konkurransespilling og avansert innholdsproduksjon, uansett hvor du bruker PC-en.",
                "tr_title": "Ulåst kraft. Perfekt balansert.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Med kombinasjonen av rask klokkefrekvens og mange kjerner får du mer ut av konkurransespilling og avansert innholdsproduksjon.",
                "tr_title": "Massiv kraft. Perfekt balansert.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Med kombinasjonen av rask klokkehastighet og mange kjerner får du mer ut av avansert innholdsproduksjon, uansett hvor du bruker PC&#8209;en.",
                "tr_title": "Massiv kraft. Perfekt balansert.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Med kombinasjonen av rask klokkehastighet og mange kjerner får du mer ut av avansert innholdsproduksjon.",
                "tr_title": "Massiv kraft. Perfekt balansert.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Utfordre potensialet ditt med den ultimate gamingopplevelsen",
                "tr_title": "Konkurrer på høyeste nivå",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i9 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN 10. GENERASJONS INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sømløs AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og opptak",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avansert innholdsproduksjon",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimal ytelse for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og mulig å overklokke<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Utfordre potensialet ditt med den ultimate gamingopplevelsen",
                "tr_title": "Konkurrer på høyeste nivå",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i9 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN 10. GENERASJONS INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sømløs AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og opptak",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avansert innholdsproduksjon",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maksimal ytelse for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og mulig å overklokke<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 10<br/>Tråder: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "En bærbar datamaskin som er utviklet spesielt for dem som alltid er på farten.",
                "tr_title": "Møt en ny klasse med bærbare datamaskiner",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i9 &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Med kombinasjonen av rask klokkefrekvens og mange kjerner får du mer ut av konkurransespilling og avansert innholdsproduksjon.",
                "tr_title": "Ulåst kraft. Perfekt balansert.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Med kombinasjonen av rask klokkehastighet, mange kjerner og ulåst ytelse får du mer ut av avansert innholdsproduksjon, uansett hvor du bruker PC-en.",
                "tr_title": "Ulåst kraft. Perfekt balansert.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Med kombinasjonen av rask klokkehastighet og mange kjerner får du mer ut av avansert innholdsproduksjon.",
                "tr_title": "Ulåst kraft. Perfekt balansert.",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "11. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Knirkefri ytelse og VR i spillene som krever mest",
                "tr_title": "Seriøs gaming starter her",
                "tr_gen_core_title": "9. generasjons Intel® Core™ i5 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game, stream og gjør opptak uten kompromisser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Beste 9. generasjons Intel® Core™ &#8209;prosessor for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 4<br/>Tråder: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "OPPTIL 32 % HØYERE FPS <br/><span class='tr_subtitle_font'>mens du gamer, streamer og gjør opptak kontra en 3 år gammel PC<sup>3</sup></span>",
                "tr_title": "Ytelse til å dele de beste spilløyeblikkene",
                "tr_gen_core_title": "9. generasjons Intel® Core™ i7 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game, stream og gjør opptak uten kompromisser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Beste 9. generasjons Intel® Core™ &#8209;prosessor for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 4<br/>Tråder: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "OPPTIL 41 % HØYERE FPS <br/><span class='tr_subtitle_font'>mens du gamer, streamer og gjør opptak kontra en 3 år gammel PC<sup>4</sup></span>",
                "tr_title": "Gamerne krever. Intel® Core™ i9 leverer.",
                "tr_gen_core_title": "9. generasjons Intel® Core™ i9 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Tråder</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Kjerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Opptil <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Hurtigminne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FINN EN INTEL® CORE™ &#8209;PROSESSOR FOR MÅTEN DU GAMER PÅ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 &#8209;prosessor"
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 &#8209;prosessor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurransespill",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Game, stream og gjør opptak uten kompromisser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Beste 9. generasjons Intel® Core™ &#8209;prosessor for gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 4<br/>Tråder: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 6<br/>Tråder: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klokke-hastighet<sup>2</sup>: <br/>opptil 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kjerner: 8<br/>Tråder: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Laget for neste generasjons gaming",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i9 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kjerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Tråder</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Kampstasjon møter arbeidsstasjon",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i9 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kjerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Tråder</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Pålitelig ytelse når du trenger det mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i3 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i3 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "En økning i ytelse der det trengs mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "En økning i ytelse der det trengs mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Overlegen ytelse der du trenger det mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Overlegen ytelse der du trenger det mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Evo™ -laptoper får du ytelse, innebygd intelligens for trygge videochatter, raskere internett, lang batterilevetid og mye mer.",
                "tr_title": "Ekstra ytelse der du trenger det mest.",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Evo™ -laptoper får du ytelse, innebygd intelligens for trygge videochatter, raskere internett, lang batterilevetid og mye mer.",
                "tr_title": "Overlegen ytelse der du trenger det mest",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Evo™ -laptoper får du ytelse, innebygd intelligens for trygge videochatter, raskere internett, lang batterilevetid og mye mer.",
                "tr_title": "Ekstrem ytelse i en førsteklasses bærbar PC",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "med Intel® Turbo Boost &#8209;teknologi&nbsp;2.0 på&nbsp;opptil&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Built for business",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Demper støy og gjør bakgrunnen din uskarp",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Skreddersyr ytelsen automatisk for å prioritere det du gjør mest",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Opplev sømløse nettanrop av høy kvalitet",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Få maskinvarebasert sikkerhet rett fra boksen",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Leverer fjernadministrasjonsegenskaper",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimaliserer automatisk Wi&#8209;Fi&#8209;ytelsen<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Kraft til datadrevne apper",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mest produktivitet med forbedret ytelse",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "med Intel® Turbo Boost &#8209;teknologi&nbsp;2.0 på&nbsp;opptil&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Arbeider hardt, så du kan fokusere på det du skal gjøre.",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Demper støy og gjør bakgrunnen din uskarp",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Skreddersyr ytelsen automatisk for å prioritere det du gjør mest",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Opplev sømløse nettanrop av høy kvalitet",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Få maskinvarebasert sikkerhet rett fra boksen",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Leverer fjernadministrasjonsegenskaper",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimaliserer automatisk Wi&#8209;Fi&#8209;ytelsen<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Kraft til datadrevne apper",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mest produktivitet med forbedret ytelse",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "med Intel® Turbo Boost &#8209;teknologi&nbsp;2.0 på&nbsp;opptil&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Voks virksomheten din",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "12. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Demper støy og gjør bakgrunnen din uskarp",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Skreddersyr ytelsen automatisk for å prioritere det du gjør mest",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Opplev sømløse nettanrop av høy kvalitet",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Få maskinvarebasert sikkerhet rett fra boksen",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Leverer fjernadministrasjonsegenskaper",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimaliserer automatisk Wi&#8209;Fi&#8209;ytelsen<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Kraft til datadrevne apper",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mest produktivitet med forbedret ytelse",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Pålitelig ytelse som gjør mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i3 &#8209;prosessor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Pålitelig ytelse i den virkelige verden, som gjør mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Pålitelig ytelse i den virkelige verden, som gjør mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Kraftig ytelse som gjør mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Kraftig ytelse som gjør mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Overlegen ytelse",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kjerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Tråder</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Koble sømløst til din eksisterende telefon<sup>24</sup>, velg det beste trådløse nettet på en intelligent måte og lad raskt med et batteri som varer.",
                "tr_title": "Overlegen ytelse for å få mer gjort hvor som helst",
                "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Koble sømløst til din eksisterende telefon<sup>24</sup>, velg det beste trådløse nettet på en intelligent måte og lad raskt med et batteri som varer.",
                "tr_title": "Overlegen ytelse slik at PC-en din gjør enda mer på én gang",
                "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Koble sømløst til din eksisterende telefon<sup>24</sup>, velg det beste trådløse nettet på en intelligent måte og lad raskt med et batteri som varer.",
                "tr_title": "Overlegen ytelse for å frese gjennom de mest krevende oppgavene",
                "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Overlegen ytelse",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i9 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kjerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Tråder</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Overlegen ytelse",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 &#8209;PROSESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 &#8209;PROSESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 &#8209;PROSESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kjerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Tråder</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Pålitelig ytelse som gjør mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Intel® Core™ i3 &#8209;prosessor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Pålitelig ytelse i den virkelige verden, som gjør mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel® Core™ i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Kraftig ytelse som gjør mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel® Core™ i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Overlegen ytelse",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 &#8209;PROSESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 &#8209;PROSESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 &#8209;PROSESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kjerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Tråder</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Pålitelig ytelse som gjør mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 3 &#8209;prosessor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Pålitelig ytelse i den virkelige verden, som gjør mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 5 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Kraftig ytelse som gjør mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 7 &#8209;PROSESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "tr_title": "Kompromissløs gaming",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 5 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 5 &#8209;PROSESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 7 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 7 &#8209;PROSESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 9 &#8209;prosessor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 9 &#8209;PROSESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Kjerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-kjerner"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Tråder</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] på LPE-kjerner"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 5 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 7 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 9 &#8209;prosessor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Kompromissløs gaming",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Kompromissløs gaming",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Kompromissløs gaming",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Kompromissløs gaming",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Kompromissløs gaming",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Kompromissløs gaming",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Små virksomheter fortjener løsninger for store virksomheter.",
                "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i5 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Demper støy og gjør bakgrunnen din uskarp",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Skreddersyr ytelsen automatisk for å prioritere det du gjør mest",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Opplev sømløse nettanrop av høy kvalitet",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Få maskinvarebasert sikkerhetsbeskyttelse rett fra boksen",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Hjelper til med å beskytte enheter og data fra fysiske angrep",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Små virksomheter fortjener løsninger for store virksomheter.",
                "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i7 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Demper støy og gjør bakgrunnen din uskarp",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Skreddersyr ytelsen automatisk for å prioritere det du gjør mest",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Opplev sømløse nettanrop av høy kvalitet",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Få maskinvarebasert sikkerhetsbeskyttelse rett fra boksen",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Hjelper til med å beskytte enheter og data fra fysiske angrep",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Små virksomheter fortjener løsninger for store virksomheter.",
                "tr_gen_core_title": "13.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i9 &#8209;prosessor",
                "tr_gen_core_title_uppercase": "13. GENERASJONS INTEL® CORE™ i9 &#8209;PROSESSOR",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "HVORDAN ER DENNE PROSESSOREN SAMMENLIGNET MED ANDRE?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Gjeldende enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Demper støy og gjør bakgrunnen din uskarp",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Skreddersyr ytelsen automatisk for å prioritere det du gjør mest",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Opplev sømløse nettanrop av høy kvalitet",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Få maskinvarebasert sikkerhetsbeskyttelse rett fra boksen",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Hjelper til med å beskytte enheter og data fra fysiske angrep",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Lås opp nye AI&#8209;opplevelser for virksomheten",
                "tr_title": "Vær klar for fremtiden med Intel® Core™ Ultra&nbsp;[XX] &#8209;prosessorer og Intel vPro®",
                "tr_gen_core_title": "Intel® Core™ Ultra [XX] &#8209;prosessor",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "GI VIRKSOMHETEN ET LØFT MED AI&#8209;DREVNE PC&#8209;ER FRA INTEL",
                    "tr_badgeSubTitle": "Kraftige PC-er som skaper flotte opplevelser er kjernen i virksomhetens produktivitet. Intel vPro® øker effektiviteten og prestasjonene til medarbeiderne på tvers av arbeidsbelastninger og applikasjoner takket være en ny arkitektur som leverer overlegen strømeffektiv ytelse for forskjellige, komplekse virksomhetsarbeidsbelastninger ved å sende riktig oppgave til riktig motor til riktig tid.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "SIKKERHETSFUNKSJONALITET PÅ FLERE LAG KLAR TIL BRUK RETT FRA BOKSEN",
                    "tr_badgeSubTitle": "Nye trusler krever en omfattende tilnærming til sikkerhet. Intel vPro® gir beskyttelse på maskinvarenivå fra topp til bunn, slik at virksomheten er bedre beskyttet. Disse unike sikkerhetstiltakene som er klare rett fra boksen, inkluderer AI&#8209;drevet trusseloppdagelse, bidrar til å beskytte brukere, data og virksomheten.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "INNEBYGDE VERKTØY SOM LAR BRUKERNE VÆRE PRODUKTIVE FRA SÅ GODT SOM HVOR SOM HELST",
                    "tr_badgeSubTitle": "Det finnes kontorer over alt, og det er PC&#8209;er også. Intel vPro® leverer moderne maskinvarebasert administrasjonsfunksjonalitet for å gjøre det lettere for virksomheter å administrere datamaskinparken, forenkle brukerstøtte og forbedre opplevelsen til sluttbrukerne, samtidig som det sørger for en bærekraftig databehandlingspraksis.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mer enn en førsteklasses PC, en AI‑PC",
                "tr_subtitle1": "DREVET AV INTEL® CORE™ ULTRA ‑PROSESSORER (SERIE 2)",
                "tr_subtitle2": "Det er mulig på en Intel AI‑PC",
                "tr_subtitle": "Intels mest effektive, sikre og høytytende laptopprosessor<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 &#8209;prosessor (Serie&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Mer kreativ</span> med AI-verktøy for å skape raskere"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mer produktiv</span> med tidsbesparende AI-assistanse"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Bedre sikkerhet</span> med innebygget databeskyttelse"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mer enn en førsteklasses PC, en AI‑PC",
                "tr_subtitle1": "DREVET AV INTEL® CORE™ ULTRA ‑PROSESSORER (SERIE 2)",
                "tr_subtitle2": "Det er mulig på en Intel AI‑PC",
                "tr_subtitle": "Intels mest effektive, sikre og høytytende laptopprosessor<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 &#8209;prosessor (Serie&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Mer kreativ</span> med AI-verktøy for å skape raskere"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mer produktiv</span> med tidsbesparende AI-assistanse"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Bedre sikkerhet</span> med innebygget databeskyttelse"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mer enn en førsteklasses PC, en AI‑PC",
                "tr_subtitle1": "DREVET AV INTEL® CORE™ ULTRA ‑PROSESSORER (SERIE 2)",
                "tr_subtitle2": "Det er mulig på en Intel AI‑PC",
                "tr_subtitle": "Intels mest effektive, sikre og høytytende laptopprosessor<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 &#8209;prosessor (Serie&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mer enn en førsteklasses PC, en AI‑PC",
                "tr_subtitle1": "DREVET AV INTEL® CORE™ ULTRA ‑PROSESSORER (SERIE 2)",
                "tr_subtitle2": "Det er mulig på en Intel AI‑PC",
                "tr_subtitle": "Intels mest effektive, sikre og høytytende laptopprosessor<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 &#8209;prosessor (Serie&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_tileTitle1": "Dedikert grafikk",
                "tr_title": "HVA ER EN GRAFIKKPROSESSORENHET?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Grafikkbehandlingsenheten, også kalt GPU, skaper bildene du ser på skjermen.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integrert GPU",
                    "tr_badgeSubTitle": "En integrert GPU bruker samme RAM som resten av datamaskinen, noe som gjør den mer energieffektiv.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Separat GPU",
                    "tr_badgeSubTitle": "Dette er den kraftigste GPU-klassen fordi den har sin egen RAM og andre ressurser som er viet til å bygge eksepsjonell grafikk.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Hybrid-GPU",
                    "tr_badgeSubTitle": "Dette er den kraftigste GPU-klassen fordi den har sin egen RAM og andre ressurser som er viet til å bygge eksepsjonell grafikk.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Effektiv bilde- og videoredigering",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Se på videoinnhold i 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Spill de nyeste spillene med fantastisk grafikk",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Doble GPU-er",
                    "tr_badgeSubTitle": "Noen systemer, blant annet de med Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> &#8209;grafikk, kan bruke både den prosessorintegrerte og den separate GPU&#8209;en til å fordele den grafiske arbeidsbelastningen.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integrert GPU",
                    "tr_badgeSubTitle": "En integrert GPU er bygd inn i den samme brikken som hovedprosessoren eller CPU. Denne typen GPU bruker samme RAM og andre ressurser som resten av datamaskinen, noe som gjør den mer energieffektiv og muliggjør en tynnere og lettere enhet.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Separat GPU",
                    "tr_badgeSubTitle": "Dette er den kraftigste GPU-klassen fordi den har sin egen RAM og andre ressurser som er viet til å bygge eksepsjonell grafikk.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Standard",
                    "tr_badgeSubTitle": "Opptil 4&nbsp;GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Konkurranse-dyktig",
                    "tr_badgeSubTitle": "Opptil 8&nbsp;GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Mer enn 8&nbsp;GB VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "Intens grafikk",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Den kraftigste grafikkbehandlingen krever et grafikkort som kan fokusere utelukkende på å skape vakre, klare bilder og flytende animasjon.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "Innebygget detaljert og krystallklar grafikk",
                "tr_pretitle": "Intel® Iris® Plus -grafikk",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Iris® Plus -grafikk kan du la deg oppsluke av 4K HDR-video og gaming i Full HD uten ekstra grafikkort.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "Fantastiske bilder, problemfri ytelse",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® &#8209;Grafikk leverer banebrytende integrert ytelse for å støtte flytende designprosesser og omfattende gamingopplevelser.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "Intel® Iris® Pro-grafikk",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "Intel® UHD -grafikk med X<sup class='small_text_sup_xe'>e</sup> &#8209;arkitektur",
                "tr_pretitle": "Oppslukende visuelle opplevelser",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Jobb effektivt med bilde- og videoredigering, se på videoer i 4K og spill de nyeste spillene med fantastisk grafikk.",
                "tr_photo_text": "Effektiv bilde- og videoredigering",
                "tr_video_text": "Se på videoinnhold i 4K",
                "tr_game_text": "Spill de nyeste spillene med fantastisk grafikk",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MYE MER ENN FINE BILDER",
                "tr_subtitle": "Grafikkortet (eller GPU) jobber tett med prosessoren (eller CPU) og er avgjørende for hvordan spillene kjører og hvordan de ser ut. VRAM, eller video-RAM, som brukes til å lage grafikk, gir en rask pekepinn på ytelsen til GPU-en.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FOR INTENS SPILLGRAFIKK",
                "tr_subtitle": "Den kraftigste grafikkbehandlingen krever et separat grafikkort som kan fokusere utelukkende på å skape vakre, klare bilder og flytende animasjon",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FOR INTENS SPILLGRAFIKK",
                "tr_subtitle": "Den kraftigste grafikkbehandlingen krever et separat grafikkort som kan fokusere utelukkende på å skape vakre, klare bilder og flytende animasjon",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MYE MER ENN FINE BILDER",
                "tr_subtitle": "Grafikkortet (eller GPU) jobber tett med prosessoren (eller CPU) og er avgjørende for hvordan spillene kjører og hvordan de ser ut. VRAM, eller video-RAM, som brukes til å lage grafikk, gir en rask pekepinn på ytelsen til GPU-en.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "Intens grafikk",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Den kraftigste grafikkbehandlingen krever et grafikkort som kan fokusere utelukkende på å skape vakre, klare bilder og flytende animasjon.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "FOR INTENS SPILLGRAFIKK",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Den kraftigste grafikkbehandlingen krever et grafikkort som kan fokusere utelukkende på å skape vakre, klare bilder og flytende animasjon.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Den kraftigste grafikkbehandlingen krever et separat grafikkort som kan fokusere utelukkende på å skape vakre, klare bilder og flytende animasjon",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Gir deg rikelig med grafikkytelse til&nbsp;å&nbsp;oppfylle minimumskravene til&nbsp;spillet&nbsp;ditt.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "For avanserte gamere som krever gamingegenskaper på konkurransenivå",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "For avanserte gamere som også bruker PC&#8209;en til tung innholdsproduksjon.",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Krystallklar video og oppslukende gaming",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Få mest mulig ut av skjermen med høy oppløsning med Intel® HD -grafikk, og gled deg over fantastiske multimedieopplevelser og innebygd grafikkbehandling.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Gir kraft til levende video og bilder",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Få mer detaljer og strålende farger når du streamer video og bilder.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "Utrolig kreativitet og gaming",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk får slanke og lette PC-er tilgang til grafikkytelse av en annen verden, fra oppslukende underholdning og avansert innholdsproduksjon til heftig gaming.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_pretitle": "FOR INTENS SPILLGRAFIKK",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> -grafikk",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Den kraftigste grafikkbehandlingen krever et separat grafikkort som kan fokusere utelukkende på å skape vakre, klare bilder og flytende animasjon",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "Slipp løs kreativiteten",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX -grafikk",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Hold flyten i kreative prosjekter og la deg oppsluke av forskjellige spill med den kombinerte kraften av den prosessorintegrerte grafikken og den første separate Intel® -grafikkmotoren.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX -grafikk",
                "tr_pretitle": "FOR INTENS SPILLGRAFIKK",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Den kraftigste grafikkbehandlingen krever et separat grafikkort som kan fokusere utelukkende på å skape vakre, klare bilder og flytende animasjon",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Grafikk",
                "tr_title": "Nydelig innebygd grafikk",
                "tr_subtitle": "Kos deg med spill, filmer og nettvideoer med utrolige detaljer og klare bilder.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafikk",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafikk",
                    "tr_specText": "Full fres på arbeidsflytene"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafikk",
                    "tr_specText": "Få fart på gamingen"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafikk",
                    "tr_specText": "Få fart på gamingen"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafikk",
                    "tr_specText": "Gaming på neste nivå"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "SLIPP LØS KREATIVITETEN",
                    "tr_specText": "Øk produktiviteten uansett hvor du drar, med den mest omfattende mediefunksjonaliteten, som <strong>den første i bransjen med AV1-maskinvarekoding</strong> og AI-akselerert innholdsproduksjon.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "SLIPP LØS KREATIVITETEN",
                    "tr_specText": "Øk produktiviteten med den mest omfattende mediefunksjonaliteten, som <strong>den første i bransjen med AV1-maskinvarekoding</strong> og AI-akselerert innholdsproduksjon.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "SLIPP LØS KREATIVITETEN",
                    "tr_specText": "Opplev banebrytende AI-akselerert innholdsproduksjon med <strong>den første i bransjen med AV1-maskinvarekoding</strong> og den avanserte <strong>X<sup>e</sup>-mediamotoren.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "SLIPP LØS KREATIVITETEN",
                    "tr_specText": "Opplev banebrytende AI-akselerert innholdsproduksjon med <strong>den første i bransjen med AV1-maskinvarekoding</strong> og den avanserte <strong>X<sup>e</sup>-mediamotoren.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "SLIPP LØS KREATIVITETEN",
                    "tr_specText": "Opplev banebrytende AI-akselerert innholdsproduksjon med <strong>den første i bransjen med AV1-maskinvarekoding</strong> og den avanserte <strong>X<sup>e</sup>-mediamotoren.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "MOBILITET MØTER YTELSE",
                    "tr_specText": "Spill de nyeste spillene når du er på farten, og få superkraftig ytelse og gjengivelse med moderne gamingfunksjonalitet. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-forbedret oppskaleringsteknologi for mer ytelse og flytende gaming. <br/><br/>Støtter <strong>DirectX* 12 Ultimate:  </strong>Ta gamingen til et nytt og virkelighetsnært nivå med grafikkteknologi som strålesporing og VRS."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "TA KONTROLL OVER SPILLENE DINE",
                    "tr_specText": "<strong>Intel® Arc™ &#8209;kontroll</strong> lar deg forbedre opplevelsen med en intuitiv og moderne layout. Få sømløs oppdatering av drivere, optimer spillene dine og mer."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "TA KONTROLL OVER SPILLENE DINE",
                    "tr_specText": "<strong>Intel® Arc™ &#8209;kontroll</strong> lar deg forbedre opplevelsen med en intuitiv og moderne layout. Få sømløs oppdatering av drivere, optimer spillene dine og mer."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "TA KONTROLL OVER SPILLENE DINE",
                    "tr_specText": "Få sømløse driveroppdateringer og optimer mange av favorittspillene dine med programmet <strong>Intel®&nbsp;Arc™&nbsp;&#8209;kontroll</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAKSIMALISER INTEL® &#8209;PLATTFORMEN",
                    "tr_specText": "Par med kompatible Intel® Core™ -prosessorer for å slippe løs <strong>Intel® Deep Link Technology</strong> for å forbedre opplevelsen din når du skaper, gamer og streamer."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MAKSIMALISER INTEL® -SYSTEMET",
                    "tr_specText": "Par med kompatible Intel® Core™ -prosessorer for å slippe løs <strong>Intel® Deep Link Technology</strong> for å forbedre opplevelsen din når du skaper, gamer og streamer."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAKSIMALISER INTEL® &#8209;PLATTFORMEN",
                    "tr_specText": "Par med kompatible Intel® Core™ -prosessorer for å slippe løs <strong>Intel® Deep Link Technology</strong> for å forbedre opplevelsen din når du skaper, gamer og streamer."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAKSIMALISER INTEL® &#8209;PLATTFORMEN",
                    "tr_specText": "Par med kompatible Intel® Core™ -prosessorer for å slippe løs <strong>Intel® Deep Link Technology</strong> for å forbedre opplevelsen din når du skaper, gamer og streamer."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAKSIMALISER INTEL® -SYSTEMET",
                    "tr_specText": "Par med kompatible Intel® Core™ -prosessorer for å slippe løs <strong>Intel® Deep Link Technology</strong> for å forbedre opplevelsen din når du skaper, gamer og streamer."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "INNTA SCENEN",
                    "tr_specText": "Send direktesendinger helt sømløst, med avanserte streamingteknologier gjort mulig med programmet <strong>Intel®&nbsp;Arc™&nbsp;&#8209;kontroll</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "INNTA SCENEN",
                    "tr_specText": "Send direktesendinger helt sømløst, med avanserte streamingteknologier gjort mulig med programmet <strong>Intel®&nbsp;Arc™&nbsp;&#8209;kontroll</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "OPPLEV ENGASJERENDE GAMING MED TOPP YTELSE",
                    "tr_specText": "Alltid topp ytelse med de siste AAA-spillene i høy oppløsning og høye oppdateringsfrekvenser. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-forbedret oppskaleringsteknologi for mer ytelse og flytende gaming. <br/><br/>Støtter <strong>DirectX* 12 Ultimate:  </strong>Ta gamingen til et nytt og virkelighetsnært nivå med grafikkteknologi som strålesporing og VRS."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "OPPLEV ENGASJERENDE GAMING MED TOPP YTELSE",
                    "tr_specText": "Alltid topp ytelse med de siste AAA-spillene i høy oppløsning og høye oppdateringsfrekvenser. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-forbedret oppskaleringsteknologi for mer ytelse og flytende gaming. <br/><br/>Støtter <strong>DirectX* 12 Ultimate:  </strong>Ta gamingen til et nytt og virkelighetsnært nivå med grafikkteknologi som strålesporing og VRS."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "GI GAMINGEN EN BOOST",
                    "tr_specText": "Ta gamingopplevelsen til neste nivå med: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-forbedret oppskaleringsteknologi for mer ytelse og flytende gaming. <br/><br/>Støtter <strong>DirectX* 12 Ultimate:  </strong>Ta gamingen til et nytt og virkelighetsnært nivå med grafikkteknologi som strålesporing og VRS."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "OPPLEV AVANSERT OG LYNRASK GAMING",
                    "tr_specText": "Nyt knirkefri gaming i mange populære AAA-spill, og gaming med høy oppdateringsfrekvens i e-sport-skytespill eller fartsfylte RTS-spill. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-forbedret oppskaleringsteknologi for mer ytelse og flytende gaming. <br/><br/>Støtter <strong>DirectX* 12 Ultimate:  </strong>Ta gamingen til et nytt og virkelighetsnært nivå med grafikkteknologi som strålesporing og VRS."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "OPPLEV ENGASJERENDE GAMING MED TOPP YTELSE",
                    "tr_specText": "Alltid topp ytelse med de siste AAA-spillene i høy oppløsning og høye oppdateringsfrekvenser. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-forbedret oppskaleringsteknologi for mer ytelse og flytende gaming. <br/><br/>Støtter <strong>DirectX* 12 Ultimate:  </strong>Ta gamingen til et nytt og virkelighetsnært nivå med grafikkteknologi som strålesporing og VRS."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafikk",
                    "tr_specText": "Et grafikkunivers med plass til alle"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "AKSELERER RAMMENE, AKSELERER SPILLENE",
                    "tr_specText": "Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling &#8209;teknologi (X<sup class='small_text_sup_xe'>e</sup>SS) tar gamingen din til neste nivå med AI-forbedret oppskalering – for toppytelse med nøyaktig bildegjengivelse. X<sup class='small_text_sup_xe'>e</sup>SS er optimert for Intel® Arc™ &#8209;grafikkprodukter, slik at du kan dra full nytte av Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX) &#8209;maskinvareakselerasjon."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "DE ULTIMATE BILDENE ER HER",
                    "tr_specText": "Med full støtte for DirectX* 12 Ultimate på all Intel® Arc™ &#8209;grafikk, kan du nyte fantastisk, oppslukende gaming takket være de nyeste grafikkteknologiene som strålesporing, variable rate shading, mesh shading og tilbakemelding fra sampler – grunnlaget for neste generasjons gaming."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "SØMLØS OPPRETTING AV BILDER, VIDEO OG MEDIA",
                    "tr_specText": "Slipp løs fantasien og fang publikum med digital innholdsproduksjon med en hyperavansert mediemotor – utvidet av AI og akselerert med Intel® -teknologi. Skap spektakulært innhold drevet av grafikk med støtte for alle de ledende, nåværende medieformatene, samtidig som du holder deg oppdatert med de nyeste AV1-videoenkodingsegenskapene."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "OPPLEV NYSKAPENDE AI-PRODUKSJON OG &#8209;GAMING",
                    "tr_specText": "Intel® Arc™ &#8209;grafikk låser opp kraftige AI-egenskaper, fra avansert produksjon til oppslukende gaming. Få mest mulig ut av dagens avanserte kreative programvarer, inkludert tekst-til-bilde-generering og AI-akselerert videoredigering. Pluss at du kan oppleve gaming med høy oppløsning og AI-akselerert X<sup class='small_text_sup_xe'>e</sup>SS&#8209;oppskalering."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Grafikk",
                    "tr_specText": "Profesjonell grafikk starter her"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "SUPERT FOR KREATIVE FAGFOLK PÅ FARTEN",
                    "tr_specText": "Tilgjengelig på utvalgte Intel® Core™ Ultra &#8209;prosessorer i H-serien. Innebygget Intel® Arc™ Pro&#8209;grafikk låser opp dobbelt så kraftige forbedringer i grafikkytelsen med sertifiseringer for uavhengige programvareleverandører (IVS) for ekstra pålitelighet, og leverer effektiv maskinvareakselerasjon med AI og strålesporing for fantastisk innholdsproduksjon på farten.<br/><br/><span class='expandable_heading'>Programvaresertifiseringer<br/></span><br/>Intel har jobbet nært sammen med hundrevis av programvareselskaper gjennom årenes løp, og denne erfaringen har vi tatt med inn i Intel® Arc™ Pro &#8209;grafikkortene i A-serien. Sertifiseringer er like viktige for oss som de er for deg."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "GJØR AI TILGJENGELIG",
                    "tr_specText": "Denne GPU-serien inkluderer et bredt utvalg av flott teknologi, inkludert støtte for kompatible AI-verktøy med innebygget, dedikert AI-akselerering der du trenger den."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "STRÅLESPORING UTEN PREMIUMKOSTNADEN",
                    "tr_specText": "Grafikkort må gjøre mer enn å bare dytte piksler ut på skjermer, de må også akselerere produksjonen av vakre bilder. Maskinvareteknologi for strålesporing innebygget i Intel® Arc™ Pro &#8209;grafikken i A-serien lar de kompatible programmene dine begynne å utforske disse nye profesjonelle arbeidsflytene. Vi tenker ofte at strålesporing bare er tilgjengelig med dyr grafikk, men Intels nyeste profesjonelle grafikkserie bryter med disse forventningene."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "NYE HØYDER FOR INNHOLDSSKAPERE MED OPPSLUKENDE SKJERMER",
                    "tr_specText": "Med full støtte for DirectX* 12 Ultimate på all Intel® Arc™ &#8209;grafikk, kan du nyte fantastisk, oppslukende gaming takket være de nyeste grafikkteknologiene som strålesporing, variable rate shading, mesh shading og tilbakemelding fra sampler – grunnlaget for neste generasjons gaming."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "DYNAMISK OPTIMERTE BILDER",
                    "tr_specText": "Med dedikert maskinvaredekoding på Pro GPU-ene bidrar Dolby Vision* til å skape større dybde, skarpere kontrast og flere farger på kompatible skjermer. Dolby Vision* låser opp det fulle potensialet til HDR‑teknologien ved å dynamisk optimere bildekvaliteten basert på tjenesten, skjermen og plattformen din for å levere slående bilder hver gang."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "PROGRAMVARE VERIFISERT AV EKSPERTER",
                    "tr_specText": "Intel har jobbet nært sammen med hundrevis av programvareselskaper gjennom årenes løp, og denne erfaringen har vi tatt med inn i Intel® Arc™ Pro &#8209;grafikkporteføljen i A-serien. Sertifiseringer og kvalifiseringer av programvarearbeidsflyter er like viktige for oss som de er for deg."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "STRÅLESPORING UTEN PREMIUMKOSTNADEN",
                    "tr_specText": "Grafikkort må gjøre mer enn å bare dytte piksler ut på skjermer, de må også akselerere produksjonen av vakre bilder. Maskinvareteknologi for strålesporing innebygget i Intel® Arc™ Pro &#8209;grafikken i A-serien lar de kompatible programmene dine begynne å utforske disse nye profesjonelle arbeidsflytene. Vi tenker ofte at strålesporing bare er tilgjengelig med dyr grafikk, men Intels nyeste profesjonelle grafikkserie bryter med disse forventningene."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "FLERE STORE SKJERMER MED ÉN LITEN MOBIL GPU",
                    "tr_specText": "Intel® Arc™ Pro &#8209;grafikk for bærbare PC-er kan støtte opptil fire ultra-store skjermer for å forbedre effektiviteten til arbeidsflytene dine. Legg til støtte for HDR (high dynamic range) og du har alt du trenger for det neste oppsettet ditt med flere skjermer, enten til sosiale medier-opplegg eller hjemmekontoret."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "MULIGHET FOR FULL MINNEBÅNDBREDDE",
                    "tr_specText": "Båndbredden til grafikkortet er en viktig faktor for en arbeidsstasjon. Blir den feil, kan det redusere hastigheten på profesjonelle arbeidsflyter. Derfor har vi sørget for at Intel®&nbsp;Arc™&nbsp;Pro GPU&#8209;en i A&#8209;serien har støtte for moderne PCIe*&nbsp;4.0, uten å gå på bekostning av bakoverkompatibilitet. Grafikkminnet forbedres ytterligere med en høy båndbreddehastighet, slik at programvaren får tilgang til prosjektdataene dine enda raskere."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "PUST LIV I HVER RAMME",
                    "tr_specText": "<span class='expandable_heading'>AI-forbedret. Støttet av Intel.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS er en AI-forbedret oppskaleringsteknologi som øker ytelsen uten å oppofre skjønnheten til hver ramme – slik at du kan oppleve gamingen slik den var ment å være."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "SANNTID.<br/> VIRKELIG OPPSLUKENDE.<br/> REALISME PÅ SITT BESTE.",
                    "tr_specText": "Mer realistiske verdener venter. Med innebygget strålesporing kan du oppleve alt fra livaktige skygger og refleksjoner til mesterlig belysning. Med andre ord er det en visuell gamingerfaring du ikke vil gå glipp av."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "DEN BESTE GAMINGOPPLEVELSEN, DELT PÅ SITT BESTE",
                    "tr_specText": "Gi publikum den sømløse gamingopplevelsen de fortjener å se. Med de maskinvareakselererte AV1-enkodingsegenskapene til Intel® Arc™ &#8209;grafikken, vil de bli bergtatt av hvert trekk du gjør. Høy bildekvalitet med samme bithastighet betyr streaming på sitt beste mens du sparer båndbredde."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Velkommen til alle skapere",
                    "tr_specText": "Nye og forbedrede AI-opplevelser klare til bruk. Med avanserte AI-akseleratorer innebygget på tvers av Intel® Arc™ &#8209;grafikken i A-serien, kan du bruke mer tid på å skape og mindre tid på å vente på de forskjellige programmene.<br/><br/>Ta videoproduksjonen din til neste nivå og vis vei med AV1-video med høy kvalitet og effektiv lagring, drevet av avanserte AV1-maskinvareegenskaper bygget inn i Intel® Arc™ &#8209;grafikken."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Alt du trenger for å få jobben gjort",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Kraftig ytelse som er tynn, lett og bygd for å vare - du kan få alt. Med lang batterilevetid, vakre berøringsskjermer*, og de nyeste halvlederstasjonenene (SSD-er) finner du det leter etter i en Windows 10-PC.",
                "tr_noteSection": "*Maskinvareavhengig",
                "titleArray": [
                  {
                    "tr_title": "Less is more",
                    "tr_subtitle": "Dagens PC-er bruker vanligvis mindre strøm slik at du får mer ut av batteriet — i gjennomsnitt mer enn åtte timer med videoavspilling.**",
                    "tr_noteSection": "**Batteritiden varierer betydelig, avhengig av enhet, innstillinger, bruk og andre faktorer."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "ALT DU TRENGER FOR Å FÅ JOBBEN GJORT",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Kraftig ytelse som er tynn, lett og bygd for å vare - du kan få alt. Med lang batterilevetid, vakre berøringsskjermer*, og de nyeste halvlederstasjonenene (SSD-er) finner du det leter etter i en Windows 10-PC.",
                "tr_noteSection": "*Maskinvareavhengig",
                "titleArray": [
                  {
                    "tr_title": "LESS IS MORE",
                    "tr_subtitle": "Dagens PC-er bruker vanligvis mindre strøm slik at du får mer ut av batteriet — i gjennomsnitt mer enn åtte timer med videoavspilling.**",
                    "tr_noteSection": "**Batteritiden varierer betydelig, avhengig av enhet, innstillinger, bruk og andre faktorer."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Strømlinjeformet for sikkerhet og ytelse",
                "tr_pretitle": "Windows* 10 i S-modus",
                "tr_subtitle": "Når du kjører Windows* 10 i S-modus, får du et ekstra lag beskyttelse, slik at du kan jobbe, spille og utforske med visshet om at enheten er sikker."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "STRØMLINJEFORMET FOR SIKKERHET OG YTELSE",
                "tr_title": "Windows* 10 i S-modus",
                "tr_subtitle": "Når du kjører Windows* 10 i S-modus, får du et ekstra lag beskyttelse, slik at du kan jobbe, spille og utforske med visshet om at enheten er sikker."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Gjør fantastiske ting",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Ytelse, pris og mye å velge mellom. Fra nyskapende 2-i-1-enheter og slanke bærbare datamaskiner til berøringsskjermer og løse tastatur – uansett hvilket budsjett du har eller hvilken stil du ønsker, finner du alltid en Windows* 10-PC som passer for deg."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "GJØR FANTASTISKE TING",
                "tr_title": "Windows*",
                "tr_subtitle": "Ytelse, pris og mye å velge mellom. Fra nyskapende 2-i-1-enheter og slanke bærbare datamaskiner til berøringsskjermer og løse tastatur – uansett hvilket budsjett du har eller hvilken stil du ønsker, finner du alltid en Windows* 10-PC som passer for deg."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Minne",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "HVOR MYE RAM TRENGER DU?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Hverdag – 2 GB OPPTIL 8 GB ",
                    "tr_badgeSubTitle": "Ideelt for dem som hovedsakelig bruker PC&#8209;en til tekstbehandling og nettsurfing og vanligvis fokuserer på ett program om gangen."
                  },
                  {
                    "tr_badgeTitle": "Kraftig – 8 GB OPPTIL 16 GB RAM",
                    "tr_badgeSubTitle": "Med mer RAM kjører programmene raskere, og du kan også kjøre flere programmer samtidig."
                  },
                  {
                    "tr_badgeTitle": "Avansert – MER ENN 16&nbsp;GB RAM",
                    "tr_badgeSubTitle": "Hvis du er interessert i spill, redigering av bilder eller video eller 3D&#8209;modellering, trenger du ekstra RAM for å håndtere disse intensive programmene."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX]&nbsp;GB RAM for multitasking i hverdagen",
                "tr_subtitle": "Minnet gjør at grunnleggende programmer for tekstbehandling og nettsurfing kan lastes inn og kjøre uten problemer.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX]&nbsp;GB RAM for kraftkrevende multitasking",
                "tr_subtitle": "Hvis du bruker flere programmer samtidig, gjør [memory_total]&nbsp;GB minne at du kan veksle mellom dem uten problemer.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX]&nbsp;GB RAM for avansert multitasking",
                "tr_subtitle": "Du får nok minne til å kjøre flere krevende programmer samtidig uten at PC-en blir treg.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1] GB minne = [Value2] GB Intel® Optane™ &#8209;minne + [Value3] GB RAM",
                "tr_subtitle": "Denne kombinasjonen av minneløsninger gir deg raskere harddiskytelse fra Intel® Optane™ &#8209;minne samt bedre respons fra RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>MINNE</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ &#8209;minne",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Mindre ventetid. Mer av det du elsker.",
                "tr_subtitle": "Når Intel® Optane™ -minne jobber sammen med systemminnet, får du raskere tilgang til programmene du bruker ofte, slik at de startes og lastes inn akkurat når du trenger dem.",
                "tr_pretitle": "[XX] GB Intel® Optane™ -minne",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "HVA GJØR MINNET FOR PC-EN MIN?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Raskere innholdsproduksjon",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Mere responsiv gaming",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Enklere multitasking",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Åpne programmer raskere",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ &#8209;minne",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Få fart på gamingen din</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ -minne",
                "tr_pretitle1": "[XX] GB Intel® Optane™ &#8209;minne akselerer gamingen din",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Start spill opptil <br/><span class=\"optane-game\">98 % raskere</span> <br/>fra datadisken med en [optane_legal_processor_name] &#8209;prosessor med Intel® Optane™ -minne<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Last inn nivåer opptil <br/><span class=\"optane-game\">4,1 ganger raskere</span> <br/>fra datadisken med en [optane_legal_processor_name] &#8209;prosessor med Intel® Optane™ -minne<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Intel® Optane™ &#8209;minne",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Få fart på gamingen din</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ -minne",
                "tr_pretitle1": "[XX] GB Intel® Optane™ &#8209;minne akselerer gamingen din",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Start spill opptil <br/><span class=\"optane-game\">98 % raskere</span> <br/>fra datadisken med en [optane_legal_processor_name] &#8209;prosessor med Intel® Optane™ -minne<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Last inn nivåer opptil <br/><span class=\"optane-game\">4,1 ganger raskere</span> <br/>fra datadisken med en [optane_legal_processor_name] &#8209;prosessor med Intel® Optane™ -minne<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1] GB minne = [Value2] GB Intel® Optane™ &#8209;minne + [Value3] GB RAM",
                "tr_subtitle": "Denne kombinasjonen av minneløsninger gir deg raskere harddiskytelse fra Intel® Optane™ &#8209;minne samt bedre respons fra RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX]&nbsp;GB RAM for multitasking",
                    "tr_subtitle": "Hvis du bruker flere programmer samtidig, gjør [memory_total]&nbsp;GB minne at du kan veksle mellom dem uten problemer."
                  },
                  {
                    "tr_title": "For hverdagens oppgaver",
                    "tr_subtitle": "Minnet gjør at grunnleggende programmer for tekstbehandling og nettsurfing kan lastes inn og kjøre uten problemer."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX]&nbsp;GB RAM",
                "tr_pretitle": "FOR MER MULTITASKING",
                "tr_subtitle": "RAM gir prosessoren raskere tilgang til programfiler, og gjør at den kan arbeide med flere oppgaver samtidig.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standardminne",
                    "tr_badgeSubTitle": "Opptil 8&nbsp;GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Avansert minne",
                    "tr_badgeSubTitle": "Over 8 GB og opptil 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Eliteminne",
                    "tr_badgeSubTitle": "Mer enn 16&nbsp;GB RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB for mer multitasking",
                "tr_pretitle": "FOR MER MULTITASKING",
                "tr_subtitle": "RAM gir prosessoren raskere tilgang til programfiler, og gjør at den kan arbeide med flere oppgaver samtidig.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standardminne",
                    "tr_badgeSubTitle": "Opptil 8&nbsp;GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Avansert minne",
                    "tr_badgeSubTitle": "Over 8 GB og opptil 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Eliteminne",
                    "tr_badgeSubTitle": "Mer enn 16&nbsp;GB RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVA SLAGS BRUK PASSER DETTE MINNET TIL?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Standard",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avansert",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Elite",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Minne",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "HVA SLAGS BRUK PASSER DETTE MINNET TIL?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "HVA SLAGS BRUK PASSER DETTE MINNET TIL?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Gjeldende enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Hverdag",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Kraftig",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avansert",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Lagring",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "HVOR MYE ER DET PLASS TIL PÅ PC&#8209;EN DIN?",
                "tr_subtitle": "Du trenger ikke å gjette! Vi viser deg nøyaktig hva du kan lagre på denne PC&#8209;en.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Opprett et bibliotek med</span> <br/><span class=\"storage-standard\">[num_songs] sanger<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>basert på et gjennomsnitt på 5&nbsp;MB per sang",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Opprett et bibliotek med</span><br/><span class=\"storage-standard\">[num_games] spill<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>basert på et gjennomsnitt på 40&nbsp;GB per spill",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Lagringsplass for</span><br/><span class=\"storage-standard\">[num_videos] videoer<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>basert på et gjennomsnitt på 5&nbsp;GB per video",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Ta og lagre</span><br/><span class=\"storage-standard\">[num_photos] bilder<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>basert på et gjennomsnitt på 4&nbsp;MB per bilde",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity]&nbsp;GB harddisk"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity]&nbsp;GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory]&nbsp;GB Intel® Optane™ &#8209;minne + [convertedSSD]&nbsp;GB SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGRING",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity] GB SSD-lagring",
                "tr_preTitle1": "[capacity] TB SSD-lagring",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Denne PC-en har en SSD med [capacity] GB lagringsplass. Det betyr at du kan lagre et imponerende antall bilder, videoer og programmer og få tilgang til dem mye raskere enn med en harddisk.",
                    "tr_title": "Plass til alt på PC&#8209;en"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Denne PC-en har en SSD med [capacity] TB lagringsplass. Det betyr at du kan lagre et imponerende antall bilder, videoer og programmer og få tilgang til dem mye raskere enn med en harddisk.",
                    "tr_title": "Denne enheten har imponerende lagringskapasitet"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGRING</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGRING</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGRING",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity] GB HDD-lagring",
                "tr_preTitle1": "[capacity] TB HDD-lagring",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Harddisker er et godt valg for dem som ønsker store mengder med lagringsplass direkte på PC&#8209;en.",
                    "tr_title": "Plass til alt på PC&#8209;en"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Harddisker er et godt valg for dem som ønsker store mengder med lagringsplass direkte på PC&#8209;en.",
                    "tr_title": "Denne enheten har imponerende lagringskapasitet"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>LAGRING</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGRING",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "HVOR MYE ER DET PLASS TIL PÅ PC&#8209;EN DIN?",
                "tr_subtitle": "Du trenger ikke å gjette! Vi viser deg nøyaktig hva du kan lagre på denne PC&#8209;en.",
                "titleArray": [
                  {
                    "tr_title": "[XX]&nbsp;GB HDD"
                  },
                  {
                    "tr_title": "[XX]&nbsp;GB SSD"
                  },
                  {
                    "tr_title": "[total_capacity] GB Intel® SSD akselerert av [optane_memory_size] GB Intel® Optane™ -minne"
                  },
                  {
                    "tr_title": "[total_capacity] TB Intel® SSD akselerert av  [optane_memory_size] GB Intel® Optane™ &#8209;minne"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD] GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD] GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD] TB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD] TB HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGRING</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGRING</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGRING</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGRING</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGRING</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGRING</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGRING",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
                "tr_preTitle1": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
                "tr_preTitle2": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
                "tr_preTitle3": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
                "titleArray": [
                  {
                    "tr_title": "Plass til alt på PC&#8209;en",
                    "tr_title1": "Denne enheten har imponerende lagringskapasitet",
                    "tr_subtitle": "Denne PC&#8209;en har en SSD med [convertedSSD] GB lagringsplass og en [convertedHDD] GB harddisk. Det gir deg både enorm kapasitet og rask lagringsytelse.",
                    "tr_subtitle1": "Denne PC&#8209;en har en SSD med [convertedSSD] TB lagringsplass og en [convertedHDD] GB harddisk. Det gir deg både enorm kapasitet og rask lagringsytelse.",
                    "tr_subtitle2": "Denne PC&#8209;en har en SSD med [convertedSSD] GB lagringsplass og en [convertedHDD] TB harddisk. Det gir deg både enorm kapasitet og rask lagringsytelse.",
                    "tr_subtitle3": "Denne PC&#8209;en har en SSD med [convertedSSD] TB lagringsplass og en [convertedHDD] TB harddisk. Det gir deg både enorm kapasitet og rask lagringsytelse."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGRING</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGRING</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGRING",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Få raskere tilgang til høykapasitetslagring med Intel®&nbsp;Optane™ &#8209;minne med SSD.",
                "tr_title": "Gigantiske filer er velkommen",
                "tr_pretitle": "[total_capacity] GB Intel® SSD akselerert av [optane_memory_size] GB Intel® Optane™ -minne",
                "tr_pretitle1": "[total_capacity] TB Intel® SSD akselerert av  [optane_memory_size] GB Intel® Optane™ &#8209;minne",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Denne revolusjonerende lagringsløsningen er den første som kombinerer Intel® Optane™ &#8209;minne og en SSD på én brikke. Fordeler:",
                    "tr_title": "HVA ER INTEL® OPTANE™ -MINNE MED SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Åpne det du trenger, raskt",
                    "tr_badgeSubTitle": "Ved å forhåndslaste filer og programmer som brukes ofte, kan du bruke mer tid på å skape innhold og mindre tid på å vente.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Lagring pluss hastighet",
                    "tr_badgeSubTitle": "Du får plassen du trenger for mediefiler, med den raske ytelsen til en SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Mer responsiv med tiden",
                    "tr_badgeSubTitle": "Intel® Optane™ &#8209;minne med SSD lærer seg måten du arbeider på, noe som gjør PC&#8209;en din mer effektiv.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGRING",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Med massevis av pålitelig lagringsplass kan du oppbevare alle mediene dine lett tilgjengelig.",
                "tr_title": "Plass til alle bildene, videoene og sangene dine"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skjerm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] millioner piksler for å sveipe, knipe og forbløffe",
                "tr_subtitle": "Samhandle med PC-en på samme måte som med en smarttelefon, med intuitive kontroller og skarpere, mer livaktige detaljer.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Oppløsning: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Piksler: [screenResolution] millioner"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] tommer skjerm",
                    "tr_badgeSubTitle": "Skjermhøyde: [screenHeight] tommer",
                    "tr_badgePreTitle": "Skjermbredde: [YY] tommer"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Berøringsskjerm",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skjerm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Fordyp deg i [screenResolution] millioner piksler",
                "tr_subtitle": "Det handler om pikslene. Jo flere du har, jo skarpere og mer levende blir bildene.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Oppløsning: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Piksler: [screenResolution] millioner"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] tommer skjerm",
                    "tr_badgeSubTitle": "Skjermhøyde: [screenHeight] tommer",
                    "tr_badgePreTitle": "Skjermbredde: [YY] tommer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skjerm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] tommer <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "DITT VINDU TIL GAMINGUNIVERSET",
                "tr_subtitle": "På samme måte som for en TV er det størrelse og oppløsning som er det viktigste ved&nbsp;en&nbsp;gamingskjerm.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Oppløsning: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Piksler: [screenResolution] millioner"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] tommer skjerm",
                    "tr_badgeSubTitle": "Skjermhøyde: [screenHeight] tommer",
                    "tr_badgePreTitle": "Skjermbredde: [screenWidth] tommer"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Berøringsskjerm"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skjerm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Denne enheten har en imponerende skjerm",
                "tr_subtitle": "Med mer enn åtte ganger så mange piksler som HD når videoene og spillene dine nye høyder av skarphet og farger.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Oppløsning: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Piksler: [screenResolution] millioner"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] tommer skjerm",
                    "tr_badgeSubTitle": "Skjermhøyde: [screenHeight] tommer",
                    "tr_badgePreTitle": "Skjermbredde: [YY] tommer"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skjerm",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] millioner piksler du kan sveipe og berøre",
                    "tr_subTitle": "Vis og samhandle med tingene du liker best, med skarpere, mer levende detaljer."
                  },
                  {
                    "tr_title": "[screenResolution] millioner piksler som gjør det digitale universet ditt enda vakrere",
                    "tr_subTitle": "Det handler om pikslene. Jo flere du har, jo skarpere og mer levende blir bildene."
                  },
                  {
                    "tr_title": "Denne enheten har en imponerende skjerm",
                    "tr_subTitle": "Med nesten fire ganger så mange piksler som HD når skarpheten og fargene i videoer og spill et helt nytt nivå."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tilkoblingsmuligheter",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3-teknologi gir den raskeste og mest allsidige tilkoblingen til alle dokkingstasjoner, skjermer eller dataenheter.",
                "tr_title": "Én port som gjør alt",
                "tr_title1": "ÉN PORT SOM GJØR ALT",
                "titleArray": [
                  {
                    "tr_title": "HVORDAN KAN JEG BRUKE THUNDERBOLT™ 3-TEKNOLOGIEN?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 3 &#8209;teknologi"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K-skjermer",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Koble til skjermer med fantastisk oppløsning, kontrast og farge"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Eksternt graffikkort",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Oppgrader grafikken på enhver PC betydelig og umiddelbart"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Raskt nettverk",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Gir raske node-til-node-tilkoblinger"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tilkoblingsmuligheter",
                "detailBGUrl": "",
                "tr_subtitle": "Thunderbolt™ 3 &#8209;teknologien overfører data med høyere hastighet enn konvensjonell USB, slik at du raskt og enkelt kan integrere eksternt utstyr, for eksempel flere skjermer eller eksterne grafikkort.",
                "tr_title": "Thunderbolt™ 3 &#8209;teknologi",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "LYNRASKE DATA",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Thunderbolt™ 3 &#8209;teknologi",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tilkoblingsmuligheter",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Én universalport og samme kabel støtter alt tilbehøret ditt</li> <li>Pålitelig lading av bærbare datamaskiner</li> <li>Solid sertifisering skaper ekstra trygghet</li></ul>",
                "tr_title": "En virkelig universell kabeltilkobling",
                "tr_title1": "EN VIRKELIG UNIVERSELL KABELTILKOBLING",
                "tr_noteSection": "<sup>†</sup>Basert på minstekravene for spesifikasjoner",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "HVORDAN BRUKER JEG THUNDERBOLT™ 4 &#8209;TEKNOLOGI?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 4 &#8209;teknologi"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Skape",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Koble til en ekstern skjerm og rask lagringsenhet for å vise og redigere bilder og videoer."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dokk",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Skap et ryddig skrivebord ved å koble den bærbare datamaskinen til alt tilbehøret med én og samme kabel."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Game",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Legg til mer SSD- og HDD-kapasitet med hastigheter opptil 3&nbsp;000&nbsp;MB/s for å få mer plass til spill og mediefiler."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maksimal ytelse<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Fire ganger raskere enn USB* 3.2 <br/>To ganger raskere enn HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tilkoblingsmuligheter",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Dra fordel av den samme utrolige ytelsen til Thunderbolt™ 3 -teknologien med enda flere funksjoner, slik at du enkelt kan koble til og bruke de nyeste Thunderbolt™ -dokkingstasjonene, -skjermene, -lagringsenhetene eller ethvert USB*-tilbehør.",
                "tr_title": "Thunderbolt™ 4 &#8209;teknologi",
                "tr_pretitle": "EN VIRKELIG UNIVERSELL KABELTILKOBLING",
                "tr_noteSection": "<sup>†</sup>Basert på minstekravene for spesifikasjoner",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Én Thunderbolt™ 4 &#8209;universalkabel kan erstatte alle USB&#8209;C*&#8209;kablene dine og gjøre valget av kabel raskt og enkelt."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Thunderbolt™ 4 &#8209;dokkingstasjoner har opptil fire Thunderbolt™ &#8209;porter, slik at du kan koble til mer av utstyret ditt."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maksimal ytelse<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Fire ganger raskere enn USB* 3.2 <br/>To ganger raskere enn HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tilkoblingsmuligheter",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Én universalport og samme kabel støtter alt tilbehøret ditt</li> <li>Pålitelig lading av bærbare datamaskiner</li> <li>Solid sertifisering skaper ekstra trygghet</li></ul>",
                "tr_title": "Få mest mulig ut av opplevelsen med Thunderbolt™ -tilbehør",
                "tr_noteSection": "<sup>†</sup>Basert på minstekravene for spesifikasjoner",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "HVORDAN BRUKER JEG THUNDERBOLT™ 4 &#8209;TEKNOLOGI?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Skape",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Koble til en ekstern skjerm og rask lagringsenhet for å vise og redigere bilder og videoer."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dokk",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Skap et ryddig skrivebord ved å koble den bærbare datamaskinen til alt tilbehøret med én og samme kabel."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Game",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Legg til mer SSD- og HDD-kapasitet med hastigheter opptil 3&nbsp;000&nbsp;MB/s for å få mer plass til spill og mediefiler."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maksimal ytelse<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Fire ganger raskere enn USB* 3.2 <br/>To ganger raskere enn HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "De vanligste typene Wi&#8209;Fi er:",
                "tr_title": "LÆR MER OM WI&#8209;FI-STANDARDER",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 tillater høyere hastigheter selv om mange brukere benytter det samme signalet.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Med Intels siste innovasjon innen Wi-Fi kan du oppnå høyere hastigheter enn med Gigabit-Wi-Fi, selv  med flere brukere på samme signal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Wi&#8209;Fi 6E er den største Wi&#8209;Fi-oppgraderingen på 20 år. Med denne standarden får du nye høyhastighetskanaler som sørger for forbedret ytelse og pålitelighet samt mindre forstyrrelser."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 tillater høyere hastigheter selv om mange brukere benytter det samme signalet.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Alle fordelene med Wi-Fi 6E kombinert med Intel® Killer™ Prioritization Engine som gir mer båndbredde til nettstedene og programmene som trenger det mest."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E",
                    "tr_badgeSubTitle": "Med mindre konkurranse på høyhastighetskanalene kan du få forbedret ytelse og pålitelighet."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 tillater høyere hastigheter selv om mange brukere benytter det samme signalet.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi&#8209;Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Med mindre forstyrrelser takket være Wi-Fi 6 og den intelligente ytelsen til Intel® Killer™ Prioritization Engine, får du en raskere og mer responsiv opplevelse på nettet.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Med mindre konkurranse på høyhastighetskanalene kan du få forbedret ytelse og pålitelighet."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Trådløse PC-hastigheter på over 5 Gbps med konsekvent og svært liten ventetid – del filer på sekunder og ikke minutter."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi&#8209;Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) er den nyeste teknologien med \"ekstrem\" pålitelighet, lite ventetid og hastigheter med AI-basert nettverksoptimering."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 tillater høyere hastigheter selv om mange brukere benytter det samme signalet.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) når hastigheter større enn 1 Gbps med pålitelighet og lite ventetid ved å bruke 6 GHz-kanaler."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Mer effektivt, større kapasitet og høyere hastigheter sammenlignet med Wi-Fi 5 på nettverk med flaskehalser."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Få bedre tilkobling med Wi&#8209;Fi [XX], med raskere<sup>†</sup> og mer pålitelig surfing.",
                "tr_title": "Få bedre rekkevidde og hastighet",
                "tr_noteSection": "<sup>†</sup>Faktisk trådløs trafikk og/eller rekkevidde vil variere.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "I flerspillingens og nettspillenes tidsalder er uavbrutt tilkobling viktigere enn noensinne. I&nbsp;tillegg til høy hastighet trenger du et trådløst signal som kan opprettholde hastigheten selv ved lange avstander.",
                "tr_title": "[XX]",
                "tr_pretitle": "TILKOBLING ER ALFA OG OMEGA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Wi&#8209;Fi 6 (Gig+) kan du koble til Wi&#8209;Fi 6-rutere med støtte for 160 MHz-kanaler, for å få raskere tilkobling og ekstra stabilitet ved flere aktive brukere.",
                "tr_title": "Nesten tre ganger raskere hastighet med Intel® <span class='no-warp'>Wi-Fi 6 </span> (Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Sammenlignet med standard AC Wi&#8209;Fi. Noen funksjoner er bare tilgjengelige med visse SKU-er. Sjekk med produsenten for å få mer informasjon. For mer informasjon om Wi&#8209;Fi, gå til intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "To-bånds Intel® Wi-Fi 6 på inngangsnivå, støtter forbedret ytelse for grunnleggende tilkoblingsbehov som e-post og nettsurfing på overbelastede nettverk.<sup>9</sup>",
                "tr_title": "Grunnleggende Wi&#8209;Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "I flerspillingens og nettspillenes tidsalder er uavbrutt tilkobling viktigere enn noensinne. I&nbsp;tillegg til høy hastighet trenger du et trådløst signal som kan opprettholde hastigheten selv ved lange avstander.",
                "tr_title": "[XX]",
                "tr_pretitle": "TILKOBLING ER ALFA OG OMEGA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Med disse nye Wi&#8209;Fi-kanalene slipper du å dele båndbredde med eldre enheter i nærheten. Dermed vil du oppleve bedre streamingkvalitet, rask surfing og effektiv utførelse av jobb- og skoleoppgaver.",
                "tr_title": "Koble til uten konkurranse",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Få bedre tilkobling med [XX], slik at surfingen blir raskere<sup>†</sup> og mer pålitelig.",
                "tr_title": "Få bedre rekkevidde og hastighet",
                "tr_noteSection": "<sup>†</sup>Faktisk trådløs trafikk og/eller rekkevidde vil variere.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "I flerspillingens og nettspillenes tidsalder er uavbrutt tilkobling viktigere enn noensinne. I&nbsp;tillegg til høy hastighet trenger du et trådløst signal som kan opprettholde hastigheten selv ved lange avstander.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "TILKOBLING ER ALFA OG OMEGA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Innebygd ultrarask Wi&#8209;Fi",
                "tr_subtitle": "Koble fra uten å miste kontakt med internettverdenen din."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Med dedikerte høyhastighetsbaner for mindre forstyrrelse og Intel® Killer™ Prioritization Engine som prioriterer gaming, har online gaming fått en kraftig oppgradering.",
                "tr_title": "Legg konkurrentene bak deg",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Med dedikerte høyhastighetsbaner for mindre forstyrrelse og Intel® Killer™ Prioritization Engine som prioriterer gaming, har online gaming fått en kraftig oppgradering.",
                "tr_title": "[XX]",
                "tr_pretitle": "LEGG KONKURRENTENE BAK DEG",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Med dedikerte høyhastighetsbaner for mindre forstyrrelse og prioritering av gaming, har online gaming fått en kraftig oppgradering.",
                "tr_title": "[XX]",
                "tr_pretitle": "LEGG KONKURRENTENE BAK DEG",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi&#8209;Fi akselererer automatisk nettverkstrafikken for programmene som trenger det mest, slik at du kan streame videoer med skarpe detaljer og mindre bufring.",
                "tr_title": "En raskere, smartere måte å koble til på.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "I flerspillingens og nettspillenes tidsalder er uavbrutt tilkobling viktigere enn noensinne. I&nbsp;tillegg til høy hastighet trenger du et trådløst signal som kan opprettholde hastigheten selv ved lange avstander.",
                "tr_title": "[XX]",
                "tr_pretitle": "TILKOBLING ER ALFA OG OMEGA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Få bedre tilkobling med Wi-Fi 5, med raskere<sup>†</sup> og mer pålitelig surfing.",
                "tr_title": "Få bedre rekkevidde og hastighet",
                "tr_noteSection": "<sup>†</sup>Faktisk trådløs trafikk og/eller rekkevidde vil variere.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "I flerspillingens og nettspillenes tidsalder er uavbrutt tilkobling viktigere enn noensinne. I&nbsp;tillegg til høy hastighet trenger du et trådløst signal som kan opprettholde hastigheten selv ved lange avstander.",
                "tr_title": "[XX]",
                "tr_pretitle": "LEGG KONKURRENTENE BAK DEG",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Wi-Fi 7-egenskaper bygger på og forbedrer tidligere Wi-Fi-generasjoner. Dette betyr ikke bare raskere hastigheter, men en dramatisk forbedring i respons og pålitelighet for oppslukende forbrukeropplevelser og sofistikerte fremtidige teknologier.",
                "tr_title": "PC-nettverk nærmest som kablet",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Wi-Fi 7-egenskaper bygger på og forbedrer tidligere Wi-Fi-generasjoner. Dette betyr ikke bare raskere hastigheter, men en dramatisk forbedring i respons og pålitelighet for oppslukende forbrukeropplevelser og sofistikerte fremtidige teknologier.",
                "tr_title": "[XX]",
                "tr_pretitle": "FORVANDLER BRUKEROPPLEVELSEN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Høyere hastighet, kortere ventetid og bedre pålitelighet med AI-basert nettverksoptimering",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Høyere hastighet, kortere ventetid og bedre pålitelighet med AI-basert nettverksoptimering.",
                "tr_title": "[XX]",
                "tr_pretitle": "RESPONS NÆRMEST SOM KABLET",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Hvordan måles det?",
                    "tr_badgeSubTitle": "De fleste produsenter oppgir batterilevetiden i timer. Selv om denne beregningen ikke er 100 % nøyaktig, bør dette hjelpe deg med å sammenligne batterilevetiden til forskjellige PC&#8209;er."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FOR GAMING NÅR DU ER PÅ FARTEN",
                "tr_subtitle": "Med ytelsen til dagens bærbare gaming-PC-er trenger du ikke å være låst til skrivebordet. Nå kan du game hvor som helst.",
                "tr_title": "[Dynamic.battery.values.hours] timer med batteritid"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Koble fra, og hold kontakten med appene, spillene og musikken din i timevis.",
                "tr_title": "[XX] timer med streaming på én enkelt lading<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Basert på PC-produsentens angitte batterilevetid.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] timer",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Koble fra, og hold kontakten med appene, spillene og musikken din i timevis.",
                "tr_title": "[Dynamic.battery.values.hours] timer med strøm på farten<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Basert på PC-produsentens angitte batterilevetid.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] timer",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Koble fra, og hold kontakten med appene, spillene og musikken din i timevis.",
                "tr_title": "[Dynamic.battery.values.hours] timer for strøm hele dagen på én enkelt lading<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Basert på PC-produsentens angitte batterilevetid.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] timer",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Denne datamaskinen optimaliserer batterilevetiden slik at den varer lengre og gir deg minst 4 timers batterilevetid etter 30 minutters lading. <sup>4</sup>",
                "tr_title": "Vær stikkontaktfri lenger med [Dynamic.battery.values.hours] timer batteritid<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Basert på PC-produsentens angitte batterilevetid.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] timer",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Hjem",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Hvorfor denne PC&#8209;en",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Vis alle spesifikasjonene",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Ekstern",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobil",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Juridisk",
        "tr_paragraph_array": [
          "Programvare og arbeidsbelastninger som brukes i ytelsestester, kan ha vært optimalisert bare med tanke på ytelsen til Intel® -mikroprosessorer. Ytelsestester, for eksempel SYSmark* og MobileMark*, måles ved hjelp av bestemte datamaskinsystemer, -komponenter og funksjoner. Hvis noen av disse faktorene endres, kan resultatene variere. Dersom du ønsker å foreta en fullstendig evaluering av planlagte innkjøp, bør du sjekke annen informasjon og andre ytelsestester, inkludert ytelsen til det aktuelle produktet i kombinasjon med andre produkter. Hvis du vil ha mer fullstendig informasjon om ytelse og resultater av ytelsestester, kan du gå til www.intel.com/benchmarks.",
          "¹Intel® Wi-Fi 6 (Gig+) -produkter støtter valgfrie 160 MHz-kanaler, noe som gir de høyeste mulige teoretiske maksimumshastighetene (2402 Mbps) for typiske 2x2 802.11ax PC-Wi-Fi-produkter. Førsteklasses Intel® Wi-Fi 6 (Gig+) -produkter muliggjør to til fire ganger høyere maksimale teoretiske hastigheter sammenlignet med standard 2x2 (1201 Mbps) eller 1x1 (600 Mbps) 802.11ax-PC-Wi-Fi-produkter, som bare støtter det obligatoriske kravet, som er 80 MHz-kanaler.",
          "²Anbefaling kun til informasjonsformål. Bruk også andre kilder når du skal ta kjøpsbeslutningen.",
          "³Intel® ‑teknologienes funksjoner og fordeler avhenger av systemkonfigurasjonen og kan kreve kompatibel maskinvare, programvare eller aktivering av tjenester. Ytelsen varierer avhengig av systemkonfigurasjonen. Ingen datasystemer kan være fullstendig sikre. Forhør deg med systemprodusenten eller forhandleren, eller les mer på intel.com.",
          "⁴For systemer med FHD-skjerm, når de brukes til trådløs surfing. Når det er slått av, fra standard avslutningsnivå for OEM.",
          "⁵Sammenlignet med andre PC-I/U-tilkoblingsteknologier inkludert eSATA, USB og IEEE 1394* FireWire. Ytelsen vil variere avhengig av hvilken maskin- og programvare du bruker. Er avhengig av en Thunderbolt™ -kompatibel enhet.",
          "⁶Nesten tre ganger raskere: 802.11ax 2x2 160 MHz muliggjør teoretiske maksimale datahastigheter på 2402 Mbps. Ca. tre ganger raskere enn standard 802.11ac 2x2 80 MHz (867 Mbps) Wi&#8209;Fi, som dokumentert i IEEE 802.11* (standardspesifikasjoner for trådløs teknologi), og krever bruk av trådløse 802.11ax-nettverksrutere med lignende konfigurasjon.",
          "⁷75 % ventetidsreduksjon: Basert på Intels simuleringsdata (79 %) av 802.11ax med og uten OFDMA med 9 klienter. Gjennomsnittlig ventetid uten OFDMA er 36 ms. Med OFDMA blir gjennomsnittlig ventetid redusert til 7,6 ms. For å få kortere ventetid må 802.11ax-ruteren (Wi-Fi 6) og alle klientene støtte OFDMA.",
          "⁸ Basert på den teoretiske maksimale datahastigheten i 802.11-spesifikasjonen kan 2x2 160 MHz Wi&#8209;Fi 6/6E (802.11ax) gi en hastighet på 2402 Mbps. Sammenlignet med 867 Mbps for 2x2 80 MHz Wi&#8209;Fi 5 (802.11ac) er dette 2,8 ganger raskere.",
          "⁹Wi&#8209;Fi 6 som er best i sin klasse: Intel® Wi&#8209;Fi 6 (GIG+) -produkter støtter valgfrie 160 MHz kanaler, noe som gir de høyeste mulige teoretiske maksimumshastighetene (2402 Mbps) for typiske 2x2 802.11ax PC-Wi&#8209;Fi-produkter. Førsteklasses Intel® Wi&#8209;Fi 6 (Gig+) -produkter muliggjør to til fire ganger høyere maksimale teoretiske hastigheter sammenlignet med standard 2x2 (1201 Mbps) eller 1x1 (600 Mbps) 802.11ax-PC-Wi&#8209;Fi-produkter, som bare støtter det obligatoriske kravet, som er 80 MHz-kanaler.",
          "Bare for utvalgte SKU-er. Funksjonaliteten varierer avhengig av OEM-design. Sjekk med OEM-en eller forhandleren for å få mer informasjon. Du finner mer informasjon om Wi&#8209;Fi på intel.com/wifi6disclaimers.",
          "¹⁰Thunderbolt™ 4-teknologi med 40 Gbps gir den raskeste, enkleste og mest pålitelige kabelløsningen til alle dokkingstasjoner, skjermer eller dataenheter sammenlignet med andre PC-I/U-tilkoblingsteknologier inkludert eSATA*, USB* og IEEE 1394* FireWire.",
          "¹¹Krever en ruter basert på 802.11ax som støtter OFDMA og flere klienter på nettverket med støtte for AX. Bedre ytelse i tette miljøer kan oppnås med OFDMA-funksjonen støttet av 802.11ax-klienter og -tilgangspunkter. 2 Gbps basert på antakelser om ca. 70 % av maksimale teoretiske datahastigheter med IEEE 802.11*-spesifikasjonen for 802.11ax 160 MHz 2402 Mbps.",
          "¹²Målt med arbeidsbelastning ved åpning av dokument med bakgrunnsaktivitet, med sammenligning av 8. generasjons Intel® Core™ i7-8565U -prosessor (512 GB TLC-SSD) og 8. generasjons Intel® Core™ i7-8565U -prosessor (32 GB + 512 GB Intel® Optane™ -minne H10 med solid-state-lagring).",
          "¹³Intel® Thread Director er utviklet for 12. generasjons Intel® Core™ -prosessorer og hjelper operativsystemet med å kanalisere arbeidsflyter til riktig kjerne på en smartere måte. Ingen brukerhandling nødvendig. Du finner mer informasjon på intel.com.",
          "¹⁴Ikke tilgjengelig på visse 12. generasjons Intel® Core™ -prosessorer. Den høytytende hybridarkitekturen kombinerer to nye kjernemikroarkitekturer, Performance-cores (ytelseskjerner – P-cores) og Efficient-cores (effektivitetskjerner – E-cores), på én og samme prosessorbrikke. Utvalgte 12. generasjons Intel® Core™ &#8209;prosessorer (enkelte 12. generasjons Intel® Core™ i5 &#8209;prosessorer og lavere) støtter ikke den høytytende hybridarkitekturen, kun P-cores.",
          "¹⁵Som målt via arbeidsbelastning ved start av spill ved sammenligning av 8. generasjons Intel® Core™ i7-8750H -prosessor (32&nbsp;GB Intel® Optane™ -minnemodul + Intel® SSD 256&nbsp;GB PCIe* + 1&nbsp;TB harddisk) kontra 8. generasjons Intel® Core™ i7-8750H -prosessor (Intel® SSD 256&nbsp;GB PCIe* + 1&nbsp;TB harddisk). Arbeidsbelastning ved start av spill – Arbeidsbelastning utviklet av Intel som måler tiden som går med til å starte Total War*: WARHAMMER* II Build: 5577.0, og nå hovedmenyen med introduksjonsvideoer deaktivert (Start). Intel® Optane™ -minne, akselereringskonfigurasjoner for datadisk: Intel® Core™ i7-8750H -prosessor, PL1 = 45&nbsp;W TDP, 6C12T, turbo opptil 4&nbsp;GHz på OEM-system (førproduksjonsenhet), grafikk: NVIDIA* GeForce* GTX 1070, minne: 2&nbsp;x&nbsp;4&nbsp;GB DDR4, lagring: Intel® SSD Series 760p 256&nbsp;GB (PCIe*)&nbsp;+&nbsp;1&nbsp;TB HDD + 32&nbsp;GB Intel® Optane™ -minne, OS: Windows* 10 RS3 Build 1709, MCU 0x84; Intel® Core™ i7-8750H -prosessor, PL1 = 45&nbsp;W TDP, 6C12T, turbo opptil 4&nbsp;GHz på OEM-system (førproduksjonsenhet), grafikk: NVIDIA* GeForce* GTX 1070, minne: 2&nbsp;x&nbsp;4&nbsp;GB DDR4, lagring: Intel® SSD Series 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB harddisk, OS: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁶Som målt via arbeidsbelastning ved innlasting av spillnivå ved sammenligning av 8. generasjons Intel® Core™ i7-8750H -prosessor (32&nbsp;GB Intel® Optane™ -minnemodul) kontra 8. generasjons Intel® Core™ i7-8750H -prosessor (bare harddisk). Arbeidsbelastning ved lasting av spillnivå – Arbeidsbelastning utviklet av Intel, som måler tiden som har gått fra hovedmenyen til fullføring av nivåinnlasting (Nivåinnlasting) i Total War*: WARHAMMER* II Build: 5577.0. Intel® Optane™ -minne, akselereringskonfigurasjoner for datadisk: Intel® Core™ i7-8750H -prosessor, PL1 = 45&nbsp;W TDP, 6C12T, turbo opptil 4&nbsp;GHz på OEM-system (førproduksjonsenhet), grafikk: NVIDIA* GeForce* GTX 1070, minne: 2&nbsp;x&nbsp;4&nbsp;GB DDR4, lagring: Intel® SSD Series 760p 256&nbsp;GB (PCIe*)&nbsp;+&nbsp;1&nbsp;TB HDD + 32&nbsp;GB Intel® Optane™ -minne, OS: Windows* 10 RS3 Build 1709, MCU 0x84; Intel® Core™ i7-8750H -prosessor, PL1 = 45&nbsp;W TDP, 6C12T, turbo opptil 4&nbsp;GHz på OEM-system (førproduksjonsenhet), grafikk: NVIDIA* GeForce* GTX 1070, minne: 2&nbsp;x&nbsp;4&nbsp;GB DDR4, lagring: Intel® SSD Series 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB harddisk, OS: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁷På Performance-cores. Ytelse varierer avhengig av bruk, konfigurasjon og andre faktorer. Les mer på www.intel.com/PerformanceIndex.",
          "¹⁸Endring av klokkefrekvens eller spenning kan skade eller redusere holdbarheten til prosessoren og andre systemkomponenter, samt redusere systemets ytelse og stabilitet. Produktgarantier kan bli ugyldige hvis du bruker prosessoren på en måte som overskrider spesifikasjonene. Du finner mer informasjon hos produsentene av systemet og enkeltkomponentene.",
          "¹⁹Som målt med Intel vPro® &#8209;plattformens uovertrufne kombinasjon av sikkerhetsegenskaper over og under operativsystemet, beskyttelse av programvare og data samt avansert trusselbeskyttelse for enhver bedriftsstørrelse, i tillegg til Intels «sikkerhet først»&#8209;tilnærming til produktdesign, &#8209;produksjon og &#8209;støtte. Alle bedrifts-PC-er bygget på Intel vPro® &#8209;plattformen har blitt testet og godkjent i henhold til strenge spesifikasjoner, inkludert unike maskinvarebaserte sikkerhetsfunksjoner. Se www.intel.com/PerformanceIndex (platforms) for informasjon. Ingen produkter eller komponenter kan være fullstendig sikre.",
          "²⁰Intel® Connectivity Performance Suite er kun tilgjengelig på Windows*&#8209;baserte systemer. Intel OTA (Over The Air) Wi-Fi&#8209;testing på et overbelastet nettverk kontra uten ICPS &#8209;programvare for prioritering av trådløs applikasjonstrafikk og smart veksling av tilgangspunkt. Se www.intel.com/PerformanceIndex (connectivity) for informasjon. Resultater kan variere.",
          "²¹Sammenlignet med tidligere prosessorgenerasjon.",
          "²²Intel® Arc™ &#8209;grafikk er først på markedet med AV1-enkodingsstøtte (per første kvartal 2022). Du finner mer informasjon på intel.com/performanceindex.",
          "²³Som målt av de unike egenskapene ved Intel® Evo™ &#8209;utforminger, som også er godkjente for å tilfredsstille viktige terskler for mobil brukeropplevelse. Testing per februar 2022.",
          "²⁴Intel® Unison™ &#8209;løsningen er for øyeblikket bare tilgjengelig på støttede Intel® Evo™ &#8209;utforminger på Windows*-baserte PC-er og kan kun pares med Android- eller iOS-baserte telefoner. Alle enheter må kjøre en støttet OS-versjon.",
          "²⁵Maksimal minnehastighet er basert på konfigurasjoner med 1 DIMM per kanal (1DPC). Bruk av flere DIMM-moduler i en kanal kan påvirke den maksimale minnehastigheten. Opptil DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 og DDR5-5200 1Rx8, 1Rx16, 2Rx8 på utvalgte produktkoder (SKU). Maksimal minnekapasitet kan oppnås med 2DPC-konfigurasjoner.",
          "²⁶Kontra standard Wi-Fi. Se intel.com/performance-wireless for mer informasjon. Resultater kan variere.",
          "²⁷Høytytende hybridarkitektur kombinerer to kjernemikroarkitekturer, ytelseskjerner (P-kjerner) og effektive kjerner (E-kjerner) på en enkeltprosessorbrikke som først ble introdusert på 12. generasjons Intel® Core™ -prosessorer. Utvalgte 12. generasjons og nyere Intel® Core™ &#8209;prosessorer har ikke høytytende hybridarkitektur, bare P-kjerner, og har samme bufferstørrelse som tidligere generasjoner. Se ark.intel.com for SKU-detaljer.",
          "²⁸Intel® Thread Director er bygget inn i maskinvaren og leveres kun i høytytende hybridarkitekturkonfigurasjoner med 12. generasjons Intel® Core™ &#8209;prosessorer eller nyere; krever støtte i operativsystemet. Tilgjengelige funksjoner og funksjonalitet varierer avhengig av operativsystemet.",
          "²⁹Intel® Thread Director er bygget inn i maskinvaren og leveres kun i 12. og 13. generasjons Intel® Core™ &#8209;prosessorer med høytytende hybridarkitekturkonfigurasjoner; krever støtte i operativsystemet. Tilgjengelige funksjoner og funksjonalitet varierer avhengig av operativsystemet.",
          "³⁰Som målt ytelse på referensevalideringsplattform for Intel® Core™ Ultra ‑laptopprosessor (serie 2 – kodenavn: Lunar Lake) kontra Intel® Core™ Ultra ‑laptopprosessorer (serie 1 – kodenavn: Meteor Lake) for 3DMark Time Spy. Se intel.com/performanceindex for informasjon. Resultater kan variere.",
          "³¹Bakgrunnsuskarp og ansiktssporing er bare tilgjengelig på Windows*-baserte systemer.",
          "³²Wi-Fi 7-produkter får tilgang til 320 Mhz-kanaler på 6 GHz og nye 160 Mhz-kanalkombinasjoner på 5 GHz med nye Multi-RU Puncturing-egenskaper.",
          "³³Basert på trådløse standardspesifikasjoner fra IEEE og maksimal teoretisk datahastighet for enheter som håndterer to datastrømmer samtidig.",
          "³⁴Intel-simuleringer av nettverksmiljøer med flaskehalser, indikerer at det er mulig å få til store reduksjoner i ventetid med ny Wi-Fi 7 Multi-Link Operation-støtte.",
          "³⁵Minimumslading oppnådd fra OEM&#8209;standard avstengingsnivå. Individuelle systemresultater kan variere. Se intel.com/performance-evo for mer informasjon.",
          "³⁶6 GHz Wi&#8209;Fi-båndet er ikke tilgjengelig for bruk i alle land/regioner.",
          "³⁷Bare Windows*",
          "³⁸Lading oppnådd fra OEM&#8209;standard avstengingsnivå. Hvis du vil ha mer utfyllende informasjon om ytelse og resultater av ytelsestester, kan du gå til intel.com/Evo.",
          "³⁹Refererer til Intel® Core™ Ultra ‑laptopprosessorer (Serie 2), basert på de unike maskinvarebaserte sikkerhetsegenskapene, uovertrufne kombinasjoner av sikkerhetsfunksjoner over og under operativsystemet, imponerende ytelse selv ved lite strømforbruk sammenlignet med tidligere generasjoner (pr. september 2024). Se intel.com/performanceindex for informasjon. Resultater kan variere.",
          "⁴⁰Programvareapplikasjonene som vises er bare for illustrasjonsformål. AI-funksjonalitet kan kreve kjøp av ekstra programvare, abonnementer eller aktivering hos en programvare- eller plattformleverandør, eller kan ha spesifikke konfigurerings- eller kompatibilitetskrav. Mer informasjon tilgjengelig på www.intel.com/PerformanceIndex. Resultater kan variere. © Intel Corporation.",
          "⁴¹Bildene som vises kan være endret eller simulerte. AI-funksjonalitet kan kreve kjøp av ekstra programvare, abonnementer eller aktivering hos en programvare- eller plattformleverandør, eller kan ha spesifikke konfigurerings- eller kompatibilitetskrav. Mer informasjon tilgjengelig på www.intel.com/AIPC.",
          "⁴²Rulles ut gradvis i forhåndsvisning i den siste oppdateringen til Windows* 11 i utvalgte globale markeder. Tilgjengeligheten varierer med enhet og marked. Les mer her: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Intel® Unison™ -løsning er nå tilgjengelig for utvalgte utforminger. Se www.intel.com/PerformanceIndex for informasjon.",
          "⁴⁴FPS oppskalert med Intel® X<sup>e</sup>SS vs. integrert FPS som målt av Dying Light 2 Stay Human som kjører på Intel® Core™ Ultra 7 ‑prosessor 165H. Se www.intel.com/PerformanceIndex for informasjon. Resultater kan variere.",
          "⁴⁵Som målt av lavere systembrikkestrøm for Intel® Core™ Ultra ‑laptopprosessorer (serie 2 – kodenavn: Lunar Lake) på referansevalideringsplattform kontra Intel® Core™ Ultra ‑laptopprosessorer (serie 1 – kodenavn: Meteor Lake) på referansevalideringsplattform på YouTube* 4K 30FPS AV1. Se intel.com/performanceindex for informasjon. Resultater kan variere.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel tar ikke ansvar for feil priser.",
          "*Andre navn og varemerker tilhører sine respektive eiere.",
          "OPPHAVSRETT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES OG RELATERTE UTFORMINGER OG SAMMENSATTE MERKER ER VAREMERKER FOR INFLEXION STUDIOS INC. MED ENERETT.",
          "Intel -teknologier kan kreve aktivering av maskinvare, programvare eller tjenester.",
          "Alle resultater og data fra NERO* leveres for å gjøre det enklere for deg og kun for informasjonsformål. Intel Corporation har ikke noe ansvar for NERO* -programmets nøyaktighet, ytelsesresultater og poengresultater, eller mulige variasjoner i ytelsesdata fra andre nedlastbare versjoner av programmet som finnes på www.nero.com eller andre tredjepartsnettsteder. Ytelsestestresultater for PC fra NERO* måles med bestemte datasystemer, komponenter, programmer, operasjoner og funksjoner. Hvis noen av disse faktorene endres, kan resultatene variere. Dersom du ønsker å foreta en fullstendig evaluering av planlagte innkjøp, bør du sjekke annen informasjon og andre ytelsestester, inkludert ytelsen til det aktuelle produktet i kombinasjon med andre produkter.",
          "Alle versjoner av Intel vPro® &#8209;plattformen krever en støttet Intel® Core™ &#8209;prosessor, et støttet operativsystem, Intel® LAN- og/eller WLAN-brikke, fastvareforbedringer og annen maskinvare og programvare som kreves for å levere administrasjons- og sikkerhetsfunksjonene, systemytelsen og stabiliteten som definerer plattformen.",
          "Ytelse varierer avhengig av bruk, konfigurasjon og andre faktorer. Les mer på www.intel.com/PerformanceIndex.",
          "Intel har ikke kontroll over og fører ikke tilsyn med data fra tredjeparter. Du bør innhente informasjon fra andre kilder for å evaluere nøyaktigheten.",
          "© Intel Corporation. Intel, Intel-logoen og andre Intel-merker er varemerker for Intel Corporation eller dets datterselskaper. *Andre navn og varemerker tilhører sine respektive eiere.",
          "Ingen produkter eller komponenter kan være fullstendig sikre.",
          "Kostnadene og resultatene kan variere.",
          "Intel, Intel-logoen, Intel Core, Intel Optane, Intel Iris, Thunderbolt og Thunderbolt-logoen er varemerker som tilhører Intel Corporation eller dets datterselskaper.  Andre navn og varemerker tilhører sine respektive eiere.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Hjem",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Skann for å sammenligne",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Trender",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/evo/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/video_link.mp4",
      "iconURL": "",
      "videoName": "video_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Desktop Processors Overview (52 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ Performance to Power Their World (16_9).mp4",
      "iconURL": "",
      "videoName": "Video_ Performance to Power Their World (16_9)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/chatbot/Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds).mp4",
      "iconURL": "",
      "videoName": "Video_ 12th Gen Intel Core Mobile Processors (Movie Night - 14 Seconds)"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "",
      "targetURL": "assets/lang/en-us/std/videos/ai/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "VELKOMMEN",
    "tr_welcomePagetrSubtitle": "Fjernstyr denne PC-en med smarttelefonen din",
    "tr_welcomePagembSubtitle": "Velkommen! Utforsk spesifikasjonene til denne PC-en på smarttelefonen din",
    "tr_welcomePageBtnText": "Kom i gang",
    "tr_welcomePageText": "Utforsk og sammenlign detaljene til denne PC&#8209;en, på smarttelefonen din",
    "tr_scrollText": "Naviger på enheten foran deg",
    "tr_toolTipTextc1": "Bruk disse knappene for å navigere til forskjellige sider på enheten foran deg.",
    "tr_toolTipTextc2": "Når du trykker på kortene nedenfor, kommer du til detaljerte sider om de forskjellige enhetsspesifikasjonene.",
    "tr_toolTipTextc3": "Bruk disse knappene for å flytte opp og ned på skjermen foran deg.",
    "tr_toolTipTextc4": "Slå av fjernstyringsmodusen for å vise enhetsspesifikasjonene på smarttelefonen din.",
    "tr_toolTipTextmb1": "Trykk på kortene for å se sider med detaljert innhold.",
    "tr_toolTipTextmb2": "Trykk for å se et sammendrag av alle enhetsspesifikasjonene",
    "tr_toolTipTextmb3": "Trykk på knappen for å skanne QR-koden og sammenligne opptil fire enheter",
    "tr_nextButton": "Neste",
    "tr_prevButton": "Forrige",
    "tr_gotItButton": "SKJØNNER",
    "tr_skipButton": "Hopp over",
    "tr_placeholderText": "*plassholder",
    "tr_processorHeading": "Prosessor",
    "tr_processorSubheading": "10. generasjons Intel® Core™ i7 &#8209;prosessor",
    "tr_memorySubheading": "[XX]&nbsp;GB",
    "tr_storageSubheading": "[XX]&nbsp;GB Intel® Optane™ -minne + [YY]&nbsp;GB SSD",
    "tr_coachmarkProcessor": "XX &#8209;prosessor",
    "tr_coachmarkGraphics": "[XX] -grafikk",
    "tr_coachmarkMemory": "Spesifikasjonsverdi",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "USD 999,95",
    "tr_compare": "Sammenlign",
    "tr_viewallspecs": "VIS ALLE SPESIFIKASJONENE",
    "tr_operatingSystem": "OPERATIV&shy;SYSTEM",
    "tr_AmdProcessorNonIntel": "AMD* &#8209;prosessor",
    "tr_QualcommProcessorNonIntel": "Qualcomm* &#8209;prosessor",
    "tr_GBText": "GB",
    "tr_remoteText": "Spesifikasjonene som vises på laptopskjermen",
    "tr_scanErrorText": "Du kan kun skanne opptil tre enheter samtidig. Slett én eller flere enheter.",
    "tr_scanErrorText2": "Du har allerede skannet denne enheten. Skann en annen.",
    "tr_scanMsg": "Skann for å legge til enhet",
    "tr_backToScan": "Gå tilbake for å sammenligne",
    "tr_scanToCompare": "Skann for å sammenligne",
    "tr_compareDevice": "Sammenlign enheter",
    "tr_processorTitle": "PROSESSOR",
    "tr_graphicTitle": "GRAFIKK",
    "tr_storageTitle": "LAGRING",
    "tr_displayTitle": "SKJERM",
    "tr_batteryTitle": "BATTERI",
    "tr_memoryTitle": "MINNE",
    "tr_wifiTitle": "Wi&#8209;Fi",
    "tr_connectivityTitle": "TILKOBLING",
    "tr_priceTitle": "PRIS",
    "tr_operatingSystemTitle": "OPERATIVSYSTEM",
    "tr_batteryHoursText": "[XX] timer",
    "tr_hrsText": "timer",
    "tr_touchscreeenText": "[screenDiagonal]” berøringsskjerm",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, berøringsskjerm",
    "tr_FHDTouchScreenText": "FHD, berøringsskjerm",
    "tr_4KTouchscreenText": "4K, berøringsskjerm",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Lagre",
    "tr_device_comparison": "Dokument for enhetssammenligning [dd]-[mm]-[yyyy]",
    "tr_Save_As_JPEG": "Lagre som JPEG",
    "tr_Save_As_PDF": "Lagre som PDF",
    "tr_Downloading_PDF": "Nedlasting av PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Åpne",
    "tr_logo": "logo",
    "tr_laptop_name": "Navn på bærbar datamaskin",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Intel® Optane™ &#8209;minne",
    "tr_inchUnit": "tommer",
    "tr_wifi1": "Wi&#8209;Fi 1",
    "tr_wifi2": "Wi&#8209;Fi 2",
    "tr_wifi3": "Wi&#8209;Fi 3",
    "tr_wifi4": "Wi&#8209;Fi 4",
    "tr_wifi5": "Wi&#8209;Fi 5",
    "tr_wifi6": "Intel® Wi&#8209;Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI&#8209;FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi&#8209;Fi 6E",
    "tr_wifiXX": "Wi&#8209;Fi [XX]",
    "tr_wifiXXCaps": "WI&#8209;FI [XX]",
    "tr_wifi6E": "Intel® Wi&#8209;Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "INTEL® OPTANE™ &#8209;MINNE",
    "tr_Thunderbolt3": "Thunderbolt™ 3 &#8209;teknologi",
    "tr_Thunderbolt4": "Thunderbolt™ 4 &#8209;teknologi",
    "tr_processorGraphicsCaps": "PROSESSORGRAFIKK",
    "tr_processorGraphicsSmall": "Prosessorgrafikk",
    "tr_graphicsCardCaps": "DEDIKERT GRAFIKK",
    "tr_graphicsCardSmall": "Dedikert grafikk",
    "tr_processorTitleCamel": "Prosessor",
    "tr_graphicTitleCamel": "Grafikk",
    "tr_storageTitleCamel": "Lagring",
    "tr_displayTitleCamel": "Skjerm",
    "tr_batteryTitleCamel": "Batteri",
    "tr_memoryTitleCamel": "Minne",
    "tr_connectivityTitleCamel": "Tilkoblingsmuligheter",
    "tr_priceTitleCamel": "Pris",
    "tr_operatingSystemTitleCamel": "Operativsystem",
    "tr_viewallspecsCamel": "Vis alle spesifikasjonene",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal]” [screenResolutionType] berøringsskjerm",
    "tr_OS": "OPERATIV&shy;SYSTEM",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "PC-spesifikasjoner",
    "tr_explorePC_text": "Utforsk alt om denne PC-en",
    "tr_mtlPC_text": "Se hva det virkelig betyr å være utviklet for å gjøre alt",
    "tr_optaneMemory": "Intel® Optane™ &#8209;minne",
    "tr_displayText_Display": "[screenDiagonal]” SKJERM",
    "tr_displayTextResolution_Display": "[screenDiagonal]” [screenResolutionType] SKJERM",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal]” [screenResolutionType] BERØRINGSSKJERM",
    "tr_displayTextTouchscreeenText": "[screenDiagonal]” BERØRINGSSKJERM",
    "tr_learnMoreLabel": "Les mer",
    "tr_batteryHoursTextUpperCase": "[XX] TIMER",
    "tr_processorGraphicsIrisXe": "11. GENERASJONS INTEL® CORE™ [cpu_fam] -PROSESSOR OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> -GRAFIKK",
    "tr_processorGraphicsIrisXeMax": "11. GENERASJONS INTEL® CORE™ [cpu_fam] -PROSESSOR OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX -GRAFIKK",
    "tr_Thunderbolt3UpperCase": "THUNDERBOLT™ 3 &#8209;TEKNOLOGI",
    "tr_Thunderbolt4UpperCase": "THUNDERBOLT™ 4 &#8209;TEKNOLOGI",
    "tr_processorWithArcGraphics": "12. GENERASJONS INTEL® CORE™ [cpu_fam] -PROSESSOR OG INTEL® ARC™ -GRAFIKK",
    "tr_processorGraphicsIrisXe12Gen": "12. GENERASJONS INTEL® CORE™ [cpu_fam] -PROSESSOR OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> -GRAFIKK",
    "tr_processorGraphicsIrisXeMax12Gen": "12. GENERASJONS INTEL® CORE™ [cpu_fam] -PROSESSOR OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX -GRAFIKK",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "13. GENERASJONS INTEL® CORE™ [cpu_fam] -PROSESSOR OG INTEL® ARC™ -GRAFIKK",
    "tr_processorGraphicsIrisXe13Gen": "13. GENERASJONS INTEL® CORE™ [cpu_fam] -PROSESSOR OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> -GRAFIKK",
    "tr_processorGraphicsIrisXeMax13Gen": "13. GENERASJONS INTEL® CORE™ [cpu_fam] -PROSESSOR OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX -GRAFIKK",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "INTEL® ARC™ A350M -GRAFIKK",
      "tr_A370M": "INTEL® ARC™ A370M -GRAFIKK",
      "tr_A730M": "INTEL® ARC™ A730M -GRAFIKK",
      "tr_A770M": "INTEL® ARC™ A770M -GRAFIKK",
      "tr_A310": "INTEL® ARC™ A310 -GRAFIKK",
      "tr_A380": "INTEL® ARC™ A380 -GRAFIKK",
      "tr_A580": "INTEL® ARC™ A580 -GRAFIKK",
      "tr_A750": "INTEL® ARC™ A750 -GRAFIKK",
      "tr_A770": "INTEL® ARC™ A770 -GRAFIKK"
    },
    "tr_memoryValue": "[XX]&nbsp;GB RAM",
    "tr_memoryWithOptaneDesc": "[Value1] GB minne = [Value2] GB Intel® Optane™ &#8209;minne + [Value3] GB RAM",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
    "tr_textGBSSD": "[convertedSSD]&nbsp;GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD]&nbsp;GB harddisk",
    "tr_textTBHDD": "[convertedHDD] TB harddisk",
    "tr_textStorageWithOptaneGB": "[XX]&nbsp;GB Intel® Optane™ -minne + [convertedSSD]&nbsp;GB SSD",
    "tr_textStorageWithOptaneTB": "[XX] GB Intel® Optane™ &#8209;minne + [convertedSSD] TB SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home i S-modus",
      "tr_OS21": "Windows* 10 Pro i S-modus",
      "tr_OS22": "Windows* 10 Enterprise i S-modus",
      "tr_OS23": "Windows* 11 Home i S-modus",
      "tr_OS24": "Windows* 11 Pro i S-modus",
      "tr_OS25": "Windows* 11 Enterprise i S-modus"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Intel® Arc™ A310 -grafikk",
      "tr_GR387": "Intel® Arc™ A350M -grafikk",
      "tr_GR388": "Intel® Arc™ A370M -grafikk",
      "tr_GR389": "Intel® Arc™ A380 -grafikk",
      "tr_GR390": "Intel® Arc™ A530M -grafikk",
      "tr_GR391": "Intel® Arc™ A550M -grafikk",
      "tr_GR392": "Intel® Arc™ A570M -grafikk",
      "tr_GR393": "Intel® Arc™ A580 -grafikk",
      "tr_GR394": "Intel® Arc™ A730M -grafikk",
      "tr_GR395": "Intel® Arc™ A750 -grafikk",
      "tr_GR396": "Intel® Arc™ A770 -grafikk",
      "tr_GR397": "Intel® Arc™ A770M -grafikk",
      "tr_GR398": "Intel® Arc™ -grafikk",
      "tr_GR399": "Intel® Arc™ Pro A30M -grafikk",
      "tr_GR400": "Intel® Arc™ Pro A40/A50 -grafikk",
      "tr_GR401": "Intel® Arc™ Pro A60 -grafikk",
      "tr_GR402": "Intel® Arc™ Pro A60M -grafikk",
      "tr_GR403": "Intel® -Grafikk",
      "tr_GR404": "Intel® HD -grafikk 2000",
      "tr_GR405": "Intel® HD -grafikk 2500",
      "tr_GR406": "Intel® HD -grafikk 3000",
      "tr_GR407": "Intel® HD -grafikk 400",
      "tr_GR408": "Intel® HD -grafikk 4000",
      "tr_GR409": "Intel® HD -grafikk 405",
      "tr_GR410": "Intel® HD -grafikk 4200",
      "tr_GR411": "Intel® HD -grafikk 4400",
      "tr_GR412": "Intel® HD -grafikk 4600",
      "tr_GR413": "Intel® HD -grafikk 500",
      "tr_GR414": "Intel® HD -grafikk 5000",
      "tr_GR415": "Intel® HD -grafikk 505",
      "tr_GR416": "Intel® HD -grafikk 510",
      "tr_GR417": "Intel® HD -grafikk 515",
      "tr_GR418": "Intel® HD -grafikk 520",
      "tr_GR419": "Intel® HD -grafikk 530",
      "tr_GR420": "Intel® HD -grafikk 5300",
      "tr_GR421": "Intel® HD -grafikk 5500",
      "tr_GR422": "Intel® HD -grafikk 5600",
      "tr_GR423": "Intel® HD -grafikk 6000",
      "tr_GR424": "Intel® HD -grafikk 610",
      "tr_GR425": "Intel® HD -grafikk 615",
      "tr_GR426": "Intel® HD -grafikk 620",
      "tr_GR427": "Intel® HD -grafikk 630",
      "tr_GR428": "Intel® HD -grafikk",
      "tr_GR429": "Intel® Iris® -grafikk 5100",
      "tr_GR430": "Intel® Iris® -grafikk 540",
      "tr_GR431": "Intel® Iris® -grafikk 550",
      "tr_GR432": "Intel® Iris® -grafikk 6100",
      "tr_GR433": "Intel® Iris® Plus -grafikk 640",
      "tr_GR434": "Intel® Iris® Plus -grafikk 645",
      "tr_GR435": "Intel® Iris® Plus -grafikk 650",
      "tr_GR436": "Intel® Iris® Plus -grafikk 655",
      "tr_GR437": "Intel® Iris® Plus -grafikk",
      "tr_GR438": "Intel® Iris® Pro -grafikk 5200",
      "tr_GR439": "Intel® Iris® Pro -grafikk 580",
      "tr_GR440": "Intel® Iris® Pro -grafikk 6200",
      "tr_GR441": "Intel® Iris® X<sup>e</sup> -grafikk",
      "tr_GR442": "Intel® Iris® X<sup>e</sup> MAX -grafikk A220M",
      "tr_GR443": "Intel® Iris® X<sup>e</sup> MAX -grafikk",
      "tr_GR444": "Integrert Intel® -Grafikk",
      "tr_GR445": "Intel® UHD -grafikk 600",
      "tr_GR446": "Intel® UHD -grafikk 605",
      "tr_GR447": "Intel® UHD -grafikk 610",
      "tr_GR448": "Intel® UHD -grafikk 615",
      "tr_GR449": "Intel® UHD -grafikk 617",
      "tr_GR450": "Intel® UHD -grafikk 620",
      "tr_GR451": "Intel® UHD -grafikk 630",
      "tr_GR452": "Intel® UHD -grafikk 710",
      "tr_GR453": "Intel® UHD -grafikk 730",
      "tr_GR454": "Intel® UHD -grafikk 770",
      "tr_GR455": "Intel® UHD -grafikk",
      "tr_GR456": "Intel® UHD -grafikk",
      "tr_GR457": "Intel® UHD -grafikk",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 med Max-Q-design",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti med Max-Q-design",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 med Max-Q-design",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 med Max-Q-design",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 med Max-Q-design",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 med Max-Q-design",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti med Max-Q-design",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti med Max-Q-design",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 med Max-Q-design",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 med Max-Q-design",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super med Max-Q-design",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 med Max-Q-design",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super med Max-Q-design",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 med Max-Q-design",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 med Max-Q-design",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 med Max-Q-design",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 med Max-Q-design",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 med Max-Q-design",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 med Max-Q-design",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 med Max-Q-design",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 med Max-Q-design",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 med Max-Q-design",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 med Max-Q-design",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "Intel® Arc™ ‑grafikk",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Kjøp nå",
    "tr_textTryagain": "Prøv igjen",
    "tr_textBuynowErr": "På grunn av et tilkoblingsproblem er ikke Kjøp nå-alternativet tilgjengelig",
    "tr_chatbotPlaceholder_text": "Spør meg hva som helst om enheten",
    "tr_deviceSpecs_text": "Enhetsspesifikasjoner"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Intel® Core™ i3 &#8209;prosessor med Intel® Hybrid Technology"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Intel® Core™ i5 &#8209;prosessor med Intel® Hybrid Technology"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Skaff deg Intel® for en mektigere gamingopplevelse",
                "tr_subtitle": "Blås liv i Marvel’s Avengers* med optimaliserte spillopplevelser fra Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Opplev Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Der verdens beste gamere samles for å konkurrere",
                "tr_subtitle": "Intel® Extreme Masters er den mest langvarige arrangementserien i gaminghistorien, og setter standarden for e-sport.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Spill som proffene",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Vinnerlagene bruker Intel.",
                "tr_subtitle": "I 2021 vant Soniqs Rainbow Six Siege* North American League. Her reflekterer de over gaming-historien sin, fremtiden for e&#8209;sport og samarbeidet med Intel.",
                "tr_pretitle": "Med Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "VR-klar",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Når virtuell virkelighet blir levende",
                "tr_subtitle": "De mest fantastiske, livaktige og oppslukende opplevelsene med virtuell virkelighet kjøres på en datamaskin. Med de nyeste Intel® Core™ &#8209;prosessorene kan du være hvem du vil, hvor du vil, og oppleve den neste dimensjonen av gaming.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Spill mer enn 100 populære konspollspill – for eksempel Sea of Thieves* og Psychonauts* 2 – på Windows*-PC-er, mobil og nettbrett med Xbox* Game Pass Ultimate og en kompatibel kontroller.",
                "titleArray": [
                  {
                    "tr_title": "Spill på tvers av enheter",
                    "tr_subtitle": "Utforsk Xbox*-universet og fellesskapet der millioner av folk spiller sammen. Koble til og spill med andre med delte spillbibliotek – enten de befinner seg på den andre siden av kloden eller sitter rett ved siden av deg."
                  },
                  {
                    "tr_title": "Gaming på Windows*-PC",
                    "tr_subtitle": "Spill en hel katalog av konsollspill via nettskyen ved hjelp av Xbox* -appen for Windows* og en kompatibel kontroller."
                  },
                  {
                    "tr_title": "Grip kontrolleren og spill",
                    "tr_subtitle": "Spill fantastiske Xbox* -spill enda flere steder enn før. Spill mer enn 100 spill på PC, mobil og nettbrett enten du er hjemme når TV-en er opptatt, på en kafé – eller hvor som helst med rask internettilkobling."
                  },
                  {
                    "tr_title": "Spill uten kontroller",
                    "tr_subtitle": "Med berøringskontrollbasert gaming på Xbox* har du flere måter å spille på. Med den samme enkelheten og nøyaktigheten som du er vant til med fysiske kontroller."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Fokus på",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100+ AI-opplevelser",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Kontroller mer, rør mindre",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Hold deg i bildet, i alle miljøer",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitet",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Korriger belysningen som en proff",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Legg til fantastiske effekter på en enkel måte",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Remiks sanger ved hjelp av AI",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitet",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Forbedre både produktiviteten og personvernet",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Tilbring mer tid uten tilgang til strømnettet",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Din verden. På dine enheter.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Underholdning",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Sømløs streaming, selv når du ikke er koblet til",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Rask, oppslukende gaming",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Fokus på",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100+ AI-opplevelser",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Gjør favorittsangene dine virkelig til dine",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Se visjonen din i sanntid",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitet",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Raskeste vei til ferdig resultat",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Gjør hvert bilde helt perfekt",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitet",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Forbedre både produktiviteten og personvernet",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Mer effektivitet. Større frihet.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Din verden. På dine enheter.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Underholdning",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Sømløs HD-streaming, selv når du ikke er koblet til ",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Oppslukende spilling på flere steder",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mer kreativ",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Bilderedigering på den enkle måten",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Lag bilder klare for innramming på sekunder",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Du skriver det. AI generer det.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Høyere ytelse",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Juksekoden for jevnere gaming",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Tilbring mer tid uten tilgang til strømnettet",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-oppslukende",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mer produktiv",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "AI-kameraten din i hverdagen",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Perfekte videoanrop",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Din verden. På dine enheter.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bedre sikkerhet",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Innebygget beskyttelse for å hjelpe til med å holde dataene dine tryggere og mer private",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mer kreativ",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Bilderedigering på den enkle måten",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI. Snarveien til ferdig resultat.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Transkriber tekstene til favorittsangene dine på et øyeblikk",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Spar timevis med redigering med AI",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Høyere ytelse",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Juksekoden for jevnere gaming",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Tilbring mer tid uten tilgang til strømnettet",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-oppslukende",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mer produktiv",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "AI-kameraten din i hverdagen",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Perfekte videoanrop",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Din verden. På dine enheter.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bedre sikkerhet",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Innebygget beskyttelse for å hjelpe til med å holde dataene dine tryggere og mer private",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "All underholdningen din. Én&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "På en PC som også fungerer som et underholdningssenter, trenger du nok lagringsplass til musikk, videoer og spill og den riktige kombinasjonen av prosessor og skjerm for å skape oppslukende visuelle opplevelser. Denne PC&#8209;en har alle tre.",
                "titleArray": [
                  {
                    "tr_title": "Klar for neste generasjons grafikk",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bygg et digitalt bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Et vindu til underholdningen din",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Sømløs gaming og streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bygg et digitalt bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Et vindu til underholdningen din",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bli mer produktiv. Hvor&nbsp;som&nbsp;helst.",
              "tr_tileTitle1": "Få mer gjort. Raskt.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Denne PC&#8209;en har den perfekte balansen mellom kraft og bærbarhet. I tillegg til en prosessor som er laget for slanke og lette utforminger, har denne PC&#8209;en også Wi&#8209;Fi som holder deg tilkoblet og det riktige batteriet for å frigjøre deg fra stikkontakter.",
                "tr_subtitle1": "Med en perfekt balanse mellom kraft og ytelse hjelper denne PC&#8209;en deg å oppnå mer på mindre tid. Dine daglige gjøremål effektiviseres med kombinasjonen av en robust prosessor hjulpet av rikelig minne og lynrask Wi-Fi.",
                "tr_subtitle2": "Denne PC&#8209;en har den perfekte balansen mellom kraft og bærbarhet. I tillegg til en prosessor som er laget for slanke og lette utforminger, har denne PC&#8209;en også Wi&#8209;Fi for å holde deg tilkoblet og riktig minne for multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Bygget for å gjøre mer",
                    "tr_title1": "Intelligent ytelse som gjør en forskjell",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Avanserte tilkoblinger",
                    "tr_title1": "Koble deg til det som betyr mest",
                    "tr_title2": "Wi&#8209;Fi på et helt nytt nivå",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Vær frakoblet lenger",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Sjongler programmer som en proff",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Friheten til å skape",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Innholdsproduksjon er en av de mest krevende oppgavene for PC&#8209;en. Heldigvis har disse komponentene det som trengs for å strømlinjeforme den kreative prosessen.",
                "titleArray": [
                  {
                    "tr_title": "En smartere måte å lage innhold på",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Gi liv til bildene dine",
                    "tr_title1": "La oss skape noe",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Minne for multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Se hva som skjuler seg inni denne PC&#8209;en",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dette er de tre hovedkomponentene du bør se på når du velger en ny PC. Ved å bestemme deg for hvordan du skal bruke den nye PC&#8209;en, er du på god vei mot å avgjøre hva slags hovedkomponenter du trenger. De er:",
                "titleArray": [
                  {
                    "tr_title": "Ytelse for gaming og streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Lagring",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Minne",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "All underholdningen din. Én&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "På en PC som også fungerer som et underholdningssenter, trenger du nok lagringsplass til musikk, videoer og spill og den riktige kombinasjonen av prosessor og skjerm for å skape oppslukende visuelle opplevelser. Denne PC&#8209;en har alle tre.",
                "titleArray": [
                  {
                    "tr_title": "Klar for neste generasjons grafikk",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bygg et digitalt bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Et vindu til underholdningen din",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Sømløs gaming og streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bygg et digitalt bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Et vindu til underholdningen din",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Minne",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Skjerm",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Høyhastighets multitasking",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "HØYHASTIGHETS MULTITASKING",
                "tr_subtitle": "Gjør mer uten å sakke",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan sjonglere flere programmer samtidig."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi som er raskt, kraftig og pålitelig."
                  },
                  {
                    "tr_title": "Minne som bytter sømløst mellom programmer."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochatting",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHATTING",
                "tr_subtitle": "Krystallklare tilkoblinger",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for videochatting:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan ta opp og dele bilder samtidig."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi som er raskt, kraftig og pålitelig."
                  },
                  {
                    "tr_title": "Et webkamera som hjelper deg med å være krystallklar."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-klar",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Når virtuell virkelighet blir levende",
                "tr_subtitle": "Hva skal til for å skape en fullstendig virtuell verden? På en PC trenger du koordineringen mellom prosessoren, grafikkortet og minnet.",
                "titleArray": [
                  {
                    "tr_title": "Kraft til virtuelle univers",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Virkelighetstro detaljer",
                    "tr_title1": "Oppslukende opplevelser",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Videoredigering",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOREDIGERING",
                "tr_subtitle": "Lag filmmagi",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for videoredigering:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan takle store filer."
                  },
                  {
                    "tr_title": "Minne som gjør redigeringsprogramvare rask og responsiv."
                  },
                  {
                    "tr_title": "Grafikk som kutter ned rendringtider."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Høyhastighets multitasking",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "HØYHASTIGHETS MULTITASKING",
                "tr_subtitle": "Gjør mer uten å sakke",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan sjonglere flere programmer samtidig."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi som er raskt, kraftig og pålitelig."
                  },
                  {
                    "tr_title": "Minne som bytter sømløst mellom programmer."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-klar",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Når virtuell virkelighet blir levende",
                "tr_subtitle": "Hva skal til for å skape en fullstendig virtuell verden? På en PC trenger du koordineringen mellom prosessoren, grafikkortet og minnet.",
                "titleArray": [
                  {
                    "tr_title": "Kraft til virtuelle univers",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Virkelighetstro detaljer",
                    "tr_title1": "Oppslukende opplevelser",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D-modellering",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D-MODELLERING",
                "tr_subtitle": "Neste nivå kreasjoner",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for 3D&#8209;modellering:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan takle avansert kreativ programvare."
                  },
                  {
                    "tr_title": "Minne som raskt kan hente inn store filer."
                  },
                  {
                    "tr_title": "Grafikk for kraftig bildeskaping og &#8209;redigering."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Samarbeid i sanntid",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SAMARBEID I SANNTID",
                "tr_subtitle": "Jobb hvor som helst",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for samarbeid på nett:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "En prosessor med kraft til kontorprogramvare."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi du kan stole på."
                  },
                  {
                    "tr_title": "Et webkamera som tar opp i skarp HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-klar",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Når virtuell virkelighet blir levende",
                "tr_subtitle": "Hva skal til for å skape en fullstendig virtuell verden? På en PC trenger du koordineringen mellom prosessoren, grafikkortet og minnet.",
                "titleArray": [
                  {
                    "tr_title": "Kraft til virtuelle univers",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Virkelighetstro detaljer",
                    "tr_title1": "Oppslukende opplevelser",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ bærbare PC&#8209;er",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tynne og lette bærbare PC&#8209;er som denne er ikke ment å være så kraftige",
                "tr_subtitle": "Intel® Evo™ bærbare PC&#8209;er er utviklet for mobil ytelse og har all hastigheten, grafikken og batterilevetiden du trenger – alt i en fantastisk tynn og lett design.",
                "titleArray": [
                  {
                    "tr_title": "Den bærbare datamaskinen betyr mer enn noensinne, så vi har sørget for at den kan gjøre mer enn noensinne.",
                    "tr_subtitle": "Bruk berøringsskjermen til å utforske, rotere og zoome."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Starter på et øyeblikk",
                    "tr_subtitle": "Med umiddelbar oppstart kan PC-en din gå fra hvilemodus til å være klar, på&nbsp;mindre enn ett sekund."
                  },
                  {
                    "tr_title": "Visuelt oppslukende",
                    "tr_subtitle": "En vakker skjerm gir liv til bildene dine med skarp&nbsp;oppløsning og livaktige farger."
                  },
                  {
                    "tr_title": "Få ting gjort raskere",
                    "tr_subtitle": "Få glede av ultraresponsiv ytelse fra 11.&nbsp;generasjons&nbsp;Intel®&nbsp;Core™&nbsp;&#8209;prosessorer."
                  },
                  {
                    "tr_title": "Vær stikkontaktfri lenger",
                    "tr_subtitle": "Hold flyten med en PC som optimaliserer batterilevetiden og&nbsp;lader&nbsp;opp på et blunk."
                  },
                  {
                    "tr_title": "Tilkobling med universalkabel",
                    "tr_subtitle": "PC-en kan raskt, enkelt og trygt kobles til andre enheter med Thunderbolt™&nbsp;4&nbsp;&#8209;teknologi.&nbsp;<sup>10</sup>"
                  },
                  {
                    "tr_title": "Raske og pålitelige tilkoblinger",
                    "tr_subtitle": "Videochat eller del filer med en tilkobling du&nbsp;kan&nbsp;stole&nbsp;på, ved å bruke Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+).&nbsp;<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ bærbare PC&#8209;er",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alt du trenger – og enda litt til – i en tynn og lett laptop.",
                "tr_subtitle": "Intel® Evo™ -laptoper er utviklet for mobil ytelse og førsteklasses brukeropplevelse<sup>2</sup>, uansett hva du holder på med.",
                "titleArray": [
                  {
                    "tr_title": "Den bærbare datamaskinen betyr mer enn noensinne, så vi har sørget for at den kan gjøre mer enn noensinne.",
                    "tr_subtitle": "Bruk berøringsskjermen til å utforske, rotere og zoome."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Starter på et øyeblikk",
                    "tr_subtitle": "Med umiddelbar oppstart kan PC-en din gå fra hvilemodus til å være klar, på&nbsp;mindre enn ett sekund."
                  },
                  {
                    "tr_title": "Visuelt oppslukende",
                    "tr_subtitle": "En vakker skjerm gir liv til bildene dine med skarp&nbsp;oppløsning og livaktige farger."
                  },
                  {
                    "tr_title": "Revolusjonær ytelse",
                    "tr_subtitle": "Få ekstra kraft der du trenger det mest, med intelligent ytelse fra 12. generasjons Intel® Core™ -prosessorer."
                  },
                  {
                    "tr_title": "Vær stikkontaktfri lenger",
                    "tr_subtitle": "Hold flyten med en PC som optimaliserer batterilevetiden og&nbsp;lader&nbsp;opp på et blunk."
                  },
                  {
                    "tr_title": "Tilkobling med universalkabel",
                    "tr_subtitle": "PC-en kan raskt, enkelt og trygt kobles til andre enheter med Thunderbolt™&nbsp;4&nbsp;&#8209;teknologi.&nbsp;<sup>10</sup>"
                  },
                  {
                    "tr_title": "Raske og pålitelige tilkoblinger",
                    "tr_subtitle": "Videochat eller del filer med en tilkobling du kan stole på, med Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ bærbare PC&#8209;er",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tynne og lette bærbare PC&#8209;er som denne er ikke ment å være så kraftige",
                "tr_subtitle": "Intel® Evo™ bærbare PC&#8209;er er utviklet for mobil ytelse og har all hastigheten, grafikken og batterilevetiden du trenger – alt i en fantastisk tynn og lett design.",
                "titleArray": [
                  {
                    "tr_title": "Den bærbare datamaskinen betyr mer enn noensinne, så vi har sørget for at den kan gjøre mer enn noensinne.",
                    "tr_subtitle": "Bruk berøringsskjermen til å utforske, rotere og zoome."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Starter på et øyeblikk",
                    "tr_subtitle": "Med umiddelbar oppstart kan PC-en din gå fra hvilemodus til å være klar, på&nbsp;mindre enn ett sekund."
                  },
                  {
                    "tr_title": "Visuelt oppslukende",
                    "tr_subtitle": "En vakker skjerm gir liv til bildene dine med skarp&nbsp;oppløsning og livaktige farger."
                  },
                  {
                    "tr_title": "Få ting gjort raskere",
                    "tr_subtitle": "Få glede av ultraresponsiv ytelse fra 11.&nbsp;generasjons&nbsp;Intel®&nbsp;Core™&nbsp;&#8209;prosessorer."
                  },
                  {
                    "tr_title": "Vær stikkontaktfri lenger",
                    "tr_subtitle": "Hold flyten med en PC som optimaliserer batterilevetiden og&nbsp;lader&nbsp;opp på et blunk."
                  },
                  {
                    "tr_title": "Tilkobling med universalkabel",
                    "tr_subtitle": "PC-en kan raskt, enkelt og trygt kobles til andre enheter med Thunderbolt™&nbsp;4&nbsp;&#8209;teknologi.&nbsp;<sup>10</sup>"
                  },
                  {
                    "tr_title": "Raske og pålitelige tilkoblinger",
                    "tr_subtitle": "Videochat eller del filer med en tilkobling du&nbsp;kan&nbsp;stole&nbsp;på, ved å bruke Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+).&nbsp;<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ bærbare PC&#8209;er",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alt du trenger – og enda litt til – i en tynn og lett laptop.",
                "tr_subtitle": "Intel® Evo™ -laptoper er utviklet for mobil ytelse og førsteklasses brukeropplevelse<sup>2</sup>, uansett hva du holder på med.",
                "titleArray": [
                  {
                    "tr_title": "Den bærbare datamaskinen betyr mer enn noensinne, så vi har sørget for at den kan gjøre mer enn noensinne.",
                    "tr_subtitle": "Bruk berøringsskjermen til å utforske, rotere og zoome."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Starter på et øyeblikk",
                    "tr_subtitle": "Med umiddelbar oppstart kan PC-en din gå fra hvilemodus til å være klar, på&nbsp;mindre enn ett sekund."
                  },
                  {
                    "tr_title": "Visuelt oppslukende",
                    "tr_subtitle": "En vakker skjerm gir liv til bildene dine med skarp&nbsp;oppløsning og livaktige farger."
                  },
                  {
                    "tr_title": "Revolusjonær ytelse",
                    "tr_subtitle": "Få ekstra kraft der du trenger det mest, med intelligent ytelse fra 12. generasjons Intel® Core™ -prosessorer."
                  },
                  {
                    "tr_title": "Vær stikkontaktfri lenger",
                    "tr_subtitle": "Hold flyten med en PC som optimaliserer batterilevetiden og&nbsp;lader&nbsp;opp på et blunk."
                  },
                  {
                    "tr_title": "Tilkobling med universalkabel",
                    "tr_subtitle": "PC-en kan raskt, enkelt og trygt kobles til andre enheter med Thunderbolt™&nbsp;4&nbsp;&#8209;teknologi.&nbsp;<sup>10</sup>"
                  },
                  {
                    "tr_title": "Raske og pålitelige tilkoblinger",
                    "tr_subtitle": "Videochat eller del filer med en tilkobling du kan stole på, med Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ bærbare PC&#8209;er",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alt du trenger – og enda litt til – i en tynn og lett laptop.",
                "tr_subtitle": "Intel® Evo™ -laptoper er utviklet for mobil ytelse og førsteklasses brukeropplevelse<sup>2</sup>, uansett hva du holder på med.",
                "titleArray": [
                  {
                    "tr_title": "Den bærbare datamaskinen betyr mer enn noensinne, så vi har sørget for at den kan gjøre mer enn noensinne.",
                    "tr_subtitle": "Bruk berøringsskjermen til å utforske, rotere og zoome."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Starter på et øyeblikk",
                    "tr_subtitle": "Med umiddelbar oppstart kan PC-en din gå fra hvilemodus til å være klar, på&nbsp;mindre enn ett sekund."
                  },
                  {
                    "tr_title": "Visuelt oppslukende",
                    "tr_subtitle": "En vakker skjerm gir liv til bildene dine med skarp&nbsp;oppløsning og livaktige farger."
                  },
                  {
                    "tr_title": "Revolusjonær ytelse",
                    "tr_subtitle": "Få ekstra kraft der du trenger det mest, med intelligent ytelse fra 13. generasjons Intel® Core™ -prosessorer."
                  },
                  {
                    "tr_title": "Vær stikkontaktfri lenger",
                    "tr_subtitle": "Hold flyten med en PC som optimaliserer batterilevetiden og&nbsp;lader&nbsp;opp på et blunk."
                  },
                  {
                    "tr_title": "Tilkobling med universalkabel",
                    "tr_subtitle": "PC-en kan raskt, enkelt og trygt kobles til andre enheter med Thunderbolt™&nbsp;4&nbsp;&#8209;teknologi.&nbsp;<sup>10</sup>"
                  },
                  {
                    "tr_title": "Raske og pålitelige tilkoblinger",
                    "tr_subtitle": "Videochat eller del filer med en tilkobling du kan stole på, med Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ bærbare PC&#8209;er",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alt du trenger – og enda litt til – i en tynn og lett laptop.",
                "tr_subtitle": "Intel® Evo™ -laptoper er utviklet for mobil ytelse og førsteklasses brukeropplevelse<sup>2</sup>, uansett hva du holder på med.",
                "titleArray": [
                  {
                    "tr_title": "Den bærbare datamaskinen betyr mer enn noensinne, så vi har sørget for at den kan gjøre mer enn noensinne.",
                    "tr_subtitle": "Bruk berøringsskjermen til å utforske, rotere og zoome."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Starter på et øyeblikk",
                    "tr_subtitle": "Med umiddelbar oppstart kan PC-en din gå fra hvilemodus til å være klar, på&nbsp;mindre enn ett sekund."
                  },
                  {
                    "tr_title": "Visuelt oppslukende",
                    "tr_subtitle": "En vakker skjerm gir liv til bildene dine med skarp&nbsp;oppløsning og livaktige farger."
                  },
                  {
                    "tr_title": "Revolusjonær ytelse",
                    "tr_subtitle": "Få ekstra kraft der du trenger det mest, med intelligent ytelse fra 13. generasjons Intel® Core™ -prosessorer."
                  },
                  {
                    "tr_title": "Vær stikkontaktfri lenger",
                    "tr_subtitle": "Hold flyten med en PC som optimaliserer batterilevetiden og&nbsp;lader&nbsp;opp på et blunk."
                  },
                  {
                    "tr_title": "Tilkobling med universalkabel",
                    "tr_subtitle": "PC-en kan raskt, enkelt og trygt kobles til andre enheter med Thunderbolt™&nbsp;4&nbsp;&#8209;teknologi.&nbsp;<sup>10</sup>"
                  },
                  {
                    "tr_title": "Raske og pålitelige tilkoblinger",
                    "tr_subtitle": "Videochat eller del filer med en tilkobling du kan stole på, med Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Fokus på",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hva er Intel® Evo™ &#8209;utgaven?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Hvorfor Intel® Evo™ &#8209;utgaven?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitet",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Vær mer kreativ på flere steder",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Bilderedigering i full fart",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Lag original kunst på et øyeblikk",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitet",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maksimer produktiviteten med Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Oppdag dypforfalskninger med AI, før du deler dem",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Din verden. På dine enheter.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilitet",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hils på bevegelsesstyring",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Batterilevetid som ikke er til hinder",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Holder deg automatisk tilkoblet",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Fokus på",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hva er Intel® Evo™ &#8209;utgaven?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Hvorfor Intel® Evo™ &#8209;utgaven?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitet",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rask og jevn avspilling av video",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Produser sanger som slår an med kraften fra AI",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Den raskeste veien til ferdig video",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitet",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maksimer produktiviteten med Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Oppdag dypforfalskninger med AI, før du deler dem",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Din verden. På dine enheter.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilitet",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Samarbeid fra så og si hvor som helst",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Batterilevetid som ikke er til hinder",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Holder deg automatisk tilkoblet",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "All underholdningen din. Én&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "På en PC som også fungerer som et underholdningssenter, trenger du nok lagringsplass til musikk, videoer og spill og den riktige kombinasjonen av prosessor og skjerm for å skape oppslukende visuelle opplevelser. Denne PC&#8209;en har alle tre.",
                "titleArray": [
                  {
                    "tr_title": "Imponerende gaming og streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bygg et digitalt bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Et vindu til underholdningen din",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Sømløs gaming og streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bygg et digitalt bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Et vindu til underholdningen din",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bli mer produktiv. Hvor&nbsp;som&nbsp;helst.",
              "tr_tileTitle1": "Få mer gjort. Raskt.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Denne PC&#8209;en har den perfekte balansen mellom kraft og bærbarhet. I tillegg til en prosessor som er laget for slanke og lette utforminger, har denne PC&#8209;en også Wi&#8209;Fi som holder deg tilkoblet og det riktige batteriet for å frigjøre deg fra stikkontakter.",
                "tr_subtitle1": "Med en perfekt balanse mellom kraft og ytelse hjelper denne PC&#8209;en deg å oppnå mer på mindre tid. Dine daglige gjøremål effektiviseres med kombinasjonen av en robust prosessor hjulpet av rikelig minne og lynrask Wi-Fi.",
                "tr_subtitle2": "Denne PC&#8209;en har den perfekte balansen mellom kraft og bærbarhet. I tillegg til en prosessor som er laget for slanke og lette utforminger, har denne PC&#8209;en også Wi&#8209;Fi for å holde deg tilkoblet og riktig minne for multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Intelligent ytelse som gjør en forskjell",
                    "tr_title1": "Få opp farten med intelligent ytelse",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Avanserte tilkoblinger",
                    "tr_title1": "Koble deg til det som betyr mest",
                    "tr_title2": "Wi&#8209;Fi på et helt nytt nivå",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Vær frakoblet lenger",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Sjongler programmer som en proff",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Friheten til å skape",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Innholdsproduksjon er en av de mest krevende oppgavene for PC&#8209;en. Heldigvis har disse komponentene det som trengs for å strømlinjeforme den kreative prosessen.",
                "titleArray": [
                  {
                    "tr_title": "Mer kreativitet, enda&nbsp;raskere",
                    "tr_title1": "Flyt gjennom kreative prosjekter",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Økt kreativitet",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Minne for multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Se hva som skjuler seg inni denne PC&#8209;en",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dette er de tre hovedkomponentene du bør se på når du velger en ny PC. Ved å bestemme deg for hvordan du skal bruke den nye PC&#8209;en, er du på god vei mot å avgjøre hva slags hovedkomponenter du trenger. De er:",
                "titleArray": [
                  {
                    "tr_title": "Prosessor",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Lagring",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Minne",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Friheten til å skape",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Innholdsproduksjon er en av de mest krevende oppgavene for PC&#8209;en. Heldigvis har disse komponentene det som trengs for å strømlinjeforme den kreative prosessen.",
                "titleArray": [
                  {
                    "tr_title": "En smartere måte å lage innhold på",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Gi liv til bildene dine",
                    "tr_title1": "La oss skape noe",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Minne for multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bli mer produktiv. Hvor&nbsp;som&nbsp;helst.",
              "tr_tileTitle1": "Få mer gjort. Raskt.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Denne PC&#8209;en har den perfekte balansen mellom kraft og bærbarhet. I tillegg til en prosessor som er laget for slanke og lette utforminger, har denne PC&#8209;en også Wi&#8209;Fi som holder deg tilkoblet og det riktige batteriet for å frigjøre deg fra stikkontakter.",
                "tr_subtitle1": "Med en perfekt balanse mellom kraft og ytelse hjelper denne PC&#8209;en deg å oppnå mer på mindre tid. Dine daglige gjøremål effektiviseres med kombinasjonen av en robust prosessor hjulpet av rikelig minne og lynrask Wi-Fi.",
                "tr_subtitle2": "Denne PC&#8209;en har den perfekte balansen mellom kraft og bærbarhet. I tillegg til en prosessor som er laget for slanke og lette utforminger, har denne PC&#8209;en også Wi&#8209;Fi for å holde deg tilkoblet og riktig minne for multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Bygget for å gjøre mer",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Avanserte tilkoblinger",
                    "tr_title1": "Koble deg til det som betyr mest",
                    "tr_title2": "Wi&#8209;Fi på et helt nytt nivå",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Vær frakoblet lenger",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Sjongler programmer som en proff",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-klar",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Når virtuell virkelighet blir levende",
                "tr_subtitle": "Hva skal til for å skape en fullstendig virtuell verden? På en PC trenger du koordineringen mellom prosessoren, grafikkortet og minnet.",
                "titleArray": [
                  {
                    "tr_title": "Kraft til virtuelle univers",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Virkelighetstro detaljer",
                    "tr_title1": "Oppslukende opplevelser",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>En fryd for øyet",
      "tr_sub_header": "Blått lys kan skape visuelt ubehag og forstyrre døgnrytmen. Samsungs SGS Eye Care Display-testede OLED-skjermer hjelper ikke ved å endre fargene, men i stedet reduserer de bølgelengdene med skadelig blått lys og gir komfort og reduserer tretthet for øynene.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Mindre skadelig blått lys"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra på alle måter",
      "tr_sub_header": "OLEDs ultralette, ultratynne design maksimerer bildekvaliteten og minimerer vekten."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Ekte svart",
      "tr_sub_header": "0,0005 nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74 %<br/><span class='conventional_text'>Konvensjonell</span>",
      "tr_sub_header": "120 %<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streaming",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMING",
                "tr_subtitle": "Uendelig underholdning",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for streaming:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan streame i HD."
                  },
                  {
                    "tr_title": "En skjerm for lyssterke, vakre bilder."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi som leverer mindre bufring og lastingstid."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Nettsurfing",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NETTSURFING",
                "tr_subtitle": "Internett gjort enkelt",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for nettsurfing:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som raskt kan laste nettsider med ulike medier."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi som er rask og pålitelig."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochatting",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHATTING",
                "tr_subtitle": "Krystallklare tilkoblinger",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for videochatting:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan ta opp og dele bilder samtidig."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi som er raskt, kraftig og pålitelig."
                  },
                  {
                    "tr_title": "Et webkamera som hjelper deg med å være krystallklar."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Høyhastighets multitasking",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "HØYHASTIGHETS MULTITASKING",
                "tr_subtitle": "Gjør mer uten å sakke",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan sjonglere flere programmer samtidig."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi som er raskt, kraftig og pålitelig."
                  },
                  {
                    "tr_title": "Minne som bytter sømløst mellom programmer."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochatting",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHATTING",
                "tr_subtitle": "Krystallklare tilkoblinger",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for videochatting:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan ta opp og dele bilder samtidig."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi som er raskt, kraftig og pålitelig."
                  },
                  {
                    "tr_title": "Et webkamera som hjelper deg med å være krystallklar."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Avslappet gaming",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "AVSLAPPET GAMING",
                "tr_subtitle": "Tid for å game",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for lett gaming:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "En prosessor med kraft til jevn gaming."
                  },
                  {
                    "tr_title": "Minne for stemmechatting og streaming samtidig."
                  },
                  {
                    "tr_title": "Grafikk som leverer fantastiske bilder."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bilderedigering",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "BILDEREDIGERING",
                "tr_subtitle": "Pikselperfekte bilder",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for bilderedigering:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "En prosessor med kraften til å redigere store mengder med bilder."
                  },
                  {
                    "tr_title": "Minne som akselerer redigeringsprogramvaren."
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 &#8209;teknologi lar deg overføre filer raskt."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Høyhastighets multitasking",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "HØYHASTIGHETS MULTITASKING",
                "tr_subtitle": "Gjør mer uten å sakke",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan sjonglere flere programmer samtidig."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi som er raskt, kraftig og pålitelig."
                  },
                  {
                    "tr_title": "Minne som bytter sømløst mellom programmer."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Samarbeid i sanntid",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SAMARBEID I SANNTID",
                "tr_subtitle": "Jobb hvor som helst",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for samarbeid på nett:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "En prosessor med kraft til kontorprogramvare."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi du kan stole på."
                  },
                  {
                    "tr_title": "Et webkamera som tar opp i skarp HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videoredigering",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOREDIGERING",
                "tr_subtitle": "Lag filmmagi",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for videoredigering:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan takle store filer."
                  },
                  {
                    "tr_title": "Minne som gjør redigeringsprogramvare rask og responsiv."
                  },
                  {
                    "tr_title": "Grafikk som kutter ned rendringtider."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bilderedigering",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "BILDEREDIGERING",
                "tr_subtitle": "Pikselperfekte bilder",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for bilderedigering:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "En prosessor med kraften til å redigere store mengder med bilder."
                  },
                  {
                    "tr_title": "Minne som akselerer redigeringsprogramvaren."
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 &#8209;teknologi lar deg overføre filer raskt."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Videoredigering",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOREDIGERING",
                "tr_subtitle": "Lag filmmagi",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for videoredigering:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan takle store filer."
                  },
                  {
                    "tr_title": "Minne som gjør redigeringsprogramvare rask og responsiv."
                  },
                  {
                    "tr_title": "Grafikk som kutter ned rendringtider."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D-modellering",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D-MODELLERING",
                "tr_subtitle": "Neste nivå kreasjoner",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for 3D&#8209;modellering:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "En prosessor som kan takle avansert kreativ programvare."
                  },
                  {
                    "tr_title": "Minne som raskt kan hente inn store filer."
                  },
                  {
                    "tr_title": "Grafikk for kraftig bildeskaping og &#8209;redigering."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Samarbeid i sanntid",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SAMARBEID I SANNTID",
                "tr_subtitle": "Jobb hvor som helst",
                "tr_subtitle1": "Dette fant vi i PC-en som gjør den bra for samarbeid på nett:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "En prosessor med kraft til kontorprogramvare."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi du kan stole på."
                  },
                  {
                    "tr_title": "Et webkamera som tar opp i skarp HD."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Intel® Unison™ &#8209;app",
    "tr_title": "Lås opp den tilkoblede verdenen",
    "tr_subtitle": "Overfør filer og bilder sømløst mens du tar hånd om telefonvarsler, telefonanrop og tekstmeldinger på PC&#8209;en.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "OVERFØR FILER OG BILDER",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "RING OG MOTTA ANROP",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "SEND OG MOTTA MELDINGER",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "BEHANDLE TELEFONVARSLER",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "LÅS OPP DEN TILKOBLEDE VERDENEN",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}